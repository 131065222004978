import React from "react";
import { Button, Modal, InputGroupAddon, InputGroupText } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { informationService } from "../../_services/masters/information.service";
import Vector1 from "../../assets/img/icons/two-factor-icon.svg";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
function Resetpasswod({ userId }) {
  const [formModal, setformModal] = React.useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    reset,
  } = useForm({});
  const password = React.useRef({});
  const [errorPassword, setErrorPassword] = React.useState("");
  password.current = watch("password", "");
  const onSubmit = (data) => {
    const data1 = {
      "userId": userId,
      "password": data.password,
    };
    //console.log(data1);
    informationService.changePassword(data1).then(
      (res) => {
        if (res.status === "success") {
          reset();
          setformModal(false);
          toast.success(
            "Your account password has been successfully updated  !!",
            "Account"
          );
        } else if (res.status === "error") {
          setErrorPassword(
            "Password At least 8 characters long 1 capital letter, 1 lowercase letter, 1 special character and 1 numeric character"
          );

          setTimeout(() => {
            setErrorPassword("");
          }, 5000);
        } else {
          setErrorPassword(
            "Password At least 8 characters long 1 capital letter, 1 lowercase letter, 1 special character and 1 numeric character"
          );
          setTimeout(() => {
            setErrorPassword("");
          }, 5000);
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const [passwordType, setPasswordType] = React.useState("password");
  const [confPasswordType, setConfPasswordType] = React.useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfPassword = () => {
    if (confPasswordType === "password") {
      setConfPasswordType("text");
      return;
    }
    setConfPasswordType("password");
  };

  return (
    <>
      <div className="greenActionBtn" onClick={() => setformModal(true)}>
        <img src={Vector1} alt="" width={16} height={16} />
        Reset Password
      </div>
      <Modal
        className="modal-dialog-centered changePasswordModal"
        size="sm"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-header head border-bottom">
          <h6 id="modal-title-default">Change Password</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setformModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group formBox">
              <label
                className="form-control-label"
                htmlFor="example2cols1Input2"
              >
                Password :
              </label>
              <div className="appendIconNew">
                <div className="d-flex">
                  <input
                    className="form-control"
                    name="password"
                    id="example2cols1Input2"
                    placeholder="type in password..."
                    type={passwordType}
                    {...register(
                      "password",
                      {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password At least 8 characters long 1 capital letter, 1 lowercase letter, 1 special character and 1 numeric character",
                        },
                      },
                      {
                        pattern:
                          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
                      }
                    )}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={togglePassword}
                      style={{ cursor: "pointer", color: "#000" }}
                    >
                      {passwordType === "text" ? <IoMdEye /> : <IoMdEyeOff />}
                    </InputGroupText>
                  </InputGroupAddon>
                </div>
                <small className="error">
                  {errorPassword ? errorPassword : errors.password?.message}
                </small>
              </div>
            </div>
            <div className="form-group formBox">
              <label
                className="form-control-label"
                htmlFor="example2cols1Input3"
              >
                Retype Password :
              </label>
              <div className="appendIconNew">
                <div className="d-flex">
                  <input
                    className="form-control"
                    name="confirmPwd"
                    id="example2cols1Input3"
                    placeholder="type in password..."
                    type={confPasswordType}
                    {...register("confirmPwd", {
                      validate: (value) => {
                        const { password } = getValues();
                        return password === value || "Passwords should match!";
                      },
                    })}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={toggleConfPassword}
                      style={{ cursor: "pointer", color: "#000" }}
                    >
                      {confPasswordType === "text" ? (
                        <IoMdEye />
                      ) : (
                        <IoMdEyeOff />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </div>
                <small className="error">{errors.confirmPwd?.message}</small>
              </div>
            </div>
            <Button type="submit" className="blueBtn w-100 mt-4">
              Submit
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
}
export default Resetpasswod;
