import React from "react";
import { useParams } from "react-router-dom";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import CombinedGuestPoolList from "components/Events/CombinedGuestPool";

const CombinedGuestPool = () => {
  const param = useParams();

  return (
    <>
      <CombinedGuestPoolHeader
        title="Global Guest Pool"
        // imgScr={Imggroup}
        arw
      />
      <div className="organizerWrapperMain">
        <div className="card organizerCardWrapper">
          <CombinedGuestPoolList />
        </div>
      </div>
    </>
  );
};

export default CombinedGuestPool;
