import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { informationService } from "../../_services/masters/information.service";
import Global from "../../_helpers/global";
function Yourteam() {
  const [dataList, setdataList] = React.useState([]);
  const [isSuperAdmin, setSuperAdmin] = React.useState(localStorage.getItem("userAdmintoken"));
  const [user_data, setuserDetails] = React.useState(JSON.parse(localStorage.getItem("userDetails")));
  //console.log("isSuperAdmin", isSuperAdmin);

  React.useEffect(() => {
    // const interval = setInterval(() => {
    //   informationService.acceptList().then(
    //     (res) => {
    //       if (res.status === "success") {

    //         setdataList(res.data);
    //       } else {
    //         console.log("Something went wrong !!");
    //         //toast.error(res.errors[0], "Fashion Store");
    //       }
    //     },
    //     (error) => {
    //       console.log("Something went wrong !!");
    //       //toast.error("Something went wrong !!", "Fashion Store");
    //     }
    //   );
    // }, 1000);
    // return () => clearInterval(interval);


    informationService.acceptList().then(
      (res) => {
        if (res.status === "success") {

          if ((isSuperAdmin === null || isSuperAdmin === "null") && (user_data.account_type != "Admin")) {
            delete res.data[0];
            let data = res.data;
            setdataList(data);
          } else {
            let data = res.data;
            setdataList(data);
          }


        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);



  // setInterval(() => {

  //   informationService.acceptList().then(
  //     (res) => {
  //       if (res.status === "success") {
  //         console.log(res.data);
  //         console.log('Interval triggered');
  //         setdataList(res.data);
  //       } else {
  //         console.log("Something went wrong !!");
  //         //toast.error(res.errors[0], "Fashion Store");
  //       }
  //     },
  //     (error) => {
  //       console.log("Something went wrong !!");
  //       //toast.error("Something went wrong !!", "Fashion Store");
  //     }
  //   );
  // }, 50000);

  return (
    <>
      <Card>
        <CardHeader>
          <div className="cardheader2">
            <div className="cardheader">
              <img
                alt="Organization Name"
                src={require("assets/img/icons/Vector-5.svg").default}
                className="icon"
              />
              <h3 className="mb-0 headingNew">Your Team</h3>
            </div>
            <div>
              <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                  className="icon"
                />
              </span>
              <ReactTooltip />
            </div>
          </div>
        </CardHeader>
        <hr style={{ margin: "0px" }} />

        <CardBody style={{ minHeight: "527px" }}>
          <div className="scrtime">
            {dataList.map((popup, i) => (
              <div key={Math.random()}>
                {popup?.first_name && (
                  <div className="user-pading yourTeamCard" key={i}>
                    <div className="badgeBox">
                      <span className="badge" style={popup?.is_logged_in === 1 ? { backgroundColor: '#2dce89' } : {}}></span>
                      <img
                        alt="placeholder"
                        src={
                          popup?.imageUrl
                            ? popup?.imageUrl
                            : require("assets/img/people-cameos.svg").default
                        }
                        className="avatar"
                      />
                    </div>
                    <div className="content" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ color: "#000", fontSize: "18px" }}>
                        <Link
                          to={`/admin/account-information/${popup?.to_id}`}
                        >
                          {popup?.first_name ? popup?.first_name : ""}{" "}
                          {popup?.last_name ? popup?.last_name : ""}
                          <span style={{ display: "block", fontSize: "10px" }}>
                            {popup?.account_type}
                          </span>
                        </Link>
                      </div>
                      {popup?.is_logged_in === 1 ? (<div>Logged in</div>) : ''}
                    </div>



                  </div>
                )}
              </div>
            ))}
          </div>
          <Link to="/admin/invite" className="MemberTeam inviteTeamMemberBtn">
            Invite Team Member
          </Link>
        </CardBody>
      </Card>
    </>
  );
}
export default Yourteam;
