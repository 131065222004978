import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventsService } from "../../_services/masters/events.service";
import * as actionTypes from "../../store/actionTypes";
import StoryzMenu from "../../components/Storyz/StoryzMenu";
import { Row, Col, Progress } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { ReactSession } from "react-client-session";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { informationService } from "../../_services/masters/information.service";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Storyzimg from "../../assets/img/icons/storyz.svg";
import StoryzThemeModal from "components/StoryzThemeModal";
import { commonData } from "store/actions";
import Storyzdelete from "../../components/model-form/storyzDelete";
import DummyImg from "../../assets/img/dummyImg.svg";
import AboutExitareaStory from "../../components/Inputbox/aboutExitareaStory";
import moment from "moment";

function Storyzdashboard() {
  const params = useParams();
  let history = useHistory();

  const dispatch = useDispatch();
  const [eventList, seteventList] = React.useState([]);
  const [eventList2, seteventList2] = React.useState([]);
  const [openThemeModal, setOpenThemeModal] = React.useState(false);
  let projectid = params.sid;

  const [story, setStoryname] = React.useState([]);

  const [honoredGuestsDashboard, sethonoredGuestsDashboard] = React.useState(
    []
  );
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));

        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [storyzSetting, setstoryzSetting] = useState({});
  function dataThemeNew(e) {
    storyzDetail();
  }

  function storyzDetail() {
    const data = {
      story_id: params.sid,
    };
    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setStoryname(res.data[0]);
          setstoryzSetting(res.data[0].Storyz_Setting);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  let urlArr = window.location.href.split("/");
  let sId = urlArr[urlArr.length - 1];

  const mockSave = (val) =>
    new Promise((resolve) => {
      setTimeout(() => resolve(val), 2000);
      storyzUpdate(val);
    });

  function storyzUpdate(val) {
    const val1 = [
      {
        id: sId,
        key_name: "our_storyz_description",
        key_value: val,
      },
    ];
    informationService.storyzAutoSave(val1).then(
      (res) => {
        if (res.status === "success") {
          toast.success("update successfully !!", "Account");
          storyzDetail();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  useEffect(() => {
    storyzDetail();
    informationService.eventList(params.sid).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
          seteventList2(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [params.sid]);

  const handleChange = (e) => {
    const valueName = e.target.value;
    const filtered = eventList2.filter((employee) => {
      return employee.event_name.indexOf(valueName) !== -1;
    });
    seteventList(filtered);
  };

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  const [allimage, setAllimage] = React.useState([]);
  const [modalShow21, setModalShow21] = React.useState(false);

  const handleClick = () => {
    setModalShow21(false);
  };
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = {
      type: "storyz",
      storyz_id: params.sid,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateprojectUpdateimage(data).then(
      (res) => {
        if (res.status === "success") {
          storyzDetail();
          toast.success(res.message, "Account");
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);
    let storyz_id = params.sid;
    let jsonData = e;
    jsonData["storyz_id"] = storyz_id;
    jsonData["imaDatajson"] = obj;

    informationService.updateprojectUpdateimage(jsonData).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          storyzDetail();
          setModalShow21(false);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }

    informationService.honoredGuestsDashboard(params.sid).then(
      (res) => {
        if (res.status === "success") {
          sethonoredGuestsDashboard(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  const DataSetEvents = (e) => {
    const eid =
      e.currentTarget.getAttribute('data-eid');
    history.push(
      `/admin/event-dashboard/${params.sid}/${eid}`
    );
  }
  return (
    <>
      {/* <Allheader
        title={story?.storyz_name ? story?.storyz_name : ""}
        imgScr={story?.profile_photo_Url ? story?.profile_photo_Url : Imggroup}
        arw
      /> */}
      {/* <div className="accountinformation ptop storyzDashboard"></div> */}

      <div className="storyzDashboard">
        <div className="card">
          <Row>
            <Col lg={6} md={12}>
              <div
                className="themeOuterWrapper"
                style={{
                  background: storyzSetting.screen_bg,
                }}
              >
                {/* <img src={story?.Storyz_Setting?.background_image} alt="" /> */}
                <div
                  className="themeOuterInner"
                  style={{
                    background: `url(${story?.Storyz_Setting?.background_image
                      ? story?.Storyz_Setting?.background_image : ""
                      // : EventBg
                      })`,
                  }}
                >
                  <div className="badge">Storyz</div>
                  {/* <div className="bakcToStoryWraper">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/edit-fieldicons.svg").default}
                      className="arrowIcon"
                    />
                    Organization Name
                  </div> */}

                  <Row>
                    <Col lg={5} md={12}>
                      <div className="coverimagest mb-2">

                        <div className="d-flex align-items-center">


                          <span
                            className="mr-2 pointer"
                            onClick={() => {
                              setModalShow21(true);
                            }}
                          >
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/mediaGallery.svg").default
                              }
                              className="icomn"
                              style={{ width: "20px" }}
                            />
                          </span>
                          {modalShow21 && (
                            <Filemanager
                              show={modalShow21}
                              onHide={handleClick}
                              onClickimageUpdate={imageUpdate}
                              list={allimage}
                              seletedImage={seletedImageClike}
                            />
                          )}


                        </div>
                      </div>
                      <div>
                        <img
                          className="storytoimage pointer"
                          alt="Organization Name"
                          src={
                            story?.profile_photo_Url
                              ? story?.profile_photo_Url
                              : DummyImg
                          }
                          onClick={() => {
                            setModalShow21(true);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={7} md={12}>
                      <h1
                        className="storyzname"
                        style={{
                          fontFamily: storyzSetting.font,
                          fontSize: storyzSetting.size,
                          fontStyle: storyzSetting.style,
                          color: storyzSetting.screen_txt,
                        }}
                      >
                        {story?.storyz_name}
                      </h1>
                      <div>
                        <span className="fontSize16 daclass">
                          {story?.event_start_date &&
                            story?.event_start_date !== "NA"
                            ? moment(story?.event_start_date).format("MMM Do")
                            : "No Date"}{" "}
                          {story?.event_start_date &&
                            story?.event_start_date !== "NA"
                            ? " - " +
                            moment(story?.event_end_date).format("MMM Do YY")
                            : "- No Date"}
                        </span>
                      </div>
                      <div className="mt-2">
                        <span className="stclass">
                          {story?.event_count} Events
                        </span>
                      </div>
                      <div className="mt-2">
                        <p className="wedding">{story?.project_type}</p>
                        <div className="wedding">
                          {story?.event_location > 0 &&
                            story?.event_location?.map((x, i) => {
                              return (
                                <>
                                  <div className="user" key={Math.random()}>{x?.location},</div>
                                </>
                              );
                            })}
                        </div>
                        <p className="wedding">
                          {/* {story?.hosted_by && "Hosted by: " + story?.hosted_by} */}
                          {story?.hosted_by && (
                            <>
                              <span
                                style={{
                                  color: storyzSetting.screen_txt,
                                }}
                              >
                                Hosted by:
                              </span>
                              <span
                                style={{
                                  color: storyzSetting.display_txt,
                                }}
                              >
                                {story?.hosted_by}
                              </span>
                            </>
                          )}
                        </p>
                        <p
                          className="GreatVibes fontSize24"
                          style={{
                            margin: "0px",
                            color: storyzSetting.screen_txt,
                          }}
                        >

                          {story?.greeting_title}
                        </p>
                        <Link to={`/admin/storyz-about/${projectid}`}>
                          <p
                            className={
                              story?.greeting_title ? "wedding" : "we2"
                            }
                            style={{
                              color: storyzSetting.display_txt,
                            }}
                          >
                            {story?.greeting_description}
                          </p>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <h4
                      className="GreatVibes fontSize24 mt-2"
                      style={{
                        color: storyzSetting.screen_txt,
                      }}
                    >
                      About
                    </h4>
                  </div>
                  <div>
                    <AboutExitareaStory
                      label="Username"
                      placeholder="About"
                      onSave={mockSave}
                      name="event_description"
                      sId={sId}
                      value={
                        story?.our_storyz_description === null ||
                          story?.our_storyz_description === "null"
                          ? ""
                          : story?.our_storyz_description
                      }
                    />

                    {/* <p
                      className={
                        story?.greeting_description
                          ? "fontSize14"
                          : "greetingDescription"
                      }
                      style={{ padding: "12px" }}
                    >
                      {story?.greeting_description}
                    </p> */}
                  </div>
                  <div className="moredetails mt-3">
                    <span
                      style={{
                        color: storyzSetting.screen_txt,
                      }}
                    >
                      More Details:
                    </span>
                    <Link to={`/admin/storyz-about/${projectid}/#aeroplane`}>
                      <img
                        alt="..."
                        className="imgclast"
                        src={require("assets/img/moredetails1.svg").default}
                      />
                    </Link>
                    <Link to={`/admin/storyz-about/${projectid}/#attire`}>
                      <img
                        alt="..."
                        className="imgclast"
                        src={require("assets/img/moredetails2.svg").default}
                      />
                    </Link>
                    <Link to={`/admin/storyz-about/${projectid}/#thingsToKnow`}>
                      <img
                        alt="..."
                        className="imgclast"
                        src={require("assets/img/Vectorma.svg").default}
                      />
                    </Link>
                    <img
                      alt="..."
                      className="imgclast"
                      src={require("assets/img/moredetails4.svg").default}
                    // onClick={() => setModalShow(true)}
                    />
                    {/* <StoryzViews
                      displayModal={modalShow}
                      closeModal={() => setModalShow(false)}
                    /> */}
                  </div>
                  <hr
                    style={{
                      marginTop: "10px",
                      marginBottom: "0em",
                      borderTop: "1px solid #979798",
                    }}
                  />

                  <div
                    onClick={() => {
                      history.push(
                        `/admin/storyz-honored-guest-list/${params.sid}`
                      );
                    }}
                  >
                    <h4
                      className="GreatVibes fontSize24 mt-2"
                      style={{
                        color: storyzSetting.screen_txt,
                      }}
                    >
                      Honored Guests
                    </h4>
                    {honoredGuestsDashboard.length > 0 ? (
                      <div className="evavter mt-4">
                        {honoredGuestsDashboard?.slice(0, 4).map((arr, i) => {
                          return (
                            <div key={Math.random()}>
                              <a
                                className="avatar rounded-circle"
                                href="#pablo"
                              >
                                <img
                                  alt="..."
                                  src={arr?.imageUrl ? arr?.imageUrl : DummyImg}
                                />
                              </a>
                              <p
                                className="fontSize12 JakartaMedium500 text-center"
                                style={{
                                  color: storyzSetting.screen_txt,
                                }}
                              >
                                {arr?.first_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <a className="avatar rounded-circle" href="#pablo">
                          <img alt="..." src={DummyImg} />
                        </a>
                        <p
                          className="fontSize12 JakartaMedium500 text-center"
                          style={{
                            color: storyzSetting.screen_txt,
                          }}
                        >
                          Select guest to honor
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mt-4 pl-3 pb-3">
                    <div
                      onClick={() => {
                        history.push(
                          `/admin/combined-guest-list/${params.sid}`
                        );
                      }}
                    >
                      <Row>
                        <Col
                          md="7"
                          style={{
                            background: "rgba(255, 255, 255, 0.38)",
                            borderRadius: "6px",
                            padding: "13px",
                          }}
                        >
                          <p
                            style={{
                              color: storyzSetting.screen_txt,
                            }}
                          >
                            Status:
                          </p>
                          <Progress
                            color="#979798"
                            className="progress-lg mb-0"
                            max="100"
                            value={
                              story?.Storyz_status_bar
                                ? story?.Storyz_status_bar
                                : "0"
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="mt-4 pl-3 pb-3">
                    <Row>
                      <Col
                        md="7"
                        style={{
                          background: "rgba(255, 255, 255, 0.38)",
                          borderRadius: "6px",
                          padding: "8px",
                        }}
                      >
                        <div className="stcaldiv">
                          <div>
                            <Link to={`/admin/combined-guest-list/${sId}`}>
                              <span className="fontSize12 Jakartanormal">
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/Vector15.svg").default
                                  }
                                  style={{ marginRight: "10px" }}
                                />
                              </span>
                              <span
                                className="fontSize12 Jakartanormal"
                                style={{
                                  marginRight: "10px",
                                  color: storyzSetting.screen_txt,
                                }}
                              >
                                {story?.total_guest ? story?.total_guest : "0"}{" "}
                                Guests
                              </span>
                            </Link>
                          </div>

                          <div>
                            <Link to={`/admin/storyz-todos/${sId}`}>
                              <span className="fontSize12 Jakartanormal">
                                <img
                                  alt="Organization Name"
                                  src={require("assets/img/Group.svg").default}
                                  style={{ marginRight: "10px" }}
                                />
                              </span>
                              <span
                                className="fontSize12 Jakartanormal"
                                style={{
                                  marginRight: "10px",
                                  color: storyzSetting.screen_txt,
                                }}
                              >
                                {story?.total_todo ? story?.total_todo : "0"}{" "}
                                Todos
                              </span>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div
                  className="pointer text-end"
                  onClick={() => setOpenThemeModal(true)}
                >
                  Japanese Wave
                  <img
                    alt="Organization Name"
                    src={require("assets/img/paint-icon 1.svg").default}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
              <StoryzThemeModal
                openThemeModal={openThemeModal}
                handleThemeClose={() => setOpenThemeModal(false)}
                // handleThemeStyle={handelDynamicStyle}
                id={params?.sid}
                dataTheme={storyzSetting}
                type="story"
                dataThemeNew={dataThemeNew}
                ThemeTypeName="Storyz"
              />
            </Col>
            <Col lg={6} md={12}>
              <StoryzMenu storyId={projectid} />
              <div className="mt-5">
                <div className="emenu">
                  <div className="emenu1">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/sound.svg").default}
                      style={{ marginRight: "10px" }}
                    />
                    <h3 className="mb-0 fontSize20 JakartaMedium300">
                      Events in this Storyz
                    </h3>
                  </div>
                  <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                      style={{ marginRight: "10px" }}
                    />
                  </span>
                  <ReactTooltip />
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "1.3em" }} />
                <div>
                  <div className="form-group">
                    <input
                      id="example2cols1Input"
                      placeholder="type to search..."
                      type="text"
                      className="form-control"
                      style={{ width: "60%" }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="todolistst">
                    <section className="storyzsection storyeventlist">
                      {eventList.length > 0 ? (
                        <>
                          {eventList.map((evdata, i) => (
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "15px",
                              }}
                              className="story1"
                              key={i}
                              data-eid={evdata?.id}
                              onClick={() => {
                                const id = { event_id: evdata?.id };
                                eventsService.eventDetails(id).then(
                                  (res) => {
                                    if (res.status === "success") {
                                      let sid = res.data.storyz_id;
                                      let eid = res.data.id;
                                      let storyName = res.data.storyz_name;
                                      let singleEvent = res.data;
                                      dispatch(commonData(common));
                                      myFunctionList(sid, eid, storyName, singleEvent);
                                    } else {
                                      console.log("Something went wrong !!");
                                    }
                                  },
                                  (error) => {
                                    console.log("Something went wrong !!");
                                    //toast.error("Something went wrong !!", "Fashion Store");
                                  }
                                );
                                history.push(
                                  `/admin/event-dashboard/${evdata?.project_id}/${evdata?.id}`
                                );

                              }}
                            >
                              <div>
                                <img
                                  alt={evdata?.event_name}
                                  src={
                                    evdata?.cover_image
                                      ? evdata?.cover_image
                                      : DummyImg
                                  }
                                  className="eventimag"
                                />
                              </div>
                              <div>
                                <div className="title">
                                  <Link to={``}>{evdata?.event_name}</Link>
                                </div>
                                <div className="subtitle">
                                  {evdata.event_start_date
                                    ? moment(evdata.event_start_date).format(
                                      "MMM Do YY"
                                    )
                                    : "No Date"}
                                </div>
                                <div className="subtitle1">
                                  {evdata?.location}
                                </div>
                                <div className="description">
                                  {evdata?.event_description
                                    ? evdata?.event_description.substring(
                                      0,
                                      25
                                    ) + "..."
                                    : ""}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        "No Events Data"
                      )}
                    </section>
                  </div>

                  <Link
                    to="#"
                    onClick={() => {
                      dispatch({
                        type: actionTypes.EVENTS_POPUP,
                        payload: true,
                      });
                    }}
                    className="newTodo"
                  >
                    New Event
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="stdeletes">
          {/* <Button
            className="delteEventBtn"
            color="default"
            type="submite"
            onClick={() => setModalShowDelete(true)}
          >
            <img alt="" src={Storyzimg} className="mr-2" />
            Delete Storyz
          </Button> */}

          <button
            type="button"
            className="delteEventBtn mt-0"
            onClick={() => setModalShowDelete(true)}
          >
            <img alt="" src={Storyzimg} className="mr-2" />
            Delete Storyz
          </button>

          <Storyzdelete
            displayModal={modalShowDelete}
            closeModal={() => setModalShowDelete(false)}
            name={story?.storyz_name}
            id={params?.sid}
            event_date={story?.event_start_date}
            event_count={story?.event_count}
            simag={
              story?.profile_photo_Url
                ? story?.profile_photo_Url
                : "/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg"
            }
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Storyzdashboard;
