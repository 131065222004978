import React from "react";
import { Row, Col, Button } from "reactstrap";
const StoryzViews = (props) => {
  // console.log('hello');
  const divStyle = {
    display: props.displayModal ? "block" : "none",
  };
  function closeModal(e) {
    e.stopPropagation();
    props.closeModal();
  }
  return (
    <div
      className="modal createStoryzModal"
      onClick={closeModal}
      style={divStyle}
    >
      <div
        className="modal-content border-bottom"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <span
                    className="close"
                    onClick={closeModal}>&times;
                </span> */}
        <div className="textimgs border-bottom">
          <img
            alt="Organization Name"
            src={require("assets/img/Icon-Text-in-Circle-story.svg").default}
          />
          <h2>Create Your Storyz</h2>
        </div>
        <div className="border-bottom pb-3">
          <div className="mt-2 border-bottom pb-3">
            <Row>
              <Col md={8}>
                <p className="storyz-are">
                  Storyz are the most important part you’ll need to set up.
                  Basically, a Storyz is a set of connected events, organized
                  together as a single experience for your guests. Once you
                  create the Storyz, the rest will follow:
                </p>
              </Col>
              <Col md={4}>
                <img
                  alt="Organization Name"
                  src={require("assets/img/image-149.svg").default}
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              </Col>
            </Row>
          </div>
          <div className="mt-3 mb-4 border-bottom">
            <p>Select The Storyz menu item from the left-hand menu.</p>
          </div>
          <div>
            <p>
              Fill in basic information about your Storyz. Select the “About”
              Iton from the Storyz dashboard to provide detail about your
              events, background, and information guests will need to know.{" "}
            </p>
            <p>
              Once you’ve created the Storyz, it is time to add events. From the
              Storyz Dashboard, select the New Event Option. You can add draft
              events, and fill in information later.
            </p>
            <p>
              Based on your event input, your Storyz will automatically generate
              additional information. For example, OurStoryz will check the
              dates for your events, and automatically create a calendar and
              timeline.
            </p>
            <p>
              You can also create your guest list at the Storyz level. If you
              add your guest list to the Storyz, your guests will be added to
              all your events. You can adjust which guests are added to which
              guests with the Combined Guest List.
            </p>
            <p>
              Use the Media Gallery and Files options to add additional
              informatiln. Upload images, audio or video to the Storyz Media
              gallery - it will be available to all your sub-events. If you have
              documents that your team needs to share, use the Files screen to
              import or link to them so all your team members can see them.
            </p>
          </div>
        </div>
        <div className="mt-3 d-flex align-items-center">
          <Button className="blueBtn flex-grow-1" type="submit">
            More information
          </Button>
          <Button
            className="cancelBtn height40 flex-grow-1"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default StoryzViews;
