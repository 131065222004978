import * as React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import SocialGoogleLogin from "../../../components/Sociallogin/SocialGoogleLogin";
import SocialFacebookLogin from "../../../components/Sociallogin/SocialFacebookLogin";

import { useForm } from "react-hook-form";
import { userService } from "../../../_services/login/user.service";
import { informationService } from "../../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { userAdminData } from "store/actions";
import { FaChevronLeft } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthHeader from "components/Headers/AuthHeader.js";
import * as actionTypes from "../../../store/actionTypes";
import { clearstoryList, clearuserData } from "../../../store/actions/index";

import { useHistory } from "react-router-dom";
function Signup() {
  const currentUrlArr = window.location.href.split("?");
  let email = "";
  if (currentUrlArr.length > 1) {
    let emailData = atob(currentUrlArr[1]);
    console.log("emailData", emailData);
    let queryDataArr = emailData.split("=");
    email = queryDataArr[1];
  }
  //console.log('email:', email);
  //email
  const history = useHistory();
  const dispatch = useDispatch();

  const contact = useSelector((state) => {
    return state.profile;
  });

  const admindata = useSelector((state) => {
    return state.userAdmin.listData;
  });

  React.useEffect(() => {
    dispatch(clearstoryList());

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === "" || userDetails === null) {
      dispatch(clearuserData());
    }

    // if (contact.isConfirmatiom === true) {
    //   history.push('/admin/account-information');
    // }
  }, []);

  const [emailId, setEmail] = React.useState(email);
  const [emailIdErr, setEmailIdErr] = React.useState(
    email == "" ? "Signup email id not found" : emailId
  );
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [formemail, setFormemail] = React.useState("");
  const [formpassword, setFormpassword] = React.useState("");
  const [formoption, setFormoption] = React.useState();

  const [local, setLocal] = React.useState(true);
  const [passErr, setPassErr] = React.useState("");
  const [passval, setPassval] = React.useState("");
  const [repassErr, setRePassErr] = React.useState("");
  const [repassval, setRePassval] = React.useState("");

  const [cancelModal, setCancelModal] = React.useState(false);
  const CancelModalHandler = () => {
    setCancelModal(!cancelModal);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      option: "",
    },
  });

  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  const onSubmit = (data) => {
    userService.login(data).then(
      (res) => {
        if (res.status == "success") {
          localStorage.setItem("userDetails", JSON.stringify(res.data));
          var retrievedObject = JSON.parse(localStorage.getItem("userDetails"));
          if (retrievedObject.name !== Cookies.get("name")) {
            Cookies.set("name", retrievedObject.name);
          }
          if (res.data.account_type === "Super Admin") {
            dispatch({ type: actionTypes.CLEAR_USER_ADMIN_INFO, payload: "" });

            let to = { token: res.data.token };
            localStorage.setItem("userAdmintoken", JSON.stringify(to));
            const value = {
              ...admindata,
              profileAddmindetails: res.data,
            };
            dispatch(userAdminData(value));
            //dispatch({ type: actionTypes.USER_ADMIN, payload: res.data });
            history.push("/organization-list");
          } else {
            userdetail();
          }
        } else if (res.status == "otp_sent_successfully") {
          localStorage.setItem("twoStepVerification", JSON.stringify(res.data));
          window.location.assign("/two-factor-authentication");
        } else if (res.status == "error") {
          localStorage.removeItem("userDetails");
          toast.error(res.message, "Login");
        } else {
          localStorage.removeItem("userDetails");
          toast.error("Please enter valid username and password !!", "Login");
        }
      },
      (error) => {
        localStorage.removeItem("userDetails");
        toast.error("Invalid Credetials !!", "Login");
      }
    );

    if (data.option === true) {
      localStorage.setItem("keepLoggedin", JSON.stringify(data));
    } else {
      localStorage.removeItem("keepLoggedin");
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("keepLoggedin")) {
      var retrievedObject = JSON.parse(localStorage.getItem("keepLoggedin"));
      setValue("email", retrievedObject.email, { shouldTouch: true });
      setValue("password", retrievedObject.password, { shouldTouch: true });
      setValue("option", retrievedObject.option, { shouldTouch: true });
      //console.log(retrievedObject.email);
    }
  }, [onSubmit]);

  // React.useEffect(() => {
  //   if (localStorage.getItem('userDetails')) {
  //     history.push('/admin/account-information');
  //   }
  // }, [])
  const localMethod = () => {
    setLocal(false);
  };
  const localMethodBack = () => {
    setLocal(true);
  };

  const validatePassword = (password) => {
    //console.log( "password", password);
    setPassval(password);
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@$)}#])[A-Za-z\d!@$)}#]{8,}$/;
    if (passwordRegex.test(password)) {
      setPassErr("");
    } else {
      setPassErr("Password is invalid");
    }
  };
  const validateRePassword = (repassword) => {
    //console.log( "password", password);
    setRePassval(repassword);
    //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@$)}#])[A-Za-z\d!@$)}#]{8,}$/;
    if (repassword === passval) {
      setRePassErr("");
    } else {
      setRePassErr("Password and Re-Password does not match");
    }
  };
const setPassword = () => {
    let data = {
                password:passval, 
                email:emailId,
                operating_system:navigator.userAgentData.platform,
                browser_type:navigator.userAgentData.brands[1],
                };
    if(emailId == ""){
        setEmailIdErr("Signup email id not found");
    }else{
      let emailData = `email=${emailId}`;
      let demaildata = btoa(emailData);
      userService.setPassword(data).then(
        (res) => {
          if (res.status == "success") {
                toast.success(`Send verification code, please check your email id '${emailId}'`);
                setTimeout(() => {
                    history.push(`verify?${demaildata}`);
                }, 3500);
                
                  //console.log("Done", demaildata);
          }else{
                //console.log("Not Done");
          }
        })
    }
  };

  return (
    <>
      <Container>
      <ToastContainer />
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            <Card className="bgsecondary border-0 mb-0 pb-3">
              <CardHeader className="bg-white pb-0">
                <Row>
                  <Col
                    xs="12"
                    style={{ textAlign: "right", margin: "0px" }}
                    className="mb-5"
                  >
                    <span style={{ width: "54px" }}>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "bold",
                          fontSize: "24px",
                          lineHeight: "32px",
                        }}
                      >
                        <img
                          alt="OurStoryz"
                          src={
                            require("assets/img/icons/common/Logomark.svg")
                              .default
                          }
                          style={{ padding: "5px" }}
                        />
                        OurStoryz
                      </h1>
                    </span>

                    <span
                      style={{
                        color: "#FC3158",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      <Link
                        className="font-weight-bold text-warning mt-5"
                        to="/"
                      >
                        Login
                      </Link>
                    </span>
                  </Col>
                </Row>

                {/* <div className=" mb-5">
                  <span style={{ float: "left", width: "54px" }}>
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/icons/common/Layer-1.svg").default
                      }
                    />
                  </span>
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "32px",
                    }}
                  >
                    {Cookies.get("name")
                      ? Cookies.get("name")
                      : "Organization Name"}
                  </h2>
                </div> */}

                {local && (
                  <>
                    <Row>
                      <Col>
                        <div className="loginMethod">Choose a login method</div>
                        <div className="loginEmail">
                          your email: {emailId == "" ? emailIdErr : emailId}
                        </div>
                        <div className="localLoginBtn" onClick={localMethod}>
                          <img
                            alt="OurStoryz"
                            src={
                              require("assets/img/icons/common/Logomark.svg")
                                .default
                            }
                          />
                          Create local login with password
                        </div>
                      </Col>
                    </Row>

                    <div
                      className="text-center text-muted bg-white mb-4"
                      style={{ borderBottom: "1px solid #979798" }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          padding: "0px 20px",
                          background: "#fff",
                          top: "10px",
                          position: "relative",
                          color: "#979798",
                        }}
                      >
                        OR
                      </span>
                    </div>

                    <div className="text-muted mb-4">
                      <small style={{ color: "#000", fontSize: "14px" }}>
                        Use your social login:
                      </small>
                    </div>
                    <div className="btn-wrapper text-center">
                      <Button
                        className="btn-neutral btn-icon appleLoginBtn"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        style={{ background: "#000", color: "#FDFDFD" }}
                      >
                        <span className="btn-inner--icon mr-1">
                          <img
                            alt="..."
                            src={
                              require("assets/img/icons/common/Vector.svg")
                                .default
                            }
                            style={{ width: "16px" }}
                          />
                        </span>
                        <span className="btn-inner--text">
                          Signup with Apple
                        </span>
                      </Button>

                      <SocialFacebookLogin />
                      <SocialGoogleLogin />
                    </div>
                    <div className="loginMethodFooter ">
                      By selecting an option I agree to our{" "}
                      <Link to="/"> Terms of Use </Link> and our{" "}
                      <Link to="/">Privacy Policy</Link>. You can adjust who you
                      share your information with while you set up your account.
                    </div>
                    <div className="loginMethodFooter loginMethodFooter1">
                      Already have an account? <Link to="">Sign in Here.</Link>
                    </div>
                    <div className="loginMethodFooter loginMethodFooter1">
                      Are you a vendor? <Link to=""> Go to Vendor Signup.</Link>
                    </div>
                  </>
                )}
              </CardHeader>

              {!local && (
                <CardBody className="bg-white pt-0">
                  <div className="loginMethod">Create a local login</div>
                  <div className="loginEmail">
                    your email: {emailId == "" ? emailIdErr : emailId}
                  </div>
                  <p className="logniMethodText">
                    You can create your own password. You can link this to
                    social logins later if necessary.
                  </p>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="loginFormNew"
                  >
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <label style={{ fontSize: "14px", fontWeight: "400" }}>
                        Password:
                      </label>
                      <InputGroup
                        className={`input-group-merge input-group-alternative passInput  ${
                          errors.password ? "errorborder" : ""
                        }`}
                      >
                        <input
                          className={`form-control`}
                          placeholder="type in password..."
                          type={passwordType}
                          name="password"
                          value={passval}
                          onChange={(e) => validatePassword(e.target.value)}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            onClick={togglePassword}
                            style={{ cursor: "pointer", color: "#000" }}
                          >
                            {passwordType === "text" ? (
                              <IoMdEye />
                            ) : (
                              <IoMdEyeOff />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <small className="error">
                        {errors?.password && errors.password.message}
                        {passErr != "" ? passErr : ""}
                      </small>

                      <div className="passReqwrapper">
                        <div className="passHead">Requirements</div>
                        <ul className="passReqList">
                          <li>
                            at least 8 characters in length <RxDotFilled />{" "}
                            Xxxxxxxx
                          </li>
                          <li className="active">
                            a min of uppercase and lowercase letters{" "}
                            <RxDotFilled /> kK
                          </li>
                          <li>
                            include at least one number <RxDotFilled /> 123
                          </li>
                          <li>
                            Include at least one special character{" "}
                            <RxDotFilled /> {`!@$)}#`}
                          </li>
                        </ul>
                      </div>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <label style={{ fontSize: "14px", fontWeight: "400" }}>
                        Retype your password:
                      </label>
                      <InputGroup
                        className={`input-group-merge input-group-alternative passInput  ${
                          errors.password ? "errorborder" : ""
                        }`}
                      >
                        <input
                          className={`form-control`}
                          placeholder="type in password..."
                          type={passwordType}
                          name="password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                          defaultValue={formpassword ? formpassword : ""}
                          value={repassval}
                          onChange={(e) => validateRePassword(e.target.value)}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            onClick={togglePassword}
                            style={{ cursor: "pointer", color: "#000" }}
                          >
                            {passwordType === "text" ? (
                              <IoMdEye />
                            ) : (
                              <IoMdEyeOff />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <small className="error">
                        {errors?.password && errors.password.message}
                        {repassErr != "" ? repassErr : ""}
                      </small>
                    </FormGroup>
                    <div className="text-center">
                      {emailId != "" ? (
                        <Button className="my-4" onClick={setPassword}>
                          Set Your Password
                        </Button>
                      ) : (
                        <small className="error">{emailIdErr}</small>
                      )}
                    </div>
                    <div className="backBtnWrapper">
                      <div className="pointer" onClick={localMethodBack}>
                        {" "}
                        <FaChevronLeft /> Back{" "}
                      </div>
                      <div className="pointer" onClick={CancelModalHandler}>
                        Cancel{" "}
                      </div>

                      <Modal
                        className="modal-dialog-centered addListModalWrapper"
                        isOpen={cancelModal}
                        toggle={CancelModalHandler}
                      >
                        <ModalBody>
                          <div className="modal-header border-bottom">
                            <img
                              alt="..."
                              src={
                                require("assets/img/Icon-Text-in-Circle.svg")
                                  .default
                              }
                              width="75px"
                              height="75px"
                              className="icon"
                            />
                            <div className="head">
                              Do you really want to exit?
                            </div>
                          </div>

                          <p>
                            Selecting “Cancel” will close and remove any data
                            you have entered.
                          </p>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="primary pinkBtn"
                            className="flex-grow-1"
                          >
                            Continue with setup
                          </Button>
                          <Button
                            className="btn-default cancelBtn"
                            onClick={CancelModalHandler}
                          >
                            Quit setup
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
}

export default Signup;
