import React, { useState, useEffect, useRef } from "react";
import FileStackApp from "../FileStackApp/FileStackAll";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { ReactSession } from "react-client-session";
import placeholde from "../../assets/img/placeholder.jpg";
import { useSelector } from "react-redux";
function Filemanager({ show, onHide, onClickimageUpdate, list, seletedImage }) {

  const inputRef = useRef();
  const [imgModalBoxfirst, setImgModalBoxfirst] = useState(show);
  const [dataFile, setDataFile] = useState({});
  const [imgModalBox, setImgModalBox] = useState(false);
  const [mapkey, setMapkey] = useState("");
  const intialValues = {
    photo_label: "",
    photo_caption: "",
    imaDatajson: [],
    img_address: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [cur_section, setCurSection] = React.useState({});

  const contact = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    setMapkey("");
  }, []);
  const [mapModal, setMapModal] = useState(false);
  const handelMapModal = () => {
    setMapModal(!mapModal);
  };
  useEffect(() => {
    let root = document.getElementsByTagName("html")[0];
    root.classList.add("overflowHidden");
    return () => {
      root.classList.remove("overflowHidden");
    };
  }, [imgModalBox]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());

    }
  };

  const setdata = (data) => {
    setImgModalBox(true);
    setDataFile(data);
    setFormValues({ ...formValues, ["imaDatajson"]: data });
    setMapkey("AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY");
    setImgModalBoxfirst(false);
  };

  const onEditChangePicture = (e) => {
    setImgModalBoxfirst(true);
    setImgModalBox(false);
  };

  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

  };

  useEffect(() => {

    if (Object.keys(formErrors).length === 0 && isSubmit) {

    }
  }, []);

  const handleSubmitInformation = () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    onClickimageUpdate(formValues);
    setMapkey("");
    onHide();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.photo_label) {
      errors.photo_label = "Title is required!";
    }

    if (!values.photo_caption) {
      errors.photo_caption = "Caption is required!";
    }

    return errors;
  };

  const selectFilebtn = () => {
    seletedImage(cur_section);
  };

  return (
    <div>
      {imgModalBoxfirst && (
        <div
          className={`modal fade mediaFileListModal ${imgModalBoxfirst ? "show" : ""
            }`}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Files</h1>
                <div className="btn-close" onClick={onHide}>
                  <svg
                    className="closeBtn"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="importImageListWrapper">
                  filestack
                  <FileStackApp setdata={setdata}>Import New</FileStackApp>
                  <div className="importedImageListWrapper">
                    <div className="importedImageListInner">
                      {list.map((x, i) => (
                        <>
                          <div
                            className={
                              x.id === cur_section.id
                                ? "importedImageList selected"
                                : "importedImageList"
                            }
                            key={Math.random()}
                            onClick={() => setCurSection(x)}
                          >
                            <div className="uploadedItem">
                              <div className="uploadedImg">
                                {x.mimetype === "video/mp4" ? (
                                  <>
                                    <video
                                      className="imageCropper"
                                      alt={x?.url}
                                      controls
                                    >
                                      <source
                                        src={x.url ? x.url : ""}
                                        type=""
                                      />
                                    </video>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={x.url ? x.url : placeholde}
                                      alt=""
                                      className="thum"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="filename">{x.filename}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="btn selectFilebtn" onClick={selectFilebtn}>
                  Select File
                </div>
                <div onClick={onHide} className="btn selectFilebtn whiteBg">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {imgModalBox && (
        <>
          <div
            className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""}`}
          >
            <form onSubmit={handleSubmitInformation}>
              <div className="closeIcon" onClick={() => onHide()}>
                <svg
                  className="closeBtn"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </div>
              <div className="mediaModalHeaderWrapper">
                <span className="mediaLogo">
                  <i className="fa fa-image mediaLogoIcon"></i>
                </span>{" "}
                Add Image Information
              </div>

              <div className="mediaImageWrapper">
                <div className="date">
                  {moment().format("MMMM Do YYYY, h:mm:ss a")}

                  {/* {imgDataUrl ? imageDate : ""} */}
                </div>
                {/* <h1>Video Type: {video}</h1> */}
                <div
                  className="imageCropperWrapper"
                  onClick={onEditChangePicture}
                >
                  {/* <div className="uploadImageFromLocalContent">
                    <input
                                        type="file"
                                        name="files"
                                        className="fileInput"
                                        accept="image/png, image/jpeg, video/*"
                                     onChange={onEditChangePicture}
                                    />
                    <i className="fa fa-plus faPlus"></i>
                  </div> */}

                  {dataFile[0]?.originalFile?.type === "video" ? (
                    <>
                      <video
                        className="imageCropper"
                        alt={dataFile[0]?.filename}
                        controls
                      >
                        <source
                          src={dataFile[0]?.url ? dataFile[0]?.url : ""}
                          type=""
                        />
                      </video>
                    </>
                  ) : (
                    <>
                      {dataFile[0]?.url ? (
                        <img
                          src={dataFile[0]?.url ? dataFile[0]?.url : ""}
                          alt={dataFile[0]?.filename}
                          className="imageCropper"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>

                {/* <div className="imageEditIcons">
                                <button className="button">
                                    <i className="fa fa-expand"></i>
                                </button>
                                <button className="button">
                                    <i className="fa fa-crop"></i>
                                </button>
                                <button className="button">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div> */}
              </div>

              <div className="mediaUserDetails">
                <div className="mediaUserImgWrapper">
                  <img
                    src="https://images.unsplash.com/photo-1684084514809-1637b070be43?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                    className="mediaUserImg"
                    alt=""
                  />
                </div>
                <div className="mediaUserContentWrapper">
                  <div className="mediaUserTitle mr-1">
                    {/* {Userfname} {Userlname} */}
                    {contact?.profiledetails?.first_name}{" "}
                    {contact?.profiledetails?.last_name}
                  </div>
                  <div className="mediaUserDesignation">
                    {contact?.profiledetails?.account_type}
                  </div>
                </div>
              </div>

              <div className="filenameWrapper">
                File name: <span>{dataFile[0]?.filename}</span>
                {/* File name: <span>{imgDataUrl ? imageName : ""}</span> */}
              </div>
              <div className="mediaFormWrapper">
                <div className="formBox">
                  <label className="form-label">Title</label>

                  <input
                    type="text"
                    className="form-control"
                    id="photo_label"
                    name="photo_label"
                    placeholder="Image Label"
                    onChange={handleChange}
                    value={formValues.photo_label}

                  // onChange={(e) => setPhotolabel(e.target.value)}
                  />

                  {/* Title: {alt_text} */}
                </div>
                <p>{formErrors.photo_label}</p>
                <div className="formBox">
                  <label className="form-label">Caption:</label>

                  <textarea
                    type="text"
                    className="form-control"
                    id="photo_caption"
                    name="photo_caption"
                    placeholder="photo caption"
                    onChange={handleChange}
                    value={formValues.photo_caption}

                  // value={photo_caption}
                  // onChange={(e) => setPhotocaption(e.target.value)}
                  ></textarea>
                </div>
                <p>{formErrors.photo_label}</p>
                <div className="formBox">
                  <label className="form-label">Location:</label>
                  <div className="locationMap locationgooglemap12">
                    <LoadScript
                      googleMapsApiKey={mapkey}
                      libraries={["places"]}
                    >
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceChanged}
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="gallerylocation"
                          placeholder="type in a name, or use the map..."
                        // value={gallerylocation}
                        // onChange={(e) => setGallerylocation(e.target.value)}
                        />
                      </StandaloneSearchBox>
                    </LoadScript>

                    <div className="map pointer" onClick={handelMapModal}>
                      <svg
                        className="diamondTrunImg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                      </svg>
                      Map
                    </div>
                  </div>
                </div>
                <div className="actionWrapper">
                  <button
                    className="btn confirmBtn"
                    onClick={() => handleSubmitInformation()}
                  >
                    Save Image Meta-Data
                  </button>
                  <button className="btn CancelBtn" onClick={() => onHide()}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="mediaOverlay"></div>
        </>
      )}

      <Modal
        isOpen={mapModal}
        toggle={handelMapModal}
        className="mapModalWrapper"
        centered
      >
        <ModalBody>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d112039.88568714322!2d77.22434559999999!3d28.652339199999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1677597775183!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            className="d-block"
          ></iframe>
        </ModalBody>
        <ModalFooter>
          <Button className="mapBtn" onClick={handelMapModal}>
            Direction
          </Button>
          <Button className="mapBtn" onClick={handelMapModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
    // message prop requires a string
  );
}
export default Filemanager;
