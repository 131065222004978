import React, { useState, useEffect } from "react";
import IconImg from "../../assets/img/setting.svg";
import { FormGroup, Row, Col, Button } from "reactstrap";
import computer from "../../assets/img/computer.svg";
import loctions from "../../assets/img/loctions.svg";
import computer1 from "../../assets/img/notifications_24px.svg";
import { informationService } from "../../_services/masters/information.service";
import computer3 from "../../assets/img/groups_black_24dp 1.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { timezoneService } from "../../_services/masters/timezone.service";
import ReactTooltip from "react-tooltip";
import { IoIosSettings } from "react-icons/io";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
function Setting() {
  let [isWebsite, setWebsite] = useState();
  let [isKeepsake, setKeepsake] = useState();
  let [isValue, setValue] = useState();
  let [isTodo, setNotifyTodo] = useState();
  let [isRSVP, setNotifyRSVP] = useState();
  let [isAlog, setNotifyAlog] = useState();
  let [isAwant, setNotifyAwant] = useState();
  let [isGuest, setNotifyGuest] = useState();
  let [isRSVPGuest, setNotifyRSVPGuest] = useState();
  let [isGuestEdit, setNotifyGuestEdit] = useState();
  let [isvalidPhone, setValidPhone] = useState();
  let [isValidEmail, setValidEmail] = useState();
  let [isValidQRCode, setValidQRCode] = useState();
  let [isValidWebsite, setValidWebsite] = useState();
  const [timezoneData, setTimezoneData] = useState();
  const [notificationModalacouno, setnotificationModalacouno] = useState(false);
  const [eventInfo, setEventInfo] = useState({ timevalue: "12" });
  const [datekey, setDataKey] = useState();
  const [timezone, setTimezone] = useState(1);
  const [clockformat, setClockformat] = useState(12);
  const [metric, setMetric] = useState("Metric");

  const timeZoneChange = async (e) => {
    setTimezone(e.target.value);
    saveTimeZoneChange(e.target.value);
  };
  const saveTimeZoneChange = async (val) => {
    let timeData = { time_zone: val };
    //alert(val)
    await informationService.createOrUpdaTimezone(timeData).then(
      (res) => {
        if (res.status === "success") {
          //handlegetTimeZone();
          toast.success("Updated successfully");
        } else {
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );
    informationService.acInformation().then((res) => {
      if (res.status === "success") {
        localStorage.setItem("userdata", JSON.stringify(res.data));
      }
    });
  };

  const clockformatChange = (e) => {
    setClockformat(e.target.value);
    let clockData = { clock_format: e.target.value };
    informationService.createOrUpdaClockformat(clockData).then(
      (res) => {
        if (res.status === "success") {
          //handlegetTimeZone();
          toast.success("Updated successfully");
        } else {
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );

    informationService.acInformation().then((res) => {
      if (res.status === "success") {
        localStorage.setItem("userdata", JSON.stringify(res.data));
      }
    });
  };

  const onChangeValue = (e) => {
    setMetric(e.target.value);
    let unitsData = { units: e.target.value };
    informationService.createOrUpdateUnits(unitsData).then(
      (res) => {
        if (res.status === "success") {
          //handlegetTimeZone();
          toast.success("Updated successfully");
        } else {
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );

    informationService.acInformation().then((res) => {
      if (res.status === "success") {
        localStorage.setItem("userdata", JSON.stringify(res.data));
      }
    });
  };

  const handleChange = (e) => {
    //alert(e.target.checked);
    setValue(e.target.checked);
    let checked = e.target.checked == true ? "yes" : "no";
    //console.log('checked!!!', checked);
    const checkedValue = e.target.value;
    // to get the checked name
    const checkedName = e.target.name;
    const newArray = {
      meta_tag: checkedName,
      meta_key: checkedValue,
      meta_value: checked,
    };

    informationService.createOrUpdatesetting(newArray).then(
      (res) => {
        if (res.status === "success" && res.message == "Update") {
          toast.success("Setting Updated successfully !!", "Account");
        } else if (res.status === "success" && res.message == "Save") {
          toast.success("Setting Save successfully !!", "Account");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
    handlegetsetting();
  };

  const handlegetsetting = () => {
    informationService.getsetting().then(
      (res) => {
        if (res.status === "success") {
          let AllData = res.data;

          const websiteArr = AllData.filter(
            (AllData) => AllData.meta_key === "Website is Public"
          );
          let webArrlan = websiteArr.length;
          let website =
            websiteArr[0].meta_value == "yes" && webArrlan != 0
              ? "checked"
              : "";
          const KeepsakeArr = AllData.filter(
            (AllData) => AllData.meta_key === "Keepsake Album is Public"
          );
          let keepArrlan = websiteArr.length;
          let Keepsake =
            KeepsakeArr[0].meta_value === "yes" && keepArrlan !== 0
              ? "checked"
              : "";

          const NTodoArr = AllData.filter(
            (AllData) =>
              AllData.meta_key === "Notify when new Todos are created"
          );
          let NTodolen = NTodoArr.length;
          let notifyTodo =
            NTodoArr[0].meta_value === "yes" && NTodolen !== 0 ? "checked" : "";

          const NRSVPArr = AllData.filter(
            (AllData) =>
              AllData.meta_key ===
              "Notify when a RSVP deadline is about to expire"
          );
          let NRSVPlen = NRSVPArr.length;
          let notifyRSVP =
            NRSVPArr[0].meta_value === "yes" && NRSVPlen != 0 ? "checked" : "";

          const NAlogArr = AllData.filter(
            (AllData) =>
              AllData.meta_key ===
              "Notify when another Admin logs into the system"
          );
          let NAloglen = NAlogArr.length;
          let notifyAlog =
            NAlogArr[0].meta_value === "yes" && NAloglen != +0 ? "checked" : "";

          const NAwantsArr = AllData.filter(
            (AllData) =>
              AllData.meta_key ===
              "Notify when another Admin wants to communicate"
          );
          let NAwantlen = NAwantsArr.length;
          let notifyAwant =
            NAwantsArr[0].meta_value === "yes" && NAwantlen != 0
              ? "checked"
              : "";

          const NAGuestArr = AllData.filter(
            (AllData) =>
              AllData.meta_key === "Notify when a Guest wants to communicate"
          );
          let NAGuestlen = NAGuestArr.length;
          let notifyGuest =
            NAGuestArr[0].meta_value === "yes" && NAGuestlen != 0
              ? "checked"
              : "";

          const NARSVPGuestArr = AllData.filter(
            (AllData) =>
              AllData.meta_key === "Notify when a Guest RSVPs for an event"
          );
          let NARSVPGuestlen = NARSVPGuestArr.length;
          let notifyRSVPGuest =
            NARSVPGuestArr[0].meta_value === "yes" && NARSVPGuestlen !== 0
              ? "checked"
              : "";

          const NAGuestEditArr = AllData.filter(
            (AllData) =>
              AllData.meta_key === "Notify when Guest edits their profile"
          );
          let NAGuestEditlen = NAGuestEditArr.length;
          let NAGuestEdit =
            NAGuestEditArr[0].meta_value === "yes" && NAGuestEditlen != 0
              ? "checked"
              : "";

          const ValidPhoneArr = AllData.filter(
            (AllData) => AllData.meta_key === "Use Phone Validation"
          );
          let ValidPhonelen = ValidPhoneArr.length;
          let ValidPhone =
            ValidPhoneArr[0].meta_value === "yes" && ValidPhonelen != 0
              ? "checked"
              : "";

          const ValidEmailArr = AllData.filter(
            (AllData) => AllData.meta_key === "Use Email Validation"
          );
          let ValidEmaillen = ValidEmailArr.length;
          let ValidEmail =
            ValidEmailArr[0].meta_value === "yes" && ValidEmaillen != +0
              ? "checked"
              : "";

          const ValidQRCodeArr = AllData.filter(
            (AllData) => AllData.meta_key === "Use QR Code"
          );
          let ValidQRCodelen = ValidQRCodeArr.length;
          let ValidQRCode =
            ValidQRCodeArr[0].meta_value === "yes" && ValidQRCodelen !== 0
              ? "checked"
              : "";

          const ValidWebsiteArr = AllData.filter(
            (AllData) => AllData.meta_key === "Through the website"
          );
          let ValidWebsitelen = ValidWebsiteArr.length;
          let ValidWebsite =
            ValidWebsiteArr[0].meta_value === "yes" && ValidWebsitelen !== 0
              ? "checked"
              : "";
          //

          setKeepsake(Keepsake);
          setWebsite(website);
          setNotifyTodo(notifyTodo);
          setNotifyRSVP(notifyRSVP);
          setNotifyAlog(notifyAlog);
          setNotifyAwant(notifyAwant);
          setNotifyGuest(notifyGuest);
          setNotifyRSVPGuest(notifyRSVPGuest);
          setNotifyGuestEdit(NAGuestEdit);
          setValidPhone(ValidPhone);
          setValidEmail(ValidEmail);
          setValidQRCode(ValidQRCode);
          setValidWebsite(ValidWebsite);
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };

  const handlegetTimeZone = () => {
    timezoneService.timezoneList().then(
      (res) => {
        if (res.status === "success") {
          setTimezoneData(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const getTimeZone = () => {
    let userdata = localStorage.getItem("userdata");
    userdata = JSON.parse(userdata);
    setTimezone(userdata.time_zone);
    setClockformat(userdata.clock_format);
    setMetric(userdata.units);
  };

  window.onload = function () {
    //alert('jj')
    informationService.acInformation().then((res) => {
      if (res.status === "success") {
        localStorage.setItem("userdata", JSON.stringify(res.data));
      }
    });
    getTimeZone();
  };

  useEffect(() => {
    handlegetsetting();
  }, [isValue]);

  useEffect(() => {
    handlegetTimeZone();
  }, []);

  useEffect(() => {
    getTimeZone();
  }, []);

  const onSelect = (e) => {

    const selectedIndex = e.target.options.selectedIndex;
    setDataKey(e.target.options[selectedIndex].getAttribute("data-key"));
    setEventInfo({ ...eventInfo, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* <Allheader title="Settings" imgScr={IconImg} arw /> */}
      <CombinedGuestPoolHeader title="Settings" imgScr={IconImg} arw />
      <div className="organizerWrapperMain">
        {/* <Row> */}
        <form>
          {/* <div className="col"> */}
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Global Settings</h4>
              <div className="ml-auto">
                <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                  />
                </span>
                <ReactTooltip />
              </div>
            </div>
            <div className="organizationPara p-4 border-bottom">
              This page shows the settings fo the entire organization.
              <span className="fontWeight500">
                These can only be edited by the account owner.
              </span>
            </div>
            {/* <h2 className="py-4 border-bottom m-0 headingNew">
              Global Settings
            </h2> */}
            {/* <small className="py-4 border-bottom">
              This page shows the settings fo the entire organization. These can
              only be edited by the account owner.
            </small> */}
            <div className="py-4 p12-right p12-left">
              <Button className="saveChangesBtn ml-auto">
                <IoIosSettings className="icon" /> Save Changes
              </Button>
            </div>
            <Row className="position-relative z-1 globalSettingListWrapper">
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img src={computer} alt="" />
                  Storyz
                </div>
                <div className="cardBox">
                  <p className="mb-0">
                    During and after the Event, visitors to the website can
                    access your “storyz” - your events, uploads, and other
                    information.
                  </p>

                  <div className="websitenInPublicListWrapper">
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Website is Public
                        <input
                          defaultChecked={isWebsite}
                          type="checkbox"
                          name="storyz_methods"
                          value="Website is Public"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Keepsake Album is Public
                        <input
                          defaultChecked={isKeepsake}
                          type="checkbox"
                          name="storyz_methods"
                          value="Keepsake Album is Public"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="basicinformation">
                  <img src={computer1} alt="" /> Admin Notifications
                </div>
                <div className="cardBox">
                  <div className="websitenInPublicListWrapper">
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when new Todos are created
                        <input
                          type="checkbox"
                          defaultChecked={isTodo}
                          name="notifications_methods"
                          value="Notify when new Todos are created"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when a RSVP deadline is about to expire
                        <input
                          defaultChecked={isRSVP}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when a RSVP deadline is about to expire"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when another Admin logs into the system
                        <input
                          defaultChecked={isAlog}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when another Admin logs into the system"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when another Admin wants to communicate
                        <input
                          defaultChecked={isAwant}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when another Admin wants to communicate"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when a Guest wants to communicate
                        <input
                          defaultChecked={isGuest}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when a Guest wants to communicate"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when a Guest RSVPs for an event
                        <input
                          defaultChecked={isRSVPGuest}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when a Guest RSVPs for an event"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Notify when Guest edits their profile
                        <input
                          defaultChecked={isGuestEdit}
                          type="checkbox"
                          name="notifications_methods"
                          value="Notify when Guest edits their profile"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img src={loctions} alt="" />
                  Units
                </div>
                <div className="cardBox">
                  <p className="mb-0">
                    Units are defined for the entire organization.
                  </p>

                  <Row className="settingUnitsWrapper">
                    <Col>
                      <div>
                        <FormGroup className="formBox">
                          <div className="inlineFormBox flex-wrap">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                type="radio"
                                value="Metric"
                                checked={metric === "Metric"}
                                onChange={(e) => onChangeValue(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                              >
                                Metric (meters)
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                type="radio"
                                value="English"
                                checked={metric === "English"}
                                onChange={(e) => onChangeValue(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadioInline2"
                              >
                                English (feet)
                              </label>
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup className="formBox">
                          <label
                            className="form-control-label mt-1"
                            htmlFor="exampleFormControlSelect3"
                          >
                            Timezone for the Organization:
                          </label>
                          <select
                            value={timezone}
                            className="form-control"
                            id="sel1"
                            name="timezone"
                            onChange={(e) => timeZoneChange(e)}
                          >
                            {timezoneData?.map((post, index) => (
                              <option
                                key={index}
                                data-key={post.abbr}
                                value={post.id}
                              >
                                {post.text}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                        <FormGroup className="formBox">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                          >
                            Clock Format
                          </label>
                          <select
                            value={clockformat}
                            className="form-control"
                            id="sel1"
                            name="timevalue"
                            onChange={(e) => clockformatChange(e)}
                          >
                            <option value="12">12-Hour</option>
                            <option value="24">24-Hour</option>
                          </select>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="basicinformation">
                  <img src={computer3} alt="" />
                  Guest Validation Methods
                </div>
                <div className="cardBox">
                  <div className="websitenInPublicListWrapper">
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Use Phone Validation
                        <input
                          defaultChecked={isvalidPhone}
                          type="checkbox"
                          name="validation_methods"
                          value="Use Phone Validation"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Use Email Validation
                        <input
                          defaultChecked={isValidEmail}
                          type="checkbox"
                          name="validation_methods"
                          value="Use Email Validation"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Use QR Code
                        <input
                          defaultChecked={isValidQRCode}
                          type="checkbox"
                          name="validation_methods"
                          value="Use QR Code"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Through the website
                        <input
                          defaultChecked={isValidWebsite}
                          type="checkbox"
                          name="validation_methods"
                          value="Through the website"
                          onClick={(e) => handleChange(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
        </form>
        {/* </Row> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default Setting;
