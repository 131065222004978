import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { combinedService } from "_services/masters/information.combined";
import { createPortal } from "react-dom";
const GuestEditDropdownpoolmenu = ({
  guestListOption,
  data,
  guest_id,
  setGuestId,
  setUserData,
  storyz_id,
  setguestDelete,
}) => {
  const params = useParams();
  const [selectMenu, setSelectMenu] = useState(false);
  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });

  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();
  // console.log("useRef", selectMenuRef);
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);

    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();

    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };
  useEffect(() => {
    let handler = (e) => {
      if (
        !selectMenuRef.current.contains(e.target) &&
        !selectMenuList?.current?.contains(e.target)
      ) {
        setSelectMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <>
      <div
        className="dropdownBoxWrapperPort simpletDropdownWrapper"
        ref={selectMenuRef}
      >
        <div
          className="dropdownTriggered"
          onClick={dropdownHandle}
          ref={buttonHeight}
        >
          <button className="btn dottedBtn" type="button">
            <BsThreeDots />
          </button>
        </div>
        {selectMenu &&
          createPortal(
            <div
              ref={selectMenuList}
              style={{ top: property.top, left: property.left }}
              className={`dropdownBoxMenuWrapperPort ${selectMenu ? "active" : ""
                }`}
            >
              <div className="dropdownBoxMenuInner">
                <ul className="dropdownMenuListWrapper">
                  {guestListOption.map((option, i) => (
                    <li
                      className={`menuItem ${option.disabled
                          ? "disabled"
                          : (option.title === "Remove from Storyz and Events" &&
                            data.storyz_count === 0) ||
                            (option.title === "Delete Admin Account" &&
                              data.account_type === "Guest")
                            ? "disabled"
                            : ""
                        }`}
                      key={i}
                      onClick={() => {
                        option.handleClick(data);
                      }}
                    >
                      {option.link ? (
                        <>
                          <Link
                            to={
                              option?.type === "Guest"
                                ? `${option.link}/${data?.id}?type=guests`
                                : `${option.link}/${data?.guest_id}`
                            }
                          >
                            <span className="icon">
                              <img src={option.img} alt={option.title} />
                            </span>
                            {option.title}
                          </Link>
                        </>
                      ) : (
                        <>
                          <div>
                            <span className="icon">
                              <img src={option.img} alt={option.title} />
                            </span>
                            {option.title}
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>,
            document.body
          )}
      </div>

      {/* <div
        className="dropdownBoxWrapper simpletDropdownWrapper"
        ref={selectMenuRef}
      >
        <div
          className="dropdownTriggered"
          onClick={dropdownHandle}
        >
          <button className="btn dottedBtn" type="button">
            <BsThreeDots />
          </button>
        </div>
        <div
          className={`dropdownBoxMenuWrapper ${selectMenu ? "active" : ""}`}
        >
          <div className="dropdownBoxMenuInner">
            <ul className="dropdownMenuListWrapper">
              {guestListOption.map((option, i) => (
                <li
                  className={`menuItem ${
                    option.disabled
                      ? "disabled"
                      : (option.title === "Remove from Storyz and Events" &&
                          data.storyz_count === 0) ||
                        (option.title === "Delete Admin Account" &&
                          data.account_type === "Guest")
                      ? "disabled"
                      : ""
                  }`}
                  key={i}
                  onClick={() => {
                    option.handleClick(data);
                  }}
                >
                  {option.link ? (
                    <>

                      <Link to={`${option.link}/${data?.id}`}>
                        <span className="icon">
                          <img src={option.img} alt={option.title} />
                        </span>
                        {option.title}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link>
                        <span className="icon">
                          <img src={option.img} alt={option.title} />
                        </span>
                        {option.title}
                      </Link>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

      </div> */}
    </>
  );
};

export default GuestEditDropdownpoolmenu;
