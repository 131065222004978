import React from "react";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import { userService } from "../../_services/login/user.service";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import { userAdminData } from "store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { informationService } from "../../_services/masters/information.service";
function SocialGoogleLogin() {
    const history = useHistory();
    const dispatch = useDispatch();
    const admindata = useSelector((state) => {
        return state.userAdmin.listData;
    });

    function userdetail() {
        informationService.acInformation().then(
            (res) => {
                if (res.status === "success") {
                    localStorage.setItem("userdata", JSON.stringify(res.data));
                    dispatch({ type: actionTypes.USER_INFO, payload: res.data });
                    history.push("/admin/organization");
                } else {
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("userdata");
                    history.push("/");
                }
            },
            (error) => {
                localStorage.removeItem("userdata");
                localStorage.removeItem("userDetails");
                history.push("/");
            }
        );

        informationService.allMedia().then(
            (res) => {
                if (res.status === "success") {
                    dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
                } else {
                    console.log("error");
                }
            },
            (error) => {
                console.log("error");
            }
        );
    }
    const responseGoogle = (response) => {
        console.log(response);
        if (response.error) {
            console.log(response.details);
        } else {
            userService.scoiallogin(response.profileObj).then(
                (res) => {
                    if (res.status == "success") {
                        localStorage.setItem("userDetails", JSON.stringify(res.data));
                        var retrievedObject = JSON.parse(
                            localStorage.getItem("userDetails")
                        );
                        if (retrievedObject.name !== Cookies.get("name")) {
                            Cookies.set("name", retrievedObject.name);
                        }
                        if (res.data.account_type === "Super Admin") {
                            dispatch({
                                type: actionTypes.CLEAR_USER_ADMIN_INFO,
                                payload: "",
                            });

                            let to = { token: res.data.token };
                            localStorage.setItem("userAdmintoken", JSON.stringify(to));
                            const value = {
                                ...admindata,
                                profileAddmindetails: res.data,
                            };
                            dispatch(userAdminData(value));
                            //dispatch({ type: actionTypes.USER_ADMIN, payload: res.data });
                            history.push("/organization-list");
                        } else {
                            userdetail();
                        }
                    } else if (res.status == "otp_sent_successfully") {
                        localStorage.setItem(
                            "twoStepVerification",
                            JSON.stringify(res.data)
                        );
                        window.location.assign("/two-factor-authentication");
                    } else if (res.status == "error") {
                        localStorage.removeItem("userDetails");
                        toast.error(res.message, "Login");
                    } else {
                        localStorage.removeItem("userDetails");
                        toast.error("Please enter valid username and password !!", "Login");
                    }
                },
                (error) => {
                    localStorage.removeItem("userDetails");
                    toast.error("Invalid Credetials !!", "Login");
                }
            );

            //localStorage.setItem("userDetails", JSON.stringify(response.profileObj));
        }
    };
    return (
        <>
            <GoogleLogin
                clientId="1063001099319-32uau4cc70kvrci1pt4s4u3ektcqovkk.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                className="btn-neutral btn-icon googlelogin"
                style={{ color: "#1F1F1F" }}
            />
        </>
    );
}

export default SocialGoogleLogin;
