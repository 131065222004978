import React, { useState, useEffect, useRef } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import { GuestEventWrapper } from "../UserGuestList/style";
import ChatIcon from "./../../../assets/img/message-icon.svg";
import SendIcon from "./../../../assets/img/sendIcon.svg";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import { useSelector } from "react-redux";
const EventMessagingList = ({ args }) => {
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef();
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let history = useHistory();
  const [chatModal, setChatModal] = useState(false);
  const [chatwithGuestModal, setChatwithGuestModal] = useState(false);
  const [endChatModal, setEndChatModal] = useState(false);
  const [savedChatModal, setSavedChatModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const handelChatModal = () => {
    setChatModal(!chatModal);
  };
  const handelEndChatModal = () => {
    setEndChatModal(!endChatModal);
  };
  const handelSavedChatModal = () => {
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
      setEndChatModal(false);
      setSavedChatModal(true);
    }, "1000");
  };

  const handelCloseSavedChatModal = () => {
    setSavedChatModal(false);
    setChatModal(false);
    setChatwithGuestModal(false);
  };

  const handelChatwithGuestModal = () => {
    setChatwithGuestModal(!chatwithGuestModal);
  };

  const guestListOption = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Account Owner",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Client",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Lorem Ipas",
      type: "Admin",
      handelClick: handelChatwithGuestModal,
    },
  ];
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {common?.singleEvent?.event_name
                ? common?.singleEvent?.event_name
                : ""}{" "}
              : Send Message to Guests
            </div>
            {/* <div className="d-flex align-items-center">
              <div className="imageeventim234">
                <span>Page Name</span>
              </div>
              <span
                className="ml-2"
                data-tip="Tooltip message will come here....."
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
            </div> */}
          </div>
          <div className="border-bottom organizationPara">
            This page lets you view and create a todo list associated with this
            specific Storyz.
            <br />
            To see the complete list of todos, go to the
            <Link
              className="grayColor ml-1"
              onClick={() => {
                history.push(`/admin/storyz/${sid}`);
              }}
            >
              Storyz view
            </Link>{" "}
            or
            <Link
              className="grayColor ml-1"
              onClick={() => {
                history.push(`/admin/organization`);
              }}
            >
              Organization view.
            </Link>
          </div>
          <div className="p-4 d-flex">
            <div className="guestUserWrap ml-auto">
              <Link to="/admin/organization-message" className="exportWrap">
                <img
                  alt="Organization Name"
                  src={require("assets/img/editfieldicons.svg").default}
                  height={24}
                />
                <div>Admin Messages</div>
              </Link>
              <Link to="/admin/storyz-message" className="exportWrap">
                <img
                  alt="Organization Name"
                  src={require("assets/img/aour-storyz.svg").default}
                  height={24}
                />
                <div>Storyz Messages</div>
              </Link>
            </div>
          </div>
          <div className="mesageBoxWrapper">
            <div className="fontSize16 fontWeight300 pb-3 border-bottom mb-3">
              Select an guest to see your ongoing discussions, or start a new
              chat.
            </div>
            <div className="guestRadioMessageWraper">
              <div className="inlineFormBox flex-wrap">
                <div className="form-check form-check-inline mr-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    All Guests (Broadcast)
                  </label>
                </div>
                <div className="form-check form-check-inline mr-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Selected Guest(s)
                  </label>
                </div>
              </div>
            </div>
            <div className="dropdownBoxWrapper" ref={selectMenuRef}>
              <div className="dropdownBoxMenuWrapper position-relative active">
                <div className="dropdownBoxMenuInner">
                  <div className="dropdownSearchWrapper">
                    <input
                      type="text"
                      placeholder="type to search..."
                      className="form-control"
                      value={inputValue}
                      onChange={(e) =>
                        setInputValue(
                          e.target.value.toLowerCase().replace(/^\s+/g, "")
                        )
                      }
                    />
                  </div>
                  <ul className="dropdownMenuListWrapper">
                    {guestListOption
                      .filter(
                        (option) =>
                          option.title.toLowerCase().includes(inputValue) ||
                          option.type.toLowerCase().includes(inputValue)
                      )
                      .map((option, i) => (
                        <li
                          className="menuItem"
                          key={i}
                          onClick={option.handelClick}
                        >
                          <div className="guestList">
                            <div className="guestUserDetails">
                              <img
                                src={option.guest_img}
                                alt={option.title}
                                className="Avatar"
                              />
                              {option.title}
                            </div>
                            <div className="guestType">{option.type}</div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={chatModal}
            toggle={handelChatModal}
            {...args}
            className="addSupportTicketModalWrapper"
          >
            <ModalHeader
              className="fontSize20 whiteColor align-items-center"
              toggle={handelChatModal}
            >
              Broadcast to All Guests
            </ModalHeader>
            <ModalBody className="bg4">
              <Row>
                <Col>
                  <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                    <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                      <img src={ChatIcon} alt="" className="mr-2" />
                      Chat Header
                    </div>
                    <div
                      className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                      onClick={handelEndChatModal}
                    >
                      end chat
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="form-check mr-3 pl-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                        checked
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        All Members
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        Lead Member
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2 text-right">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                      <input
                        type="email"
                        className="form-control text-right sendBtnInput"
                        id="exampleFormControlInput1"
                        placeholder="This is chat text"
                      />
                      <Button type="button" className="sendBtn">
                        <img
                          src={SendIcon}
                          alt=""
                          width={24}
                          height={24}
                          className="sendBtnIcon"
                        />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={endChatModal}
            toggle={handelEndChatModal}
            {...args}
            className="saveChatTemplateModal"
          >
            <ModalBody>
              <div className="d-flex align-items-center pb-2 mb-3 border-bottom fontSize20 blackColor">
                <img
                  alt="Organization Name"
                  src={require("assets/img/circle-chat-icon.svg").default}
                  className="m12-right"
                  width={88}
                  height={88}
                />
                Save Chat Transcript
              </div>
              <p className="mb-0 blackColor">
                Choose where to save the transcript. The transcript is a simple
                text file. Images and attachments will not be saved.
              </p>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 blueBtn rounded"
                onClick={handelSavedChatModal}
              >
                Email Transcript
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handelEndChatModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={savedChatModal}
            toggle={handelSavedChatModal}
            {...args}
            className="savedChatModal"
            backdrop={false}
          >
            <ModalBody>
              <div className="align-items-center pb-2 mb-4 border-bottom fontSize20 blackColor d-flex flex-column">
                <img
                  alt="Organization Name"
                  src={require("assets/img/circle-saved-chat-icon.svg").default}
                  className="m12-bottom"
                  width={88}
                  height={88}
                />
                Chat Transcript Saved
              </div>
              <p className="mb-0 blackColor">
                Transcript was saved to your email.
              </p>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 blueBtn rounded w-100"
                onClick={handelCloseSavedChatModal}
              >
                Continue
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={loadingModal} {...args} className="loadingModal">
            <ModalBody className="d-flex align-items-center justify-content-center">
              <Row>
                <Col>
                  <div className="circleAnimation1"></div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={chatwithGuestModal}
            toggle={handelChatwithGuestModal}
            {...args}
            className="addSupportTicketModalWrapper"
          >
            <ModalHeader
              className="fontSize20 whiteColor align-items-center"
              toggle={handelChatwithGuestModal}
            >
              Chat with a Guest
            </ModalHeader>
            <ModalBody className="bg4">
              <Row>
                <Col>
                  <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                    <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                      <img src={ChatIcon} alt="" className="mr-2" />
                      Chat Header
                    </div>
                    <div
                      className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                      onClick={handelEndChatModal}
                    >
                      end chat
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="form-check mr-3 pl-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                        checked
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        All Members
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        Lead Member
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2 text-right">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                      <input
                        type="email"
                        className="form-control text-right sendBtnInput"
                        id="exampleFormControlInput1"
                        placeholder="This is chat text"
                      />
                      <Button type="button" className="sendBtn">
                        <img
                          src={SendIcon}
                          alt=""
                          width={24}
                          height={24}
                          className="sendBtnIcon"
                        />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </Eventstyle>
    </>
  );
};

export default EventMessagingList;
