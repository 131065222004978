import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { eventsService } from "../../../_services/masters/events.service";
import { useForm } from "react-hook-form";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { GuestEventWrapper } from "../UserGuestList/style";
import { BsFillChatDotsFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAccountCircle } from "react-icons/md";
import Allheader from "components/Headers/AllheaderEvent";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import GroupMemberDropdown from "components/Dropdown/GroupMemberDropdown";
import GuestGroupDropdown from "components/Dropdown/GuestGroupDropdown";
import GuestGroupImg1 from "./../../../assets/img/guestGroupImg1.jpg";
import GuestGroupImg2 from "./../../../assets/img/guestGroupImg2.jpg";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import Preview from "./../../../assets/img/computer.svg";
import SendInvites from "./../../../assets/img/sendInvites.jpg";
import SendInvites1 from "./../../../assets/img/sendInvites1.jpg";
import SendInvites2 from "./../../../assets/img/sendInvites2.jpg";
import HornIcon from "./../../../assets/img/hornIcon.svg";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaHandsHelping } from "react-icons/fa";
import ThemeBg from "../../../assets/img/modalThemeBg.png";
import Thememap from "../../../assets/img/thememap.png";
import AppStore from "../../../assets/img/app-store-download.png";
import GooglePlay from "../../../assets/img/google-play-download.png";
import { IoMdClose } from "react-icons/io";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";
import DummyImg from "../../../assets/img/dummyImg.svg";
import Resend from "../../../assets/img/inviteResend.svg";
import RSVPDeadline from "../../../assets/img/infoRedIcon.svg";
import Filemanager from "../../CommonMediaModal/Filemanager";

const AddInviteRSVPsEmpty = ({ ...args }) => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const params = useParams();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const contact = useSelector((state) => {
    return state.profile;
  });

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  });
  const [allimage, setAllimage] = useState([]);

  let event_name = localStorage.getItem("todo_event_name");
  let history = useHistory();
  const [addGuestGroupDropdown, setAddGuestGroupDropdown] = useState(false);
  const [eventItems, setEventItems] = useState();
  const [guestGroupModal, setGuestGroupModal] = useState();
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [inviteStatusModal, setInviteStatusModal] = useState(false);
  const [isSendEmail, setisSendEmail] = useState(1);
  const [is_invite_id, setInviteID] = useState(params.id);
  const [inviteTitle, setInviteTitle] = useState("");
  const [inviteDescription, setInviteDescription] = useState("");
  const [inviteTitleErr, setInviteTitleErr] = useState(
    "This field is required"
  );
  const [inviteDescriptionErr, setInviteDescriptionErr] = useState(
    "This field is required"
  );
  const [review, setReview] = useState(false);
  const [rsvpLoading, setRsvpLoading] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [rsvpDeadline, setRsvpDeadline] = useState(false);
  const [invite_status, setInviteStatus] = useState(0);
  const [invite_updated_at, setInviteUpdatedAT] = useState(new Date());
  const [is_send, setisSend] = useState(0);
  const [rsvp_deadline, setRSVP_Deadline] = useState(0);
  const [rsvp_remaining, setRSVP_Remaining] = useState(0);
  const [eventname, setEventName] = useState("");
  const [eventenddate, setEventStartDate] = useState(new Date());
  const [eventstartdate, setEventStartEnd] = useState(new Date());
  const [event_location, setEventLocation] = useState("");
  const [event_img, setEventImg] = useState("");
  const [deleteData, setDeleteData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [imgJsonData, setImgJsonData] = useState([]);
  const [checkimg, setCheckimg] = useState(1);

  //const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const percentage = 0;
  const eventUrl = `/admin/event-about/${params.sid}/${params.eid}?type=event`;

  //console.log("is_invite_id", is_invite_id)
 //console.log("params.id", params.id);
 const gotoEvent = () => {
    //eventUrl
    window.open(eventUrl, "_blank");
  };

  const handleClickimag = () => {
    setModalShow(false);
  };

  const seletedImageClike = (e) => {
    //alert('seletedImageClike');
    setImgDa(e.url);
    setImgData2(e.url);
    setImgData2Err("");
    setModalShow(false);
    let jsonData = JSON.parse(e.img_json);
    jsonData = JSON.parse(JSON.stringify(jsonData));
    e["imaDatajson"] = [jsonData];
    delete e.img_json;
    setImgJsonData(JSON.stringify(e));
    //console.log("jsonData", e);
    //setImgJsonData(null);
  };

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    setImgJsonData(JSON.stringify(e));
    setImgDa(jsonData[0].url);
    setImgData2(jsonData[0].url);
    setImgData2Err("");
  };

  const handleDeleteGuestUser = () => {
    setDeleteData( params.id === undefined ? is_invite_id : params.id);
    userGuestService.guestInvitedetails( params.id === undefined ? is_invite_id : params.id).then(
      (res) => {
        if (res.status === "success") {
          //if (params.id !== undefined) {
            setInviteTitle(res.data?.invite_title);
            setInviteDescription(res.data?.invite_description);
            setInviteStatus(res.data?.invite_status);
            setInviteUpdatedAT(res.data?.updated_at);
            setisSend(res.data?.is_send);
            setEventName(res.data?.event_name);
            setEventStartDate(res.data?.event_start);
            setEventStartEnd(res.data?.event_end);
            setEventImg(res.data?.event_img);
            setEventLocation(res.data?.event_location);
            let ev_last_date = new Date(res.data?.rsvp_deadline);
            let today_date = new Date();
            if (today_date >= ev_last_date) {
              setRSVP_Deadline(1);
            } else {
              setRSVP_Deadline(0);
            }
            setisSendEmail(Number(res.data?.is_send));
            setDeleteGuestUser(true);
          //}
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const [inviteTemplate, setInviteTemplate] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  //   const [eventList, seteventList] = React.useState([]);
  const [allValues, setAllValues] = useState({
    invite_title: "",
    invite_description: "",
    include_guest_app_download: true,
    include_location: true,
    include_qr_code: true,
    include_web_link: true,
    include_guest: true,
    include_organizer_contact: true,
    include_social_networks: true,
    send_in_email: true,
    send_in_sms: true,
  });

  // Start Delete

  const handleDelGuestUser = () => {
    userGuestService.guestInvitedelete(params.id === undefined ? is_invite_id : params.id).then((res) => {
      if (res.status === "success") {
        //datatolist();
        //setDeleteGuestGroup(!deleteGuestGroup);
        setDeleteGuestUser(false);
        toast.success("Delete successfully");
        setTimeout(() => {
          window.location.href = `/admin/manage-invites/${params.sid}/${params.eid}`;
        }, 1000);
      } else {
        toast.error("Something went wrong !!");
      }
    });
  };

  //End Delete

  const handleChange = (event) => {
    if (event.target.value === "" && event.target.name === "invite_title") {
      setInviteTitleErr("This field is required");
    } else {
      setInviteTitleErr("");
    }
    if (
      event.target.value === "" &&
      event.target.name === "invite_description"
    ) {
      setInviteDescriptionErr("This field is required");
    } else {
      setInviteDescriptionErr("");
    }
    setAllValues({ ...allValues, [event.target.name]: event.target.value });
  };
  const handleChange1 = (event) => {
    if (event.target.checked) {
      setAllValues({ ...allValues, [event.target.name]: true });
    } else {
      setAllValues({ ...allValues, [event.target.name]: false });
    }
  };

  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };
  const handelInviteStatusModal = () => {
    setInviteStatusModal(!inviteStatusModal);
  };
  const [imgData2, setImgData2] = useState();
  const [imgData2Err, setImgData2Err] = useState("please select cover image");
  const [imgDa, setImgDa] = useState(null);
  const [userguestList, setUserguestList] = React.useState([]);
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const getAllData = (id) => {
    //alert(is_invite_id)

    userGuestService.guestInvitedetails(id).then(
      (res) => {
        if (res.status === "success") {
          if (params.id !== undefined || id !== undefined) {
            //console.log("res.data", res.data);
            setInviteTitle(res.data?.invite_title);
            localStorage.setItem("inviteName", res.data?.invite_title);
            setInviteDescription(res.data?.invite_description);
            setInviteStatus(res.data?.invite_status);
            setInviteUpdatedAT(res.data?.updated_at);
            setisSend(res.data?.is_send);
            setEventName(res.data?.event_name);
            setEventStartDate(res.data?.event_start);
            setEventStartEnd(res.data?.event_end);
            setEventImg(res.data?.event_img);
            setEventLocation(res.data?.event_location);
            setInviteTitleErr("");
            setInviteDescriptionErr("");
            let ev_last_date = new Date(res.data?.rsvp_deadline);
            let today_date = new Date();
            const date1 = today_date;
            const date2 = ev_last_date;
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            setRSVP_Remaining(diffDays);
            //console.log(diffDays + " days");
            //console.log("Event Date ev_last_date -->  ", ev_last_date);
            //console.log("Event Date today_date -->  ", today_date);
            if (today_date >= ev_last_date) {
              setRSVP_Deadline(1);
              setRSVP_Remaining(0);
              //console.log("Event Date  1 -->  ", 1);
            } else {
              //console.log("Event Date 0  -->  ", 0);
              setRSVP_Deadline(0);
              //setRSVP_Remaining(diffDays);
             
            }

            //setInviteID(params.id);
            setInviteID(id)
            setisSendEmail(Number(res.data?.is_send));
            setValue("invite_title", res.data?.invite_title);
            //setAllValues({ ...allValues, ["invite_title"]: res.data?.invite_title })

            setValue("invite_description", res.data?.invite_description);
            setValue(
              "include_guest_app_download",
              res.data?.include_guest_app_download === 1 ? true : false
            );
            setValue(
              "include_location",
              res.data?.include_location === 1 ? true : false
            );
            setValue(
              "include_qr_code",
              res.data?.include_qr_code === 1 ? true : false
            );
            setValue(
              "include_web_link",
              res.data?.include_web_link === 1 ? true : false
            );
            setValue(
              "include_guest",
              res.data?.include_guest === 1 ? true : false
            );
            setValue(
              "include_organizer_contact",
              res.data?.include_organizer_contact === 1 ? true : false
            );
            setValue(
              "include_social_networks",
              res.data?.include_social_networks === 1 ? true : false
            );
            setValue(
              "dont_send_to_rsvped",
              res.data?.dont_send_to_rsvped === 1 ? true : false
            );
            setValue(
              "send_to_all_events_in_story",
              res.data?.send_to_all_events_in_story === 1 ? true : false
            );
            setValue(
              "send_in_email",
              res.data?.send_in_email === 1 ? true : false
            );
            setValue("send_in_sms", res.data?.send_in_sms === 1 ? true : false);
            setValue(
              "generate_printable_pdf",
              res.data?.generate_printable_pdf === 1 ? true : false
            );
            setImgData2(res.data.cover_image);
            setImgData2Err("");
          }
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );

    const eid = { event_id: params.eid };
    eventsService.eventDetails(eid).then(
      (res) => {
        if (res.status === "success") {
          setEventItems(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    userGuestService.userGuestList(params.eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  };

  useEffect(() => {
    getAllData(params.id);
  }, [params.id]);
  const onSubmit = (data) => {
    const formdata = new FormData();
    // What to Include
    formdata.append("cover_image", imgData2);
    formdata.append("imaDatajson", imgJsonData);
    formdata.append("invite_title", data.invite_title);
    formdata.append("invite_description", data.invite_description);

    // Additional Information
    formdata.append(
      "include_guest_app_download",
      data.include_guest_app_download === true ? 1 : 0
    );
    formdata.append("include_location", data.include_location === true ? 1 : 0);
    formdata.append("include_qr_code", data.include_qr_code === true ? 1 : 0);
    formdata.append("include_web_link", data.include_web_link === true ? 1 : 0);

    formdata.append("include_guest", data.include_guest === true ? 1 : 0);
    formdata.append(
      "include_organizer_contact",
      data.include_organizer_contact === true ? 1 : 0
    );
    formdata.append(
      "include_social_networks",
      data.include_social_networks === true ? 1 : 0
    );

    // When to Send
    formdata.append(
      "dont_send_to_rsvped",
      data.dont_send_to_rsvped == true ? 1 : 0
    );
    formdata.append(
      "send_to_all_events_in_story",
      data.send_to_all_events_in_story == true ? 1 : 0
    );

    // How to Send
    formdata.append("send_in_email", data.send_in_email === true ? 1 : 0);
    formdata.append("send_in_sms", data.send_in_sms === true ? 1 : "0");
    formdata.append(
      "generate_printable_pdf",
      data.generate_printable_pdf === true ? 1 : 0
    );

    const totalElement = 6;
    let selectedElement = 0;
    if (imgDa !== "") {
      selectedElement += 1;
    }
    if (data.invite_title !== "") {
      selectedElement += 1;
    }
    if (data.invite_description !== "") {
      selectedElement += 1;
    }
    if (
      data.include_guest_app_download === true ||
      data.include_location === true ||
      data.include_qr_code === true ||
      data.include_web_link === true
    ) {
      selectedElement += 1;
    }
    if (
      data.dont_send_to_rsvped === true ||
      data.send_to_all_events_in_story === true
    ) {
      selectedElement += 1;
    }
    if (
      data.send_in_email === true ||
      data.send_in_sms === true ||
      data.generate_printable_pdf === true
    ) {
      selectedElement += 1;
    }

    let invite_status_percent = 100;
    if (totalElement >= selectedElement) {
      invite_status_percent = (selectedElement / totalElement) * 100;
    }

    formdata.append("event_id", params.eid);
    formdata.append("invite_status", invite_status_percent);
    formdata.append("id", is_invite_id !== undefined ? is_invite_id : "");
    userGuestService.addInviteRsvp(formdata).then(
      (res) => {
        if (res.status === "success") {
          //alert(res.data.id)
          setisSendEmail(0);
          setInviteID(is_invite_id === undefined ? res.data.id : is_invite_id);

          if (params.id == undefined) {
            //setImgData2();
          }
          toast.success(res.message, "Account");
          if (params?.id) {
          } else {
            getAllData(res.data.id);
            // Rohit
            ///admin/manage-invites-edit/167/273/79?type=invites-rsvps
            //reset();

          }
        } else {
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const sendNow = () => {
    //alert("send now");
    let obj = {
      event_id: params.eid,
      storyz_id: params.sid,
      invite_id: params.id === undefined ? is_invite_id : params.id,
    };
    //console.log("obj", obj);
    userGuestService.sendNow(obj).then(
      (res) => {
        if (res.status === "success") {
          setisSendEmail(1);
          handleSendingInvite();
          // reset();
        } else {
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    setImgData2(URL.createObjectURL(e.target.files[0]));
    setImgData2Err("");
  };

  const handleInviteTemplate = () => {
    setInviteTemplate(!inviteTemplate);
  };

  const handleSendingInvite = () => {
    setRsvpLoading(true);
    setTimeout(() => {
      setRsvpLoading(false);
      setReview(false);
      toast.success("mail sent successfully");
    }, 3000);
  };

  const handleReview = () => {
    setReview(!review);
  };

  const handleRsvpDeadline = () => {
    setRsvpDeadline(!rsvpDeadline);
  };
  const handleRsvpDeadlineClose = () => {
    setRsvpDeadline(false);
  };

  const Checkimg = () => {
    //alert("gfg");
    //console.log("imgData2Err", imgData2Err);
    if (
      imgData2Err === "" &&
      inviteTitleErr === "" &&
      inviteDescriptionErr === ""
    ) {
      setCheckimg(1);
    } else {
      setCheckimg(0);
    }
  };

  return (
    <>
      <Modal
        isOpen={inviteTemplate}
        toggle={handleInviteTemplate}
        // {...args}
        className="inviteTemplateModalWrapper"
      >
        <ModalHeader
          toggle={handleInviteTemplate}
          className="zIndex99 position-relative"
        ></ModalHeader>
        <ModalBody className="pb-0 zIndex99 position-relative">
          <div>
            <div className="themeTitle mb-4">
              {allValues.invite_title === ""
                ? inviteTitle
                : allValues.invite_title}
            </div>
            <div className="subTitle mb-4 pb-4">
              {allValues.invite_description === ""
                ? inviteDescription
                : allValues.invite_description}
            </div>
            {imgData2 ? (
              <div className="themeImg mb-4">
                <img src={imgData2} alt="." />
                {/* <img
                src="https://images.unsplash.com/photo-1579724449219-8da6b76bef68?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3266&q=80"
                alt=""
              /> */}
              </div>
            ) : (
              ""
            )}

            <div className="themeBox">
              <div className="themeName mb-4">{event_name}</div>

              <div className="themeDate mb-4">
                {moment(eventItems?.event_start_date).format(
                  "MMMM D, YYYY: h:mm:sa"
                )}
              </div>

              {allValues.include_guest === true ? (
                <div className="themeGuestWrapper align-items-center mb-4">
                  <div className="head mb-3">
                    Guests • {userguestList.length >= 4 ? 4 : userguestList.length}
                  </div>
                  {userguestList.length > 0 ? (
                    <>
                      <div className="themeGuestListWrapper d-flex">
                        {userguestList.map((arr, i) => {
                          if (i < 4) {
                            return (
                              <>
                                <div className="themeGuestList px-1" key={i}>
                                  <img
                                    src={
                                      arr.imageUrl === ""
                                        ? DummyImg
                                        : arr.imageUrl
                                    }
                                    alt=""
                                    className="themeGuestImg"
                                  />
                                  {arr.first_name}
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {allValues.include_organizer_contact === true ||
                allValues.include_location === true ? (
                <div className="themeBoxWrapper d-flex mb-4">
                  {allValues.include_location ? (
                    <div className="themeMapWrapper pt-3 mr-2">
                      <div>
                        <div className="title mb-1 px-3">
                          {eventItems?.name_of_geospace}
                        </div>
                        <div className="subTitle text-left px-3 border-0">
                          {eventItems?.location}
                        </div>
                      </div>
                      <div className="mapWrapper position-relative mt-3">
                        <Button className="directionBtn">
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/themeDirection.svg").default
                            }
                            className="mr-2"
                            width={18}
                          />
                          Directions
                        </Button>
                        <img
                          alt="Organization Name"
                          src={Thememap}
                          className="themeMapImg"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {userDetails !== undefined ? (
                    <div className="themePorterWrapper align-items-center position-relative pt-3">
                      <img
                        alt="Organization Name"
                        src={userDetails.imageUrl}
                        className="img"
                        width={50}
                      />
                      <div className="title pb-1">{userDetails.name}</div>
                      <div className="subTitle b-4 border-0">
                        {userDetails.account_type}
                      </div>
                      <Button className="contactBtn">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/themeMail.svg").default}
                          className="mr-2"
                          width={18}
                        />
                        Contact
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {allValues.include_web_link === true ? (
                <div className="themeAppWrapper p-3 mb-4">
                  <div className="head mb-2">RSVP Options</div>
                  <div className="themeStoreApp d-flex justify-content-between">
                    <div className="themeImg1">
                      <img
                        alt="Organization Name"
                        src={AppStore}
                        className="d-flex"
                      />
                    </div>
                    <div className="themeImg2">
                      <img
                        alt="Organization Name"
                        src={GooglePlay}
                        className="d-flex"
                      />
                    </div>
                    <div className="themeImg3 d-flex flex-column justify-content-center align-items-center">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/computer.svg").default}
                        className="d-flex"
                      />
                      Website
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {allValues.include_social_networks === true ? (
                <div className="d-flex align-items-center justify-content-between px-5 pb-4">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/instagram-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/twitter-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tiktok-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/facebook-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/youtube-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/vimeo-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
        <img src={ThemeBg} alt="" className="themeBg" />
      </Modal>

      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu
              handleInviteTemplate={handleInviteTemplate}
              handleDeleteGuestUser={handleDeleteGuestUser}
            />
            <div className="column2">
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>
                  {common?.singleEvent?.event_name
                    ? common?.singleEvent?.event_name
                    : ""}
                </div>
                <div className="d-flex align-items-center">
                  <div className="imageeventim234">
                    <span>Invite Details</span>
                  </div>

                  <Modal
                    isOpen={guestGroupModal}
                    toggle={handelGuestGroupModal}
                    {...args}
                    className="aboutGuestGroupModal"
                  >
                    <ModalBody className="pb-0">
                      <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                        <img
                          src={GeospaceInfoIcon}
                          alt=""
                          className="guestGroupInfoIcon mt-3"
                        />
                        <div className="head">
                          Send Invites to Your Guest List
                        </div>
                      </div>

                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            You can create and send multiple invitations to your
                            guests. Invitations CANNOT be recalled - so make
                            sure you are ready before sending!
                          </p>
                          <p>
                            Invites are SEPARATE from your guest list and RSVPs.
                            Guests can RSVP by downloading the guest app, even
                            if they haven’t received an invite.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites}
                            alt=""
                            className="maxWidth100 mx-auto"
                            width={70}
                          />
                        </Col>
                      </Row>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            Each invitation can be sent and resent to your guest
                            list. You can control which guests receive the
                            invites.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites1}
                            alt=""
                            className="maxWidth100 mx-auto"
                          />
                        </Col>
                      </Row>

                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            If the RSVP date has past, you cannot send the
                            Invite. To send it, you will have to change the RSVP
                            date.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites2}
                            alt=""
                            className="maxWidth100 mx-auto"
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                      <Button color="primary blueBtn" className="w-100">
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <span
                    onClick={handelGuestGroupModal}
                    className="ml-2 pointer"
                    data-tip="Tooltip message will come here....."
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                    />
                  </span>
                </div>
              </div>
              <div className="organizationPara border-bottom">
                This view lets you edit details, select a template, and send
                your invites.
              </div>

              <GuestEventWrapper className="p-4">
                <div className="organizeByGroupOuter mt-0">
                  <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex mb-4">
                      {/* {checkimg} */}
                      {imgData2Err === "" &&
                        inviteTitleErr === "" &&
                        inviteDescriptionErr === "" ? (
                        <GlobalButton
                          customClass="ml-auto"
                          img={require("assets/img/heartMailIcon.svg").default}
                          type="submit"
                        >
                          Save Changes
                        </GlobalButton>
                      ) : (
                        <Button
                          className="btn saveChangesBtn ml-auto"
                          img={require("assets/img/heartMailIcon.svg").default}
                          onClick={() => Checkimg()}
                        >
                          Save Changes
                        </Button>
                      )}

                      <Button
                        className="deleteBtn"
                        onClick={() => handleDeleteGuestUser()}
                      >
                        <IoMdClose className="icon" />
                        Delete Invite
                      </Button>
                    </div>
                    <Row className="addGuestUerFormWrapper">
                      <Col className="" lg={6} md={12}>
                        <div>
                          <div className="heading">What to Include</div>
                          <Row>
                            <Col>
                              <div className="imageventsla mb-2 mt-4">
                                <div>Image Label:</div>

                                <div>
                                  {/* file stack */}
                                  <img
                                    alt="Organization Name"
                                    src={
                                      require("assets/img/mediaGallery.svg")
                                        .default
                                    }
                                    className="icomn pointer"
                                    style={{ width: "24px" }}
                                    onClick={() => setModalShow(true)}
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <div
                                  className="imgMaskevente"
                                  onClick={() => setModalShow(true)}
                                >
                                  <div className="imgMask-content">+</div>
                                  {imgData2 ? (
                                    <img
                                      className="storytoimageev"
                                      id="blah2"
                                      src={imgData2}
                                      alt="."
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {/* <input
                                    type="file"
                                    className="imgg1"
                                    onChange={fileHandler}
                                  /> */}
                                </div>
                                {/* {checkimg} */}
                                <p style={{ color: "red", fontSize: 13 }}>
                                  {checkimg !== 0 && imgData2Err !== ""
                                    ? ""
                                    : imgData2Err}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Row className="my-3 p-2">
                            <Col className="form-group">
                              <label className="form-label">
                                Invite Title (this will appear in Invite):
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="enter text..."
                                name="invite_title"
                                {...register("invite_title", {
                                  required: true,
                                })}
                                onChange={handleChange}
                              ///required
                              />
                              <p style={{ color: "red", fontSize: 13 }}>
                                {checkimg !== 0 ? "" : inviteTitleErr}
                              </p>
                            </Col>
                          </Row>
                          <Row className="my-3 p-2">
                            <Col className="form-group">
                              <label className="form-label">Description:</label>
                              <textarea
                                type="text"
                                name="invite_description"
                                {...register("invite_description", {
                                  required: true,
                                })}
                                className="form-control"
                                placeholder="enter text..."
                                onChange={handleChange}
                                required
                              ></textarea>

                              <p style={{ color: "red", fontSize: 13 }}>
                                {checkimg !== 0 ? "" : inviteDescriptionErr}
                              </p>
                            </Col>
                          </Row>
                        </div>

                        <div>
                          <div className="heading">Additional Information</div>

                          <Row className="mt-3">
                            <Col>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Guest App Download
                                  <input
                                    type="checkbox"
                                    name="include_guest_app_download"
                                    {...register("include_guest_app_download")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Location Map Link
                                  <input
                                    type="checkbox"
                                    name="include_location"
                                    {...register("include_location")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include QR Code
                                  <input
                                    type="checkbox"
                                    name="include_qr_code"
                                    {...register("include_qr_code")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Web LInk
                                  <input
                                    type="checkbox"
                                    name="include_web_link"
                                    {...register("include_web_link")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Guest Information
                                  <input
                                    type="checkbox"
                                    name="include_guest"
                                    {...register("include_guest")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Organizer Contact
                                  <input
                                    type="checkbox"
                                    name="include_organizer_contact"
                                    {...register("include_organizer_contact")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Social Networks
                                  <input
                                    type="checkbox"
                                    name="include_social_networks"
                                    {...register("include_social_networks")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className="" lg={6} md={12}>
                        <div>
                          <div className="heading">When to Send</div>

                          <Row className="my-3 p-2">
                            <Col>
                              <div className="py-1 px-2 d-flex align-items-center justify-content-between grayColor mb-3">
                                {   Number(invite_status) !== 100 &&  Number(rsvp_deadline) === 0 ? ("") : 
                                    (<span> { rsvp_deadline === 0 ?  rsvp_remaining + " days until event RSVP deadline" : "RSVP deadline passed" }</span>)
                                }
                                
                                {rsvp_deadline > 0 ? (
                                  <span
                                    className="ml-2 pointer"
                                    data-tip="Tooltip message will come here....."
                                    onClick={handelInviteStatusModal}
                                  >
                                    <img
                                      alt="Organization Name"
                                      src={
                                        require("assets/img/tip.svg").default
                                      }
                                    />
                                  </span>
                                ) : ("")}

                                <Modal
                                  isOpen={inviteStatusModal}
                                  toggle={handelInviteStatusModal}
                                  {...args}
                                  className="aboutGuestGroupModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                                      <img
                                        src={GeospaceInfoIcon}
                                        alt=""
                                        className="guestGroupInfoIcon mt-3"
                                      />
                                      <div className="head">
                                        Understanding Invite Status
                                      </div>
                                    </div>
                                    <Container fluid>
                                      <Row className=" border-bottom mb-4 pb-2">
                                        <Col>
                                          <p>
                                            Invites can have several states
                                            before and after they are sent to
                                            your guests. The following lists the
                                            possible states.
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="mb-4 pb-2">
                                        <Col>
                                          <div className="d-flex align-items-center py-3 pr-3 border-bottom">
                                            <div
                                              style={{
                                                width: "48px",
                                                height: "48px",
                                                borderRadius: "8px",
                                                background: "#FFF5D5",
                                              }}
                                              className="mr-3"
                                            ></div>
                                            <div>
                                              <div className="fontSize16 grayColor">
                                                Incomplete
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <p>
                                            <span className="bold">
                                              Incomplete -{" "}
                                            </span>{" "}
                                            you need to add required information
                                            before sending.
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="mb-4 pb-2">
                                        <Col>
                                          <div className="d-flex align-items-center py-3 pr-3 border-bottom">
                                            <div
                                              style={{
                                                width: "48px",
                                                height: "48px",
                                                borderRadius: "8px",
                                                background: "#53D769",
                                              }}
                                              className="mr-3"
                                            ></div>
                                            <div>
                                              <div className="fontSize16 grayColor">
                                                Ready to Send
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <p>
                                            <span className="bold">
                                              Ready to Send -
                                            </span>{" "}
                                            you can send the Invite at any time.
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="mb-4 pb-2">
                                        <Col>
                                          <div className="d-flex align-items-center py-3 pr-3 border-bottom">
                                            <div
                                              style={{
                                                width: "48px",
                                                height: "48px",
                                                borderRadius: "8px",
                                                background: "#287F54",
                                              }}
                                              className="mr-3"
                                            ></div>
                                            <div>
                                              <div className="fontSize16 grayColor">
                                                Sent July 22, 2022
                                              </div>
                                              <div className="fontSize16 grayColor">
                                                3:30 pm UTC
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <p>
                                            <span className="bold">Sent -</span>{" "}
                                            check RSVPs for responses for your
                                            invite, re-send if new guests are
                                            added.
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="mb-4 pb-2">
                                        <Col>
                                          <div className="d-flex align-items-center py-3 pr-3 border-bottom">
                                            <div
                                              style={{
                                                width: "48px",
                                                height: "48px",
                                                borderRadius: "8px",
                                                background: "#FC3158",
                                              }}
                                              className="mr-3"
                                            ></div>
                                            <div>
                                              <div className="fontSize16 grayColor">
                                                Can not be sent
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col>
                                          <p>
                                            <span className="bold">
                                              Past RSVP Deadline -
                                            </span>{" "}
                                            the RSVP deadline you specified in
                                            your event is in the past. You need
                                            to change the RSVP date in your
                                            event to send this invite.
                                          </p>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </ModalBody>
                                  <ModalFooter className="pt-0">
                                    <Button
                                      color="primary "
                                      className="w-100 blueBtn"
                                      onClick={handelInviteStatusModal}
                                    >
                                      Continue
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </div>

                              <div className="border-bottom mb-3 pb-3">
                                <div
                                  // className="inviteStatus d-flex align-items-center justify-content-end incomplete w-100"
                                  className={`inviteStatus d-flex align-items-center justify-content-end  w-100
                                  ${Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                                      ? "incomplete"
                                      : ""
                                    }
                                  ${Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                                      ? "canNotSent"
                                      : ""
                                    }
                                  `}
                                >
                                  {Number(invite_status) === 100 && Number(rsvp_deadline) === 0
                                    ? Number(isSendEmail) === 0
                                      ? "Ready To Send"
                                      : "Send" +
                                      " " +
                                      moment(invite_updated_at).format(
                                        "MMMM D, YYYY: h:mm:sa"
                                      ) +
                                      " " +
                                      "UTC"
                                    : Number(invite_status) === 100 &&
                                      Number(rsvp_deadline) === 1
                                      ? "Cannot be send"
                                      : Number(invite_status) !== 100 &&
                                        Number(rsvp_deadline) === 0
                                        ? "incomplete"
                                        : "incomplete"}
                                  <div className="guestCircleWrapper ml-3">
                                    <CircularProgressbar
                                      value={invite_status}
                                      text={false}
                                      strokeWidth={20}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "#53D769 ",
                                        trailColor: "#1345A5",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3 fontSize14 grayColor">
                                Send Options:
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Don’t send to guests who have RSVPed
                                  <input
                                    type="checkbox"
                                    name="dont_send_to_rsvped"
                                    {...register("dont_send_to_rsvped")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              {
                                // Start Review and Send
                                Number(invite_status) === 100 && Number(rsvp_deadline) === 0 ? (
                                  <div className="my-5">
                                    <button
                                      type="button"
                                      className="reviewEventBtn ml-auto"
                                      onClick={handleReview}
                                    >
                                      <img
                                        alt="Organization Name"
                                        src={
                                          require("assets/img/invite-icon-white.svg")
                                            .default
                                        }
                                        className="mr-2"
                                        width={18}
                                      />
                                      {Number(isSendEmail) === 0
                                        ? "Review and Send"
                                        : "Review and Resend"}
                                    </button>
                                  </div>
                                ) : // Start Changes deadline rsvp
                                  Number(invite_status) === 100 && Number(rsvp_deadline) === 1 ? (
                                    <div className="my-5">
                                      <button
                                        className="messageBtn pinkBtn ml-auto"
                                        type="button"
                                        onClick={handleRsvpDeadline}
                                      >
                                        <img
                                          src={HornIcon}
                                          alt=""
                                          className="mr-2"
                                        />{" "}
                                        Change RSVP Deadline in Event{" "}
                                      </button>
                                    </div>
                                  ) : Number(invite_status) !== 100 &&
                                    Number(rsvp_deadline) === 0 ? (
                                    <div className="my-5">
                                      <button
                                        className="messageBtn ml-auto"
                                        type="button"
                                      >
                                        <img
                                          alt="Organization Name"
                                          src={
                                            require("assets/img/newInvite.svg")
                                              .default
                                          }
                                          className="icon"
                                        />
                                        Send Now
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="my-5">
                                      <button
                                        className="messageBtn ml-auto"
                                        type="button"
                                      >
                                        <img
                                          alt="Organization Name"
                                          src={
                                            require("assets/img/newInvite.svg")
                                              .default
                                          }
                                          className="icon"
                                        />
                                        Review and Send
                                      </button>
                                    </div>
                                  )
                              }
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <div className="heading">How to Send</div>

                          <Row className="pt-3">
                            <Col>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Send in Email
                                  <input
                                    name="send_in_email"
                                    type="checkbox"
                                    {...register("send_in_email")}
                                    onChange={handleChange1}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center disabled rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Generate Printable PDF
                                  <input
                                    type="checkbox"
                                    name="generate_printable_pdf"
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center disabled rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Export information for Invitation Service
                                  <input type="checkbox" name="" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <div className="heading">Invite Template </div>
                          <Row className="my-3 p-2">
                            <Col>
                              <div className="p-3 d-flex">
                                <div className="mr-3">
                                  <div
                                    className="themeImgWrapper bg-white d-flex align-items-center justify-content-center"
                                    onClick={handleInviteTemplate}
                                  >
                                    <img
                                      src={SendInvites}
                                      alt=""
                                      className="mx-auto"
                                      width={70}
                                    />
                                  </div>
                                </div>
                                <div className="pt-4">
                                  <div className="fontSize18 blackColor mb-2">
                                    Theme
                                  </div>
                                  <button
                                    className="rsvpPreviewBtn"
                                    type="button"
                                    onClick={handleInviteTemplate}
                                    disabled={
                                      Number(invite_status) !== 100 &&
                                        Number(rsvp_deadline) === 0
                                        ? "disabled"
                                        : ""
                                    }
                                  >
                                    <img
                                      alt="Organization Name"
                                      src={Preview}
                                      className="icon"
                                    />
                                    Preview
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </GuestEventWrapper>
            </div>
          </Eventstyle>
        </div>
        {isSendEmail === 1 ?
          (<button
            type="button"
            className="deleteGuestBtn"
          >
            <img
              alt="Organization Name"
              src={require("assets/img/newInvite.svg").default}
              className="mr-2"
              width={18}
            />
            Delete Invitation
          </button>) : (<button
            type="button"
            className="delteEventBtn"
            onClick={handleDeleteGuestUser}
          >
            <img
              alt="Organization Name"
              src={require("assets/img/invite-icon-white.svg").default}
              className="mr-2"
              width={18}
            />
            Delete Invitation
          </button>)
        }


        <Modal
          isOpen={review}
          toggle={handleReview}
          // {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-3 d-flex flex-row">
              <img src={Resend} alt="" className="geospaceInfoIcon mr-3" />
              <div className="head text-left">
                You are About to Send Your Invite
              </div>
            </div>
            <div className="reviewCard">
              <div className="img">
                <img
                  alt="Organization Name"
                  src={event_img}
                  className="mr-2"
                  width={18}
                />
              </div>
              <div className="content">
                <div className="title">{eventname}</div>
                <div className="subTitle">
                  {moment(eventenddate).format("MMMM D, YYYY: h:mm:sa")} -{" "}
                  {moment(eventstartdate).format("MMMM D, YYYY: h:mm:sa")} - UTC
                </div>
                <div className="subTitle">{event_location}</div>
              </div>
            </div>
            <Row>
              <Col className="px-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="mr-2">
                    <img alt="Organization Name" src={SendInvites} width={70} />
                  </div>
                  <div className="w-100">
                    <div className="fontSize16 borderBottom pb-2 mb-2">
                      {inviteTitle}
                    </div>
                    <div
                      className={`inviteStatus d-flex align-items-center justify-content-end  w-100
                                  ${Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                          ? "incomplete"
                          : ""
                        }
                                  ${Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                          ? "canNotSent"
                          : ""
                        }
                                  `}
                    >
                      {Number(invite_status) === 100 && Number(rsvp_deadline) === 0
                        ? Number(isSendEmail) === 0
                          ? "Ready To Send"
                          : "Send" +
                          " " +
                          moment(invite_updated_at).format(
                            "MMMM D, YYYY: h:mm:sa"
                          ) +
                          " " +
                          "UTC"
                        : Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                          ? "Cannot be send"
                          : Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                            ? "incomplete"
                            : "incomplete"}
                      <div className="guestCircleWrapper ml-3">
                        <CircularProgressbar
                          value={invite_status}
                          text={false}
                          strokeWidth={20}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#53D769 ",
                            trailColor: "#1345A5",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-4">
                <p className="blackColor">{inviteDescription}</p>
                <p className="blackColor">Are you ready?</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button
              color="primary "
              className="flex-grow-1 blueBtn border-radius10"
              onClick={() => sendNow()}
            >
              Do It!
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleReview(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={rsvpLoading}
          // toggle={handleRsvpLoading}
          className="loadingModalRSVPModal"
        >
          <ModalBody className="p-0">
            <div className="head">Sending Invite</div>
            <p>Queueing for delivery within 1 hour</p>
            <div className="RsvpLoading">
              <div className="circleAnimation1"></div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={rsvpDeadline}
          toggle={handleRsvpDeadline}
          {...args}
          className="resetGeospaceModal rsvpDeadlineModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-3 pb-2">
              <img src={RSVPDeadline} alt="" className="geospaceInfoIcon" />
              <div className="head">It is After Your RSVP Deadline</div>
            </div>
            <div className="border-bottom">
              <Row>
                <Col className="px-3">
                  <p className="fontWeight700">
                    Invites can’t be sent after the RSVP deadline for the event.
                  </p>
                  <p>
                    This is a safety precaution so you don’t send out the Invite
                    after your deadline for receiving Invites.
                  </p>
                  <p>
                    To change this, go to your event, and change your RSVP
                    deadline.
                  </p>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between mt-n1">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={gotoEvent}
            >
              Go to Event
            </Button>
            <Button
              className="btn-default cancelBtn flex-grow-1"
              onClick={handleRsvpDeadlineClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* Start Delete */}

        <Modal
          isOpen={deleteGuestUser}
          toggle={handleDeleteGuestUser}
          // {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
              <img
                src={ResetGeospaceInfoIcon}
                alt=""
                className="geospaceInfoIcon"
              />
              <div className="head">Delete this Invite?</div>
            </div>
            <Row>
              <Col className="px-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="mr-3">
                    <img alt="Organization Name" src={SendInvites} width={70} />
                  </div>
                  <div className="w-100">
                    <div className="fontSize16 borderBottom pb-2 mb-2">
                      {inviteTitle}
                    </div>
                    <div
                      className={`inviteStatus d-flex align-items-center justify-content-end  w-100
                                  ${Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                          ? "incomplete"
                          : ""
                        }
                                  ${Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                          ? "canNotSent"
                          : ""
                        }
                                  `}
                    >
                      {Number(invite_status) === 100 && Number(rsvp_deadline) === 0
                        ? Number(isSendEmail) === 0
                          ? "Ready To Send"
                          : "Send" +
                          " " +
                          moment(invite_updated_at).format(
                            "MMMM D, YYYY: h:mm:sa"
                          ) +
                          " " +
                          "UTC"
                        : Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                          ? "Cannot be send"
                          : Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                            ? "incomplete"
                            : "incomplete"}
                      <div className="guestCircleWrapper ml-3">
                        <CircularProgressbar
                          value={invite_status}
                          text={false}
                          strokeWidth={20}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#53D769 ",
                            trailColor: "#1345A5",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eventThumWrapper">
                  <div className="eventThum">
                    <img src={event_img} alt="" className="eventThumImg" />
                  </div>
                  <div className="eventThumContent">
                    <div className="eventthumbTitle">{eventname}</div>
                    <div className="eventthumbSubTitle">{event_location}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-4">
                <p>
                  Confirm you want to remove this invite. This will clear your
                  current invitation and template from the list.
                </p>
                <p>
                  Deleting will NOT affect your guest list or RSVPs, nor will it
                  affect any Invites that have already been sent to guests.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={() => handleDelGuestUser()}
            >
              Delete Invite
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => setDeleteGuestUser(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {modalShow ? (
          <Filemanager
            show={modalShow}
            onHide={handleClickimag}
            onClickimageUpdate={imageUpdate}
            list={allimage}
            seletedImage={seletedImageClike}
            mapkey=""
          />
        ) : (
          ""
        )}

        {/* End Delete */}
      </div>
      <ToastContainer />
    </>
  );
};

export default AddInviteRSVPsEmpty;
