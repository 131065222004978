import React from "react";

export const InputField = React.forwardRef(
  (
    {
      value,
      defaultValue,
      formGroup,
      label,
      name,
      placeholder,
      type,
      onChange,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`${formGroup === false ? "" : "form-group"}`}>
        {label && <label htmlFor="input-field">{label}</label>}
        <input
          type={type}
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          {...rest}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
);

//export default InputField;

export const CheckboxField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  check,
}) => (
  <>
    {/* <div className="form-check">
      <input
        className="form-check-input"
        id={name}
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={check}
      />
      {label && (
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      )}
    </div> */}
    <div className="custom-control custom-checkbox">
      <label
        className="container3 grayColor mb-0"
        style={{ fontSize: "14px" }}
        htmlFor={label ? name : ""}
      >
        {label ? label : ""}
        <input
          id={name}
          type="checkbox"
          name={name}
          onChange={onChange}
          checked={check}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  </>
);

export const TextareaField = React.forwardRef(
  ({ value, label, name, placeholder, rows, type, onChange, ...rest }, ref) => {
    return (
      <div className="form-group">
        {label && <label htmlFor={name}>{label}</label>}
        <textarea
          type={type}
          name={name}
          id={name}
          rows={rows}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          {...rest}
        ></textarea>
      </div>
    );
  }
);
