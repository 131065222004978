import React, { useEffect, useState, useRef } from "react";
import { FaPlus, FaChevronDown } from "react-icons/fa";
import { createPortal } from "react-dom";

const GuestPrefixDropdown = ({
  guestPrefixOption1,
  placeholder,
  caption,
  style,
  handlerFunction,
  handleAddModal,
  gid,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [addUserBtn, setAddUserBtn] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();

  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    setChangeText(placeholder);
  }, [placeholder]);
  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);

    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();

    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };
  useEffect(() => {
    let handler = (e) => {
      if (
        !selectMenuRef.current.contains(e.target) &&
        !selectMenuList?.current?.contains(e.target)
      ) {
        setSelectMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const ChangetextHandle = (title) => {
    setSelectMenu(false);
    setChangeText(title);
    handlerFunction(title, gid);
  };

  const guestPrefixOption = () => {
    if (!searchValue) {
      return guestPrefixOption1;
    }

    return guestPrefixOption1.filter(
      (option) =>
        option.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className=" dropdownBoxWrapperPort" ref={selectMenuRef}>
      <div
        className="dropdownTriggered placeholderText"
        onClick={dropdownHandle}
        style={style}
        ref={buttonHeight}
      >
        <div className="dropdownPlaceholder">
          <span className="pl-3 pr-5">{changeText}</span>
          <button className="arrowIcon">
            <FaChevronDown />
          </button>
          {/* </div> */}
        </div>
      </div>
      {selectMenu &&
        createPortal(
          <div
            ref={selectMenuList}
            style={{ top: property.top, left: property.left }}
            className={`dropdownBoxMenuWrapperPort ${
              selectMenu ? "active" : ""
            }`}
          >
            <div className="dropdownBoxMenuInner">
              {addUserBtn ? (
                <p className="AddGestUser" onClick={handleAddModal}>
                  Add <FaPlus className="icon" />
                </p>
              ) : null}
              <div className="dropdownSearchWrapper">
                <input
                  type="text"
                  placeholder="type to search..."
                  className="form-control"
                  onChange={onSearch}
                  value={searchValue}
                />
              </div>
              <ul className="dropdownMenuListWrapper">
                {caption ? (
                  <li className="menuItem captionTitle">
                    <div className="guestList">{caption}</div>
                  </li>
                ) : (
                  ""
                )}

                {guestPrefixOption().map((option, i) => (
                  <li
                    className="menuItem"
                    key={i}
                    onClick={() => ChangetextHandle(option.title)}
                  >
                    <div className="guestList">{option.title}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default GuestPrefixDropdown;





