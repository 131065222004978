import React, { useEffect, useState } from "react";
import Allheader from "../../components/Headers/AllheaderEvent";
import { useParams } from "react-router-dom";
import KeepsakeAlbumUpload from "components/Events/KeepsakeAlbumUpload";
import Imggroup from "../../assets/img/dummyImg.svg";

import { useSelector } from "react-redux";
function GuestKeepsakeAlbumUpload() {
  const params = useParams();
  const [eventList, seteventList] = useState([]);

  const common = useSelector((state) => {
    return state.commonData.common;
  });
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  return (
    <>
      {/* <Allheader
        title="Event Keepsake Album"
        imgScr="https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHdlZGRpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60"
        arw
      /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <KeepsakeAlbumUpload />
        </div>
      </div>
    </>
  );
}
export default GuestKeepsakeAlbumUpload;
