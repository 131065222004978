import React, { useState } from "react";
import classnames from "classnames";

import makeAnimated from "react-select/animated";
import "react-phone-number-input/style.css";

import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";

import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'



import { isValidPhoneNumber } from "react-phone-number-input/input";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { informationService } from "../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import placeholde from "../../assets/img/placeholder.jpg";
import { userGuestService } from "_services/masters/userGuest.service";
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";



import { TiUser } from "react-icons/ti";

import Filemanager from "../../components/CommonMediaModal/Filemanager";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";

import IconImg from "../../assets/img/icons/system-logos.svg";
import InfoIcon from "../../assets/img/infoOrangeIcon.svg";

function AccountViewOtherOwner() {
  // const history = useHistory();
  const [allimage, setAllimage] = React.useState([]);
  const params = useParams();
  const [prifix, setPrifix] = React.useState([]);
  const [suffix, setSuffix] = React.useState([]);
  // const dispatch = useDispatch();
  const contact = useSelector((state) => {
    return state.profile.profiledetails;
  })
  const [userInfo, setuserInfo] = React.useState({});
  // const [alldetails, setAlldetails] = useState({});
  const [id, setId] = React.useState(params.id ? params.id : "");

  const [emailAddress, setemailAddress] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState();
  // const [selectedOption, setSelectedOption] = React.useState(null);
  // const animatedComponents = makeAnimated();
  // const [startDate, setStartDate] = React.useState();
  // const [picture, setPicture] = React.useState(null);
  const [imgData, setImgData] = React.useState(null);

  const [zoneval, setValuezone] = React.useState("utc");
  const [country, setCountry] = React.useState("us");
  const [phoneNumber1, setPhoneNumber1] = React.useState();
  const [errorMobile, setErrorMobile] = React.useState("");
  // const handleChange = (e) => {
  //   setValuezone(e.target.value);
  // };
  // let handleOnChange = value => {

  //   if (value && isValidPhoneNumber(value) === true) {
  //     setPhoneNumber1((value));
  //     setValue('mobile_number', value);
  //   } else {
  //     setPhoneNumber1();
  //     setValue('mobile_number', '');
  //   }
  //   // setValue('mobile_number', isValidPhone === true ? value : '')
  //   setPhoneNumber(value);

  // };
  // let handleOnChangephone = e => {
  //   setPhoneNumber(e.target.value);
  // };
  function listSuffix() {
    userGuestService.listSuffix().then(
      (res) => {
        if (res.status === "success") {
          setSuffix(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function listPrifix() {
    userGuestService.listPrifix().then(
      (res) => {
        if (res.status === "success") {
          setPrifix(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      // e.preventDefault();
      // setPicture(e.target.files[0]);
      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   setImgData(reader.result);
      // });

      // const formData = new FormData()
      // formData.append('file', e.target.files[0])

      // let userInfo = JSON.parse(localStorage.getItem('userDetails'));

      // axios.post(""+Global.BASE_API_PATH+"/profile/upload", formData, {
      //     headers: {
      //         'token': userInfo.token
      //     }
      // })
      // .then((res) => {
      //   console.log(res)
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   })

      // console.log("picture: ", e.target.files);

      // console.log(e.target.files[0]);
      // console.log(e.target.files[0].name);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      const imageUrl = URL.createObjectURL(e.target.files[0]);

      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      informationService.profileUpdate(formData).then(
        (res) => {
          if (res.status === "success") {
            toast.success("Profile upload successfully !!", "Account");
            userdetail();
          } else {
            toast.error("Something went wrong !! !!", "Account");
          }
        },
        (error) => {
          toast.error("Something went wrong !! !!", "Account");
        }
      );
    }
  };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  function userdetail() {
    informationService.acInformationAdmin(id).then(
      (res) => {
        if (res.status === "success") {
          setuserInfo(res.userInfo);

          setValue("surname", res.userInfo.surname, { shouldTouch: true });
          setValue("suffix", res.userInfo.suffix, { shouldTouch: true });

          setValue("first_name", res.userInfo.first_name, {
            shouldTouch: true,
          });
          setValue("last_name", res.userInfo.last_name, { shouldTouch: true });

          setValue("about", res.userInfo.about ? res.userInfo.about : "", {
            shouldTouch: true,
          });



          setCountry(res.userInfo.country_code ? res.userInfo.country_code : '');
          setPhone(res.userInfo.mobile_number);
          setValue("mobile_number", res.userInfo.mobile_number, { shouldTouch: true });



          //setCountry(res.userInfo.country_code);

        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const onSubmit = (data) => {

    // if (data.mobile_number === '' || data.mobile_number === undefined) {
    //   {
    //     setErrorMobile("Mobile Number required");
    //   }
    // } else {
    //   setErrorMobile("");
    // }

    const update = {
      first_name: data.first_name,
      last_name: data.last_name,
      mobile_number: `${data.mobile_number}`,
      surname: data.surname,
      suffix: data.suffix,
      about: data.about,
      country_code: country,
      user_id: id,
    };




    informationService.acInformationupdate(update).then(
      (res) => {
        if (res.status === "success") {
          toast.success(
            "Your account details has been successfully updated  !!",
            "Account"
          );
          setValue("first_name", data.first_name, { shouldTouch: true });
          setValue("last_name", data.last_name, { shouldTouch: true });
          setValue("mobile_number", data.mobile_number, { shouldTouch: true });
          setValue("surname", data.surname ? data.surname : "0", {
            shouldTouch: true,
          });
          setValue("suffix", data.suffix ? data.suffix : "0", {
            shouldTouch: true,
          });
          setValue("about", data.about ? data.about : "", {
            shouldTouch: true,
          });
          //userdetail();
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const numberphone = (phone) => {
    console.log(phone);


    if (phone.phone.match(/12345/)) {
      setErrorMobile("Mobile Number Invalid value");
      return false;
    } else if (phone.phone.match(/1234/)) {
      setErrorMobile("Mobile Number Invalid value ");
      return false
    } else {
      setErrorMobile("");
      setValue("mobile_number", phone.phone, { shouldTouch: true });
    }



    // console.log(isValidPhoneNumber(e));

    // if (e && isValidPhoneNumber(e) === true) {
    //   setErrorMobile("");
    //   setPhoneNumber(e);
    // } else {
    //   setPhoneNumber(e);
    //   setErrorMobile("Invalid phone number !!!");
    // }
    //console.log(code);
  };
  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {labels[country]} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );
  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  React.useEffect(() => {
    listSuffix();
    listPrifix();
    userdetail();
  }, [id]);
  const handleClick = () => {
    setModalShow(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "profile",
      user_id: id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    });

    informationService.profileUpdate(data).then(
      (res) => {
        if (res.status === "success") {
          userdetail();
          toast.success("Profile upload successfully !!", "Account");

          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const seletedImageClike = (e) => {
    let jsonData = JSON.parse(e.img_json);
    const data = JSON.stringify({
      type: "profile",
      user_id: id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData,
    });

    informationService.profileUpdate(data).then(
      (res) => {
        if (res.status === "success") {
          userdetail();
          setModalShow(false);
          toast.success("Profile upload successfully !!", "Account");

          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);


  const [changeEmail, setChangeEmail] = useState(false);
  const handleChangeEmail = () => {
    setChangeEmail(!changeEmail);
  };

  const [phone, setPhone] = useState("");
  let handleOnChangephone = e => {
    setPhone(e.target.value);
  };

  let handleOnChange = value => {
    setCountry(value.country.countryCode);
    setPhone(value.phone);
    setValue("mobile_number", value.phone, { shouldTouch: true });
  };

  return (
    <>
      {/* <Allheader title="Your Account" /> */}
      <Modal
        isOpen={changeEmail}
        toggle={handleChangeEmail}
        className="editEmailModal"
      >
        <ModalBody>
          <div className="editEmailHeader border-bottom">
            <img src={InfoIcon} alt="" className="editEmailInfoIcon" />
            <div className="head">
              Editing email will invalidate this Admin’s Account
            </div>
          </div>
          <Row>
            <Col>
              <div className="deleteEverythingThumWrapper">
                <div className="deleteEverythingThum">
                  <img
                    src={placeholde}
                    alt=""
                    className="deleteEverythingThumImg"
                  />
                </div>
                <div className="deleteEverythingThumContent">
                  <div className="deleteEverythingthumbTitle">Guest Name</div>
                  <div className="deleteEverythingthumbSubTitle">
                    Role in Event
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Since you are the Account Owner, you can edit this email.
                However, changing it will invalidate this Admin’s account.
              </p>
              <p>They will need to re-authenticate using their new email.</p>
              <p>You will need to send them a re-invite</p>
              <p className="bold">Make sure you want to do this!</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="flex-column">
          <div
            color="primary"
            className="btn orangeBtn w-100"
          // onClick={RemoveGuest}
          >
            Continue
          </div>
        </ModalFooter>
      </Modal>
      <CombinedGuestPoolHeader title="Your Account" imgScr={IconImg} />
      <div className="organizerWrapperMain">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card organizerCardWrapper aboutInfoWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Account Information</h4>
            </div>
            <div className="organizationPara p-4">
              This page displays the information shared if you are a guest at an
              event.
            </div>

            <div className="px-4 py-2 d-flex">
              <div className="grayBtnDisabled disabled ml-auto">
                <img
                  alt="Organization Name"
                  src={require("assets/img/icons/Chat-Vector2.svg").default}
                />
                Message
              </div>
            </div>
            <div className="d-flex m-4">
              {userInfo.users_id != contact.user_id ? (
                <>
                  {contact.account_type === "Account Owner" ? (
                    <Button className="saveChangesBtn ml-auto">
                      <TiUser className="icon" /> Save Changes
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <Button className="saveChangesBtn ml-auto">
                    <TiUser className="icon" /> Save Changes
                  </Button>
                </>
              )}
            </div>
            <Row className="aboutInfoBox">
              <Col lg={6} md={12}>
                <div className="basicinformation">Basic Information</div>

                <div className="cardBox">
                  <div className="basicInfoAvatar">
                    <div className="basicInfoAvatarImg">
                      {/* <div
                        style={{
                          width: "120px",
                          height: "120px",
                          position: "relative",
                        }}
                      > */}
                      {/* <div className="plusIcon">+</div> */}
                      <img
                        src={
                          userInfo?.imageUrl ? userInfo?.imageUrl : placeholde
                        }
                        className="rounded-circle pointer img"
                        style={{ width: "120px", height: "120px" }}
                        alt="Avatar"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      />
                      {/* </div> */}
                      {modalShow ? (
                        <Filemanager
                          show={modalShow}
                          onHide={handleClick}
                          onClickimageUpdate={imageUpdate}
                          list={allimage}
                          seletedImage={seletedImageClike}
                        />
                      ) : (
                        ""
                      )}

                      <div className="accountTypeName">
                        {userInfo?.account_type ? userInfo?.account_type : ""}{" "}
                      </div>
                    </div>

                    <div className="basicInfoAvatarContent">
                      <div className="basicInfoMediaIcon ">
                        <img
                          src={require("assets/img/update.svg").default}
                          onClick={() => {
                            setModalShow(true);
                          }}
                          alt=""
                          className="pointer"
                        />
                        {/* <Input id="profilePic" type="file" name="myfile" onChange={onChangePicture} /> */}
                      </div>
                      <div className="usernameonly">
                        {userInfo?.surname ? userInfo?.surname : ""}{" "}
                        {userInfo?.first_name ? userInfo?.first_name : ""}{" "}
                        {userInfo?.last_name ? userInfo?.last_name : ""}{" "}
                        {userInfo?.suffix ? userInfo?.suffix : ""}{" "}
                      </div>
                      <Link
                        to={`/admin/account-information/${userInfo.users_id}`}
                        className="greenActionBtn"
                      >
                        <i className="fas fa-user"></i>
                        View Admin Account
                      </Link>
                      {/* <Link to="/admin/account-information"><p className="accountname mt-3"><span style={{ marginRight: '10px' }}><i className="fas fa-user"></i></span>View Admin Account</p></Link> */}
                    </div>
                  </div>
                </div>
                <div className="cardBox basicInfoFormName">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="prefix">
                      Prefix (Ms., Dr.):
                    </label>
                    <select
                      id="prefix"
                      className="form-control my-1"
                      name="surname"
                      {...register("surname")}
                    >
                      <option value="0">--Select--</option>
                      {prifix.map((option, i) => (
                        <option key={i} value={prifix[i].prefix_name}>
                          {prifix[i].prefix_name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      First Name:
                    </label>
                    <input
                      className="form-control"
                      name="first_name"
                      {...register("first_name", {
                        required: "last name is required",
                      })}
                      id="example2cols1Input"
                      placeholder="First Name"
                      type="text"
                    />
                    {errors.first_name && (
                      <small style={{ color: "#ff0000" }}>
                        first name is required
                      </small>
                    )}
                  </FormGroup>
                </div>
                <div className="cardBox basicInfoFormName reverse">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Last Name:
                    </label>
                    <input
                      className="form-control"
                      name="last_name"
                      {...register("last_name", {
                        required: "last name is required",
                      })}
                      id="example2cols1Input"
                      placeholder="Last Name"
                      type="text"
                    />
                    {errors.lastName && (
                      <small style={{ color: "#ff0000" }}>
                        last name is required
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Suffix (Esq, Phd.):
                    </label>
                    <select
                      id="exampleFormControlSelect1"
                      type="select"
                      className="form-control my-1"
                      name="suffix"
                      {...register("suffix")}
                    >
                      {suffix.map((option, i) => (
                        <option key={i} value={suffix[i].suffix_name}>
                          {suffix[i].suffix_name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </div>

                <div className="basicinformation">
                  Admin Notes on this Guest
                </div>
                <div className="cardBox">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea2"
                    >
                      Add any special information about this guest here:
                    </label>
                    <textarea
                      className="form-control"
                      name="about"
                      {...register("about")}
                      rows="3"
                      placeholder="This person will require some special attention getting to event."
                    ></textarea>
                  </FormGroup>

                  {/* <p className="basicinformation mt-2">Partnership Status (optional)</p>
                      <div>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect1"
                              >
                                Partnership Status:
                              </label>
                              <Input id="exampleFormControlSelect1" type="select">
                                <option>--Select--</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> */}
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">Contact Information</div>
                <div className="cardBox">
                  <FormGroup>
                    <label className="form-control-label">Mobile Number:</label>
                    <div className="d-flex align-items-center countryMobileWrpper">
                      <PhoneInput
                        name="mobile_number"
                        autoCorrect="off"
                        placeholder="Enter a Valid Phone Number"
                        country={country}
                        value={phone}
                        onChange={(phone, country) =>
                          handleOnChange({ phone, country })
                        }
                        className="flex-grow"
                      />

                      <input
                        style={{ display: "none" }}
                        placeholder="Enter a Valid Phone Number"
                        autoCorrect="off"
                        id="mobile_number"
                        name="mobile_number"
                        {...register("mobile_number", {
                          required: "Mobile number is required",
                          pattern: {
                            value: /[0-9]+/,
                            message: "This input is number only.",
                          },
                          minLength: {
                            value: 11,
                            message: "Please input proper number.",
                          },
                        })}
                        value={phone}
                        onChange={handleOnChangephone}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="fas fa-phone" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </div>
                  </FormGroup>
                  {errors.mobile_number?.message && (
                    <small style={{ color: "#ff0000" }}>
                      {errors.mobile_number?.message}
                    </small>
                  )}
                </div>

                <div className="cardBox">
                  <FormGroup>
                    <label className="form-control-label">Email:</label>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: emailAddress,
                      })}
                      onClick={handleChangeEmail}
                    >
                      <Input
                        placeholder="Email"
                        type="email"
                        defaultValue={userInfo?.email ? userInfo?.email : ""}
                        disabled
                      />
                      <InputGroupAddon addonType="append" className="pointer">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </div>

                {/*
                    <p className="basicinformation mt-2">Honored Guest Roles</p>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"

                          >
                            Role in the Event
                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[5], options[6], options[7]]}
                            isMulti
                            options={options}

                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* <p className="basicinformation mt-2">Guest Groups</p>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"

                          >
                            Member of Guest Groups (type below to add a group):

                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[1], options[2], options[3], options[4], options[0]]}
                            isMulti
                            placeholder="weqweqweqw"
                            options={options}
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* <div className="basicinformation">Storyz</div> */}
                {/* <div className="cardBox">
                  <FormGroup>
                    <label className="form-control-label">
                      All Storyz in which this guest appears on the guest list.
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[options[8], options[9], options[10]]}
                      isMulti
                      options={options}
                    />
                  </FormGroup>
                </div> */}
              </Col>
            </Row>
            {/* <Row>
              <Col md="12">
                <Button className="blueBtn mx-auto">Update</Button>
              </Col>
            </Row> */}
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
}

export default AccountViewOtherOwner;
