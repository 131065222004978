import React, { useEffect, useState, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";

const CombinedSelectDropdown = ({
  guestListOption,
  fullwidth,
  filterData,
  type,
  status,
  id,
}) => {
  const [selectMenu, setSelectMenu] = useState();
  const [selected, setSelected] = useState(type);
  const selectMenuRef = useRef();

  const setValue = (tittle) => {
    setSelected(tittle);
    filterData(tittle, type, id);
  };

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };

  useEffect(() => {
    let handler = (e) => {
      setSelectMenu(false);
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  return (
    <div
      ref={selectMenuRef}
      className={`dropdownBoxWrapper ${fullwidth ? "fullwidthDrodown" : ""}`}
    >
      <div
        className={
          status === "Closed"
            ? "dropdownTriggered disabled"
            : "dropdownTriggered"
        }
        onClick={dropdownHandle}
      >
        <div className="dropdownPlaceholder">
          <span className="selectInput">{selected}</span>
          <button className="arrowIcon">
            <FaChevronDown />
          </button>
        </div>
      </div>
      {/* {status} */}
      <div className={`dropdownBoxMenuWrapper ${selectMenu ? "active" : ""}`}>
        <div className="dropdownBoxMenuInner">
          <ul className="dropdownMenuListWrapper">
            {guestListOption.map((option, i) => (
              <li
                className={`menuItem ${option.disabled ? "disabled" : ""}`}
                key={i}
                onClick={() => setValue(option.title)}
              >
                <span className="icon">
                  <img src={option.img} alt={option.img} />
                </span>
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CombinedSelectDropdown;
