import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  ModalHeader,
} from "reactstrap";
import { Row, Col } from "reactstrap";

import { TiUser } from "react-icons/ti";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { BiDownArrowCircle } from "react-icons/bi";
import { CombinedGuestPool } from "./style";
import DeleteEverythingInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import ExportGuestIcon from "./../../../assets/img/exportGuestIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import GuestEditDropdown from "components/Dropdown/GuestEditDropdown";
import { combinedService } from "_services/masters/information.combined";
import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import GlobalPoolDropdown from "components/Dropdown/GlobalPoolDropdown";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import LockIcon from "./../../../assets/img/lock-invert-icon.svg";
import { customersupportService } from "../../../_services/masters/customersupport.service";
import ChatIcon from "./../../../assets/img/message-icon.svg";
import SupportTicket from "./../../../assets/img/ticket.svg";
import logoIcon from "./../../../assets/img/organizationlogos.svg";
import SendIcon from "./../../../assets/img/sendIcon.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import { useParams } from "react-router-dom";
import DummyImg from "../../../assets/img/dummyImg.svg";
import SelectDropdown from "components/Dropdown/SelectDropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { SearchBar } = Search;

const CustomerSupportList = ({ args }) => {
  const loginUserArr = JSON.parse(localStorage.getItem("userDetails"));
  const u_name = loginUserArr.name;
  const u_imageUrl = loginUserArr.imageUrl;
  //const uId =  loginUserArr.id;
  const [deleteEverything, setDeleteEverything] = useState(false);
  const [customerSupportModal, setCustomerSupportModal] = useState(false);
  const [customerSupportModal1, setCustomerSupportModal1] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [csData, setCSData] = useState([]);
  const [csAllData, setAllCSData] = useState([]);
  const [problem_title, setProblemTitle] = useState("Select");
  const [problem_title_err, setProblemTitleErr] = useState("");
  const [problem_message_err, setProblemMessageErr] = useState("");
  const [filter_data, setFilterData] = useState("Select");
  const [problem_message, problemMessage] = useState(null);
  const [resTime, setExpectedResponse] = useState("2 hours");
  const [eID, seteID] = useState(0);
  const [topfilter, setTopFilter] = useState("All Guest");
  const [admin_name, setAdminName] = useState(u_name);
  const [admin_img, setAdminImg] = useState(u_imageUrl);
  const [lastChat, setLastChat] = useState("Today");
  const [chatData, setChatData] = useState("");
  const [toID, settoID] = useState(0);
  const [u_id, setUID] = useState(0);
  const [chatList, setChatList] = useState([]);
  const [account_type, setAccountType] = useState(loginUserArr.account_type);

  const params = useParams();
  const CustomerSupportData = [];

  //console.log("selected", selected);
  const FilterData = (tittle, type, e_id) => {
    if (type == "Pending") {
      let objData = { id: e_id, status: tittle };
      customersupportService.updateStatus(objData).then((res) => {
        if (res.status === "success") {
          closeAll();
          getCSData(topfilter);
          toast.success(res.message);
        } else {
          setCustomerSupportModal(false);
          setCustomerSupportModal1(false);
          //toast.error("Something went wrong !!");
        }
      });
    } else {
      setFilterData(tittle);
      setProblemTitle(tittle);
      //setSelected(tittle)
    }
  };

  const handleDeleteEverything = (e) => {
    e.preventDefault();
    setDeleteEverything(!deleteEverything);
  };

  const handelChatModal = () => {
    setChatModal(!chatModal);
  };

  const top_filter = (filter_text) => {
    //alert(filter_text);
    setTopFilter(filter_text);
    getCSData(filter_text);
  };

  const sendRequet = () => {
    //alert(problem_message);
    if (problem_title == "Select" || problem_title == "") {
      setProblemTitleErr("Please select any one");
    } else if (problem_message == null || problem_message == "") {
      setProblemMessageErr("This field is required");
    } else {
      setCustomerSupportModal(false);
      setCustomerSupportModal1(true);
    }
  };

  const handelCustomerSupport = () => {
    seteID(0);
    setProblemTitle("");
    problemMessage("");
    setCustomerSupportModal(true);
    setAdminName(u_name);
    setAdminImg(u_imageUrl);
  };

  const handelCustomerSupportClose = () => {
    seteID(0);
    setCustomerSupportModal(false);
    setProblemTitleErr("");
    setProblemMessageErr("");
  };

  const closeAll = () => {
    seteID(0);
    setCustomerSupportModal(false);
    setCustomerSupportModal1(false);
  };

  //Start By Rohit

  const getChat = (chat_id) => {
    let sendData = { chat_id: chat_id };
    // console.log("sendData", sendData);
    customersupportService.getChat(sendData).then((res) => {
      if (res.status === "success") {
        setChatList(res.data);
      } else {
        ///toast.error("Something went wrong !!");
      }
    });
    //console.log("sendData", sendData);
  };

  const sendData = () => {
    let sendData = {
      chat_id: eID,
      receiver_id: toID,
      chat_messages: chatData,
    };
    // console.log("sendData", sendData);
    customersupportService.sendData(sendData).then((res) => {
      if (res.status === "success") {
        setChatList(res.data);
        setChatData("");
      } else {
        /// toast.error("Something went wrong !!");
      }
    });

    //console.log("sendData", sendData);
  };

  const openChat = (id) => {
    //alert(id);
    setChatList([]);
    seteID(id);
    let objData = { id: id };
    customersupportService.getDetails(objData).then((res) => {
      if (res.status === "success") {
        handelChatModal(true);
        //console.log("details", res.data)
        setProblemTitle(res.data.subject);
        problemMessage(res.data.description);
        setAdminName(res.data.admin_name);
        setAdminImg(res.data.imageUrl);
        setLastChat(res.data.updated_at);
        settoID(res.data.to_id);
        setUID(res.data.userid);
        getChat(id);
      } else {
        setProblemTitle();
        problemMessage();
      }
    });
  };

  const handelCustomerSupport1 = (id) => {
    //alert(id);
    seteID(id);
    let objData = { id: id };
    customersupportService.getDetails(objData).then((res) => {
      if (res.status === "success") {
        //console.log("details", res.data)
        setProblemTitle(res.data.subject);
        problemMessage(res.data.description);
        setAdminName(res.data.admin_name);
        setAdminImg(res.data.imageUrl);
        setCustomerSupportModal(true);
      } else {
        setProblemTitle();
        problemMessage();
      }
    });
  };

  const addData = () => {
    let objData = {
      id: eID,
      subject: problem_title,
      description: problem_message,
    };
    customersupportService.addData(objData).then((res) => {
      if (res.status === "success") {
        closeAll();
        getCSData(topfilter);
        toast.success(res.message);
      } else {
        setCustomerSupportModal(false);
        setCustomerSupportModal1(false);
        toast.error("Something went wrong !!");
      }
    });
  };

  const update = () => {
    let objData = {
      id: eID,
      subject: problem_title,
      description: problem_message,
    };
    customersupportService.update(objData).then((res) => {
      if (res.status === "success") {
        closeAll();
        getCSData(topfilter);
        toast.success(res.message);
      } else {
        setCustomerSupportModal(false);
        setCustomerSupportModal1(false);
        toast.error("Something went wrong !!");
      }
    });
  };

  const getCSData = (filter_guest) => {
    let obj = { filter_guest: filter_guest };
    customersupportService.getCSData(obj).then(
      (res) => {
        if (res.status === "success") {
          const listData = res.data;
          //console.log("listData", listData);
          setAllCSData(res.data);
          for (let i = 0; i < listData.length; i++) {
            let id = listData[i].id;
            let ticket_status = listData[i].status;
            let obj = {
              ticket: (
                <img
                  src={SupportTicket}
                  alt=""
                  width={32}
                  height={32}
                  onClick={() => handelCustomerSupport1(listData[i].id)}
                  className={
                    ticket_status == "Closed" || account_type == "Account Owner"
                      ? "disabled"
                      : ""
                  }
                />
              ),
              name: (
                <div
                  className="guestUserCard ml-4"
                  onClick={(id) => handelCustomerSupport1(listData[i].id)}
                >
                  <div className="guestUserPic">
                    {listData[i].imageUrl !== "" &&
                      listData[i].imageUrl !== null ? (
                      <img src={listData[i].imageUrl} className="pic" alt="" />
                    ) : (
                      <img src={DummyImg} className="pic" alt="" />
                    )}
                  </div>
                  <div className="guestUserBody">
                    <div className="title">{listData[i].admin_name}</div>
                    {/* <div className="subTitle">Sibling of bride </div> */}
                  </div>
                </div>
              ),
              ticket_id: listData[i].ticket_id,

              status:
                account_type !== "Account Owner" ? (
                  listData[i].status
                ) : (
                  <SelectDropdown
                    guestListOption={filterBy}
                    fullwidth
                    filterData={FilterData}
                    type="Pending"
                    status={listData[i].status}
                    id={listData[i].id}
                    className={ticket_status == "Closed" ? "disabled" : ""}
                  />
                ),
              copy_status: listData[i].status,
              msg: moment(new Date(listData[i].updated_at)).format(
                "MMM DD YYYY, h:mm:ss a"
              ),
              chat: (
                <img
                  src={ChatIcon}
                  alt=""
                  className={
                    ticket_status == "Closed"
                      ? "ml-auto d-block disabled"
                      : "ml-auto d-block"
                  }
                  onClick={(id) => openChat(listData[i].id)}
                />
              ),
              guest_name: listData[i].admin_name,
            };
            CustomerSupportData.push(obj);
          }
          setCSData(CustomerSupportData);
        } else {
          //toast.error("Something went wrong !!");
        }
      },
      (error) => {
        //toast.error("Something went wrong !!");
      }
    );
  };

  //

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const TableHead = [
    {
      dataField: "ticket",
      text: <img src={logoIcon} alt="" />,
      sort: false,
    },

    {
      dataField: "name",
      text: (
        <div className="d-flex align-items-center align-middle">
          Admin Requesting Support
        </div>
      ),
      sort: false,
    },
    {
      dataField: "ticket_id",
      text: "Ticket ID",
      sort: true,
    },

    {
      dataField: "status",
      text: "status",
      sort: true,
      headerStyle: { display: "none" },
    },
    {
      dataField: "copy_status",
      text: "status",
      sort: true,
      style: { display: "none" },
      //headerStyle: { display: "none" },
      // width: "1"
    },

    {
      dataField: "msg",
      text: "Last Message",
      sort: true,
    },
    {
      dataField: "chat",
      text: (
        <img
          src={ChatIcon}
          alt=""
          className="ml-auto d-block pointer"
        // onClick={handelChatModal}
        />
      ),
      sort: false,
    },
    {
      dataField: "guest_name",
      style: { display: "none" },
      headerStyle: { display: "none" },
    },
  ];

  const filterBy = [
    { title: "Submitted" },
    { title: "Pending" },
    { title: "In Progress" },
    { title: "Closed" },
  ];
  const ProblemType = [
    { title: "How do I...." },
    { title: "Logins and Accounts" },
    { title: "Payment and Billing" },
    { title: "Storyz and Events" },
    { title: "Guest App" },
    { title: "Other" },
  ];

  useEffect(() => {
    getCSData(topfilter);
  }, []);

  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });

  }, [chatList]);

  return (
    <>
      <ToastContainer />
      <CombinedGuestPool>
        <div className="CombinedGuestPoolWrapper">
          <div className="headingWrapper borderBottom">
            <h4 className="heading-1">Internal Tester Support</h4>
            <span
              className="ml-auto"
              data-tip="Tooltip message will come here....."
            >
              <img
                alt="Organization Name"
                src={require("assets/img/tip.svg").default}
              />
            </span>
            <ReactTooltip />
          </div>

          <div className="organizationPara p-4">
            Contact support, create or check your current support tickets.
          </div>

          <ToolkitProvider
            keyField="id"
            data={csData}
            columns={TableHead}
            search
          >
            {(props) => (
              <div className="dataFundaTable combinedTableWrapper filterWrapper">
                <div className="filterInnerWrapper">
                  <div className="filterList">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter"
                    >
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/monogram-circular.svg")
                            .default
                        }
                      />
                      <SearchBar
                        className="form-control"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </div>
                    <div className="filterBy">
                      <label
                        className="form-control-label mb-0"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Filter by :
                      </label>

                      <FormGroup style={{ width: "129px" }} className="mb-0">
                        <Input
                          id="exampleFormControlSelect1"
                          type="select"
                          onChange={(e) => top_filter(e.target.value)}
                        >
                          <option value="All Guest">All Guest</option>
                          <option value="Pending">Pending</option>
                          <option value="Submitted">Submitted</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Closed">Closed</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="guestUserWrap">
                    {account_type !== "Account Owner" ? (
                      <div
                        className="exportWrap"
                        onClick={handelCustomerSupport}
                      >
                        <img
                          src={SupportTicket}
                          alt=""
                          width={32}
                          height={32}
                        />
                        <div>New Support Ticket</div>
                      </div>
                    ) : (
                      ""
                    )}
                    <Modal
                      isOpen={chatModal}
                      toggle={handelChatModal}
                      {...args}
                      className="addSupportTicketModalWrapper"
                    >
                      <ModalHeader
                        className="fontSize20 whiteColor align-items-center"
                        toggle={handelChatModal}
                      >
                        Chat with a Guest
                      </ModalHeader>
                      <ModalBody className="bg4">
                        <Row>
                          <Col>
                            <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                              <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                                <img src={ChatIcon} alt="" className="mr-2" />
                                Chat Header
                              </div>
                              <div className="fontSize16 link-opacity-100 ml-2 text-decoration-underline">
                                end chat
                              </div>
                            </div>

                            <div className="mt-0">
                              <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                                <div className="my-2 text-left">
                                  <div className="">
                                    <div className="fontSize14 fontWeight300 blackColor mb-3">
                                      {moment(new Date(lastChat)).format(
                                        "MMM DD YYYY, h:mm: a"
                                      )}
                                    </div>
                                    <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                      Problem Type: {problem_title}
                                    </div>
                                  </div>
                                </div>

                                <div className="my-2 text-left">
                                  <div className="">
                                    <div className="fontSize14 fontWeight300 blackColor mb-3">
                                      {moment(new Date(lastChat)).format(
                                        "MMM DD YYYY, h:mm: a"
                                      )}
                                    </div>
                                    <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                      {problem_message}
                                    </div>
                                  </div>
                                </div>
                                {/* {chatList} */}
                                {chatList.map((data, i) => (
                                  // <div className="my-2 text-left">
                                  <div
                                    ref={bottomRef}
                                    className={
                                      data.sender_id == u_id
                                        ? "my-2 text-right"
                                        : "my-2 text-left text-left"
                                    }
                                    key={Math.random()}
                                  >
                                    <div className="">
                                      <div className="fontSize14 fontWeight300 blackColor mb-3">
                                        {moment(
                                          new Date(data.updated_at)
                                        ).format("MMM DD YYYY, h:mm: a")}
                                      </div>
                                      <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                        {data.chat_messages}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* Rohit Chat */}
                              <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                                <input
                                  type="email"
                                  className="form-control sendBtnInput"
                                  id="exampleFormControlInput1"
                                  placeholder="This is chat text"
                                  value={chatData}
                                  onChange={(e) => setChatData(e.target.value)}
                                />
                                <Button type="button" className="sendBtn">
                                  <img
                                    src={SendIcon}
                                    alt=""
                                    width={24}
                                    height={24}
                                    className={
                                      chatData == ""
                                        ? "sendBtnIcon disabled"
                                        : "sendBtnIcon"
                                    }
                                    onClick={sendData}
                                  />
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                    <Modal
                      isOpen={customerSupportModal}
                      toggle={handelCustomerSupportClose}
                      {...args}
                      className="addSupportTicketModalWrapper"
                    >
                      <ModalHeader
                        className="fontSize20 whiteColor align-items-center"
                        toggle={handelCustomerSupportClose}
                      >
                        Customer Support
                      </ModalHeader>
                      <ModalBody className="pb-0">
                        <Row>
                          <Col>
                            <div className="d-flex align-items-center fontSize18 blackColor ml-n2 mt-n2">
                              <img
                                src={SupportTicket}
                                alt=""
                                className="rounded-circle"
                              />
                              Support Ticket
                            </div>
                            <p className="mb-4 fontSize16 blackColor">
                              Provide your problem report below. We will get
                              back to you in Messages.
                            </p>
                            <div className="d-flex align-items-center justify-content-center mb-4 fontSize14 blackColor fontWeight300">
                              <img
                                src={
                                  admin_img !== "" && admin_img !== null
                                    ? admin_img
                                    : DummyImg
                                }
                                alt=""
                                className="mr-3 rounded-circle"
                                width={32}
                                height={32}
                              />
                              {admin_name}
                            </div>

                            <div className="mb-4">
                              <div style={{ color: "red" }}>
                                {problem_title == "Select" ||
                                  problem_title == ""
                                  ? problem_title_err
                                  : ""}
                              </div>
                              <SelectDropdown
                                guestListOption={ProblemType}
                                fullwidth={true}
                                filterData={FilterData}
                                type="Select"
                                status={problem_title}
                                id={eID}
                              />
                            </div>
                            <div className="mb-4 form-group">
                              <label className="fontSize16 blackColor fontWeight300">
                                Describe your problem in detail
                              </label>
                              <div style={{ color: "red" }}>
                                {problem_message == null ||
                                  problem_message == ""
                                  ? problem_message_err
                                  : ""}
                              </div>
                              <textarea
                                className="form-control large"
                                id="exampleFormControlTextarea1"
                                rows="5"
                                placeholder="Type something here"
                                onChange={(e) => problemMessage(e.target.value)}
                              >
                                {problem_message}
                              </textarea>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button
                          color="primary"
                          className="flex-grow-1 blueBtn rounded"
                          onClick={sendRequet}
                        >
                          Send Request
                        </Button>
                        <Button
                          className="btn-default cancelBtn"
                          onClick={handelCustomerSupportClose}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal
                      isOpen={customerSupportModal1}
                      toggle={closeAll}
                      {...args}
                      className="addSupportTicketModalWrapper"
                    >
                      <ModalHeader
                        className="fontSize20 whiteColor align-items-center"
                        toggle={closeAll}
                      >
                        Customer Support
                      </ModalHeader>
                      <ModalBody className="pb-0">
                        <Row>
                          <Col>
                            <div className="d-flex align-items-center fontSize18 blackColor ml-n2 mt-n2">
                              <img
                                src={SupportTicket}
                                alt=""
                                className="rounded-circle"
                              />
                              Support Ticket
                            </div>
                            <p className="mb-4 fontSize16 blackColor">
                              Your Request has been sent.
                            </p>
                            <div className="d-flex align-items-center justify-content-center mb-4 fontSize14 blackColor fontWeight300">
                              <img
                                src={
                                  u_imageUrl !== "" && u_imageUrl !== null
                                    ? u_imageUrl
                                    : DummyImg
                                }
                                alt=""
                                className="mr-3 rounded-circle"
                                width={32}
                                height={32}
                              />
                              {u_name}
                            </div>
                            <div className="fontSize14 fontWeight500 blackColor text-center mb-4">
                              Expected Response within:
                              <div className="fontSize18 mt-2">{resTime}</div>
                            </div>
                            <div>{problem_title}</div>
                            <p>{problem_message}</p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        {eID == 0 ? (
                          <Button
                            color="primary"
                            className="flex-grow-1 blueBtn rounded"
                            onClick={addData}
                          >
                            Continue
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="flex-grow-1 blueBtn rounded"
                            onClick={update}
                          >
                            Continue
                          </Button>
                        )}
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
                <div className="tableResponsiveWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    pagination={pagination}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </CombinedGuestPool>
    </>
  );
};

export default CustomerSupportList;
