import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { Row, Col } from "reactstrap";

import { TiUser } from "react-icons/ti";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { BiDownArrowCircle } from "react-icons/bi";
import { CombinedGuestPool } from "./style";
import DeleteEverythingInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import ExportGuestIcon from "./../../../assets/img/exportGuestIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import GuestEditDropdown from "components/Dropdown/GuestEditDropdown";
import { combinedService } from "_services/masters/information.combined";
import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import GlobalPoolDropdown from "components/Dropdown/GlobalPoolDropdown";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import LockIcon from "./../../../assets/img/lock-invert-icon.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useParams } from "react-router-dom";
import DummyImg from "../../../assets/img/dummyImg.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { GrCircleInformation } from "react-icons/gr";
import { CSVLink, CSVDownload } from "react-csv";

import ProgressIcon from "../../../assets/img/progressIcon.svg";
import MessageIcon from "../../../assets/img/message-icon.svg";
import YellowCircle from "../../../assets/img/yellow-circle.svg";
import GreenCircle from "../../../assets/img/green-circle.svg";
import RedCircle from "../../../assets/img/red-circle.svg";
import BlueCircle from "../../../assets/img/blue-circle.svg";
import CombinedSelectDropdown from "components/Dropdown/CombinedSelectDropdown";
import { useHistory, Link } from "react-router-dom";


const { SearchBar } = Search;

const CombinedList = ({ args }) => {
  const [deleteEverything, setDeleteEverything] = useState(false);
  const [exportGuestList, setExportGuestList] = useState(false);
  const [mergeAll, setMergeAll] = useState(false);
  const [isChack, setCheckBox] = useState("");
  const [eventData, setEventData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [EvDataArr, setEvData] = useState([]);
  const [guestId, setGuestId] = useState(0);
  const [load_id, setReload] = useState(0);
  const [delGuestData, setguestDelete] = useState(null);
  const [guestPoolData, setGuestPoolData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [mstoryz_data, setMStoryzData] = useState(null);
  const [filter_data, setFilterData] = useState("All Guests");
  const [count, setCount] = useState(0);
  const [cs_respons, setCSRespons] = useState([]);
  const [storyz_data, setStoryzData] = useState(null);
  const params = useParams();
  const history = useHistory();
  localStorage.setItem("count_combined", 0);


  const FilterData = (dropdwon_data) => {
    //alert(dropdwon_data);
    let filterVal = dropdwon_data;
    if (dropdwon_data === "Pending") {
      filterVal = "PENDING";
    }
    if (dropdwon_data === "Going") {
      filterVal = "GOING";
    }
    if (dropdwon_data === "Not Going") {
      filterVal = "NOT GOING";
    }
    if (dropdwon_data === "Attended Event") {
      filterVal = "ATTENDED";
    }
    setFilterData(filterVal);
    getCombinedlist(filterVal);
  };

  const headers = [
    { label: "PREFIX", key: "PREFIX" },
    { label: "FIRST NAME", key: "FIRST_NAME" },
    { label: "LAST NAME", key: "LAST_NAME" },
    { label: "SUFFIX", key: "SUFFIX" },
    { label: "COUNTRY CODE", key: "COUNTRY_CODE" },
    { label: "MOBILE NUMBER", key: "MOBILE_NUMBER" },
    { label: "EMAIL ADDRESS", key: "EMAIL_ADDRESS" },
    { label: "ADMIN NOTES", key: "ADMIN_NOTES" },
  ];

  let obj = {
    dataField: "action",
    text: <div className="d-flex align-items-center align-middle">Action</div>,
    sort: false,
  };

  let guest_name = {
    dataField: "guest_name",
    sort: false,
    style: { display: "none" },
  };

  let gnameobj = {
    dataField: "guest_data",
    text: (
      <div className="d-flex align-items-center align-middle">
        <TiUser size={20} className="mr-3 userIcon" />
        Guest Name
      </div>
    ),
    sort: false,
  };

  const handleDeleteEverything = (e) => {
    setDeleteEverything(true);
  };

  const gotoKeepsakeAlbum = (gid) => {
    history.push(`/admin/storyz-keepsake-album/${params.sid}`);
  };

  const gotoGuest = (gid) => {
    history.push(
      `/admin/edit-user-guest-list/${params.sid}/${params.sid}/${gid}?type=guests`
    );
  };

  const gotoEVauthentication = (gid) => {
    history.push(`/admin/event-guest-authentication/${params.sid}/${gid}`);
  };

  const closeDelete = (e) => {
    setDeleteEverything(false);
  };

  const getGuestData = (guest_id) => {
    //alert(guest_id);
    let data = { guest_id: guest_id, guest_storyz_id: Number(params.sid) };
    combinedService.addGuestEvent(data).then((res) => {
      if (res.status === "success") {
        getCombinedlist();
        toast.success(res.message);
      } else {
        getCombinedlist();
        toast.success(res.message);
      }
    });
  };

  const GlobalPoolOptionList = [];
  const getGuestPool = () => {
    let resdata = { organization_id: Number(3) };
    combinedService.getGuestPool(resdata).then(
      (res) => {
        if (res.status === "success") {
          const guestPool = res.data;
          for (let j = 0; j < guestPool.length; j++) {
            let obj = {};
            obj["guest_id"] = guestPool[j].guest_id;
            obj["guest_img"] =
              guestPool[j].guest_imageUrl === ""
                ? DummyImg
                : guestPool[j].guest_imageUrl;
            obj["title"] =
              guestPool[j].guest_first_name +
              " " +
              guestPool[j].guest_last_name;
            obj["type"] = "Guest";
            obj["handleClick"] = getGuestData;
            GlobalPoolOptionList.push(obj);
          }
          setGuestPoolData(GlobalPoolOptionList);
          //console.log("getGuestPool", res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const deleteGuest = () => {
    let deleteData = {
      guest_id: Number(guestId),
      guest_storyz_id: Number(params.sid),
    };
    //console.log('deleteData', deleteData);
    combinedService.guestDelete(deleteData).then((res) => {
      if (res.status === "success") {
        //setGuestData([])
        getCombinedlist();
        closeDelete();
        toast.success(res.message);
      } else {
        closeDelete();
        toast.success(res.message);
        getCombinedlist();
      }
    });
  };

  const handleMergeAll = (e) => {
    getStoryz();
    e.preventDefault();
    setMergeAll(!mergeAll);
  };

  const getStoryz = () => {
    let SData = { storyz_id: Number(params.sid) };
    combinedService.getStoryz(SData).then((res) => {
      if (res.status === "success") {
        setMStoryzData(res.dataStoryz);
      } else {
        //toast.success(res.message);
      }
    });
  };

  const mergeAllGuest = () => {
    let SData = { storyz_id: Number(params.sid) };
    combinedService.mergeAllGuest(SData).then((res) => {
      if (res.status === "success") {
        //setGuestData([])
        getCombinedlist();
        toast.success(res.message);
        setMergeAll(false);
      } else {
        toast.success(res.message);
        setMergeAll(false);
        getCombinedlist();
      }
    });
  };

  const handleExportGuestList = (e) => {
    let resdata = { storyz_id: Number(params.sid) };
    combinedService.getCSVData(resdata).then(
      (res) => {
        if (res.status === "success") {
          setCSVData(res.data);
          setStoryzData(res.dataStoryz);
          //alert(res.message)
          //toast.success(res.message);
        } else {
          //toast.error("Something went wrong");
        }
      },
      (error) => {
        toast.error("Something went wrong");
      }
    );
    //setCSVData([])
    setExportGuestList(!exportGuestList);
  };

  const handleChange = (event, guest_id, event_id) => {
    setCheckBox(event.target.checked === true ? "checked" : "");
    let check_box_val = event.target.checked === true ? 1 : 0;
    let data = {
      check_box_val: check_box_val,
      guest_id: guest_id,
      event_id: event_id,
      guest_storyz_id: Number(params.sid),
    };
    combinedService.updateGuestEvent(data).then(
      (res) => {
        if (res.status === "success") {
          //alert(res.message)
          getCombinedlist();
          toast.success(res.message);
        } else {
          toast.error("Something went wrong");
        }
      },
      (error) => {
        toast.error("Something went wrong");
      }
    );
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      disabled: "disabled",
      handleClick: gotoGuest,
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
      disabled: "disabled",
      handleClick: gotoKeepsakeAlbum,
    },
    {
      img: LockIcon,
      title: "Event Authentication",
      disabled: "disabled",
      handleClick: gotoEVauthentication,
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteEverything,
    },
  ];

  const CombinedTableData = [];

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const getCombinedlist = (filterData = "All Guests") => {
    let eventDataArr = [];
    let rowDataArr = [];
    //setEventData(eventDataArr)
    // setGuestData(rowDataArr)
    let resdata = { storyz_id: Number(params.sid), filter_data: filterData };
    combinedService.getCombinedlist(resdata).then(
      (res) => {
        if (res.status === "success") {
          let respons = filterData === "Have Messaged" ? [] : res.data;
          setCSRespons(respons);

          let evData = respons[0] != undefined ? respons[0].event_data : [];
          setEvData(evData);
          eventDataArr.push(obj);
          eventDataArr.push(gnameobj);
          for (let i = 0; i < evData.length; i++) {
            let obj2 = {
              dataField: `event_${i}`,
              text: (
                <Link to={`/admin/event-dashboard/${params.sid}/${evData[i].event_id}`}><div className="eventCard">
                  <img
                    src={
                      evData[i].cover_image ? evData[i].cover_image : DummyImg
                    }
                    alt="No img"
                    className="img"
                  />
                  {evData[i].event_name}
                </div></Link>
              ),
              sort: false,
            };
            eventDataArr.push(obj2);
          }
          eventDataArr.push(guest_name);

          setEventData(eventDataArr);
          for (let c = 0; c < respons.length; c++) {
            let fname = respons[c].first_name;
            let guest_id = respons[c].guest_id;
            let lname =
              respons[c].last_name !== null ? respons[c].last_name : "";
            let fullname = fname + " " + lname;
            let gobj = {
              guest_name: fullname,
              action: (
                <div className="d-flex align-items-center">
                  <GuestEditDropdown
                    guestListOption={guestListOption}
                    guest_id={guest_id}
                    setGuestId={setGuestId}
                    setguestDelete={setguestDelete}
                    storyz_id={Number(params.sid)}
                  />
                </div>
              ),
            };
            gobj["guest_data"] = (
              <div className="guestUserCard">
                <div className="guestUserPic">
                  <img
                    src={
                      respons[c].imageUrl === "" ? DummyImg : respons[c].imageUrl
                    }
                    className="pic"
                    alt=""
                  />
                </div>
                <div className="guestUserBody">
                  <div className="title">{fullname}</div>
                  {/* <div className="subTitle">Sibling of bride </div> */}
                </div>
              </div>
            );
            let evArr = respons[c].event_data;
            for (let e = 0; e < evArr.length; e++) {
              let g_ev_id = evArr[e].event_id;
              let g_ev_status = evArr[e].ev_status;
              let isTrue = "";
              isTrue =
                evArr[e].event_id === evArr[e].ev_id && g_ev_status === 0
                  ? "checked"
                  : "";
              gobj["event_" + e] = (
                <div className="custom-control custom-checkbox">
                  {isTrue === "checked" ? (
                    <>
                      {" "}
                      <label className="container3">
                        <input
                          type="checkbox"
                          name="storyz_methods"
                          defaultChecked="checked"
                          onChange={(e) => handleChange(e, guest_id, g_ev_id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </>
                  ) : (
                    <>
                      <label className="container3">
                        <input
                          type="checkbox"
                          name="storyz_methods"
                          defaultChecked=""
                          onChange={(e) => handleChange(e, guest_id, g_ev_id)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </>
                  )}
                </div>
              );
            }
            rowDataArr.push(gobj);
          }
          //setCSVData(rowDataArr);
          setGuestData(rowDataArr);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  useEffect(() => {
    getGuestPool();
  }, []);

  useEffect(() => {
    getCombinedlist();
  }, []);

  const filterBy = [
    {
      img: EditPencilIcon,
      title: "All Guests",
    },
    {
      img: ProgressIcon,
      title: "Incomplete Records",
    },
    {
      img: MessageIcon,
      title: "Have Messaged",
    },
    {
      img: YellowCircle,
      title: "Pending",
    },
    {
      img: GreenCircle,
      title: "Going",
    },
    {
      img: RedCircle,
      title: "Not Going",
    },
    {
      img: BlueCircle,
      title: "Attended Event",
    },
  ];

  return (
    <>
      <ToastContainer />
      <CombinedGuestPool>
        <div className="CombinedGuestPoolWrapper">
          <div className="headingWrapper borderBottom">
            <h4 className="heading-1">Combined Guest List for All Events</h4>
            <GrCircleInformation
              className="ml-auto iconColor infoToolTip"
              data-tip="Tooltip message will come here....."
            />
            <ReactTooltip />
          </div>
          <div className="borderBottom organizationPara p-4">
            <p>
              This view shows all guests invited to all events in your Storyz.
              Guests marked with a “check” will be part of the invitation list
              for each event. The current RSPV status of that guest is indicated
              to the right of the check.
            </p>
            <p className="mb-0">
              If you want to remove all guests from an event, uncheck the
              checkbox at the top of the list.
            </p>
          </div>
          <div>
            <Modal
              isOpen={deleteEverything}
              toggle={handleDeleteEverything}
              {...args}
              className="deleteEverythingModal"
            >
              <ModalBody className="pb-0">
                <div className="deleteEverythingHeader border-bottom mb-4">
                  <img
                    src={DeleteEverythingInfoIcon}
                    alt=""
                    className="deleteEverythingInfoIcon"
                  />
                  <div className="head">
                    Remove This Guest From the Guest List?
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="deleteEverythingThumWrapper">
                      <div className="deleteEverythingThum">
                        <img
                          src={
                            delGuestData === null
                              ? DummyImg
                              : delGuestData.userData.imageUrl === ""
                                ? DummyImg
                                : delGuestData.userData.imageUrl
                          }
                          alt=""
                          className="deleteEverythingThumImg"
                        />
                      </div>
                      <div className="deleteEverythingThumContent">
                        <div className="deleteEverythingthumbTitle">
                          {delGuestData === null
                            ? ""
                            : delGuestData.userData.first_name +
                            " " +
                            delGuestData.userData.last_name}
                        </div>
                        <div className="deleteEverythingthumbSubTitle">
                          {/* Role in Event */}
                        </div>
                      </div>
                    </div>

                    <div className="repeateventDetails pl-0">
                      <div className="fontSize14 grayColor mr-2">
                        {delGuestData === null
                          ? ""
                          : moment(
                            delGuestData.storyzData.event_start_date
                          ).format("D MMM")}
                      </div>
                      <div>
                        <img
                          className="evimages ml-0"
                          src={
                            delGuestData === null
                              ? DummyImg
                              : delGuestData.storyzData.project_image === ""
                                ? DummyImg
                                : delGuestData.storyzData.project_image
                          }
                          alt=""
                        />
                      </div>
                      <div className="repeateventTitle">
                        <div>
                          {delGuestData === null
                            ? ""
                            : delGuestData.storyzData.storyz_name}
                        </div>
                        <div className="repeateventlocation">
                          {" "}
                          {delGuestData === null
                            ? 0 + " Event"
                            : delGuestData.storyzData.event_count + " Event"}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2 ">
                  <Col>
                    <p>
                      Confirm you want to remove this guest from the Event guest
                      list.
                    </p>
                    <p>This will NOT remove the guest data.</p>
                    <p>
                      Instead it will just remove the guest from the invite list
                      for this event.
                    </p>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                <Button
                  color="primary"
                  className="flex-grow-1 pinkBtn"
                  onClick={deleteGuest}
                >
                  Remove Guest from List
                </Button>
                <Button className="btn-default cancelBtn" onClick={closeDelete}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <ToolkitProvider
            keyField="id"
            data={
              cs_respons.length > 0 && filter_data !== "Have Messaged"
                ? guestData
                : []
            }
            columns={
              cs_respons.length > 0 && filter_data !== "Have Messaged"
                ? eventData
                : [obj]
            }
            search
          >
            {(props) => (
              <div className="dataFundaTable combinedTableWrapper filterWrapper">
                <div className="filterInnerWrapper">
                  <div className="filterList">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter"
                    >
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/monogram-circular.svg")
                            .default
                        }
                      />
                      <SearchBar
                        className="form-control"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </div>
                    <div className="filterBy">
                      <label
                        className="form-control-label mb-0 no-wrap"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Filter by :
                      </label>
                      <CombinedSelectDropdown
                        guestListOption={filterBy}
                        fullwidth
                        filterData={FilterData}
                        type="All Guest"
                      />
                    </div>
                  </div>
                  <div className="guestUserWrap">
                    {EvDataArr.length > 0 ? (<div className="addGuest" onClick={handleMergeAll}>
                      <img
                        src={require("assets/img/guestlist.svg").default}
                        alt=""
                        width={30}
                      />
                      <div className="nowrap">Merge All</div>
                    </div>) : ("")}

                    <Modal
                      isOpen={mergeAll}
                      toggle={handleMergeAll}
                      {...args}
                      className="mergeAllModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="mergeAllHeader border-bottom mb-4">
                          <img
                            src={DeleteEverythingInfoIcon}
                            alt=""
                            className="mergeAllInfoIcon"
                          />
                          <div className="head">
                            Merge Your Guest Lists Across All Events?
                          </div>
                        </div>
                        <Row>
                          <Col>
                            <div className="mergeAllThumWrapper">
                              <div className="mergeAllDate mr-2">
                                {" "}
                                {mstoryz_data !== null
                                  ? moment(
                                    mstoryz_data.event_start_date
                                  ).format("D MMM")
                                  : "NA"}
                              </div>
                              <div className="mergeAllThum">
                                <img
                                  src={
                                    mstoryz_data === null
                                      ? DummyImg
                                      : mstoryz_data.project_image === ""
                                        ? DummyImg
                                        : mstoryz_data.project_image
                                  }
                                  alt=""
                                  className="mergeAllThumImg"
                                />
                              </div>
                              <div className="mergeAllThumContent">
                                <div className="mergeAllthumbTitle">
                                  {mstoryz_data !== null
                                    ? mstoryz_data.storyz_name
                                    : ""}
                                </div>
                                <div className="mergeAllthumbSubTitle">
                                  {mstoryz_data !== null
                                    ? mstoryz_data.event_count
                                    : 0}{" "}
                                  Event
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col>
                            <p>
                              Confirm that you want to merge the separate guest
                              lists, so all events have the same guest list.
                            </p>
                            <p>
                              All your guests will now be invited to all the
                              events in your Storyz.
                            </p>
                            <p className="bold">
                              This operation is NOT un-doable.
                            </p>

                            <p>
                              You can adjust the invitation of individual guests
                              in the guest list after you complete the merge.
                            </p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button
                          color="primary"
                          className="flex-grow-1 pinkBtn"
                          onClick={() => mergeAllGuest()}
                        >
                          Merge the list
                        </Button>
                        <Button
                          className="btn-default cancelBtn"
                          onClick={handleMergeAll}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                   
                      <GlobalPoolDropdown
                        guestListOption={
                          guestPoolData.length === 0 ? [] : guestPoolData
                        }
                        placeholder="Add from Global Pool"
                        icon={require("assets/img/guestlist.svg").default}
                      />
                    { EvDataArr.length > 0 ? (<div className="exportWrap" onClick={handleExportGuestList}>
                      <RiArrowDownCircleLine className="icon" />
                      <div>Export</div>
                    </div>) : ("")}

                    <Modal
                      isOpen={exportGuestList}
                      toggle={handleExportGuestList}
                      {...args}
                      className="exportGuestModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="exportGuestHeader border-bottom flex-row">
                          <img
                            src={ExportGuestIcon}
                            alt=""
                            className="exportGuestInfoIcon"
                          />
                          <div className="head">Export Combined Guest List</div>
                        </div>
                        <Row>
                          <Col>
                            <p>
                              Export Your Combined Guest List for all Events in
                              a variety of formats.
                            </p>
                            <div className="exportGuestThumWrapper">
                              {storyz_data === null ? (
                                ""
                              ) : (
                                <>
                                  <div className="date mr-2">
                                    {storyz_data === null
                                      ? "NA"
                                      : moment(
                                        storyz_data.event_start_date
                                      ).format("D MMM")}
                                  </div>
                                  <div className="exportGuestThum">
                                    <img
                                      src={
                                        storyz_data === null
                                          ? DummyImg
                                          : storyz_data.project_image === ""
                                            ? DummyImg
                                            : storyz_data.project_image
                                      }
                                      alt=""
                                      className="exportGuestThumImg"
                                    />
                                  </div>
                                  <div className="exportGuestThumContent">
                                    <div className="exportGuestthumbTitle">
                                      {storyz_data === null
                                        ? ""
                                        : storyz_data.storyz_name}
                                    </div>
                                    <div className="exportGuestthumbSubTitle">
                                      {storyz_data.totalEvent} Event
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col>
                            <div className="exportFileListWrapper">
                              <div className="exportFileList">
                                <div className="exportFileWrapper">
                                  <img
                                    src={FileIcon}
                                    alt=""
                                    className="exportFile"
                                  />
                                  <div className="exportFileText">.csv</div>
                                </div>
                                <CSVLink
                                  data={csvData}
                                  headers={headers}
                                  filename={"combined_guest_record.csv"}
                                  newLineSeparator="\r\n"
                                >
                                  <div className="exportFileBtn">
                                    <BiDownArrowCircle className="exportDownloadIcon" />
                                    Download CSV
                                  </div>
                                </CSVLink>
                              </div>
                              <div className="exportFileList">
                                <div className="exportFileWrapper">
                                  <img
                                    src={FileIcon}
                                    alt=""
                                    className="exportFile"
                                  />
                                  <div className="exportFileText">.xlsx</div>
                                </div>
                                <CSVLink
                                  data={csvData}
                                  headers={headers}
                                  filename={"combined_guest_record.xlsx"}
                                  newLineSeparator="\r\n"
                                >
                                  <div className="exportFileBtn">
                                    <BiDownArrowCircle
                                      size={20}
                                      className="exportDownloadIcon"
                                    />
                                    Download Excel
                                  </div>
                                </CSVLink>
                              </div>
                              <div className="exportFileList">
                                <div className="exportFileWrapper">
                                  <img
                                    src={FileIcon}
                                    alt=""
                                    className="exportFile"
                                  />
                                  <div className="exportFileText">Sheets</div>
                                </div>
                                <div className="exportFileBtn">
                                  <BiDownArrowCircle className="exportDownloadIcon" />
                                  Create Google Docs
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button
                          className="btn-default cancelBtn w-100"
                          onClick={() => handleExportGuestList(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </div>
                <div className="tableResponsiveWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                    pagination={pagination}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </CombinedGuestPool>
    </>
  );
};

export default CombinedList;
