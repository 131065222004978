import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     return <Route {...rest} render={props => {
//         return localStorage.getItem('userDetails') ?
//             <Layouts><Component /></Layouts>
//             : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
//     }} />
// };

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                const userDetails = JSON.parse(localStorage.getItem("userDetails"));

                sessionStorage.setItem(
                    "service_level",
                    userDetails ? userDetails.service_level : ""
                );
                return localStorage.getItem("userDetails") ? (
                    <Component />
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                );
            }}
        />
    );
};
