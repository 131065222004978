import React from 'react'
import SelectServices from "../views/pages/examples/selectServices"
import AuthFooter from 'components/Footers/AuthFooter'

const SelectServiceLayout = () => {
  return (
    <>
    <div className="main-content login-backimage">
    <SelectServices />
    </div>
    <AuthFooter />
    </>
  )
}

export default SelectServiceLayout