import React from "react";

const FullScreen = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.5"
        y1="2.37988"
        x2="10.5"
        y2="2.37988"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="15.5"
        y1="2.37988"
        x2="23.5"
        y2="2.37988"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="1.5"
        y1="23.5"
        x2="1.5"
        y2="2.5"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="1.5"
        y1="-1.5"
        x2="21.4113"
        y2="-1.5"
        transform="matrix(0.741991 -0.67041 0.700071 0.714073 8 18.28)"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="23.5"
        y1="23.5"
        x2="23.5"
        y2="14.98"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M23.5 10.6001V3.4001"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="2.5"
        y1="23.5"
        x2="23.5"
        y2="23.5"
        stroke="#666666"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default FullScreen;
