import React from "react"
import Modal from "../../components/Modal"
import InteractiveMapContent from "./InteractiveMapContent"

const InteractiveMapModal = () => {
  return (
    <Modal>
      <InteractiveMapContent />
    </Modal>
  )
}

export default InteractiveMapModal
