export function authHeader(isImage) {
    // Return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem("userDetails"));
    let admintoken = JSON.parse(localStorage.getItem("userAdmintoken"));
    if (user && user.token) {
        if (isImage !== null && isImage) {
            return {
                token: user.token,
            };
        } else {
            return {
                "Content-Type": "application/json",
                token: user.token
                    ? user.token
                    : admintoken.token
                        ? admintoken.token
                        : "",
            };
        }
    } else {
        return {};
    }
}
