import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Container,
    Row,
    Col,
} from "reactstrap";
import GuestGroupIcon from "./../../../assets/img/guest-group-icon.svg";
import placeholde from "../../../assets/img/placeholder.jpg";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { combinedService } from "../../../_services/masters/information.combined";
import { Link, useHistory } from "react-router-dom";
function DeleteGuestList({ show, onHide, single, urlRedirect }) {
    let history = useHistory();
    const common = useSelector((state) => {
        return state.commonData.common;
    });
    const handleDeleteGuestRemove = () => {
        combinedService.deleteguestgroup(single.id).then((res) => {
            if (res.status === "success") {
                sessionStorage.removeItem("detailsOfUser");
                onHide();
                toast.success("Delete successfully");
                history.push(
                    `/admin/event-guest-group-list/${common?.sid}/${common?.eid}?type=guests`
                );
            } else {
            }
        });
    };
    return (
        <Modal isOpen={show} toggle={onHide} className="deleteGuestGroupModal">
            <ModalBody className="pb-0">
                <div className="deleteGuestGroupHeader d-flex align-items-center border-bottom mb-4 pb-2 ">
                    <img
                        src={GuestGroupIcon}
                        alt=""
                        className="deleteGuestGroupInfoIcon mr-2 mb-0"
                    />
                    <div className="head">Remove this Guest Group?</div>
                </div>
                <Row>
                    <Col>
                        <div className="deleteGuestGroupThumWrapper">
                            <div className="deleteGuestGroupThum">
                                <img
                                    src={single?.group_image ? single?.group_image : placeholde}
                                    alt=""
                                    className="deleteGuestGroupThumImg"
                                />
                            </div>
                            <div className="deleteGuestGroupThumContent">
                                <div className="deleteGuestGroupthumbTitle">
                                    {single?.group_name}
                                </div>
                                <div className="deleteGuestGroupthumbSubTitle">
                                    Role in Event
                                </div>
                            </div>
                        </div>

                        <div className="repeateventDetails pl-0">
                            <div>
                                <img
                                    className="evimages ml-0"
                                    src={
                                        common?.singleEvent?.cover_image
                                            ? common?.singleEvent?.cover_image
                                            : placeholde
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="repeateventTitle">
                                <div>{common?.singleEvent?.event_name}</div>
                                <div className="repeateventlocation">
                                    {common?.singleEvent?.location
                                        ? common?.singleEvent?.location
                                        : "Location"}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2 ">
                    <Col>
                        <p>
                            Confirm that you want to remove this guest group. This will NOT
                            affect the Guest List, invites or RSVPs.
                        </p>
                        <p>
                            Guests will remain in another groups they are assigned to. If they
                            are not part of any other group, they will be listed as individual
                            in the guest list.
                        </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                <Button
                    color="primary blueBtn"
                    className="flex-grow-1"
                    onClick={() => handleDeleteGuestRemove()}
                >
                    Remove Guest from Group
                </Button>
                <Button className="btn-default cancelBtn" onClick={onHide}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default DeleteGuestList;
