import dataFundatadata from "components/Todo/DataFundata";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FileStackApp from "../FileStackApp/FileStackKeepsakeAlbum";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ReactSession } from "react-client-session";
import moment from "moment";
const ImageUploadModal = ({
  closeModal,
  getAllstate,
  setImgModalBox,
  openBrowImgModal,
  storyz_id,
  event_id,
  datakeepskeAlbum,
}) => {
  console.log("getAllstate", getAllstate);
  const mimeStype = getAllstate.imaDatajson.mimetype.split("/");
  //console.log( "mimeStype", mimeStype[0]);
  const [fileData, setFileData] = useState([]);
  const [photolabel, setPhotolabel] = useState("");
  const [Photocaption, setPhotocaption] = useState(getAllstate.photo_caption);
  const [gallerylocation, setGallerylocation] = useState("");
  const [filestack, setFilestack] = useState([]);
  const [mime_type, setMimetype] = useState(mimeStype[0]);
  const [guestID, setGuestID] = useState(0);
  const [guestIDErr, setGuestIDErr] = useState("");
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );
  const inputRef = useRef();
  //const [mime_type, setMimetype] = useState(mimeStype[0]);

  const openFileManager = () => {
    //alert("hii");
    //setImgModalBoxfirst(true);
    //setImgModalBox(true);
    openBrowImgModal();
    closeModal();
    //onHide();
  };

  const contact = useSelector((state) => {
    return state.profile;
  });

  const params = useParams();

  const [state, setState] = useState(getAllstate);
  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
    }
  };

  const setdata = (data) => {
    let objKaData = {};
    const imgJSon = data[0];
    let mimetypeArr =
      imgJSon != undefined ? imgJSon.mimetype.split("/") : "image";
    setMimetype(mimetypeArr[0]);
    objKaData["type"] = "keepsakealbum";
    objKaData["imaDatajson"] = imgJSon;
    objKaData["storyz_id"] = storyz_id;
    objKaData["event_id"] = event_id;
    //objKaData['location'] = gallerylocation;
    objKaData["photo_label"] = photolabel;
    // objKaData['photo_caption'] = Photocaption;
    objKaData["description"] = "";
    //console.log("objKaData", objKaData);
    setState(objKaData);
  };

  const addGuestID = (val) => {
    setGuestID(val);
    setGuestIDErr("");
  };

  const addKeepsake = () => {
    //console.log("state val", state);
    //alert("addKeepsake");
    if (guestID == "Select Guest Name" || guestID == 0) {
      setGuestIDErr("Please select guest name");
    } else {
      let finalObj = JSON.parse(JSON.stringify(state));
      delete finalObj.photo_caption;
      delete finalObj.location;
      finalObj["photo_caption"] = Photocaption;
      finalObj["location"] = gallerylocation;
      finalObj["guest_user_id"] = guestID;
      //console.log("finalObj", finalObj);
      userGuestService.addKeepsake(finalObj).then(
        (res) => {
          if (res.status === "success") {
            toast.success("Save Successfully");
            setImgModalBox(false);
            datakeepskeAlbum();
            //onChildClick(res.data);
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
      setImgModalBox(false);
    }
  };
  const [userguestList, setUserguestList] = React.useState([]);

  React.useEffect(() => {
    userGuestService.userGuestList(params.eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  }, [params.eid]);

  return (
    <>
      {/* <form className="" onSubmit={handleSubmit(onSubmit)}> */}
      <div
        className="closeIcon"
        // onClick={() => setImgModalBox(false)}
        onClick={closeModal}
      >
        <svg
          className="closeBtn"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
        </svg>
      </div>
      <div className="mediaModalHeaderWrapper">
        <span className="mediaLogo">
          <i className="fa fa-image mediaLogoIcon"></i>
        </span>{" "}
        Add Image Information 
      </div>

      <div className="mediaImageWrapper">
        <div className="date">
          {moment().format("MMMM D, YYYY: h:mm:sa")}
          {/* {current_date} */}
        </div>
        <div className="imageCropperWrapper">
          {/* <div
            className="uploadImageFromLocalContent"
            onClick={openFileManager}
          >
            <i className="fa fa-plus faPlus"></i>
          </div> */}

          {/* <img
              src={getAllstate ? getAllstate.imgUrl : ""}
              alt=''
              className="imageCropper"
            /> */}
          {mime_type === "video" ? (
            <>
              <video
                className="imageCropper"
                alt={state.imaDatajson?.url}
                controls
              >
                <source
                  src={state.imaDatajson?.url ? state.imaDatajson?.url : ""}
                  type=""
                />
              </video>
            </>
          ) : (
            <>
              <img
                src={state.imaDatajson?.url ? state.imaDatajson?.url : ""}
                alt={state.imaDatajson?.url}
                className="imageCropper"
              />
            </>
          )}
        </div>

        {/* <div className="imageEditIcons">
            <button className="button">
              <i className="fa fa-expand"></i>
            </button>
            <button className="button">
              <i className="fa fa-crop"></i>
            </button>
            <button
              className="button"
               onClick={() => setImgUrl(false)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div> */}
      </div>

      <div className="mediaUserDetails">
        {/* <div className="mediaUserImgWrapper"> */}
        {/* <img
              src="https://images.unsplash.com/photo-1509967419530-da38b4704bc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2395&q=80"
              alt=""
              className="mediaUserImg"
            /> */}
        {/* <img
            src={Global.BASE_USER_IMAGES_PATH + UserPic}
            className="mediaUserImg"
            alt={Global.BASE_USER_IMAGES_PATH + UserPic}
          /> */}
        {/* </div> */}

        {state.type === "keepsakeAlbum" ? (
          <>
            <div className="form-group">
              <label for="sel1">Select Guest Name:</label>
              <select
                className="form-control"
                id="sel1"
                onChange={(e) => addGuestID(e.target.value)}
              //
              // {...register("guest_id", { required: true })}
              >
                <option>Select Guest Name</option>

                {userguestList.map((y, i) => (
                  <>
                    <option key={i} value={y.id}>
                      {y.first_name}
                    </option>
                  </>
                ))}
              </select>
              <p style={{ color: "red" }}>{guestIDErr}</p>
            </div>
          </>
        ) : (
          <>
            <div className="mediaUserContentWrapper">
              <div className="mediaUserTitle">
                Use Name{/* {Userfname} {Userlname} */}
              </div>
              <div className="mediaUserDesignation">Role in Event</div>
            </div>
          </>
        )}
      </div>

      <div className="filenameWrapper pt-0">
        File name: <span>{/* {imgDataUrl ? imageName : ""} */}</span>
      </div>
      <div className="mediaFormWrapper1">
        <div className="formBox">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="photo_label"
            defaultValue={photolabel == "" ? state?.title : photolabel}
            {...register("title", { required: true })}
            placeholder="Image Label"
            //value={photolabel}
            onChange={(e) => setPhotolabel(e.target.value)}
          // value={photo_label}
          // onChange={(e) => setPhotolabel(e.target.value)}
          />
        </div>
        <div className="formBox">
          <label className="form-label">Caption:</label>
          <input
            type="text"
            className="form-control"
            id="photo_caption"
            defaultValue={Photocaption}
            placeholder="photo caption"
            onChange={(e) => setPhotocaption(e.target.value)}
          />
        </div>
        <div className="formBox">
          <label className="form-label">Location:</label>
          <div className="locationMap locationgooglemap12">
            <LoadScript googleMapsApiKey={mapkey} libraries={["places"]}>
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <input
                  type="text"
                  className="form-control"
                  id="gallerylocation"
                  placeholder="type in a name, or use the map..."
                  defaultValue={gallerylocation}
                //   onChange={(e) => setGallerylocation(e.target.value)}
                />
              </StandaloneSearchBox>
            </LoadScript>
            <div className="map">
              <svg
                className="diamondTrunImg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
              </svg>
              Map
            </div>
          </div>
        </div>
        <div className="actionWrapper mt-4">
          <button
            type="submit"
            className="btn confirmBtn"
            onClick={() => addKeepsake()}
          >
            Save Image Meta-Data
          </button>
          <a
            className="btn CancelBtn"
            // onClick={() => setImgModalBox(false)}
            onClick={closeModal}
          >
            Cancel
          </a>
        </div>
      </div>
      {/* </form> */}
    </>
  );
};

export default ImageUploadModal;
