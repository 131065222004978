import React, { memo, forwardRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Checkbox from "../../components/Checkbox"
import CreateButton from "../../components/CreateButton"
import Map from "../../components/Map"
import Section from "../../components/Section"
import { FullScreen } from "../../icons"
import { interactiveObjectSelector } from "../../store/interactive-map/selector"
import {
  changeShowBeacons,
  changeShowOther,
  changeShowTables
} from "../../store/interactive-map/slice"
import classNames from "./InteractiveMap.module.scss"

const InteractiveMap = ({ isFullscreen }, ref) => {
  const dispatch = useDispatch()
  const { showBeacons, showOther } = useSelector(interactiveObjectSelector)

  const onClickFullScreen = () => {
    const fullscreenButton = document.getElementsByClassName(
      "gm-fullscreen-control"
    )[0]
    if (fullscreenButton) fullscreenButton.click()
  }

  const onChangeShowBeacons = value => {
    dispatch(changeShowBeacons(value))
  }

  const onChangeShowOther = value => {
    dispatch(changeShowOther(value))
    dispatch(changeShowTables(value))
  }

  return (
    <Section title="Interactive Map">
      {/* <div className={classNames.buttons}>
        <CreateButton title="Add Bluetooth Beacons" onClick={console.log} />
        <CreateButton
          title="Add Tables and other Objects"
          onClick={console.log}
        />
      </div> */}
      <div className={classNames.options}>
        <div className={`beaconsCheckbox ${classNames.checkboxes}`}>
          <Checkbox
            label="Show Beacons"
            checked={showBeacons}
            onChange={onChangeShowBeacons}
          />
          <Checkbox
            label="Show other Event Objects"
            checked={showOther}
            onChange={onChangeShowOther}
          />
        </div>
        <div
          onClick={onClickFullScreen}
          className={classNames.fullscreen__wrapper}
        >
          <span className={classNames.fullscreen__title}>Fullscreen</span>
          <FullScreen />
        </div>
      </div>
      <div className={classNames.map__wrapper}>
        <Map ref={ref} isFullscreen={isFullscreen} />
      </div>
    </Section>
  );
}

export default memo(forwardRef(InteractiveMap))
