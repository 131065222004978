import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const timezoneService = {
    timezoneList,
    eventSave,
    repeatSave,
    eventUpdate,
    eventSearch,
    getuserTimeZone,
    updateUserTimeZone,
};
async function eventSearch(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/event/search`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function timezoneList() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/timezone/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getuserTimeZone() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/timezone/get-user-time-zone`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateUserTimeZone(TimeZoneData) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(TimeZoneData),
    };
    return fetch(Global.BASE_API_PATH + `/setting/createOrUpdatetime`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function eventUpdate(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(formdata),
    };

    return fetch(Global.BASE_API_PATH + `/event/update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function eventSave(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: formdata,
    };
    return fetch(Global.BASE_API_PATH + `/event/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function repeatSave(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(formdata),
    };
    return fetch(
        Global.BASE_API_PATH + `/event/repeat/createUpdate`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
