import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const getTimeZone = function (offset, date) {
    var targetTime = new Date(date);

    var timeZoneFromDB = offset; //time zone value from database

    //get the timezone offset from local time in minutes
    var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();

    //convert the offset to milliseconds, add to targetTime, and make a new Date
    return new Date(targetTime.getTime() + tzDifference * 60 * 1000);
};

export const useFindPath = (pathIdx) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState();

    useEffect(() => {
        const path = location.pathname;

        if (pathIdx) {
            const splitPath = location.pathname.split("/");

            setCurrentPath(splitPath[pathIdx]);
        } else {
            setCurrentPath(path);
        }
    }, [location, pathIdx]);

    return currentPath;
};

export const useFindPathid = (pathIdx) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState();

    useEffect(() => {
        const path = location.pathname;

        if (pathIdx) {
            const splitPath = location.pathname.split("/");

            setCurrentPath(splitPath[pathIdx]);
        } else {
            setCurrentPath(path);
        }
    }, [location, pathIdx]);

    return currentPath;
};
export const useFindPathEid = (pathIdx) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState();

    useEffect(() => {
        const path = location.pathname;

        if (pathIdx) {
            const splitPath = location.pathname.split("/");

            setCurrentPath(splitPath[pathIdx]);
        } else {
            setCurrentPath(path);
        }
    }, [location, pathIdx]);

    return currentPath;
};
export const useFindPathSid = (pathIdx) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState();

    useEffect(() => {
        const path = location.pathname;

        if (pathIdx) {
            const splitPath = location.pathname.split("/");

            setCurrentPath(splitPath[pathIdx]);
        } else {
            setCurrentPath(path);
        }
    }, [location, pathIdx]);

    return currentPath;
};

export const useDateCount = (enddate) => {
    if (enddate) {

        const now = new Date();
        const endOfYear = new Date(enddate);
        const days = Math.floor((endOfYear - now) / 1000 / 60 / 60 / 24);
        let countDay;
        if (days > 0) {
            countDay = `${Number(days)} day until`
        } else {
            countDay = `${Number(days)} day left`

        }
        return countDay;

    } else {
        return '';
    }
}

export const eventsList = (sid) => {
    return 'false';
};
