import React, { useState } from "react";
import IconImg from "../../assets/img/icons/reports2.svg";
import {
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import Allheader from "../../components/Headers/Allheader";
import { GrCircleInformation } from "react-icons/gr";

function Reports() {
  const [runReport, setRunReport] = useState(false);
  const handelRunReport = () => {
    setRunReport(true);
  };
  const handelCloseReport = () => {
    setRunReport(false);
  };

  return (
    <>

      <Allheader title="Reports" imgScr={IconImg} arw />
      <div className="organizerWrapperMain">
        <Form>
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h2 className="heading-1">Reports</h2>
              <GrCircleInformation className="ml-auto iconColor infoToolTip" />
            </div>

            <div className="borderBottom organizationPara p-4">
              This page shows the settings for your account. These can only be
              edited by the account owner.
            </div>
            <div className="headingWrapper borderBottom pt-4">
              <h2 className="heading-1 blackColor">Available Reports</h2>
            </div>

            <div className="reportFormWrapper border-bottom">
              <div className="reportFromList">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Project:
                  </label>
                  <Input id="exampleFormControlSelect1" type="select">
                    <option>-- Select Storyz --</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Event:
                  </label>
                  <Input id="exampleFormControlSelect1" type="select">
                    <option>-- Select Storyz --</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Report Range:
                  </label>
                  <Input id="exampleFormControlSelect1" type="select">
                    <option>Last Week</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                </FormGroup>
              </div>

              <div className="guestUserWrap">
                <div className="addGuest" onClick={handelRunReport}>
                  <img
                    alt="Organization Name"
                    src={require("assets/img/icons/RunReports.svg").default}
                  />
                  Run Reports
                </div>
                <div
                  className={`addGuest ${!runReport ? "disabledNew" : ""}`}
                  onClick={handelCloseReport}
                >
                  <img
                    alt="Organization Name"
                    src={require("assets/img/icons/close.svg").default}
                  />
                  Clear
                </div>
              </div>
            </div>
            <div className="reportListGenratedWrapper">
              <div>
                <h5>Automatically Generated:</h5>
                <div className="reportListGenratedList">
                  <div>
                    <img
                      alt="Organization Name"
                      src={require("assets/img/icons/profile-icon.svg").default}
                    />
                  </div>
                  <div>2345 Guests</div>
                </div>

                <div className="reportListGenratedList">
                  <div>
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/icons/rsvps-gray-512.svg").default
                      }
                    />
                  </div>
                  <div>814 Guest RSVPed</div>
                </div>

                <div className="reportListGenratedList">
                  <div>
                    <img
                      alt="Organization Name"
                      src={require("assets/img/icons/profile-icon.svg").default}
                    />
                  </div>
                  <div>241 Guests edited their Profile</div>
                </div>

                <div className="reportListGenratedList">
                  <div>
                    <img
                      alt="Organization Name"
                      src={require("assets/img/icons/Chat-Vector2.svg").default}
                    />
                  </div>
                  <div>31 Guests Communicated by Chat</div>
                </div>
              </div>
              <div className="reportListManuallyWrapper">
                <h5>Select additional reports to run manually:</h5>
                <div style={{ columnCount: "2" }}>
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Incomplete Events
                      <input type="checkbox" name="" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Incomplete Guest Records
                      <input type="checkbox" name="" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Guest who Set Relationships
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Guests who Edited their Profile
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Guest who Sent Messages
                      <input type="checkbox" name="" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline disabledNew">
                    <label className="container3 form-check-label">
                      Keepsake Album Uploads
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline disabledNew">
                    <label className="container3 form-check-label">
                      Onboarding
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline disabledNew">
                    <label className="container3 form-check-label">
                      Geospace Entry/Exit
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline disabledNew">
                    <label className="container3 form-check-label">
                      Guests with Full Bluetooth Permissions
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="custom-control form-check form-check-inline disabledNew">
                    <label className="container3 form-check-label">
                      Website Traffic
                      <input type="checkbox" name="" />
                      <span className="checkmark"></span>
                    </label>
                  </div>


                </div>
              </div>
            </div>
            {runReport && (
              <>
                <div className="reportDataListWrapper">
                  <div className="head">Incomplete Events</div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <div className="heading">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/settingIcon.svg").default
                              }
                            />
                            Date
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                              className="sortIcon"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="heading">
                            Log Record
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                            />
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/Edit-Specific-Events-in-this-Storyz.svg")
                                  .default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Event 1 missing RSVP Date</td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/Edit-Specific-Events-in-this-Storyz.svg")
                                  .default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Event 2 missing start date</td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/Edit-Specific-Events-in-this-Storyz.svg")
                                  .default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Event 3 missing </td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="reportDataListWrapper">
                  <div className="head">Incomplete Guest Records</div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <div className="heading">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/settingIcon.svg").default
                              }
                            />
                            Date
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                              className="sortIcon"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="heading">
                            Log Record
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                            />
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/rsvp-black-512.svg").default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Guest record with no first or last name</td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/rsvp-black-512.svg").default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Empty guest record</td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/rsvp-black-512.svg").default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>Guest Record missing phone number</td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="reportDataListWrapper">
                  <div className="head">Guests Who Sent Messages</div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <div className="heading">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/settingIcon.svg").default
                              }
                            />
                            Date
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                              className="sortIcon"
                            />
                          </div>
                        </th>
                        <th>
                          <div className="heading">
                            Log Record
                            <img
                              alt="Organization Name"
                              src={require("assets/img/sortIcon1.svg").default}
                            />
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="date">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/message-icon.svg").default
                              }
                            />
                            8/4/2022 - 9:30pm UTC
                          </div>
                        </td>
                        <td>
                          Guest
                          <span className="text-decoration-underline mx-1">
                            FIRSTNAME LASTNAME
                          </span>
                          chatted with Guest FIRSTNAME LASTNAME
                        </td>
                        <td>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/deleteIcon.svg").default}
                            className="closeIcon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
    </>
  );
}

export default Reports;
