import React, { useState, useEffect } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { GuestEventWrapper } from "../UserGuestList/style";
import Select from "react-select";

import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import { combinedService } from "../../../_services/masters/information.combined";
import { TiUser } from "react-icons/ti";
import SortIcon from "./../../../assets/img/sortIcon.svg";
import GuestEditDropdown from "components/Dropdown/GuestEditDropdown";
import { CombinedGuestPool } from "../CombinedGuestPool/style";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import GuestGroupIcon from "./../../../assets/img/guest-group-icon.svg";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import placeholde from "../../../assets/img/placeholder.jpg";
import GuestGroupImg1 from "./../../../assets/img/guestGroupImg1.jpg";
import GuestGroupImg2 from "./../../../assets/img/guestGroupImg2.jpg";
import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
} from "../../../CommonData";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { commonData } from "store/actions";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const { SearchBar } = Search;

const EventGuestGroupList = ({ args }) => {
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteGuestGroup, setDeleteGuestGroup] = useState(false);
  const [addPrefix, setAddPrefix] = useState(false);
  const [guestGroupModal, setGuestGroupModal] = useState();
  const [guestGroupList, setGuestGroupList] = useState([]);
  const [deleteGroup, setDeleteGroup] = useState();
  const [seteventData, setEventData] = useState();

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  useEffect(() => {
    datatolist();
  }, [common]);

  function datatolist() {
    const id = { event_id: common.eid };
    combinedService.guestGroupList(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestGroupList(res.data);
          setEventData(res.eventDetails);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const listPrefix = [
    // { title: "Honored Guests" },
    { title: "Family" },
    { title: "Business" },
    { title: "Friends" },
    { title: "Partners" },
    { title: "Professional" },
    { title: "Casual" },
    { title: "Social" },
    { title: "Political" },
    { title: "Relegious" },
    { title: "Cultural" },
    { title: "Educational" },
    { title: "Sports" },
  ];

  const handleDeleteGuestGroup = (e) => {
    setDeleteGroup(e);
    setDeleteGuestGroup(!deleteGuestGroup);
  };

  const handleDeleteGuestRemove = () => {
    combinedService.deleteguestgroup(deleteGroup.id).then((res) => {
      if (res.status === "success") {
        datatolist();
        setDeleteGuestGroup(!deleteGuestGroup);
        toast.success("Delete successfully");
      } else {
      }
    });
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: `/admin/edir-guest-group/${sid}/${eid}`,
      settype: "guests",
    },

    {
      img: DeleteIcon,
      title: "Delete Group",
      handleClick: handleDeleteGuestGroup,
    },
  ];

  const AddPrefixModal = () => {
    setAddPrefix(!addPrefix);
  };
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };

  const handlerFunction2 = (e) => {
    return e;
  };

  const guestGroupColumn = [
    {
      dataField: "group_name",
      text: (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            Actions
            <TiUser size={20} className="mx-3 userIcon" />
            Group Name
          </div>
        </div>
      ),
      formatter: (value, row) => (
        <div className="d-flex align-items-center">
          <GuestEditDropdown guestListOption={guestListOption} data={row} />
          <div className="guestUserCard ml-4">
            <div className="guestUserPic">
              <img
                src={row.group_image ? row.group_image : placeholde}
                className="pic"
                alt=""
              />
            </div>
          </div>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Honored Guests"
            defaultValue={row.group_name}
            onBlur={async (e) => {
              const val = e.target.value;

              if (val !== row.group_name) {
                try {
                  // const val1 =
                  // {
                  //   "group_id": row.id,
                  //   "key_name": 'group_name',
                  //   "val": e.target.value
                  // };
                  const val1 = JSON.stringify({
                    group_id: row.id,
                    key: "group_name",
                    val: e.target.value,
                  });
                  combinedService.autoUpdateGuest(val1).then((res) => {
                    if (res.status === "success") {
                      datatolist();
                      toast.success("update successfully !!", "Account");
                    } else {
                      toast.error("Something went wrong !!", "error");
                    }
                  });
                } catch (err) {
                  toast.error("Something went wrong !!", "error");
                }
              }
            }}
          />
        </div>
      ),
      sort: true,
    },
    {
      dataField: "group_type",
      text: <div className="d-flex align-items-center">Group Type</div>,
      formatter: (value, row) => (
        <GuestPrefixDropdown
          placeholder={
            row.group_type ? row.group_type : "---Select Group Type---"
          }
          guestPrefixOption1={listPrefix}
          addUserBtn
          handleAddModal={AddPrefixModal}
          handlerFunction={(e) => {
            if (e !== row.group_type) {
              try {
                const val1 = JSON.stringify({
                  group_id: row.id,
                  key: "group_type",
                  val: e,
                });
                combinedService.autoUpdateGuest(val1).then((res) => {
                  if (res.status === "success") {
                    datatolist();
                    toast.success("update successfully !!", "Account");
                  } else {
                    toast.error("Something went wrong !!", "error");
                  }
                });
              } catch (err) {
                toast.error("Something went wrong !!", "error");
              }
            }
          }}
        />
      ),
      sort: true,
    },
    {
      dataField: "group_description",
      text: <div className="d-flex align-items-center">Description</div>,
      formatter: (value, row) => (
        <div>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="type description..."
            defaultValue={row.group_description}
            onBlur={async (e) => {
              const val = e.target.value;

              if (val !== row.group_description) {
                try {
                  const val1 = JSON.stringify({
                    group_id: row.id,
                    key: "group_description",
                    val: e.target.value,
                  });
                  combinedService.autoUpdateGuest(val1).then((res) => {
                    if (res.status === "success") {
                      datatolist();
                      toast.success("update successfully !!", "Account");
                    } else {
                      toast.error("Something went wrong !!", "error");
                    }
                  });
                } catch (err) {
                  toast.error("Something went wrong !!", "error");
                }
              }
            }}
          />
        </div>
      ),
      sort: true,
    },
    {
      dataField: "members",
      text: <div className="d-flex align-items-center">Members</div>,
      formatter: (value, row) => (
        <div>
          <div className="guestGropuListWrapper d-flex align-items-center">
            <span className="num mr-2">{row.member_role.length}</span>
            {row.member_role.length > 0 ? (
              <>
                <div className="guestGropuList">
                  {row.member_role.map((user, i) => (
                    <>
                      <img
                        src={user.imageUrl ? user.imageUrl : placeholde}
                        alt=""
                        className="guestGropuImg"
                        key={i}
                      />
                    </>
                  ))}
                  <span className="dotted ml-1">...</span>
                </div>
              </>
            ) : (
              " "
            )}
          </div>
        </div>
      ),
    },
  ];
  const guestGroupData = guestGroupList;

  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {common?.singleEvent?.event_name
                ? common?.singleEvent?.event_name
                : ""}
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234" onClick={handelGuestGroupModal}>
                <span>Guest Groups</span>
              </div>

              <Modal
                isOpen={guestGroupModal}
                toggle={handelGuestGroupModal}
                {...args}
                className="aboutGuestGroupModal"
              >
                <ModalBody className="pb-0">
                  <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                    <img
                      src={GeospaceInfoIcon}
                      alt=""
                      className="guestGroupInfoIcon mt-3"
                    />
                    <div className="head">About Guest Groups</div>
                  </div>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        Guests at your event can be added to groups. A good
                        example would be a family - you could add several guests
                        to a group, and specify “family” as the group type.
                      </p>
                    </Col>
                    <Col className="d-flex align-items-center justify-center">
                      <img
                        src={GuestGroupImg1}
                        alt=""
                        className="maxWidth100 mx-auto"
                        width={183}
                      />
                    </Col>
                  </Row>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        A key advantage of groups is that you can send messages
                        to the entire group, rather than having to send messages
                        to each event individually.
                      </p>
                    </Col>
                    <Col sm={5} className="d-flex align-items-center">
                      <img
                        src={GuestGroupImg2}
                        alt=""
                        className="maxWidth100 mx-auto"
                        width={76}
                      />
                    </Col>
                  </Row>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col>
                      <p>
                        If you remove a guest from a group, it doesn’t affect
                        their status in the guest list - just the group. Guests
                        can also be added to multiple groups within the event.
                      </p>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="pt-0">
                  <Button
                    color="primary blueBtn"
                    className="w-100"
                    onClick={handelGuestGroupModal}
                  >
                    Continue
                  </Button>
                </ModalFooter>
              </Modal>

              <span
                className="ml-2"
                data-tip="Tooltip message will come here....."
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
            </div>
          </div>
          <p className="border-bottom organizationPara">
            This page lets you view and create a todo list associated with this
            specific Storyz.
            <br />
            To see the complete list of todos, go to the
            <Link
              className="grayColor ml-1"
              onClick={() => {
                history.push(`/admin/storyz/${sid}`);
              }}
            >
              Storyz view
            </Link>{" "}
            or
            <Link
              className="grayColor ml-1"
              onClick={() => {
                history.push(`/admin/organization`);
              }}
            >
              Organization view.
            </Link>
          </p>
          <div className="p-4">
            <>
              <GuestEventWrapper className="pb-4">
                <Row>
                  <Col lg={4} md={4}>
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter"
                      style={{ width: "100%" }}
                    >
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/monogram-circular.svg")
                            .default
                        }
                        width="30px"
                        style={{ marginRight: "10px" }}
                      />

                      <SearchBar className="form-control" placeholder="" />
                    </div>
                  </Col>
                  <Col lg={5} md={5}>
                    <Row>
                      <label
                        className="form-control-label mb-0"
                        htmlFor="exampleFormControlSelect1"
                        style={{ padding: "12px" }}
                      >
                        Filter by :
                      </label>

                      <FormGroup style={{ width: "129px" }} className="mb-0">
                        <Input id="exampleFormControlSelect1" type="select">
                          <option value="All">Select</option>
                          <option value="Organization">
                            Organization Todo
                          </option>
                          <option value="Storyz">Storyz Todo</option>
                          <option value="Event">Events Todo</option>
                        </Input>
                      </FormGroup>
                    </Row>
                  </Col>
                  <Col
                    lg={3}
                    md={12}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="guestUserWrap">
                      <Link
                        to={`/admin/add-guest-group/${sid}/${eid}/?type=guests`}
                        className="addGuest"
                      >
                        <img
                          alt="Organization Name"
                          src={require("assets/img/groups.svg").default}
                          className="icon"
                        />
                        <div className="whiteSpace">Add New Group</div>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </GuestEventWrapper>

              <CombinedGuestPool className="pb-4">
                <div className="rsvpTableWrapper bootstraptrue">
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={guestGroupData}
                    columns={guestGroupColumn}
                    pagination={pagination}
                    bordered={false}
                    noDataIndication="No Data to Display"
                  />
                </div>
              </CombinedGuestPool>
            </>
          </div>
          {/* <GuestEventWrapper className="p-4">
            <div className="guestSearchWrapper">
              <div className="searchWrapper">
                <button className="searchBtn">
                  <FaSearch />
                </button>

                <input
                  className="formInput searchInput"
                  type="text"
                  name="search"
                  placeholder="search by...."
                />
              </div>
              <div className="showEntries">
                <span>Show</span>

                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={showEntries}
                  className="formSelect enteriesDropdown showDropdown"
                />

                <span>Entries</span>
              </div>
              <div className="showEntries">
                <span>Filter by</span>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={filterGuest}
                  className="formSelect enteriesDropdown"
                />
              </div>
              <div className="guestUserWrap">
                <Link to="add-guest-group" className="addGuest">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/groups.svg").default}
                    className="icon"
                  />
                  <div className="whiteSpace">Add New Group</div>
                </Link>
              </div>
            </div>
          </GuestEventWrapper> */}
          {/* <CombinedGuestPool className="px-4 pb-4">
            <div className="honoredTableWrapper">
              <table className="table overflowHidden">
                <thead>
                  <tr>
                    <th>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          Actions
                          <TiUser size={20} className="mx-3 userIcon" />
                          Guest Name
                        </div>
                        <img
                          src={SortIcon}
                          alt=""
                          className="ml-auto userIcon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center">
                        Group Type
                        <img
                          src={SortIcon}
                          alt=""
                          className="ml-auto userIcon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center">
                        Description
                        <img
                          src={SortIcon}
                          alt=""
                          className="ml-auto userIcon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center">
                        Members
                        <img
                          src={SortIcon}
                          alt=""
                          className="ml-auto userIcon"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(10)].map((x, i) => (
                    <>
                      <tr key={i}>
                        <td>
                          <div className="d-flex align-items-center">
                            <GuestEditDropdown
                              guestListOption={guestListOption}
                            />

                            <div className="guestUserCard ml-4">
                              <div className="guestUserPic">
                                <img
                                  src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                  className="pic"
                                  alt=""
                                />
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Honored Guests"
                            />
                          </div>
                        </td>
                        <td>
                          <GuestPrefixDropdown
                            placeholder="---Select Group Type---"
                            guestPrefixOption={listPrefix}
                            addUserBtn
                            handleAddModal={AddPrefixModal}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="type description..."
                          />
                        </td>
                        <td>
                          <div className="guestGropuListWrapper d-flex align-items-center">
                            <span className="num mr-2">5</span>
                            <div className="guestGropuList">
                              <img
                                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                alt=""
                                className="guestGropuImg"
                              />
                              <img
                                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                alt=""
                                className="guestGropuImg"
                              />
                              <img
                                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                alt=""
                                className="guestGropuImg"
                              />
                            </div>
                            <span className="dotted ml-1">...</span>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="paginationWrapper">
              <div className="showingPaginationText">
                Showing 1 to 20 of 2220 Entries{" "}
              </div>
              <nav className="customPagination">
                <ul className="pagination">
                  <li className="page-item disabled prev">
                    <Link className="page-link next">Previous</Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      2
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      4
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      5
                    </Link>
                  </li>
                  <li className="page-item dotted">
                    <Link className="page-link" to="#">
                      <BsThreeDots />
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      12
                    </Link>
                  </li>
                  <li className="page-item next">
                    <Link className="page-link" to="#">
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </CombinedGuestPool> */}
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
};

export default EventGuestGroupList;
