import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";

function selectServices() {
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            {/* Select a service level  */}
            <Card className="selectServiceLevel">
              <CardBody className="bg-white ">
                <div className="heading">
                  <img
                    alt="OurStoryz"
                    src={
                      require("assets/img/modal-icons/serviceLevelIcon.svg")
                        .default
                    }
                  />{" "}
                  Select a service level
                </div>
                <p className="logniMethodText">Change your service level</p>
                <div className="payNowLine"></div>

                <div className="changeServiceWrapper">
                  <div className="serviceListWrapper">
                    <div className="serviceLeft">
                      <FormGroup check>
                        <Input name="radio1" type="radio" />
                      </FormGroup>
                    </div>
                    <div className="serviceRight">
                      <div className="serviceListInner">
                        <div className="serviceHeadWraper">
                          <div className="serviceHead">
                            <img
                              alt="OurStoryz"
                              src={
                                require("assets/img/modal-icons/enterpriseIcon.svg")
                                  .default
                              }
                            />
                            Enterprise
                          </div>
                          <div className="serviecPrice">
                            $99/<span>Month</span>
                          </div>
                        </div>
                        <p>Create teams and event groups</p>
                        <div className="serviceLineBox">
                          <div className="payNowLine"></div>
                          <img
                            alt="OurStoryz"
                            src={
                              require("assets/img/modal-icons/leftArrowIcon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="serviceListWrapper">
                    <div className="serviceLeft">
                      <FormGroup check>
                        <Input name="radio1" type="radio" />
                      </FormGroup>
                    </div>
                    <div className="serviceRight">
                      <div className="serviceListInner">
                        <div className="serviceHeadWraper">
                          <div className="serviceHead">
                            <img
                              alt="OurStoryz"
                              src={
                                require("assets/img/modal-icons/professionaIcon.svg")
                                  .default
                              }
                            />
                            Professional
                          </div>
                          <div className="serviecPrice">
                            $40/<span>Month</span>
                          </div>
                        </div>
                        <p>Create a team support multiple clients</p>
                        <div className="serviceLineBox">
                          <div className="payNowLine"></div>
                          <img
                            alt="OurStoryz"
                            src={
                              require("assets/img/modal-icons/leftArrowIcon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="serviceListWrapper active">
                    <div className="serviceLeft">
                      <FormGroup check>
                        <Input name="radio1" type="radio" />
                      </FormGroup>
                    </div>
                    <div className="serviceRight">
                      <div className="serviceListInner">
                        <div className="serviceHeadWraper">
                          <div className="serviceHead">
                            <img
                              alt="OurStoryz"
                              src={
                                require("assets/img/modal-icons/diyIcon.svg")
                                  .default
                              }
                            />
                            DIY
                          </div>
                          <div className="serviecPrice">
                            $120/<span>1yr</span>
                          </div>
                        </div>
                        <p>Create one event with sub-events</p>
                        <div className="serviceLineBox">
                          <div className="payNowLine"></div>
                          <img
                            alt="OurStoryz"
                            src={
                              require("assets/img/modal-icons/leftArrowIcon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="serviceListWrapper">
                    <div className="serviceLeft">
                      <FormGroup check>
                        <Input name="radio1" type="radio" />
                      </FormGroup>
                    </div>
                    <div className="serviceRight">
                      <div className="serviceListInner">
                        <div className="serviceHeadWraper">
                          <div className="serviceHead">
                            <img
                              alt="OurStoryz"
                              src={
                                require("assets/img/modal-icons/locatorIcon.svg")
                                  .default
                              }
                            />
                            Locator
                          </div>
                          <div className="serviecPrice">
                            FREE/<span>1yr</span>
                          </div>
                        </div>
                        <p>A single event for testing</p>
                        <div className="serviceLineBox">
                          <div className="payNowLine"></div>
                          <img
                            alt="OurStoryz"
                            src={
                              require("assets/img/modal-icons/leftArrowIcon.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center card-footer">
                  <Button className="my-4 m-0 eventBtn">
                    Go to your events
                  </Button>
                  <span className="cancelBtnText float-right mt-2">cancel</span>
                </div>
              </CardBody>
            </Card>

        
          </Col>
        </Row>
      </Container>
    
      <ToastContainer />
    </>
  );
}

export default selectServices;
