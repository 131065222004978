import React, { useState, useEffect, useRef } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { GuestEventWrapper } from "../UserGuestList/style";
import { fileService } from "../../../_services/masters/file.service";
import moment from "moment";
import FileStackApp from "../../../components/FileStackApp/FileStackFiles";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { ReactSession } from "react-client-session";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import docxIcon from "../../../assets/files-icon-png/docx-icon.png";

import folderIcon from "../../../assets/files-icon-png/folder-icon.png";

import imgIcon from "../../../assets/files-icon-png/img-icon.png";
import pdfIcon from "../../../assets/files-icon-png/pdf-icon.png";
import txtIcon from "../../../assets/files-icon-png/txt-icon.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import csvIcon from "../../../assets/files-icon-png/csv-icon.png";

const EventFileList = () => {
  const inputRef = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const [fileDelete, setFileDelete] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [OrgDetail, setOrgDetail] = useState([]);
  const [type_Icon, settypeIcon] = useState(folderIcon);
  const [filestack, setFilestack] = useState([]);
  const [dataFile, setDataFile] = useState({});
  const [imgModalBox, setImgModalBox] = React.useState(false);
  const [mapkey, setMapkey] = useState("");
  const [extension, setExtension] = useState("");
  const intialValues = {
    photo_label: "",
    photo_caption: "",
    imaDatajson: [],
    img_address: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [imgModalBoxfirst, setImgModalBoxfirst] = useState(false);
  const [imgDataUrl, setImgUrl] = React.useState("");
  const [photo_caption, setPhotocaption] = React.useState("");
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [editImageName, setEditImageName] = useState("");
  const [imageName, setImageName] = useState("");
  const [photo_label, setPhotolabel] = React.useState("");
  const [gallerylocation, setGallerylocation] = useState("");
  const [video, setVideo] = useState("");
  const todayDate = new Date();
  const current_date = moment(todayDate).format("MMMM D, YYYY: h:mm:sa"); // December 13th 2018, 5:25:14 pm


  const MediaUserDetails = JSON.parse(localStorage.getItem("userdata"));
  const organization_id = MediaUserDetails.organization[0].organization_id;
  const Userfname = MediaUserDetails.first_name;
  const Userlname = MediaUserDetails.last_name;
  const UserPic = MediaUserDetails.imageUrl;
  const UserEmail = MediaUserDetails.email;
  let typeIcon = folderIcon;
  const params = useParams();
  const sid = params.sid;
  const eid = params.eid;

  const setdata = (data) => {
    let imgTypeArr = data[0].mimetype.split("/");
    setImgModalBox(true);
    setDataFile(data);
    setFormValues({ ...formValues, ["imaDatajson"]: data });
    setMapkey("AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY");
    setImgModalBoxfirst(false);
    setImgModalBox(true);
    setImgUrl(data[0].url);
    setVideo(imgTypeArr[0]);
    setExtension(imgTypeArr[1]);

    ///console.log("dataFile", dataFile);
  };

  const saveData = () => {
    let saveData = {
      photo_label: photo_label,
      photo_caption: photo_caption,
      key: "event_id",
      key_value: eid,
      type: "Event",
      description: "",
      location: gallerylocation,
      imaDatajson: dataFile,
    };
    //console.log('SaveData ORG', saveData);
    fileService.saveData(saveData).then(
      (res) => {
        if (res.status === "success") {
          setImgUrl("");
          setPhotolabel("");
          setPhotocaption("");
          fileList();
          toast.success("Added Successfully");
        } else if (res.message === "This photo already exists in this storyz") {
          toast.error("This photo already exists in this storyz");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );
    setImgModalBox(false);
  };

  const deleteFile = (delID) => {
    let delData = { id: delID };
    //console.log('SaveData ORG', saveData);
    fileService.deleteFile(delData).then(
      (res) => {
        if (res.status === "success") {
          setImgUrl("");
          setPhotolabel("");
          setPhotocaption("");
          fileList();
          toast.success("Deleted Successfully");
        } else if (res.message === "This photo already exists in this storyz") {
          toast.error("This photo already exists in this storyz");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );
    setImgModalBox(false);
    setActiveIndex(null);
    handelFileDeleteClose();
  };

  const downloadFile = (blob, fileName) => {
    fetch(blob)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .catch(console.error);
  };
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const Download = (fileUrl) => {
    alert(fileUrl);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      //console.log("place.formatted_address", place.formatted_address);
      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // setFormValues({ ...formValues, ['img_address']: place.formatted_address });
    }
  };

  const handleItemClick = (index, fileID) => {
    if (index === activeIndex) {
      setActiveIndex(null);
      const org_detail = orgList.filter((org_list) => org_list.id === fileID);
      if (org_detail[0].mime_type === "pdf") {
        typeIcon = pdfIcon;
      } else if (
        org_detail[0].mime_type === "docx" ||
        org_detail[0].mime_type === "msword" ||
        org_detail[0].mime_type === "doc"
      ) {
        typeIcon = docxIcon;
      } else if (
        org_detail[0].mime_type === "txt" ||
        org_detail[0].mime_type === "plain"
      ) {
        typeIcon = txtIcon;
      } else if (
        org_detail[0].mime_type === "png" ||
        org_detail[0].mime_type === "jpg" ||
        org_detail[0].mime_type === "jpg" ||
        org_detail[0].mime_type === "jpeg" ||
        org_detail[0].mime_type === "jfif" ||
        org_detail[0].mime_type === "pjpeg" ||
        org_detail[0].mime_type === "pjp" ||
        org_detail[0].mime_type === "gif" ||
        org_detail[0].mime_type === "jpeg" ||
        org_detail[0].mime_type === "JPEG"
      ) {
        typeIcon = imgIcon;
      } else {
        typeIcon = folderIcon;
      }

      setOrgDetail(org_detail);
      setActiveIndex(index);
      settypeIcon(typeIcon);
    } else {
      const org_detail = orgList.filter((org_list) => org_list.id === fileID);

      if (org_detail[0].mime_type === "pdf") {
        typeIcon = pdfIcon;
      } else if (
        org_detail[0].mime_type === "docx" ||
        org_detail[0].mime_type === "msword" ||
        org_detail[0].mime_type === "doc" ||
        org_detail[0].mime_type ===
        "vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        typeIcon = docxIcon;
      } else if (
        org_detail[0].mime_type === "txt" ||
        org_detail[0].mime_type === "plain"
      ) {
        typeIcon = txtIcon;
      } else if (
        org_detail[0].mime_type === "png" ||
        org_detail[0].mime_type === "jpg" ||
        org_detail[0].mime_type === "jpg" ||
        org_detail[0].mime_type === "jpeg" ||
        org_detail[0].mime_type === "jfif" ||
        org_detail[0].mime_type === "pjpeg" ||
        org_detail[0].mime_type === "pjp" ||
        org_detail[0].mime_type === "gif" ||
        org_detail[0].mime_type === "jpeg" ||
        org_detail[0].mime_type === "JPEG"
      ) {
        typeIcon = imgIcon;
      } else if (org_detail[0].mime_type === "csv") {
        typeIcon = csvIcon;
      } else {
        typeIcon = folderIcon;
      }

      setOrgDetail(org_detail);
      setActiveIndex(index);
      settypeIcon(typeIcon);
    }
  };

  const handelFileDelete = () => setFileDelete(true);
  const handelFileDeleteClose = () => setFileDelete(false);

  const fileList = () => {
    let obj = { key: "event_id", key_value: eid };
    fileService.fileList(obj).then(
      (res) => {
        if (res.status === "success") {
          const ol = res.data;
          for (let i = 0; i < ol.length; i++) {
            if (ol[i].mime_type === "pdf") {
              typeIcon = pdfIcon;
            } else if (
              ol[i].mime_type === "docx" ||
              ol[i].mime_type === "msword" ||
              ol[i].mime_type === "doc" ||
              ol[i].mime_type ===
              "vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
              typeIcon = docxIcon;
            } else if (ol[i].mime_type === "txt" || ol[i].mime_type === "plain") {
              typeIcon = txtIcon;
            } else if (
              ol[i].mime_type === "png" ||
              ol[i].mime_type === "jpg" ||
              ol[i].mime_type === "jpg" ||
              ol[i].mime_type === "jpeg" ||
              ol[i].mime_type === "jfif" ||
              ol[i].mime_type === "pjpeg" ||
              ol[i].mime_type === "pjp" ||
              ol[i].mime_type === "gif" ||
              ol[i].mime_type === "jpeg" ||
              ol[i].mime_type === "JPEG"
            ) {
              typeIcon = imgIcon;
            } else if (ol[i].mime_type == "csv") {
              typeIcon = csvIcon;
            } else {
              typeIcon = folderIcon;
            }

            ol[i]["icon_url"] = typeIcon;
          }
          setOrgList(JSON.parse(JSON.stringify(ol)));
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  useEffect(() => {
    fileList();
  }, []);
  return (
    <>
      <ToastContainer></ToastContainer>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div class="badge">Event</div>
              {common?.singleEvent?.event_name
                ? common?.singleEvent?.event_name
                : ""}
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234">
                <span>Event Files</span>
              </div>
              <span
                className="ml-2"
                data-tip="Tooltip message will come here....."
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
            </div>
          </div>
          <div className="border-bottom organizationPara">
            This view shows files that have been associated with this event.
            <br />
            To see more files, check the
            <Link className="ml-1 text-decoration-underline mr-1">
              Storyz view
            </Link>
            or
            <Link className="ml-1 text-decoration-underline">
              Organization view.
            </Link>
          </div>
          <div className="p-4">
            <GuestEventWrapper className="pb-4">
              <Row>
                <Col className="d-flex justify-content-end align-items-center pb-4">
                  <div className="guestUserWrap">
                    <Link to="/admin/organization-file" className="exportWrap">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/editfieldicons.svg").default}
                        height={24}
                      />
                      <div>Organization Files</div>
                    </Link>
                    <Link
                      to={`/admin/storyz-file/${sid}`}
                      className="exportWrap"
                    >
                      <img
                        alt="Organization Name"
                        src={require("assets/img/aour-storyz.svg").default}
                        height={24}
                      />
                      <div>Storyz Files</div>
                    </Link>

                    <div className="addGuest">
                      <div>
                        <FileStackApp
                          setFilestack={setFilestack}
                          setdata={setdata}
                        ></FileStackApp>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="fileWrapperMain">
                    <div className="fileTopOuter border-bottom">
                      <div className="fileTopWrapper">
                        <div className="fileTopList">
                          <div className="fileTopBox">
                            <div className="fileTopBoxInner">
                              <img
                                alt="Organization Name"
                                src={
                                  require("assets/img/file-icons/folder-icon.svg")
                                    .default
                                }
                                height={24}
                                width={24}
                                className="fileIcon"
                              />

                              <div className="fileType">Type</div>
                            </div>
                            <div className="fileName">
                              File the Name Goes Here e
                            </div>
                          </div>

                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/file-icons/arrow-icon.svg")
                                .default
                            }
                            height={24}
                            width={24}
                            className="arrowIcon"
                          />
                          <div className="fileTopBox">
                            <div className="fileTopBoxInner">
                              <img
                                alt="Organization Name"
                                src={
                                  require("assets/img/file-icons/folder-icon.svg")
                                    .default
                                }
                                height={24}
                                width={24}
                                className="fileIcon"
                              />

                              <div className="fileType">Type</div>
                            </div>
                            <div className="fileName">
                              File the Name Goes Here
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fileBottomWrapper">
                      {/* Start 1st */}

                      <div className="fileBottomList">
                        {orgList.map((fileData, i) => (
                          <div
                            className={`fileBottomBox ${activeIndex === i ? "active" : ""
                              }`}
                            onClick={() => handleItemClick(i, fileData.id)}
                            key={Math.random()}
                          >
                            <div className="fileBottomBoxInner">
                              <img
                                alt="Organization Name"
                                src={fileData.icon_url}
                                height={24}
                                width={24}
                                className="fileIcon"
                              />

                              <div className="fileType">Type</div>
                            </div>
                            <div className="fileName">{fileData.file_name}</div>
                          </div>
                        ))}
                      </div>

                      {/* End 1st */}

                      {activeIndex !== null && (
                        <div className="fileTypeShowWrapper">
                          <div className="head">
                            {OrgDetail !== undefined
                              ? OrgDetail[0].file_name
                              : ""}
                          </div>
                          <div className="fileDataShowWrapper">
                            <div className="fileDataLeftWrapper">
                              <div className="fileImgMain">
                                {/* Type Start  */}
                                <img
                                  alt="Organization Name"
                                  src={OrgDetail !== undefined ? type_Icon : ""}
                                  height={24}
                                  width={24}
                                  className="fileIcon"
                                />
                                {/* Type End  */}
                              </div>
                              <div className="fileFeatureWrapper">
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/file-icons/fullscreen-icon.svg")
                                      .default
                                  }
                                  height={24}
                                  width={24}
                                />
                                {/* Download  Start */}
                                <img
                                  alt="Download"
                                  src={
                                    require("assets/img/file-icons/download-icon.svg")
                                      .default
                                  }
                                  height={24}
                                  width={24}
                                  onClick={() =>
                                    downloadFile(
                                      OrgDetail[0].file_url,
                                      OrgDetail[0].file_name
                                    )
                                  }
                                />
                                {/* Download  End */}
                                <a href={`mailto:${UserEmail}`}>
                                  <img
                                    alt="Email"
                                    src={
                                      require("assets/img/file-icons/email.icon.svg")
                                        .default
                                    }
                                    height={24}
                                    width={24}
                                  />
                                </a>
                                <img
                                  alt="Delete"
                                  src={
                                    require("assets/img/file-icons/delete-icon.svg")
                                      .default
                                  }
                                  height={24}
                                  width={24}
                                  onClick={handelFileDelete}
                                />

                                <Modal
                                  isOpen={fileDelete}
                                  toggle={handelFileDeleteClose}
                                  className="fileDeleteModal"
                                >
                                  <ModalHeader className="border-bottom">
                                    <img
                                      alt="Organization Name"
                                      src={
                                        require("assets/img/Icon-Text-in-Circle.svg")
                                          .default
                                      }
                                      className="icon"
                                    />
                                    <h2>
                                      Confirm You Want to Permanently Delete
                                      This File
                                    </h2>
                                  </ModalHeader>
                                  <ModalBody>
                                    <h3>File name goes here</h3>
                                    <p>
                                      Confirm that you want to delete this file.
                                    </p>
                                    <p>
                                      Files directly uploaded into OurStoryz
                                      from your device (desktop, laptop,
                                      smartphone) will be deleted.
                                    </p>
                                    <p>
                                      Files shared from other sources will just
                                      have their local shortcut removed. The
                                      original files will be unchanged.
                                    </p>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button
                                      className="pinkBtn flex-grow-1"
                                      type="button"
                                      onClick={() =>
                                        deleteFile(
                                          OrgDetail !== undefined
                                            ? OrgDetail[0].id
                                            : ""
                                        )
                                      }
                                    >
                                      Delete Event File
                                    </Button>
                                    <Button
                                      className="cancelBtn"
                                      type="button"
                                      onClick={handelFileDeleteClose}
                                    >
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </div>
                            </div>
                            <div className="fileDataRightWrapper">
                              <div className="fileTitle">
                                Type:
                                <span>
                                  {OrgDetail !== undefined
                                    ? OrgDetail[0].mime_type
                                    : ""}
                                </span>
                              </div>
                              <div className="fileTitle">
                                Source:
                                <span>
                                  {OrgDetail !== undefined
                                    ? OrgDetail[0].source
                                    : ""}
                                </span>
                              </div>
                              <div className="fileTitle">
                                Uploaded:
                                <span>
                                  {OrgDetail !== undefined
                                    ? moment(OrgDetail[0].updated_at).format(
                                      "MMMM D YYYY, h:mm:ss a"
                                    ) + "UTC"
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="fileAvatarWrapper">
                              <img
                                alt="Organization Name"
                                src={UserPic}
                                className="avatar"
                              />
                              <div>
                                <div className="title">{Userfname}</div>
                                <div className="subTitle">Uploaded</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {imgModalBox && (
                        <>
                          <div
                            className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""
                              }`}
                          >
                            <div
                              className="closeIcon"
                              onClick={() => setImgModalBox(false)}
                            >
                              <svg
                                className="closeBtn"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                              </svg>
                            </div>
                            <div className="mediaModalHeaderWrapper">
                              <span className="mediaLogo">
                                <i className="fa fa-image mediaLogoIcon"></i>
                              </span>{" "}
                              Add Image Information
                            </div>

                            <div className="mediaImageWrapper">
                              <div className="date">{current_date}</div>
                              <div
                                className={
                                  video === "video" || video === "image"
                                    ? "imageCropperWrapper"
                                    : "imageCropperWrapper extentionWrapper"
                                }
                              >
                                {/* {video === "video" || video === "image" ? (
                                  <div className="uploadImageFromLocalContent">
                                    <FileStackApp
                                      setImgModalBox={setImgModalBox}
                                      setFilestack={setFilestack}
                                      setdata={setdata}
                                      type="plus"
                                    ></FileStackApp>
                                  </div>
                                ) : (
                                  ""
                                )} */}

                                {video === "video" ? (
                                  <>
                                    <video
                                      className="imageCropper"
                                      alt={imgDataUrl}
                                      controls
                                    >
                                      <source
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        type=""
                                      />
                                    </video>
                                  </>
                                ) : video === "image" ? (
                                  <>
                                    <img
                                      src={imgDataUrl ? imgDataUrl : ""}
                                      alt={imgDataUrl}
                                      className="imageCropper"
                                    />
                                  </>
                                ) : extension === "pdf" ? (
                                  <>
                                    <div className="fileExtentionWrapper">
                                      <img
                                        src={pdfIcon}
                                        alt={extension}
                                        className="imageCropper"
                                      />
                                      <span
                                        className="truncate"
                                        title={dataFile[0]?.filename}
                                      >
                                        {dataFile[0]?.filename}
                                      </span>
                                    </div>
                                  </>
                                ) : extension === "docx" ||
                                  extension === "msword" ||
                                  extension === "doc" ||
                                  extension ===
                                  "vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                  <>
                                    <div className="fileExtentionWrapper">
                                      <img
                                        src={docxIcon}
                                        alt={extension}
                                        className="imageCropper"
                                      />
                                      <span
                                        className="truncate"
                                        title={dataFile[0]?.filename}
                                      >
                                        {dataFile[0]?.filename}
                                      </span>
                                    </div>
                                  </>
                                ) : extension === "txt" ||
                                  extension === "plain" ? (
                                  <>
                                    <div className="fileExtentionWrapper">
                                      <img
                                        src={txtIcon}
                                        alt={extension}
                                        className="imageCropper"
                                      />
                                      <span
                                        className="truncate"
                                        title={dataFile[0]?.filename}
                                      >
                                        {dataFile[0]?.filename}
                                      </span>
                                    </div>
                                  </>
                                ) : extension === "csv" ? (
                                  <>
                                    <div className="fileExtentionWrapper">
                                      <img
                                        src={csvIcon}
                                        alt={extension}
                                        className="imageCropper"
                                      />
                                      <span
                                        className="truncate"
                                        title={dataFile[0]?.filename}
                                      >
                                        {dataFile[0]?.filename}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="fileExtentionWrapper">
                                      <img
                                        src={folderIcon}
                                        alt={extension}
                                        className="imageCropper"
                                      />
                                      <span
                                        className="truncate"
                                        title={dataFile[0]?.filename}
                                      >
                                        {dataFile[0]?.filename}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="mediaUserDetails">
                              <div className="mediaUserImgWrapper">
                                <img
                                  src={UserPic}
                                  className="mediaUserImg"
                                  alt={UserPic}
                                />
                              </div>
                              <div className="mediaUserContentWrapper">
                                <div className="mediaUserTitle">
                                  {Userfname} {Userlname}
                                </div>
                                <div className="mediaUserDesignation">
                                  Role in Event
                                </div>
                              </div>
                            </div>

                            <div className="filenameWrapper">
                              {/* File name: <span>File NameXXX </span> */}
                              File name:{" "}
                              <span>{imgDataUrl ? imageName : ""}</span>
                            </div>
                            <div className="mediaFormWrapper">
                              <div className="formBox">
                                <label className="form-label">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="photo_label"
                                  name="photo_label"
                                  placeholder="Image Label"
                                  value={photo_label}
                                  onChange={(e) =>
                                    setPhotolabel(e.target.value)
                                  }
                                />
                              </div>
                              <div className="formBox">
                                <label className="form-label">Caption:</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="photo_caption"
                                  name="photo_caption"
                                  placeholder="photo caption"
                                  value={photo_caption}
                                  onChange={(e) =>
                                    setPhotocaption(e.target.value)
                                  }
                                ></textarea>
                              </div>
                              <div className="formBox">
                                <label className="form-label">Location:</label>
                                <div className="locationMap locationgooglemap12">
                                  <LoadScript
                                    googleMapsApiKey={mapkey}
                                    libraries={["places"]}
                                  >
                                    <StandaloneSearchBox
                                      onLoad={(ref) => (inputRef.current = ref)}
                                      onPlacesChanged={handlePlaceChanged}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="gallerylocation"
                                        placeholder="type in a name, or use the map..."
                                      // value={gallerylocation}
                                      // onChange={(e) => setGallerylocation(e.target.value)}
                                      />
                                    </StandaloneSearchBox>
                                  </LoadScript>

                                  <div
                                    className="map pointer"
                                  //  onClick={handelMapModal}
                                  >
                                    <svg
                                      className="diamondTrunImg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                    </svg>
                                    Map
                                  </div>
                                </div>
                              </div>
                              <div className="actionWrapper">
                                <button
                                  type="submit"
                                  className="btn confirmBtn"
                                  onClick={() => saveData()}
                                >
                                  Save Image Meta-Data
                                </button>
                                <button
                                  type="submit"
                                  className="btn CancelBtn"
                                  onClick={() => setImgModalBox(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mediaOverlay"></div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </GuestEventWrapper>
          </div>
        </div>
      </Eventstyle>
    </>
  );
};

export default EventFileList;
