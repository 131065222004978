
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
    UncontrolledAlert,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

function Eventspopup() {
    const [focusedEmail, setFocusedEmail] = React.useState(false);
    const [focusedPassword, setFocusedPassword] = React.useState(false);
    const [defaultModal, setdefaultModal] = React.useState(false);
    const [defaultModale, setdefaultModale] = React.useState(false);
    const [notificationModal, setnotificationModal] = React.useState(false);
    const [formModal, setformModal] = React.useState(false);
    const [notificationModalacoun, setnotificationModalacoun] =
        React.useState(false);
    const [notificationModalacouno, setnotificationModalacouno] =
        React.useState(false);
    const [notificationModalRestored, setnotificationModalRestored] =
        React.useState(false);

    const [alert, setalert] = React.useState(false);
    const notificationAlertRef = React.useRef(null);
    const notify = (type) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        Bootstrap Notify
                    </span>
                    <span data-notify="message">
                        Turning standard Bootstrap alerts into awesome notifications
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const basicAlert = () => {
        setalert(
            <ReactBSAlert
                style={{ display: "block", marginTop: "-100px" }}
                title="Here's a message!"
                onConfirm={() => setalert(null)}
                onCancel={() => setalert(null)}
                btnSize=""
                text="A few words about this sweet alert ..."
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
    };
    const infoAlert = () => {
        setalert(
            <ReactBSAlert
                info
                style={{ display: "block", marginTop: "-100px" }}
                title="Info"
                onConfirm={() => setalert(null)}
                onCancel={() => setalert(null)}
                confirmBtnBsStyle="info"
                confirmBtnText="Ok"
                btnSize=""
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
    };
    const successAlert = () => {
        setalert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setalert(null)}
                onCancel={() => setalert(null)}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
    };
    const warningAlert = () => {
        setalert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Warning"
                onConfirm={() => setalert(null)}
                onCancel={() => setalert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
    };
    const questionAlert = () => {
        setalert(
            <ReactBSAlert
                custom
                style={{ display: "block", marginTop: "-100px" }}
                title="Question"
                customIcon={
                    <div
                        className="swal2-icon swal2-question swal2-animate-question-icon"
                        style={{ display: "flex" }}
                    >
                        <span className="swal2-icon-text">?</span>
                    </div>
                }
                onConfirm={() => setalert(null)}
                onCancel={() => setalert(null)}
                confirmBtnBsStyle="default"
                confirmBtnText="Ok"
                btnSize=""
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
    };

    return (
        <>
            <Container className="mt--6" fluid>
                <Row className="justify-content-center ">
                    <Col className="card-wrapper " lg="8" style={{ minHeight: "750px" }}>
                        <Card className="mt-8">
                            <CardHeader>
                                <h3 className="mb-0">Events Modals</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="primary"
                                            onClick={() => setdefaultModale(true)}
                                        >
                                            Events
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered floatingSearchDialog"
                                            isOpen={defaultModale}
                                            toggle={() => setdefaultModale(false)}
                                        >
                                            <div className="modal-header">
                                                <h6 className="modal-title" id="modal-title-default">
                                                    <img
                                                        alt="..."
                                                        src={
                                                            require("assets/img/monogramcircular.svg").default
                                                        }
                                                        width="25px"
                                                    />
                                                    <span className="title">Events</span>
                                                </h6>
                                                <button
                                                    aria-label="Close"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    type="button"
                                                    onClick={() => setdefaultModale(false)}
                                                >
                                                    <span aria-hidden={true}>×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <FormGroup>
                                                    <Input
                                                        id="example2cols1Input"
                                                        placeholder="type to search..."
                                                        type="text"
                                                    />
                                                </FormGroup>
                                                <div>
                                                    <section className="storyzsection">
                                                        {Array.from({ length: 15 }, (_, i) => (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginBottom: "20px",
                                                                }}
                                                                className="story1"
                                                                key={i}
                                                            >
                                                                <div style={{ width: "64px" }}>Aug 14</div>
                                                                <div>
                                                                    <img
                                                                        alt="Organization Name"
                                                                        src={
                                                                            require("assets/img/theme/Event-name.svg")
                                                                                .default
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className="titlee"
                                                                        style={{
                                                                            margin: "0px",
                                                                            lineHeight: "11px",
                                                                        }}
                                                                    >
                                                                        Event Name {i + 1}
                                                                    </p>
                                                                    <p className="subtitle">Storyz {i + 1}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </section>
                                                </div>
                                            </div>
                                            <div className="modal-footer"></div>
                                        </Modal>
                                    </Col>
                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="primary"
                                            onClick={() => setdefaultModal(true)}
                                        >
                                            Storyz
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered floatingSearchDialog"
                                            isOpen={defaultModal}
                                            toggle={() => setdefaultModal(false)}
                                        >
                                            <div className="modal-header">
                                                <h6 className="modal-title" id="modal-title-default">
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/Storyz.svg").default}
                                                        width="25px"
                                                    />
                                                    <span className="title">Storyz</span>
                                                </h6>
                                                <button
                                                    aria-label="Close"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    type="button"
                                                    onClick={() => setdefaultModal(false)}
                                                >
                                                    <span aria-hidden={true}>×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <FormGroup>
                                                    <Input
                                                        id="example2cols1Input"
                                                        placeholder="type to search..."
                                                        type="text"
                                                    />
                                                </FormGroup>
                                                <div>
                                                    <section className="storyzsection">
                                                        {Array.from({ length: 15 }, (_, i) => (
                                                            <div className="story1 pointer" key={i}>
                                                                <div style={{ width: "64px" }}>Aug 14</div>
                                                                <div>
                                                                    <img
                                                                        alt="Organization Name"
                                                                        src={
                                                                            require("assets/img/Mask-Group.svg")
                                                                                .default
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className="titlee"
                                                                        style={{
                                                                            margin: "0px",
                                                                            lineHeight: "11px",
                                                                        }}
                                                                    >
                                                                        Event Name {i + 1}
                                                                    </p>
                                                                    <p
                                                                        className="subtitle"
                                                                        style={{ margin: "0px" }}
                                                                    >
                                                                        Storyz {i + 1}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </section>
                                                </div>
                                            </div>
                                            <div className="modal-footer"></div>
                                        </Modal>
                                    </Col>

                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="warning"
                                            onClick={() => setnotificationModal(true)}
                                        >
                                            Delete Account Owner
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered modal-danger deleteaccountowner"
                                            isOpen={notificationModal}
                                            toggle={() => setnotificationModal(false)}
                                        >
                                            <div className="modal-header">
                                                <h6
                                                    className="modaltitle"
                                                    id="modal-title-notification"
                                                >
                                                    <img
                                                        alt="..."
                                                        src={
                                                            require("assets/img/editfieldicons.svg").default
                                                        }
                                                        width="48px"
                                                        style={{ marginRight: "10px" }}
                                                    />
                                                    Delete Your Organization?
                                                </h6>
                                            </div>
                                            <div className="modal-body">
                                                <div className="">
                                                    <h4 className="">
                                                        Confirm Organization Account Deletion
                                                    </h4>
                                                    <p>
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "400",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            This operation will delete everything your
                                                            organization has saved to OurStoryz. This can only
                                                            be done by the Account Owner.
                                                        </span>

                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            This will delete all events, teams, clients, and
                                                            vendors from your system, and remove your login
                                                            information.
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "400",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            Make absolutely sure you want to do this!
                                                        </span>

                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            Your Event and Guest information will be stored
                                                            for 30 days, and can be retrieved in spreadsheet
                                                            format by contacting support@ourstoryz.com.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    style={{ background: "#FC3158", color: "#fff" }}
                                                >
                                                    Remove
                                                </Button>
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    onClick={() => setnotificationModal(false)}
                                                    style={{ border: "1px solid #147EFB" }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Modal>
                                    </Col>

                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="warning"
                                            onClick={() => setnotificationModalacoun(true)}
                                        >
                                            Delete Account Owner Complete
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered modal-danger deleteaccountowner"
                                            isOpen={notificationModalacoun}
                                            toggle={() => setnotificationModalacoun(false)}
                                        >
                                            <div className="modal-body mt-4">
                                                <div className="">
                                                    <h4 className="">
                                                        Organization Account Deletion Complete
                                                    </h4>
                                                    <p>
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginTop: "20px",
                                                                marginBottom: "30px",
                                                            }}
                                                        >
                                                            Your account has been deleted.
                                                        </span>

                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            Your Event and Guest information will be stored
                                                            for 30 days, you will receive instructions to
                                                            download your guest and event data in spreadsheet
                                                            format. For help, contact support@ourstoryz.com
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    onClick={() => setnotificationModalacoun(false)}
                                                    style={{ border: "1px solid #147EFB" }}
                                                >
                                                    Restore Account
                                                </Button>
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    style={{ background: "#FC3158", color: "#fff" }}
                                                >
                                                    Exit OurStoryz
                                                </Button>
                                            </div>
                                        </Modal>
                                    </Col>

                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="warning"
                                            onClick={() => setnotificationModalacouno(true)}
                                        >
                                            Delete Account Owner Restore Account
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered modal-danger deleteaccountowner"
                                            isOpen={notificationModalacouno}
                                            toggle={() => setnotificationModalacouno(false)}
                                        >
                                            <div className="modal-body mt-4">
                                                <div className="">
                                                    <h4 className="">Restore Your Account</h4>
                                                    <p className="mt-4 mb-4">
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginBottom: "15px",
                                                            }}
                                                        >
                                                            This action will restore your organization’s
                                                            account, along with your last payment and billing
                                                            information.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    onClick={() => setnotificationModalacouno(false)}
                                                    style={{ border: "1px solid #147EFB" }}
                                                >
                                                    Cancel Restore
                                                </Button>
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    style={{ background: "#FC3158", color: "#fff" }}
                                                >
                                                    Continue Account Restore
                                                </Button>
                                            </div>
                                        </Modal>
                                    </Col>

                                    <Col md="4">
                                        <Button
                                            block
                                            className="mb-3"
                                            color="warning"
                                            onClick={() => setnotificationModalRestored(true)}
                                        >
                                            Account Owner Account Restored hjghjg
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered modal-danger deleteaccountowner"
                                            isOpen={notificationModalRestored}
                                            toggle={() => setnotificationModalRestored(false)}
                                        >
                                            <div className="modal-body mt-4">
                                                <div className="">
                                                    <h4 className="">
                                                        Your Account has been Restored...
                                                    </h4>

                                                    <p>
                                                        <span
                                                            style={{
                                                                fontSize: "14px",
                                                                display: "block",
                                                                fontWeight: "300",
                                                                marginTop: "30px",
                                                                marginBottom: "30px",
                                                            }}
                                                        >
                                                            Welcome back! All your organization, project and
                                                            event and billing information has been restored.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="modal-footer" style={{ margin: "auto" }}>
                                                <Button
                                                    className="btn-white"
                                                    color="default"
                                                    type="button"
                                                    onClick={() => setnotificationModalRestored(false)}
                                                    style={{
                                                        border: "1px solid #147EFB",
                                                        padding: "10px 50px 10px 50px",
                                                        background: "rgb(20, 126, 251)",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    Continue
                                                </Button>
                                            </div>
                                        </Modal>
                                    </Col>

                                    <Col md="4">
                                        <Button
                                            block
                                            color="default"
                                            onClick={() => setformModal(true)}
                                        >
                                            Floating Search Dialog
                                        </Button>
                                        <Modal
                                            className="modal-dialog-centered"
                                            size="sm"
                                            isOpen={formModal}
                                            toggle={() => setformModal(false)}
                                        >
                                            <div className="modal-body p-0">
                                                <Card className="bg-secondary border-0 mb-0">
                                                    <CardHeader className="bg-transparent pb-5">
                                                        <div className="text-muted text-center mt-2 mb-3">
                                                            <small>Sign in with</small>
                                                        </div>
                                                        <div className="btn-wrapper text-center">
                                                            <Button
                                                                className="btn-neutral btn-icon"
                                                                color="default"
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <img
                                                                        alt="..."
                                                                        src={
                                                                            require("assets/img/icons/common/github.svg")
                                                                                .default
                                                                        }
                                                                    />
                                                                </span>
                                                                <span className="btn-inner--text">Github</span>
                                                            </Button>
                                                            <Button
                                                                className="btn-neutral btn-icon"
                                                                color="default"
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <img
                                                                        alt="..."
                                                                        src={
                                                                            require("assets/img/icons/common/google.svg")
                                                                                .default
                                                                        }
                                                                    />
                                                                </span>
                                                                <span className="btn-inner--text">Google</span>
                                                            </Button>
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody className="px-lg-5 py-lg-5">
                                                        <div className="text-center text-muted mb-4">
                                                            <small>Or sign in with credentials</small>
                                                        </div>
                                                        <Form role="form">
                                                            <FormGroup
                                                                className={classnames("mb-3", {
                                                                    focused: focusedEmail,
                                                                })}
                                                            >
                                                                <InputGroup className="input-group-merge input-group-alternative">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-email-83" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        placeholder="Email"
                                                                        type="email"
                                                                        onFocus={() => setFocusedEmail(true)}
                                                                        onBlur={() => setFocusedEmail(false)}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                            <FormGroup
                                                                className={classnames({
                                                                    focused: focusedPassword,
                                                                })}
                                                            >
                                                                <InputGroup className="input-group-merge input-group-alternative">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-lock-circle-open" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        placeholder="Password"
                                                                        type="password"
                                                                        onFocus={() => setFocusedPassword(true)}
                                                                        onBlur={() => setFocusedPassword(false)}
                                                                    />
                                                                </InputGroup>
                                                            </FormGroup>
                                                            <div className="custom-control custom-control-alternative custom-checkbox">
                                                                <input
                                                                    className="custom-control-input"
                                                                    id=" customCheckLogin"
                                                                    type="checkbox"
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor=" customCheckLogin"
                                                                >
                                                                    <span className="text-muted">
                                                                        Remember me
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="text-center">
                                                                <Button
                                                                    className="my-4"
                                                                    color="primary"
                                                                    type="button"
                                                                >
                                                                    Sign in
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </Modal>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Eventspopup;
