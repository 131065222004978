import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Row, Col, Label } from "reactstrap";
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { paymentService } from "../../_services/masters/payment.service";

// const CARD_OPTIONS = {
// 	iconStyle: "solid",
// 	style: {
// 		base: {
// 			iconColor: "#c4f0ff",
// 			color: "black",
// 			fontWeight: 500,
// 			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
// 			fontSize: "16px",
// 			fontSmoothing: "antialiased",
// 			":-webkit-autofill": { color: "black" },
// 			"::placeholder": { color: "black" }
// 		},
// 		invalid: {
// 			iconColor: "#ffc7ee",
// 			color: "black"
// 		}
// 	}
// }

function PaymentForm() {
	const [success, setSuccess] = useState(false)
	const stripe = useStripe()
	const elements = useElements()
	const [errorSet, setErrorSet] = useState()
	const [cardData, setCardData] = useState([]);

	const onChange = (e) => {
		console.log('hello');
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement)
		})
		let number = document.getElementById("CardNumberElement");


		if (!error) {
			setErrorSet('');

			// try {
			//     const { id } = paymentMethod
			//     const response = await axios.post(Global.BASE_API_PATH + `/payment/charge_card`, {
			//         amount: 10000,
			//         id
			//     })

			//     if (response.data.success) {
			//         console.log("Successful Payment")
			//         setSuccess(true)
			//     }

			// } catch (error) {
			//     setErrorSet("Error", error)
			// }
		} else {
			setErrorSet(error.message)
		}
	}

	useEffect(() => {
		paymentService.cardDetails()
			.then(
				res => {

					if (res.status === 'success') {
						setCardData(res.cardDetails.cardData);
					}
				},
				error => {
					console.log('Something went wrong !!');
				})
	}, []);


	return (
		<>
			{errorSet && <p style={{ color: 'red' }}>{errorSet}</p>}
			{!success ?
				<Row>
					<Col sm={12} lg={7}>

						<form onSubmit={handleSubmit}>
							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Card Number (last 4 digit):</Label>
								<Input id="exampleText" name="text" type="text" readOnly value={cardData.last4} />
							</FormGroup>
							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Exp Date:</Label>
								<Input id="exampleText" name="text" type="text" readOnly value={cardData.exp_month + '/' + cardData.exp_year} />
							</FormGroup>
							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Brand:</Label>
								<Input id="exampleText" name="text" type="text" readOnly value={cardData.brand} />
							</FormGroup>
							{/* <FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Card Number:</Label>
								<InputGroup className={classnames("input-group-alternative", {})}>
									<CardNumberElement id='CardNumberElement' options={CARD_OPTIONS} className="form-control" onChange={onChange} />
								</InputGroup>
							</FormGroup>

							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Expire Date:</Label>
								<InputGroup className={classnames("input-group-alternative", {})}>
									<CardExpiryElement id='expdate' options={CARD_OPTIONS} className="form-control" />
								</InputGroup>
							</FormGroup>

							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">Security Code:</Label>
								<InputGroup className={classnames("input-group-alternative", {})}>
									<CardCvcElement id='cardcvc' options={CARD_OPTIONS} className="form-control" />
								</InputGroup>
							</FormGroup>
							<div className="modal-footer px-0 pb-0">
								<Button type="button" className="blueBtn flex-grow-1 ">Save New Card</Button>
								<Button type="submit" className="blueBtn flex-grow-1 ">Submit</Button>
							</div> */}
						</form>
					</Col>
					<Col sm={12} lg={5}>
						<div className="paymentReminderBox">
							<FormGroup className="formBox">
								<div className="custom-control form-check form-check-inline">
									<label className="container3 form-check-label">
										Notify when payment is due:
										<input type="checkbox" name="" checked />
										<span className="checkmark"></span>
									</label>
								</div>
							</FormGroup>
							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">
									Payment Reminder
								</Label>
								<Input
									id="exampleText"
									name="text"
									type="textarea"
									value="2 days before expiration"
								/>
							</FormGroup>
							<FormGroup className="formBox">
								<Label htmlFor="exampleEmail">
									Payment Gateway is active
								</Label>
								<div className="d-flex align-items-center">
									<button className="changeBtn fontWeight500 flex-grow-1 m-0">
										Validate
									</button>
									<button className="changeBtn fontWeight500 flex-grow-1 ml-2">
										Cancel
									</button>
								</div>
							</FormGroup>
						</div>
					</Col>
				</Row>
				:
				<Row>
					<Col sm={12} lg={7}>
						<div className="payment-success">
							<h2>Payment successful</h2>
							<h3 className='Thank-you'>Thank you for your patronage</h3>
						</div>
					</Col>
				</Row>
			}
		</>
	)
}

export default PaymentForm;
