import { USER_ADMIN, CLEAR_USER_ADMIN_INFO } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    listData: {
        profileAddmindetails: null,
        orgList: {},
    },
};
function userAdminDataReducers(state = initialState, action) {
    switch (action.type) {
        case USER_ADMIN:
            return {
                ...state,
                isConfirmatiom: true,
                listData: action?.payload,
            };
        case CLEAR_USER_ADMIN_INFO:
            // console.log(action.product_Id);
            // const newPeople = state.cart.filter(item => item.id !== action.product_Id);
            //console.log('remove');
            return {
                isConfirmatiom: false,
                listData: {},
            };

        default:
            return state;
    }
}
export default userAdminDataReducers;
