import React, { useEffect, useState, useRef } from "react";
import { Eventstyle } from "./styles";
import Eventmenu from "./eventMenu.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Row,
  Col,
  Progress,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Imggroup from "../../assets/img/dummyImg.svg";
import ReactTooltip from "react-tooltip";
import { ReactSession } from "react-client-session";
import { informationService } from "../../_services/masters/information.events";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Global from "_helpers/global";
import { useSelector, useDispatch } from "react-redux";
import { GrCircleInformation } from "react-icons/gr";
import DeleteEverythingInfoIcon from "./../../../src/assets/img/resetgeospaceInfoIcon.svg";
import "../../components/FileStackApp/index.css";
import FileStackApp from "../FileStackApp/FileStackApp";
import moment from "moment";
import { GuestEventWrapper } from "./UserGuestList/style";
import { Link } from "react-router-dom";

function Eventabout({ ...args }) {
  const inputRef = useRef();
  const [imgData, setImgData] = React.useState(null);
  const [imgEditData, setEditImgData] = React.useState(null);
  const [imgDataUrl, setImgUrl] = React.useState("");
  const [msg, setMsg] = React.useState();
  const [imgModalBox, setImgModalBox] = React.useState(false);
  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [importFromLocal, setImportFromLocal] = useState(false);
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );

  const [imgEditDataUrl, setEditImgUrl] = React.useState("");
  const params = useParams();
  const project_id = params.sid; // story id
  const event_id = params.eid; // event id
  const [projectid, setProjectId] = React.useState(params.sid);
  const [eventid, setEventId] = React.useState(event_id);
  // const [eventid, setEventId] = React.useState(params.eid);
  const [img_id, imgId] = React.useState(0);
  const [delImgUrl, setDelImgUrl] = React.useState("");
  const [delImgName, setDelImgName] = React.useState("");
  const [delImgMimeType, setDelImgMimeType] = React.useState("");

  const [img_eid, imgEditId] = React.useState(0);
  const [mediaGallertList, setMediagallert] = React.useState([]);
  const [defaultModale, setdefaultModale] = React.useState(false);
  const [photo_label, setPhotolabel] = React.useState("");
  const [photo_caption, setPhotocaption] = React.useState("");
  const [photo_Editlabel, setEditPhotolabel] = React.useState("");
  const [photo_Editcaption, setEditPhotocaption] = React.useState("");
  const [gallerylocation, setGallerylocation] = useState("");
  const [imgEditModalBox, setImgEditModalBox] = useState(false);
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [editImageName, setEditImageName] = useState("");
  const [imageName, setImageName] = useState("");
  const [video, setVideo] = useState("");
  const [editVideo, setEditVideo] = useState("");
  const [editHiddenId, setEditHiddenId] = useState("");
  const [filestack, setFilestack] = useState([]);
  const [mediaGallery, setMediaGallery] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [AddCopy, setAddCopy] = useState("New");
  const [photoId, setPhotoId] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [storyCount, setStoryCount] = useState(0);
  const [importType, setType] = useState("Add");

  //let todayDate = new Date();
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      //setImgUrl('');
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setMsg("");
      setImgUrl("loading..");
      setVideo("image");
      setImgData(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setTimeout(() => {
        setVideo(videourl1);
        setImgUrl(urldata);
      }, "0");
    }
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {

      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // setFormValues({ ...formValues, ['img_address']: place.formatted_address });
    }
  };

  const onEditChangePicture = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setEditImgUrl(urldata);
      setEditImgData(e.target.files[0]);
      setEditImageName(e.target.files[0].name);
      setEditVideo(videourl1);
    }
  };

  useEffect(() => {
    setImgModalBox(false);
    getmgList(eventid);
  }, [eventid]);

  //   list of data showing oin page
  const getmgList = (eventid) => {
    setImgEditModalBox(false);
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
    setPhotolabel("");
    setPhotocaption("");
    setImgData(null);
    informationService.eventGetMediaGallertList(eventid).then(
      (res) => {
        if (res.status === "success") {
          setMediagallert(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  //   list of data showing oin page
  const getMediaGallery = () => {
    informationService.getMediaGallery().then(
      (res) => {
        if (res.status === "success") {
          setMediaGallery(res.data);
          //console.log('mediaGallery', res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  //   create media gallery here
  const saveGallery = () => {
    var form = new FormData();
    form.append("photo_name", imgData); // image url
    form.append("mime_type", imgData.type); // image type image/video
    form.append("photo_label", photo_label); // title
    form.append("photo_caption", photo_caption); // image caption
    form.append("project_id", projectid); // story Id
    form.append("event_id", eventid); // event category
    form.append("is_default", 1); // Is Deleted
    form.append("status", 1);
    form.append("description", "hhhhh"); // Description
    form.append("gallery_location", gallerylocation); // Location Map

    informationService.saveEventGallery(form).then(
      (res) => {
        if (res.status === "success") {
          getmgList(eventid);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
  };
  //   create media gallery here
  const saveGalleryWithFileStack = () => {
    //alert(video);
    let saveData = {
      photo_name: imgDataUrl,
      mime_type: video,
      photo_label: photo_label,
      photo_caption: photo_caption,
      project_id: projectid,
      event_id: eventid,
      description: "",
      location: gallerylocation,
      add_type: AddCopy,
      photo_id: photoId,
    };
    //console.log('saveData', saveData);
    informationService.saveGalleryWithFileStack(saveData).then(
      (res) => {
        if (res.status === "success") {
          getmgList(eventid);
          toast.success("added successfully");
        } else if (res.message == "This photo already exists in this event") {
          //console.log("Something went wrong !!");
          toast.error("This photo already exists in this event");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
  };

  const updateGallery_Old = () => {
    var form = new FormData();
    if (imgEditData !== null) {
      form.append("photo_name", imgEditData);
      form.append("mime_type", video);
    }
    form.append("photo_label", photo_Editlabel);
    form.append("photo_caption", photo_Editcaption);
    form.append("project_id", projectid);
    form.append("event_id", eventid);
    form.append("is_default", 1);
    form.append("status", 1);
    form.append("description", "hhhhh");
    form.append("id", editHiddenId);
    form.append("gallery_location", gallerylocation); // Location Map
    informationService.updateEventGallery(form).then(
      (res) => {
        if (res.status === "success") {
          imgEditId(0);
          getmgList(eventid);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgEditModalBox(false);
  };

  const updateGallery = () => {
    let updateData = {
      photo_name: imgEditDataUrl,
      mime_type: video,
      photo_label: photo_Editlabel,
      photo_caption: photo_Editcaption,
      project_id: parseInt(projectid),
      event_id: parseInt(eventid),
      description: "",
      location: galleryEditlocation,
      add_type: "Edit",
      id: parseInt(editHiddenId),
    };

    informationService.updateEventGallery(updateData).then(
      (res) => {
        if (res.status === "success") {
          imgEditId(0);
          getmgList(eventid);
          toast.success("updated successfully");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgEditModalBox(false);
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
  };

  const openDeleteBox = (id, imgUrl, imgName, mime_type) => {
    //console.log('projectid', projectid);
    let data = { photo_id: id };
    informationService.countEventStory(data).then(
      (res) => {
        if (res.status === "success") {
          //console.log('res.data.event_id', res.data.event_id)
          setEventCount(res.data.event_id);
          setStoryCount(res.data.story_id);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
    imgId(id);
    setDelImgUrl(imgUrl);
    setDelImgName(imgName);
    setDelImgMimeType(mime_type);
    // imgEditId(eid);
    setdefaultModale(true);
    setImgEditModalBox(false);
  };

  const deleteImg = (img_id) => {
    informationService.deleteEventImg(img_id).then(
      (res) => {
        if (res.status === "success") {
          setdefaultModale(false);
          getmgList(eventid);
          toast.success("deleted successfully");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const openEditMode = (eid) => {
    setType("Edit");
    const result = mediaGallertList.filter(
      (mediaGallertList) => mediaGallertList.id == eid
    );
    //alert(result[0].updated_at);
    const urlName = result[0].photo_url.split("/");
    const urlName1 = urlName[urlName.length - 1];
    setEditImgUrl(result[0].photo_url);
    setEditPhotolabel(result[0].alt_text);
    setGalleryEditlocation(result[0].location);
    //setTodayDate(result[0].updated_at);
    setEditPhotocaption(result[0].caption);
    setVideo(result[0].mime_type);
    setEditVideo(result[0].mime_type);
    setEditImageName(urlName1);
    setEditHiddenId(result[0].id);
    // imgEditId(eid);
    setImgEditModalBox(eid);
  };

  const AddBrowImgModal = () => {
    setType("Add");
    setPhotoId(0);
    setBrowseImgModal(true);
    setImgEditModalBox(false);
    getMediaGallery();
  };

  const EditBrowImgModal = () => {
    setType("Edit");
    //setPhotoId(0)
    setBrowseImgModal(true);
    setImgEditModalBox(false);
    getMediaGallery();
  };

  const BrowseImgModal = () => {
    setBrowseImgModal(false);
  };

  const ImportFromLocal = () => {
    setImportFromLocal(true);
    setBrowseImgModal(false);
  };
  const ImportFromLocalClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(true);

    setImgUrl();
    setImgData();
    setImageName();
    setVideo();
  };

  const changesSetFile = () => {
    // alert('changesSetFile')
    setImgModalBox(false);
    localStorage.setItem("setFile", "");
  };

  const gotoEdit = (eid) => {
    //alert(eid);
    setImgModalBox(true);
    const result = mediaGallery.filter(
      (mediaGallery) => mediaGallery.id == eid
    );
    //console.log('mediaGallery', mediaGallertList);
    setImgUrl(result[0].photo_url);
    setPhotolabel(result[0].alt_text);
    setPhotocaption(result[0].caption);
    setVideo(result[0].mime_type);
    setGallerylocation(result[0].location);
    setImgModalBox(true);
    setBrowseImgModal(false);
    setAddCopy("AddCopy");
    setPhotoId(eid);
  };

  const setdata = (data) => {
    setPhotolabel("");
    setPhotocaption("");
    if (data.length > 0) {
      let mime_typeArr = data[0].mimetype.split("/");
      let mime_type = mime_typeArr[0];
      //alert(mime_type);
      setVideo(mime_type);
      setEditVideo(mime_type);
      setImgUrl(data[0].url);
      setEditImgUrl(data[0].url);
      setImageName(data[0].filename);
      // console.log('filestack imgDataUrl', imgDataUrl);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("setFile") !== "") {
      setImgModalBox(false);
    }
  }, [localStorage.getItem("setFile")]);

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const MediaUserDetails = JSON.parse(localStorage.getItem("userdata"));
  const Userfname = MediaUserDetails.first_name;
  const Userlname = MediaUserDetails.last_name;
  const UserPic = MediaUserDetails.imageUrl;

  return (
    <>
      <Modal
        isOpen={defaultModale}
        toggle={() => setdefaultModale(false)}
        {...args}
        className="deleteEverythingModal"
      >
        <ModalBody className="pb-0">
          <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
            <img
              src={DeleteEverythingInfoIcon}
              alt=""
              className="deleteEverythingInfoIcon"
            />
            <div className="head">Trash Media File?</div>
          </div>

          <Row className="mb-2 ">
            <Col>
              {delImgMimeType == "video" ? (
                <>
                  <video
                    className="deleteModalImg mb-3"
                    alt={delImgUrl}
                    controls
                  // autoplay
                  >
                    <source src={delImgUrl} type="" />
                  </video>
                </>
              ) : (
                <>
                  <img src={delImgUrl} className="deleteModalImg mb-3" alt="" />
                </>
              )}
              <p className="bold">File Name: {delImgName}</p>
              <p className="bold">
                Used by: {storyCount} Storyz, {eventCount} Events
              </p>
              <p></p>
              <p></p>
              <p>This will delete the file from your archive entirely.</p>
              <p>
                If you used this file in a Storyz, event, or Keepsake Album, you
                will need to replace it with another file. You will receive a
                notification of the affected files in your Notifications area.
              </p>
              <p>
                If you want to replace this file with different media, select
                “replace”.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn w-100 boxShadowNone"
            onClick={() => deleteImg(img_id)}
          >
            Trash
          </Button>

          <Button
            color="primary"
            className="flex-grow-1 blueBtn w-100 border-radius10 boxShadowNone"
          >
            Import Replacement
          </Button>
          <Button
            className="btn-default cancelBtn w-100 boxShadowNone"
            onClick={() => setdefaultModale(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Eventstyle>
        <div>
          <Eventmenu />
          <div className="column2">
            <div className="column2sate backgroeventdata">
              <div className="title">
                <div className="badge">Event</div>
                {common?.singleEvent?.event_name
                  ? common?.singleEvent?.event_name
                  : ""}
              </div>
              <div className="d-flex align-items-center">
                <div className="imageeventim234">
                  <span>Details</span>
                </div>
                <GrCircleInformation className="ml-3 pointer infoIcon" />
              </div>
            </div>
            <div className="borderBottom eventBannerPara">
              This view lets you assign images to this event, which will appear
              in web listings and in the guest app.
              <br />
              You can also assign media in the Storyz view.
            </div>
            <GuestEventWrapper>
              <div className="guestSearchWrapper mediaTabWrapper">
                <div className="guestUserWrap">
                  <Link to={`/admin/media-gallery/${params.sid}`}>
                    <div className="addGuest">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/aour-storyz.svg").default}
                        className="icon"
                      />
                      <div className="whiteSpace">Storyz Media</div>
                    </div>
                  </Link>
                  <div className="addGuest">
                    <div className="text-center">
                      <FileStackApp
                        setImgModalBox={setImgModalBox}
                        setFilestack={setFilestack}
                        setdata={setdata}
                        type="Add"
                      >
                        <div className="whiteSpace pt-1">Import Media</div>
                      </FileStackApp>
                    </div>
                  </div>
                </div>
              </div>
            </GuestEventWrapper>
            <div className="mainContentBoxWrapper eventMediaGallaryOuterWrapper">
              <div className="eventMediaListNew">
                <div className="eventMediaList">
                  <div className="lablepoint disabledNew">
                    <div> Image Label: </div>
                    <div className="buticon">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/pencil-icon.svg").default}
                        className="iconBtn"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/trash-icon.svg").default}
                        className="iconBtn"
                      />

                      {/* <button className="btn iconBtn fa fa-pen" title="Edit">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/pencil-icon.svg").default}
                            className="icon"
                          />
                        </button> */}
                      {/* <button
                          className="btn iconBtn fa fa-trash"
                          title="Delete"
                        ></button> */}
                    </div>
                  </div>
                  <div className="imageuplode">
                    <div className="imgMask" onClick={AddBrowImgModal}>
                      <div className="imgMask-content">+</div>
                    </div>
                  </div>

                  {browseImgModal && (
                    <div
                      className={`modal fade mediaFileListModal ${browseImgModal ? "show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title">Events Files</h1>
                            <button
                              type="button"
                              className="btn-close p-0"
                              onClick={BrowseImgModal}
                            >
                              <img
                                alt="Import New"
                                src={
                                  require("assets/img/deleteIcon.svg").default
                                }
                                className="closeBtn"
                              />
                            </button>
                          </div>
                          <div className="modal-body">
                            <div
                              className="importImageListWrapper"
                              onClick={BrowseImgModal}
                            >
                              {importType == "Add" ? (
                                <FileStackApp
                                  setImgModalBox={setImgModalBox}
                                  setFilestack={setFilestack}
                                  setdata={setdata}
                                  type="Add"
                                >
                                  Import New
                                </FileStackApp>
                              ) : (
                                <FileStackApp
                                  setImgEditModalBox={setImgEditModalBox}
                                  setBrowseImgModal={setBrowseImgModal}
                                  setFilestack={setFilestack}
                                  setdata={setdata}
                                  type="Edit"
                                >
                                  Import Edit
                                </FileStackApp>
                              )}

                              <div className="importedImageListWrapper">
                                <div className="importedImageListInner">
                                  {mediaGallery.map((x, i) => (
                                    <>
                                      {mediaGallery[i].mime_type == "video" ? (
                                        <>
                                          <div
                                            className="importedImageList"
                                            key={i}
                                            onClick={() =>
                                              gotoEdit(mediaGallery[i].id)
                                            }
                                          >
                                            <div className="uploadedItem">
                                              <div className="uploadedImg">
                                                <video
                                                  className="uploadImageFromLocalImg"
                                                  alt={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  controls
                                                // autoplay
                                                >
                                                  <source
                                                    src={
                                                      mediaGallery[i].photo_url
                                                    }
                                                    type=""
                                                  />
                                                </video>
                                                <img
                                                  src={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  alt=""
                                                  className="thum"
                                                />
                                              </div>
                                            </div>
                                            <div className="filename">
                                              {mediaGallery[i].alt_text}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="importedImageList"
                                            key={i}
                                            onClick={() =>
                                              gotoEdit(mediaGallery[i].id)
                                            }
                                          >
                                            <div className="uploadedItem">
                                              <div className="uploadedImg">
                                                <img
                                                  src={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  alt=""
                                                  className="thum"
                                                />
                                              </div>
                                            </div>
                                            <div className="filename">
                                              {mediaGallery[i].alt_text}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn selectFilebtn"
                              onClick={() =>
                                imgDataUrl !== ""
                                  ? setImgModalBox(true)
                                  : setMsg("Please choose video or photo")
                              }
                            >
                              Select File
                            </button>
                            <div
                              onClick={() => BrowseImgModal(false)}
                              className="btn selectFilebtn whiteBg"
                            >
                              Cancel
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {importFromLocal && (
                    <div
                      className={`modal fade mediaFileListModal importFromLocalModal ${importFromLocal ? "show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content importImageFromLocalContainer">
                          <div className="importFromLocalLeftWrapper">
                            <div className="importFromLocalLefList active">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/local-device.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Local Device
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/google-drive.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Google Drive
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/instagram.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Instagram
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/google-photos.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Google Photos
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={require("assets/img/icloud.svg").default}
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              iCloud
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/one-drive.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              OneDrive
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/dropbox.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Dropbox
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={require("assets/img/box.svg").default}
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Box
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/facebook.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Facebook
                            </div>
                          </div>
                          <div>
                            <div className="modal-header">
                              <h1 className="modal-title">
                                Import From Local Device
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={ImportFromLocalClose}
                              >
                                <svg
                                  className="closeBtn"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                                </svg>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="importFromLocalContent">
                                <div className="importFromLocalContentLeft">
                                  <i className="fa fa-image faImage"></i>
                                </div>
                                Drag file into grey area below, or tap to upload
                              </div>
                              {/* <h1>Image Type</h1> */}
                              <p style={{ color: "#FF0000" }}>{msg}</p>
                              <div className="uploadImageFromLocalWrapper">
                                <div className="uploadImageFromLocalContent">
                                  <input
                                    type="file"
                                    name="files"
                                    className="fileInput"
                                    accept="image/png, image/jpeg, video/*"
                                    onChange={onChangePicture}
                                  />
                                  <i className="fa fa-plus faPlus"></i>
                                </div>
                                {/* <div className="imageuplode mt-3"> */}
                                {video}

                                {video == "video" ? (
                                  <>
                                    <video
                                      className="uploadImageFromLocalImg"
                                      alt={imgDataUrl}
                                      controls
                                    >
                                      <source src={imgDataUrl} type="" />
                                    </video>
                                  </>
                                ) : (
                                  <>
                                    {imgDataUrl ? (
                                      <img
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        alt={imgDataUrl}
                                        className="uploadImageFromLocalImg"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                                {/* </div> */}
                                {/* {imgDataUrl ? (
                              <img
                                className="uploadImageFromLocalImg"
                                src={imgDataUrl ? imgDataUrl : ""}
                                alt="."
                              />
                            ) : (
                              ""
                            )} */}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn selectFilebtn"
                                onClick={() =>
                                  imgDataUrl !== ""
                                    ? setImgModalBox(true)
                                    : setMsg("Please choose video or photo")
                                }
                              >
                                Select File
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {imgModalBox && (
                    <>
                      <div
                        className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""
                          }`}
                      >
                        <div className="closeIcon" onClick={changesSetFile}>
                          <svg
                            className="closeBtn"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                          </svg>
                        </div>
                        <div className="mediaModalHeaderWrapper">
                          <span className="mediaLogo">
                            <i className="fa fa-image mediaLogoIcon"></i>
                          </span>{" "}
                          Add Image Information
                        </div>
                        <div className="mediaImageWrapper">
                          <div className="date">
                            {moment(todayDate).format("MMMM D,  YYYY h:mm a")}
                            {/* {todayDate} */}
                            {/* August 15, 2022: 3:30pm */}
                            {/* {imgDataUrl ? imageDate : ""} */}
                          </div>
                          {/* <h1>Video Type: {video}</h1> */}
                          <div className="imageCropperWrapper">
                            <div className="uploadImageFromLocalContent">
                              <FileStackApp
                                setImgModalBox={setImgModalBox}
                                setFilestack={setFilestack}
                                setdata={setdata}
                                type="plus"
                              ></FileStackApp>
                            </div>

                            {video === "video" ? (
                              <>
                                <video
                                  className="imageCropper"
                                  alt={imgDataUrl}
                                  controls
                                >
                                  <source
                                    src={imgDataUrl ? imgDataUrl : ""}
                                    type=""
                                  />
                                </video>
                              </>
                            ) : (
                              <>
                                {imgDataUrl ? (
                                  <img
                                    src={imgDataUrl ? imgDataUrl : ""}
                                    alt={imgDataUrl}
                                    className="imageCropper"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>

                          {/* <div className="imageEditIcons">
                            <button className="button">
                              <i className="fa fa-expand"></i>
                            </button>
                            <button className="button">
                              <i className="fa fa-crop"></i>
                            </button>
                            <button
                              className="button"
                              onClick={() => setImgUrl(false)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div> */}
                        </div>

                        <div className="mediaUserDetails">
                          <div className="mediaUserImgWrapper">
                            <img
                              src={UserPic}
                              className="mediaUserImg"
                              alt={UserPic}
                            />
                          </div>
                          <div className="mediaUserContentWrapper">
                            <div className="mediaUserTitle">
                              {Userfname} {Userlname}
                            </div>
                            <div className="mediaUserDesignation">
                              Role in Event
                            </div>
                          </div>
                        </div>

                        <div className="filenameWrapper">
                          {/* File name: <span>File NameXXX </span> */}
                          File name: <span>{imgDataUrl ? imageName : ""}</span>
                        </div>
                        <div className="mediaFormWrapper">
                          <div className="formBox">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="photo_label"
                              name="photo_label"
                              placeholder="Image Label"
                              value={photo_label}
                              onChange={(e) => setPhotolabel(e.target.value)}
                            />

                            {/* Title: {alt_text} */}
                          </div>
                          <div className="formBox">
                            <label className="form-label">Caption:</label>

                            <textarea
                              type="text"
                              className="form-control"
                              id="photo_caption"
                              name="photo_caption"
                              placeholder="photo caption"
                              value={photo_caption}
                              onChange={(e) => setPhotocaption(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="formBox">
                            <label className="form-label">Location:</label>
                            <div className="locationMap locationgooglemap12">
                              <LoadScript
                                googleMapsApiKey={mapkey}
                                libraries={["places"]}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gallerylocation"
                                    placeholder="type in a name, or use the map..."
                                  // value={gallerylocation}
                                  // onChange={(e) => setGallerylocation(e.target.value)}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>
                              <div
                                className="map pointer"
                              //  onClick={handelMapModal}
                              >
                                <svg
                                  className="diamondTrunImg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                </svg>
                                Map
                              </div>
                            </div>
                          </div>
                          <div className="actionWrapper">
                            <button
                              type="submit"
                              className="btn confirmBtn"
                              onClick={() => saveGalleryWithFileStack()}
                            >
                              Save Image Meta-Data
                            </button>
                            <button
                              type="submit"
                              className="btn CancelBtn"
                              onClick={() => setImgModalBox(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mediaOverlay"></div>
                    </>
                  )}

                  {imgEditModalBox && (
                    <>
                      <div
                        className={`mediaModalWrapper ${imgEditModalBox ? "ShowModal" : ""
                          }`}
                      >
                        <div
                          className="closeIcon"
                          onClick={() => setImgEditModalBox(false)}
                        >
                          <svg
                            className="closeBtn"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                          </svg>
                        </div>
                        <div className="mediaModalHeaderWrapper">
                          <span className="mediaLogo">
                            <i className="fa fa-image mediaLogoIcon"></i>
                          </span>
                          Edit Image Information
                        </div>
                        <div className="mediaImageWrapper">
                          <div className="date">
                            {moment(todayDate).format("MMMM D,  YYYY h:mm a")}
                            {/* August 15, 2022: 3:30pm */}
                          </div>
                          <div className="imageCropperWrapper">
                            {/* <div className="uploadImageFromLocalContent">
                              <div
                                className="imgMask"
                                onClick={EditBrowImgModal}
                              >
                                <div className="imgMask-content">+</div>
                              </div>
                            </div> */}
                            {editVideo == "video" ? (
                              <>
                                <video
                                  className="imageCropper"
                                  alt={imgEditDataUrl}
                                  controls
                                >
                                  <source
                                    src={imgEditDataUrl ? imgEditDataUrl : ""}
                                    type=""
                                  />
                                </video>
                              </>
                            ) : (
                              <>
                                {imgEditDataUrl ? (
                                  <img
                                    src={imgEditDataUrl ? imgEditDataUrl : ""}
                                    alt={imgEditDataUrl}
                                    className="imageCropper"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                          {/* <div className="imageEditIcons">
                            <button className="button">
                              <i className="fa fa-expand"></i>
                            </button>
                            <button className="button">
                              <i className="fa fa-crop"></i>
                            </button>
                            <button
                              className="button"
                              onClick={() => setEditImgUrl(false)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div> */}
                        </div>

                        <div className="mediaUserDetails">
                          <div className="mediaUserImgWrapper">
                            <img
                              src={UserPic}
                              className="mediaUserImg"
                              alt={UserPic}
                            />
                          </div>
                          <div className="mediaUserContentWrapper">
                            <div className="mediaUserTitle">
                              {Userfname} {Userlname}
                            </div>
                            <div className="mediaUserDesignation">
                              Role in Event
                            </div>
                          </div>
                        </div>
                        <input
                          type="hidden"
                          className="form-control"
                          id="editHiddenId"
                          name="editHiddenId"
                          placeholder="editHiddenId"
                          value={editHiddenId}
                          onChange={(e) => setEditHiddenId(e.target.value)}
                        />
                        <div className="filenameWrapper">
                          File name:{" "}
                          <span>{imgEditDataUrl ? editImageName : ""}</span>
                        </div>
                        <div className="mediaFormWrapper">
                          <div className="formBox">
                            <label className="form-label">Title</label>

                            <input
                              type="text"
                              className="form-control"
                              id="photo_Editlabel"
                              name="photo_Editlabel"
                              placeholder="Image Label"
                              value={photo_Editlabel}
                              onChange={(e) =>
                                setEditPhotolabel(e.target.value)
                              }
                            />
                          </div>
                          <div className="formBox">
                            <label className="form-label">Caption:</label>

                            <textarea
                              type="text"
                              className="form-control"
                              id="photo_Editcaption"
                              name="photo_Editcaption"
                              placeholder="photo caption"
                              value={photo_Editcaption}
                              onChange={(e) =>
                                setEditPhotocaption(e.target.value)
                              }
                            ></textarea>
                          </div>
                          <div className="formBox">
                            <label className="form-label">Location:</label>
                            <div className="locationMap locationgooglemap12">
                              <LoadScript
                                googleMapsApiKey={mapkey}
                                libraries={["places"]}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gallerylocation"
                                    placeholder="type in a name, or use the map..."
                                    defaultValue={galleryEditlocation}
                                  //onChange={(e) => setGallerylocation(e.target.value)}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>
                              <div className="map">
                                <svg
                                  className="diamondTrunImg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                </svg>
                                Map
                              </div>
                            </div>
                          </div>
                          <div className="actionWrapper">
                            <button
                              type="submit"
                              className="btn confirmBtn"
                              onClick={() => updateGallery()}
                            >
                              Save Image Meta-Data
                            </button>
                            <button
                              type="submit"
                              className="btn CancelBtn"
                              onClick={() => setImgEditModalBox(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mediaOverlay"></div>
                    </>
                  )}

                  <div className="caption disabledNew">
                    <p>Caption:</p>
                    {/* <textarea type="text" className="form-control" id="photo_caption" name="photo_caption" placeholder="photo caption" value={photo_caption} onChange={(e) => setPhotocaption(e.target.value)} ></textarea> */}
                    <div className="captionBox"></div>
                  </div>
                </div>
                {mediaGallertList.map((GalleryData, i) => (
                  <div className="eventMediaList" key={i}>
                    {img_eid == GalleryData?.id ? (
                      <>
                        <div className="lablepoint">
                          <div> Image Label: </div>
                          <div className="buticon">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/pencil-icon.svg").default
                              }
                              className="iconBtn"
                              onClick={() => openEditMode(GalleryData?.id)}
                            />
                            <img
                              alt="Organization Name"
                              src={require("assets/img/trash-icon.svg").default}
                              className="iconBtn"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="imageuplode">
                          <div className="imgMask">
                            <div
                              className="imgMask-content"
                              style={{ zIndex: 2, color: "#070000" }}
                            >
                              <input
                                type="file"
                                accept="image/png, image/jpeg, video/*"
                                className="imgg"
                                id="imgInp"
                                name="files"
                                onChange={onChangePicture}
                              />
                              +
                            </div>
                          </div>

                          {imgEditDataUrl ? (
                            <img
                              id="blah1"
                              src={imgEditDataUrl ? imgEditDataUrl : ""}
                              alt="."
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="caption">
                          <p>Caption:</p>
                          <div className="captionBox">caption here</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="lablepoint">
                          <div
                            className="title"
                            data-tip={GalleryData.alt_text}
                          >
                            {GalleryData.alt_text}
                          </div>
                          <ReactTooltip />
                          <div className="buticon">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/pencil-icon.svg").default
                              }
                              className="iconBtn"
                              onClick={() => openEditMode(GalleryData?.id)}
                            />
                            <img
                              alt="Organization Name"
                              src={require("assets/img/trash-icon.svg").default}
                              className="iconBtn"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="imageuplode">
                          {GalleryData?.mime_type !== "video" ? (
                            <div>
                              {GalleryData ? (
                                <img
                                  id="blah1"
                                  src={GalleryData?.photo_url}
                                  alt="."
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div>
                              <video
                                id="blah1"
                                width="320"
                                height="240"
                                controls
                              >
                                <source
                                  src={GalleryData?.photo_url}
                                  type=""
                                  style={{ marginTop: 37 }}
                                />
                              </video>
                            </div>
                          )}
                        </div>
                        <div className="caption">
                          <p>Caption:</p>
                          <div className="captionBox">
                            {GalleryData.caption}
                            {/* A wild beach wedding that will have to be seen to believed. */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
}
export default Eventabout;
