import React, { memo, useEffect } from "react";
import classNames from "./App.module.scss";
import GeofenceCoordinates from "./sections/GeofenceGoordinates";
import GpsLocationSection from "./sections/GpsLocation";
import InteractiveMap from "./sections/InteractiveMap";

import InteractiveMapModal from "./sections/InteractiveMapModal";
import NameAndSettingSection from "./sections/NameAndSettings";
import { batch, useDispatch, useSelector } from "react-redux";
import GlobalButton from "components/Button/GlobalButton";
import { useGetInfoQuery, useGetMessagesQuery } from "./services/information";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eventsService } from "../../../_services/masters/events.service";
import {
  useGetBeaconsQuery,
  useGetOthersQuery,
  useGetTablesQuery,
} from "./services/objects";
import {
  changeElevation,
  changeLatitude,
  changeLongitude,
  changeMotion,
  changeRadius,
  changeAddress,
} from "./store/geofence/slice";
import { changeLocationType } from "./store/gps/slice";
import { useGps } from "./hooks/useGps";
import Loader from "./icons/Loader";
import { setGeoId } from "./store/settings/slice";
import { settingSelector } from "./store/settings/selector";
import { useMap } from "./hooks/useMap";
import { geofenceSelector } from "./store/geofence/selector";
import { locationTypeSelector } from "./store/gps/selector";
import {
  changeSettingName,
  changeSettingDesc,
  changeSettingImage,
} from "./store/settings/slice";
const MOCKED_GEOSPACE_ID = 1;

function App({ evid, allimage }) {
  const dispatch = useDispatch();
  const { mapContainerRef, fitRadius, isFullscreen, isRadiusNotFit } = useMap();
  const { geoId } = useSelector(settingSelector);
  useEffect(() => {
    dispatch(setGeoId(MOCKED_GEOSPACE_ID));
    eventDetai();
  }, []);
  const { changeGpsLocation } = useGps();
  const { data: dataInfo, isLoading: isDataLoading } = useGetInfoQuery(
    { geoId },
    { skip: !geoId }
  );
  const { isLoading: isBeaconLoading } = useGetBeaconsQuery(
    { geoId },
    { skip: !geoId }
  );
  const { isLoading: isTableLoading } = useGetTablesQuery(
    { geoId },
    { skip: !geoId }
  );
  const { isLoading: isOtherLoading } = useGetOthersQuery(
    { geoId: geoId },
    { skip: !geoId }
  );
  const { isLoading: isMessagesLoading } = useGetMessagesQuery();

  useEffect(() => {

    if (dataInfo) {
      batch(() => {

        dispatch(changeElevation(dataInfo.elevation));
        dispatch(changeLatitude(dataInfo.lat));
        dispatch(changeLongitude(dataInfo.lng));
        dispatch(changeMotion(dataInfo.motion));
        dispatch(changeRadius(dataInfo.radius));
      });
      changeGpsLocation({
        latitude: dataInfo.lat,
        longitude: dataInfo.lng,
        elevation: dataInfo.elevation,
      });
    }
  }, [dataInfo]);
  const [eventData, setEventData] = React.useState({});
  const [imageurl, setImageurl] = React.useState("");
  function eventDetai() {
    const id = { event_id: evid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {

          setEventData(res.data);

          dispatch(changeElevation(res.data.elevation));
          dispatch(changeLatitude(res.data.latitude));
          dispatch(changeLongitude(res.data.longitude));
          dispatch(changeMotion(res.data.motion));
          dispatch(changeRadius(res.data.radius));
          dispatch(changeAddress(res.data.location));

          dispatch(changeSettingName(res.data.name_of_geospace));
          dispatch(changeSettingDesc(res.data.location_description));
          dispatch(changeLocationType("type"));
          setImageurl(res.data.location_cover_image);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  // if (
  //   !geoId ||
  //   isBeaconLoading ||
  //   isDataLoading ||
  //   isOtherLoading ||
  //   isTableLoading ||
  //   isMessagesLoading
  // ) {
  //   return (
  //     <div className={classNames.loader}>
  //       <Loader />
  //       <div>Fetching information...</div>
  //     </div>
  //   );
  // }
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const { latitude, longitude, motion, elevation, radius, address } =
    useSelector(geofenceSelector);

  const locationType = useSelector(locationTypeSelector);
  const { name, desc, image } = useSelector(settingSelector);
  const onSubmit = () => {
    if (eventData?.location_id) {
      const dataval = JSON.stringify({
        location: address ? address : "",
        latitude: latitude ? latitude : "",
        longitude: longitude ? longitude : "",
        elevation: elevation ? elevation : "",
        motion: motion ? motion : "",
        radius: radius ? radius : "",
        show_beacons: 1,
        show_other_event_objects: 1,
        name_of_geospace: name,
        location_type: locationType,
        location_description: desc,
        event_id: evid,
        id: eventData?.location_id,
        location_cover_image: image?.imaDatajson ? image?.imaDatajson : "",
        type: "event_location",
      });
      eventsService.updateLocation(dataval).then(
        (res) => {
          if (res.status === "success") {
            eventDetai();
            toast.success(res.message, "Account");
          } else {
            //console.log('Something went wrong !!');
            toast.error("Something went wrong !!", "Error");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          toast.error("Something went wrong !!", "Error");
          //console.log('Something went wrong !!');
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    } else {
      const dataval = JSON.stringify({
        location: address ? address : "",
        latitude: latitude ? latitude : "",
        longitude: longitude ? longitude : "",
        elevation: elevation ? elevation : "",
        motion: motion ? motion : "",
        radius: radius ? radius : "",
        show_beacons: 1,
        show_other_event_objects: 1,
        name_of_geospace: name,
        location_type: locationType,
        location_description: desc,
        event_id: evid,
        type: "event_location",
        imaDatajson: image?.imaDatajson ? image?.imaDatajson : "",
      });
      eventsService.addLocation(dataval).then(
        (res) => {
          if (res.status === "success") {
            eventDetai();
            toast.success(res.message, "Account");
          } else {
            //console.log('Something went wrong !!');
            toast.error("Something went wrong !!", "Error");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          toast.error("Something went wrong !!", "Error");

        }
      );
    }
  };

  return (
    <>
      <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="location_id" {...register("location_id")} />
        <div className="d-flex m-4">
          <GlobalButton
            customClass="ml-auto"
            img={require("assets/img/hornIcon.svg").default}
            type="submit"
          >
            Save Changes
          </GlobalButton>
          {/* <DeleteBtn icon> Delete Event </DeleteBtn> */}
        </div>
        <div className={classNames.main}>
          <div className={classNames.main__info_section}>
            <GpsLocationSection fitRadius={fitRadius} />
            <div className="GeofenceCoordinates">
              <GeofenceCoordinates
                fitRadius={fitRadius}
                isRadiusNotFit={isRadiusNotFit}
              />
            </div>
          </div>
          <div className={classNames.main__map_section}>
            <InteractiveMap ref={mapContainerRef} isFullscreen={isFullscreen} />
            <NameAndSettingSection allimage={allimage} imageurl={imageurl} />
          </div>
        </div>
        <InteractiveMapModal />
      </form>
    </>
  );
}

export default memo(App);
