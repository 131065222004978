import mouseImage from "../images/mouse.png";
import markerImage from "../images/marker.png";
import beaconImage from "../images/beacon.png";
import tableImage from "../images/table.png";
import checkInImage from "../images/checkIn.png";

const markerImages = {
  center: markerImage,
  beacon: beaconImage,
  table: tableImage,
  other: markerImage,
  checkIn: checkInImage,
};

const google = (window.google = window.google ? window.google : {});

export const getMarkerOptions = (type, map, position, icon) => ({
  map,
  clickable: type !== "center",
  draggable: type !== "center",
  icon: icon ?? markerImages[type],
  position,
});

export const getMapOptions = (latitude, longitude, radius) => ({
  center: {
    lat: latitude,
    lng: longitude,
  },
  zoom: 22 - radius * 0.1,
  fullscreenControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControlOptions: {
    position: google.maps.ControlPosition.BOTTOM_LEFT,
  },
});

export const getCircleOptions = (map, radius) => ({
  radius,
  map,
  editable: true,
  draggable: true,
  cursor: `url('${mouseImage}'), crosshair`,
  fillColor: "rgba(20, 126, 251)",
  fillOpacity: 0.05,
  strokeColor: "rgba(198, 193, 203)",
  strokeOpacity: 0.74,
  strokeWeight: 6,
});

export const removeMarkers = (markers) => {
  markers.forEach((marker) => {
    google.maps.event.clearListeners(marker, "dragend");
    marker.setMap(null);
  });
};

export const haversineDistance = (p1, p2) => {
  const R = 6378137;
  const rlat1 = p1.latitude * (Math.PI / 180);
  const rlat2 = p2.latitude * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (p2.longitude - p1.longitude) * (Math.PI / 180);
  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
        Math.cos(rlat1) *
        Math.cos(rlat2) *
        Math.sin(difflon / 2) *
        Math.sin(difflon / 2)
      )
    );
  return d;
};
