import React, { useEffect } from "react";
import { Eventstyle } from "./styles";
import { Row, Col, Modal, Button } from "reactstrap";
import Eventmenu from "./eventMenu.js";
import format from "date-fns/format";
import { informationService } from "../../_services/masters/information.service";
import { timezoneService } from "../../_services/masters/timezone.service";
import Timezoneset from "./time-zone";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import Accordion from "../Accordion/Accordion";
import Selectbox from "../../components/Inputbox/selectbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Repeatingevents from "./repeatingevents";
import { useDispatch, useSelector } from "react-redux";
import {
  InputField,
  CheckboxField,
  TextareaField,
} from "../../components/Inputbox";
import { useForm } from "react-hook-form";
import { GrCircleInformation } from "react-icons/gr";
import CustomButton from "components/Button/CustomButton";
import { TiUser } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";

function Eventabout() {
  const params = useParams();

  const events = useSelector((state) => {
    return state.eventSave.event;
  });

  const [eventInfo, setEventInfo] = React.useState({});
  const [howtochke, setHowtochke] = React.useState(
    eventInfo?.is_private ? eventInfo?.is_private : 0
  );
  const [paramsId, setParamsId] = React.useState(params.id ? params.id : "");
  const [notificationModalacouno, setnotificationModalacouno] =
    React.useState(false);
  const [selectboxS, setSelectboxS] = React.useState("");
  const [notificationModal, setnotificationModal] = React.useState(false);
  const [timezoneData, setTimezoneData] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endtDate, setEventEnds] = React.useState(new Date());
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const [imgData, setImgData] = React.useState(null);
  const [imgDa, setImgDa] = React.useState(null);
  const [eventName, setEventname] = React.useState(null);
  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    if (e.target.files[0]) {
      setImgData(URL.createObjectURL(e.target.files[0]));
    }
  };
  useEffect(() => {
    const data = {
      story_id: paramsId,
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    timezoneService.timezoneList().then(
      (res) => {
        if (res.status === "success") {
          setTimezoneData(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [paramsId]);

  const handleChangeinput = (e) => {
    eventInfo[e.targe.name] = e.targe.value;
    setEventInfo({ ...eventInfo });
  };
  const onSubmit = (data) => {
    var formdata = new FormData();
    formdata.append("event_name", data.event_name);
    formdata.append("project_id", params.id);
    formdata.append("event_type", selectboxS.id);
    formdata.append("hosted_by", data.hosted_by);
    formdata.append("cover_image", imgDa);

    formdata.append("event_description", data.event_description);
    formdata.append("special_instructions", data.special_instructions);
    formdata.append("event_start_date", format(startDate, "MM-dd-yyyy h:mm a"));
    formdata.append("event_end_date", format(endtDate, "MM-dd-yyyy h:mm a"));
    formdata.append("rsvp_deadline", data.rsvp_deadline);
    formdata.append("is_private", howtochke);
    formdata.append("is_global_rsvp", 1);
    formdata.append("status", 1);
    formdata.append("timezone", data.timezone);
    formdata.append("clock_format", data.clock_format);
    formdata.append("timezone_id", data.timezone_id);
    formdata.append("notes", data.notes);

    // var formdata = new FormData();
    // formdata.append("event_name", "tryrtyrt");
    // formdata.append("project_id", "1");
    // formdata.append("event_type", "1");
    // formdata.append("hosted_by", "fghfghfh");
    // formdata.append("cover_image", '');
    // formdata.append("event_description", "df");
    // formdata.append("special_instructions", "df");
    // formdata.append("event_start_date", "01-03-2023 7:30 AM");
    // formdata.append("event_end_date", "01-03-2023 7:30 AM");
    // formdata.append("rsvp_deadline", "2023-01-04 18:20:20");
    // formdata.append("is_private", "1");
    // formdata.append("is_global_rsvp", 1);
    // formdata.append("status", 1);
    // formdata.append("timezone", "GMT");
    // formdata.append("clock_format", "24");
    // formdata.append("parent_event_id", "1");
    // formdata.append("notes", "This a test notes");
    // formdata.append("timezone_id", "1");

    timezoneService.eventSave(formdata).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          if (res.event_id) {
            Repeatingfun(res.event_id);
          }

          reset();
        } else {
          //console.log('Something went wrong !!');
          toast.error("Something went wrong !!", "Error");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        toast.error("Something went wrong !!", "Error");
        //console.log('Something went wrong !!');
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  function Repeatingfun(event_id) {
    let eventId = event_id;
    //var formdata = new FormData();
    //formdata.append("is_repeating_event", parseInt(events?.repeating_events.repeating_event));
    //formdata.append("repeats_every", events?.repeating_events.repeat_event);
    //formdata.append("custom_days", parseInt(events?.repeating_events.custom));
    //formdata.append("repeating_day", events?.repeating_events.dayofrepeat);
    //formdata.append("repeats_until", events?.repeating_events.until);
    //formdata.append("eventId", parseInt(eventId));

    var formdata = {
      is_repeating_event: parseInt(events?.repeating_events.repeating_event),
      repeats_every: events?.repeating_events.repeat_event,
      custom_days: parseInt(events?.repeating_events.custom),
      repeating_day: events?.repeating_events.dayofrepeat,
      repeats_until: events?.repeating_events.until,
      eventId: parseInt(eventId),
    };
    //console.log(formdata);
    timezoneService.repeatSave(formdata).then(
      (res) => {
        if (res.status === "success") {
          reset();
          window.location.reload();
        } else {
          //console.log('Something went wrong !!');
          toast.error("Something went wrong !!", "Error");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        toast.error("Something went wrong !!", "Error");
        //console.log('Something went wrong !!');
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  return (
    <>
      <Eventstyle>
        <div>
          <Eventmenu />
          <div className="column2">
            <div className="column2sate backgroeventdata">
              <div className="title">
                <div className="badge">Event</div>
                {common?.singleEvent?.event_name
                  ? common?.singleEvent?.event_name
                  : ""}
              </div>
              <div className="d-flex align-items-center">
                <div className="imageeventim234">
                  <span>Details</span>
                </div>
                <GrCircleInformation className="ml-3 pointer infoIcon" />
              </div>
            </div>
            {/* <div className="AdDLiSt_BuTtON">
              <CustomButton>Save Information</CustomButton>
            </div> */}
            <div className="borderBottom eventBannerPara">
              This view lets you assign information, dates, and deadlines for
              your event.
            </div>
            <div className="d-flex m-4">
              <GlobalButton
                customClass="ml-auto"
                img={require("assets/img/hornIcon.svg").default}
                type="submit"
              >
                Save Changes
              </GlobalButton>
              <DeleteBtn icon> Delete Event </DeleteBtn>
            </div>
            <div className="mainContentBoxWrapper eventAboutWrapperNew">
              <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={12} xl={6}>
                    <div className="cardBox">
                      <div className="imgEventWraperNew">
                        <div className="imageventsla">
                          <div className="imgLabel">Image Label:</div>
                          <div className="imgContainer">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/mediaGallery.svg").default
                              }
                              className="icomn iconSize24 pointer"
                            />
                          </div>
                        </div>
                        <div className="imgMaskevente">
                          <div className="imgMask-content">+</div>
                          {imgData ? (
                            <img
                              className="storytoimageev"
                              id="blah2"
                              src={imgData ? imgData : ""}
                              alt="."
                            />
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            className="imgg1"
                            id="imgInp"
                            onChange={fileHandler}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="cardBox">
                      <InputField
                        type="text"
                        placeholder="Event Name"
                        label="Event Name:"
                        name="event_name"
                        onChange={handleChangeinput}
                        {...register("event_name", { required: true })}
                      />
                      {errors.event_name && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>

                    <div className="cardBox form-group eventHonoredGuestDropdown">
                      <label htmlFor="input-field">
                        Contact Admin for Event:
                      </label>
                      <div className="imageeventim">
                        <div className="imageeventim2 avatarCardWrapper">
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/Guest-Profile-Imageev.svg")
                                .default
                            }
                            className="imagevent"
                          />
                          <div className="imageventContent">
                            <div className="title">Admin Name</div>
                            <div className="subTitle">Admin</div>
                          </div>
                        </div>
                        <div
                          className="imageeventim22 pointer"
                          style={{ marginLeft: "25px" }}
                        >
                          Change
                        </div>
                      </div>
                    </div>

                    <div className="cardBox categoryTypeDropdown">
                      <Selectbox
                        onChangeSelect={setSelectboxS}
                        title=" Select list (select one):"
                      />
                    </div>

                    <div className="cardBox">
                      <InputField
                        type="text"
                        placeholder="Hosted by"
                        label="Hosted by:"
                        name="hosted_by"
                        onChange={handleChangeinput}
                        {...register("hosted_by")}
                      />
                    </div>

                    <div className="cardBox">
                      <TextareaField
                        name="our_storyz_description"
                        row="3"
                        placeholder="This storyz rox. It will be the greatest storyz ever told."
                        label="Event Description (1 paragraph):"
                        onChange={handleChangeinput}
                        {...register("event_description")}
                      />
                    </div>
                    <div className="cardBox">
                      <TextareaField
                        name="our_storyz_description"
                        row="3"
                        placeholder="This storyz rox. It will be the greatest storyz ever told."
                        label="Special Instructions (1 paragraph):"
                        onChange={handleChangeinput}
                        {...register("special_instructions")}
                      />
                    </div>
                  </Col>
                  <Col sm={12} xl={6}>
                    <div className="basicinformation">Dates and Times</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <label htmlFor="date" className="form-label">
                          Event Begins:
                        </label>
                        <div className="dateTimePickerGlobal">
                          <DatePicker
                            isClearable
                            name="event_start_date"
                            className={"form-control"}
                            selected={startDate}
                            onChange={(val) => {
                              setStartDate(val);
                              setValue("event_start_date", val);
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            placeholderText="Date"
                            dateFormat="MM-dd-yyyy h:mm a"
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>
                        <div className="form-text">
                          8/8/2022 in your local timezone (PST)
                        </div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="form-group">
                        <label htmlFor="date" className="form-label">
                          Event Ends:
                        </label>
                        <div className="dateTimePickerGlobal">
                          <DatePicker
                            isClearable
                            name="event_end_date"
                            className={"form-control"}
                            selected={endtDate}
                            onChange={(val) => {
                              setEventEnds(val);
                              setValue("event_end_date", val);
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            placeholderText="Date"
                            dateFormat="MM-dd-yyyy h:mm a"
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>
                        <div className="form-text">
                          8/8/2022 in your local timezone (PST)
                        </div>
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="form-group">
                        <Timezoneset />
                        <InputField
                          type="hidden"
                          name="timezone_id"
                          defaultValue={events?.time?.timezone}
                          value={events?.time?.timezone}
                          onChange={() => {
                            setValue("timezone_id", events?.time?.timezone);
                          }}
                          {...register("timezone_id")}
                        />

                        <InputField
                          type="hidden"
                          name="clock_format"
                          defaultValue={events?.time?.timevalue}
                          value={events?.time?.timevalue}
                          onChange={() => {
                            setValue("clock_format", events?.time?.timevalue);
                          }}
                          {...register("clock_format")}
                        />

                        <InputField
                          type="hidden"
                          defaultValue={events?.time?.abbr}
                          value={events?.time?.abbr}
                          name="timezone"
                          onChange={() => {
                            setValue("timezone", events?.time?.abbr);
                          }}
                          {...register("timezone")}
                        />
                      </div>
                    </div>
                    <div className="basicinformation">RSVP</div>
                    <div className="RSVPDeadline cardBox">
                      <div className="form-group">
                        <InputField
                          type="date"
                          placeholder="RSVP Deadline"
                          label="RSVP Deadline:"
                          name="rsvp_deadline"
                          onChange={handleChangeinput}
                          {...register("rsvp_deadline")}
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          8/8/2022 in your local timezone (PST)
                        </small>
                      </div>
                    </div>
                    <div className="basicinformation">Notes</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <TextareaField
                          name="notes"
                          row="3"
                          placeholder="This storyz rox. It will be the greatest storyz ever told."
                          label="Admin notes (not visible to guests):"
                          onChange={handleChangeinput}
                          {...register("notes")}
                        />
                      </div>
                    </div>

                    <div className="basicinformation">Repeating Events</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <Repeatingevents name={eventName} img={imgData} />
                      </div>
                    </div>

                    <div className="basicinformation">Settings</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <div className="eventSettingCheckbox">
                          <CheckboxField
                            label="Event is Private (not searchable from website)"
                            name="is_private"
                            {...register("is_private")}
                            onChange={(e) => {
                              setValue(
                                "is_private",
                                e.target.checked ? "1" : "0"
                              );
                              setHowtochke(!howtochke);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <CustomButton>Save Information</CustomButton> */}
                {/* <div className="AdDLiSt_BuTtON">
                <Button
                  className="btn-white"
                  color="default"
                  style={{
                    background: "#FC3158",
                    color: "#fff",
                    width: "120px",
                    borderRadius: "10px",
                  }}
                >
                  Save
                </Button>
              </div>*/}
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
}
export default Eventabout;
