import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import OrganizationLocator from "../../views/pages/OrganizationLocator";

import YourCompany from "../../components/Organization/YourCompany";
import Organizationmenu from "../../components/Organization/Organizationmenu";
import Yourteam from "../../components/Organization/Yourteam";
import Yourstoryz from "../../components/Organization/Yourstoryz";
import { useSelector } from "react-redux";

function Organization() {
  const contact = useSelector((state) => {
    return state.profile;
  });


  return (
    <>
      {/* {loading ? (
        <div className="laodingForContainer">
          <div className="circleAnimation1"></div>
        </div>
      ) : ( */}
      <>
        {contact.profiledetails.service_level === "Professional" ? (
          <>
            {/* <Allheader title="Organization" /> */}
            {/* <CombinedGuestPoolHeader title="Organization" imgScr={IconImg} /> */}
            <div className="organization">
              <Row>
                <Col lg={6} md={12}>
                  <div className="card-wrapper cardWrapper">
                    <Card className="">
                      <CardHeader>
                        <div className="cardheader2">
                          <div className="cardheader">
                            <img
                              alt="Organization Name"
                              src={require("assets/img/icons/eyes.svg").default}
                              className="icon"
                            />
                            <h3 className="mb-0 headingNew">Your Company</h3>
                          </div>
                          <div>
                            <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                              <img
                                alt="Organization Name"
                                src={require("assets/img/tip.svg").default}
                                className="icon"
                              />
                            </span>
                            <ReactTooltip />
                          </div>
                        </div>
                      </CardHeader>
                      <hr className="m-0" />
                      <CardBody>
                        <YourCompany />
                      </CardBody>
                    </Card>
                  </div>
                </Col>

                <Col lg={6} md={12}>
                  <div className="card-wrapper cardWrapper">
                    <Organizationmenu />
                  </div>
                </Col>

                <Col lg={6} md={12}>
                  <div className="card-wrappe cardWrapper">
                    <Yourteam />
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="card-wrapper cardWrapper">
                    <Yourstoryz />
                  </div>
                </Col>

              </Row>
            </div>
          </>
        ) : (
          ""
        )}
        {contact.profiledetails.service_level === "Locator" ? (
          <>
            <OrganizationLocator />
          </>
        ) : (
          <></>
        )}
        {contact.profiledetails.service_level === "DIY" ? (
          <>
            <OrganizationLocator />
          </>
        ) : (
          <></>
        )}
      </>
      {/* )} */}
    </>
  );
}

export default Organization;
