import React from "react";
import { TiUser } from "react-icons/ti";

const GlobalButton = ({ children, customClass, img, icon, type, value }) => {
  return (
    <>
      <button
        className={`btn saveChangesBtn ${customClass}`}
        type={type}
        disabled={value}
      >
        {img ? <img src={img} alt="" className="icon" /> : ""}
        {icon ? <TiUser className="icon" /> : ""}
        {children}
      </button>
    </>
  );
};

export default GlobalButton;
