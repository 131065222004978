import React from "react";
import { Button } from "reactstrap";
import moment from "moment";
import { informationService } from "../../_services/masters/information.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFindPathEid, useFindPathSid } from "../../CommonData";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import { useHistory } from "react-router-dom";
const EventDelete = (props) => {
  const dispatch = useDispatch();
  let eid = useFindPathEid(4);
  let sid = useFindPathSid(3);
  let history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };
  function closeModal(e) {
    e.stopPropagation();
    setIsLoading(false);
    props.closeModal();
  }
  function dataEvList() {
    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.EVENTS_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

  }

  const deleteStory = () => {
    const data = { id: eid };

    informationService.deleteEvent(data).then(
      (res) => {
        if (res.status === "success") {
          informationService.projectStory().then(
            (res) => {
              if (res.status === "success") {
                dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
              } else {
                console.log("Something went wrong !!");
              }
            },
            (error) => {
              console.log("Something went wrong !!");
              //toast.error("Something went wrong !!", "Fashion Store");
            }
          );
          toast.success(res.message);
          setIsLoading(true);
          dataEvList();
          history.push(`/admin/storyz/${sid}`);

        } else {
          //console.log('Something went wrong !!');
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );





  };
  return (
    <>
      {isLoading === false ? (
        <div
          className="modal storyzdelet eventDeleteModal"
          onClick={closeModal}
          style={divStyle}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="head border-bottom">
              <img
                alt="Organization Name"
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                className="icon"
              />
              <h2>Do You Want to Delete this Event?</h2>
            </div>
            <div className="popdataWrapper">
              <div className="popdata">
                <div className="popdate">
                  {props.event_date &&
                    props.event_date !== "NA"
                    ? moment
                      .utc(props.event_date)
                      .format("MMM Do")
                    : "No Date"}{" "}


                  {/* {props.event_date === "NA" || props.event_date !== ""
                    ? "No Date"
                    : moment(props.event_date).format("MMM Do")} */}
                </div>
                <div className="podImg">
                  <img className="" alt="Organization Name" src={props.simag} />
                </div>
                <div>
                  <div className="title">{props.name}</div>
                  <div className="subTitle">{props.event_count} location</div>
                </div>
              </div>
            </div>

            <p>
              Confirm that you want to delete this event permanently. All
              information will be lost.
            </p>

            <div className="modal-footer deleteModalFooter">
              <Button
                className="pinkBtn flex-grow-1"
                type="submit"
                onClick={deleteStory}
              >
                Delete Event
              </Button>
              <Button className="cancelBtn" type="submit" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default EventDelete;
