import React, { useState, useEffect } from "react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import Allheader from "../../components/Headers/Allheader";
import { informationService } from "../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Imggroup from "../../assets/img/dummyImg.svg";
import CombinedGuestPoolHeader from "../../components/Headers/CombinedGuestPoolHeader";
import { timezoneService } from "../../_services/masters/timezone.service";
import CalendarIcon from "./../../assets/img/calendarIconHeader.svg";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Modal,
  Container,
  Row,
  Col,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ResetGeospaceInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
// core components
let calendar;
function CalendarView({ ...args }) {
  const [events, setEvents] = React.useState([]);
  // const [alert, setAlert] = React.useState(null);
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalChange, setModalChange] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [radios, setRadios] = React.useState(null);
  const [eventId, setEventId] = React.useState(null);
  const [eventTitle, setEventTitle] = React.useState(null);
  const [eventCoverImage, setEventCoverImage] = React.useState(null);
  const [startDatePOP, setStartDatePOP] = React.useState(null);
  const [endDatePOP, setEndDatePOP] = React.useState(null);
  const [eventType, setEventType] = React.useState(null);
  const [hostedBy, setHostedBy] = React.useState(null);
  const [timezone, setTimezone] = React.useState(null);
  const [clock_format, setclockFormat] = React.useState(null);
  const [eventDescription, setEventDescription] = React.useState(null);
  // eslint-disable-next-line
  const [timezoneData, setTimezoneData] = React.useState();
  const [time_zone, TimezoneSet] = React.useState(37);
  const [Clock_style, ClockStyleSet] = React.useState(12);
  const [time_zoneData, TimezoneData] = React.useState([]);

  const [event, setEvent] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(null);
  const calendarRef = React.useRef(null);
  const [gridType, setGrid] = React.useState("dayGridMonth");
  const [count, setCount] = React.useState(0);

  const [storyData, setstoryData] = React.useState({});
  const params = useParams();
  //console.log("Param", params);
  const [paramsId, setParamsId] = React.useState(params.sid ? params.sid : "");


  function storyzDetail() {
    const data = {
      story_id: paramsId,
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setstoryData(res.data[0]);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const getuserTimeZone = (timezone_Data) => {
    //console.log("timezoneData", timezoneData);
    timezoneService.getuserTimeZone().then(
      (res) => {
        if (res.status === "success") {
               //setTimezoneData(res.data);
               TimezoneSet(res.data.time_zone);
               ClockStyleSet(res.data.clock_format);
             
               let resultTime = timezone_Data.filter((timezone_Data) => timezone_Data.id == res.data.time_zone);
               TimezoneData(resultTime);
            } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
   
  }

  const updateUserTimeZone = () => {
      const TimeZoneData = { clock_format: Clock_style, time_zone:time_zone };
      timezoneService.updateUserTimeZone(TimeZoneData).then(
      (res) => {
        if (res.status === "success") {
               //setTimezoneData(res.data);
               setTimezoneNew(false);
               getuserTimeZone(timezoneData);
               toast.success("Updated successfully");
               //userdetail();
          } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

  }

  React.useEffect(() => {
    timezoneService.timezoneList().then(
      (res) => {
        if (res.status === "success") {
             setTimezoneData(res.data);
             getuserTimeZone(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    
  }, []);


  useEffect(() => {
    if (paramsId !== "") {
      storyzDetail();
    } else {
      // getEventdata();
      //createCalendar();
    }
  }, [paramsId]);

  React.useEffect(() => {
    getEventdata();
    createCalendar();
    // eslint-disable-next-line
  }, [count]);

  const getEventdata = () => {
    informationService.storyzCalendarList().then(
      (res) => {
        if (res.status === "success") {
          let eData = res.data;
          for (let i = 0; i < eData.length; i++) {
            if (eData[i].start !== undefined) {
              eData[i].start = new Date(eData[i].start);
            }
            if (eData[i].end !== undefined) {
              eData[i].end = new Date(eData[i].end);
            }
          }

          let UserData = JSON.parse(localStorage.getItem("userdata"));
          let clock_format = UserData.clock_format;
          let timezone = UserData.time_zone_value;
          setEvents(eData);
          setTimezone(timezone || "IST");
          setclockFormat(clock_format || 12);
          setCount(1);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    //console.log('events-Data --', events)
  };

  const createCalendar = () => {
    calendar = new Calendar(calendarRef.current, {
      plugins: [interaction, dayGridPlugin],
      initialView: "dayGridMonth",
      selectable: true,
      editable: false,
      events: events,
      headerToolbar: "",
      // Add new event
      select: (info) => {
        setModalAdd(false);
        setStartDate(info.startStr);
        setEndDate(info.endStr);
        setRadios("bg-info");
      },
      // Edit calendar event action
      eventClick: ({ event }) => {
        window.location.href = `/admin/time-line/${event.id}`;
        // let st = event.startStr;
        // st = st.toString();
        // st = new Date(st);
        // st = st.toString();
        // let et = event.endStr;
        // et = et.toString();
        // et = new Date(et);
        // et = et.toString();
        // setEventId(event.id);
        // setEventTitle(event.title);
        // setStartDatePOP(st)
        // setEndDatePOP(et)
        // setEventType(event.extendedProps.event_type)
        // setHostedBy(event.extendedProps.hosted_by)
        // setTimezone(event.extendedProps.timezone)
        // setclockFormat(event.extendedProps.clock_format)
        // setEventCoverImage(event.extendedProps.cover_image);
        // setEventDescription(event.extendedProps.description);
        // setRadios("bg-info");
        // setEvent(event);
        // setModalChange(true);
      },
    });
    calendar.render();
    setCurrentDate(calendar.view.title);
  };
  const changeView = (newView) => {
    setGrid(newView);
    calendar.changeView(newView);
    setCurrentDate(calendar.view.title);
  };
  const addNewEvent = () => {
    var newEvents = events;
    newEvents.push({
      title: eventTitle,
      start: startDate,
      end: endDate,
      className: radios,
      id: events[events.length - 1] + 1,
    });
    calendar.addEvent({
      title: eventTitle,
      start: startDate,
      end: endDate,
      className: radios,
      id: events[events.length - 1] + 1,
    });
    setModalAdd(false);
    setEvents(newEvents);
    setStartDate(undefined);
    setEndDate(undefined);
    setRadios("bg-info");
    setEventTitle(undefined);
    setStartDatePOP(undefined);
    setEndDatePOP(undefined);
    setEventType(undefined);
    setHostedBy(undefined);
    setTimezone(undefined);
    setclockFormat(undefined);
    setEventCoverImage(undefined);
    setEventCoverImage(undefined);
  };
  // const changeEvent = () => {
  //   var newEvents = events.map((prop, key) => {
  //     if (prop.id + "" === eventId + "") {
  //       setEvent(undefined);
  //       calendar.getEventById(eventId).remove();
  //       let saveNewEvent = {
  //         ...prop,
  //         title: eventTitle,
  //         className: radios,
  //         description: eventDescription
  //       };
  //       calendar.addEvent(saveNewEvent);
  //       return {
  //         ...prop,
  //         title: eventTitle,
  //         className: radios,
  //         description: eventDescription
  //       };
  //     } else {
  //       return prop;
  //     }
  //   });
  //   setModalChange(false);
  //   setEvents(newEvents);
  //   setRadios("bg-info");
  //   setEventTitle(undefined);
  //   setStartDatePOP(undefined)
  //   setEndDatePOP(undefined)
  //   setEventType(undefined)
  //   setHostedBy(undefined)
  //   setEventCoverImage(undefined);
  //   setEventCoverImage(undefined)
  //   setTimezone(undefined)
  //   setclockFormat(undefined)
  //   setEventDescription(undefined);
  //   setEventId(undefined);
  //   setEvent(undefined);
  // };
  // const deleteEventSweetAlert = () => {
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => {
  //         setAlert(false);
  //         setRadios("bg-info");
  //         setEventTitle(undefined);
  //         setStartDatePOP(undefined)
  //         setEndDatePOP(undefined)
  //         setEventType(undefined)
  //         setHostedBy(undefined)
  //         setTimezone(undefined)
  //         setclockFormat(undefined)
  //         setEventCoverImage(undefined);
  //         setEventDescription(undefined);
  //         setEventId(undefined);
  //       }}
  //       onCancel={() => deleteEvent()}
  //       confirmBtnCssClass="btn-secondary"
  //       cancelBtnBsStyle="danger"
  //       confirmBtnText="Cancel"
  //       cancelBtnText="Yes, delete it"
  //       showCancel
  //       btnSize=""
  //     >
  //       You won't be able to revert this!
  //     </ReactBSAlert>
  //   );
  // };
  // const deleteEvent = () => {
  //   var newEvents = events.filter((prop) => prop.id + "" !== eventId);
  //   setEvent(undefined);
  //   setAlert(
  //     <ReactBSAlert
  //       success
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Success"
  //       onConfirm={() => setAlert(null)}
  //       onCancel={() => setAlert(null)}
  //       confirmBtnBsStyle="primary"
  //       confirmBtnText="Ok"
  //       btnSize=""
  //     >
  //       A few words about this sweet alert ...
  //     </ReactBSAlert>
  //   );
  //   setModalChange(false);
  //   setEvents(newEvents);
  //   setRadios("bg-info");
  //   setEventTitle(undefined);
  //   setStartDatePOP(undefined)
  //   setEndDatePOP(undefined)
  //   setEventType(undefined)
  //   setHostedBy(undefined)
  //   setTimezone(undefined)
  //   setclockFormat(undefined)
  //   setEventCoverImage(undefined);
  //   setEventDescription(undefined);
  //   setEventId(undefined);
  //   setEvent(undefined);
  // };
  const [timezoneNew, setTimezoneNew] = useState(false);
  const handleTimezone = (e) => {
    e.preventDefault();
    setTimezoneNew(!timezoneNew);
  };


  return (
    <>
    <ToastContainer />
      {/* {storyData ? ( */}
      <>
        {/* <Allheader
          title={
            storyData?.storyz_name ? storyData?.storyz_name : "New Storyz edit"
          }
          imgScr={
            storyData?.profile_photo_Url
              ? storyData?.profile_photo_Url
              : Imggroup
          }
          arw
        /> */}
        <CombinedGuestPoolHeader
          title="Calendar"
          arw={false}
          imgScr={CalendarIcon}
        />
        {/* <Allheader title="Calendar" arw={false} /> */}
        <div className="organizerWrapperMain">
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Storyz Calendar</h4>
              <div className="ml-auto">
                <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                  />
                </span>
                <ReactTooltip />
              </div>
            </div>
            {/* <h2 className="py-4 border-bottom m-0 headingNew">Storyz Calendar</h2> */}
            {/* <small className="py-4 border-bottom">
            Use the Calendar view as a way of organizing the events in your
            larger Storyz.
          </small> */}
            <div className="organizationPara p-4 border-bottom">
              Use the Calendar view as a way of organizing the events in your
              larger Storyz.
            </div>

            <div className="p-4 d-flex">
              <div className="ml-auto">
                <div className="h5">
                  Timezone :
                  <span className="font-weight-normal"> { time_zoneData.length > 0 ? time_zoneData[0].text : "" } </span>
                </div>
                <div className="h5">
                  Clock :
                  <span className="font-weight-normal">{Clock_style}</span>
                </div>
              </div>
              {/* <Link href="/admin/setting">
                <button className="settingBtn ml-2">Setting</button>
              </Link> */}
               
                <button className="settingBtn ml-2" onClick={handleTimezone}>Setting</button>
              
            </div>
            <Modal
                            isOpen={timezoneNew}
                            toggle={handleTimezone}
                            {...args}
                            className="changeTimezoneModal"
                          >
                            <ModalBody>
                              <div className="changeTimezoneModalHeader border-bottom ">
                                <img
                                  src={ResetGeospaceInfoIcon}
                                  alt=""
                                  className="changeTimezoneInfoIcon"
                                />
                                <div className="head">
                                  Change Timezon and Clock Style?
                                </div>
                              </div>
                              <Row>
                                <Col>
                                  <div className="containerBox px-4">
                                    <div className="form-group">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Timezone for the Organization
                                      </label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) => TimezoneSet(e.target.value)}
                                        value={time_zone}
                                      >
                                        {timezoneData?.map((post, index) => (
                                          <option key={index} data-key={post.abbr} value={post.id}>
                                            {post.text}
                                            </option>
                                           ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Clock Style
                                      </label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) => ClockStyleSet(e.target.value)}
                                        value={Clock_style}
                                      >
                                        <option value="12" >12-Hour</option>
                                        <option value="24">24-Hour</option>
                                      </select>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <ModalFooter>
                                <Button
                                  color="primary"
                                  className="flex-grow-1 pinkBtn"
                                  onClick={() => updateUserTimeZone() }
                                 >
                                  Change
                                </Button>
                                <Button
                                  className="btn-default cancelBtn"
                                  onClick={handleTimezone}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </ModalBody>
                          </Modal>

            <div className="header header-dark pb-4 content__title content__title--calendar">
              <Container fluid className="">
                <div className="header-body">
                  <Row className="align-items-center">
                    <Col md="6">
                      <div className="d-flex showDate">
                        <Button
                          className="fullcalendar-btn-prev btn-neutral border-0 mr-0"
                          color="default"
                          onClick={() => {
                            calendar.prev();
                            changeView(gridType);
                          }}
                          size="sm"
                        >
                          <i className="fas fa-angle-left" />
                        </Button>
                        <h6 className="fullcalendar-title h2 mb-0 mx-2 dateWidth">
                          {currentDate}
                        </h6>
                        <Button
                          className="fullcalendar-btn-next btn-neutral border-0"
                          color="default"
                          onClick={() => {
                            calendar.next();
                            changeView(gridType);
                          }}
                          size="sm"
                        >
                          <i className="fas fa-angle-right" />
                        </Button>
                      </div>
                    </Col>
                    <Col className="mt-3 mt-md-0 text-md-right" md="6">
                      <Button
                        className="btn-neutral dateBtn"
                        color="default"
                        data-calendar-view="month"
                        onClick={() => changeView("dayGridMonth")}
                        size="sm"
                      >
                        Month
                      </Button>
                      <Button
                        className="btn-neutral dateBtn"
                        color="default"
                        data-calendar-view="basicWeek"
                        onClick={() => changeView("dayGridWeek")}
                        size="sm"
                      >
                        Week
                      </Button>
                      <Button
                        className="btn-neutral dateBtn"
                        color="default"
                        data-calendar-view="basicDay"
                        onClick={() => changeView("dayGridDay")}
                        size="sm"
                      >
                        Day
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <Container fluid>
              <Row>
                <div className="col">
                  <Card className="card-calendar">
                    <CardHeader>
                      <h5 className="h3 mb-0">Calendar</h5>
                    </CardHeader>
                    <CardBody className="p-0">
                      <div
                        className="calendar"
                        data-toggle="calendar"
                        id="calendar"
                        ref={calendarRef}
                      />
                    </CardBody>
                  </Card>
                  <Modal
                    isOpen={modalAdd}
                    toggle={() => setModalAdd(false)}
                    className="modal-dialog-centered modal-secondary"
                  >
                    <div className="modal-body">
                      <form className="new-event--form">
                        <FormGroup>
                          <label className="form-control-label">
                            Event title
                          </label>
                          <Input
                            className="form-control-alternative new-event--title"
                            placeholder="Event Title"
                            type="text"
                            onChange={(e) => setEventTitle(e.target.value)}
                          />
                        </FormGroup>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <Button
                        className="new-event--add"
                        color="primary"
                        type="button"
                        onClick={addNewEvent}
                      >
                        Add event
                      </Button>
                      <Button
                        className="ml-auto"
                        color="link"
                        type="button"
                        onClick={() => setModalAdd(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={modalChange}
                    toggle={() => setModalChange(false)}
                    className="modal-dialog-centered modal-secondary"
                  >
                    <div className="modal-body"></div>
                    <div className="modal-footer">
                      {/* <Button color="primary" onClick={changeEvent}>
                      Update
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => {
                        setModalChange(false);
                        deleteEventSweetAlert();
                      }}
                    >
                      Delete
                    </Button> */}
                      <Button
                        className="ml-auto"
                        color="link"
                        onClick={() => setModalChange(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </>

      <ToastContainer />
    </>
  );
}

export default CalendarView;
