import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const eventsService = {
    eventDetails,
    addLocation,
    addLocationDelete,
    updateLocation,
    combinedGuestpool,
    deleteEverywhere,
    deleteAdminaccount,
    deleteGuest,
    deleteGuestsingle,
};

async function deleteGuestsingle(value) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(value),
    };
    return fetch(Global.BASE_API_PATH + `/guest/deleteGuest`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteGuest(value) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(value),
    };
    return fetch(Global.BASE_API_PATH + `/guest/deleteGuest`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function deleteEverywhere(value) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(value),
    };
    return fetch(
        Global.BASE_API_PATH + `/combined-guest/delete-everywhere`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteAdminaccount(id, val) {
    let organization = { guest_id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(organization),
    };
    if (val === "Delete") {
        return fetch(
            Global.BASE_API_PATH + `/combined-guest/delete-admin-guest`,
            requestOptions
        )
            .then(handleResponse)
            .then((res) => {
                return res;
            });
    } else if (val === "ALL GUEST DATA") {
        return fetch(
            Global.BASE_API_PATH + `/combined-guest/delete-everywhere`,
            requestOptions
        )
            .then(handleResponse)
            .then((res) => {
                return res;
            });
    } else {
        return fetch(
            Global.BASE_API_PATH + `/combined-guest/remove-storyz-event`,
            requestOptions
        )
            .then(handleResponse)
            .then((res) => {
                return res;
            });
    }
}
async function combinedGuestpool(id) {
    let organization = { organization_id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(organization),
    };
    return fetch(Global.BASE_API_PATH + `/combined-guest/pool`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function addLocationDelete(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(id),
    };
    return fetch(Global.BASE_API_PATH + `/event/deleteLocation`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function eventDetails(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(id),
    };
    return fetch(Global.BASE_API_PATH + `/event/details`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function updateLocation(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: data,
    };

    return fetch(Global.BASE_API_PATH + `/event/updateLocation`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function addLocation(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: data,
    };

    return fetch(Global.BASE_API_PATH + `/event/addLocation`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
