import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import {
  FaUserAlt,
  FaSearch,
  FaPhoneAlt,
  FaPlus,
  FaChevronDown,
} from "react-icons/fa";
import GuestList from "./GuestList";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import { BsThreeDots, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { GuestEventWrapper } from "./style";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import GuestActionDropdown from "components/Dropdown/GuestActionDropdown";
import ExportGuestIcon from "./../../../assets/img/exportGuestListIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import { BiDownArrowCircle } from "react-icons/bi";

import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import LockInvertIcon from "./../../../assets/img/lock-invert-icon.svg";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import data from "../../../data.json";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import { Radio } from "react-loader-spinner";
import SelectDropdown from "components/Dropdown/SelectDropdown";
import ProgressIcon from "../../../assets/img/progressIcon.svg";
import MessageIcon from "../../../assets/img/message-icon.svg";
import YellowCircle from "../../../assets/img/yellow-circle.svg";
import GreenCircle from "../../../assets/img/green-circle.svg";
import RedCircle from "../../../assets/img/red-circle.svg";
import BlueCircle from "../../../assets/img/blue-circle.svg";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
//import { userGuestService } from "../../../userGuest.service";
import { userGuestService } from "_services/masters/userGuest.service";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import UserGuestListDropdown from "components/Dropdown/UserGuestListDropdown";
import { ToastContainer, toast } from "react-toastify";

const animatedComponents = makeAnimated();

const GuestListData = (props) => {
  const columns1 = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "DOB",
      selector: "dob",
    },
  ];

  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);

  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  //  const [exportGuestList, setExportGuestList] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [selectMenu1, setSelectMenu1] = useState(false);
  const [guestList, setguestList] = useState([]);
  //  const [getId,setGetId] = useState("")
  const [roledata, setRoledata] = useState([]);
  const [addRolepopus, setAddRolepopus] = useState(false);
  const [rolename, setRolename] = useState("");
  const [roleerror, setRoleerror] = useState(false);
  const [roleItemsid, setRoleItemsid] = useState([]);
  const [prifix, setPrifix] = useState([]);
  const [countries, setCountries] = useState([]);
  const [suffix, setSuffix] = useState([]);
  // const [guestHonorsList, setRolelist] = useState([{"id":1,"title":"Bride"},{"id":2,"title":"Featured Speaker"},{"id":4,"title":"Bride"},{"id":5,"title":"Featured Speaker"}]);
  // const [grouplist, setGrouplist] = useState([{"id":"1","title":"Family"},{"id":"2","title":"Business"},{"id":"3","title":"Friends"},{"id":"4","title":"Partners"},{"id":"5","title":"Professional"},{"id":"6","title":"Casual"},{"id":"7","title":"Social"},{"id":"8","title":"Political"},{"id":"9","title":"Religious"},{"id":"10","title":"Cultural"},{"id":"11","title":"Educational"},{"id":"12","title":"Sports"}]);
  // const [eventData, eventInvited] = useState([{"id":"1","title":"Event 1"},{"id":"2","title":"Event 2"},{"id":"3","title":"Event 3"},{"id":"4","title":"Event 3"},{"id":"5","title":"Event 4"},{"id":"6","title":"Event 5"},{"id":"7","title":"Event 6"},{"id":"8","title":"Event 7"},{"id":"9","title":"Event 8"},{"id":"10","title":"Event 8"},{"id":"11","title":"Event 8"},{"id":"12","title":"Event 8"}]);
  const [count, setCount] = useState(0);
  const [guestHonorsList, setRolelist] = useState([]);
  const [grouplist, setGrouplist] = useState([]);
  const [eventData, eventInvited] = useState([]);

  let countryArr = [];
  let grouplistArr = [];
  let arr = [];
  var l = {};
  var groupOptinArr = [];
  var eventsInvitedArr = [];
  var guestHonorArr = [];
  let surname_arr = [];
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  function getCountries() {
    userGuestService.getCountries().then(
      (res) => {
        //setCount(0);
        if (res.status === "success") {
          //let respons = res.data;
          setCountries(res.data);
          setCount(2);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function listPrifix() {
    userGuestService.listPrifix().then(
      (res) => {
        if (res.status === "success") {
          setPrifix(res.data);
          setCount(1);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function listSuffix() {
    userGuestService.listSuffix().then(
      (res) => {
        if (res.status === "success") {
          setSuffix(res.data);
          setCount(3);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function rolelist() {
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          setRolelist(res.data);
          setCount(4);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function grouplistAll() {
    userGuestService.grouplistAll().then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;
          for (let index = 0; index < respons.length; index++) {
            let obj = {
              id: respons[index].id,
              title: respons[index].group_type,
            };
            grouplistArr.push(obj);
          }
          setGrouplist(grouplistArr);
          setCount(5);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  useEffect(() => {
    listPrifix();
    getCountries();
    listSuffix();
    rolelist();
    grouplistAll();
    //setPrifix(prifix);
  }, []);

  const AddRoleModal = () => {
    setAddRolepopus(!addRolepopus);
    setRoleerror(false);
    rolelistguest();
  };

  function rolelistguest() {
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          setRoledata(res.data);
        } else {
          console.log("Something went wrong !!");
          toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const searchAPI = (Sval, type, user_id) => {
    setCount(2021);
    let obj = { s_val: Sval, type: type, user_id: user_id };
    userGuestService.searchAPI(obj).then(
      (res) => {
        if (res.status == "success" && type == "guest_event") {
          eventInvited([]);
          eventInvited(res.data);
          setTimeout(() => {
            setCount(2001);
          }, 1);
        } else if (res.status == "success" && type == "guest_group") {
          setGrouplist([]);
          setGrouplist(res.data);
          setTimeout(() => {
            setCount(2002);
          }, 1);
        } else if (res.status == "success" && type == "guest_role") {
          setRolelist([]);
          setRolelist(res.data);
          setTimeout(() => {
            setCount(2003);
          }, 1);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  function updateData(key, val, id) {
    let kayVal = {};
    kayVal["val"] = val;
    kayVal["key"] = key;
    kayVal["id"] = id;
    userGuestService.updateData(kayVal).then(
      (res) => {
        if (res.status === "success") {
          setCount(200);

          toast.success("updated successfully");
        } else {
          console.log("Something went wrong !!");
          toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const [users, setUsers] = useState({});
  useEffect(() => {
    userGuestService.userGuestList(eid).then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;
          for (let index = 0; index < respons.length; index++) {
            let social_media = respons[index].social_media;
            //console.log("social_media", social_media);
            let socialMediaArr = [];
            if (social_media != null) {
              socialMediaArr = Object.keys(social_media);
            }
            let grouparr =
              respons[index].res_group_type == 0
                ? []
                : respons[index].res_group_type;
            let eventsInvited =
              respons[index].inviteEvent == 0 ? [] : respons[index].inviteEvent;
            let guest_h_role =
              respons[index].guest_user_role.length == 0
                ? []
                : respons[index].guest_user_role;

            l = {
              id: respons[index].id,
              // actions: (
              //   <>
              //     <GuestActionDropdown
              //       guestListOption={guestListOption}
              //       OverflowClip={OverflowClip}
              //     // getIds={getIds}
              //     />
              //     <Modal
              //       isOpen={deleteGuestUser}
              //       toggle={handleDeleteGuestUser}
              //       // {...args}
              //       className="resetGeospaceModal"
              //     >
              //       <ModalBody className="pb-0">
              //         <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
              //           <img
              //             src={ResetGeospaceInfoIcon}
              //             alt=""
              //             className="geospaceInfoIcon"
              //           />
              //           <div className="head">
              //             Remove This Guest From the Guest List?
              //           </div>
              //         </div>
              //         <Row>
              //           <Col className="px-4">
              //             <div className="geospaceThumWrapper">
              //               <div className="geospaceThum">
              //                 <img
              //                   src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
              //                   alt=""
              //                   className="geospaceThumImg"
              //                 />
              //               </div>
              //               <div className="geospaceThumContent">
              //                 <div className="geothumbTitle">Guest Name</div>
              //                 <div className="geothumbSubTitle">
              //                   Role in Event
              //                 </div>
              //               </div>
              //             </div>
              //             <div className="eventThumWrapper">
              //               <div className="eventThum">
              //                 <img
              //                   src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
              //                   alt=""
              //                   className="eventThumImg"
              //                 />
              //               </div>
              //               <div className="eventThumContent">
              //                 <div className="eventthumbTitle">Event Name</div>
              //                 <div className="eventthumbSubTitle">Location</div>
              //               </div>
              //             </div>
              //           </Col>
              //         </Row>
              //         <Row className="mb-2 ">
              //           <Col className="px-4">
              //             <p>
              //               Confirm you want to remove this guest from the Event
              //               guest list.{" "}
              //             </p>
              //             <p>This will NOT remove the guest datga2.</p>
              //             <p>
              //               Instead it will just remove the guest from the invite
              //               list for this event.
              //             </p>
              //           </Col>
              //         </Row>
              //       </ModalBody>
              //       <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              //         <Button color="primary" className="flex-grow-1 pinkBtn">
              //           Remove Guest from List
              //         </Button>
              //         <Button
              //           className="btn-default cancelBtn"
              //           onClick={() => handleDeleteGuestUser(false)}
              //         >
              //           Cancel
              //         </Button>
              //       </ModalFooter>
              //     </Modal>
              //   </>
              // ),
              guest: (
                <div className="guestUserCard">
                  <div className="guestUserPic">
                    <img src={respons[index].imageUrl} className="pic" alt="" />
                  </div>
                  <div className="guestUserBody">
                    <div className="title">{respons[index].first_name}</div>
                    <div className="subTitle">Sibling of Bride</div>
                  </div>
                </div>
              ),
              status: (
                <div className="guestCircleWrapper">
                  <CircularProgressbar
                    value={percentage}
                    text={false}
                    strokeWidth={20}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      pathColor: "#53D769 ",
                      trailColor: "#1345A5",
                    })}
                  />
                </div>
              ),
              rsvp: (
                <span
                  className="RSVPchip pending"
                  onClick={handleStatusOfGuestUser}
                >
                  Pending
                </span>
              ),
              sirName: (
                <select
                  name="sirName"
                  id="sirName"
                  className="form-control height40"
                  onChange={(e) =>
                    updateData("surname", e.target.value, respons[index].id)
                  }
                >
                  <option defaultValue={respons[index].surname}>
                    {respons[index].surname}
                  </option>
                  {prifix.map((option, i) => (
                    <option key={i} defaultValue={prifix[i].prefix_name}>
                      {prifix[i].prefix_name}
                    </option>
                  ))}
                </select>
              ),
              firstLast: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  defaultValue={respons[index].first_name}
                  placeholder="First Name"
                  onBlur={(e) =>
                    updateData("first_name", e.target.value, respons[index].id)
                  }
                />
              ),
              lastName: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  defaultValue={respons[index].last_name}
                  placeholder="Last Name"
                  onBlur={(e) =>
                    updateData("last_name", e.target.value, respons[index].id)
                  }
                />
              ),
              suffix: (
                <select
                  name="sirName"
                  id="sirName"
                  className="form-control height40"
                  onChange={(e) =>
                    updateData("suffix", e.target.value, respons[index].id)
                  }
                >
                  <option defaultValue={respons[index].suffix}>
                    {respons[index].suffix}
                  </option>
                  {suffix.map((option, i) => (
                    <option key={i} defaultValue={suffix[i].suffix_name}>
                      {suffix[i].suffix_name}
                    </option>
                  ))}
                </select>
              ),
              email: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  value={respons[index].email}
                  placeholder="Email"
                  readOnly
                />
              ),
              phone: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  defaultValue={respons[index].mobile_number}
                  placeholder="Phone Number"
                  onBlur={(e) =>
                    updateData(
                      "mobile_number",
                      e.target.value,
                      respons[index].id
                    )
                  }
                />
              ),
              country: (
                <select
                  name="sirName"
                  id="sirName"
                  className="form-control height40"
                  onChange={(e) =>
                    updateData(
                      "country_code",
                      e.target.value,
                      respons[index].id
                    )
                  }
                >
                  <option defaultValue={respons[index].country_code}>
                    {respons[index].country_code}
                  </option>
                  {countries.map((option, i) => (
                    <option
                      key={i}
                      defaultValue={countries[i].country_sortname}
                    >
                      {countries[i].country_sortname}
                    </option>
                  ))}
                </select>
              ),
              greeting: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  defaultValue={respons[index].guest_personal_greeting}
                  placeholder="Type short greeting here.."
                  onBlur={(e) =>
                    updateData(
                      "guest_personal_greeting",
                      e.target.value,
                      respons[index].id
                    )
                  }
                />
              ),
              guestHonors: (
                <div className="EventdropdownOuter my-2">
                  <UserGuestListDropdown
                    placeholder="Find more event roles"
                    guestListOption={guestHonorsList}
                    selected={guest_h_role}
                    user_id={respons[index].id}
                    type="guest_role"
                    addUserBtn={false}
                    handleAddModal={AddRoleModal}
                    onChange={onClickRole}
                    searchAPI={searchAPI}
                  />
                </div>
              ),
              groups: (
                <div className="EventdropdownOuter my-2">
                  <UserGuestListDropdown
                    placeholder="Find more event roles"
                    guestListOption={grouplist}
                    selected={grouparr}
                    user_id={respons[index].id}
                    type="guest_group"
                    addUserBtn={false}
                    handleAddModal={AddRoleModal}
                    onChange={onClickRole}
                    searchAPI={searchAPI}
                  />
                </div>
              ),
              eventsInvitedTo: (
                <div className="EventdropdownOuter my-2">
                  <UserGuestListDropdown
                    placeholder="Find more event roles"
                    guestListOption={eventData}
                    selected={eventsInvited}
                    user_id={respons[index].id}
                    type="guest_event"
                    addUserBtn={false}
                    handleAddModal={AddRoleModal}
                    onChange={onClickRole}
                    searchAPI={searchAPI}
                  />
                </div>
              ),
              adminNotes: (
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  defaultValue={respons[index].admin_notes_on_guest}
                  placeholder="Admin notes here"
                  onBlur={(e) =>
                    updateData(
                      "admin_notes_on_guest",
                      e.target.value,
                      respons[index].id
                    )
                  }
                />
              ),
              contact: (
                <div>
                  <Link className="contactIcon" to="/">
                    <BsFillChatDotsFill />
                  </Link>
                  <Link className="contactIcon" to="/">
                    <AiOutlineMail />
                  </Link>
                  <Link className="contactIcon" to="/">
                    <FaPhoneAlt />
                  </Link>
                </div>
              ),
              socialMedia: (
                <div>
                  <button type="button" className="editSocialBtn">
                    Edit Social
                  </button>
                  {socialMediaArr.includes("facebook") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={FacebookIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}

                  {socialMediaArr.includes("instagram") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={InstagramIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}

                  {socialMediaArr.includes("tiktok") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={TiktokIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}

                  {socialMediaArr.includes("twitter") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={TwitterIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}

                  {socialMediaArr.includes("youtube") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={YoutubeIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}

                  {socialMediaArr.includes("vimeo") == true ? (
                    <Link to="/" className="socialIcon">
                      <img src={VimeoIcon} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              ),
            };
            arr.push(l);
          }
          setguestList(arr);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );

    rolelistguest();
  }, [count]);

  const showEntries = [{ value: "20", label: "20" }];

  const percentage = 66;

  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: "/admin/edit-user-guest-list/",
    },
    {
      img: ImgIcon,
      title: "Profile Images",
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
    },
    {
      img: LockInvertIcon,
      title: "Event Authentication",
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteGuestUser,
    },
  ];

  const filterBy = [
    {
      img: EditPencilIcon,
      title: " All Guests",
    },
    {
      img: ProgressIcon,
      title: "Incomplete Records",
    },
    {
      img: MessageIcon,
      title: "Have Messaged",
    },
    {
      img: YellowCircle,
      title: "Pending",
    },
    {
      img: GreenCircle,
      title: "Going",
    },
    {
      img: RedCircle,
      title: "Not Going",
    },
    {
      img: BlueCircle,
      title: "Attended Event",
    },
  ];

  const handleStatusOfGuestUser = () => {
    setStatusGuestUser(!statusGuestUser);
  };

  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
    }, 3000);
  };

  const OverflowClip = (value) => {
    setSelectMenu1(value);
  };

  const onClickRole = (e) => {
    setRoleItemsid(e);
  };

  const columns = [
    {
      name: "Actions",
      // selector: (row) => row.actions,
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
      selector: "Actions",
      cell: (row) => (
        <div>
          <GuestActionDropdown
            guestListOption={guestListOption}
            OverflowClip={OverflowClip}
            id={row.id}

          // getIds={getIds}
          />
          <Modal
            isOpen={deleteGuestUser}
            toggle={handleDeleteGuestUser}
            // {...args}
            className="resetGeospaceModal"
          >
            <ModalBody className="pb-0">
              <div className="resetGeoModalHeader border-bottom mb-4">
                <img
                  src={ResetGeospaceInfoIcon}
                  alt=""
                  className="geospaceInfoIcon"
                />
                <div className="head">
                  Remove This Guest From the Guest List?
                </div>
              </div>
              <Row>
                <Col className="px-4">
                  <div className="geospaceThumWrapper">
                    <div className="geospaceThum">
                      <img
                        src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                        alt=""
                        className="geospaceThumImg"
                      />
                    </div>
                    <div className="geospaceThumContent">
                      <div className="geothumbTitle">Guest Name</div>
                      <div className="geothumbSubTitle">Role in Event</div>
                    </div>
                  </div>
                  <div className="eventThumWrapper">
                    <div className="eventThum">
                      <img
                        src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                        alt=""
                        className="eventThumImg"
                      />
                    </div>
                    <div className="eventThumContent">
                      <div className="eventthumbTitle">Event Name</div>
                      <div className="eventthumbSubTitle">Location</div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col className="px-4">
                  <p>
                    Confirm you want to remove this guest from the Event guest
                    list.{" "}
                  </p>
                  <p>This will NOT remove the guest datga2.</p>
                  <p>
                    Instead it will just remove the guest from the invite list
                    for this event.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button color="primary" className="flex-grow-1 pinkBtn">
                Remove Guest from List
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={() => handleDeleteGuestUser(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ),
    },
    {
      name: (
        <div>
          <FaUserAlt className="userIcon" /> Guest
        </div>
      ),
      selector: (row) => row.guest,
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: (
        <div className="guestCircleWrapper ">
          <CircularProgressbar
            value={percentage}
            text={false}
            strokeWidth={20}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#53D769 ",
              trailColor: "#1345A5",
            })}
          />
        </div>
      ),
      selector: (row) => row.status,
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "RSVP",
      selector: (row) => row.rsvp,
    },
    {
      name: "Sirname",
      selector: (row) => row.sirName,
    },
    {
      name: "First Name",
      selector: (row) => row.firstLast,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Suffix",
      selector: (row) => row.suffix,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
    },
    {
      name: "Country Code",
      selector: (row) => row.country,
    },
    {
      name: "Greeting",
      selector: (row) => row.greeting,
    },
    {
      name: "Guest Honors",
      selector: (row) => row.guestHonors,
    },
    {
      name: "Groups",
      selector: (row) => row.groups,
    },
    {
      name: "Events Invited to",
      selector: (row) => row.eventsInvitedTo,
    },
    {
      name: "Admin Notes",
      selector: (row) => row.adminNotes,
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
    },
    {
      name: "Social Media",
      selector: (row) => row.socialMedia,
    },
  ];

  return (
    <>
      <ToastContainer />

      <div
        className={`guestDataTableWrapper ${selectMenu1 ? "overflowClip" : ""}`}
      >
        {/* <DataTable
          columns={columns}
          data={guestList}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}


        /> */}

        {/* <BootstrapTable
          bootstrap4
          keyField="id"
          data={guestList}
          columns={columns}
          pagination={paginationFactory()}
        /> */}
      </div>

      <Modal
        isOpen={statusGuestUser}
        toggle={handleStatusOfGuestUser}
        //  {...args}
        className="resetGeospaceModal statusofGuestaListModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Force the RSVP Status for This Guest?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img
                    src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                    alt=""
                    className="eventThumImg"
                  />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">Event Name</div>
                  <div className="eventthumbSubTitle">Location</div>
                </div>
              </div>
              <div className="geospaceThumWrapper">
                <div className="geospaceThum">
                  <img
                    src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                    alt=""
                    className="geospaceThumImg"
                  />
                </div>
                <div className="geospaceThumContent">
                  <div className="geothumbTitle">Guest NameXXX</div>
                  <div className="geothumbSubTitle">Event Role</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                You are manually setting the status of this Guest's RSVP. Select
                an RSVP state belolow by clicking one of the following buttons:
              </p>
              <hr className="mb-4 mt-3" />
              <div className="statusGuestUserWrapper mb-3">
                <div className="form-check pending">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    PENDING
                  </label>
                </div>
                <div className="form-check going">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    GOING
                  </label>
                </div>
                <div className="form-check notGoing">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    NOT GOING
                  </label>
                </div>
                <div className="form-check attended">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    ATTENDED
                  </label>
                </div>
              </div>
              <p>
                Are you sure you want to do this? This will erase the RSVP
                status set by the system.
              </p>
            </Col>
          </Row>

          <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={handleReseting}
            >
              Remove Guest from List
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleStatusOfGuestUser(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={resetting}
        toggle={handleReseting}
        //  {...args}
        className="resetRSVPModal"
      >
        <ModalBody className="pb-0">
          <div className="border-bottom mb-3 pb-3">
            <div className="head">Resetting RSVPs</div>
          </div>
          <Row>
            <Col className="px-4">
              <p className=" px-4 text-center">
                Changing the RSVP Status for this guest...
              </p>
              <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                <div className="circleAnimation"></div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <GuestList />
    </>
  );
};

export default GuestListData;
