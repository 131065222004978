import React from "react";

const ArrowUp = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill="#1F1F1F" />
    </svg>
  );
};

export default ArrowUp;
