import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import IconImg from "../../../assets/img/adminLogo.svg";
// import { useLocation } from "react-router-dom";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
// import { userService } from "../../../_services/login/user.service";
// import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import PropTypes from "prop-types";
import en from "react-phone-number-input/locale/en.json";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm } from "react-hook-form";
import FileStackApp from "../../../components/FileStackApp/FileStackAll";
function NewGuestCreateLoginForm() {
  const ref = useRef();
  let history = useHistory();
  const [imagSavejson, setImagSavejson] = useState({});
  const [filestack, setFilestack] = useState([]);
  const {

    register,
    handleSubmit,

    setValue,
    formState: { errors },
  } = useForm();
  //const [userInfo, setuserInfo] = React.useState();
  const [emailAddress, setemailAddress] = React.useState();
  const [country, setCountry] = React.useState('us');
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [phoneNumber1, setPhoneNumber1] = React.useState();
  useEffect(() => {
    var userAdmin = JSON.parse(sessionStorage.getItem("userAdmin"));
    setemailAddress(userAdmin.email);
    setValue("email", userAdmin.email);
  });
  const [phone, setPhone] = useState("");
  let handleOnChangephone = e => {
    setPhone(e.target.value);
  };

  let handleOnChange = value => {
    setCountry(value.country.countryCode);
    setPhone(value.phone);
    setValue("mobile_number", value.phone, { shouldTouch: true });
  };
  // let handleOnChange = (value) => {
  //   if (value && isValidPhoneNumber(value) === true) {
  //     setPhoneNumber1(value);
  //     setValue("mobile_number", value);
  //   } else {
  //     setPhoneNumber1();
  //     setValue("mobile_number", "");
  //   }
  //   // setValue('mobile_number', isValidPhone === true ? value : '')
  //   setPhoneNumber(value);
  // };
  // let handleOnChangephone = (e) => {
  //   setPhoneNumber(e.target.value);
  // };

  //var userAdmin = JSON.parse(sessionStorage.getItem("userAdmin"));

  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {labels[country]} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  const onSubmit = (data) => {
    sessionStorage.setItem("usernewAdmin", JSON.stringify(data));
    sessionStorage.setItem("imgJson", JSON.stringify(imagSavejson));
    history.push(`/term-and-conditions`);
  };

  const [imgEditDataUrl, setEditImgUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const setdata = (data) => {
    if (data.length > 0) {
      setImagSavejson(data[0]);
      setEditImgUrl(data[0].url);
      setImageName(data[0].filename);
      // console.log('filestack imgDataUrl', imgDataUrl);
    }
  };
  return (
    <>
      <Container fluid className="GuestcreateLoginHeaderWrappper">
        <Link to="/">
          <i className="ni ni-bold-left arrowIcon"></i>
        </Link>
        <img src={IconImg} alt="" />
        Set Up Your Account
      </Container>
      <div className="twofactorauth">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className="GuestcreateLoginWrappper" fluid>
            <div className="borderLine"></div>
            <Card className="GuestcreateLoginInner">
              <div className="headingWrapper borderBottom">
                <h4 className="heading-1">
                  Provide Information for Your New Admin Account
                </h4>
              </div>
              <div className="organizationPara p-4">
                You have been invited to join an OurStoryz Project as an Admin!
              </div>
              <div>
                <div className="organizationPara">
                  You have been invited become an OurStoryz Admin!
                </div>
                <div className="existingGuestLoginBox">
                  <div className="title">Storyz Name: Storyz NameXXX</div>
                  <div className="title">Your Role: Admin</div>
                </div>
              </div>
              <div className="headingWrapper borderBottom">
                <h4 className="heading-2 px-4">
                  To become an Admin, you will need to provide information to
                  log into the OurStoryz Cloud. Complete this information and
                  choose a login option.
                </h4>
              </div>

              <Row className="aboutInfoBox p-4">
                <Col lg={6} md={12}>
                  <div className="basicinformation">Basic Information</div>

                  <div className="cardBox">
                    <div className="basicInfoAvatar">
                      <div className="basicInfoAvatarImg d-flex flex-column align-items-center">
                        <img
                          src={
                            imgEditDataUrl
                              ? imgEditDataUrl
                              : require("assets/img/avatarImg.svg").default
                          }
                          className="rounded-circle"
                          style={{ width: "72px", height: "72px" }}
                          alt="Avatar"
                        />

                        <div className="accountTypeName">
                          {imageName ? imageName : "Name or Caption"}
                        </div>
                      </div>

                      <div className="basicInfoAvatarContent">
                        <div className="basicInfoMediaIcon">
                          <FileStackApp
                            setFilestack={setFilestack}
                            setdata={setdata}
                          ></FileStackApp>

                          <img
                            src={require("assets/img/cameraIcon.svg").default}
                            alt=""
                          />
                        </div>
                        {/* <div className="usernameonly">Name</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="cardBox basicInfoFormName">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="prefix">
                        Prefix (Ms., Dr.):
                      </label>
                      <div className="height48 d-flex align-items-center">
                        <select
                          id="prefix"
                          className="form-control"
                          name="surname"
                          {...register("surname")}
                        >
                          <option value="">--Select--</option>
                          <option value="Mr">Mr.</option>
                          <option value="Ms">Ms.</option>
                          <option value="Dr">Dr.</option>
                          <option value="Miss">Miss.</option>
                        </select>
                      </div>

                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        First Name:
                      </label>
                      <input
                        className="form-control"
                        name="first_name"
                        placeholder="First Name"
                        type="text"
                        {...register("first_name", {
                          required: "last name is required",
                        })}
                      />
                      {errors.first_name && (
                        <small style={{ color: "#ff0000" }}>
                          first name is required
                        </small>
                      )}
                    </FormGroup>
                  </div>
                  <div className="cardBox basicInfoFormName reverse">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example2cols1Input"
                      >
                        Last Name:
                      </label>
                      <input
                        className="form-control"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        {...register("lastName")}
                      />

                    </FormGroup>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Suffix (Esq, Phd.):
                      </label>
                      <div className="height48 d-flex align-items-center">
                        <select
                          {...register("suffix", {
                            required: "suffix is required",
                          })}
                          type="select"
                          className="form-control"
                          name="suffix"
                        >
                          <option value="">--Select--</option>
                          <option value="Esq">Esq</option>
                          <option value="PhD">PhD</option>
                        </select>
                      </div>
                      {errors.suffix && (
                        <small style={{ color: "#ff0000" }}>
                          suffix is required
                        </small>
                      )}
                    </FormGroup>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="basicinformation">Contact Information</div>
                  <div className="cardBox">
                    <FormGroup>
                      <label className="form-control-label">Mobile Number:</label>
                      <div className="d-flex align-items-center countryMobileWrpper">
                        <PhoneInput
                          name="mobile_number"
                          autoCorrect="off"
                          placeholder="Enter a Valid Phone Number"
                          country={country}
                          value={phone}
                          onChange={(phone, country) =>
                            handleOnChange({ phone, country })
                          }
                          className="flex-grow"
                        />

                        <input
                          style={{ display: "none" }}
                          placeholder="Enter a Valid Phone Number"
                          autoCorrect="off"
                          id="mobile_number"
                          name="mobile_number"
                          {...register("mobile_number", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /[0-9]+/,
                              message: "This input is number only.",
                            },
                            minLength: {
                              value: 11,
                              message: "Please input proper number.",
                            },
                          })}
                          value={phone}
                          onChange={handleOnChangephone}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-phone" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </div>
                    </FormGroup>
                    {errors.mobile_number?.message && (
                      <small style={{ color: "#ff0000" }}>
                        {errors.mobile_number?.message}
                      </small>
                    )}
                  </div>
                  <div className="cardBox">
                    <FormGroup>
                      <label className="form-control-label">Email:</label>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: emailAddress,
                        })}
                      >
                        <Input
                          placeholder="Email"
                          type="email"
                          defaultValue={emailAddress ? emailAddress : ""}
                          disabled
                        />

                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-envelope" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <div className="d-flex">
                <button className="btn pinkBtn ml-auto">Complete Setup</button>
              </div>

              {/* <div className="existingGuestLoginWrapper">
                  <div className="existingGuestLoginLeft">
                    <div className="basicinformation">
                      <img src={Vector1} alt="" />
                      Set Local Password
                    </div>

                    <div className="cardBox">
                      <Row>
                        <Col lg={10} md={12}>
                          <FormGroup className="formBox">
                            <Label htmlFor="exampleEmail">Password:</Label>
                            <InputGroup className="localPassBox">
                              <Input
                                id="exampleEmail"
                                name="text"
                                placeholder="type in password..."
                                type="text"
                              />
                              <InputGroupText>
                                <img
                                  src={
                                    require("assets/img/edit-field-icons.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </InputGroupText>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="formBox">
                            <Label htmlFor="exampleEmail">
                              Retype Password.
                            </Label>
                            <InputGroup>
                              <Input
                                id="exampleEmail"
                                name="text"
                                placeholder="type in password..."
                                type="text"
                              />
                            </InputGroup>
                          </FormGroup>
                          <div className="custom-control form-check form-check-inline formBox">
                            <label className="container3 form-check-label">
                              Remember this login
                              <input type="checkbox" name="" />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="d-flex">
                            <button
                              type="button"
                              className="greenActionBtn ml-auto"
                            >
                              Set Password
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="orBox">
                    <div className="orBoxtext">OR</div>
                  </div>
                  <div className="existingGuestLoginRight">
                    <div className="basicinformation">
                      <img
                        alt="password"
                        src={require("assets/img/socialIcon.svg").default}
                      />
                      Use a Social Login
                    </div>
                    <div className="cardBox">
                      <div className="guestLoginWrapper">
                        <Button className="loginWithApple">
                          <img
                            alt="..."
                            src={
                              require("assets/img/icons/common/Vector.svg")
                                .default
                            }
                          />
                          Login with Apple
                        </Button>
                        <Button className="loginWithFacebook">
                          <img
                            alt="..."
                            src={
                              require("assets/img/facebook-white.svg").default
                            }
                          />
                          Login with Facebook
                        </Button>
                        <Button className="loginWithGoogle">
                          <img
                            alt="..."
                            src={
                              require("assets/img/icons/googleplus.svg").default
                            }
                          />
                          Login with Google
                        </Button>
                      </div>
                    </div>
                  </div>
                </div> */}
            </Card>
            {/* <div className="GuestcreateLoginInner">
                <h1></h1>
              </div> */}
            {/* <Card>
                <CardHeader>
                  <h3
                    className="mb-0"
                    style={{
                      borderBottom: "1px solid #E7E7E7",
                      paddingBottom: "13px",
                    }}
                  >
                    Password Recovery
                  </h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <p className="basicinformation mt-2 ">
                        <img
                          src={Vector1}
                          style={{ marginRight: "11px" }}
                          alt=""
                        />
                        Password Recovery
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          New Password:
                        </label>
                        <InputGroup className={classnames("input-group-merge")}>
                          <input
                            placeholder="New Password"
                            type="password"
                            name="password"
                            className="form-control"
                            required
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <img
                                alt="password"
                                src={
                                  require("assets/img/icons/common/password.svg")
                                    .default
                                }
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mt-2 mb-2">
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Retype Password.
                        </label>
                        <input
                          id="example3cols1Input"
                          placeholder="Enter Retype Password."
                          type="password"
                          name="cpassword"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                      <Button className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow">
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
          </Container>
        </form>
      </div>
      <ToastContainer />
      <AuthFooter />
    </>
  );
}
export default NewGuestCreateLoginForm;
