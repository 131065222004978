import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "reactstrap";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import placeholder from "../../assets/img/placeholder.jpg";
import { timezoneService } from "../../_services/masters/timezone.service";

import { useForm } from "react-hook-form";
import * as actionTypes from "../../store/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useFindPathEid } from "../../CommonData";
function Repeatingevents(props) {
  const contact = useSelector((state) => {
    return state.eventSave.event;
  });
  let eid = useFindPathEid(4);
  const [eventInfo, setEventInfo] = React.useState({});
  const [notificationModal, setnotificationModal] = React.useState(false);
  const dispatch = useDispatch();
  const [timezoneData, setTimezoneData] = React.useState();

  const [startDate, setStartDate] = React.useState(new Date());
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const [gender, setGender] = useState(0);

  function onChangeValuer(event) {
    setGender(parseInt(event.target.value));
  }

  const handleChangeinput = (e) => {
    setEventInfo({ ...eventInfo, [e.target.name]: e.target.value });
  };

  const save = () => {
    const data = {
      is_repeating_event: gender,
      repeats_every: eventInfo.repeat_event,
      custom_days: eventInfo.custom,
      repeating_day: eventInfo.dayofrepeat,
      repeats_until: format(startDate, "MM-dd-yyyy h:mm a"),
      eventId: eid,
    };

    timezoneService.repeatSave(data).then(
      (res) => {
        if (res.status === "success") {
          setnotificationModal(false);
          toast.success(res.message, "Account");
        } else {
          //console.log('Something went wrong !!');
          toast.error("Something went wrong !!", "Error");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        toast.error("Something went wrong !!", "Error");
        //console.log('Something went wrong !!');
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    // const value = {
    //   ...contact,
    //   repeating_events: data
    // }
    // dispatch({ type: actionTypes.EVENT_SAVE, payload: value });
  };
  return (
    <>
      <div className="imageeventim repeatingEventBox">
        <div className="imageeventim2">Event Does not Repeat</div>
        <div
          className="imageeventim22 pointer"
          style={{ marginLeft: "25px" }}
          onClick={() => setnotificationModal(true)}
        >
          <span>Change</span>
        </div>

        <Modal
          // className="modal-dialog-centered modal-danger deleteaccountowner maxWidth450"
          className="modal-dialog-centered maxWidth450 repeatEventModalWrapper"
          isOpen={notificationModal}
          toggle={() => setnotificationModal(false)}
        >
          <div className="modal-body">
            <h4 className="ChangeTimezone repeatEventWrapper">
              <img
                alt="Organization Name"
                src={require("assets/img/circlediconevents.svg").default}
                className="imagevent"
              />
              Set Up Repeating Event
            </h4>

            <div className="repeateventDetails">
              <div className="repeateeventDate mr-2">
                {props.date ? moment(props.date).format("MMMM D") : "No Date"}
              </div>
              <div>
                <img
                  className="evimages ml-0"
                  src={props.img ? props.img : placeholder}
                />
              </div>
              <div className="repeateventTitle ml-2">
                <div>{props.name}</div>
                <div className="repeateventlocation">
                  {props.location ? props.location : "Location"}
                </div>
              </div>
            </div>

            <div
              className="repeatingEvent justify-content-center"
              onChange={onChangeValuer}
            >
              <div className="form-check pl-0">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register2("repeating_event")}
                  value={0}
                  name="repeating_event"
                  checked={gender === 0}
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Does Not Repeat
                </label>
              </div>
              <div className="form-check repeatingtypp">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register2("repeating_event")}
                  value={1}
                  name="repeating_event"
                  checked={gender === 1}
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  Repeating
                </label>
              </div>
            </div>

            <p className="repeatingEventText">
              Set up a repeating event below.
            </p>

            <div
              className="repeateventformwrapper"
              style={
                gender === 0 ? { pointerEvents: "none", opacity: "0.4" } : {}
              }
            >
              <div className="containerBox formBox pt-0">
                <Row>
                  <Col md={8}>
                    <div className="form-group mb-0">
                      <label for="sel1" className="form-label">
                        Repeats Every:
                      </label>
                      <select
                        className="form-control my-1"
                        id="sel1"
                        name="repeat_event"
                        {...register2("repeat_event")}
                        onChange={handleChangeinput}
                        required
                      >
                        <option value="">--- select ---</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-0">
                      <label for="sel1" className="form-label">
                        Custom:
                      </label>
                      <span className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Custom"
                          name="custom"
                          {...register2("custom")}
                          onChange={handleChangeinput}
                          required
                        />
                        <span className="form-label mb-0 ml-2">Days</span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="containerBox formBox pt-0">
                <label for="sel1" className="form-label">
                  Day of Repeat:
                </label>
                <select
                  className="form-control"
                  id="sel1"
                  name="dayofrepeat"
                  {...register2("dayofrepeat")}
                  onChange={handleChangeinput}
                  required
                >
                  <option value="">--- Select day of week ---</option>
                  <option value="sunday">Sunday</option>
                  <option value="monday">Monday</option>
                  <option value="tuesday">Tuesday</option>
                  <option value="wednesday">Wednesday</option>
                  <option value="thursday">Thursday</option>
                  <option value="friday">Friday</option>
                  <option value="saturday">Saturday</option>
                </select>
              </div>
              <div className="containerBox formBox pt-0">
                <label htmlFor="date" className="form-label">
                  Until:
                </label>
                <div className="dateTimePickerGlobal">
                  <DatePicker
                    className={"form-control"}
                    selected={startDate}
                    onChange={(val) => {
                      setStartDate(val);
                      // setValue("event_end_date", val);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Time"
                    placeholderText="Date"
                    dateFormat="MM-dd-yyyy h:mm a"
                    minDate={new Date()}
                    onFocus={(e) => e.target.blur()}
                  />
                </div>
                {/* <input
                  className="form-control"
                  type="date"
                  id="start"
                  name="until"
                  placeholder="Select Date..."
                  {...register2("until")}
                  onChange={handleChangeinput}
                  required
                /> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="pinkBtn flex-grow-1"
              color="default"
              type="submit"
              style={
                gender === 0
                  ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                  : {
                    opacity: "1",
                  }
              }
              onClick={save}
            >
              Save
            </Button>
            <Button
              className="btn-white cancelBtn"
              color="default"
              type="button"
              onClick={() => setnotificationModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Repeatingevents;
