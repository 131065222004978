import { StoryzThemeModalCss } from "./storyzThemeCss";
import React, { useState } from "react";
import Select from "react-select";
import { GrClose } from "react-icons/gr";

import storyzThemeLogo from "./../../assets/img/storyzThemeLogo.svg";
import setBg from "./../../assets/img/setBg.svg";
import ModalThemeBg from "./../../assets/img/modalThemeBg.png";

const InviteThemeModal = ({ openThemeModal, handleThemeClose }) => {
  const [selectFont, setSelectFont] = useState(null);
  const [selectStyle, setSelectStyle] = useState(null);
  const [selectFontSize, setSelectFontSize] = useState(null);
  const [changeBg, SetChangeBg] = useState(null);

  const [chnageColor, setColorChange] = useState({
    screenTxt: "#333333",
    displayTxt: "#ffffff",
    adminTxt: "#333333",
    emphasisTxt: "#ffffff",
    screenBg: "#97a391",
    locationBg: "#778a6e",
    AdminBg: "#cfd8c0",
    EmphasisBg: "778a6e",
  });

  const colorChnage = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setColorChange({ ...chnageColor, [name]: value });
  };

  const handleChangeImg = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      SetChangeBg(urldata);

    }
  };

  const fontList = [{ value: "greatvibes", label: "Great Vibes" }];
  const fontStyle = [
    { value: "normal", label: "Normal" },
    { value: "semibold", label: "Semi Bold" },
    { value: "bold", label: "Bold" },
  ];
  const fontSize = [
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "22", label: "22" },
  ];
  return (
    <>
      <StoryzThemeModalCss
        className={`modal fade storyzThemeModal ${openThemeModal ? "show" : ""
          }`}
        id="storyzThemeModal"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">
                <img src={storyzThemeLogo} className="storyzThemeLogo" alt="" />
                Invite Theme
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={handleThemeClose}
              >
                <GrClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="textBox">
                <div className="h4">Theme: Japanese Wave</div>
                <div className="h4">Component: Event</div>
                <div className="d-flex align-items-center my-4">
                  <button className="changeBtn">Change Theme</button>
                  <div className="addGuest d-flex flex-column align-items-center ml-auto">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/recycle.svg").default}
                      width={20}
                      height={20}
                    />
                    <div className="whiteSpace grayColor1 fontWeight300">
                      Reset Styles
                    </div>
                  </div>
                  <img
                    src={ModalThemeBg}
                    alt=""
                    width={80}
                    height={53}
                    className="border-radius12 ml-4"
                  />
                </div>
                <p>
                  Set an overall theme for your Storyz. Your theme provides a
                  default for your events, invites, and website listings. You
                  can apply a separate theme to individual events or invites.
                </p>
              </div>
              <div className="textBox">
                <div className="h5 fontWeight400">Display Type:</div>
                <div className="displayTypeWrapper">
                  <div className="mb-3 mx-0 row p-0">
                    <label className="col col-form-label p-0 label">
                      Font:
                    </label>
                    <div className="col px-0">
                      <Select
                        defaultValue={selectFont}
                        onChange={setSelectFont}
                        options={fontList}
                        className="displayDropdown"
                      />
                    </div>
                  </div>
                  <div className="mb-3 mx-0 row p-0">
                    <div className="col mx-0 row px-0">
                      <label className="col col-form-label p-0 label">
                        Style:
                      </label>
                      <div className="col px-0">
                        <Select
                          defaultValue={selectStyle}
                          onChange={setSelectStyle}
                          options={fontStyle}
                          className="displayDropdown"
                        />
                      </div>
                    </div>
                    <div className="col mx-0 row px-0">
                      <label className="col col-form-label p-0 label pl-2">
                        Size:
                      </label>
                      <div className="col px-0">
                        <Select
                          defaultValue={selectFontSize}
                          onChange={setSelectFontSize}
                          options={fontSize}
                          className="displayDropdown"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="h5">
                  Screen Palette (used in CMS and native app):
                </h5>
                <div className="palatteWrapper">
                  <div className="pallateList">
                    <div className="palatteTxt">Screen txt</div>
                    <div
                      className="colorPallate screenTxt brdrTxt pointer"
                      style={{ background: `${chnageColor["screenTxt"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="screenTxt"
                        value={chnageColor["screenTxt"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">{chnageColor["screenTxt"]}</div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Display txt</div>
                    <div
                      className="colorPallate displayTxt brdrTxt pointer"
                      style={{ background: `${chnageColor["displayTxt"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="displayTxt"
                        value={chnageColor["displayTxt"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">
                      {chnageColor["displayTxt"]}
                    </div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Admin txt</div>
                    <div
                      className="colorPallate adminTxt brdrTxt pointer"
                      style={{ background: `${chnageColor["adminTxt"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="adminTxt"
                        value={chnageColor["adminTxt"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">{chnageColor["adminTxt"]}</div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Emphasis txt</div>
                    <div
                      className="colorPallate emphasisTxt brdrTxt pointer"
                      style={{ background: `${chnageColor["emphasisTxt"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="emphasisTxt"
                        value={chnageColor["emphasisTxt"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">
                      {chnageColor["emphasisTxt"]}
                    </div>
                  </div>
                </div>
                <div className="palatteWrapper">
                  <div className="pallateList">
                    <div className="palatteTxt">Screen bg</div>
                    <div
                      className="colorPallate screenBg brdrBg pointer"
                      style={{ background: `${chnageColor["screenBg"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="screenBg"
                        value={chnageColor["screenBg"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">{chnageColor["screenBg"]}</div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Location bg</div>
                    <div
                      className="colorPallate locationBg brdrBg pointer"
                      style={{ background: `${chnageColor["locationBg"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="locationBg"
                        value={chnageColor["locationBg"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">
                      {chnageColor["locationBg"]}
                    </div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Admin bg</div>
                    <div
                      className="colorPallate adminBg brdrBg pointer"
                      style={{ background: `${chnageColor["AdminBg"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="AdminBg"
                        value={chnageColor["AdminBg"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">{chnageColor["AdminBg"]}</div>
                  </div>
                  <div className="pallateList">
                    <div className="palatteTxt">Emphasis bg</div>
                    <div
                      className="colorPallate emphasisBg brdrBg pointer"
                      style={{ background: `${chnageColor["EmphasisBg"]}` }}
                    >
                      <input
                        type="color"
                        className="colorInput pointer"
                        name="EmphasisBg"
                        value={chnageColor["EmphasisBg"]}
                        onChange={colorChnage}
                      ></input>
                    </div>
                    <div className="palatteTxt">
                      {chnageColor["EmphasisBg"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="textBox pb-3">
                <h6 className="h6">Background Image (overlays screen bg)</h6>
                <div className="backgroundImgWrapper">
                  <div className="setImgWrapper">
                    <div className="bgPalatte">
                      <img
                        src={changeBg ? changeBg : setBg}
                        alt=""
                        className="bgImg"
                      />
                    </div>
                    <div className="setImgChip">
                      Set Image
                      <input
                        className="fileUplaod"
                        type="file"
                        name="files"
                        accept="image/*"
                        onChange={handleChangeImg}
                      />
                    </div>
                    {changeBg && (
                      <button
                        className="closeChip"
                        onClick={() => SetChangeBg("")}
                      >
                        <GrClose />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pb-2 pt-4 px-0">
              <button type="button" className="btn saveChangesBtn">
                Save Changes
              </button>
              <button
                type="button"
                className="btn cancelBtn"
                onClick={handleThemeClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </StoryzThemeModalCss>
    </>
  );
};
export default InviteThemeModal;
