import React, { useEffect, useState } from "react";
import { Form, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import { ReactSession } from "react-client-session";
import Imggroup from "../../assets/img/dummyImg.svg";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import ReactTooltip from "react-tooltip";
import HeaderSection from "../../components/Headers/HeaderSection";
import { informationService } from "../../_services/masters/information.service";
import Allheader from "../../components/Headers/Allheader";
import {
  InputField,
  CheckboxField,
  TextareaField,
} from "../../components/Inputbox";
import Selectbox from "../../components/Inputbox/selectbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Events from "../../components/Events";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import KeepsakeAlbumCoverImg from "./../../assets/img/cover_img.jpg";
import { FaChevronRight } from "react-icons/fa";
import * as actionTypes from "../../store/actionTypes";
import { FiArrowLeft } from "react-icons/fi";
import GlobalButton from "components/Button/GlobalButton";
import { useDispatch, useSelector } from "react-redux";
function StoryzAbout({ ...args }) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [paramsId, setParamsId] = React.useState(params.sid ? params.sid : "");
  const [imgData, setImgData] = React.useState(null);
  const [storyData, setstoryData] = React.useState({});
  const [imgDa, setImgDa] = React.useState(null);
  const [ourStoryzchke, setOurstoryzchke] = React.useState(
    storyData?.is_our_storyz ? storyData?.is_our_storyz : ""
  );
  const [attirechke, setAttirechke] = React.useState(
    storyData?.is_attire ? storyData?.is_attire : ""
  );
  const [additionalchke, setAdditionalchke] = React.useState(
    storyData?.is_additional_things ? storyData?.is_additional_things : ""
  );
  const [howtochke, setHowtochke] = React.useState(
    storyData?.is_how_to_get ? storyData?.is_how_to_get : ""
  );
  const [questions, setQuestions] = React.useState(
    storyData?.is_que_and_ans ? storyData?.is_que_and_ans : ""
  );
  const [selectboxS, setSelectboxS] = React.useState();
  const [val, setcheckVal] = React.useState(0);
  const [previewModal, setPreviewModal] = useState(false);
  const [item, setItem] = useState();

  const [userInfo, setUserInfo] = React.useState({});

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  const [allimage, setAllimage] = React.useState([]);
  const [modalShow21, setModalShow21] = React.useState(false);

  const handleClick12 = () => {
    setModalShow21(false);
  };

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = {
      type: "storyz",
      storyz_id: params.sid,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateprojectUpdateimage(data).then(
      (res) => {
        if (res.status === "success") {
          storyzDetail();
          toast.success(res.message, "Account");
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
          storyFunctiom();
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };
  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);
    let storyz_id = params.sid;
    let jsonData = e;
    jsonData["storyz_id"] = storyz_id;
    jsonData["imaDatajson"] = obj;

    informationService.updateprojectUpdateimage(jsonData).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          storyzDetail();
          setModalShow21(false);
          storyFunctiom();
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});

  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    if (e.target.files[0]) {
      setImgData(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangeinput = (e) => {
    // set userInfo state
    userInfo[e.targe.name] = e.targe.value;
    setUserInfo({ ...userInfo });

    // clear the userName error
    // errors["username"] = {};
    // setErrors{...errors };
  };

  console.log(storyData);
  const onSubmit = (data) => {

    var formdata = new FormData();
    formdata.append("storyz_name", data.project_name);
    formdata.append("hosted_by", data.hosted_by);
    formdata.append("project_image", imgDa);
    formdata.append("storyz_type", selectboxS.value);

    formdata.append("greeting_title", data.title_greeting);
    formdata.append("greeting_description", data.greeting);
    formdata.append("send_greeting", data.checkboxcontrol);

    formdata.append("is_our_storyz", data.usethisfieldstoryz);
    formdata.append("our_storyz_title", data.information);
    formdata.append("our_storyz_description", data.our_storyz_description);

    formdata.append("is_attire", data.usethisfieldattire);
    formdata.append("attire_title", data.attire);
    formdata.append("attire_description", data.attire_description);

    formdata.append("is_additional_things", data.usethisfieldadditional);
    formdata.append("additional_things_title", data.additional);
    formdata.append(
      "additional_things_description",
      data.additional_things_description
    );

    formdata.append("is_how_to_get", data.usethisfieldget);
    formdata.append("how_to_get_title", data.titlearrangements);
    formdata.append("how_to_get_description", data.how_to_get_description);

    formdata.append("is_que_and_ans", data.usethisfieldquestions);
    formdata.append("que_and_ans_title", data.titlequ);
    formdata.append("que_and_ans_description", data.que_and_ans_description);

    formdata.append("organization_id", data.org_id);
    formdata.append("id", data.id);

    // console.log(formdata);
    // for (var key of formdata.entries()) {
    //     console.log(key[0] + ', ' + key[1]);
    // }
    informationService.storyzUpdate(formdata).then(
      (res) => {
        if (res.status === "success") {
          history.push(`/admin/storyz-about/${data.id}`);

          storyFunctiom();
          toast.success(res.message, "Account");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };


  function storyFunctiom() {
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }


  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  function storyzDetail() {
    const data = {
      story_id: paramsId,
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setstoryData(res.data[0]);

          setValue(
            "project_name",
            res.data[0].storyz_name === null ||
              res.data[0].storyz_name === "null"
              ? ""
              : res.data[0].storyz_name,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "hosted_by",
            res.data[0].hosted_by === null || res.data[0].hosted_by === "null"
              ? ""
              : res.data[0].hosted_by,
            { shouldTouch: true }
          );

          setValue(
            "title_greeting",
            res.data[0].greeting_title === null ||
              res.data[0].greeting_title === "null"
              ? ""
              : res.data[0].greeting_title,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "greeting",
            res.data[0].greeting_description === null ||
              res.data[0].greeting_description === "null"
              ? ""
              : res.data[0].greeting_description,
            {
              shouldTouch: true,
            }
          );

          setcheckVal(res.data[0].send_greeting);
          setValue("checkboxcontrol", res.data[0].send_greeting, {
            shouldTouch: true,
          });

          setOurstoryzchke(res.data[0].is_our_storyz);
          setValue("usethisfieldstoryz", res.data[0].is_our_storyz, {
            shouldTouch: true,
          });
          setValue(
            "information",
            res.data[0].our_storyz_title === null ||
              res.data[0].our_storyz_title === "null"
              ? ""
              : res.data[0].our_storyz_title,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "our_storyz_description",
            res.data[0].our_storyz_description === null ||
              res.data[0].our_storyz_description === "null"
              ? ""
              : res.data[0].our_storyz_description,
            { shouldTouch: true }
          );

          setAttirechke(res.data[0].is_attire);
          setValue("usethisfieldattire", res.data[0].is_attire, {
            shouldTouch: true,
          });
          setValue(
            "attire",
            res.data[0].attire_title === null ||
              res.data[0].attire_title === "null"
              ? " "
              : res.data[0].attire_title,
            { shouldTouch: true }
          );
          setValue(
            "attire_description",
            res.data[0].attire_description === null ||
              res.data[0].attire_description === "null"
              ? ""
              : res.data[0].attire_description,
            {
              shouldTouch: true,
            }
          );

          setAdditionalchke(res.data[0].is_additional_things);
          setValue("usethisfieldadditional", res.data[0].is_additional_things, {
            shouldTouch: true,
          });

          setValue(
            "additional",
            res.data[0].additional_things_title === null ||
              res.data[0].additional_things_title === "null"
              ? ""
              : res.data[0].additional_things_title,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "additional_things_description",
            res.data[0].additional_things_description === null ||
              res.data[0].additional_things_description === "null"
              ? ""
              : res.data[0].additional_things_description,
            { shouldTouch: true }
          );

          setHowtochke(res.data[0].is_how_to_get);
          setValue("usethisfieldget", res.data[0].is_how_to_get, {
            shouldTouch: true,
          });
          setValue(
            "titlearrangements",
            res.data[0].how_to_get_title === null ||
              res.data[0].how_to_get_title === "null"
              ? ""
              : res.data[0].how_to_get_title,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "how_to_get_description",
            res.data[0].how_to_get_description === null ||
              res.data[0].how_to_get_description === "null"
              ? ""
              : res.data[0].how_to_get_description,
            { shouldTouch: true }
          );

          setQuestions(res.data[0].is_que_and_ans);
          setValue(
            "usethisfieldquestions",
            res.data[0].is_que_and_ans === null ||
              res.data[0].is_que_and_ans === "null"
              ? ""
              : res.data[0].is_que_and_ans,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "titlequ",
            res.data[0].que_and_ans_title === null ||
              res.data[0].que_and_ans_title === "null"
              ? ""
              : res.data[0].que_and_ans_title,
            {
              shouldTouch: true,
            }
          );
          setValue(
            "que_and_ans_description",
            res.data[0].que_and_ans_description === null ||
              res.data[0].que_and_ans_description === "null"
              ? " "
              : res.data[0].que_and_ans_description,
            { shouldTouch: true }
          );

          setValue("id", res.data[0].proId, { shouldTouch: true });
          setValue("org_id", res.data[0].org_id, { shouldTouch: true });

          setTimeout(() => {
            setSelectboxS({
              value: res.data[0].storyz_type_id,
              id: res.data[0].storyz_type_id,
              label: res.data[0].storyz_type,
            });
          }, 500);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    storyzDetail();
  }, [paramsId]);


  const PreviweModal = () => {
    setPreviewModal(!previewModal);
  };
  const handleClick = (e) => {
    // console.log(e.target.getAttribute("data-id"));
    setItem(e.target.getAttribute("data-id"));
  };

  // useEffect(()=>{
  //   window.location.hash = "#aeroplane";
  // },[])

  useEffect(() => {
    setTimeout(() => {
      const currentUrl = window.location.href;
      const urlArr = currentUrl.split("/");
      let hashTag = urlArr[urlArr.length - 1];

      const sectionToScroll = document.querySelector(hashTag);
      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({ behavior: "smooth", top: "0" });
      }
    }, 1000);
  }, []);

  return (
    <>
      {storyData ? (
        <>
          <Allheader
            id={storyData?.proId}
            title={
              storyData?.storyz_name
                ? storyData?.storyz_name
                : "New Storyz edit"
            }
            imgScr={
              storyData?.profile_photo_Url
                ? storyData?.profile_photo_Url
                : Imggroup
            }
            arw
          />
          <div className="storyabout storyzWrapperMain bgPannel">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="card storyzCardWrapper">
                <HeaderSection
                  title="About"
                  tooltip="This page shows your current billing level, payment method, and billing history."
                  setdata="This page lets youprovidie additional information to your audience about your overall Storyz. You can provide additional information for each event by navigating to the event page."
                />
                <div className="py-4 d-flex">
                  <div className="ml-auto">
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/pulldownitemtextwebapp.svg").default
                      }
                      className=" pointer"
                      onClick={PreviweModal}
                    />
                  </div>
                </div>

                <div className="pb-4">
                  {/* <Button className="saveChangesBtn ml-auto">
                      <img
                        src={require("assets/img/hornIcon.svg").default}
                        alt=""
                      />{" "}
                      Save Changes
                    </Button> */}
                  <GlobalButton
                    customClass="ml-auto"
                    img={require("assets/img/hornIcon.svg").default}
                    type="submit"
                  >
                    Save Changes
                  </GlobalButton>
                </div>
                <Modal
                  isOpen={previewModal}
                  toggle={PreviweModal}
                  {...args}
                  className="keepsakeAlbumModal"
                >
                  <ModalHeader toggle={() => PreviweModal(false)}></ModalHeader>
                  <ModalBody>
                    {!item && (
                      <>
                        <div className="coverImgWrapper">
                          <span className="title">Keepsake Album</span>
                          <img
                            src={KeepsakeAlbumCoverImg}
                            alt=""
                            className="coverImg"
                          />
                        </div>
                        <div className="keepsakeAlbumContent">
                          This is an introduction to the Keepsake album, created
                          by the Admins in the CMS. It can be viewed during and
                          after the event.
                        </div>
                        <div className="keepsakeAlbumAction">
                          <div className="actionWrapper">
                            <button
                              className="keepsakeAlbumBtn active"
                              onClick={handleClick}
                            >
                              Date
                            </button>
                            <button
                              className="keepsakeAlbumBtn"
                              onClick={handleClick}
                              data-id="1"
                            >
                              Guest
                            </button>
                          </div>
                        </div>
                        <div className="keepsakeAlbumMediaWrapper">
                          {[...Array(3)].map((x, i) => (
                            <div key={i}>
                              <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                                <div className="date">August 15, 2022</div>
                                <FaChevronRight size={24} className="icon" />
                              </div>
                              <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                                {[...Array(10)].map((x, i) => (
                                  <div className="sliderList" key={i}>
                                    <div className="slideListOverlay align-items-center justify-content-center">
                                      See more
                                    </div>
                                    <img
                                      src={KeepsakeAlbumCoverImg}
                                      alt=""
                                      className="sliderImg"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {item && (
                      <>
                        <div className="coverImgDetails">
                          <button onClick={handleClick} className="backArrow">
                            <FiArrowLeft className="" />
                          </button>

                          <span className="title">Keepsake Album</span>
                        </div>
                        <div className="keepsakeAlbumContent">
                          This is an introduction to the Keepsake album, created
                          by the Admins in the CMS. It can be viewed during and
                          after the event.
                        </div>
                        <div className="keepsakeAlbumAction">
                          <div className="actionWrapper">
                            <button
                              className="keepsakeAlbumBtn "
                              onClick={handleClick}
                            >
                              Date
                            </button>
                            <button
                              className="keepsakeAlbumBtn active"
                              onClick={handleClick}
                              data-id="1"
                            >
                              Guest
                            </button>
                          </div>
                        </div>
                        <div className="keepsakeAlbumMediaWrapper">
                          {[...Array(3)].map((x, i) => (
                            <div key={i}>
                              <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                                <div className="keepsakeUser1">
                                  <div className="keepsakeUser1Thum">
                                    <img
                                      src="https://images.unsplash.com/photo-1621621667797-e06afc217fb0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="keepsakeUser1ThumImg"
                                    />
                                  </div>
                                  <div className="keepsakeUser1ThumContent">
                                    <div className="keepsakeUser1thumbTitle">
                                      Kevin Whitfield
                                    </div>
                                    <div className="keepsakeUser1thumbSubTitle">
                                      Guest
                                    </div>
                                  </div>
                                </div>
                                <FaChevronRight size={24} className="icon" />
                              </div>
                              <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                                {[...Array(10)].map((x, i) => (
                                  <div className="sliderList" key={i}>
                                    <div className="slideListOverlay align-items-center justify-content-center">
                                      See more
                                    </div>
                                    <img
                                      src={KeepsakeAlbumCoverImg}
                                      alt=""
                                      className="sliderImg"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ModalBody>
                </Modal>

                <div className="basicinformation">
                  <img
                    src={require("assets/img/aour-storyz.svg").default}
                    alt=""
                  />
                  Our Storyz
                </div>

                {/* <Row> */}
                <div className="cardBox">
                  <div className="d-flex aboutMediaWrapper">
                    <div className="form-group aboutMediaLeft">
                      <div className="coverimagest">
                        <div className="coverimage1 d-flex align-items-center">
                          Cover Image :
                        </div>
                        <div>
                          {/* <div
                            className="upload-btn-wrapper"
                            onClick={() => {
                              setModalShow21(true);
                            }}
                          > */}
                          <img
                            src={require("assets/img/update.svg").default}
                            className="pointer"
                            alt=""
                            onClick={() => {
                              setModalShow21(true);
                            }}
                          />

                          {/* <Input id="profilePic" type="file" /> */}
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="">
                        <div
                          className="imgMask1 uploadCircleWrapper"
                          onClick={() => {
                            setModalShow21(true);
                          }}
                        >
                          {imgData || storyData?.profile_photo_Url ? (
                            <img
                              className="storytoimage346"
                              id="blah2"
                              src={
                                imgData
                                  ? imgData
                                  : storyData?.profile_photo_Url
                                    ? storyData?.profile_photo_Url
                                    : ""
                              }
                              alt="."
                            />
                          ) : (
                            ""
                          )}
                          <div className="imgMask-content">+</div>
                          {/* <input
                            type="file"
                            className="imgg1"
                            id="imgInp"
                            onChange={fileHandler}
                          /> */}
                        </div>

                        {/* <img className='storytoimage' alt="Organization Name" src="/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg" /> */}
                      </div>
                    </div>
                    {modalShow21 && (
                      <Filemanager
                        show={modalShow21}
                        onHide={handleClick12}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        seletedImage={seletedImageClike}
                      />
                    )}
                    <div className="flex-grow-1 m12-left aboutMediaRight">
                      <InputField
                        type="hidden"
                        placeholder=""
                        label=""
                        name="id"
                        formGroup={false}
                        onChange={handleChangeinput}
                        {...register("id")}
                      />
                      <InputField
                        type="hidden"
                        placeholder=""
                        label=""
                        name="org_id"
                        formGroup={false}
                        onChange={handleChangeinput}
                        {...register("org_id")}
                      />
                      <div className="formBox">
                        <InputField
                          type="text"
                          placeholder="Storyz Name Goes Here"
                          label="Storyz Name:"
                          name="project_name"
                          onChange={handleChangeinput}
                          {...register("project_name", { required: true })}
                        />
                        {errors.project_name && (
                          <span style={{ color: "red" }}>
                            This field is required
                          </span>
                        )}
                      </div>
                      <p className="information">
                        Information Below is Defined by Editing Your Events:
                      </p>
                      <p className="information">
                        <b>Dates: </b>
                        {storyData?.event_start_date
                          ? moment(storyData?.event_start_date).format("MMM Do")
                          : "No Date"}{" "}
                        -
                        {storyData?.event_end_date
                          ? moment(storyData?.event_end_date).format(
                            "MMM Do YYYY"
                          )
                          : "No Date"}
                      </p>
                      <p className="information">
                        <b>Location:</b> No Data
                      </p>
                    </div>
                  </div>
                </div>
                {/* </Row> */}
                <Row className="mt-4">
                  <Col md="12" lg={6}>
                    {selectboxS ? (
                      <>
                        {/* <div className="formBox"> */}
                        <Selectbox
                          onChangeSelect={setSelectboxS}
                          selectdata={selectboxS}
                          id={6}
                        />
                        {/* </div> */}
                      </>
                    ) : (
                      ""
                    )}

                    <div className="mt-3 mb-3"></div>
                    <div className="formBox">
                      <InputField
                        type="text"
                        placeholder="Hosted by"
                        label="Hosted by:"
                        name="hosted_by"
                        defaultValue={storyData?.hosted_by}
                        onChange={handleChangeinput}
                        {...register("hosted_by", { required: true })}
                      />
                      {errors.hosted_by && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="formBox">
                      <InputField
                        type="text"
                        placeholder="Welcome!"
                        label="Title for Greeting:"
                        name="title_greeting"
                        defaultValue={storyData?.greeting_title}
                        onChange={handleChangeinput}
                        {...register("title_greeting", { required: true })}
                      />
                      {errors.greeting && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="formBox">
                      <InputField
                        type="text"
                        placeholder="Enter a short greeting to your guests....."
                        label="Greeting:"
                        name="greeting"
                        defaultValue={storyData?.greeting_description}
                        onChange={handleChangeinput}
                        {...register("greeting", { required: true })}
                      />
                      {errors.greeting && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="basicinformation">
                      <img
                        src={require("assets/img/greetingicon.svg").default}
                        alt=""
                      />
                      Let Guests Send You a Greeting?
                    </div>
                    <div className="cardBox">
                      <p>
                        Leave this checked to show a “send greeting” option to
                        guests. Their greeting will go to all the Honored Guests
                        specified in the event.
                      </p>

                      <div className="ml-n3">
                        <CheckboxField
                          label="Checkbox control"
                          {...register("checkboxcontrol")}
                          name="checkboxcontrol"
                          onChange={(e) => {
                            setValue(
                              "checkboxcontrol",
                              e.target.checked ? "1" : "0"
                            );
                            setcheckVal(!val);
                          }}
                          check={val}
                          className="pl-3"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="12" lg={6}>
                    <div className="storyAboutEventWrapper">
                      <Events />
                    </div>
                  </Col>
                  <Col md="12" className="mt-5">
                    <div className="emenu">
                      <div className="emenu1">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/sound.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                        <h3 className="mb-0 fontSize20 JakartaMedium300">
                          Optional Fields
                        </h3>
                      </div>
                      <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/tip.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                      </span>
                      <ReactTooltip />
                    </div>
                    <hr style={{ marginTop: "1rem", marginBottom: "1em" }} />
                  </Col>
                  <Col md="12">
                    <section id="ourStoryz">
                      <div className="basicinformation">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/aour-storyz.svg").default}
                          className="icomn"
                        />
                        Our Storyz
                      </div>
                      <div className="cardBox">
                        <div className="d-flex align-items-center justify-content-end">
                          <CheckboxField
                            label="Use this Field"
                            name="usethisfieldstoryz"
                            {...register("usethisfieldstoryz")}
                            onChange={(e) => {
                              setValue(
                                "usethisfieldstoryz",
                                e.target.checked ? "1" : "0"
                              );
                              setOurstoryzchke(!ourStoryzchke);
                            }}
                            check={ourStoryzchke}
                          />
                        </div>
                      </div>
                    </section>
                  </Col>
                  {ourStoryzchke ? (
                    <>
                      <Col md="12">
                        <div className="formBox">
                          <InputField
                            type="text"
                            placeholder="Information About This Event....."
                            label="Title (used to link to Storyz page in the Guest App):"
                            name="information"
                            defaultValue={storyData?.our_storyz_title}
                            onChange={handleChangeinput}
                            {...register("information")}
                          />
                        </div>
                        <div className="formBox">
                          <TextareaField
                            name="our_storyz_description"
                            defaultValue={storyData?.our_storyz_description}
                            onChange={handleChangeinput}
                            {...register("our_storyz_description")}
                            row="3"
                            placeholder="This storyz rox. It will be the greatest storyz ever told."
                            label="Description:"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md="12">
                    <section id="attire">
                      <div className="basicinformation">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/editfieldiconsot.svg").default
                          }
                          className="icomn"
                        />
                        Attire
                      </div>
                      <div className="cardBox">
                        <div className="d-flex align-items-center justify-content-end">
                          <CheckboxField
                            label="Use this Field"
                            name="usethisfieldattire"
                            {...register("usethisfieldattire")}
                            onChange={(e) => {
                              setValue(
                                "usethisfieldattire",
                                e.target.checked ? "1" : "0"
                              );
                              setAttirechke(!attirechke);
                            }}
                            check={attirechke}
                          />
                        </div>
                      </div>
                    </section>
                  </Col>

                  {attirechke ? (
                    <>
                      <Col md="12">
                        <div className="formBox">
                          <InputField
                            type="text"
                            placeholder="Attire....."
                            label="Title:"
                            name="attire"
                            defaultValue={storyData?.attire_title}
                            onChange={handleChangeinput}
                            {...register("attire")}
                          />
                        </div>
                        <div className="formBox">
                          <TextareaField
                            name="attire_description"
                            defaultValue={storyData?.attire_description}
                            onChange={handleChangeinput}
                            {...register("attire_description")}
                            row="3"
                            placeholder="A description of dress codes and clothing, if needed):"
                            label="A description of dress codes and clothing, if needed):"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md="12">
                    <section id="thingsToKnow">
                      <div className="basicinformation">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/menu_book_black_24dp1.svg")
                              .default
                          }
                          className="icomn"
                        />
                        Additional Things to Know
                      </div>
                      <div className="cardBox">
                        <div className="d-flex align-items-center justify-content-end">
                          <CheckboxField
                            label="Use this Field"
                            name="usethisfieldadditional"
                            {...register("usethisfieldadditional")}
                            onChange={(e) => {
                              setValue(
                                "usethisfieldadditional",
                                e.target.checked ? "1" : "0"
                              );
                              setAdditionalchke(!additionalchke);
                            }}
                            check={additionalchke}
                          />
                        </div>
                      </div>
                    </section>
                  </Col>
                  {additionalchke ? (
                    <>
                      <Col md="12">
                        <div className="formBox">
                          <InputField
                            type="text"
                            placeholder="Additional Things to Know....."
                            label="Title:"
                            name="additional"
                            defaultValue={storyData?.additional_things_title}
                            onChange={handleChangeinput}
                            {...register("additional")}
                          />
                        </div>
                        <div className="formBox">
                          <TextareaField
                            name="additional_things_description"
                            defaultValue={
                              storyData?.additional_things_description
                            }
                            onChange={handleChangeinput}
                            {...register("additional_things_description")}
                            row="3"
                            placeholder="Special information related to the events:"
                            label="Special information related to the events:"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md="12">
                    <section id="aeroplane">
                      <div className="basicinformation">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/editfieldicons90.svg").default
                          }
                          className="icomn"
                        />
                        How to Get Here
                      </div>
                      <div className="cardBox">
                        <div className="d-flex align-items-center justify-content-end">
                          <CheckboxField
                            label="Use this Field"
                            name="usethisfieldget"
                            {...register("usethisfieldget")}
                            onChange={(e) => {
                              setValue(
                                "usethisfieldget",
                                e.target.checked ? "1" : "0"
                              );
                              setHowtochke(!howtochke);
                            }}
                            check={howtochke}
                          />
                        </div>
                      </div>
                    </section>
                  </Col>
                  {howtochke ? (
                    <>
                      <Col md="12">
                        <div className="formBox">
                          <InputField
                            type="text"
                            placeholder="How to get here..."
                            label="Title:"
                            name="titlearrangements"
                            defaultValue={storyData?.how_to_get_title}
                            onChange={handleChangeinput}
                            {...register("titlearrangements")}
                          />
                        </div>
                        <div className="formBox">
                          <TextareaField
                            name="how_to_get_description"
                            defaultValue={storyData?.how_to_get_description}
                            onChange={handleChangeinput}
                            {...register("how_to_get_description")}
                            row="3"
                            placeholder="Travel Arrangements for the events, if present:"
                            label="Travel Arrangements for the events, if present:"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md="12">
                    <div className="basicinformation">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/moredetails4.svg").default}
                        className="icomn "
                      />
                      Questions and Answers
                    </div>
                    <div className="cardBox">
                      <div className="d-flex align-items-center justify-content-end">
                        <CheckboxField
                          label="Use this Field"
                          name="usethisfieldquestions"
                          onChange={(e) => {
                            setValue(
                              "usethisfieldquestions",
                              e.target.checked ? "1" : "0"
                            );
                            setQuestions(!questions);
                          }}
                          check={questions}
                        />
                      </div>
                    </div>
                  </Col>
                  {questions ? (
                    <>
                      <Col md="12">
                        <div className="formBox">
                          <InputField
                            type="text"
                            placeholder="Questions and Answers..."
                            label="Title:"
                            name="titlequ"
                            defaultValue={storyData?.que_and_ans_title}
                            onChange={handleChangeinput}
                            {...register("titlequ")}
                          />
                        </div>
                        <div className="formBox">
                          <TextareaField
                            row="3"
                            placeholder="List questions and answer them here:"
                            label="List questions and answer them here:"
                            name="que_and_ans_description"
                            defaultValue={storyData?.que_and_ans_description}
                            onChange={handleChangeinput}
                            {...register("que_and_ans_description")}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {/* <div className="AdDLiSt_BuTtON">
                    <Button
                      className="btn-white"
                      color="default"
                      style={{
                        background: "#FC3158",
                        color: "#fff",
                        width: "120px",
                        borderRadius: "10px",
                      }}
                    >
                      Update
                    </Button>
                  </div> */}
                {/* <Button className="btn btn-secondary mt-3 buttonfute" type="submit">
                                        Save
                                    </Button> */}
              </div>
              {/* </div> */}
            </Form>
          </div>
        </>
      ) : (
        <>
          <div className="loding">
            <TailSpin
              ariaLabel="loading"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}
export default StoryzAbout;
