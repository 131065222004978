import React from "react";
import classnames from "classnames";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { ReactSession } from "react-client-session";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { informationService } from "../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import User from "../../assets/img/PngItem_4212341.png";

import * as actionTypes from "../../store/actionTypes";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import { TiUser } from "react-icons/ti";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import IconImg from "../../assets/img/icons/system-logos.svg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
// const options = [
//   // { value: 'Baid Group', label: `<div><img src="https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1" height="30px" width="30px"/>Baid Group</div>` },
//   { value: 'Baid Group', label: funImag('Baid Group', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Markiewicz Group', label: funImag('Markiewicz Group', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Burt Group', label: funImag('Burt Group', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Lash Group', label: funImag('Lash Group', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Cypress Group', label: funImag('Cypress Group', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Groom', label: funImag('Groom', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'DJ', label: funImag('Dj', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Featured Speaker', label: funImag('Featured Speaker', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Event 1', label: funImag('Event 1', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Event 2', label: funImag('Event 2', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
//   { value: 'Event 3', label: funImag('Event 3', 'https://www.gravatar.com/avatar/1fb5e92d034cdd25d18225bff8a5e8d9?s=64&d=identicon&r=PG&f=1') },
// ];

// function funImag(name, img) {
//   return (<>
//     <div>
//       <img src={img} style={{ borderRadius: '50%' }} height="30px" width="30px" />
//       <span style={{ paddingLeft: '4px' }}>{name}</span>
//     </div>
//   </>)
// }

function AccountInformation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const contact = useSelector((state) => {
    return state.profile;
  });
  const [allimage, setAllimage] = React.useState([]);
  // const [alldetails, setAlldetails] = useState({});
  const [userInfo, setuserInfo] = React.useState();
  let emailAddress = false;
  //const [emailAddress, setemailAddress] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState();
  // const [selectedOption, setSelectedOption] = React.useState(null);
  // const animatedComponents = makeAnimated();
  // const [startDate, setStartDate] = React.useState();
  // const [picture, setPicture] = React.useState(null);
  const [imgData, setImgData] = React.useState(null);

  //const [zoneval, setValuezone] = React.useState('utc');
  const [country, setCountry] = React.useState();

  // const handleChange = (e) => {
  //   setValuezone(e.target.value);
  // };
  const PhoneNumber = (event) => {
    setPhoneNumber(event);
  };
  // const onChangePicture = e => {
  //   if (e.target.files[0]) {
  //     // e.preventDefault();
  //     // setPicture(e.target.files[0]);
  //     // const reader = new FileReader();
  //     // reader.addEventListener("load", () => {
  //     //   setImgData(reader.result);
  //     // });

  //     // const formData = new FormData()
  //     // formData.append('file', e.target.files[0])

  //     // let userInfo = JSON.parse(localStorage.getItem('userDetails'));

  //     // axios.post(""+Global.BASE_API_PATH+"/profile/upload", formData, {
  //     //     headers: {
  //     //         'token': userInfo.token
  //     //     }
  //     // })
  //     // .then((res) => {
  //     //   console.log(res)
  //     //   })
  //     //   .catch((error) => {
  //     //     console.log(error);
  //     //   })

  //     // console.log("picture: ", e.target.files);

  //     // console.log(e.target.files[0]);
  //     // console.log(e.target.files[0].name);
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       setImgData(reader.result);

  //     });
  //     reader.readAsDataURL(e.target.files[0]);

  //     const imageUrl = URL.createObjectURL(e.target.files[0]);

  //     const formData = new FormData()
  //     formData.append('file', e.target.files[0])
  //     informationService.profileUpdate(formData)
  //       .then(
  //         res => {
  //           if (res.status === 'success') {
  //             toast.success("Profile upload successfully !!", "Account");
  //             userdetail();
  //           } else {

  //             toast.error("Something went wrong !! !!", "Account");
  //           }

  //         }, error => {
  //           toast.error("Something went wrong !! !!", "Account");

  //         }
  //       )
  //   }
  // };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      surname: "",
      suffix: "",
      about: "",
    },
  });
  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));

          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );
  }
  const onSubmit = (data) => {
    if (
      data.mobile_number &&
      isValidPhoneNumber(data.mobile_number) === false
    ) {
      setValue("mobile_number", "", { shouldValidate: true });
      return false;
    }

    const update = {
      first_name: data.first_name,
      last_name: data.last_name,
      mobile_number: data.mobile_number,
      surname: data.surname,
      suffix: data.suffix,
      about: data.about,
      country_code: country,
    };

    informationService.acInformationupdate(update).then(
      (res) => {
        if (res.status === "success") {
          toast.success(
            "Your account details has been successfully updated  !!",
            "Account"
          );
          setValue("first_name", data.first_name, { shouldTouch: true });
          setValue("last_name", data.last_name, { shouldTouch: true });
          setValue("mobile_number", data.mobile_number, { shouldTouch: true });
          setValue("surname", data.surname ? data.surname : "0", {
            shouldTouch: true,
          });
          setValue("suffix", data.suffix ? data.suffix : "0", {
            shouldTouch: true,
          });
          setValue("about", data.about ? data.about : "", {
            shouldTouch: true,
          });
          userdetail();
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => {
        onChange(event.target.value || undefined);

        setValue("mobile_number", "", { shouldValidate: true });
      }}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {labels[country]} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );
  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  React.useEffect(() => {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          setuserInfo(res.data);
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          setValue("first_name", res.data.first_name, { shouldTouch: true });
          setValue("last_name", res.data.last_name, { shouldTouch: true });
          setValue("mobile_number", res.data.mobile_number, {
            shouldTouch: true,
          });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  React.useEffect(() => {
    setValue(
      "first_name",
      contact?.profiledetails ? contact?.profiledetails.first_name : "",
      { shouldTouch: true }
    );
    setValue(
      "last_name",
      contact?.profiledetails ? contact?.profiledetails.last_name : "",
      { shouldTouch: true }
    );
    setValue(
      "mobile_number",
      contact?.profiledetails ? contact?.profiledetails.mobile_number : "",
      { shouldTouch: true }
    );
    setValue(
      "surname",
      contact?.profiledetails ? contact?.profiledetails.surname : 0,
      { shouldTouch: true }
    );
    setValue(
      "suffix",
      contact?.profiledetails ? contact?.profiledetails.suffix : 0,
      { shouldTouch: true }
    );
    setValue(
      "about",
      contact?.profiledetails ? contact?.profiledetails.about : "",
      { shouldTouch: true }
    );

    setCountry(
      contact?.profiledetails ? contact?.profiledetails.country_code : ""
    );

  }, [contact]);

  const handleClick = () => {
    setModalShow(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "profile",
      user_id: contact?.profiledetails?.user_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    });

    informationService.profileUpdate(data).then(
      (res) => {
        if (res.status === "success") {
          userdetail();
          toast.success("Profile upload successfully !!", "Account");

          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const seletedImageClike = (e) => {
    let jsonData = JSON.parse(e.img_json);
    const data = JSON.stringify({
      type: "profile",
      user_id: contact?.profiledetails?.user_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData,
    });

    informationService.profileUpdate(data).then(
      (res) => {
        if (res.status === "success") {
          userdetail();
          setModalShow(false);
          toast.success("Profile upload successfully !!", "Account");

          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);

  return (
    <>
      {/* <Allheader title="Your Account" /> */}
      <CombinedGuestPoolHeader title="Your Account" imgScr={IconImg} />
      <div className="organizerWrapperMain">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card organizerCardWrapper aboutInfoWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Account Information</h4>
            </div>
            <div className="organizationPara p-4">
              This page displays the information shared if you are a guest at an
              event.
            </div>

            <div className="px-4 py-2 d-flex">
              <div className="grayBtnDisabled disabled ml-auto">
                <img
                  alt="Organization Name"
                  src={require("assets/img/icons/Chat-Vector2.svg").default}
                />
                Message
              </div>
            </div>
            <div className="d-flex m-4">
              <Button className="saveChangesBtn ml-auto">
                <TiUser className="icon" /> Save Changes
              </Button>
              {/* <Button className="deleteBtn">
                <IoMdClose className="icon" /> Delete Guest
              </Button> */}
            </div>
            <Row className="aboutInfoBox">
              <Col lg={6} md={12}>
                <div className="basicinformation">Basic Information</div>

                <div className="cardBox">
                  <div className="basicInfoAvatar">
                    <div className="basicInfoAvatarImg">
                      <img
                        src={
                          imgData
                            ? imgData
                            : contact.profiledetails.imageUrl
                              ? contact.profiledetails.imageUrl
                              : User
                        }
                        className="rounded-circle"
                        style={{ width: "120px", height: "120px" }}
                        alt="Avatar"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      />
                      {modalShow ? (
                        <Filemanager
                          show={modalShow}
                          onHide={handleClick}
                          onClickimageUpdate={imageUpdate}
                          list={allimage}
                          seletedImage={seletedImageClike}
                        />
                      ) : (
                        ""
                      )}

                      <div className="accountTypeName">
                        {contact?.profiledetails
                          ? contact.profiledetails?.account_type
                          : ""}
                      </div>
                    </div>

                    <div className="basicInfoAvatarContent">
                      <div className="basicInfoMediaIcon">
                        <img
                          src={require("assets/img/update.svg").default}
                          onClick={() => {
                            setModalShow(true);
                          }}
                        />
                        {/* <Input id="profilePic" type="file" name="myfile" onChange={onChangePicture} /> */}
                      </div>
                      <div className="usernameonly">
                        {contact?.profiledetails
                          ? contact.profiledetails?.first_name
                          : ""}{" "}
                        {contact?.profiledetails
                          ? contact.profiledetails?.last_name
                          : ""}
                      </div>
                      <Link
                        to="/admin/account-information"
                        className="greenActionBtn"
                      >
                        <i className="fas fa-user"></i>
                        View Admin Account
                      </Link>
                      {/* <Link to="/admin/account-information"><p className="accountname mt-3"><span style={{ marginRight: '10px' }}><i className="fas fa-user"></i></span>View Admin Account</p></Link> */}
                    </div>
                  </div>
                </div>
                <div className="cardBox basicInfoFormName">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="prefix">
                      Prefix (Ms., Dr.):
                    </label>
                    <select
                      id="prefix"
                      className="form-control"
                      name="surname"
                      {...register("surname")}
                    >
                      <option value="0">--Select--</option>
                      <option value="Mr">Mr.</option>
                      <option value="Ms">Ms.</option>
                      <option value="Dr">Dr.</option>
                      <option value="Miss">Miss.</option>
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      First Name:
                    </label>
                    <input
                      className="form-control"
                      name="first_name"
                      {...register("first_name", {
                        required: true,
                      })}
                      id="example2cols1Input"
                      placeholder="First Name"
                      type="text"
                    />
                    {errors.first_name && (
                      <small style={{ color: "#ff0000" }}>
                        first name is required
                      </small>
                    )}
                  </FormGroup>
                </div>
                <div className="cardBox basicInfoFormName reverse">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example2cols1Input"
                    >
                      Last Name:
                    </label>
                    <input
                      className="form-control"
                      name="last_name"
                      {...register("last_name", {
                        required: true,
                      })}
                      id="example2cols1Input"
                      placeholder="Last Name"
                      type="text"
                    />
                    {errors.last_name && (
                      <small style={{ color: "#ff0000" }}>
                        last name is required
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Suffix (Esq, Phd.):
                    </label>
                    <select
                      id="exampleFormControlSelect1"
                      type="select"
                      className="form-control"
                      name="suffix"
                      {...register("suffix")}
                    >
                      <option value="0">--Select--</option>
                      <option value="Esq">Esq</option>
                      <option value="PhD">PhD</option>
                    </select>
                  </FormGroup>
                </div>

                <div className="basicinformation">
                  Admin Notes on this Guest
                </div>
                <div className="cardBox">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlTextarea2"
                    >
                      Add any special information about this guest here:
                    </label>
                    <textarea
                      className="form-control"
                      name="about"
                      {...register("about")}
                      rows="3"
                      placeholder="This person will require some special attention getting to event."
                    ></textarea>
                  </FormGroup>

                  {/* <p className="basicinformation mt-2">Partnership Status (optional)</p>
                      <div>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect1"
                              >
                                Partnership Status:
                              </label>
                              <Input id="exampleFormControlSelect1" type="select">
                                <option>--Select--</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div> */}
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">Contact Information</div>
                <div className="cardBox basicInfoFormName">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Country Code:
                    </label>

                    <CountrySelect
                      labels={en}
                      value={country}
                      name="country_code"
                      className="form-control"
                      onChange={setCountry}
                      control={control}
                    />

                    {/* <Input id="exampleFormControlSelect1" type="select">
                            <option>--Select--</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input> */}
                  </FormGroup>

                  <FormGroup>
                    <div className="form-group">
                      <label className="form-label">Mobile Number:</label>
                      <div className="d-flex">
                        <PhoneInput
                          className="form-control"
                          name="mobile_number"
                          {...register("mobile_number", {
                            required: true,
                          })}
                          defaultValue={phoneNumber}
                          country={country}
                          international
                          withCountryCallingCode
                          control={control}
                          placeholder="Phone number"
                          onChange={PhoneNumber}
                          style={
                            errors.mobile_number
                              ? { border: "1px solid #f00909" }
                              : {}
                          }
                          error={
                            phoneNumber
                              ? isValidPhoneNumber(phoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {phoneNumber
                          ? isValidPhoneNumber(phoneNumber)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>
                    </div>
                  </FormGroup>
                </div>
                <div className="cardBox">
                  <FormGroup>
                    <label className="form-control-label">Email:</label>
                    <InputGroup
                      className={classnames("input-group-merge", {
                        focused: emailAddress,
                      })}
                    >
                      <Input
                        placeholder="Email"
                        type="email"
                        defaultValue={userInfo?.email ? userInfo?.email : ""}
                        disabled
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </div>
                {/*
                    <p className="basicinformation mt-2">Honored Guest Roles</p>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"

                          >
                            Role in the Event
                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[5], options[6], options[7]]}
                            isMulti
                            options={options}

                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* <p className="basicinformation mt-2">Guest Groups</p>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"

                          >
                            Member of Guest Groups (type below to add a group):

                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[options[1], options[2], options[3], options[4], options[0]]}
                            isMulti
                            placeholder="weqweqweqw"
                            options={options}
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                {/* <div className="basicinformation">Storyz</div>
                <div className="cardBox">
                  <FormGroup>
                    <label className="form-control-label">
                      All Storyz in which this guest appears on the guest list.
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[options[8], options[9], options[10]]}
                      isMulti
                      options={options}
                    />
                  </FormGroup>
                </div> */}

                {/* <div className="basicinformation">Date</div>
                <div className="cardBox form-group">
                  <Input
                    id="exampleFormControlSelect1"
                    type="select"
                    onChange={handleChange}
                  >
                    <option value="America/Los_Angeles">
                      UTC-7 - America/Los Angeles
                    </option>
                    <option value="America/Chicago">
                      UTC-5 - America/Chicago
                    </option>
                    <option value="America/New_York">
                      UTC-4 - America/New York
                    </option>
                    <option value="utc">UTC</option>
                    <option value="Europe/London">UTC+1 - Europe/London</option>
                    <option value="Europe/Berlin">UTC+2 - Europe/Berlin</option>
                    <option value="Europe/Bucharest">
                      UTC+3 - Europe/Bucharest
                    </option>
                    <option value="local" selected="">
                      UTC+5.5 - Local
                    </option>
                    <option value="Asia/Shanghai">UTC+8 - Asia/Shanghai</option>
                    <option value="Asia/Tokyo">UTC+9 - Asia/Tokyo</option>
                  </Input>

                  <FormGroup>
                    <div className="dateTimePickerGlobal">
                      <DatePicker
                        isClearable
                        name="datetime1"
                        className={"form-control"}
                        selected={startDate}
                        onChange={(val) => {
                          setStartDate(val);
                          setValue("start", val);
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Time"
                        placeholderText="Date"
                        dateFormat="MM-dd-yyyy h:mm a"
                      />
                    </div>
                  </FormGroup>
                </div> */}
              </Col>
            </Row>
            {/* <Row>
              <Col md="12">
                <Button className="blueBtn mx-auto">Update</Button>
              </Col>
            </Row> */}
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
}

export default AccountInformation;
