import React, { forwardRef, memo } from "react";
import classNames from "./Map.module.scss";
import cn from "classnames";

const Map = ({ existBorder = true, isFullscreen = false }, ref) => {
  return (
    <div
      className={cn(classNames.map, {
        [classNames.border]: existBorder,
        "without-fullscreen-control": !isFullscreen,
      })}
      ref={ref}
    />
  );
};

export default memo(forwardRef(Map));
