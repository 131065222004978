/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AdminFooter() {
  return (
    <>
      <div className="footerWrapperNew">
        <footer className="footer p-0" style={{ background: "#000" }}>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="12">
              <p
                style={{
                  color: "#fff",
                  textAlign: "end",
                  padding: "12px 28px",
                  margin: "0",
                }}
              >
                Copyright © 2022 OurStoryz. All rights reserved.
              </p>
            </Col>
          </Row>
        </footer>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
}

export default AdminFooter;
