import React, { useState, useEffect } from "react";
import { informationService } from "../../_services/masters/information.service";
import { timezoneService } from "../../_services/masters/timezone.service";
import { FormGroup, Input, Modal, Button } from "reactstrap";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Dropdown from "../Dropdown/Dropdown";
import { commonData } from "store/actions";
import DatePicker from "react-datepicker";
import * as actionTypes from "../../store/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import { InputField, CheckboxField } from "../Inputbox";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DummyImg from "../../assets/img/dummyImg.svg";
function EventConfiguration(props) {
  const handleClick = () => {
    setDefaultModale(true);
  };
  const dispatch = useDispatch();
  let history = useHistory();
  const [isStoryz, setisStoryz] = useState(false);
  // const [disabled, setDisabled] = useState(false);
  const [val, setcheckVal] = React.useState(1);
  const [storyzList, setStoryzlist] = React.useState([]);
  const [storyzList2, setStoryzlist2] = React.useState([]);
  const [eventsInfo, setEventsInfo] = React.useState({});
  const [selectboxS, setSelectboxS] = React.useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({});
  const contact = useSelector((state) => {
    return state.profile;
  });

  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  let [showInfo1, setShowInfo1] = useState(false);
  const [errorseventtype, setErrorseventtype] = React.useState(false);

  const storysList = useSelector((state) => {
    return state.storysList.storysList;
  });
  const [eventType, setEventType] = React.useState([]);
  const [defaultModale, setDefaultModale] = React.useState(false);

  const options = eventType;

  function dataEvList() {
    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.EVENTS_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();

  useEffect(() => {
    Eventtype();
  }, []);

  const onSubmitList = (data) => {
    informationService.eventAdd(data).then(
      (res) => {
        if (res.status === "success") {
          reset2();
          Eventtype();
          setDefaultModale(false);
          toast.success(res.message, "Account");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  function Eventtype() {
    informationService.eventType().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const handleStoryz = (e) => {
    const valueName = e.target.value;

    const data = {
      storyz_name: valueName,
    };
    informationService.projectSearch(data).then(
      (res) => {
        if (res.status === "success") {
          setStoryzlist2(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  React.useEffect(() => {
    setStoryzlist(storysList);
    setStoryzlist2(storysList);
  }, [storysList]);
  const handleChangeinput = (e) => {
    eventsInfo[e.targe.name] = e.targe.value;
    setEventsInfo({ ...eventsInfo });
  };
  // function handleSubmitTask1(event) {
  //   event.preventDefault();

  // }

  // function handleSubmitTask2(event) {
  //   event.preventDefault();
  //   console.log("Execute Task2");
  // }

  const onSubmit = (data) => {
    var formdata = new FormData();

    formdata.append("event_name", data.event_name);
    formdata.append("event_type", data.event_type);
    formdata.append("project_id", props.sId);
    formdata.append(
      "organization_id",
      contact?.profiledetails.organization[0]?.organization_id
    );
    if (data.dateyet === "0") {
      if (data.durationtype === "day") {
        var date = new Date(startDate);
        var day = data.duration * (60 * 60 * 24 * 1000);
        var result = new Date(startDate.getTime() + day);
        formdata.append(
          "event_start_date",
          moment(startDate).format("YYYY-MM-DD h:mm:ss a")
        );
        formdata.append(
          "event_end_date",
          moment(result).format("YYYY-MM-DD h:mm:ss a")
        );

        // var result = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + data.duration));
        // console.log(result);
      } else if (data.durationtype === "week") {
        var date = new Date(startDate);
        var allDate = data.duration * 7;
        var day = allDate * (60 * 60 * 24 * 1000);
        var result = new Date(startDate.getTime() + day);
        formdata.append(
          "event_start_date",
          moment(startDate).format("YYYY-MM-DD h:mm:ss a")
        );
        formdata.append(
          "event_end_date",
          moment(result).format("YYYY-MM-DD h:mm:ss a")
        );
      } else if (data.durationtype === "month") {
        var date = new Date(startDate);
        var allDate = data.duration * 30;
        var day = allDate * (60 * 60 * 24 * 1000);
        var result = new Date(startDate.getTime() + day);
        formdata.append(
          "event_start_date",
          moment(startDate).format("YYYY-MM-DD h:mm:ss a")
        );
        formdata.append(
          "event_end_date",
          moment(result).format("YYYY-MM-DD h:mm:ss a")
        );
      } else if (data.durationtype === "year") {
        var date = new Date(startDate);
        var allDate = data.duration * 365;
        var day = allDate * (60 * 60 * 24 * 1000);
        var result = new Date(startDate.getTime() + day);
        formdata.append(
          "event_start_date",
          moment(startDate).format("YYYY-MM-DD h:mm:ss a")
        );
        formdata.append(
          "event_end_date",
          moment(result).format("YYYY-MM-DD h:mm:ss a")
        );
      }
      // else {
      //   var d = new Date(startDate);
      //   d = new Date(d.setHours(d.getHours() + parseInt(data.duration)));
      //   formdata.append("event_start_date", moment(startDate).format("YYYY-MM-DD h:mm:ss a"));
      //   formdata.append("event_end_date", moment(d).format("YYYY-MM-DD h:mm:ss a"));

      // }
    }
    // for (let [key, value] of formdata) {
    //   console.log(`${key}: ${value}`)
    // }

    // const data1 = {
    //   "event_name": data.event_name,
    //   "event_type": selectboxS.id,
    //   "project_id": props.sId,
    //   "organization_id": contact?.profiledetails.organization[0]?.organization_id
    // }

    const buttonType = window.event.submitter.name;

    if (buttonType === "createEvent") {
      timezoneService.eventSave(formdata).then(
        (res) => {
          if (res.status === "success") {
            toast.success(res.message, "Account");
            dataEvList();

            const common = {
              sid: res.eventDtails.storyz_id,
              eid: res.eventDtails.id,
              storyName: res.eventDtails.storyz_name,
              eventList: res.eventList,
              singleEvent: res.eventDtails,
            };

            dispatch(commonData(common));

            history.push(
              `/admin/event-dashboard/${res.eventDtails.storyz_id}/${res.eventDtails.id}`
            );
            reset();
            setValue("event_type", "");
            props.onHide();
          } else {
            console.log("Something went wrong !!");
            setErrorseventtype(true);
            //toast.error('Please Select the Storyz your Event will be part of', "Error");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    } else {
      timezoneService.eventSave(formdata).then(
        (res) => {
          if (res.status === "success") {
            toast.success(res.message, "Account");
            dataEvList();
            reset();
            setValue("event_type", "");
            window.location.reload(false);
          } else {
            console.log("Something went wrong !!");
            setErrorseventtype(true);
            //toast.error('Please Select the Storyz your Event will be part of', "Error");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger deleteaccountowner"
        isOpen={defaultModale}
      >
        <form onSubmit={handleSubmit2(onSubmitList)} key={1}>
          <div
            className="modal-header"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <h6
              className="modaltitle mx-auto mb-0"
              id="modal-title-notification"
            >
              <img
                alt="..."
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                width="75px"
                style={{ marginRight: "10px" }}
              />
            </h6>
          </div>
          <div className="modal-body">
            <div className="">
              <h4 className="" style={{ textAlign: "center" }}>
                Add a New List Item?
              </h4>

              <p>
                <span
                  style={{
                    fontSize: "14px",
                    display: "block",
                    fontWeight: "400",
                    marginBottom: "15px",
                  }}
                >
                  {" "}
                  Confirm that you want to add a list item to this list.
                </span>

                <span
                  style={{
                    fontSize: "14px",
                    display: "block",
                    fontWeight: "300",
                    marginBottom: "15px",
                  }}
                >
                  Make sure you want to do this!
                </span>
              </p>

              <input
                className="form-control"
                type="text"
                placeholder="Add New Item"
                name="event_type"
                {...register2("event_type", { required: true })}
              />
              {errors2.event_type && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white "
              type="submit"
              style={{ background: "#FC3158", color: "#fff" }}
            >
              <span className="whiteColor">Add</span>
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                // this.setState({ defaultModale: false });
                setDefaultModale(false);
              }}
              style={{ border: "1px solid #147EFB" }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={props.show}
        // toggle={() => setdefaultModale(false)}

        centered
        className="eventConfigurationModal"
        onClick={() => {
          setShowInfo1(true);
        }}
      >
        <div className="modal-header">
          <h2 className="" id="modal-title-notification">
            <img
              alt="..."
              src={
                require("assets/img/Edit-Specific-Events-in-this-Storyz.svg")
                  .default
              }
              width="48px"
            />
            Event Configuration
          </h2>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="eventConfigurationFormWrapper">
              <div className="eventConfigurationFormLeft">
                <h4>Select the Storyz your Event will be part of:</h4>
                <FormGroup>
                  <Input
                    id="example2cols1Input"
                    placeholder="type to search..."
                    type="text"
                    onChange={handleStoryz}
                  />
                </FormGroup>
                <div className="SelectStoryzEvent">
                  {storyzList2?.length > 0 ? (
                    <>
                      {storyzList2?.map((popup, i) => (
                        <div key={Math.random()}>
                          <div
                            className={
                              props.sId === popup.id
                                ? "story1ev eventConfigurCard active_item "
                                : "story1ev eventConfigurCard"
                            }

                            onClick={() => {
                              history.push(`/admin/storyz/${popup.id}`);
                              setisStoryz(false);
                            }}
                          >
                            <div className="date">
                              {popup.project_state_date
                                ? moment(popup.project_state_date).format(
                                  "MMM Do YY"
                                )
                                : "No Date"}
                            </div>
                            <div className="storysImg">
                              <img
                                alt="Organization Name"
                                // src={
                                //   require("assets/img/Mask-Group.svg").default
                                // }
                                src={
                                  popup.project_image
                                    ? popup.project_image
                                    : DummyImg
                                }
                                className="stimage imgs"
                              />
                            </div>
                            <div>
                              <p className="titlee">{popup.storyz_name}</p>
                              <p className="subtitle">
                                Event {popup.event_count}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    "No Storyz Data"
                  )}
                </div>
                <div>
                  {contact.profiledetails.service_level === "DIY" ||
                    contact.profiledetails.service_level === "Professional" ? (
                    <>
                      <Link
                        className="newstoryz"
                        to="#"
                        // onClick={ToggleSidebarStoryz}
                        onClick={() => {
                          setisStoryz(false);
                          dispatch({
                            type: actionTypes.STORYZ_POPUP,
                            payload: true,
                          });
                          dispatch({
                            type: actionTypes.EVENTS_POPUP,
                            payload: false,
                          });
                        }}
                      >
                        New Storyz
                      </Link>
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              <div className="eventConfigurationFormRight">
                <div className="cardBox pt-0 mb-0">
                  <InputField
                    type="text"
                    placeholder="enter Event name here..."
                    label="Event Name:"
                    name="event_name"
                    onChange={handleChangeinput}
                    {...register("event_name", { required: true })}
                  />
                  {errors.event_name && (
                    <span className="errorMessage" style={{ color: "red" }}>
                      This field is required
                    </span>
                  )}
                </div>
                <div className="cardBox mb-0">
                  <div className="selectToall form-group">
                    <label>Select the Event Type :</label>
                    <Dropdown
                      isSearchable
                      handleClick={handleClick}
                      placeHolder="Select..."
                      options={options}
                      onChange={(value) => {
                        setSelectboxS(value);
                        setValue("event_type", value.id);
                      }}
                      onClickOutside={showInfo1}
                      addButton={false}
                      customClass={"selectStoryTypeDropdown"}
                    />
                    <input
                      type="hidden"
                      name="event_type"
                      {...register("event_type", { required: true })}
                    />
                    {errors.event_type && (
                      <span className="errorMessage" style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="cardBox mb-0">
                  <h4 className="mb-0"> Specify dates for your event</h4>
                </div>
                <div className="cardBox mb-0">
                  <div className="ml-n3">
                    <CheckboxField
                      label="Don’t have a date yet"
                      name="dateyet"
                      {...register("dateyet")}
                      onChange={(e) => {
                        setValue("dateyet", e.target.checked ? "1" : "0");
                        setcheckVal(!val);
                      }}
                      check={val}
                    />
                  </div>
                </div>
                <div className="cardBox mb-0 py-0">
                  <div
                    style={val ? { pointerEvents: "none", opacity: "0.4" } : {}}
                  >
                    <div className="eventConfigureDateWrapper">
                      {/* <div> */}
                      {/* <InputField

                                                formGroup={false}
                                                type="date"
                                                placeholder="enter date here"
                                                label="Start Date and Time:"
                                                name="start_date"
                                                onChange={handleChangeinput}
                                                {...register("start_date")}
                                                disabled={false
                                                } /> */}

                      <FormGroup>
                        <label>Start Date and Time:</label>
                        <div className="dateTimePickerGlobal datePickerGlobal">
                          {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            filterTime={filterPassedTime}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            style={{ width: "100%" }}
                            minDate={new Date()}
                            onFocus={(e) => e.target.blur()}
                          /> */}
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            filterTime={filterPassedTime}
                            timeFormat="HH:mm"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            style={{ width: "100%" }}
                            minDate={new Date()}
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>
                      </FormGroup>
                      <InputField
                        // formGroup={false}
                        type="text"
                        placeholder="enter duration"
                        label="Duration:"
                        name="duration"
                        onChange={handleChangeinput}
                        {...register("duration")}
                        defaultValue="1"
                      />
                      <div className="form-group">
                        <label for="sel1">&nbsp;</label>
                        <select
                          className="form-control"
                          id="sel1"
                          disabled={false}
                          {...register("durationtype")}
                          name="durationtype"
                        >
                          <option value="day">Day</option>
                          <option value="week">Week</option>
                          <option value="month">Month</option>
                          <option value="year">Year</option>
                        </select>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {errorseventtype && (
                    <span style={{ color: "red" }}>
                      Please Select the Storyz your Event will be part of !!
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <Button
                className="btn-white pinkBtn height40"
                color="default"
                type="submite"
                name="createEvent"
              >
                Create Event
              </Button>

              <Button
                className="btn-white blueBtn"
                color="default"
                type="submite"
                name="createAnother"
              >
                Create and Add Another
              </Button>
              <Button
                className="btn-white cancelBtn"
                color="default"
                type="button"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
export default EventConfiguration;
