import React from "react";
import Eventimage from "../../assets/img/theme/event-image.svg";
import { Link } from "react-router-dom";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { Row, Col } from "reactstrap";
import { ReactSession } from "react-client-session";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as actionTypes from "../../store/actionTypes";
import { useDispatch, useSelector } from "react-redux";

import { informationService } from "../../_services/masters/information.service";
function YourCompany() {
  const contact = useSelector((state) => {
    return state.profile;
  });
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [allimage, setAllimage] = React.useState([]);
  const handleClick = () => {
    setModalShow(false);
  };
  const userdetail = () => {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
      }
    );
  };

  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);
    let organization_id =
      contact?.profiledetails?.organization[0]?.organization_id;
    let jsonData = e;
    jsonData["org_id"] = organization_id;
    jsonData["imaDatajson"] = obj;

    informationService.updateCoverImage(jsonData).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          userdetail();
          setModalShow(false);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";
    let organization_id =
      contact?.profiledetails?.organization[0]?.organization_id;
    const data = {
      type: "organization",
      org_id: organization_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateCoverImage(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          userdetail();
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  return (
    <>
      <div className="divround mb-4">
        <span className="organizationimg orgDashImg">
          <img
            alt="Organization Name"
            src={
              contact?.profiledetails.organization[0]?.organization_logo
                ? contact?.profiledetails.organization[0]?.organization_logo
                : require("assets/img/icons/common/Layer-1.svg").default
            }
            className="img-fluid"
          />
        </span>
        <p style={{ fontSize: "28px", lineHeight: "22px" }}>
          {contact?.profiledetails.organization[0]?.organization_company_name}
        </p>
      </div>

      <div className="coverimage">
        <div className="coverimage1">
          <p className="fontSize14" style={{ color: "#666666" }}>
            Image Label:
          </p>
        </div>
        <div>
          <p
            onClick={() => {
              setModalShow(true);
            }}
          >
            <img
              alt="Organization Name"
              src={require("assets/img/mediaGallery.svg").default}
              className="icomn pointer"
              style={{ width: "24px" }}
            />
          </p>
        </div>
      </div>
      {modalShow ? (
        <Filemanager
          show={modalShow}
          onHide={handleClick}
          onClickimageUpdate={imageUpdate}
          list={allimage}
          seletedImage={seletedImageClike}
        />
      ) : (
        ""
      )}
      <div
        className="imgMaskevente"
        onClick={() => {
          setModalShow(true);
        }}
      >
        <div className="imgMask-content">+</div>
        <img
          alt=""
          src={
            contact?.profiledetails.organization[0]?.organization_cover_image
              ? contact?.profiledetails.organization[0]
                ?.organization_cover_image
              : Eventimage
          }
          className="img-fluid imgfludicon"
          width="100%"
        />
      </div>
      <Row>
        <Col md="12">
          <Link to="/admin/organization-self-account" className="MemberTeam">
            Company Details{" "}
          </Link>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}
export default YourCompany;
