import React, { useState, useEffect } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { GuestEventWrapper } from "../UserGuestList/style";
import Select from "react-select";
import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
} from "../../../CommonData";
import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";

import { TiUser } from "react-icons/ti";
import SortIcon from "./../../../assets/img/sortIcon.svg";
import GuestEditDropdown from "components/Dropdown/GuestEditDropdown";
import { CombinedGuestPool } from "../CombinedGuestPool/style";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import GuestGroupIcon from "./../../../assets/img/guest-group-icon.svg";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import DummyImg from "../../../assets/img/dummyImg.svg";
import LockIcon from "./../../../assets/img/lock-invert-icon.svg";

import AboutRsvps from "./../../../assets/img/about-rsvps.jpg";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import LockInvertIcon from "./../../../assets/img/lock-invert-icon.svg";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { SearchBar } = Search;

const EventInviteRSVPsList = ({ args }) => {
  const params = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteGuestGroup, setDeleteGuestGroup] = useState(false);
  const [addPrefix, setAddPrefix] = useState(false);
  const [guestGroupModal, setGuestGroupModal] = useState();
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [getRsvpList, setGetRsvp] = useState([]);
  const [RsvpList, setGetRsvpList] = useState([]);
  const [guestId, setGuestId] = useState(0);
  const [ev_Id, setEVId] = useState(0);
  const [delGuestData, setguestDelete] = useState(null);
  const [guest_name, setGuestName] = useState("");
  const [guest_img, setGuestImg] = useState("");
  const [guest_relation, setGuestRelation] = useState("");
  const [rsvp_status, setRsvpStatus] = useState("");
  const [event_name, setEventName] = useState("");
  const [cover_image, setCoverImage] = useState("");
  const [event_location, setEventLocation] = useState("");
  const [deleteEverything, setDeleteEverything] = useState(false);

  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let history = useHistory();
  const handleDeleteGuestGroup = (e) => {
    e.preventDefault();
    setDeleteGuestGroup(!deleteGuestGroup);
  };

  const getRsvp = (filter = "All Guest") => {
    localStorage.setItem("rsvp_Data", []);
    let objData = { filter: filter, event_id: params.eid };
    userGuestService.getRsvp(objData).then((res) => {
      if (res.status == "success") {
        const rsvp_Data = res.data;
        const rsvpDataArr = [];
        for (let i = 0; i < rsvp_Data.length; i++) {
          let guest_id = rsvp_Data[i].guest_id;
          let fname =
            rsvp_Data[i].first_name == null ? "" : rsvp_Data[i].first_name;
          let last_name =
            rsvp_Data[i].last_name == null ? "" : rsvp_Data[i].last_name;
          let fullName = fname + " " + last_name;
          let relation = rsvp_Data[i].guest_event_relation;
          let imageUrl = rsvp_Data[i].imageUrl;
          let rsvp_status = rsvp_Data[i].rsvp_status;
          let invites_status = rsvp_Data[i].invites_status;
          //console.log("rsvpData guest_id", guest_id);

          let objrsvp = {
            action: (
              <div className="d-flex align-items-center">
                <GuestEditDropdown
                  guestListOption={guestListOption}
                  guest_id={guest_id}
                  setGuestId={setGuestId}
                  setguestDelete={setguestDelete}
                  storyz_id={Number(params.sid)}
                />
              </div>
            ),

            name: (
              <div className="d-flex align-items-center">
                <div className="guestUserCard ml-4">
                  <div className="guestUserPic">
                    <img
                      src={imageUrl == "" ? DummyImg : imageUrl}
                      className="pic"
                      alt=""
                    />
                  </div>
                  <div className="guestUserBody">
                    <div className="title">{fullName}</div>
                    <div className="subTitle">{relation}</div>
                  </div>
                </div>
              </div>
            ),
            guest_name: fullName,
            invites: invites_status,
            rsvp: (
              <div
                className={`RSVPchip
                             ${rsvp_status == "PENDING"
                    ? "pending"
                    : "" || rsvp_status == "GOING"
                      ? "success"
                      : "" || rsvp_status == "NOT GOING"
                        ? "failed"
                        : "" || rsvp_status == "PAST DEADLINE"
                          ? "failed"
                          : "" || rsvp_status == "ATTENDED"
                            ? "success"
                            : ""
                  }`}
                onClick={() => handleStatusOfGuestUser(params.eid, guest_id)}
              >
                {rsvp_status}
              </div>
            ),
            rsvp_copy: rsvp_status,
          };
          rsvpDataArr.push(objrsvp);
        }
        //console.log("rsvpDataArr", rsvpDataArr);
        setGetRsvp(rsvpDataArr);
        let rsvpString = JSON.stringify(rsvp_Data);
        setGetRsvpList(JSON.parse(rsvpString));
        localStorage.setItem("rsvp_Data", rsvpString);
      } else {
        toast.success(res.message);
      }
    });
  };

  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
  };

  const gotoKeepsakeAlbum = (gid) => {
    history.push(`/admin/storyz-keepsake-album/${params.sid}`);
  };

  const gotoGuest = (gid) => {
    history.push(
      `/admin/edit-user-guest-list/${params.sid}/${params.eid}/${gid}?type=guests`
    );
  };

  const gotoEVauthentication = (gid) => {
    history.push(
      `/admin/event-guest-authentication/${params.sid}/${params.eid}`
    );
  };

  const handleDeleteEverything = (gid) => {
    let rsvpObj = { event_id: params.eid, guest_id: gid };
    userGuestService.getEventRsvp(rsvpObj).then((res) => {
      if (res.status == "success") {

        getGuestdata(gid);
        setEVId(params.eid);
        setEventName(res.data.event_name);
        setCoverImage(res.data.cover_image);
        setEventLocation(res.data.event_location);
        setDeleteGuestUser(true);
        //console.log("getEventRsvp New", res.data);
      } else {
        toast.success(res.message);
      }
    });
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      handleClick: gotoGuest,
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
      handleClick: gotoKeepsakeAlbum,
    },
    {
      img: LockIcon,
      title: "Event Authentication",
      handleClick: gotoEVauthentication,
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteEverything,
    },
  ];

  const AddPrefixModal = () => {
    setAddPrefix(!addPrefix);
  };
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };
  const [resetting, setResetting] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);

  //setRsvpStatus
  const changeRsvpType = (rsvpType) => {
    setRsvpStatus(rsvpType);
  };

  const getGuestdata = (guest_id) => {
    let rldata = JSON.parse(localStorage.getItem("rsvp_Data"));
    if (rldata.length > 0) {
      const guestDetails = rldata.filter((List) => List.guest_id == guest_id);
      let fname =
        guestDetails[0].first_name == null ? "" : guestDetails[0].first_name;
      let last_name =
        guestDetails[0].last_name == null ? "" : guestDetails[0].last_name;
      let fullName = fname + " " + last_name;
      setGuestId(guest_id);
      setGuestName(fullName);
      setGuestImg(guestDetails[0].imageUrl);
      setGuestRelation(guestDetails[0].guest_event_relation);
      setRsvpStatus(guestDetails[0].rsvp_status);
      //console.log('guestDetails', guestDetails)
      //setStatusGuestUser(true);
    } else {
    }
  };

  const handleStatusOfGuestUser = (ev_id, guest_id) => {
    let rsvpObj = { event_id: ev_id, guest_id: guest_id };
    userGuestService.getEventRsvp(rsvpObj).then((res) => {
      if (res.status == "success") {
        getGuestdata(guest_id);
        setEVId(ev_id);
        setEventName(res.data.event_name);
        setCoverImage(res.data.cover_image);
        setEventLocation(res.data.event_location);
        //console.log("getEventRsvp New", res.data);
        setStatusGuestUser(true);
      } else {
        toast.success(res.message);
      }
    });
  };

  const updateRSVP = () => {
    let objRsvp = {
      rsvp_type: rsvp_status,
      event_id: ev_Id,
      guest_id: guestId,
    };
    //console.log("objRsvp", objRsvp);
    userGuestService.updateRSVP(objRsvp).then((res) => {
      if (res.status == "success") {
        getRsvp();
        handleStatusOfGuestUserClose(false);
        handleReseting();
      } else {
        toast.error("something went wrong");
      }
    });
  };

  const deleteRsvpGuest = () => {
    let objRsvp = { event_id: ev_Id, guest_id: guestId };

    userGuestService.deleteRsvpGuest(objRsvp).then((res) => {
      if (res.status == "success") {
        getRsvp();
        handleStatusOfGuestUserClose(false);
        handleDelReseting();
      } else {
        toast.error("something went wrong");
      }
    });
  };

  const handleStatusOfGuestUserClose = () => {
    setStatusGuestUser(false);
    setDeleteGuestUser(false);
  };
  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
      toast.success("Updated Successfully");
    }, 3000);
  };

  const handleDelReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
      toast.success("Deleted Successfully");
    }, 3000);
  };
  const [resetRsvps, setResetRsvps] = useState(false);

  const handleResetRsvps = () => {
    setResetRsvps(!resetRsvps);
  };

  useEffect(() => {
    getRsvp();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const rsvpColumn = [
    {
      dataField: "action",
      text: (
        <div className="d-flex align-items-center align-middle">Action</div>
      ),
      sort: false,
    },
    {
      dataField: "name",
      text: (
        <div className="d-flex align-items-center">
          <TiUser size={20} className="mx-3 userIcon" />
          Guest
        </div>
      ),
      headerStyle: { display: "none" },
      sort: true,
    },
    {
      dataField: "guest_name",
      text: (
        <div className="d-flex align-items-center">
          <TiUser size={20} className="mx-3 userIcon" />
          Guest
        </div>
      ),
      sort: true,
      style: { display: "none" },
    },
    {
      dataField: "invites",
      text: <div className="d-flex align-items-center">Invites</div>,
      sort: true,
    },
    {
      dataField: "rsvp",
      text: <div className="d-flex align-items-center">RSVP</div>,
      headerStyle: { display: "none" },
      sort: true,
    },
    {
      dataField: "rsvp_copy",
      text: <div className="d-flex align-items-center">RSVP</div>,
      sort: true,
      style: { display: "none" },
    },
  ];

  return (
    <>
      <ToastContainer></ToastContainer>
      <Modal
        isOpen={deleteGuestUser}
        toggle={handleDeleteGuestUser}
        // {...args}
        className="resetGeospaceModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Remove This Guest From the Guest List?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="geospaceThumWrapper">
                <div className="geospaceThum">
                  <img
                    src={guest_img == "" ? DummyImg : guest_img}
                    alt=""
                    className="geospaceThumImg"
                  />
                </div>
                <div className="geospaceThumContent">
                  <div className="geothumbTitle">{guest_name}</div>
                  <div className="geothumbSubTitle">{guest_relation}</div>
                </div>
              </div>
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img
                    src={cover_image == "" ? DummyImg : cover_image}
                    alt=""
                    className="eventThumImg"
                  />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">{event_name}</div>
                  <div className="eventthumbSubTitle">{event_location}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                Confirm you want to remove this guest from the Event guest list.{" "}
              </p>
              <p>This will NOT remove the guest data.</p>
              <p>
                Instead it will just remove the guest from the invite list for
                this event.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn"
            onClick={() => deleteRsvpGuest()}
          >
            Remove Guest from List
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={() => handleDeleteGuestUser(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={statusGuestUser}
        toggle={handleStatusOfGuestUserClose}
        {...args}
        className="resetGeospaceModal statusofGuestaListModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Force the RSVP Status for This Guest?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img
                    src={cover_image == "" ? DummyImg : cover_image}
                    alt=""
                    className="eventThumImg"
                  />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">{event_name}</div>
                  <div className="eventthumbSubTitle">{event_location}</div>
                </div>
              </div>
              <div className="geospaceThumWrapper">
                <div className="geospaceThum">
                  <img src={guest_img} alt="" className="geospaceThumImg" />
                </div>
                <div className="geospaceThumContent">
                  <div className="geothumbTitle">{guest_name}</div>
                  <div className="geothumbSubTitle">{guest_relation}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                You are manually setting the status of this Guest’s RSVP. Select
                an RSVP state belolow by clicking one of the following buttons:
              </p>
              <hr className="mb-4 mt-3" />
              <div className="statusGuestUserWrapper mb-3">
                <div className="form-check pending">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="PENDING"
                    checked={rsvp_status == "PENDING" ? true : false}
                    onChange={(e) => changeRsvpType(e.target.value)}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    PENDING
                  </label>
                </div>
                <div className="form-check going">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="GOING"
                    checked={rsvp_status == "GOING" ? true : false}
                    onChange={(e) => changeRsvpType(e.target.value)}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    GOING
                  </label>
                </div>
                <div className="form-check notGoing">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="NOT GOING"
                    checked={rsvp_status == "NOT GOING" ? true : false}
                    onChange={(e) => changeRsvpType(e.target.value)}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    NOT GOING
                  </label>
                </div>
                <div className="form-check attended">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="ATTENDED"
                    checked={rsvp_status == "ATTENDED" ? true : false}
                    onChange={(e) => changeRsvpType(e.target.value)}
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    ATTENDED
                  </label>
                </div>
              </div>
              <p>
                Are you sure you want to do this? This will erase the RSVP
                status set by the system.
              </p>
            </Col>
          </Row>

          <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={updateRSVP}
            >
              Force RSVP Status
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleStatusOfGuestUserClose(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={resetting}
        toggle={handleReseting}
        {...args}
        className="resetRSVPModal"
      >
        <ModalBody className="pb-0">
          <div className="border-bottom mb-3 pb-3">
            <div className="head">Resetting RSVPs</div>
          </div>
          <Row>
            <Col className="px-4">
              <p className=" px-4 text-center">
                Changing the RSVP Status for this guest...
              </p>
              <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                <div className="circleAnimation"></div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              Event Name
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234" onClick={handelGuestGroupModal}>
                <span>Event RSVPs</span>
              </div>

              <Modal
                isOpen={guestGroupModal}
                toggle={handelGuestGroupModal}
                {...args}
                className="aboutGuestGroupModal"
              >
                <ModalBody className="pb-0">
                  <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                    <img
                      src={GeospaceInfoIcon}
                      alt=""
                      className="guestGroupInfoIcon mt-3"
                    />
                    <div className="head">About RSVPs</div>
                  </div>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        This page shows all the RSVPs you have received for an
                        event, independent of any Invites you have sent out.
                      </p>
                      <p>
                        You can manually force the RSVP status for a guest, if
                        they have RSVPed or declined in a different way.
                      </p>
                    </Col>
                    <Col className="d-flex align-items-center justify-center">
                      <img
                        src={AboutRsvps}
                        alt=""
                        className="maxWidth100 mx-auto"
                        width={106}
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="pt-0">
                  <Button color="primary blueBtn" className="w-100">
                    Continue
                  </Button>
                </ModalFooter>
              </Modal>

              <span
                className="ml-2"
                data-tip="Tooltip message will come here....."
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
            </div>
          </div>
          <div className="border-bottom organizationPara">
            This pages shows you all the guests on your list who have RSVP. You
            can manually set a guest's RSVP here.
            <br />
            To see the complete list of todos, go to the
            <Link
              className="ml-1"
              onClick={() => {
                history.push(`/admin/storyz/${sid}`);
              }}
            >
              Storyz view
            </Link>{" "}
            or
            <Link
              onClick={() => {
                history.push(`/admin/organization`);
              }}
              className="ml-1"
            >
              Organization view.
            </Link>
          </div>
          <div className="p-4">
            <ToolkitProvider
              keyField="id"
              data={getRsvpList.length == 0 ? [] : getRsvpList}
              columns={rsvpColumn}
              search
            >
              {(props) => (
                <>
                  <GuestEventWrapper className="pb-4">
                    <Row>
                      <Col lg={4} md={4}>
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter"
                          style={{ width: "100%" }}
                        >
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/monogram-circular.svg")
                                .default
                            }
                            width="30px"
                            style={{ marginRight: "10px" }}
                          />
                          <SearchBar
                            className="form-control"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </div>
                      </Col>
                      <Col lg={5} md={5}>
                        <Row>
                          <label
                            className="form-control-label mb-0"
                            htmlFor="exampleFormControlSelect1"
                            style={{ padding: "12px" }}
                          >
                            Filter by :
                          </label>

                          <FormGroup
                            style={{ width: "129px" }}
                            className="mb-0"
                          >
                            <Input
                              id="exampleFormControlSelect1"
                              type="select"
                              onChange={(e) => getRsvp(e.target.value)}
                            >
                              <option value="All Guest">All Guest</option>
                              <option value="PENDING">Pending</option>
                              <option value="GOING">Going</option>
                              <option value="NOT GOING">Not Going</option>
                              <option value="ATTENDED">Attended</option>
                            </Input>
                          </FormGroup>
                        </Row>
                      </Col>
                      {/* <Col
                        lg={3}
                        md={12}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <div className="guestUserWrap">
                          <Link to="" className="addGuest">
                            <img
                              alt="Organization Name"
                              src={require("assets/img/rsvps-icon.svg").default}
                              className="icon"
                            />
                            <div className="whiteSpace">Combined RSVPS</div>
                          </Link>

                          <div className="addGuest" onClick={handleResetRsvps}>
                            <img
                              alt="Organization Name"
                              src={require("assets/img/recycle.svg").default}
                              className="icon"
                            />
                            <div className="whiteSpace">Reset RSVPs</div>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                  </GuestEventWrapper>

                  <CombinedGuestPool className="pb-4">
                    <div className="rsvpTableWrapper">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        pagination={pagination}
                      />
                    </div>
                  </CombinedGuestPool>
                </>
              )}
            </ToolkitProvider>
            <p className="fontSize14">
              This dialog lets you change the RSVP status of a guest manually,
              independent of any invitations.
            </p>
          </div>
          <Modal
            isOpen={deleteGuestGroup}
            toggle={handleDeleteGuestGroup}
            {...args}
            className="deleteGuestGroupModal"
          >
            <ModalBody className="pb-0">
              <div className="deleteGuestGroupHeader d-flex align-items-center border-bottom mb-4 pb-2 ">
                <img
                  src={GuestGroupIcon}
                  alt=""
                  className="deleteGuestGroupInfoIcon mr-2 mb-0"
                />
                <div className="head">Remove this Guest Group?</div>
              </div>
              <Row>
                <Col>
                  <div className="deleteGuestGroupThumWrapper">
                    <div className="deleteGuestGroupThum">
                      <img
                        src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                        alt=""
                        className="deleteGuestGroupThumImg"
                      />
                    </div>
                    <div className="deleteGuestGroupThumContent">
                      <div className="deleteGuestGroupthumbTitle">
                        Guest Group Name
                      </div>
                      <div className="deleteGuestGroupthumbSubTitle">
                        Role in Event
                      </div>
                    </div>
                  </div>

                  <div className="repeateventDetails pl-0">
                    <div>
                      <img
                        className="evimages ml-0"
                        src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                        alt=""
                      />
                    </div>
                    <div className="repeateventTitle">
                      <div>Event Name</div>
                      <div className="repeateventlocation">Location</div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col>
                  <p>
                    Confirm that you want to remove this guest group. This will
                    NOT affect the Guest List, invites or RSVPs.
                  </p>
                  <p>
                    Guests will remain in another groups they are assigned to.
                    If they are not part of any other group, they will be listed
                    as individual in the guest list.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button color="primary blueBtn" className="flex-grow-1 ">
                Remove Guest from Group
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handleDeleteGuestGroup}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Eventstyle>
    </>
  );
};

export default EventInviteRSVPsList;
