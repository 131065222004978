import React, { useEffect } from "react";
import Allheader from "../../components/Headers/AllheaderEvent";
import { useParams } from "react-router-dom";
import KeepsakeAlbumList from "components/Events/KeepsakeAlbum";
import Imggroup from "../../assets/img/dummyImg.svg";

import { eventsService } from "../../_services/masters/events.service";
function EventKeepsakeAlbum() {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const params = useParams();
  const [eventList, seteventList] = React.useState([]);

  React.useEffect(() => {
    const id = { event_id: params.eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [params]);


  return (
    <>
      <Allheader
        title={
          eventList.storyz_name ? eventList.storyz_name : "Event Keepsake Album"
        }
        imgScr={eventList.project_image ? eventList.project_image : Imggroup}
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <KeepsakeAlbumList name={eventList.event_name} />
        </div>
      </div>
    </>
  );
}
export default EventKeepsakeAlbum;
