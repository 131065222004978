import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const GlobalPoolDropdown = ({ guestListOption, placeholder }) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef();
  // console.log("useRef ..", selectMenuRef);

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };
  const closeDropdown = () => {
    setSelectMenu(false);
  };
  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <div className="dropdownPlaceholder">
          <div className="addGuest">
            <img
              src={require("assets/img/guestlist.svg").default}
              alt=""
              width={30}
            />
            <div className="nowrap">Add from Global Pool</div>
          </div>
          {/* <div className="imageeventim234">
            <span>{placeholder}</span>
          </div> */}
        </div>
      </div>
      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div>
            {/* <p className="AddGestUser">
              Add <FaPlus className="icon" />{" "}
            </p> */}
          </div>
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              value={inputValue}
              onChange={(e) =>
                setInputValue(e.target.value.toLowerCase().replace(/^\s+/g, ""))
              }
            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {guestListOption
              .filter(
                (option) =>
                  option.title.toLowerCase().includes(inputValue) ||
                  option.type.toLowerCase().includes(inputValue)
              )
              .map((option, i) => (
                <li className="menuItem" key={i}>
                  <Link
                    onClick={() => {
                      option.handleClick(option.guest_id);
                    }}
                  >
                    <div className="guestList" onClick={closeDropdown}>
                      <div className="guestUserDetails">
                        <img
                          src={option.guest_img}
                          alt={option.title}
                          className="Avatar"
                        />
                        {option.title}
                      </div>
                      <div className="guestType">{option.type}</div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GlobalPoolDropdown;
