import React, { useEffect, useRef, useState } from "react";
import AuthFooter from "components/Footers/AuthFooter.js";
import { useDispatch, useSelector } from "react-redux";
import { informationService } from "../_services/masters/information.service";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../store/actionTypes";
import { GrCircleInformation } from "react-icons/gr";
import { userAdminData } from "store/actions";
import { TailSpin } from "react-loader-spinner";
function OrganizationList({ aa }) {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const dispatch = useDispatch();
  const history = useHistory();

  const searchRef = useRef();
  const contact = useSelector((state) => {
    return state.userAdmin.listData;
  });
  const [organizationlist, setOrganizationlist] = useState(
    contact?.orgList ? contact?.orgList : []
  );
  const logOut = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userdata");
    localStorage.removeItem("userAdmintoken");

    dispatch({ type: actionTypes.CLEAR_USER_ADMIN_INFO, payload: "" });
    history.push("/");
  };

  useEffect(() => {
    informationService.acInformationadmin().then(
      (res) => {
        if (res.status === "success") {
          const value = {
            ...contact,
            orgList: res.data,
          };
          dispatch(userAdminData(value));
          setOrganizationlist(res.data);
        } else {
          //history.push("/");
        }
      },
      (error) => {
        // history.push("/");
      }
    );
  }, []);
  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }
  function openAccount(userData) {
    setIsLoading(userData);

    informationService.autoLogin(userData).then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userDetails", JSON.stringify(res.data));
          userdetail();
          setIsLoading(null);
          // setTimeout(() => {
          //   userdetail();
          //   setIsLoading(null);
          // }, 5000);
        } else {
          history.push("/");
          setIsLoading(null);
        }
      },
      (error) => {
        history.push("/");
        setIsLoading(null);
      }
    );
  }
  const onSearch = (e) => {
    setSearchValue(e.target.value);
    // setOrganizationlist(organizationlist.filter((option) => option.company_name.includes(searchValue)));
  };

  const getOptions = () => {
    if (!searchValue) {
      return organizationlist;
    }
    return organizationlist.filter(
      (option) =>
        option.company_name.toLowerCase().indexOf(searchValue.toLowerCase()) >=
        0
    );
  };

  return (
    <>
      <div className="main-content organizationListWrapper">
        <div className="organizationInnerWrapper">
          {/* <div className="organizationListTop">
            <div className="organizationListLogo">
              <img
                alt="OurStoryz"
                src={require("assets/img/icons/common/Logomark.svg").default}
                className="img"
              />
              OurStoryz
            </div>
            <div className="organizationListSuperUser">Superuser Login</div>
          </div> */}
          {/* <div className="organizationListheader">
            <img
              alt="OurStoryz"
              src={require("assets/img/organizationLogo.svg").default}
              className="img"
            />
            Organization List
          </div> */}
          <div className="organizationListHeadSec border-bottom">
            <div className="organizationListHead">
              <img
                alt="OurStoryz"
                src={require("assets/img/adminAccountIcon.svg").default}
              />
              Accounts in the System
            </div>
            <GrCircleInformation className="ml-auto iconColor infoToolTip" />
          </div>
          <p>
            To access events, select a Storyz, or use the “Events” side menu.
          </p>
          <div className="organizationListSelectAccount">
            Select an user account in OurStoryz
          </div>
          <div className="organizationListSearchWrapper">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="type to search..."
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
              />
            </div>
            <div className="organizationListSearch">
              {/* <div className="organizationListSearchList active">
                <div className="avatarCardBox">
                  <div className="avatarCardPic">
                    <img
                      alt="OurStoryz"
                      src={require("assets/img/organizationLogos1.svg").default}
                      className="pic"
                    />
                  </div>
                  <div className="avatarCardBody">
                    <div className="title">Organization Name</div>
                    <div className="subTitle">Service Level </div>
                  </div>
                </div>
                <button type="button" className="openAccountBtn">
                  Open Account
                </button>
              </div> */}
              {loading ? (
                <>
                  <div className="loding">
                    <TailSpin
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                </>
              ) : (
                <>
                  {organizationlist.length &&
                    getOptions().map((x, i) => (
                      <div className="organizationListSearchList" key={i}>
                        <div className="avatarCardBox">
                          <div className="avatarCardPic">
                            <img
                              alt="OurStoryz"
                              src={
                                x.logo
                                  ? x.logo
                                  : require("assets/img/organizationLogos1.svg")
                                    .default
                              }
                              className="pic"
                            />
                          </div>
                          <div className="avatarCardBody">
                            <div className="title">
                              {x.company_name}
                              {x.service_level}
                            </div>
                            <div className="subTitle">{x.service_level}</div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="openAccountBtn d-flex align-items-center justify-content-center"
                          disabled={isLoading === x.user_id}
                          onClick={() => {
                            openAccount(x.user_id);
                          }}
                        >
                          Open Account
                          {isLoading === x.user_id ? (
                            <div className="loding ml-2">
                              <TailSpin
                                height="16"
                                width="16"
                                color="#1d192b"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          <div className="logOutSec" onClick={logOut}>
            <img
              alt="OurStoryz"
              src={require("assets/img/superUserIcon.svg").default}
              className="img"
            />
            Superuser Log Out from System
          </div>
          {/* <p onClick={logOut}>LogOut</p> */}
        </div>
      </div>
      <AuthFooter />
    </>
  );
}

export default OrganizationList;
