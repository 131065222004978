import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Row, Col } from "reactstrap";
// import { CombinedGuestPool } from "./style";
// import FileImg from "./../../../assets/img/file-listings.png";
import DummyImg from "../../../assets/img/dummyImg.svg";
import { Link } from "react-router-dom";
// import Global from "../../../_helpers/global"
import { GrCircleInformation } from "react-icons/gr";
import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import DesktopPreview from "./../../../assets/img/desktopPreview.png";
import MobilePreview from "./../../../assets/img/mobilePreview.png";
import AppStore from "../../../assets/img/app-store-download.png";
import GooglePlay from "../../../assets/img/google-play-download.png";
import CloseCircle from "./../../../assets/img/close-circle.svg";
import SocialMediaLinkIcon from "./../../../assets/img/socialMediaLinkIcon.svg";
import WebSocialMedia from "../../../components/model-form/web-social-media";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import CustomButton from "components/Button/CustomButton";
import { informationService } from "../../../_services/masters/information.service";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";
const StoryzWebListingDetailsNew = () => {
  const [webAccess, setWebAccess] = useState(false);
  const [desktopWebAccess, setDesktopWebAccess] = useState(false);
  const [mobileWebAccess, setMobileWebAccess] = useState(false);

  const [socialMediaLink, setSocialMediaLink] = useState(false);
  const [createSocialMediaLink, setCreateSocialMediaLink] = useState(false);
  //Rohit Start here
  const [WebsiteVisible, setWebsiteVisible] = useState("checked");
  const [appdownload, setAppdownload] = useState("checked");
  const [url, setUrl] = useState("custom_url");
  const [custom_website, setCustomWebsite] = useState("");
  const [cmErr, setCWRrr] = useState("");
  const [default_website, setdefaultWebsite] = useState("");
  const [rssvp_value, setRsvp] = useState("");
  const [rsvpErr, setRsvpErr] = useState("");
  const [name, setName] = useState(null);
  const [img, setImg] = useState(null);
  const [date, setDate] = useState(null);
  const [role, setRole] = useState(null);
  const [greeting_title, setGreetingTitle] = useState(null);
  const [hosted, setHosted] = useState(null);
  const [stDate, setStartDate] = useState(null);
  const [event_count, setEventCount] = useState(0);
  const [demo_sm, setDemoSM] = useState([]);
  const [Facebook, setFacebook] = useState(null);
  const [Instagram, setInstagram] = useState(null);
  const [Twitter, setTwitter] = useState(null);
  const [Tiktok, setTiktok] = useState(null);
  const [Youtube, setYoutube] = useState(null);
  const [Vimeo, setVimeo] = useState(null);
  const [ev_location, setEVlocation] = useState("");

  const [eID, setEditID] = useState(null);
  // const [weblistData, setWebList] = useState(null);
  const [socialMe, setSocialMe] = useState([]);
  const params = useParams();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  let first_name = userdata.first_name != null ? userdata.first_name : "";
  let last_name = userdata.last_name != null ? userdata.last_name : "";
  let uid = userdata.user_id;
  let full_user_name = "";
  if (first_name == "") {
    last_name = last_name.toLowerCase();
    full_user_name = `${last_name}-${uid}`;
  } else if (last_name == "") {
    first_name = first_name.toLowerCase();
    full_user_name = `${first_name}-${uid}`;
  } else {
    last_name = last_name.toLowerCase();
    first_name = first_name.toLowerCase();
    full_user_name = `${first_name}-${last_name}-${uid}`;
  }

  const addElement = (e) => {
    setSocialMe(e);
  };

  const defaultURL = `https://ourstoryz.avdemosites.com/web-listing/${full_user_name}/${params.sid}`;
  //console.log('defaultURL', defaultURL);
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  //return regex.test(URL);
  // End here
  const saveData = () => {
    let data = {
      storyz_id: params.sid,
      app_download: appdownload,
      website_visible: WebsiteVisible,
      custom_url: url,
      link_to_registry: default_website,
      custom_website: custom_website,
      rsvp_link: rssvp_value,
      social_media: socialMe,
      id: eID,
    };
    informationService.saveStoryzData(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message);
          getWebList();
          getShortStoryzDetails();
        } else {
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );
  };

  const getShortStoryzDetails = () => {
    let data = { storyz_id: params.sid };
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setHosted(userDetails.name);
    informationService.getShortStoryzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setName(res.data.storyz_name);
          setImg(res.data.project_image);
          setDate(res.data.event_start_date);
          setGreetingTitle(res.data.greeting_title);
          //toast.success(res.message);
        } else {
          //toast.error("Something went wrong !!");
        }
      },
      (error) => {
        //toast.error("Something went wrong !!");
      }
    );
  };

  const getWebList = () => {
    let data = { storyz_id: params.sid };
    informationService.getWebList(data).then(
      (res) => {
        if (res.status === "success") {
          //setWebList(res.data);

          let start_date = res.data.start;
          let end_date = res.data.end;
          let s_d = "";
          let s_m = "";
          let s_y = "";
          let e_d = "";
          let e_m = "";
          let e_y = "";
          if (start_date != 0) {
            s_d = moment(new Date(start_date)).format("DD");
            s_m = moment(new Date(start_date)).format("MMM");
            s_y = moment(new Date(start_date)).format("YYYY");
          }
          if (end_date != 0) {
            e_d = moment(new Date(end_date)).format("DD");
            e_m = moment(new Date(end_date)).format("MMM");
            e_y = moment(new Date(end_date)).format("YYYY");
          }
          let finalDate = "NA";
          if (s_m == e_m && s_y == e_y) {
            finalDate = `${s_m} ${s_d} - ${e_d},  ${e_y}`;
          } else if (s_m != e_m && s_y == e_y) {
            finalDate = `${s_m} ${s_d} - ${e_m} ${e_d},  ${e_y}`;
          } else if (s_m != e_m && s_y != e_y) {
            finalDate = `${s_m} ${s_d}, ${s_y} - ${e_m} ${e_d},  ${e_y}`;
          } else if (s_m != e_m && s_y == e_y) {
            finalDate = `${s_m} ${s_d} - ${e_m} ${e_d},  ${e_y}`;
          } else if (s_m == e_m && s_y != e_y) {
            finalDate = `${s_m} ${s_d},  ${e_y} - ${e_m} ${e_d},  ${e_y}`;
          } else {
            finalDate = `${s_m} ${s_d}, ${s_y} - ${e_m} ${e_d},  ${e_y}`;
          }
          setStartDate(finalDate);

          let swl = res.data.social_media;
          swl = swl != null && swl != "null" && swl != "" ? swl : [];
          for (let index = 0; index < swl.length; index++) {
            let key = swl[index].key;
            let val = swl[index].value;
            if (key == "Facebook") {
              setFacebook(key);
            }
            if (key == "Instagram") {
              setInstagram(key);
            }
            if (key == "Twitter") {
              setTwitter(key);
            }
            if (key == "Tiktok") {
              setTiktok(key);
            }
            if (key == "Youtube") {
              setYoutube(key);
            }
            if (key == "Vimeo") {
              setVimeo(key);
            }
          }
          localStorage.setItem("web_social_media", JSON.stringify(swl));
          setWebsiteVisible(res.data.website_visible);
          setAppdownload(res.data.app_download);
          setUrl(res.data.custom_url);
          setdefaultWebsite(res.data.link_to_registry);
          setCustomWebsite(res.data.custom_website);
          setRsvp(res.data.rsvp_link);
          setSocialMe(res.data.social_media);
          setEditID(res.data.id);
          setEventCount(res.data.event_count);
          setEVlocation(res.data.ev_location);
          //toast.success(res.message);
        } else {
          setEditID(null);
          //toast.error("Something went wrong !!");
        }
      },
      (error) => {
        //toast.error("Something went wrong !!");
      }
    );
  };

  const handelWebAccess = () => setWebAccess(!webAccess);
  const handeldesktopWebAccess = () => setDesktopWebAccess(!desktopWebAccess);
  const handelemobileWebAccess = () => setMobileWebAccess(!mobileWebAccess);

  const handleSocialMediaLink = () => {
    getShortStoryzDetails();
    setSocialMediaLink(!socialMediaLink);
    // console.log("dsdsd");
  };
  const handleCreateSocialMediaLink = () => {
    setCreateSocialMediaLink(!createSocialMediaLink);
    //setSocialMediaLink(false)
  };
  const handleCreateSocialMediaLinkClose = () => {
    setCreateSocialMediaLink(false);
    setSocialMediaLink(true);
  };

  const handleSocialMediaadd = () => {
    handleSocialMediaLink(false);
    //  add(socialMe);
  };

  //Rohit
  const websiteVisible = (event) => {
    setWebsiteVisible(event.target.checked == true ? "checked" : "");
  };

  const appDownload = (event) => {
    setAppdownload(event.target.checked == true ? "checked" : "");
  };

  const onChangeValue = (e) => {
    setUrl(e.target.value);
  };
  const customWebsite = (e) => {
    let isUrl = regex.test(e.target.value);
    setCWRrr(isUrl == false ? "please enter a valid url" : "");
    setCustomWebsite(e.target.value);
  };

  const defaultWebsite = (e) => {
    let isUrl = regex.test(e.target.value);
    setCWRrr(isUrl == false ? "please enter a valid url" : "");
    setdefaultWebsite(e.target.value);
  };

  const rsvp = (e) => {
    let isUrl = regex.test(e.target.value);
    setRsvpErr(isUrl == false ? "please enter a valid url" : "");
    setRsvp(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem("web_social_media", "");
    getWebList();
    getShortStoryzDetails();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={webAccess}
        toggle={handelWebAccess}
        className="webAccessModal"
      >
        <ModalHeader className="border-bottom">
          <img
            alt="Organization Name"
            src={require("assets/img/Icon-Text-in-Circle-story.svg").default}
            className=""
          />
          Websites and Social Media
        </ModalHeader>
        <ModalBody>
          <div className="border-bottom py-4 webAccessWebsiteBox">
            <div>
              You can define a custom website for your event, and link to social
              media you’ve created for your Storyz
            </div>
            <div className="text-center webAccessWebsiteImg">
              <img
                src={require("assets/img/websiteIcon1.svg").default}
                alt=""
              />
            </div>
          </div>
          <div className="border-bottom py-4">
            To add a custom website, just type in its address.
          </div>
          <div className="border-bottom py-4">
            <p>
              To add social media, type in the address of your site in the
              social media service.
            </p>
            To add social media, type in the address of your site in the social
            media service.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="blueBtn w-100" onClick={handelWebAccess}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={desktopWebAccess}
        toggle={handeldesktopWebAccess}
        className="desktopWebAccessModal"
      >
        <ModalHeader toggle={handeldesktopWebAccess}></ModalHeader>
        <ModalBody>
          <div className="webListingDesktopOuter">
            <div className="desktopWebAccessModal webListingDesktopWrapper">
              <div className="webListingImg">
                <img src={img == "" ? DummyImg : img} alt="" />
                {event_count} Events
              </div>
              <div className="webListingContent">
                <div className="title">{name}</div>
                <div>
                  {stDate} • {ev_location}
                </div>
                <div>
                  <span>Hosted by:</span> {hosted}
                </div>
                <div>{greeting_title}</div>
              </div>
            </div>
            <div className="desktopWebStoreWrapper">
              <div className="socialIconWrapper">
                <img
                  src={require("assets/img/websiteIcon.svg").default}
                  alt=""
                />
                {Instagram == "Instagram" ? (
                  <Link to="/" className="socialIcon">
                    <img src={InstagramIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Facebook == "Facebook" ? (
                  <Link to="/" className="socialIcon">
                    <img src={FacebookIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}

                {Twitter == "Twitter" ? (
                  <Link to="/" className="socialIcon">
                    <img src={TwitterIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}

                {Tiktok == "Tiktok" ? (
                  <Link to="/" className="socialIcon">
                    <img src={TiktokIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Youtube == "Youtube" ? (
                  <Link to="/" className="socialIcon">
                    <img src={YoutubeIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Vimeo == "Vimeo" ? (
                  <Link to="/" className="socialIcon">
                    <img src={VimeoIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="webAppStore">
                <a href={rssvp_value} target="_blank">
                  RSVP
                </a>
                <img src={AppStore} alt="" />
                <img src={GooglePlay} alt="" />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={mobileWebAccess}
        toggle={handelemobileWebAccess}
        className="desktopWebAccessModal mobileWebAccessModal"
      >
        <ModalHeader toggle={handelemobileWebAccess}></ModalHeader>
        <ModalBody>
          <div className="webListingDesktopOuter">
            <div className="webListingDesktopWrapper">
              <div className="webListingImg">
                <img src={img == "" ? DummyImg : img} alt="" />
                {event_count} Events
              </div>
              <div className="webListingContent">
                <div className="title">{name}</div>
                <div>
                  {stDate} • {ev_location}
                </div>
                <div>
                  <span>Hosted by:</span> {hosted}
                </div>
                <div>{greeting_title}</div>
              </div>
            </div>
            <div className="desktopWebStoreWrapper">
              <div className="socialIconWrapper">
                <img
                  src={require("assets/img/websiteIcon.svg").default}
                  alt=""
                />
                {Instagram == "Instagram" ? (
                  <Link to="/" className="socialIcon">
                    <img src={InstagramIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Facebook == "Facebook" ? (
                  <Link to="/" className="socialIcon">
                    <img src={FacebookIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}

                {Twitter == "Twitter" ? (
                  <Link to="/" className="socialIcon">
                    <img src={TwitterIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}

                {Tiktok == "Tiktok" ? (
                  <Link to="/" className="socialIcon">
                    <img src={TiktokIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Youtube == "Youtube" ? (
                  <Link to="/" className="socialIcon">
                    <img src={YoutubeIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
                {Vimeo == "Vimeo" ? (
                  <Link to="/" className="socialIcon">
                    <img src={VimeoIcon} alt="" />
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="webAppStore">
                <a href={rssvp_value} target="_blank">
                  RSVP
                </a>
                <img src={AppStore} alt="" />
                <img src={GooglePlay} alt="" />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div>
        {/* <div className="AdDLiSt_BuTtON webListingUpdateBtn" onClick={saveData}>
          <CustomButton>Save Information</CustomButton>
        </div> */}
        <div className="headingWrapper borderBottom">
          <h4 className="heading-1">Web Access</h4>

          <GrCircleInformation
            className="ml-auto iconColor infoToolTip pointer"
            onClick={handelWebAccess}
          />
        </div>
        <div className="organizationPara p-4">
          This view lets you specify information included in the web listing for
          your Storyz.
        </div>
        <div className="d-flex mb-4">
          <div onClick={saveData} className="ml-auto m12-right">
            <GlobalButton
              customClass="ml-auto"
              img={require("assets/img/hornIcon.svg").default}
              type="submit"
            >
              Save Changes
            </GlobalButton>
          </div>
          {/* <DeleteBtn icon> Delete Album </DeleteBtn> */}
        </div>
        <Row className="addGuestUerFormWrapper webAccessInfoWrapper">
          <Col md={12} lg={6}>
            <div>
              <div className="basicinformation">
                <img
                  src={require("assets/img/adminAccountIcon.svg").default}
                  alt=""
                />
                App startup screen for Storyz
              </div>
              <div className="cardBox">
                <p>
                  The OurStoryz app will display your organization logo during
                  startup, and only show events created by your organization.
                </p>

                <div className="custom-control form-check form-check-inline disabledNew">
                  <label className="container3 form-check-label">
                    Make App downloads specific to our Organization
                    <input
                      type="checkbox"
                      name="check"
                      defaultChecked={appdownload == "checked" ? "checked" : ""}
                      onChange={(e) => appDownload(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div className="basicinformation">
                <img
                  src={require("assets/img/adminAccountIcon.svg").default}
                  alt=""
                />
                Web Content
              </div>
              <div className="cardBox">
                <p>
                  You can use the default website listing, or specify your own
                  website.
                </p>

                <div className="custom-control form-check form-check-inline formBox">
                  <label className="container3 blackColor mb-0 fontSize14 fontWeight300">
                    Website is visible online
                    <input
                      type="checkbox"
                      name="check"
                      defaultChecked={
                        WebsiteVisible == "checked" ? "checked" : ""
                      }
                      onChange={(e) => websiteVisible(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div
                  className={WebsiteVisible == "checked" ? "" : "disabledNew"}
                >
                  <div className="formBox">
                    <div className="inlineFormBox webListingCustomUrl">
                      <div className="form-check form-check-inline">
                        <input
                          className={
                            WebsiteVisible == "checked"
                              ? "form-check-input"
                              : "form-check-input disabledNew"
                          }
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="custom_url"
                          checked={url == "custom_url"}
                          onChange={(e) => onChangeValue(e)}
                          disabled={WebsiteVisible == "checked" ? false : true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Custom URL
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className={
                            WebsiteVisible == "checked"
                              ? "form-check-input"
                              : "form-check-input disabledNew"
                          }
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="default_url"
                          checked={url == "default_url"}
                          onChange={(e) => onChangeValue(e)}
                          disabled={WebsiteVisible == "checked" ? false : true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Use OurStoryz Default
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      url == "custom_url" && WebsiteVisible == "checked"
                        ? "formBox"
                        : "formBox disabledNew"
                    }
                  >
                    <label className="form-label">Custom Website</label>
                    <div className="inlineFormBox">
                      <img
                        src={require("assets/img/websiteIcon.svg").default}
                        alt=""
                      />
                      <input
                        className={
                          url == "custom_url" && WebsiteVisible == "checked"
                            ? "form-control"
                            : "form-control disabledNew"
                        }
                        type="text"
                        value={
                          url == "custom_url" && WebsiteVisible == "checked"
                            ? custom_website
                            : defaultURL
                        }
                        placeholder="ourstoryz.com/something"
                        onChange={(e) => customWebsite(e)}
                        readOnly={
                          url == "custom_url" && WebsiteVisible == "checked"
                            ? false
                            : true
                        }
                      />
                    </div>
                    <p style={{ color: "red" }}>
                      {custom_website != "" && url == "custom_url" ? cmErr : ""}
                    </p>
                  </div>
                  <div
                    className={
                      url == "default_url" && WebsiteVisible == "checked"
                        ? "formBox"
                        : "formBox disabledNew"
                    }
                  >
                    <label className="form-label">Link to Registry</label>
                    <div className="inlineFormBox">
                      <img
                        src={require("assets/img/registryIcon.svg").default}
                        alt=""
                      />
                      <input
                        className={
                          url == "default_url" && WebsiteVisible == "checked"
                            ? "form-control"
                            : "form-control disabledNew"
                        }
                        type="text"
                        value={default_website}
                        placeholder="example.com"
                        onChange={(e) => defaultWebsite(e)}
                        readOnly={
                          url == "default_url" && WebsiteVisible == "checked"
                            ? false
                            : true
                        }
                      />
                    </div>
                    <p style={{ color: "red" }}>
                      {default_website != "" && url == "default_url"
                        ? cmErr
                        : ""}
                    </p>
                  </div>

                  <div className="formBox">
                    <label className="form-label">Link to Web-Based RSVP</label>
                    <div className="inlineFormBox">
                      <img
                        src={require("assets/img/rsvpIcon.svg").default}
                        alt=""
                      />
                      <input
                        className={
                          WebsiteVisible == "checked"
                            ? "form-control"
                            : "form-control disabledNew"
                        }
                        type="text"
                        value={rssvp_value}
                        placeholder="https://rsvpify.com"
                        onChange={(e) => rsvp(e)}
                        readOnly={WebsiteVisible == "checked" ? false : true}
                      />
                    </div>
                    <p style={{ color: "red" }}>
                      {WebsiteVisible == "checked" && rssvp_value != ""
                        ? rsvpErr
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div>
              <WebSocialMedia
                add={addElement}
                name={name != null ? name : null}
                img={img != null ? img : DummyImg}
                role={role != null ? role : null}
                date={date != null ? date : null}
              //social_Me={ socialMe.length > 0 ? socialMe : [] }
              />
            </div>
            <div>
              <div className="basicinformation">
                <img src={require("assets/img/computer.svg").default} alt="" />
                Web Search Previews
              </div>
              <div className="cardBox">
                <p>
                  Select the thumbnails below to see how your Events will appear
                  when your guests search for their event on the ourstoryz.com
                  website
                </p>
                <div className="webSearchPreviewBox">
                  <div className="webSearchPreviewList">
                    <div className="title">Desktop</div>
                    <img
                      src={DesktopPreview}
                      alt=""
                      onClick={handeldesktopWebAccess}
                      className="pointer"
                    />
                  </div>
                  <div className="webSearchPreviewList">
                    <div className="title">Mobile</div>
                    <img
                      src={MobilePreview}
                      alt=""
                      className="pointer"
                      onClick={handelemobileWebAccess}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StoryzWebListingDetailsNew;
