import React from "react";
import Radio from "../Radio";
import classNames from "./RadioGrouo.module.scss";

const RadioGroup = ({ title, options, value, onChange, onClick }) => {
  const innerOnChange = (value) => (checked) => {
    if (checked) {
      onChange(value);
      onClick(value);
    }
  };

  return (
    <div className={classNames.group}>
      {title && <span className={classNames.group__title}>{title}</span>}
      <div className={classNames.group__options}>
        {options.map((option) => (
          <Radio
            key={option.id}
            label={option.label}
            checked={option.value === value}
            onChange={innerOnChange(option.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
