import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import placeholde from "../../assets/img/placeholder.jpg";
import { IoMdClose } from "react-icons/io";
import { userGuestService } from "_services/masters/userGuest.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
const EventGuestDropdownEdit = ({
  guestListOption,
  rowData,
  eid,
  gid,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [eventItems, setEventItems] = useState([]);
  const [eventList, setEventList] = useState([]);
  const selectMenuRef = useRef();
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };
  useEffect(() => {
    setEventItems(rowData);
  }, [rowData]);
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  useEffect(() => {
    setEventList(guestListOption);
  }, [guestListOption]);

  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);

      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const guestPrefixOption = () => {
    if (!searchValue) {
      return eventList;
    }

    return eventList.filter(
      (option) =>
        option.event_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const onAdd = (product) => {
    const index = eventItems.findIndex((item) => item.id === product.id);

    if (index > -1) {
    } else {
      let da2 = [...eventItems, { ...product }];
      setEventItems(da2);

      var arr = [];
      da2?.map((track) => arr.push(track.id));

      let obj = {};
      obj["user_id"] = gid;
      obj["type"] = "guest_event";
      obj["drop_down_data"] = arr;

      //console.log('roleItems onAdd --> ', obj);

      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status === "success") {
            toast.success("updated successfully");
            sessionStorage.removeItem("row_id");
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };
  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    // console.log('hello');
    // console.log(eventItems);
    // var arr = [];
    // let ids = eventItems?.map((track) => arr.push(track.id));
    // onChange(arr);
    // if (sessionStorage.getItem("row_id")) {
    //   let obj = {};
    //   obj['user_id'] = sessionStorage.getItem("row_id");
    //   obj['type'] = 'guest_event';
    //   obj['event_id'] = Eid;
    //   obj['drop_down_data'] = arr;
    //   console.log(obj);
    //   //console.log('roleItems onAdd --> ', obj);
    //   userGuestService.updateDropDown(obj).then(
    //     (res) => {
    //       if (res.status == "success") {
    //         //setCount(200)
    //         sessionStorage.removeItem("row_id");
    //       } else {
    //         console.log("Something went wrong !!");
    //         //toast.error(res.message);
    //       }
    //     },
    //     (error) => {
    //       console.log("Something went wrong !!");
    //       //toast.error("Something went wrong !!", "Fashion Store");
    //     }
    //   );
    // }
  }, [eventItems]);
  const iclose = (e) => {
    var data = {
      guest_id: gid,
      event_id: e.id,
    };

    userGuestService.deletesingleEvent(data).then((res) => {
      if (res.status === "success") {
        // roleall();
        const newPeople = eventItems.filter((person) => person.id !== e.id);
        setEventItems(newPeople);

        toast.success("Delete successfully");
      } else {
        console.log("error");
      }
    });
  };

  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer d-flex flex-wrap">
            {eventItems?.map((event, i) => {
              return (
                <div
                  className="inviteGuestList d-flex align-items-center"
                  key={i}
                >
                  <Link to={`/admin/event-dashboard/${common?.sid}/${event.id}`} >
                    <img
                      src={event?.cover_image ? event?.cover_image : placeholde}
                      alt=""
                      className="avatar"
                    />
                    {event.event_name}
                  </Link>
                  <IoMdClose
                    className="ml-2 pointer"
                    size={16}
                    onClick={() => iclose(event)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 left ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              onChange={onSearch}
              value={searchValue}

            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {guestPrefixOption()?.length > 0 &&
              guestPrefixOption()?.map((option, i) => (
                <li
                  className="menuItem pointer"
                  key={i}
                  onClick={() => onAdd(option)}
                >
                  <div className="eventThumDropdown">
                    <div className="date">
                      {" "}
                      {option.event_start_date
                        ? moment(option.event_start_date).format("MMM Do YY")
                        : "No Date"}
                    </div>
                    <div className="eventThum">
                      <img
                        alt={option?.event_name}
                        src={
                          option?.cover_image ? option?.cover_image : placeholde
                        }
                        className="eventThumImg"
                      />
                    </div>
                    <div className="eventThumContent">
                      <div className="eventthumbTitle">{option.event_name}</div>
                      <div className="eventthumbSubTitle"></div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventGuestDropdownEdit;
