import React, { useEffect, useState } from "react";
import { Eventstyle } from "./styles";
import { Row, Col, Modal, Button } from "reactstrap";
import { ReactSession } from "react-client-session";

import AdminList from "components/Dropdown/AdminList";
import { informationService } from "../../_services/masters/information.service";
import { userGuestService } from "../../_services/masters/userGuest.service";
import Dropdown from "../Dropdown/Dropdown";
import { eventsService } from "../../_services/masters/events.service";
import format from "date-fns/format";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { timezoneService } from "../../_services/masters/timezone.service";
import Timezoneset from "./time-zone";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Selectbox from "../../components/Inputbox/selectbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Repeatingevents from "./repeatingevents";
import { useSelector, useDispatch } from "react-redux";
import Eventmenu from "./eventMenu.js";
import { GrCircleInformation } from "react-icons/gr";
import * as actionTypes from "../../store/actionTypes";
import {
  InputField,
  CheckboxField,
  TextareaField,
} from "../../components/Inputbox";
import { useForm } from "react-hook-form";
import { commonData } from "store/actions";
import CustomButton from "components/Button/CustomButton";
import { IoMdClose } from "react-icons/io";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";

function Eventabout(props) {
  const seletedImageClike = (e) => {
    let event_id = common?.singleEvent?.id;
    const val1 = [
      {
        id: event_id,
        key_name: "cover_image",
        key_value: e.url,
      },
    ];

    userGuestService.eventUpdate(val1).then((res) => {
      if (res.status === "success") {
        toast.success("update successfully !!", "Account");
        setFilestackImag(e.url);
        localStorage.setItem("todo_event_img", e.url);
        setModalShow(false);
        setDataevent();
      } else {
        toast.error("Something went wrong !!", "error");
      }
    });
  };
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  });
  const [allimage, setAllimage] = useState([]);
  const params = useParams();
  const [selectMenu, setSelectMenu] = React.useState(false);

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };

  const [eventType, setEventType] = React.useState([]);
  const options = eventType;
  const events = useSelector((state) => {
    return state.eventSave.event;
  });

  const contact = useSelector((state) => {
    return state.profile;
  });
  const [defaultModale, setDefaultModale] = React.useState(false);
  const handleClick = () => {
    setDefaultModale(true);
  };

  const [adminDetails, setAdminDetails] = React.useState({});

  const dispatch = useDispatch();
  const [eventInfo, setEventInfo] = React.useState({});

  const [howtochke, setHowtochke] = React.useState(0);
  const [selectboxS, setSelectboxS] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endtDate, setEventEnds] = React.useState();
  const [rsvpDate, setRsvpDate] = React.useState();
  const [filestackImag, setFilestackImag] = React.useState();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const [imgData, setImgData] = React.useState(null);
  const [imgDa, setImgDa] = React.useState(null);
  const [eventName, setEventname] = React.useState(null);

  const [adminid, SetAdminid] = React.useState("");

  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    if (e.target.files[0]) {
      setImgData(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangeinput = (e) => {
    eventInfo[e.targe.name] = e.targe.value;
    setEventInfo({ ...eventInfo });
  };
  useEffect(() => {
    Eventtype();
  }, []);

  function Eventtype() {
    informationService.eventType().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  React.useEffect(() => {
    setDatafor();
  }, []);

  function setDatafor() {

    setValue("event_name", common?.singleEvent?.event_name, {
      shouldTouch: true,
    });
    setValue("project_id", common?.singleEvent?.project_id, {
      shouldTouch: true,
    });
    setValue("hosted_by", common?.singleEvent?.hosted_by, {
      shouldTouch: true,
    });
    setValue("event_description", common?.singleEvent?.event_description, {
      shouldTouch: true,
    });
    setValue(
      "special_instructions",
      common?.singleEvent?.special_instructions,
      { shouldTouch: true }
    );
    setValue("notes", common?.singleEvent?.notes, { shouldTouch: true });
    setValue("is_private", common?.singleEvent?.is_private, {
      shouldTouch: true,
    });
    setHowtochke(common?.singleEvent?.is_private);
    setValue("event_start_date", common?.singleEvent?.event_start_date, {
      shouldTouch: true,
    });
    setValue("event_end_date", common?.singleEvent?.event_end_date, {
      shouldTouch: true,
    });
    setValue("rsvp_deadline", common?.singleEvent?.rsvp_deadline, {
      shouldTouch: true,
    });
    if (common?.singleEvent?.rsvp_deadline) {
      let assigned_date = moment
        .utc(common?.singleEvent?.rsvp_deadline)
        .format("YYYY-MM-DD HH:mm:ss");
      setRsvpDate(moment(assigned_date).toDate());
    } else {
      setRsvpDate("");
    }

    if (common?.singleEvent?.event_start_date) {
      let assigned_date = moment
        .utc(common?.singleEvent?.event_start_date)
        .format("YYYY-MM-DD HH:mm:ss");
      setStartDate(moment(assigned_date).toDate());
    } else {
      setStartDate("");
    }

    if (common?.singleEvent?.event_end_date) {
      let due_date = moment
        .utc(common?.singleEvent?.event_end_date)
        .format("YYYY-MM-DD HH:mm:ss");
      setEventEnds(moment(due_date).toDate());
    } else {
      setEventEnds("");
    }

    setTimeout(() => {
      setSelectboxS({
        value: common?.singleEvent?.event_type,
        id: common?.singleEvent?.event_type,
        label: common?.singleEvent?.event_type_name,
      });
    }, 500);
  }
  const onSubmit = (data) => {

    var dataSend = {
      id: common?.singleEvent?.id,
      event_name: data.event_name,
      project_id: common?.singleEvent?.storyz_id,
      event_type: selectboxS.id,
      hosted_by: data.hosted_by,
      event_description: data.event_description,
      special_instructions: data.special_instructions,
      event_start_date: format(startDate, "MM-dd-yyyy h:mm a"),
      event_end_date: format(endtDate, "MM-dd-yyyy h:mm a"),
      rsvp_deadline: format(rsvpDate, "MM-dd-yyyy h:mm a"),
      is_private: howtochke,
      is_global_rsvp: 1,
      status: 1,
      notes: data.notes,
      organization_id: contact?.profiledetails.organization[0]?.organization_id,
      admin_id: adminid,
    };

    timezoneService.eventUpdate(dataSend).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          dataEventget();

        } else {
          //console.log('Something went wrong !!');
          toast.error("Something went wrong !!", "Error");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        toast.error("Something went wrong !!", "Error");
        //console.log('Something went wrong !!');
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  function dataEventget() {
    const id = { event_id: common?.singleEvent?.id };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {



          const value = {
            ...common,
            singleEvent: res.data,
          };
          dispatch(commonData(value));

        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.EVENTS_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function Repeatingfun(event_id) {
    let eventId = event_id;
    //var formdata = new FormData();
    //formdata.append("is_repeating_event", parseInt(events?.repeating_events.repeating_event));
    //formdata.append("repeats_every", events?.repeating_events.repeat_event);
    //formdata.append("custom_days", parseInt(events?.repeating_events.custom));
    //formdata.append("repeating_day", events?.repeating_events.dayofrepeat);
    //formdata.append("repeats_until", events?.repeating_events.until);
    //formdata.append("eventId", parseInt(eventId));

    var formdata = {
      is_repeating_event: parseInt(events?.repeating_events.repeating_event),
      repeats_every: events?.repeating_events.repeat_event,
      custom_days: parseInt(events?.repeating_events.custom),
      repeating_day: events?.repeating_events.dayofrepeat,
      repeats_until: events?.repeating_events.until,
      eventId: parseInt(eventId),
    };
    //console.log(formdata);
  }

  const onParentClick = (e) => {
    const data = {
      contact_id: e.to_id,
      event_id: common?.singleEvent?.id,
    };

    SetAdminid(e.to_id);

    setAdminDetails({
      account_type: e?.account_type,
      email: e?.email,
      username: "",
      first_name: e?.first_name,
      last_name: e?.last_name,
      imageUrl: e?.imageUrl,
    });

    userGuestService.assignAdmin(data).then(
      (res) => {
        if (res.status === "success") {
        } else {
          //console.log('Something went wrong !!');
          toast.error("Something went wrong !!", "Error");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        toast.error("Something went wrong !!", "Error");
        //console.log('Something went wrong !!');
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const [modalShow, setModalShow] = React.useState(false);
  const handleClickimag = () => {
    setModalShow(false);
  };
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    let event_id = common?.singleEvent?.id;
    const data = {
      type: "event",
      event_id: event_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateEventCoverImage(data).then(
      (res) => {
        if (res.status === "success") {
          setFilestackImag(jsonData[0].url);
          localStorage.setItem("todo_event_img", jsonData[0].url);
          toast.success(res.message, "Account");
          setDataevent();
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };
  const setDataevent = () => {
    const id = { event_id: common?.singleEvent?.id };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            ...common,
            singleEvent: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const [minTimeMaxDay, setMinTimeMaxDay] = React.useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [maxTimeMinDay, setMaxTimeMinDay] = React.useState(
    new Date().setHours(23, 59, 0, 0)
  );

  const [minDate, setMinDate] = React.useState(
    new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
  );

  const valStartDate = (val) => {
    setStartDate(val);

    setMinTimeMaxDay(moment(val).format("HH:mm:ss"));

    let endDate = new Date(val?.getTime() + 60 * 60 * 24 * 1000);
    setEventEnds(endDate);
    setMinDate(
      val
        ? new Date(val?.getTime() + 60 * 60 * 24 * 1000)
        : new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
    );
  };

  return (
    <>
      <Eventstyle>
        <div>
          <Eventmenu img={props.eventData?.project_image} />
          <div className="column2">
            <form key={1} className="forwith" onSubmit={handleSubmit(onSubmit)}>
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>
                  {common?.singleEvent?.event_name
                    ? common?.singleEvent?.event_name
                    : ""}
                </div>
                <div className="d-flex align-items-center">
                  <div className="imageeventim234">
                    <span>Details</span>
                  </div>
                  <GrCircleInformation className="ml-3 pointer infoIcon" />
                </div>
              </div>
              {/* <div className="AdDLiSt_BuTtON">
                <CustomButton>Save Information</CustomButton>
              </div> */}

              <div className="borderBottom eventBannerPara">
                This view lets you assign information, dates, and deadlines for
                your event.
              </div>
              <div className="d-flex m-4">
                <GlobalButton
                  customClass="ml-auto"
                  img={require("assets/img/hornIcon.svg").default}
                  type="submit"
                >
                  Save Changes
                </GlobalButton>
                {/* <DeleteBtn icon> Delete Event </DeleteBtn> */}
                {/* <Button className="saveChangesBtn ml-auto">
                  <img
                    src={require("assets/img/hornIcon.svg").default}
                    alt=""
                    className="icon"
                  />
                  Save Changes
                </Button> */}
                {/* <Button className="deleteBtn">
                  <IoMdClose className="icon" /> Delete Event
                </Button> */}
              </div>
              <div className="mainContentBoxWrapper eventAboutWrapperNew">
                <Row>
                  <Col sm={12} xl={6}>
                    <div className="cardBox">
                      <div className="imgEventWraperNew">
                        <div className="imageventsla">
                          <div className="imgLabel">Image Label:</div>
                          <div className="imgContainer">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/mediaGallery.svg").default
                              }
                              className="icomn iconSize24 pointer"
                              onClick={() => {
                                setModalShow(true);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="imgMaskevente"
                          onClick={() => {
                            setModalShow(true);
                          }}
                        >
                          <div className="imgMask-content">+</div>
                          {filestackImag || common?.singleEvent?.cover_image ? (
                            <img
                              className="storytoimageev"
                              id="blah2"
                              src={
                                filestackImag
                                  ? filestackImag
                                  : common?.singleEvent?.cover_image
                                    ? common?.singleEvent?.cover_image
                                    : ""
                              }
                              alt="."
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {modalShow ? (
                      <Filemanager
                        show={modalShow}
                        onHide={handleClickimag}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        seletedImage={seletedImageClike}
                        mapkey=""
                      />
                    ) : (
                      ""
                    )}

                    <div className="cardBox">
                      <InputField
                        type="text"
                        placeholder="Event Name"
                        label="Event Name:"
                        name="event_name"
                        onChange={handleChangeinput}
                        {...register("event_name", { required: true })}
                      />
                      {errors.event_name && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>

                    <div className="cardBox form-group eventHonoredGuestDropdown">
                      <label htmlFor="input-field">
                        Contact Admin for Event:
                      </label>
                      <div className="imageeventim">
                        <AdminList admin={true} onParentClick={onParentClick} />

                        {/* <HonoredGuestDropdown
                          admin={true}
                          onParentClick={onParentClick}
                          adminDetails={props.eventData?.adminDetails}
                        /> */}
                      </div>
                    </div>

                    <div className="cardBox categoryTypeDropdown">
                      {/* {selectboxS ? (
                        <>
                          <Selectbox
                            onChangeSelect={setSelectboxS}
                            title="Select list (select one):"
                            selectdata={selectboxS}
                            className=""
                            id={6}
                          />
                        </>
                      ) : (
                        ""
                      )} */}

                      <Dropdown
                        isSearchable
                        selectdata={selectboxS}
                        handleClick={handleClick}
                        placeHolder={
                          selectboxS ? selectboxS.label : "Select..."
                        }
                        options={options}
                        onChange={(value) => setSelectboxS(value)}
                        customClass={"selectStoryTypeDropdown"}
                      />
                    </div>

                    <div className="cardBox">
                      <InputField
                        type="text"
                        placeholder="Hosted by"
                        label="Hosted by:"
                        name="hosted_by"
                        onChange={handleChangeinput}
                        {...register("hosted_by")}
                      />
                    </div>
                    <div className="cardBox">
                      <TextareaField
                        name="our_storyz_description"
                        row="3"
                        placeholder="This storyz rox. It will be the greatest storyz ever told."
                        label="Event Description (1 paragraph):"
                        onChange={handleChangeinput}
                        {...register("event_description")}
                      />
                    </div>
                    <div className="cardBox">
                      <TextareaField
                        name="our_storyz_description"
                        row="3"
                        placeholder="This storyz rox. It will be the greatest storyz ever told."
                        label="Special Instructions (1 paragraph):"
                        onChange={handleChangeinput}
                        {...register("special_instructions")}
                      />
                    </div>
                  </Col>
                  <Col sm={12} xl={6}>
                    <div className="basicinformation">Dates and Times</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <label htmlFor="date">Event Begins:</label>
                        <div className="dateTimePickerGlobal">
                          <DatePicker

                            className={"form-control"}
                            selected={startDate}
                            onChange={(val) => {
                              valStartDate(val);
                              //setStartDate(val);
                              setValue("event_start_date", val, {
                                shouldTouch: true,
                              });
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            placeholderText="Date"
                            dateFormat="MM-dd-yyyy h:mm a"
                            minDate={new Date()}
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>

                        <input
                          value={startDate}
                          type="hidden"
                          name="event_start_date"
                          {...register("event_start_date", {
                            required: true,
                          })}
                        />
                        <div className="form-text">
                          8/8/2022 in your local timezone (PST)
                        </div>

                        {errors.event_start_date && (
                          <span style={{ color: "red" }}>
                            Event start date is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="cardBox">
                      <div className="form-group">
                        <label htmlFor="date">Event Ends:</label>

                        <div className="dateTimePickerGlobal">
                          <DatePicker
                            className={"form-control"}
                            selected={endtDate}
                            onChange={(val) => {
                              setEventEnds(val);
                              setValue("event_end_date", val);
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            placeholderText="Date"
                            dateFormat="MM-dd-yyyy h:mm a"
                            minDate={minDate}
                            onFocus={(e) => e.target.blur()}
                          />
                        </div>
                        <input
                          value={endtDate}
                          type="hidden"
                          name="event_end_date"
                          {...register("event_end_date", {
                            required: true,
                          })}
                        />
                        <div className="form-text">
                          8/8/2022 in your local timezone (PST)
                        </div>

                        {errors.event_end_date && (
                          <span style={{ color: "red" }}>
                            Event end date is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="cardBox pb-0 mb-0 mb-n3">
                      <div className="form-group">
                        <Timezoneset />
                        <InputField
                          type="hidden"
                          name="timezone_id"
                          defaultValue={events?.time?.timezone}
                          value={events?.time?.timezone}
                          onChange={() => {
                            setValue("timezone_id", events?.time?.timezone);
                          }}
                          {...register("timezone_id")}
                        />

                        <InputField
                          type="hidden"
                          name="clock_format"
                          defaultValue={events?.time?.timevalue}
                          value={events?.time?.timevalue}
                          onChange={() => {
                            setValue("clock_format", events?.time?.timevalue);
                          }}
                          {...register("clock_format")}
                        />

                        <InputField
                          type="hidden"
                          defaultValue={events?.time?.abbr}
                          value={events?.time?.abbr}
                          name="timezone"
                          onChange={() => {
                            setValue("timezone", events?.time?.abbr);
                          }}
                          {...register("timezone")}
                        />
                      </div>
                    </div>
                    <div className="basicinformation">RSVP</div>

                    <div className="RSVPDeadline cardBox">
                      <div className="form-group">
                        {/* <InputField
                          type="date"
                          placeholder="RSVP Deadline"
                          label="RSVP Deadline:"
                          name="rsvp_deadline"
                          onChange={handleChangeinput}
                          {...register("rsvp_deadline", { required: true })}
                        /> */}
                        <div className="dateTimePickerGlobal">
                          <DatePicker
                            className={"form-control"}
                            selected={rsvpDate}
                            onChange={(val) => {
                              setRsvpDate(val);
                              setValue("rsvp_deadline", val, {
                                shouldTouch: true,
                              });
                            }}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            timeCaption="Time"
                            placeholderText="Date"
                            dateFormat="MM-dd-yyyy h:mm a"
                            minDate={new Date()}
                            maxDate={endtDate}
                            minTime={minTimeMaxDay}
                            maxTime={maxTimeMinDay}
                            onFocus={(e) => e.target.blur()}
                          />
                          <input
                            value={rsvpDate}
                            type="hidden"
                            name="rsvp_deadline"
                            {...register("rsvp_deadline", {
                              required: true,
                            })}
                          />
                        </div>
                        {errors.rsvp_deadline && (
                          <span style={{ color: "red" }}>
                            RSVP Deadline is required
                          </span>
                        )}
                        <small id="emailHelp" className="form-text text-muted">
                          8/8/2022 in your local timezone (PST)
                        </small>
                      </div>
                    </div>
                    <div className="basicinformation">Notes</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <TextareaField
                          name="notes"
                          row="3"
                          placeholder="This storyz rox. It will be the greatest storyz ever told."
                          label="Admin notes (not visible to guests):"
                          onChange={handleChangeinput}
                          {...register("notes")}
                        />
                      </div>
                    </div>
                    <div className="basicinformation">Repeating Events</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <Repeatingevents
                          date={props.eventData?.event_start_date}
                          name={props.eventData?.event_name}
                          img={props.eventData?.cover_image}
                          location={props.eventData?.location}
                        />
                      </div>
                    </div>

                    <div className="basicinformation">Settings</div>
                    <div className="cardBox">
                      <div className="form-group">
                        <div className="eventSettingCheckbox">
                          {setValue.is_private}
                          <CheckboxField
                            label="Event is Private (not searchable from website)"
                            name="is_private"
                            {...register("is_private")}
                            onChange={(e) => {
                              setValue(
                                "is_private",
                                e.target.checked ? "1" : "0"
                              );
                              setHowtochke(!howtochke);
                            }}
                            check={howtochke ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <div className="AdDLiSt_BuTtON"> */}
                {/* <Button
                  className="btn-white"
                  color="default"
                  style={{
                    background: "#FC3158",
                    color: "#fff",
                    width: "120px",
                    borderRadius: "10px",
                  }}
                >
                  Update
                </Button> */}
                {/* <CustomButton>Save Information</CustomButton> */}
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
        {/* <p
          style={{ padding: "25px 25px 16px 25px" }}
          className="borderBottom"
        >
          This view lets you assign information, dates, and deadlines for your
          event.{" "}
        </p>
        <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ padding: "15px" }}>
            <Col md={6}>
              <div className="imageventsla">
                <div>Image Label:</div>
                <div>
                  <img
                    alt="Organization Name"
                    src={require("assets/img/mediaGallery.svg").default}
                    className="icomn"
                    style={{ width: "24px" }}
                  />
                </div>
              </div>
              <div className="mt-3">
                <div className="imgMaskevente">
                  <div className="imgMask-content">+</div>
                  {imgData ? (
                    <img
                      className="storytoimageev"
                      id="blah2"
                      src={imgData ? imgData : ""}
                      alt="."
                    />
                  ) : (
                    ""
                  )}
                  <input
                    type="file"
                    className="imgg1"
                    id="imgInp"
                    onChange={fileHandler}
                  />
                </div>
              </div>
              <div className="mt-4">
                <InputField
                  type="text"
                  placeholder="Event Name"
                  label="Event Name:"
                  name="event_name"
                  onChange={handleChangeinput}
                  {...register("event_name", { required: true })}
                />
                {errors.event_name && (
                  <span style={{ color: "red" }}>This field is required</span>
                )}
              </div>
              <div className="mt-5 mb-5">
                <label htmlFor="input-field">Contact Admin for Event:</label>
                <div className="imageeventim mt-2">
                  <div className="imageeventim2">
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/Guest-Profile-Imageev.svg")
                          .default
                      }
                      className="imagevent"
                    />
                    <div style={{ marginLeft: "25px" }}>
                      <span>Admin Name</span>
                      <br />
                      <span>Admin</span>
                    </div>
                  </div>
                  <div
                    className="imageeventim22 pointer"
                    style={{ marginLeft: "25px" }}
                  >
                    <span>Change</span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <Selectbox
                  onChangeSelect={setSelectboxS}
                  title=" Select list (select one):"
                />
              </div>
              <div className="mb-4">
                <InputField
                  type="text"
                  placeholder="Hosted by"
                  label="Hosted by:"
                  name="hosted_by"
                  onChange={handleChangeinput}
                  {...register("hosted_by")}
                />
              </div>
              <div className="mb-5">
                <TextareaField
                  name="our_storyz_description"
                  row="3"
                  placeholder="This storyz rox. It will be the greatest storyz ever told."
                  label="Event Description (1 paragraph):"
                  onChange={handleChangeinput}
                  {...register("event_description")}
                />
              </div>
              <TextareaField
                name="our_storyz_description"
                row="3"
                placeholder="This storyz rox. It will be the greatest storyz ever told."
                label="Special Instructions (1 paragraph):"
                onChange={handleChangeinput}
                {...register("special_instructions")}
              />
            </Col>
            <Col md={6}>
              <p className="basicinformation">Dates and Times</p>
              <div className="mt-4 mb-5">
                <label htmlFor="date" className="form-label">
                  Event Begins:
                </label>
                <DatePicker
                  isClearable
                  name="event_start_date"
                  className={"form-control"}
                  selected={startDate}
                  onChange={(val) => {
                    setStartDate(val);
                    setValue("event_start_date", val);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  placeholderText="Date"
                  dateFormat="MM-dd-yyyy h:mm a"
                />
              </div>
              <div>
                <label htmlFor="date" className="form-label">
                  Event Ends:
                </label>
                <DatePicker
                  isClearable
                  name="event_end_date"
                  className={"form-control"}
                  selected={endtDate}
                  onChange={(val) => {
                    setEventEnds(val);
                    setValue("event_end_date", val);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  placeholderText="Date"
                  dateFormat="MM-dd-yyyy h:mm a"
                />
              </div>
              <div className="mt-4 mb-4">
                <Timezoneset />
                <InputField
                  type="hidden"
                  name="timezone_id"
                  defaultValue={events?.time?.timezone}
                  value={events?.time?.timezone}
                  onChange={() => {
                    setValue("timezone_id", events?.time?.timezone);
                  }}
                  {...register("timezone_id")}
                />

                <InputField
                  type="hidden"
                  name="clock_format"
                  defaultValue={events?.time?.timevalue}
                  value={events?.time?.timevalue}
                  onChange={() => {
                    setValue("clock_format", events?.time?.timevalue);
                  }}
                  {...register("clock_format")}
                />

                <InputField
                  type="hidden"
                  defaultValue={events?.time?.abbr}
                  value={events?.time?.abbr}
                  name="timezone"
                  onChange={() => {
                    setValue("timezone", events?.time?.abbr);
                  }}
                  {...register("timezone")}
                />
              </div>
              <p className="basicinformation mt-2">RSVP Deadline</p>
              <div className="RSVPDeadline mb-3">
                <InputField
                  type="date"
                  placeholder="RSVP Deadline"
                  label="RSVP Deadline:"
                  name="rsvp_deadline"
                  onChange={handleChangeinput}
                  {...register("rsvp_deadline")}
                />
                <small id="emailHelp" className="form-text text-muted">
                  8/8/2022 in your local timezone (PST)
                </small>
              </div>
              <p className="basicinformation mt-2">Notes</p>

              <TextareaField
                name="notes"
                row="3"
                placeholder="This storyz rox. It will be the greatest storyz ever told."
                label="Admin notes (not visible to guests):"
                onChange={handleChangeinput}
                {...register("notes")}
              />
              <p className="basicinformation mt-2">Repeating Events</p>
              <Repeatingevents name={eventName} img={imgData} />

              <p className="basicinformation mt-2">Settings</p>
              <CheckboxField
                label="Event is Private (not searchable from website)"
                name="is_private"
                {...register("is_private")}
                onChange={(e) => {
                  setValue("is_private", e.target.checked ? "1" : "0");
                  setHowtochke(!howtochke);
                }}
              />
            </Col>
          </Row>
          <div className="AdDLiSt_BuTtON">
            <Button
              className="btn-white"
              color="default"
              style={{
                background: "#FC3158",
                color: "#fff",
                width: "120px",
                borderRadius: "10px",
              }}
            >
              Update
            </Button>
          </div>
        </form> */}

        <ToastContainer />
      </Eventstyle>
    </>
  );
}
export default Eventabout;
