import React, { useState } from "react";
import { Container, Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import IconImg from "../../../assets/img/logo-terms.svg";
// import { useLocation } from "react-router-dom";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
import { userService } from "../../../_services/login/user.service";
// import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
function TermCondition() {
  let history = useHistory();
  const [termsModal, setTermsModal] = useState(false);
  const handelTermsModal = () => setTermsModal(!termsModal);
  const iAcceptTerms = () => {
    var usernewAdmin = JSON.parse(sessionStorage.getItem("usernewAdmin"));
    var userAdmin = JSON.parse(sessionStorage.getItem("userAdmin"));
    var imgJson = JSON.parse(sessionStorage.getItem("imgJson"));
    let sendData = {
      token: userAdmin?.token,
      password: userAdmin?.password,
      invite_token: userAdmin?.token,
      email: userAdmin?.email,
      prefix: usernewAdmin?.surname,
      first_name: usernewAdmin?.first_name
        ? usernewAdmin?.first_name
        : userAdmin?.first_name,
      last_name: usernewAdmin?.lastName
        ? usernewAdmin?.lastName
        : userAdmin?.last_name,
      suffix: usernewAdmin?.suffix,
      country_id: "",
      mobile_number: usernewAdmin?.mobile_number,
      imaDatajson: imgJson ? imgJson : "",
    };

    userService.invitationUser(sendData).then(
      (res) => {
        if (res.status === "success") {
          sessionStorage.removeItem("usernewAdmin");
          sessionStorage.removeItem("userAdmin");
          sessionStorage.removeItem("imgJson");
          toast.success(res.message, "Login");
          history.push(`/`);
        } else if (res.status === "error") {
          console.log(res.message, "Login");
        } else {
          console.log("Please enter valid username and password !!", "Login");
        }
      },
      (error) => {
        console.log("Invalid Credetials !!", "Login");
      }
    );
  };
  return (
    <>
      <Modal
        isOpen={termsModal}
        toggle={handelTermsModal}
        className="termsModal"
      >
        <ModalBody>
          <div className="termsProceedWraper">
            <img src={require("assets/img/logo-terms.svg").default} alt="" />
            In order to Proceed, You Must Accept the Terms of Service
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="pinkBtn" onClick={handelTermsModal}>
            Terms of Service
          </Button>
          <Button className="cancelBtn" onClick={handelTermsModal}>
            Exit OurStoryz
          </Button>
        </ModalFooter>
      </Modal>
      <Container fluid className="GuestcreateLoginHeaderWrappper">
        <Link to="/">
          <i className="ni ni-bold-left arrowIcon"></i>
        </Link>
        <img src={IconImg} alt="" />
        OurStoryz
      </Container>
      <div className="twofactorauth">
        <Container className="termConditionWrapper" fluid>
          <div className="termConditionTopWrapper">
            <div className="termConditionMsgWrapper">
              Before using the OurStoryz service, you need to agree to our Terms
              of Service. <br /> Scroll downwards through the followng
              information and indicate your acceptance of the terms.
            </div>
          </div>
          <div className="termConditionContentWrapper">
            <div className="termConditionOverflow">
              <h1>Terms of Service</h1>
              <p>1. Your Acceptance of the Terms</p>
              <p>
                By using or accessing our Services, you confirm your agreement
                to be bound by these Terms and our Privacy Policy, which are
                incorporated herein by reference. If you do not agree to these
                Terms and the Privacy Policy, please do not use or access the
                Services.
              </p>
              <p>
                The Terms expressly supersede any prior Terms of Use between you
                and us or any of our affiliates or predecessors. Certain
                features or products available through the Services may be
                subject to additional terms and conditions presented to you at
                the time that you use or purchase them.
              </p>
              <p>a. Customers</p>
              <p>
                We may offer different types of paid and free memberships or
                subscriptions. We reserve the right to modify the terms of or
                cancel any such unpaid vendor memberships. Additional terms and
                conditions described may apply paid subscriptions, and are made
                a part of the Terms by reference.
              </p>
              <p>
                We have no control over the existence, quality, accuracy,
                safety, or legality of the transactions that take place on our
                Services or the accuracy of any Customer listings. We have no
                control over the ability of Vendors to provide items or perform
                services or the ability of Customers to pay for any goods and
                services. We make no representations or warranties and are not
                liable or responsible for the actions or inactions of our Users.
              </p>
              <p>b. Vendors</p>
              <p>
                As a Vendor you acknowledge that we will not endorse you or your
                products or services, or assist you in providing goods and
                services to Customers. Your membership or subscription to our
                Services may not be transferred or sold to another party.
              </p>
              <p>
                As a Vendor you must list the true and correct name of your
                business on the Services. If there is a change to that business
                name, Vendors must promptly update the Services and may need to
                provide additional documentation for proof of name change.
                Vendors who engage in the sale of goods and services must have a
                valid business operations license, as applicable. If you or your
                company experiences a dissolution, merger or other significant
                change in personnel (e.g., sale of company), then we, in our
                sole discretion, have the right to determine whether to keep
                active, transfer or terminate your account, including all
                content previously associated with such account.
              </p>
              <p>
                We may offer different types of paid and free memberships or
                subscriptions. Free Vendor memberships do not guarantee any
                advertising placement or other benefits. We reserve the right to
                modify the terms of or cancel any such unpaid vendor
                memberships. Additional terms and conditions described in the
                apply to Paid Subscriptions, and are made a part of the Terms by
                reference. If there is a conflict between the Terms and the
                terms for any service offered on or through the Services, such
                as Paid Subscriptions, the latter terms shall control with
                respect to your use of that portion of the Services.
              </p>
              <p>4. Nondiscrimination Policy</p>
              <p>
                We prohibit discrimination against Users, Guests based on race,
                color, religion, sex, national origin, ancestry, ethnicity,
                immigration status, disability, marital, family, pregnancy
                status, sexual orientation, gender identity, gender expression,
                veteran or citizenship status, age, or any other characteristic
                protected under applicable federal, regional, state, or local
                law. Such discrimination includes, but is not limited to,
                refusing to provide or accept services or any other conduct that
                improperly takes into account these characteristics. This
                prohibition applies to the posting of discriminatory content,
                such as reviews or forum posts, on the Services. We will, at our
                discretion, take steps to enforce this policy, up to and
                including suspending from our Services those Vendors and Members
                who violate this policy.
              </p>
              <p>
                If you experience discrimination with any Member or Vendor,
                please contact support at support@ourstoryz.com with the subject
                "Nondiscrimination Policy," so we can investigate and take
                appropriate measures.
              </p>
              <p>Suspension of Service</p>
              <p>
                We reserve the right to suspend any User's access to the
                Services and cancel the contract of any Vendor to violates these
                rules or who engages in offensive and detrimental behavior,
                including behavior that shocks, insults, or offends the
                community and public morals and decency, including through
                making racist, discriminatory or offensive comments on our
                properties and elsewhere or by taking actions that would tend to
                reflect poorly on us.
              </p>
              <p>5. Jurisdictional Issues</p>
              <p>
                We control and operate the Services from our facilities in the
                United States of America and, unless otherwise specified, the
                materials displayed on the Services are presented solely for the
                purpose of promoting products and services available in the
                United States, its territories, possessions, and protectorates.
                We do not represent that materials on the Services are
                appropriate or available for use in other locations. If you
                choose to access the Services from other locations, you are
                responsible for compliance with local laws, if and to the extent
                that local laws are applicable.
              </p>
              <p>6. Accounts, Passwords and Security</p>
              <p>
                To access certain features or areas of the Services, you may be
                required to register and create an account. If you create an
                account, it is important to protect the confidentiality of your
                account password. We are not liable for any loss or damage
                arising from your failure to protect your password or account
                information.
              </p>
              <p>
                You agree to provide true, accurate, current, and complete
                information about yourself as prompted by the applicable
                registration or log-in form, and you are responsible for keeping
                such information up to date, so that we can reliably contact
                you. Certain features of the Services may only be available to
                our registered Users. To access those areas of the Services you
                will be required to log in using your username and password. You
                are responsible for all activity occurring when the Services are
                accessed through your account, whether authorized by you or not.
              </p>
              <p>7. Privacy</p>
              <p>
                Our Privacy Policy, describes how we handle the information you
                provide to us when you use our Services. You understand that
                through your use of the Services you consent to the collection
                and use (as set forth in the Privacy Policy) of this
                information, including the processing and use by us and our
                affiliates. To the extent that you interact directly with a
                Vendor through our Services, you are subject to their Privacy
                Policy in connection with such interactions.
              </p>
              <div className="d-flex justify-content-center py-4">
                <Button className="blueBtn" onClick={iAcceptTerms}>
                  I Accept the Terms
                </Button>
                <Button className="blueBtn" onClick={handelTermsModal}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <ToastContainer />
      <AuthFooter />
    </>
  );
}
export default TermCondition;
