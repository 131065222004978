import React from "react";
import IconImg2 from "../../assets/img/icons/invite-icons.svg";
import InviteForm from "../../components/model-form/invite-form";
import { informationService } from "../../_services/masters/information.service";
import { TailSpin } from "react-loader-spinner";
import Resendinvite from "../../components/Invite/Resendinvite";
import dateFormat from "dateformat";
import { ToastContainer, toast } from "react-toastify";

import {
  FormGroup,
  Form,
  Input,
  Table,
  Modal,
} from "reactstrap";
import { GrCircleInformation } from "react-icons/gr";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";

function Notifications() {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [dataList, setdataList] = React.useState([]);
  React.useEffect(() => {
    informationService.listInvite().then(
      (res) => {
        if (res.status === "success") {
          setdataList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);


  return (
    <>
      {/* <Allheader title="Invite Team Members" imgScr={IconImag} /> */}
      <CombinedGuestPoolHeader title="Invite Team Members" />
      <div className="accountinvite organizerWrapperMain">
        {/* <Row> */}
        <Form>
          {/* <div className="col"> */}
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Invite New Admins to the Team</h4>
              <GrCircleInformation className="ml-auto iconColor infoToolTip" />
            </div>
            <div className="borderBottom organizationPara p-4">
              You can invite admins from your guest pool, or outside the pool by
              using an email address.
            </div>
            <div className="addInviteBox">
              <div
                onClick={() => setdefaultModal(true)}
                className="todoIconWrapper"
              >
                <img src={IconImg2} alt="" className="toIcon" />
                Invite New Admin
              </div>
            </div>
            <Modal
              className="modal-dialog-centered inviteNewAdminModal"
              isOpen={defaultModal}
              toggle={() => setdefaultModal(false)}
            >
              <div className="modal-header border-bottom">
                <h6 className="modal-title" id="modal-title-default">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/circled5icon.svg").default}
                  />
                  Invite New Admin
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => setdefaultModal(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Type in an email, guest name, or gues phone number to add an
                  Admin to your team. They will create their own longin. You can
                  also set their role in the organization.
                </p>
                <InviteForm onClick1={() => setdefaultModal(false)} />
              </div>
            </Modal>

            {dataList ? (
              <>
                <div className="inviteAdminTableWrapper">
                  <Table responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Email Address
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Status
                        </th>

                        <th className="sort" data-sort="budget" scope="col">
                          Team Role
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {dataList.map((popup, i) => (
                        <tr key={i}>
                          <td>{popup.invite_email}</td>
                          <td className="budget">
                            <span>
                              <img
                                alt="..."
                                src={
                                  popup.imageUrl
                                    ? popup.imageUrl
                                    : require("assets/img/people-cameos.svg")
                                      .default
                                }
                                className="imagein"
                              />
                            </span>
                            <span>
                              {popup.status === "Accept" ? "Accept" : "Invited"}{" "}
                              {dateFormat(popup.created_at, "mmmm d, yyyy")}{" "}
                            </span>
                          </td>
                          <td>
                            <FormGroup>
                              <Input
                                id="exampleFormControlSelect1"
                                type="select"
                              >
                                <option>Admin</option>
                              </Input>
                            </FormGroup>
                          </td>
                          <td className="budget">
                            {popup.status === "Accept" ? (
                              <>
                                <Link
                                  to={`/admin/account-information/${popup.user_id}`}
                                  className="ins gotoAccount pointer hcolor"
                                >
                                  <img
                                    alt="..."
                                    src={
                                      require("assets/img/icons/goaccount.svg")
                                        .default
                                    }
                                  />
                                  Go to Account
                                </Link>
                              </>
                            ) : (
                              <>
                                <Resendinvite email={popup.invite_email} />
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div style={{ margin: "auto" }}>
                  {" "}
                  <TailSpin
                    className="loader"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    style={{ margin: "auto" }}
                  />
                </div>
              </>
            )}
          </div>
          {/* </div> */}
        </Form>
        {/* </Row> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default Notifications;
