import Allheader from "../../components/Headers/Allheader";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import computer from "../../assets/img/Information-for-the-Entire-Album.svg";
import computer1 from "../../assets/img/Edit-Specific-Events-in-this-Storyz.svg";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { useHistory } from "react-router-dom";
import { InputField, TextareaField } from "../../components/Inputbox";
import { informationService } from "../../_services/masters/information.service";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { ReactSession } from "react-client-session";
import { useParams } from "react-router-dom";
import moment from "moment";
import * as actionTypes from "../../store/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { FaChevronRight } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";
import Imggroup from "../../assets/img/dummyImg.svg";
import { eventKeepsake, removeeventKeepsake } from "../../store/actions/index";

function StoryzKeepsakeAlbum() {
  const dispatch = useDispatch();
  const common = useSelector((state) => {
    return state.eventkeepsakealbum.Eventkeepsakealbum;
  });
  const { register, handleSubmit, setValue } = useForm({});
  const [userInfo, setUserInfo] = React.useState({});
  const params = useParams();
  let history = useHistory();
  const [imgModalBox, setImgModalBox] = React.useState(false);
  const [story, setStoryname] = React.useState([]);
  const [eventList, seteventList] = React.useState([]);
  // const [eventList2, seteventList2] = React.useState([]);
  const [storyzalbumdetails, setStoryzalbumdetails] = React.useState({});
  const [previewModal, setPreviewModal] = useState(false);
  const [item, setItem] = useState();
  // const [selectedOption, setSelectedOption] = useState("byDate");
  const [keepsakeAlbumAll, setKeepsakeAlbumAll] = useState([]);

  const [imgData, setImgData] = React.useState();

  const contact = useSelector((state) => {
    return state.profile;
  });
  const handleChangeinput = (e) => {
    userInfo[e.targe.name] = e.targe.value;
    setUserInfo({ ...userInfo });
  };

  const [allimage, setAllimage] = React.useState([]);
  const [modalShow21, setModalShow21] = React.useState(false);

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  let organization_id =
    contact?.profiledetails?.organization[0].organization_id;
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "keepsakealbum",
      storyz_id: params.sid,
      organization_id: parseInt(organization_id),
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    });

    userGuestService.createAlbumStoryz(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Updated successfully");
          datagetstoryz();
          Fileset();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  function Fileset() {
    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }
  const seletedImageClike = (e) => {
    //console.log("objKA", e);
    var obj = JSON.parse(e.img_json);
    //alert("bghfh");
    //console.log("objKA", obj);
    let storyz_id = params.sid;
    let jsonData = {};
    e["storyz_id"] = storyz_id;
    e["type"] = "keepsakealbum";
    e["imaDatajson"] = JSON.parse(JSON.stringify(obj));

    jsonData = JSON.parse(JSON.stringify(e));

    delete jsonData.img_json;
    delete jsonData.id;
    delete jsonData.user_id;
    delete jsonData.filename;
    delete jsonData.handle;
    delete jsonData.mimetype;
    delete jsonData.source;
    delete jsonData.url;
    delete jsonData.created_at;
    delete jsonData.updated_at;
    delete jsonData.img_address;
    delete jsonData.lat;
    delete jsonData.lng;

    userGuestService.storyzKeepsakeAlbumupdateImage(jsonData).then(
      (res) => {
        if (res.status === "success") {
          //console.log("jsonData", jsonData.imaDatajson.imaDatajson[0].url)
          datagetstoryz();
          setImgData(jsonData.imaDatajson.url != undefined ? jsonData.imaDatajson.url : jsonData.imaDatajson.imaDatajson[0].url);
          toast.success(res.message, "Account");
          //storyzDetail();
          setModalShow21(false);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  useEffect(() => {
    const data = {
      story_id: params.sid,
    };
    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setStoryname(res.data[0]);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    informationService.eventList(params.sid).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
          //seteventList2(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    datagetstoryz();
  }, [params.sid]);

  function datagetstoryz() {
    userGuestService.storyzAlbumdetails(params.sid).then(
      (res) => {
        if (res.status === "success") {
          setStoryzalbumdetails(res.data);
          setValue("custom_title", res.data.tittle, { shouldTouch: true });
          setValue("our_storyz_description", res.data.description, {
            shouldTouch: true,
          });
          setValue("id", res.data.id, { shouldTouch: true });
          setImgData(res.data.cover_image ? res.data.cover_image : "");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const handleClick = (e, val) => {
    //console.log("ehandleClick", e);
    setModalShow21(false);
    setItem(e.target.getAttribute("data-id"));
    //setSelectedOption(val);
    const dataa = {
      display_by: val,
      event_id: 245,
      storyz_id: 143,
    };
    userGuestService.getStoryzKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            setKeepsakeAlbumAll(res.data[0].keepsake_album_photo);
            //setstoryzKeepsake(res.data[0]);
            sessionStorage.setItem("keep_id", res.data[0].id);
            dispatch(eventKeepsake(res.data));
            //setImgModalBox(false);
            //setModalShow21(false)
          } else {
            dispatch(removeeventKeepsake());
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    // CloseUploadImgModal();
    // setModalShow21(false)
  };

  const PreviweModal = () => {
    //alert("Hi..");
    const dataa = {
      display_by: "byDate",
      event_id: 245,
      storyz_id: 143,
    };
    setPreviewModal(true);
    dispatch(removeeventKeepsake());
    userGuestService.getKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            setKeepsakeAlbumAll(res.data[0].keepsake_album_photo);
            //setstoryzKeepsake(res.data[0]);
            sessionStorage.setItem("keep_id", res.data[0].id);
            setPreviewModal(true);
            dispatch(eventKeepsake(res.data));
            //setImgModalBox(false);
            //setModalShow21(false)
          } else {
            dispatch(removeeventKeepsake());
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    //console.log( "storyzalbumdetails", storyzalbumdetails );
  };

  const PreviweModalClose = () => {
    //alert("Hi..");
    setPreviewModal(false);
    dispatch(removeeventKeepsake());
  };

  const searchEvent = (val) => {

    informationService.searcheventList(params.sid, val).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
          //seteventList2(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  
  };

 

  // const [selectedImage, setSelectedImage] = useState(null);

  const onSubmit = (data) => {
    const data1 = JSON.stringify({
      type: "keepsakealbum",
      storyz_id: params.sid,
      organization_id: parseInt(organization_id),
      description: data.our_storyz_description,
      tittle: data.custom_title,
    });
    userGuestService.createAlbumStoryz(data1).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Updated successfully");
          datagetstoryz();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  return (
    <>
      <Allheader
      id={story?.proId}
title={story?.storyz_name ? story?.storyz_name : "New Storyz edit"}
        imgScr={story?.profile_photo_Url ? story?.profile_photo_Url : Imggroup}
        arw
      />
      <div className="organizerWrapperMain bgPannel">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Keepsake Album</h4>
              <span
                data-tip="Tooltips display informative text when users hover over, focus on, or tap an element."
                className="ml-auto"
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
              <ReactTooltip />
            </div>

            <div className="borderBottom organizationPara p-4">
              This page lets you create virtual photo album, featuring files
              contributed by guests during all of your Events. You can edit
              indivdiual photos as necessary.
            </div>
            <div className="p-4 d-flex">
              <img
                alt="Organization Name"
                src={require("assets/img/storyz-keepsake.svg").default}
                className="ml-auto pointer"
                onClick={PreviweModal}
              />
              <Modal
                isOpen={previewModal}
                toggle={PreviweModalClose}
                className="keepsakeAlbumModal"
              >
                <ModalHeader toggle={() => PreviweModalClose()}></ModalHeader>
                <ModalBody>
                  {!item && (
                    <>
                      <div className="coverImgWrapper">
                        <span className="title">
                          {storyzalbumdetails.tittle}
                        </span>
                        <img
                          src={storyzalbumdetails.cover_image}
                          alt=""
                          className="coverImg"
                        />
                      </div>
                      <div className="keepsakeAlbumContent">
                        {storyzalbumdetails.description}
                      </div>
                      <div className="keepsakeAlbumAction">
                        <div className="actionWrapper">
                          <button
                            className="keepsakeAlbumBtn active"
                            onClick={(e) => handleClick(e, "byDate")}
                          >
                            Date
                          </button>
                          <button
                            className="keepsakeAlbumBtn"
                            onClick={(e) => handleClick(e, "Guest")}
                            data-id="1"
                          >
                            Guest
                          </button>
                        </div>
                      </div>
                      <div className="keepsakeAlbumMediaWrapper">
                        {common[0]?.keepsake_album_photo.map((x, i) => (
                          <div key={i}>
                            <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                              <div className="date">{x.date}</div>
                              <FaChevronRight size={24} className="icon" />
                            </div>
                            <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                              {x.images.map((img, g) => (
                                <div className="sliderList" key={g}>
                                  {img.mime_type === "video" ? (
                                    <>
                                      <video
                                        width="400"
                                        controls
                                        className="videoHeight"
                                      >
                                        <source
                                          src={img.photo_url}
                                          type="video/mp4"
                                        />
                                        Your browser does not support HTML
                                        video.
                                      </video>
                                    </>
                                  ) : (
                                    <img
                                      src={img.photo_url}
                                      alt=""
                                      className="sliderImg"
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {item && (
                    <>
                      <div className="coverImgDetails">
                        <button onClick={handleClick} className="backArrow">
                          <FiArrowLeft className="" />
                        </button>

                        <span className="title">Keepsake Album</span>
                      </div>
                      <div className="keepsakeAlbumContent">
                        This is an introduction to the Keepsake album, created
                        by the Admins in the CMS. It can be viewed during and
                        after the event.
                      </div>
                      <div className="keepsakeAlbumAction">
                        <div className="actionWrapper">
                          <button
                            className="keepsakeAlbumBtn "
                            onClick={handleClick}
                          >
                            Date
                          </button>
                          <button
                            className="keepsakeAlbumBtn active"
                            onClick={handleClick}
                            data-id="1"
                          >
                            Guest
                          </button>
                        </div>
                      </div>
                      <div className="keepsakeAlbumMediaWrapper">
                        {common[0]?.keepsake_album_photo.map((x, i) => (
                          <div key={i}>
                            <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                              <div className="date">
                                {/* Rohit-Gust-BY */}
                                <img
                                  src={x.guestProfile}
                                  alt="Girl in a jacket"
                                  width="21"
                                  height="21"
                                />
                                {/* &nbsp; */}
                                {x.name}
                              </div>
                              <FaChevronRight size={24} className="icon" />
                            </div>
                            <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                              {x.images.map((img, g) => (
                                <div className="sliderList" key={g}>
                                  {img.mime_type === "video" ? (
                                    <>
                                      <video
                                        width="400"
                                        controls
                                        className="videoHeight"
                                      >
                                        <source
                                          src={img.photo_url}
                                          type="video/mp4"
                                        />
                                        Your browser does not support HTML
                                        video.
                                      </video>
                                    </>
                                  ) : (
                                    <img
                                      src={img.photo_url}
                                      alt=""
                                      className="sliderImg"
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </ModalBody>
              </Modal>
            </div>
            <div className="d-flex mx-4 mb-4">
              <GlobalButton
                customClass="ml-auto"
                img={require("assets/img/hornIcon.svg").default}
                type="submit"
              >
                Update
              </GlobalButton>
              <DeleteBtn icon> Delete Album </DeleteBtn>
            </div>
            <Row>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img src={computer} alt="" />
                  Information for the Entire Album
                </div>
                <div className="cardBox">
                  <div className="py-4 blackColor fontSize16 fontWeight300">
                    Provide album information for the entire Storyz.
                  </div>
                  <div>
                    <div className="formBox">
                      <InputField
                        type="text"
                        placeholder="Custom title for the Keepsake Album:"
                        label="Custom title for the Keepsake Album:"
                        name="custom_title"
                        onChange={handleChangeinput}
                        required
                        {...register("custom_title", { required: true })}
                      />
                    </div>
                    <div className="keepsakeImgBoxWrapper formBox">
                      <div className="coverimagestsp ">
                        <div className="coverimage1">Cover Image :</div>
                        <div>
                          {/* <div className="upload-btn-wrapper" style={{ marginLeft: '10px' }} onClick={() => setImgModalBox(true)
                          }> */}
                          <img
                            src={require("assets/img/update.svg").default}
                            alt=""
                            onClick={() => {
                              setModalShow21(true);
                            }}
                            className="pointer"
                          />
                        </div>
                      </div>
                      <div
                        className="imgMaskevente"
                        onClick={() => {
                          setModalShow21(true);
                        }}
                      >
                        {imgData ? (
                          <img
                            className="storyt-oimage"
                            id="blah2"
                            src={imgData}
                            alt=""
                          />
                        ) : (
                          " "
                        )}
                        <div className="imgMask-content">+</div>
                        {/* <input
                            type="file"
                            className="imgg1"
                            id="imgInp"
                            onChange={(event) => {
                              setSelectedImage(event.target.files[0]);
                              setImgData(
                                URL.createObjectURL(event.target.files[0])
                              );
                            }}
                          /> */}
                      </div>
                    </div>

                    {modalShow21 && (
                      <Filemanager
                        show={modalShow21}
                        onHide={handleClick}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        seletedImage={seletedImageClike}
                      />
                    )}

                    <div className="formBox">
                      <input type="hidden" name="id" {...register("id")} />
                      <TextareaField
                        name="our_storyz_description"
                        onChange={handleChangeinput}
                        {...register("our_storyz_description", {
                          required: true,
                        })}
                        row="5"
                        placeholder="Text introducing the entire album:"
                        label="Text introducing the entire album:"
                        required
                      />
                    </div>
                    {/* <div>
                      <Button className="pinkBtn">Update</Button>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img src={computer1} alt="" />
                  Edit Specific Events in this Storyz
                </div>
                <div className="cardBox">
                  <div className="py-4 blackColor fontSize16 fontWeight300">
                    To edit specifc events in the Keepsake Album, select below.
                  </div>
                  <div>
                    <div>
                      <div className="form-group">
                        <input
                          id="example2cols1Input"
                          placeholder="type to search..."
                          type="text"
                          className="form-control"
                          style={{ width: "60%" }}
                          onKeyUp={(e)=>searchEvent(e.target.value)}
                          
                        />
                      </div>
                      <div className="todolistst">
                        <section className="storyzsection">
                          {eventList.length > 0 ? (
                            <>
                              {eventList.map((evdata, i) => (
                                <div
                                  className="story1 storyzListBox"
                                  key={i}
                                  onClick={() => {
                                    history.push(
                                      `/admin/event-keepsake-album/${params.sid}/${evdata.id}?type=event`
                                    );
                                  }}
                                >
                                  <img
                                    alt={evdata?.event_name}
                                    src={
                                      evdata?.cover_image
                                        ? evdata?.cover_image
                                        : require("assets/img/theme/Event-name.svg")
                                            .default
                                    }
                                    className="eventimag"
                                  />

                                  <div>
                                    <div className="title">
                                      {evdata?.event_name}
                                    </div>
                                    <div className="subtitle">
                                      {evdata.event_start_date
                                        ? moment(
                                            evdata.event_start_date
                                          ).format("MMM Do YY")
                                        : "No Date"}
                                    </div>
                                    <div className="subtitle1">
                                      {evdata?.location}
                                    </div>
                                    <div className="description">
                                      {evdata?.event_description
                                        ? evdata?.event_description.substring(
                                            0,
                                            25
                                          ) + "..."
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            "No Event Data"
                          )}
                        </section>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="basicinformation">
                      <img src={computer1} alt="" />
                      Storage
                    </div>
                    <div className="cardBox">
                      <div className="py-4 blackColor fontSize16 fontWeight300">
                        Change Location where uploaded files will be stored.
                        This may requiring authenticating with a third-party
                        service (e.g. Google Drive) before storage work.
                      </div>
                      <div>
                        <Link className=" greenActionBtn ml-auto">
                          <img
                            alt="..."
                            src={require("assets/img/upload-icon.svg").default}
                          />
                          Change Location
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </form>
        {imgModalBox && (
          <>
            <div
              className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""}`}
            >
              <div className="closeIcon" onClick={() => setImgModalBox(false)}>
                <svg
                  className="closeBtn"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                </svg>
              </div>
              <div className="mediaModalHeaderWrapper">
                <span className="mediaLogo">
                  <i className="fa fa-image mediaLogoIcon"></i>
                </span>{" "}
                Add Image Information
              </div>
              <div className="mediaImageWrapper">
                <div className="date">
                  August 15, 2022: 3:30pm
                  {/* {imgDataUrl ? imageDate : ""} */}
                </div>
                {/* <h1>Video Type: {video}</h1> */}
                <div className="imageCropperWrapper">
                  {/* <div className="uploadImageFromLocalContent">
                    <input
                      type="file"
                      name="files"
                      className="fileInput"
                      accept="image/png, image/jpeg, video/*"
                    />
                    <i className="fa fa-plus faPlus"></i>
                  </div> */}

                  <img src="2" alt="w" className="imageCropper" />

                  {/* {imgDataUrl ? (
                            <img
                              className="imageCropper"
                              id="blah1"
                              src={imgDataUrl ? imgDataUrl : ""}
                              alt="."
                            />
                          ) : (
                            ""
                          )} */}
                </div>

                {/* <div className="imageEditIcons">
                  <button className="button">
                    <i className="fa fa-expand"></i>
                  </button>
                  <button className="button">
                    <i className="fa fa-crop"></i>
                  </button>
                  <button className="button">
                    <i className="fa fa-trash"></i>
                  </button>
                </div> */}
              </div>

              <div className="mediaUserDetails">
                <div className="mediaUserImgWrapper">
                  <img src="ds" className="mediaUserImg" />
                </div>
                <div className="mediaUserContentWrapper">
                  <div className="mediaUserTitle"></div>
                  <div className="mediaUserDesignation">Role in Event</div>
                </div>
              </div>

              <div className="filenameWrapper">
                {/* File name: <span>File NameXXX </span> */}
                File name: <span></span>
              </div>
              <div className="mediaFormWrapper">
                <div className="formBox">
                  <label className="form-label">Title</label>
                  {/* <input
                          type="text"
                          className="form-control"
                          id=""
                          placeholder="type in a name, or use the map..."
                        /> */}
                  {/* <input
                          type="text"
                          className="form-control"
                          id="alt_text"
                          name="alt_text"
                          placeholder="type in a name, or use the map..."
                          value={alt_text}
                          onChange={(e) => setPhotolabel(e.target.value)}
                        /> */}

                  <input
                    type="text"
                    className="form-control"
                    id="photo_label"
                    name="photo_label"
                    placeholder="Image Label"
                  />

                  {/* Title: {alt_text} */}
                </div>
                <div className="formBox">
                  <label className="form-label">Caption:</label>

                  <textarea
                    type="text"
                    className="form-control"
                    id="photo_caption"
                    name="photo_caption"
                    placeholder="photo caption"
                  ></textarea>
                </div>
                <div className="formBox">
                  <label className="form-label">Location:</label>
                  <div className="locationMap">
                    {/* <input
                            type="email"
                            className="form-control"
                            id=""
                            placeholder="type in a name, or use the map..."
                          /> */}
                    <input
                      type="text"
                      className="form-control"
                      id="gallerylocation"
                      name="gallerylocation"
                      placeholder="type in a name, or use the map..."
                    />
                    <div className="map">
                      <svg
                        className="diamondTrunImg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                      </svg>
                      Map
                    </div>
                  </div>
                </div>
                <div className="actionWrapper">
                  <button type="submit" className="btn confirmBtn">
                    Save Image Meta-Data
                  </button>
                  <button
                    type="submit"
                    className="btn CancelBtn"
                    onClick={() => setImgModalBox(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mediaOverlay"></div>
          </>
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default StoryzKeepsakeAlbum;
