/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useId } from "react";
import { ArrowDown, ArrowUp } from "../../icons";
import classNames from "./NumberInput.module.scss";

const NumberInput = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  disabled,
  fullWidth,
  placeholder,
}) => {
  const id = useId();

  const innerOnChange = (event) => onChange(Number(event.target.value), event);
  const innerOnKeyPress = (event) => {
    // @ts-ignore
    onKeyPress?.(event.key, event);
  };

  const onClickUp = () => onChange(value + 1);
  const onClickDown = () => onChange(value - 1);

  return (
    <div
      className={classNames.numberfield}
      //@ts-ignore
      style={{ "--width": fullWidth ? "100%" : "55px" }}
    >
      {label && (
        <label className={classNames.numberfield__label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={classNames.numberfield__input_container}>
        <input
          placeholder={placeholder}
          disabled={disabled}
          className={classNames.numberfield__input}
          value={value}
          type="number"
          onChange={innerOnChange}
          onBlur={onBlur}
          onFocus={onFocus}
          // @ts-ignore
          onKeyPress={innerOnKeyPress}
        />
        <div className={classNames.numberfield__arrow_container}>
          <ArrowUp onClick={onClickUp} />
          <ArrowDown onClick={onClickDown} />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
