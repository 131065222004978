import React, { useEffect, useState, useRef } from "react";
import EditIcon from "./../../../../src/assets/img/pencil-icon.svg";
import TrashIcon from "./../../../../src/assets/img/trash-icon.svg";
import PlusIcon from "./../../../../src/assets/img/plus-icon.svg";
import DeleteEverythingInfoIcon from "./../../../../src/assets/img/resetgeospaceInfoIcon.svg";
import {
  eventKeepsake,
  removeeventKeepsake,
} from "../../../store/actions/index";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { ReactSession } from "react-client-session";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { FaUsers } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import EditFilemanager from "../../CommonMediaModal/FilemanagerEditKeepsakeAlbum";
import { useSelector, useDispatch } from "react-redux";
const KeepsakeGallery = ({
  AddBrowImgModal,
  data,
  onData,
  handleClickUserInfo,
  ...args
}) => {
  const dispatch = useDispatch();
  const common = useSelector((state) => {
    return state.eventkeepsakealbum.Eventkeepsakealbum;
  });
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [imgModalBox, setImgModalBox] = useState(false);
  const [deleteStore, setDeleteStore] = useState({});
  const [editStore, setEditStore] = useState({});
  const [editModalShow21, setEditModalShow21] = useState(false);
  const [allimage, setAllimage] = useState([]);
  const params = useParams();
  const [eventId, setEventId] = useState(params.eid ? params.eid : "");
  const [storyzId, setStoryzId] = useState(params.sid ? params.sid : "");
  const [uestUserID, setguestUserID] = useState(0);
  const [gallerylocation, setGallerylocation] = useState("");
  const [photolabel, setPhotolabel] = useState("");
  const [photoCaption, setPhotoCaption] = useState("");
  const [edit_id, setEditID] = useState(0);
  const [seletedImage, setSeletedImage] = useState([]);
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );
  const inputRef = useRef();

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
    }
  };

  const handelDeleteMedia = (data) => {
    let obj = { photo_url: data.photo_url };
    userGuestService.getDeleteData(obj).then(
      (res) => {
        if (res.status == "success") {
          setDeleteStore(res.data);
          setDeleteMedia(true);
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    //setDeleteStore(data);
  };

  const handelEditMedia = (data) => {
    setTimeout(() => {
      setSeletedImage(data.imaDatajson);
    }, 1000);
    //console.log("handelEditMedia newData", newData);
    let editData = JSON.parse(data.keepsake_image_json);
    data["imaDatajson"] = editData;
    data["imaDatajson"]["url"] = data.photo_url;
    data["photo_caption"] = data.caption;
    data["photo_label"] = data.photo_label;
    data["location"] = data.location;
    //let newData = JSON.parse( JSON.stringify(data) );
    //console.log("handelEditMedia", newData);
    setEditID(data.id);
    setEditStore(data);

    setImgModalBox(!imgModalBox);
  };

  const saveData = () => {
    //alert(gallerylocation);
    let objKaData = {};
    let selImg = seletedImage;
    //console.log("selImg", selImg);
    objKaData["type"] = "keepsakealbum";
    objKaData["id"] = edit_id;
    objKaData["guest_user_id"] = uestUserID;
    objKaData["location"] = gallerylocation;
    //objKaData['photo_label'] = photolabel;
    objKaData["caption"] = photoCaption;
    objKaData["description"] = "";
    objKaData["imaDatajson"] = selImg;
    //console.log("objKaData", objKaData);

    userGuestService.updateKeepsake(objKaData).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Updated Successfully");
          setImgModalBox(false);
          handleClickUserInfo();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const closeEditFilemanager = () => {
    setEditModalShow21(false);
    setImgModalBox(false);
  };

  const openEditFilemanager = () => {
    //alert("openEditFilemanager");
    setEditModalShow21(true);
    setImgModalBox(false);
    //setImgModalBox(true)
  };

  const deleteData = (photo_url) => {
    let obj = { photo_url: photo_url };
    userGuestService.deleteData(obj).then(
      (res) => {
        if (res.status == "success") {
          setDeleteMedia(false);
          getkeepskeAlbum();
          toast.success("Deleted Successfully");
        } else {
          toast.error("Not Deleted Successfully");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const getkeepskeAlbum = () => {
    const dataa = {
      display_by: onData,
      event_id: eventId,
      storyz_id: storyzId,
    };
    userGuestService.getKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            dispatch(eventKeepsake(res.data));
          } else {
            dispatch(removeeventKeepsake());
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  return (
    <>
      <div className="keepsakeAlbumListWrapper">
        {data.map((y, i) => (
          <div className="keepsakeAlbumList" key={i}>
            <div className="topAction d-flex justify-content-end align-items-center">
              <img
                src={EditIcon}
                alt=""
                className="icon pointer ml-auto"
                onClick={() => handelEditMedia(y)}
              />
              <img
                src={TrashIcon}
                alt=""
                className="icon pointer ml-3"
                onClick={() => handelDeleteMedia(y)}
              />
            </div>
            <div className="keepsakeAlbumListImgWrapper mb-3">
              {y.mime_type === "video" ? (
                <>
                  <video className="imageCropper" alt={y.photo_url} controls>
                    <source src={y.photo_url ? y.photo_url : ""} type="" />
                  </video>
                </>
              ) : (
                <>
                  <img src={y.photo_url} alt="" className="listImg" />
                </>
              )}
            </div>
            <div className="keepsakeAlbumListContent p-3">
              <div className="two-line-trucate" title={y.caption}>
                {y.caption}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={deleteMedia}
        toggle={handelDeleteMedia}
        {...args}
        className="deleteEverythingModal"
      >
        <ModalBody className="pb-0">
          <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
            <img
              src={DeleteEverythingInfoIcon}
              alt=""
              className="deleteEverythingInfoIcon"
            />
            <div className="head">Trash Media File?</div>
          </div>

          <Row className="mb-2 ">
            <Col>
              {deleteStore.mime_type === "video" ? (
                <>
                  <video width="400" controls className="videoHeight">
                    <source src={deleteStore.photo_url} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </>
              ) : (
                <>
                  <img
                    alt="Organization Name"
                    src={deleteStore.photo_url}
                    className="mb-5 deleteMediaImg w-100"
                  />
                </>
              )}
              {/* <img
                alt="Organization Name"
                src="https://images.unsplash.com/photo-1587271636175-90d58cdad458?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                className="mb-5 deleteMediaImg w-100"
              /> */}

              <p className="bold">File Name: {deleteStore.filename}</p>
              <p className="bold">
                Used by: {deleteStore.total_storyz} Storyz,{" "}
                {deleteStore.total_event} Events
              </p>
              <p></p>
              <p></p>
              <p>This will delete the file from your archive entirely.</p>
              <p>
                If you used this file in a Storyz, event, or Keepsake Album, you
                will need to replace it with another file. You will receive a
                notification of the affected files in your Notifications area.
              </p>
              <p>
                If you want to replace this file with different media, select
                “replace”.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn w-100 boxShadowNone"
            onClick={() => deleteData(deleteStore.photo_url)}
          >
            Trash
          </Button>

          <Button
            color="primary"
            className="flex-grow-1 blueBtn w-100 border-radius10 boxShadowNone"
          >
            Import Replacement
          </Button>
          <Button
            className="btn-default cancelBtn w-100 boxShadowNone"
            onClick={() => setDeleteMedia(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {editModalShow21 && (
        <EditFilemanager
          show={editModalShow21}
          openEditFrom={setImgModalBox}
          storyz_id={params.sid}
          event_id={params.eid}
          onHide={closeEditFilemanager}
          list={allimage}
          seletedImage={setSeletedImage}
          setEditStore={setEditStore}
        />
      )}

      {imgModalBox && (
        <>
          <div
            className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""}`}
          >
            <div className="closeIcon" onClick={() => setImgModalBox(false)}>
              <svg
                className="closeBtn"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </div>
            <div className="mediaModalHeaderWrapper">
              <span className="mediaLogo">
                <i className="fa fa-image mediaLogoIcon"></i>
              </span>{" "}
              Edit Image Information
            </div>
            <div className="mediaImageWrapper">
              <div className="date">
                {moment(editStore.created_at).format("MMMM D, YYYY: h:mm:sa")}{" "}
              </div>
              <div className="imageCropperWrapper">
                {/* <div
                  className="uploadImageFromLocalContent"
                  onClick={openEditFilemanager}
                >
                  <i className="fa fa-plus faPlus"></i>
                </div> */}

                <img
                  src={editStore.imaDatajson.url}
                  alt="w"
                  className="imageCropper"
                />
              </div>

              {/* <div className="imageEditIcons">
                <button className="button">
                  <i className="fa fa-expand"></i>
                </button>
                <button className="button">
                  <i className="fa fa-crop"></i>
                </button>
                <button className="button">
                  <i className="fa fa-trash"></i>
                </button>
              </div> */}
            </div>

            <div className="mediaUserDetails">
              <div className="mediaUserImgWrapper">
                <img
                  src={
                    editStore.guest_profile
                      ? editStore.guest_profile
                      : require("assets/img/reshot-icon-style-N2BEF9R38U.svg")
                        .default
                  }
                  className="mediaUserImg"
                  alt=""
                />
              </div>
              <div className="mediaUserContentWrapper flex-column">
                <div className="mediaUserTitle">{editStore.user_name}</div>
                <div className="mediaUserDesignation">
                  {editStore.role_type}
                </div>
              </div>
            </div>

            <div className="filenameWrapper">
              File name: <span>File NameXXX </span>
            </div>
            <div className="mediaFormWrapper">
              <div className="formBox">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="photo_label"
                  name="photo_label"
                  placeholder="Image Label"
                  defaultValue={editStore.photo_label}
                  onChange={(e) => setPhotolabel(e.target.value)}
                />
              </div>
              <div className="formBox">
                <label className="form-label">Caption:</label>

                <textarea
                  type="text"
                  className="form-control"
                  id="photo_caption"
                  name="photo_caption"
                  placeholder="photo caption"
                  defaultValue={editStore.photo_caption}
                  onChange={(e) => setPhotoCaption(e.target.value)}
                ></textarea>
              </div>
              <div className="formBox">
                <label className="form-label">Location:</label>
                <div className="locationMap locationgooglemap12">
                  <LoadScript googleMapsApiKey={mapkey} libraries={["places"]}>
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="gallerylocation"
                        placeholder="type in a name, or use the map..."
                        defaultValue={
                          gallerylocation == ""
                            ? editStore.location
                            : gallerylocation
                        }
                      //   onChange={(e) => setGallerylocation(e.target.value)}
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  <div className="map">
                    <svg
                      className="diamondTrunImg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                    </svg>
                    Map
                  </div>
                </div>
              </div>
              <div className="actionWrapper">
                <button
                  type="submit"
                  className="btn confirmBtn"
                  onClick={() => saveData()}
                >
                  Save Image Meta-Data
                </button>
                <button
                  type="submit"
                  className="btn CancelBtn"
                  onClick={() => setImgModalBox(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
          <div className="mediaOverlay"></div>
        </>
      )}
    </>
  );
};

export default KeepsakeGallery;
