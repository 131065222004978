import React from "react";
import InviteForm from "../model-form/invite-form";
import { Modal } from "reactstrap";
function Resendinvite(props) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  return (
    <>
      <Modal
        className="modal-dialog-centered inviteNewAdminModal"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header border-bottom">
          <h6 className="modal-title" id="modal-title-default">
            <img
              alt="Organization Name"
              src={require("assets/img/icons/Vector-5.svg").default}
            />
            Re Invite Admin Team Member
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <h6 className="modal-title mb-4" id="modal-title-default">
              <img
                alt="Organization Name"
                src={require("assets/img/icons/Vector-5.svg").default}
              />
              Re Invite Admin Team Member
            </h6> */}

          <InviteForm
            email={props.email}
            onClick3={() => setdefaultModal(false)}
            onTrur="true"
          />
        </div>
      </Modal>

      <span
        className="ins resendinvite hcolor pointer"
        onClick={() => setdefaultModal(true)}
      >
        <img
          alt="..."
          src={require("assets/img/icons/invite-icons.svg").default}
        />
        Resend Invite
      </span>
    </>
  );
}
export default Resendinvite;
