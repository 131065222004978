import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal");

const Modal = ({ children }) => {
  const element = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(element);
    };
  }, []);

  return createPortal(children, element);
};

export default Modal;
