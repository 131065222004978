import React, { useState } from "react";
import TodoIcon from "./../../assets/img/todo-icon.svg";
import ArrowBtn from "./../../assets/img/arrow-btn.svg";
import statusClose from "./../../assets/img/status-close.svg";
import statusCheck from "./../../assets/img/status-check.svg";
import statusRefresh from "./../../assets/img/status-refresh.svg";
import statusNotification from "./../../assets/img/status-notification.svg";
import OrganizationLogo from "./../../assets/img/organizationLogos1.svg";
import { todoService } from "_services/masters/information.todos";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import placeholde from "../../assets/img/placeholder.jpg";
import { InputField, CheckboxField } from "../Inputbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "jquery";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import format from "date-fns/format";
import validUrl from "valid-url";
const CreateEditTodo = ({
  args,
  modal_flag,
  TodoModal,
  EventData,
  storyz,
  events,
  organization,
  refresh,
}) => {

  let [eeData, seteData] = useState(EventData);
  const [url_error, setUrlError] = useState("");
  const [todoModal, setTodoModal] = useState(modal_flag);
  const [active, setActive] = useState(null);
  const [name, setName] = useState(EventData.title);
  const [duedate, setDuedate] = useState(null);
  const [assigned, setAssigned] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [shortdes, setShortdes] = useState(null);
  const [url, setUrl] = useState(null);
  const [name_error, setNameError] = useState("");
  const [duedate_error, setDuedateError] = useState("");
  const [assigned_error, setAssignedError] = useState("");

  let eData = Object.keys(eeData).length === 0 ? EventData : EventData;

  console.log(eData);

  useEffect(() => {
    // let assigned_date = String(eData.assigned);
    // assigned_date = assigned_date.split('/').reverse().join('-');
    // setStartDate(new Date(assigned_date));

    // let due_date = String(eData.due);
    // due_date = due_date.split('/').reverse().join('-');
    // setEndDate(new Date(due_date));

    const localDate = moment(new Date()).format();
    const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions();
    const apiTZ = "utc";

    const pickerTimeFromApiTime = moment(eData.oldDate)
      .tz(apiTZ)
      .tz(localTZ, true)
      .toDate();

    setStartDate(pickerTimeFromApiTime);

    const pickerTimeFromApiTime2 = moment(eData.endoldDate)
      .tz(apiTZ)
      .tz(localTZ, true)
      .toDate();
    setEndDate(pickerTimeFromApiTime2);
  }, [EventData]);

  let due_date = String(eData.due);
  due_date = due_date.split("/").reverse().join("-");
  let due = new Date(due_date);

  let assigned_date = String(eData.assigned);
  assigned_date = assigned_date.split("/").reverse().join("-");
  let assigned_date_on = new Date(assigned_date);

  let UserData = JSON.parse(localStorage.getItem("userDetails"));
  //console.log("UserData", UserData);
  let org_id = UserData.orgData.length > 0 ? UserData.orgData[0].id : 0;
  let org_name = UserData.orgData.length > 0 ? UserData.orgData[0].company_name : "";
  let org_img = UserData.orgData.length > 0 ? UserData.orgData[0].logo : "";
  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let storyz_name = localStorage.getItem("todo_storyz_name");
  let storyz_img = localStorage.getItem("todo_storyz_img");

  let ose_name = "";
  if (eData.todo_type == "Organization") {
    ose_name = org_name;
  } else if (eData.todo_type == "Storyz") {
    ose_name = storyz_name;
  } else if (eData.todo_type == "Event") {
    ose_name = event_name;
  }

  let urlArr = window.location.href.split("/");
  let event_id = urlArr[urlArr.length - 1];
  let storyz_id = urlArr[urlArr.length - 2];

  const countryData = JSON.parse(localStorage.getItem("userdata"));
  const countryCode = countryData.country_code;

  let todayDate = new Date();
  localStorage.setItem("todayDate", todayDate);
  localStorage.getItem("todayDate");
  //  console.log("Local storage", localStorage.getItem("todayDate"));

  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }

  let Nowday = day + "/" + month + "/" + year;

  const TodoModal2 = () => {
    //TodoModal(!modal_flag);
    setActive(EventData.todo_status);
  };

  const add = () => {
    if (url && !validUrl.isUri(url)) {
      setUrlError("Is Not Valid URL");
      setTimeout(() => {
        setUrlError("");
      }, 3000);
    }
    if (name == "") {
      setNameError("This field required");
      setTimeout(() => {
        setNameError("");
      }, 3000);
    } else if (startDate == "") {
      setAssignedError("This field required");
      setTimeout(() => {
        setAssignedError("");
      }, 3000);
    } else if (endDate == "") {
      setDuedateError("This field required");
      setTimeout(() => {
        setDuedateError("");
      }, 3000);
    } else if (url !== "" && validUrl.isUri(url)) {
      setUrlError("Is Not Valid URL");
      setTimeout(() => {
        setUrlError("");
      }, 3000);
    } else {
      if (eData.todo_type == "Event") {
        let todo_name = name !== null ? name : eData.title;
        let shor_tdes = shortdes !== null ? shortdes : eData.shortdes;
        let url_val = url !== null ? url : eData.url;
        let assigned_on = startDate !== null ? startDate : assigned_date_on;
        let due_date = endDate !== null ? endDate : due;
        let status_todo = active !== null ? active : eData.todo_status;

        let todo_eData = {
          id: eData.id,
          todo_name: todo_name,
          short_description: shor_tdes,
          knowledge_base_url: url_val,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(assigned_on, "yyyy-MM-dd h:mm a "),
          due_date: format(due_date, "yyyy-MM-dd h:mm a "),
          todo_status: status_todo,
          status: 1,
          org_id: org_id,
          todo_type: eData.todo_type,
        };

        todoService.updateEventTodo(todo_eData).then(
          (res) => {
            if (res.status === "success") {
              toast.success("updated successfully");
            } else {
              toast.error("not updated successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (eData.todo_type == "Storyz") {
        let todo_name = name !== null ? name : eData.title;
        let shor_tdes = shortdes !== null ? shortdes : eData.shortdes;
        let url_val = url !== null ? url : eData.url;
        let assigned_on = startDate !== null ? startDate : assigned_date_on;
        let due_date = endDate !== null ? endDate : due;
        let status_todo = active !== null ? active : eData.todo_status;

        let todo_Storyzdata = {
          id: eData.id,
          todo_name: todo_name,
          short_description: shor_tdes,
          knowledge_base_url: url_val,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(assigned_on, "yyyy-MM-dd h:mm a "),
          due_date: format(due_date, "yyyy-MM-dd h:mm a "),
          todo_status: status_todo,
          status: 1,
          org_id: org_id,
          todo_type: eData.todo_type,
        };

        //console.log('todo_Storyzdata', todo_Storyzdata);

        todoService.updateStoryzTodo(todo_Storyzdata).then(
          (res) => {
            if (res.status === "success") {
              toast.success("updated successfully");
            } else {
              toast.error("not updated successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (eData.todo_type == "Organization") {
        let todo_name = name !== null ? name : eData.title;
        let shor_tdes = shortdes !== null ? shortdes : eData.shortdes;
        let url_val = url !== null ? url : eData.url;
        let assigned_on = startDate !== null ? startDate : assigned_date_on;
        let due_date = endDate !== null ? endDate : due;
        let status_todo = active !== null ? active : eData.todo_status;

        let todo_Organizationdata = {
          id: eData.id,
          todo_name: todo_name,
          short_description: shor_tdes,
          knowledge_base_url: url_val,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(assigned_on, "yyyy-MM-dd h:mm a "),
          due_date: format(due_date, "yyyy-MM-dd h:mm a "),
          todo_status: status_todo,
          status: 1,
          org_id: org_id,
          todo_type: eData.todo_type,
        };

        //console.log('eData todo_Organizationdata', todo_Organizationdata);
        todoService.updateOrganizationTodo(todo_Organizationdata).then(
          (res) => {
            if (res.status === "success") {
              toast.success("updated successfully");
            } else {
              toast.error("not updated successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }
      setActive(null);
      setName(null);
      setStartDate(null);
      setEndDate(null);
      setShortdes(null);
      setUrl(null);
      TodoModal();
      //refresh();
    }
  };

  const UpdateSetActive = (value) => {
    setActive(value);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={modal_flag}
        toggle={TodoModal}
        {...args}
        className="TodoModalWrapper"
      >
        <ModalHeader toggle={TodoModal} onClick={TodoModal2}>
          Edit Todo {todoModal}{" "}
        </ModalHeader>
        <ModalBody className="p-2 modalContainerScroll">
          <div className="mb-3 formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Todo Name title
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="todo name"
              defaultValue={eData.title}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="mb-0 error">{name_error}</div>
          </div>

          {organization && (
            <div className="mb-3 formBox">
              <div className="todoThumWrapper">
                <div className="orgTodoLogo">
                  <img src={eData.cover_image} alt="" className="todoThumImg" />
                </div>
                <div className="todoThumContent">
                  <div className="todothumbTitle">{ose_name}</div>
                  <div className="todothumbSubTitle">{eData.todo_type}</div>
                  <div className="todothumbSubTitle">Service Level</div>
                </div>
              </div>
            </div>
          )}

          {storyz && (
            <div className="mb-3 formBox">
              <div className="todoThumWrapper">
                <div className="todoThum">
                  <img
                    src={eData.cover_image ? eData.cover_image : placeholde}
                    alt="storyz img"
                    className="todoThumImg"
                  />
                </div>
                <div className="todoThumContent">
                  <div className="todothumbTitle">Storyz: {storyz_name}</div>
                  {/* <div className="todothumbSubTitle">Event: {event_name}</div> */}
                </div>
              </div>
            </div>
          )}

          {events && (
            <div className="mb-3 formBox">
              <div className="todoThumWrapper">
                <div className="todoDate">Aug 14</div>
                <div className="todoEventThumb">
                  <img
                    src={eData.cover_image}
                    alt="event img"
                    className="todoThumImg"
                  />
                </div>
                <div className="todoThumContent">
                  <div className="todothumbTitle">{event_name}</div>
                  <div className="todothumbSubTitle">Location</div>
                </div>
              </div>
            </div>
          )}

          <div className="statusWrapper mb-3">
            <div className="form-label">Status:</div>
            <div className="statusListWrapper">
              <div
                className={`statusList d-flex align-items-center ${active == "Not Started" ||
                  (active == null && eData.todo_status == "Not Started")
                  ? "active"
                  : ""
                  }`}
                onClick={() => UpdateSetActive("Not Started")}
              >
                <img src={statusClose} alt="" className="icon" />
                Not Started
              </div>
              <div
                className={`statusList d-flex align-items-center ${active == "In Progress" ||
                  (active == null && eData.todo_status == "In Progress")
                  ? "active"
                  : ""
                  }`}
                onClick={() => UpdateSetActive("In Progress")}
              >
                <img src={statusRefresh} alt="" className="icon" /> In Progress
              </div>
              <div
                className={`statusList d-flex align-items-center ${active == "Completed" ||
                  (active == null && eData.todo_status == "Completed")
                  ? "active"
                  : ""
                  }`}
                onClick={() => UpdateSetActive("Completed")}
              >
                <img src={statusCheck} alt="" className="icon" /> Completed
              </div>
              <div
                className={`statusList d-flex align-items-center  ${active == "Overdue" ||
                  (active == null && eData.todo_status == "Overdue")
                  ? "active"
                  : ""
                  }`}
                onClick={() => UpdateSetActive("Overdue")}
              >
                <img src={statusNotification} alt="" className="icon" />
                Overdue
              </div>
            </div>
          </div>
          <div className="mb-3 formBox dateWrapper">
            <div>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Assigned on:
              </label>
              <div className="dateTimePickerGlobal">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className={"form-control"}
                  placeholderText={eData.assigned ? eData.assigned : startDate}
                  // dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy h:mm a"
                  minDate={new Date()}
                  maxDate={
                    endDate !== null
                      ? new Date(endDate)
                      : eData.due !== null
                        ? new Date(
                          String(eData.due?.split("/").reverse().join("-"))
                        )
                        : null
                  }
                  onFocus={(e) => e.target.blur()}
                />
              </div>
              <div className="error">{assigned_error}</div>
              <div className="form-text">
                {/* {Nowday} in your local timezone
                {countryCode === "US" ? "(PST)" : "(IST)"} */}
              </div>
            </div>
            <div>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Due Date :
              </label>
              <div className="dateTimePickerGlobal">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className={"form-control"}
                  placeholderText={endDate !== null ? endDate : eData.due}
                  // dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy h:mm a"
                  minDate={startDate}
                  onFocus={(e) => e.target.blur()}
                />
              </div>
              <div className="error">{duedate_error}</div>
              <div className="form-text">
                {Nowday} in your local timezone{" "}
                {countryCode === "US" ? "(PST)" : "(IST)"}
              </div>
            </div>
          </div>
          <div className="mb-3 formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Short Description:
            </label>
            <textarea
              type=""
              className="form-control"
              placeholder=""
              defaultValue={eData.shortdes}
              rows="3"
              onChange={(e) => setShortdes(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3 formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Address of Knowledge Base (URL):
            </label>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Field Content"
                defaultValue={eData.url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <img src={ArrowBtn} alt="" className="arrowBtn ml-1" />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-centent-center">
          <Button onClick={add} className="blueBtn">
            Update
          </Button>
          {/* <Button onClick={addAnother} className="todoBtn">
            Save and Add Another
          </Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateEditTodo;
