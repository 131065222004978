import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { todoService } from "_services/masters/information.todos";
import mL from "_helpers/month";
import TodoIcon from "./../../assets/img/todo-icon.svg";
import moment from "moment";
import {
  Navbar,
  FormGroup,
  Input,
  Container,
  Col,
  Row,
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Eventstyle } from "./styles";

import Eventmenu from "./eventMenu.js";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DataFundata from "../../components/Todo/DataFundata";
import CreateTodo from "components/Todo/CreateTodo";
import CreateEditTodo from "components/Todo/CreateEditTodo";
import { Link } from "react-router-dom";
import { GrCircleInformation } from "react-icons/gr";
import DeleteModal from "components/Modal/DeleteModal";
import { useHistory } from "react-router-dom";
// import DeleteEverythingInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";

const { SearchBar } = Search;
function EventTodosList({ ...args }) {
  const params = useParams();
  let history = useHistory();
  const [todolist, setTodolist] = useState([]);
  const [modal_flag, setmodalFlag] = useState(false);
  const [EventData, setEventData] = useState({});
  const [delEventData, setDelEventData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  let [count, setRefresh] = useState(0);
  localStorage.setItem("count", Math.floor(Math.random() * 11));

  let eventTodoArr = [];
  let urlArr = window.location.href.split("/");
  let event_id = urlArr[urlArr.length - 1];
  let storyz_id = urlArr[urlArr.length - 2];
  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let storyz_name = localStorage.getItem("todo_storyz_name");
  let storyz_img = localStorage.getItem("todo_storyz_img");

  const editTodo2 = (props) => {
    //console.log('---props', props);
    //console.log('propsRohit', props);
    setEventData(props);
    setTimeout(() => {
      setmodalFlag(true);
    }, 1);
  };

  const TodoModal = () => {
    setmodalFlag(!modal_flag);
    listEventTodo();
  };

  const handelDeleteModal = (props) => {
    setDelEventData(props);
    setDeleteModal(!deleteModal);
    listEventTodo();
    localStorage.setItem("count", Math.floor(Math.random() * 8));
  };

  const refresh = () => {
    listEventTodo();
    localStorage.setItem("count", Math.floor(Math.random() * 4));
  };

  const listEventTodo = () => {
    let resdata = { event_id: event_id };
    todoService.listEventTodo(resdata).then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;
          let obj = {};
          for (let index = 0; index < respons.length; index++) {
            let assignedArr = respons[index].assigned_on.split("T");
            let assigned_date_arr = assignedArr[0].split("-");
            let day = assigned_date_arr[assigned_date_arr.length - 1];
            assigned_date_arr[assigned_date_arr.length - 1] = day;
            let assigned_date = moment
              .utc(respons[index].assigned_on)
              .format("DD/MM/YYYY h:mm a");

            let dueArr = respons[index].due_date.split("T");
            let due_date_arr = dueArr[0].split("-");
            let end_day = due_date_arr[due_date_arr.length - 1];
            due_date_arr[due_date_arr.length - 1] = end_day;
            let due_date = moment
              .utc(respons[index].due_date)
              .format("DD/MM/YYYY h:mm a");
            let start_date = "";

            if (respons[index].start_date !== null) {
              let start_dateArr = respons[index].start_date.split("T");
              let start_date_Arr = start_dateArr[0].split("-");
              let strat_day = start_date_Arr[start_date_Arr.length - 1];
              let strat_month = start_date_Arr[start_date_Arr.length - 2];
              let monthArr = strat_month.split("");
              let m = 0;
              if (monthArr[0] == 0) {
                m = monthArr[1];
              } else {
                m = monthArr[0] + monthArr[1];
              }
              m = parseInt(m - 1);
              //console.log('monthArr', monthArr);
              start_date = ` ${mL[m]} ${strat_day}`;

              
            }

            let todoStatus = respons[index].todo_status;
            let todaydate = new Date();
            let duedate = new Date( respons[index].due_date );
            if( todaydate > duedate && todoStatus != "Completed" ){
                     todoStatus = "Overdue";
            }
            obj = {
              todo_name: respons[index].todo_name,
              actions: respons[index].id,
              guest_name: (
                <DataFundata
                  start_date={start_date}
                  id={respons[index].id}
                  title={respons[index].todo_name}
                  assigned={assigned_date}
                  due={due_date}
                  cover_image={respons[index].cover_image}
                  event_name={respons[index].event_name}
                  todo_status={todoStatus}
                  url={respons[index].knowledge_base_url}
                  shortdes={respons[index].short_description}
                  todo_type={respons[index].todo_type}
                  editTodo2={editTodo2}
                  handelDeleteModal={handelDeleteModal}
                  oldDate={respons[index].assigned_on}
                  endoldDate={respons[index].due_date}
                  storyz
                />
              ),
            };
            eventTodoArr.push(obj);
          }
          setTodolist(eventTodoArr);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  useEffect(() => {
    listEventTodo();
  }, [localStorage.getItem("count")]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const dataTable = todolist;
  return (
    <>
      <ToastContainer />
      <DeleteModal
        deleteModal={deleteModal}
        handelDeleteModal={handelDeleteModal}
        refresh={refresh}
        delEventData={delEventData}
        organization
      />
      <CreateEditTodo
        modal_flag={modal_flag}
        TodoModal={TodoModal}
        refresh={refresh}
        EventData={EventData}
        events
      />
      <Eventstyle>
        <div>
          <Eventmenu />
          <div className="column2">
            <div className="column2sate backgroeventdata">
              <div className="title">
                <div className="badge">Event</div>
                {event_name}
              </div>
              <div className="d-flex align-items-center">
                <div className="imageeventim234">
                  <span>Event Todos</span>
                </div>
                <GrCircleInformation className="ml-3 pointer infoIcon" />
              </div>
            </div>
            <div className="borderBottom organizationPara">
              This page lets you view and create a todo list associated with
              this specific event.
              <br />
              You can also assign todos at the
              <span
                onClick={() => {
                  history.push(`/admin/storyz-todos/${params.sid}`);
                }}
                className="mx-1 pointer"
              >
                Storyz
              </span>
              view or
              <span
                onClick={() => {
                  history.push(`/admin/todos`);
                }}
                className="mx-1 pointer"
              >
                Organization
              </span>
              view.
            </div>

            <ToolkitProvider
              data={dataTable}
              keyField="id"
              columns={[
                {
                  dataField: "guest_name",
                  text: "",
                  sort: false,
                },
                {
                  dataField: "todo_name",
                  sort: false,
                  style: { display: "none" },
                },
              ]}
              search
            >
              {(props) => (
                <div className="todoTableWrapper">
                  <div className="todoTableWrapperInner eventTodoWrapper">
                    <div className="eventTodoList">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter"
                      >
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/icons/monogram-circular.svg")
                              .default
                          }
                        />
                        <SearchBar
                          className="form-control"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </div>
                    </div>

                    <div className="eventTodoList">
                      <div
                        className="todoIconWrapper"
                        onClick={() => {
                          history.push(`/admin/todos`);
                        }}
                      >
                        <img src={TodoIcon} alt="" className="toIcon" />
                        Organization Todos
                      </div>
                      <div
                        className="todoIconWrapper"
                        onClick={() => {
                          history.push(`/admin/storyz-todos/${params.sid}`);
                        }}
                      >
                        <img src={TodoIcon} alt="" className="toIcon" />
                        Storyz Todos
                      </div>
                      <CreateTodo
                        title="New Event Todo"
                        refresh={refresh}
                        events
                      />
                    </div>
                  </div>
                  <div className="eventTodoTableNew">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    ></BootstrapTable>
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </Eventstyle>
    </>
  );
}
export default EventTodosList;
