import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { CombinedGuestPool } from "./style";
import Global from "../../../_helpers/global";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import Imggroup from "../../../assets/img/dummyImg.svg";

const StoryzFileList = ({ args }) => {
  const [imgData, setImgData] = useState(null);
  const [imgModalBox, setImgModalBox] = useState(false);
  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [importFromLocal, setImportFromLocal] = useState(false);
  const [imgDataUrl, setImgUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [msg, setMsg] = useState();
  const [video, setVideo] = useState("");

  const [imgEditDataUrl, setEditImgUrl] = useState("");
  const [imgEditData, setEditImgData] = useState(null);
  const [imgEditModalBox, setImgEditModalBox] = useState(false);
  const [editImageName, setEditImageName] = useState("");
  const [editVideo, setEditVideo] = useState("");

  const [photo_label, setPhotolabel] = useState("");
  const [photo_caption, setPhotocaption] = useState("");
  const [gallerylocation, setGallerylocation] = useState("");

  const AddBrowImgModal = () => {
    setBrowseImgModal(true);
    setImgEditModalBox(false);
  };

  const BrowseImgModal = () => {
    setBrowseImgModal(false);
  };

  const ImportFromLocal = () => {
    setImportFromLocal(true);
    setBrowseImgModal(false);
  };
  const ImportFromLocalClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(true);

    setImgUrl();
    setImgData();
    setImageName();
    setVideo();
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setMsg("");
      setImgUrl("loading..");
      setVideo("image");
      setImgData(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setTimeout(() => {
        setVideo(videourl1);
        setImgUrl(urldata);
      }, "0");
    }
  };

  const onEditChangePicture = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setEditImgUrl(urldata);
      setEditImgData(e.target.files[0]);
      setEditImageName(e.target.files[0].name);
      setEditVideo(videourl1);
    }
  };

  const MediaUserDetails = JSON.parse(localStorage.getItem("userdata"));
  const Userfname = MediaUserDetails.first_name;
  const Userlname = MediaUserDetails.last_name;
  const UserPic = MediaUserDetails.imageUrl;

  return (
    <>
      <CombinedGuestPoolHeader title="Storyz Name..." imgScr={Imggroup} arw />
      <Container
        className="accountinformation ptop pl-0 eventLeftMenu bgPannel py-4"
        fluid
      >
        <div className="card" style={{ marginBottom: "0px" }}>
          <CombinedGuestPool className="p-4">
            <div className="CombinedGuestPoolWrapper">
              <div className="py-4">
                <Row className="mb-4">
                  <Col className="d-flex justify-content-end align-items-center">
                    <div className="guestUserWrap">
                      <div className="exportWrap" onClick={ImportFromLocal}>
                        <img
                          alt="Organization Name"
                          src={require("assets/img/upload-icon.svg").default}
                        />
                        <div>Upload File</div>
                      </div>

                      {browseImgModal && (
                        <div
                          className={`modal fade mediaFileListModal ${browseImgModal ? "show" : ""
                            }`}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h1 className="modal-title">Storyz Files</h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={BrowseImgModal}
                                >
                                  <img
                                    alt="Import New"
                                    src={
                                      require("assets/img/deleteIcon.svg")
                                        .default
                                    }
                                    className="closeBtn"
                                  />
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="importImageListWrapper">
                                  <div
                                    className="importImageWrapper"
                                    onClick={ImportFromLocal}
                                  >
                                    <img
                                      alt="Import New"
                                      src={
                                        require("assets/img/upload-icon.svg")
                                          .default
                                      }
                                      className="importIcon"
                                    />
                                    Import New
                                  </div>
                                  <div className="importedImageListWrapper">
                                    <div className="importedImageListInner">
                                      <div className="importedImageList selected">
                                        <div className="uploadedItem">
                                          <div className="uploadedImg">
                                            <div className="playIcon">
                                              <i className="fa fa-play"></i>
                                            </div>
                                            <img
                                              src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2373&q=80"
                                              alt=""
                                              className="thum"
                                            />
                                          </div>
                                        </div>
                                        <div className="filename">
                                          File the Name Goes Here
                                        </div>
                                      </div>
                                      <div className="importedImageList">
                                        <div className="uploadedItem">
                                          <div className="uploadedImg">
                                            <img
                                              src="https://images.unsplash.com/photo-1485968579580-b6d095142e6e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80"
                                              alt=""
                                              className="thum"
                                            />
                                          </div>
                                        </div>
                                        <div className="filename">
                                          File the Name Goes Here
                                        </div>
                                      </div>
                                      {[...Array(8)].map((x, i) => (
                                        <div
                                          className="importedImageList"
                                          key={i}
                                        >
                                          <div className="uploadedItem">
                                            <div className="uploadedImg">
                                              <img
                                                src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2373&q=80"
                                                alt=""
                                                className="thum"
                                              />
                                            </div>
                                          </div>
                                          <div className="filename">
                                            File the Name Goes Here
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn selectFilebtn"
                                  onClick={() =>
                                    imgDataUrl != ""
                                      ? setImgModalBox(true)
                                      : setMsg("Please choose video or photo")
                                  }
                                >
                                  Select File
                                </button>
                                <button
                                  type="button"
                                  className="btn selectFilebtn whiteBg"
                                  onClick={() => BrowseImgModal(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {importFromLocal && (
                        <div
                          className={`modal fade mediaFileListModal importFromLocalModal ${importFromLocal ? "show" : ""
                            }`}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content importImageFromLocalContainer">
                              <div className="importFromLocalLeftWrapper">
                                <div className="importFromLocalLefList active">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/local-device.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Local Device
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/google-drive.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Google Drive
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/instagram.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Instagram
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/google-photos.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Google Photos
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/icloud.svg").default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  iCloud
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/one-drive.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  OneDrive
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/dropbox.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Dropbox
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/box.svg").default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Box
                                </div>
                                <div className="importFromLocalLefList">
                                  <div className="importFromLocalIconBox">
                                    <img
                                      alt=""
                                      src={
                                        require("assets/img/facebook.svg")
                                          .default
                                      }
                                      className="importFromLocalIcon"
                                    />
                                  </div>{" "}
                                  Facebook
                                </div>
                              </div>
                              <div>
                                <div className="modal-header">
                                  <h1 className="modal-title">
                                    Import From Local Device
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={ImportFromLocalClose}
                                  >
                                    <svg
                                      className="closeBtn"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 320 512"
                                    >
                                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                                    </svg>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div className="importFromLocalContent">
                                    <div className="importFromLocalContentLeft">
                                      <i className="fa fa-image faImage"></i>
                                    </div>
                                    Drag file into grey area below, or tap to
                                    upload
                                  </div>
                                  {/* <h1>Image Type</h1> */}
                                  <p style={{ color: "#FF0000" }}>{msg}</p>
                                  <div className="uploadImageFromLocalWrapper">
                                    <div className="uploadImageFromLocalContent">
                                      <input
                                        type="file"
                                        name="files"
                                        className="fileInput"
                                        accept="image/png, image/jpeg, video/*"
                                        onChange={onChangePicture}
                                      />
                                      <i className="fa fa-plus faPlus"></i>
                                    </div>

                                    {video === "video" ? (
                                      <>
                                        <video
                                          className="uploadImageFromLocalImg"
                                          alt={imgDataUrl}
                                          controls
                                        // autoplay
                                        >
                                          <source src={imgDataUrl} type="" />
                                        </video>
                                      </>
                                    ) : (
                                      <>
                                        {imgDataUrl ? (
                                          <img
                                            src={imgDataUrl ? imgDataUrl : ""}
                                            alt={imgDataUrl}
                                            className="uploadImageFromLocalImg"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn selectFilebtn"
                                    onClick={() =>
                                      imgDataUrl != ""
                                        ? setImgModalBox(true)
                                        : setMsg("Please choose video or photo")
                                    }
                                  >
                                    Select File
                                  </button>
                                  <button
                                    type="button"
                                    className="btn selectFilebtn whiteBg"
                                    onClick={() => BrowseImgModal(false)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {imgModalBox && (
                        <>
                          <div
                            className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""
                              }`}
                          >
                            <div
                              className="closeIcon"
                              onClick={() => setImgModalBox(false)}
                            >
                              <svg
                                className="closeBtn"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                              </svg>
                            </div>
                            <div className="mediaModalHeaderWrapper">
                              <span className="mediaLogo">
                                <i className="fa fa-image mediaLogoIcon"></i>
                              </span>{" "}
                              Add Image Information
                            </div>
                            <div className="mediaImageWrapper">
                              <div className="date">
                                August 15, 2022: 3:30pm
                                {/* {imgDataUrl ? imageDate : ""} */}
                              </div>
                              {/* <h1>Video Type: {video}</h1> */}
                              <div className="imageCropperWrapper">
                                {/* <div className="uploadImageFromLocalContent">
                                  <input
                                    type="file"
                                    name="files"
                                    className="fileInput"
                                    accept="image/png, image/jpeg, video/*"
                                    onChange={onEditChangePicture}
                                  />
                                  <i className="fa fa-plus faPlus"></i>
                                </div> */}

                                {video === "video" ? (
                                  <>
                                    <video
                                      className="imageCropper"
                                      alt={imgDataUrl}
                                      controls
                                    >
                                      <source
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        type=""
                                      />
                                    </video>
                                  </>
                                ) : (
                                  <>
                                    {imgDataUrl ? (
                                      <img
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        alt={imgDataUrl}
                                        className="imageCropper"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>

                              {/* <div className="imageEditIcons">
                                <button className="button">
                                  <i className="fa fa-expand"></i>
                                </button>
                                <button className="button">
                                  <i className="fa fa-crop"></i>
                                </button>
                                <button
                                  className="button"
                                  onClick={() => setImgUrl(false)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div> */}
                            </div>

                            <div className="mediaUserDetails">
                              <div className="mediaUserImgWrapper">
                                <img
                                  src={UserPic}
                                  className="mediaUserImg"
                                  alt={UserPic}
                                />
                              </div>
                              <div className="mediaUserContentWrapper">
                                <div className="mediaUserTitle mr-1">
                                  {Userfname} {Userlname}
                                </div>
                                <div className="mediaUserDesignation">
                                  Role in Event
                                </div>
                              </div>
                            </div>

                            <div className="filenameWrapper">
                              {/* File name: <span>File NameXXX </span> */}
                              File name:{" "}
                              <span>{imgDataUrl ? imageName : ""}</span>
                            </div>
                            <div className="mediaFormWrapper">
                              <div className="formBox">
                                <label className="form-label">Title</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="photo_label"
                                  name="photo_label"
                                  placeholder="Image Label"
                                  value={photo_label}
                                  onChange={(e) =>
                                    setPhotolabel(e.target.value)
                                  }
                                />

                                {/* Title: {alt_text} */}
                              </div>
                              <div className="formBox">
                                <label className="form-label">Caption:</label>

                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="photo_caption"
                                  name="photo_caption"
                                  placeholder="photo caption"
                                  value={photo_caption}
                                  onChange={(e) =>
                                    setPhotocaption(e.target.value)
                                  }
                                ></textarea>
                              </div>
                              <div className="formBox">
                                <label className="form-label">Location:</label>
                                <div className="locationMap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gallerylocation"
                                    name="gallerylocation"
                                    placeholder="type in a name, or use the map..."
                                    value={gallerylocation}
                                    onChange={(e) =>
                                      setGallerylocation(e.target.value)
                                    }
                                  />
                                  <div className="map">
                                    <svg
                                      className="diamondTrunImg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                    </svg>
                                    Map
                                  </div>
                                </div>
                              </div>
                              <div className="actionWrapper">
                                <button
                                  type="submit"
                                  className="btn confirmBtn"
                                // onClick={() => saveGallery()}
                                >
                                  Save Image Meta-Data
                                </button>
                                <button
                                  type="submit"
                                  className="btn CancelBtn"
                                  onClick={() => setImgModalBox(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mediaOverlay"></div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </CombinedGuestPool>
        </div>
      </Container>
    </>
  );
};

export default StoryzFileList;
