import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { GrClose } from "react-icons/gr";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { Button, Modal, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import SelectDropdownFilter from "components/Dropdown/SelectDropdownFilter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { BsThreeDots, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import placeholde from "../../../assets/img/placeholder.jpg";
import GuestEditDropdownpoolmenu from "../../../components/Dropdown/GuestEditDropdownpoolmenu";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { userGuestService } from "_services/masters/userGuest.service";
import { eventsService } from "_services/masters/events.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import EventGuestListDropdown from "components/Dropdown/EventGuestListDropdown";
import HonoredGuestlist from "components/Dropdown/HonoredGuestlist";
import ProgressIcon from "../../../assets/img/progressIcon.svg";
import MessageIcon from "../../../assets/img/message-icon.svg";
import YellowCircle from "../../../assets/img/yellow-circle.svg";
import GreenCircle from "../../../assets/img/green-circle.svg";
import RedCircle from "../../../assets/img/red-circle.svg";
import BlueCircle from "../../../assets/img/blue-circle.svg";
import { combinedService } from "../../../_services/masters/information.combined";
import { informationService } from "../../../_services/masters/information.service";
import { ReactSession } from "react-client-session";
import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import SocialMediaList from "../../../components/model-form/social-media-list";
import * as actionTypes from "../../../store/actionTypes";
import { BiDownArrowCircle } from "react-icons/bi";
import {
  FaUserAlt,
  FaSearch,
  FaPhoneAlt,
  FaPlus,
  FaChevronDown,
} from "react-icons/fa";
import ExportGuestIcon from "./../../../assets/img/exportGuestListIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import GuestGrouplist from "components/Dropdown/GuestGrouplist";
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import LockInvertIcon from "./../../../assets/img/lock-invert-icon.svg";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import en from "react-phone-number-input/locale/en.json";
import { useParams } from "react-router-dom";
import DeleteGuestSingle from "./GuestDeleteSingle";
import ExportGuestList from "./ExportGuestList";
import { commonData, guestsLists } from "store/actions";
const { SearchBar } = Search;
const GuestList = () => {
  let history = useHistory();
  const percentage = 66;
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  const allguestListf2q = useSelector((state) => {
    return state.guestList;
  });
  const allguestList = useSelector((state) => {
    return state.guestList.guestsList;
  });
  const [exportGuestList, setExportGuestList] = useState(false);
  const [guestgroupid, setGuestgroupid] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [guestData, setGuestData] = useState({});

  const headers = [
    { label: "RSVP", key: "rsvp_status" },
    { label: "Sir name", key: "surname" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Suffix", key: "suffix" },
    { label: "Email", key: "email" },
    { label: "Mobile number", key: "mobile_number" },
  ];
  const filterBy = [
    {
      img: EditPencilIcon,
      title: " All Guests",
    },
    {
      img: ProgressIcon,
      title: "Incomplete Records",
    },
    {
      img: MessageIcon,
      title: "Have Messaged",
    },
    {
      img: YellowCircle,
      title: "Pending",
    },
    {
      img: GreenCircle,
      title: "Going",
    },
    {
      img: RedCircle,
      title: "Not Going",
    },
    {
      img: BlueCircle,
      title: "Attended",
    },
  ];

  const onClickguestGroup = (e, id) => {
    setGuestgroupid(e);

    let obj = {};
    obj["user_id"] = id;
    obj["type"] = "guest_group";
    obj["event_id"] = common.eid;
    obj["drop_down_data"] = e;


    userGuestService.updateDropDown(obj).then(
      (res) => {
        if (res.status == "success") {
          //setCount(200)
          toast.success("updated successfully");
          sessionStorage.removeItem("row_id");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const [guestGroupList, setGuestGroupList] = useState([]);
  const [country, setCountry] = React.useState("");
  const [eventItemsid, setEventItemsid] = useState([]);
  const [eventList, seteventList] = React.useState();
  const [eventLists, seteventLists] = React.useState();
  const [activeBtn, setActiveBtn] = useState(false);
  const onClickEvent = (e, id) => {
    setEventItemsid(e);

    // allDataget();
    // surnamefu();
    // suffixlistguest();

    let obj = {};
    obj["user_id"] = id;
    obj["type"] = "guest_event";
    obj["drop_down_data"] = e;

    //console.log('roleItems onAdd --> ', obj);

    userGuestService.updateDropDown(obj).then(
      (res) => {
        if (res.status == "success") {
          toast.success("updated successfully");
          sessionStorage.removeItem("row_id");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const handleExportGuestList = () => {
    setExportGuestList(!exportGuestList);
    setActiveBtn(false);
  };

  useEffect(() => {
    seteventList(common.eventList);
  }, [common]);

  function datatolist() {
    const id = { event_id: common.eid };
    combinedService.guestGroupList(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestGroupList(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const params = useParams();

  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [currentId, setCurrentId] = useState("");

  useEffect(() => {
    dispatch({ type: actionTypes.GUEST_LIST_CLEAR, payload: "" });
  }, []);

  const addElement = (e, id) => {
    let obj = {};
    obj["user_id"] = id;
    obj["type"] = "social_media";
    obj["social_media"] = e;
    informationService.updateSocialmedia(obj).then(
      (res) => {
        if (res.status === "success") {
          allDataget();
          surnamefu();
          suffixlistguest();
          toast.success("updated successfully");
          sessionStorage.removeItem("row_id");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const [guestRsvpsStatus, setGuestRsvpsStatus] = useState("");
  const handleStatusOfGuestUser = (d) => {
    setSingleData(d);
    setGuestRsvpsStatus(d.rsvp_status);
    setStatusGuestUser(!statusGuestUser);
  };
  const [prefix, setPrefix] = useState("");
  const [listPrefix, setListPrefix] = useState([]);
  const [listSuffi, setListSuffi] = useState([]);
  const [suffix, setSuffix] = useState("");
  const [resetting, setResetting] = useState(false);
  const [roleItemsid, setRoleItemsid] = useState([]);

  const onClickRole = (e, gid) => {
    let obj = {};
    obj["user_id"] = gid;
    obj["type"] = "guest_relation";
    obj["event_id"] = common.eid;

    obj["drop_down_data"] = e;

    userGuestService.updateDropDown(obj).then(
      (res) => {
        if (res.status == "success") {
          allDataget();
          toast.success("updated successfully");
          sessionStorage.removeItem("row_id");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => onChange(event.target.value || undefined)}
    // onChange={(event) => onChange(() => {

    //     let kayVal = {}
    //     kayVal['val'] = event.target.value;
    //     kayVal['key'] = 'country_code';
    //     kayVal['id'] = sessionStorage.getItem("row_id");
    //     userGuestService.updateData(kayVal).then(
    //         (res) => {
    //             if (res.status === "success") {
    //                 allDataget();
    //                 surnamefu();
    //                 suffixlistguest();
    //                 sessionStorage.removeItem("row_id");
    //                 toast.success('updated successfully');
    //             } else {
    //                 allDataget();
    //                 surnamefu();
    //                 suffixlistguest();
    //                 console.log("Something went wrong !!");
    //                 toast.error(res.message);
    //                 sessionStorage.removeItem("row_id");
    //             }
    //         },
    //         (error) => {
    //             console.log("Something went wrong !!");
    //             toast.error("Something went wrong !!", "Fashion Store");
    //         }
    //     );

    //     kayVal['val'] = `+${getCountryCallingCode(event.target.value)}`;
    //     kayVal['key'] = 'mobile_number';
    //     kayVal['id'] = sessionStorage.getItem("row_id");

    //     userGuestService.updateData(kayVal).then(
    //         (res) => {
    //             if (res.status === "success") {
    //                 allDataget();
    //                 surnamefu();
    //                 suffixlistguest();
    //                 sessionStorage.removeItem("row_id");

    //                 // toast.success('updated successfully');
    //             } else {
    //                 allDataget();
    //                 surnamefu();
    //                 suffixlistguest();
    //                 console.log("Something went wrong !!");
    //                 toast.error(res.message);
    //                 sessionStorage.removeItem("row_id");
    //             }
    //         },
    //         (error) => {
    //             console.log("Something went wrong !!");
    //             toast.error("Something went wrong !!", "Fashion Store");
    //         }
    //     );
    // })}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {country} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );
  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };
  const handleReseting = () => {
    setResetting(true);
    let data12 = {
      guest_id: singleData.id,
      event_id: eventLists.id,
      rsvp_status: guestRsvpsStatus ? guestRsvpsStatus : "",
    };
    userGuestService.updateRsvpStatus(data12).then((res) => {
      if (res.status === "success") {
        toast.success("RSVP Status upload successfully !!", "Account");
        allDataget();
        surnamefu();
        suffixlistguest();
      } else {
        allDataget();
        surnamefu();
        suffixlistguest();
      }
    });

    setTimeout(() => {
      setResetting(false);
      handleStatusOfGuestUser(false);
    }, 1000);
  };
  // useEffect(() => {
  //     let kayVal = {}
  //     kayVal['val'] = country;
  //     kayVal['key'] = 'suffix';
  //     kayVal['id'] = sessionStorage.getItem("row_id");
  //     userGuestService.updateData(kayVal).then(
  //         (res) => {
  //             if (res.status === "success") {
  //                 allDataget();
  //                 surnamefu();
  //                 suffixlistguest();

  //                 toast.success('updated successfully');
  //             } else {
  //                 allDataget();
  //                 surnamefu();
  //                 suffixlistguest();
  //                 console.log("Something went wrong !!");
  //                 toast.error(res.message);
  //             }
  //         },
  //         (error) => {
  //             console.log("Something went wrong !!");
  //             toast.error("Something went wrong !!", "Fashion Store");
  //         }
  //     );
  // }, [country])

  function handleChangesuffix(dateValue, id) {
    let kayVal = {};
    kayVal["val"] = dateValue;
    kayVal["key"] = "suffix";
    kayVal["id"] = id;

    userGuestService.updateData(kayVal).then(
      (res) => {
        if (res.status === "success") {
          allDataget();
          surnamefu();
          suffixlistguest();

          sessionStorage.removeItem("row_id");
          toast.success("updated successfully");
        } else {
          allDataget();
          surnamefu();
          suffixlistguest();
          console.log("Something went wrong !!");
          toast.error(res.message);
          sessionStorage.removeItem("row_id");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const numberphone = (e, code) => {


    if (isValidPhoneNumber(e) === true) {
      toast.success("phone number is creact  !!!");
    } else {
      toast.error("Invalid phone number !!!");
    }
    //console.log(code);
  };

  function handleChangeprefix(dateValue, id) {
    let kayVal = {};
    kayVal["val"] = dateValue;
    kayVal["key"] = "surname";
    kayVal["id"] = id;

    userGuestService.updateData(kayVal).then(
      (res) => {
        if (res.status === "success") {
          allDataget();
          surnamefu();
          suffixlistguest();

          sessionStorage.removeItem("row_id");
          toast.success("updated successfully");
        } else {
          allDataget();
          surnamefu();
          suffixlistguest();
          console.log("Something went wrong !!");
          toast.error(res.message);
          sessionStorage.removeItem("row_id");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  function updateData(key, val, id, old_value) {
    if (old_value === val) {
    } else {
      if (key === "first_name" && val === "") {
        toast.error("First name is required");
      } else if (key === "last_name" && val === "") {
        toast.error("Last name is required");
      } else {
        let kayVal = {};
        kayVal["val"] = val;
        kayVal["key"] = key;
        kayVal["id"] = id;
        userGuestService.updateData(kayVal).then(
          (res) => {
            if (res.status === "success") {
              allDataget();
              surnamefu();
              suffixlistguest();
              toast.success("updated successfully");
              sessionStorage.removeItem("row_id");
            } else {
              allDataget();
              surnamefu();
              suffixlistguest();
              console.log("Something went wrong !!");
              toast.error(res.message);
              sessionStorage.removeItem("row_id");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }
    }
  }
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
    setGuestData(e);
  };
  const [exportGuestlist, setexportGuestlist] = useState(false);
  const handleDeleteGuestUserex = (e) => {
    setexportGuestlist(!exportGuestlist);
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: `/admin/edit-user-guest-list/${common?.sid}/${common?.eid}`,
      type: "Guest",
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
      link: `/admin/guest-keepsake-album/${common?.sid}/${common?.eid}`,
      type: "Guest",
    },
    {
      img: LockInvertIcon,
      title: "Event Authentication",
      link: `/admin/event-guest-authentication/${common?.sid}/${common?.eid}`,
      type: "Guest",
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteGuestUser,
    },
  ];
  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      // headerStyle: () => {
      //     return {
      //         whiteSpace: "nowrap",
      //         position: "sticky",
      //         left: "0",
      //         background: "white",
      //         zIndex: 100
      //     };
      // },
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
      formatter: (value, row) => (
        <div className="d-flex align-items-center">
          <GuestEditDropdownpoolmenu
            guestListOption={guestListOption}
            data={row}
          />
        </div>
      ),
    },
    {
      dataField: "first_name",
      text: (
        <div>
          <FaUserAlt className="userIcon" /> Guest
        </div>
      ),
      formatter: (value, row) => (
        <div className="guestUserCard">
          <div className="guestUserPic">
            <img
              src={row.imageUrl ? row.imageUrl : placeholde}
              className="pic"
              alt=""
            />
          </div>
          <div className="guestUserBody">
            <div className="title">
              {row.first_name} {row.last_name}
            </div>
            <div className="subTitle">Sibling of Bride</div>
          </div>
        </div>
      ),
      // headerStyle: () => {
      //     return {
      //         whiteSpace: "nowrap",
      //         position: "sticky",
      //         left: "0",
      //         background: "white",
      //         zIndex: 100
      //     };
      // },
      selector: (row) => row.guest,
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      text: (
        <div className="guestCircleWrapper ">
          <CircularProgressbar
            value={percentage}
            text={false}
            strokeWidth={20}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#53D769 ",
              trailColor: "#1345A5",
            })}
          />
        </div>
      ),
      // headerStyle: () => {
      //     return {
      //         whiteSpace: "nowrap",
      //         position: "sticky",
      //         left: "0",
      //         background: "white",
      //         zIndex: 100
      //     };
      // },
      formatter: (value, row) => (
        <div className="guestCircleWrapper">
          <CircularProgressbar
            value={
              row.guest_complete_status ? row.guest_complete_status : percentage
            }
            text={false}
            strokeWidth={20}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#53D769 ",
              trailColor: "#1345A5",
            })}
          />
        </div>
      ),
    },

    {
      dataField: "first_name",
      text: "RSVP",
      formatter: (value, row) => (
        <span
          className={`RSVPchip ${row.rsvp_status
            .toLowerCase()
            .replaceAll(" ", "")}`}
          onClick={() => handleStatusOfGuestUser(row)}
        >
          {row.rsvp_status}
        </span>
      ),

      selector: (row) => row.rsvp,
    },
    {
      text: "Sirname",
      formatter: (value, row) => (
        <div className="form-group flex-grow-1">
          <GuestPrefixDropdown
            placeholder={row.surname ? row.surname : ""}
            guestPrefixOption1={listPrefix}
            caption="Pope"
            addUserBtn
            handleAddModal={""}
            handlerFunction={handleChangeprefix}
            gid={row.id}
          />
        </div>
      ),
    },
    {
      text: "First Name",
      formatter: (value, row) => (
        <input
          className="formInput"
          type="text"
          name="name"
          defaultValue={row.first_name}
          placeholder="First Name"
          onBlur={(e) =>
            updateData("first_name", e.target.value, row.id, row.first_name)
          }
        />
      ),
    },
    {
      text: "Last Name",
      formatter: (value, row) => (
        <input
          className="formInput"
          type="text"
          name="name"
          defaultValue={row.last_name}
          placeholder="Last Name"
          onBlur={(e) =>
            updateData("last_name", e.target.value, row.id, row.last_name)
          }
        />
      ),
    },
    {
      text: "Suffix",
      selector: (row) => row.suffix,
      formatter: (value, row) => (
        <div className="form-group flex-grow-1">
          <GuestPrefixDropdown
            placeholder={row.suffix ? row.suffix : ""}
            guestPrefixOption1={listSuffi}
            caption="Grand Wizard"
            addUserBtn
            handleAddModal={""}
            handlerFunction={handleChangesuffix}
            gid={row.id}
          />
        </div>
      ),
    },
    {
      text: "Email Address",
      formatter: (value, row) => (
        <input
          className="formInput"
          type="text"
          name="name"
          value={row.email}
          placeholder="Email"
          readOnly
        />
      ),
      selector: (row) => row.email,
    },
    {
      text: "Country Code",
      selector: (row) => row.country_code,
      formatter: (value, row) => (
        <div>
          <CountrySelect
            labels={en}
            value={row.country_code}
            name="country_code"
            className="form-control"
            onChange={setCountry}
          />
        </div>
      ),
    },
    {
      text: "Phone Number",
      selector: (row) => row.phone,
      formatter: (value, row) => (
        <>
          {/* <input
                        className="formInput"
                        type="text"
                        name="name"
                        defaultValue={row.mobile_number}
                        placeholder="Phone Number"
                        onBlur={(e) => updateData('mobile_number', e.target.value, row.id)}
                    /> */}

          <PhoneInput
            className="formInput"
            name="mobile_number"
            value={row.mobile_number}
            placeholder="Phone number"
            onChange={(e) => numberphone(e, row.country_code)}
          />
          {/* <div className={`mobile_number_${row.id}`}>sadfdfsdfsdf</div> */}
        </>
      ),
    },

    {
      text: "Greeting",
      selector: (row) => row.greeting,
      formatter: (value, row) => (
        <input
          className="formInput"
          type="text"
          name="name"
          defaultValue={row.guest_personal_greeting}
          placeholder="Type short greeting here.."
          onBlur={(e) =>
            updateData("guest_personal_greeting", e.target.value, row.id)
          }
        />
      ),
    },
    {
      text: "Guest Honors",
      selector: (row) => row.guestHonors,
      formatter: (value, row) => (
        <div
          className="EventdropdownOuter"
        // onClick={() => {
        //   sessionStorage.setItem("row_id", row.id);
        // }}
        >
          <HonoredGuestlist
            placeholder="Find more Honored roles"
            onClickrole={onClickRole}
            selectdata={row.guest_relation}
            gid={row.id}
          />
        </div>
      ),
    },
    {
      text: "Groups",
      selector: (row) => row.groups,
      formatter: (value, row) => (
        <div className="EventdropdownOuter">
          <GuestGrouplist
            placeholder="Find guest group"
            guestListOption={guestGroupList}
            onChange={onClickguestGroup}
            data={row.res_group_type}
            gid={row.id}
          />
        </div>
      ),
    },
    {
      text: "Events Invited to",
      selector: (row) => row.eventsInvitedTo,
      formatter: (value, row) => (
        <div className="EventdropdownOuter">
          <EventGuestListDropdown
            placeholder="Find more events "
            guestListOption={eventList}
            onChange={onClickEvent}
            rowData={row.inviteEvent}
            gid={row.id}
          />
        </div>
      ),
    },
    {
      text: "Admin Notes",
      selector: (row) => row.adminNotes,
      formatter: (value, row) => (
        <input
          className="formInput"
          type="text"
          name="name"
          defaultValue={row.admin_notes_on_guest}
          placeholder="Admin notes here"
          onBlur={(e) =>
            updateData(
              "admin_notes_on_guest",
              e.target.value,
              row.id,
              row.admin_notes_on_guest
            )
          }
        />
      ),
    },
    {
      text: "Contact",
      selector: (row) => row.contact,
      formatter: (value, row) => (
        <div>
          <Link className="contactIcon" size={24} to="/">
            <BsFillChatDotsFill />
          </Link>
          <a className="contactIcon" size={24} href={`mailTo:${row?.eamil}`}>
            {" "}
            <AiOutlineMail />
          </a>
          <a
            className="contactIcon"
            size={24}
            href={`tel:${row?.mobile_number}`}
          >
            {" "}
            <FaPhoneAlt />
          </a>
        </div>
      ),
    },
    {
      text: "Social Media",
      selector: (row) => row.socialMedia,
      formatter: (value, row) => (
        <div className="guestListSocial d-flex align-items-center">
          <SocialMediaList
            data={row}
            socialmediadata={row.social_media ? row.social_media : []}
            add={addElement}
            gid={row.id}
          />
          {row.social_media?.length > 0 &&
            row.social_media?.map((user) => (
              <div key={Math.random()}>
                {user.name === "facebook" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={FacebookIcon} alt="" />
                  </a>
                )}
                {user.name === "instagram" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={InstagramIcon} alt="" />
                  </a>
                )}
                {user.name === "tiktok" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={TiktokIcon} alt="" />
                  </a>
                )}
                {user.name === "twitter" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={TwitterIcon} alt="" />
                  </a>
                )}
                {user.name === "youtube" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={YoutubeIcon} alt="" />
                  </a>
                )}
                {user.name === "vimeo" && (
                  <a target="_blank" href={user.value} className="socialIcon">
                    <img src={VimeoIcon} alt="" />
                  </a>
                )}
              </div>
            ))}
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const [userguestList, setUserguestList] = React.useState([]);
  const [userAll, setUserAll] = React.useState([]);

  useEffect(() => {
    if (statusValue != "") {
      const filtered2 = allguestList.filter((employee) => {
        return employee.rsvp_status === statusValue;
      });
      setUserguestList(filtered2);
    } else {
      setUserguestList(allguestList);
    }
    setUserAll(allguestList);
  }, [allguestList, statusValue]);
  function allDataget() {
    userGuestService.userGuestList(eid).then((res) => {
      if (res.status === "success") {
        dispatch(guestsLists(res.data));
      } else {
        setUserguestList();
      }
    });
  }

  function suffixlistguest() {
    userGuestService.listSuffix().then(
      (res) => {
        if (res.status === "success") {
          setListSuffi(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  function surnamefu() {
    userGuestService.listPrefix().then(
      (res) => {
        if (res.status === "success") {
          setListPrefix(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    allDataget();
    surnamefu();
    suffixlistguest();
    datatolist();
    const id12 = { event_id: eid };
    eventsService.eventDetails(id12).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventLists(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    sessionStorage.removeItem("row_id");
  }, [eid]);
  useEffect(() => {
    informationService.eventList(common.sid).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    sessionStorage.removeItem("row_id");
  }, []);
  const SelectDropdownonClike = (e) => {
    //const [userguestList, setUserguestList] = React.useState([]);
    setStatusValue(e.toUpperCase());
    if (
      e === "Pending" ||
      e === "Going" ||
      e === "Not Going" ||
      e === "Attended"
    ) {
      const filtered2 = userAll.filter((employee) => {
        return employee.rsvp_status === e.toUpperCase();
      });
      setUserguestList(filtered2);
    } else {
      setStatusValue("");
      setUserguestList(userAll);
    }
  };

  /***Datat Set */
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (params?.eid) {
      const id = { event_id: params?.eid };
      eventsService.eventDetails(id).then(
        (res) => {
          if (res.status === "success") {
            let sid = res.data.storyz_id;
            let eid = res.data.id;
            let storyName = res.data.storyz_name;
            let singleEvent = res.data;
            dispatch(commonData(common));
            myFunctionList(sid, eid, storyName, singleEvent);
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [params]);
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  /*Data Set */



  return (
    <>
      {allguestListf2q.isConfirmatiom === true && userguestList ? (
        <ToolkitProvider
          keyField="id"
          data={userguestList}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="guestSearchWrapper">
                <div className="searchWrapper">
                  <button className="searchBtn">
                    <FaSearch />
                  </button>
                  {/* <input
                    type="text"
                    name="search"
                    value=""
                    placeholder="search by...."
                  /> */}
                  <SearchBar
                    className="formInput searchInput"
                    {...props.searchProps}
                  />
                </div>

                <div className="showEntries">
                  <span>Filter by</span>
                  <SelectDropdownFilter
                    guestListOption={filterBy}
                    SelectDropdownonClike={SelectDropdownonClike}
                    selectbox="All Guests"
                  />
                </div>
                <div className="guestUserWrap">
                  <div
                    className="addGuest"
                    onClick={() => {
                      history.push(
                        `/admin/add-user-guest-list/${sid}/${eid}/?type=guests`
                      );
                    }}
                  >
                    <FaUserAlt className="icon" />
                    <div>Add New Guest</div>
                  </div>
                  <div className="exportWrap" onClick={handleDeleteGuestUserex}>
                    <RiArrowDownCircleLine className="icon" />
                    <div>Export</div>
                  </div>
                </div>
              </div>
              {/* <div className="organizeByGroupOuter">
                <div className="organizeByGroupWrapper">
                  <div className="form-check">
                    <input
                      className="form-check-input formCheckInput"
                      type="checkbox"
                      name=""
                      value=""
                      id="organizeByGroup"
                    />
                    <label className="form-check-label" htmlFor="organizeByGroup">
                      Organize by Group
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="useGuestListTableWrapper">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                  noDataIndication="No Data to Display"
                  pagination={pagination}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TailSpin
            className="loader"
            color="#00BFFF"
            height={120}
            width={120}
            style={{ margin: "auto" }}
          />
        </div>
      )}
      <Modal
        isOpen={statusGuestUser}
        toggle={handleStatusOfGuestUser}
        //  {...args}
        className="resetGeospaceModal statusofGuestaListModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Force the RSVP Status for This Guest?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img
                    src={
                      eventLists?.cover_image
                        ? eventLists?.cover_image
                        : placeholde
                    }
                    alt=""
                    className="eventThumImg"
                  />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">
                    {" "}
                    {eventLists?.event_name}
                  </div>
                  <div className="eventthumbSubTitle">
                    {" "}
                    {eventLists?.location}
                  </div>
                </div>
              </div>
              <div className="geospaceThumWrapper">
                <div className="geospaceThum">
                  <img
                    src={
                      singleData?.imageUrl ? singleData?.imageUrl : placeholde
                    }
                    alt=""
                    className="geospaceThumImg"
                  />
                </div>
                <div className="geospaceThumContent">
                  <div className="geothumbTitle">
                    {singleData?.first_name} {singleData?.last_name}
                  </div>
                  <div className="geothumbSubTitle">Event Role</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                You are manually setting the status of this Guest's RSVP. Select
                an RSVP state belolow by clicking one of the following buttons:
              </p>
              <hr className="mb-4 mt-3" />
              <div className="statusGuestUserWrapper mb-3">
                <div className="form-check pending">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1PENDING"
                    value="PENDING"
                    onChange={(e) => setGuestRsvpsStatus("PENDING")}
                    checked={guestRsvpsStatus === "PENDING"}
                  />
                  <label
                    className="form-check-label"
                    for="flexRadioDefault1PENDING"
                  >
                    PENDING
                  </label>
                </div>
                <div className="form-check going">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1GOING"
                    value="GOING"
                    onChange={(e) => setGuestRsvpsStatus("GOING")}
                    checked={guestRsvpsStatus === "GOING"}
                  />
                  <label
                    className="form-check-label"
                    for="flexRadioDefault1GOING"
                  >
                    GOING
                  </label>
                </div>
                <div className="form-check notGoing">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1NOT"
                    value="NOT GOING"
                    onChange={(e) => setGuestRsvpsStatus("NOT GOING")}
                    checked={guestRsvpsStatus === "NOT GOING"}
                  />
                  <label
                    className="form-check-label"
                    for="flexRadioDefault1NOT"
                  >
                    NOT GOING
                  </label>
                </div>
                <div className="form-check attended">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1ATTENDED"
                    value="ATTENDED"
                    onChange={(e) => setGuestRsvpsStatus("ATTENDED")}
                    checked={guestRsvpsStatus === "ATTENDED"}
                  />
                  <label
                    className="form-check-label"
                    for="flexRadioDefault1ATTENDED"
                  >
                    ATTENDED
                  </label>
                </div>
              </div>
              <p>
                Are you sure you want to do this? This will erase the RSVP
                status set by the system.
              </p>
            </Col>
          </Row>

          <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={handleReseting}
            >
              Force RSVP Status
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleStatusOfGuestUser(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={resetting}
        toggle={handleReseting}
        //  {...args}
        className="resetRSVPModal"
      >
        <ModalBody className="pb-0">
          <div className="border-bottom mb-3 pb-3">
            <div className="head">Resetting RSVPs</div>
          </div>
          <Row>
            <Col className="px-4">
              <p className=" px-4 text-center">
                Changing the RSVP Status for this guest...
              </p>
              <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                <div className="circleAnimation"></div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {deleteGuestUser && (
        <DeleteGuestSingle
          show={deleteGuestUser}
          onHide={handleDeleteGuestUser}
          single={guestData}
        />
      )}

      {exportGuestlist && (
        <ExportGuestList
          show={exportGuestlist}
          onHide={handleDeleteGuestUserex}
        />
      )}

      <ToastContainer />
    </>
  );
};
export default GuestList;
