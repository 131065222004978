import React from "react";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import { userService } from "../../_services/login/user.service";
import Cookies from "js-cookie";
function SocialFacebookLogin() {
    const history = useHistory();
    const responseFacebook = (response) => {
        console.log("FB", response);
        if (response.error) {
            console.log(response);
        } else {
            userService.scoialloginf(response).then(
                (res) => {
                    if (res.status === "success") {
                        console.log("1");
                        localStorage.setItem("userDetails", JSON.stringify(res.data));

                        var retrievedObject = JSON.parse(
                            localStorage.getItem("userDetails")
                        );
                        if (retrievedObject.name !== Cookies.get("name")) {
                            Cookies.set("name", retrievedObject.name);
                        }
                        history.push("/admin/account-information");
                    } else if (res.status === "error") {
                        console.log("2");
                        // localStorage.removeItem("userDetails");
                        // toast.error(res.message, "Login");
                    } else {
                        console.log("3");
                        //localStorage.removeItem("userDetails");
                        // toast.error("Please enter valid username and password !!", "Login");
                    }
                },
                (error) => {
                    // localStorage.removeItem("userDetails");
                    // toast.error("Invalid Credetials !!", "Login");
                }
            );
        }
    };
    return (
        <>
            <FacebookLogin
                appId="310943204761050"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                icon="fa-facebook"
            />
        </>
    );
}
export default SocialFacebookLogin;
