import React, { useEffect, useState } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { FaUserAlt, FaSearch } from "react-icons/fa";
import moment from "moment";
import { RiArrowDownCircleLine } from "react-icons/ri";
import {
  BsThreeDots,
  BsFillChatDotsFill,
  BsChevronCompactLeft,
} from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { GrCircleInformation, GrClose } from "react-icons/gr";
import { GuestEventWrapper } from "./style";
import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
  useFindPathid,
} from "../../../CommonData";
import Select from "react-select";
import DummyImg from "../../../assets/img/dummyImg.svg";
import QRCode from "react-qr-code";
import makeAnimated from "react-select/animated";
import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import GuestActionDropdown from "components/Dropdown/GuestActionDropdown";
import ExportGuestIcon from "./../../../assets/img/exportGuestListIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import { BiDownArrowCircle } from "react-icons/bi";

import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import LockInvertIcon from "./../../../assets/img/lock-invert-icon.svg";
import ImgIcon from "./../../../assets/img/Image-Icon.svg";
import { useSelector, useDispatch } from "react-redux";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import { Radio } from "react-loader-spinner";
import SelectDropdown from "components/Dropdown/SelectDropdown";
import ProgressIcon from "../../../assets/img/progressIcon.svg";
import MessageIcon from "../../../assets/img/message-icon.svg";
import YellowCircle from "../../../assets/img/yellow-circle.svg";
import GreenCircle from "../../../assets/img/green-circle.svg";
import RedCircle from "../../../assets/img/red-circle.svg";
import BlueCircle from "../../../assets/img/blue-circle.svg";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
import { useHistory } from "react-router-dom";
import { userGuestService } from "_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const animatedComponents = makeAnimated();
const EvenGuestAuthenticationDetail = ({ args, columns, data, name }) => {
  let history = useHistory();
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [exportGuestList, setExportGuestList] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [guestData, setGuestData] = useState({});
  const [qCode, setQCode] = useState({});
  const [showDate, setShowDate] = useState();
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let id = useFindPathid(5);
  const [errormsg, setErrormsg] = useState(true);

  const GuestListOptionList = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Sally Struthers",
      type: "Friend of Groom",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
  ];

  const filterBy = [
    {
      img: EditPencilIcon,
      title: " All Guests",
    },
    {
      img: ProgressIcon,
      title: "Incomplete Records",
    },
    {
      img: MessageIcon,
      title: "Have Messaged",
    },
    {
      img: YellowCircle,
      title: "Pending",
    },
    {
      img: GreenCircle,
      title: "Going",
    },
    {
      img: RedCircle,
      title: "Not Going",
    },
    {
      img: BlueCircle,
      title: "Attended Event",
    },
  ];

  const handleExportGuestList = () => {
    setExportGuestList(!exportGuestList);
  };

  const initialState = {
    guest_name: false,
    phone_number: false,
    email: false,
  };
  const [selectedSeats, setSelectedSeats] = useState(initialState);
  const toggleSeat = (id) => {
    setSelectedSeats((prev) => {
      return { ...prev, [id]: !prev[id] };
    });
  };
  function inSetData() {
    var d2 = {
      guest_id: id,
      event_id: eid,
      include_guest_name: selectedSeats.guest_name,
      include_phone_no: selectedSeats.phone_number,
      include_email_address: selectedSeats.email,
      include_custom_startup_screen: false,
    };

    userGuestService.InsetGuestauth(d2).then(
      (res) => {
        if (res.status === "success") {
          toast.success(
            "Guest Authentication updated successfully !!",
            "successfully"
          );
          listGuest_auth(guestData);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    userGuestService.guestDetails(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestData(res.data);
          const obj = {
            id: res.data.id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            type: "GuestDetails",
          };
          sessionStorage.setItem("detailsOfUser", JSON.stringify(obj));

          listGuest_auth(res.data);

          //setQCode(`{ User ID:- OurStoryz-${res.data.id}, name:- ${res.data.first_name} ${res.data.last_name}, email:- ${res.data.email}, Mobile number:- ${res.data.mobile_number} }`)
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [id]);

  function listGuest_auth(val) {
    var d2 = {
      guest_id: id,
      event_id: eid,
    };

    userGuestService.listGuestauth(d2).then(
      (res) => {
        if (res.status === "success") {
          let arr = res.data;
          if (arr.length > 0) {
            setSelectedSeats({
              guest_name: res.data[0].include_guest_name,
              phone_number: res.data[0].include_phone_no,
              email: res.data[0].include_email_address,
            });

            setErrormsg(true);
            setShowDate(res.data[0].updated_at);

            setQCode(
              `${res.data[0].include_guest_name === true
                ? "Guest name :- " +
                val.first_name +
                " " +
                val.last_name +
                "\n"
                : ""
              }${res.data[0].include_email_address === true
                ? "Guest Eamil :- " + val.email + "\n"
                : ""
              }${res.data[0].include_phone_no === true
                ? "Mobile number :- " + val.mobile_number + "\n"
                : ""
              }`
            );

            // setQCode(`{ Guest name:- ${res.data[0].include_guest_name === true ? guestData.first_name + ' ' + guestData.last_name : 'Not Available'}, email:- ${res.data[0].include_email_address === true ? guestData.email : 'Not Available'}, Mobile number:- ${res.data[0].include_phone_no === true ? guestData.mobile_number : 'Not Available'} }`)
          } else {
            setErrormsg(false);
          }
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const generate = () => {
    inSetData();
  };

  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {common?.singleEvent?.event_name}
            </div>
            <div className="d-flex align-items-center">
              <GuestListDropdown
                guestListOption={GuestListOptionList}
                placeholder="Guest Authentication"
              />
              <GrCircleInformation className="ml-3 pointer infoIcon" />
            </div>
          </div>
          <div className="eventBannerPara border-bottom mb-4">
            This page lets you view and create a todo list associated with this
            specific Storyz. <br /> To see the complete list of todos, go to the
            <Link
              onClick={() => {
                history.push(`/admin/storyz/${sid}`);
              }}
              className="text-decoration-underline mx-1"
            >
              Storyz view
            </Link>
            or
            <Link
              onClick={() => {
                history.push(`/admin/organization`);
              }}
              className="text-decoration-underline mx-1"
            >
              Organization view
            </Link>
            .
          </div>

          <div className="border-bottom px-4 pb-4 d-flex align-items-center">
            <div className="authenticationCard">
              <div className="backArrow">
                <BsChevronCompactLeft />
              </div>
              <div className="img">
                <img
                  src={guestData?.imageUrl ? guestData?.imageUrl : DummyImg}
                  alt=""
                />
              </div>
              <div className="content">
                <div className="title">
                  {guestData?.first_name} {guestData?.last_name}
                </div>
                <div className="role">Event Role</div>
              </div>
            </div>
            <div className="grayBtnDisabled ml-auto">
              <img
                alt="Organization Name"
                src={require("assets/img/icons/Chat-Vector2.svg").default}
              />
              Message
            </div>
          </div>
          <div className="p-4">
            <Row>
              <Col lg={12} md={12}>
                {errormsg === true ? (
                  ""
                ) : (
                  <div style={{ color: "red" }}>
                    Please select checkbox and generate QR Code
                  </div>
                )}
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img alt="" src={require("assets/img/qr-code.svg").default} />
                  QR Code
                </div>

                <div className="cardBox">
                  <div className="authenCardWrapper form-group">
                    <label>QR Code:</label>
                    <div className="qrCodeCard">
                      <div className="img">
                        {/* <img
                          alt=""
                          src={require("assets/img/qrcode.svg").default}
                        /> */}

                        <QRCode
                          size={240}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={qCode}
                          viewBox={`0 0 240 240`}
                        />
                      </div>
                      <div className="content">
                        <div className="title">
                          <div className="grayBtnDisabled" onClick={generate}>
                            <img
                              alt="Organization Name"
                              src={require("assets/img/recycle.svg").default}
                            />
                            Generate
                          </div>
                        </div>
                        <div className="role">
                          {showDate
                            ? "Last update : " +
                            moment(showDate).format("DD/MM/yyyy")
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cardBox">
                  <div className="form-group qrCodeList">
                    <label>Include in QR Code:</label>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Guest Name
                        <input
                          type="checkbox"
                          name="guest_name"
                          checked={selectedSeats.guest_name}
                          id="guest_name"
                          onChange={() => toggleSeat("guest_name")}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Phone Number
                        <input
                          type="checkbox"
                          name="phone_number"
                          checked={selectedSeats.phone_number}
                          id="phone_number"
                          onChange={() => toggleSeat("phone_number")}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="custom-control form-check form-check-inline">
                      <label className="container3 form-check-label">
                        Email Address
                        <input
                          type="checkbox"
                          name="email"
                          id="email"
                          checked={selectedSeats.email}
                          onChange={() => toggleSeat("email")}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div
                      className="custom-control form-check form-check-inline"
                      style={{ pointerEvents: "none", opacity: "0.4" }}
                    >
                      <label className="container3 form-check-label">
                        Custom Startup Screen
                        <input type="checkbox" name="" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img
                    alt=""
                    src={require("assets/img/pencil-icon.svg").default}
                  />
                  ID Validation
                </div>
                <div className="cardBox">
                  <div className="idCardWrapper disabled">
                    <div className="idCard">
                      <div className="img">
                        <img
                          alt=""
                          src={require("assets/img/avatarImg.svg").default}
                        />
                      </div>
                      <div className="text">ID card</div>
                    </div>
                    <div className="content">
                      <div className="title">
                        <div className="grayBtnDisabled">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/recycle.svg").default}
                          />
                          Generate
                        </div>
                      </div>
                      <div className="date">Last update 8/09/2022</div>
                    </div>
                  </div>
                </div>
                <div className="basicinformation">
                  <img
                    alt=""
                    src={require("assets/img/pencil-icon.svg").default}
                  />
                  Document Upload
                </div>
                <div className="cardBox">
                  <div className="authenDocWrapper disabled">
                    <div className="authenDocList">
                      <div className="doc"></div>
                      <div className="text">Document Name</div>
                    </div>
                    <div className="authenDocList">
                      <div className="doc"></div>
                      <div className="text">Document Name</div>
                    </div>
                    <div className="grayBtnDisabled">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/recycle.svg").default}
                      />
                      Upload Document
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Eventstyle>
      <ToastContainer />
    </>
  );
};

export default EvenGuestAuthenticationDetail;
