import React, { useState, useEffect, useRef } from "react";
import FileStackApp from "../FileStackApp/FileStackAll";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { ReactSession } from "react-client-session";
import placeholde from "../../assets/img/placeholder.jpg";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import { userService } from "../../_services/login/user.service";
import { BsArrowDown, BsArrowUp, BsArrowRight, BsXLg } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
function FilemanagerCsv({
  show,
  onHide,
  onClickimageUpdate,
  list,
  seletedImage,
}) {
  const inputRef = useRef();
  const [imgModalBoxfirst, setImgModalBoxfirst] = useState(show);

  const [imgModalcsvBoxfirst, setImgModalcsvBoxfirst] = useState(false);

  const [cur_section, setCurSection] = React.useState({});

  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dbdata, setDbdata] = useState([
    "PREFIX",
    "FIRST NAME",
    "LAST NAME",
    "SUFFIX",
    "COUNTRY CODE",
    "MOBILE NUMBER",
    "EMAIL ADDRESS",
  ]);
  const [file, setFile] = useState("");
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      setFile(inputFile);
      // If user clicks the parse button without
      // a file we show a error
      // if (!file) return setError("Enter a valid file");

      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();

      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        setCsvData(parsedData);
        const columns = Object.keys(parsedData[0]);
        setData(columns);
      };
      reader.readAsText(inputFile);
      //onHide();
      setImgModalcsvBoxfirst(true);
    }
  };
  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find(
      (item, index) => index === event.oldIndex
    );
    const remainingItems = originalArray.filter(
      (item, index) => index !== event.oldIndex
    );

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];

    return reorderedItems;
  };
  function changeOrder(index, direction) {
    setData(
      reorderArray(
        { oldIndex: index, newIndex: index + (direction === "UP" ? -1 : 1) },
        data
      )
    );
  }
  useEffect(() => {
    const newArr = csvData.map((e) =>
      data.reduce((obj, key) => ({ ...obj, [key]: e[key] }), {})
    );


    userService.getGuestColumn(data).then(
      (res) => {
        if (res.status === "success") {
          // setDbdata(res.column);
          // console.log(res.column);
          //history.push(`/admin/storyz-todos`);
        } else {
          console.log("Invalid Credetials !!", "Login");
        }
      },
      (error) => {
        console.log("Invalid Credetials !!", "Login");
      }
    );
  }, [data]);

  const functioncheck = () => {
    // alert('hello');
  };

  return (
    <>
      <div>
        {imgModalBoxfirst && (
          <div
            className={`modal fade mediaFileListModal ${imgModalBoxfirst ? "show" : ""
              }`}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title">Storyz Files</h1>
                  <div className="btn-close" onClick={onHide}>
                    <svg
                      className="closeBtn"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="importImageListWrapper">
                    <div className="d-flex justify-content-end">
                      <input
                        type="file"
                        name="file"
                        id="upload"
                        hidden
                        onChange={handleFileChange}
                      />
                      <label for="upload" className="pointer">
                        Import New
                      </label>
                    </div>
                    <div className="importedImageListWrapper">
                      <div className="importedImageListInner">
                        {list.map((x, i) => (
                          <div
                            className={
                              x.id === cur_section.id
                                ? "importedImageList selected"
                                : "importedImageList"
                            }
                            key={i}
                            onClick={() => setCurSection(x)}
                          >
                            <div className="uploadedItem">
                              <div className="uploadedImg">
                                <img
                                  src={x.url ? x.url : placeholde}
                                  alt=""
                                  className="thum"
                                />
                              </div>
                            </div>
                            <div className="filename">{x.filename}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div onClick={onHide} className="btn selectFilebtn whiteBg">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {imgModalcsvBoxfirst && (
        <div
          className={`modal fade mediaFileListModal selectUploadDownloadModal ${imgModalcsvBoxfirst ? "show" : ""
            }`}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Select upload/download option</h1>
                <div className="btn-close" onClick={onHide}>
                  <svg
                    className="closeBtn"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <ol className="listOption">
                  <li>Control what you upload.</li>
                  <li>
                    To turn uploads of individual fields on and off, select the
                    green arrows or red “X” icons.
                  </li>
                  <li>
                    Use the arrows at left to move your fields up and down to
                    match.
                  </li>
                  <li>Select “add spacer” to create an empty field</li>
                </ol>
                <div className="selectUploadCheckbox">
                  <div className="custom-control form-check form-check-inline">
                    <label className="container3 form-check-label">
                      Upload to all events in the Storyz
                      <input type="checkbox" name="" checked />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                {/* <div className="importImageListWrapper">
                  <div className="importedImageListWrapper">
                    <div className="importedImageListInner"> */}
                <div className="row mb-3">
                  <div className="col-md-5">
                    <div className="selectedUploaedFile">
                      <div className="selectedUploaedFileImg">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/CSVIcon.svg").default}
                        />
                      </div>
                      <div>
                        <div className="title">File: Something.xls</div>
                        <div className="subTitle">
                          Row 263 of 264 records used
                        </div>
                      </div>
                    </div>
                    <div className="addSpacerBtn">
                      <div className="addSpacerBtnTxt">add spacer</div>{" "}
                      <FiPlus className="icon" />
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5">
                    <div className="selectUploadGuestList">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/userProfileIcon.svg").default}
                      />
                      OurStoryz Guest List
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <table className="uploadDownloadTableWrapper">
                      {data.map((col, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <div className="arrowUpDown">
                                <div onClick={() => changeOrder(idx, "UP")}>
                                  <BsArrowUp />
                                </div>
                                <div onClick={() => changeOrder(idx, "DOWN")}>
                                  <BsArrowDown />
                                </div>
                              </div>
                            </td>
                            <td>{col}</td>
                            {/* {idx < dbdata.length ? <td><BsArrowRight /></td> : <td><BsXLg /></td>} */}
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className="col-md-2">
                    <table className="uploadDownloadTableWrapper">
                      {data &&
                        data.map((col, idx) => {
                          return (
                            <tr key={idx}>
                              {idx < dbdata.length ? (
                                <>
                                  {dbdata[idx]
                                    .toLowerCase()
                                    .indexOf(col.toLowerCase()) !== -1 ? (
                                    <td>
                                      <BsArrowRight className="arrowIcon" />
                                    </td>
                                  ) : (
                                    <td>
                                      <BsXLg className="closeIcon" />
                                    </td>
                                  )}
                                </>
                              ) : (
                                <td>
                                  <BsXLg />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                  <div className="col-md-5">
                    <table className="uploadDownloadTableWrapper">
                      {dbdata?.map((col, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{col}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
                {/* </div>
                  </div>
                </div> */}
              </div>
              <div className="modal-footer">
                <button disabled className="importGuestBtn">
                  Import guests
                </button>
                <span className="skipBtn" onClick={onHide}>
                  Skip the upload
                </span>
                {/* <div onClick={onHide} className="btn selectFilebtn whiteBg">
                  Cancel
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default FilemanagerCsv;
