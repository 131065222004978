import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Allheader from "components/Headers/AllheaderEvent";
import Imggroup from "../../assets/img/dummyImg.svg";
import UserGuestList from "components/Events/UserGuestList/index";
import { FaUsers } from "react-icons/fa";
import { useSelector } from "react-redux";
import DeleteGuestList from "components/Events/UserGuestList/DeleteGuestList";
import { eventsService } from "../../_services/masters/events.service";
const EventUserGuestList = (args) => {
  const [deleteGuestList, setDeleteGuestList] = useState(false);
  const param = useParams();

  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);

  const handleDeleteGuestList = () => {
    setDeleteGuestList(!deleteGuestList);
  };

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const [eventList, seteventList] = React.useState([]);
  React.useEffect(() => {
    const id = { event_id: param.eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [param]);

  return (
    <>
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={eventList.storyz_name ? eventList.storyz_name : "Storyz Name"}
        imgScr={eventList.project_image ? eventList.project_image : Imggroup}
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <UserGuestList name={eventList.event_name} />
        </div>
        <button
          type="button"
          className="resetGeoBtn"
          onClick={handleDeleteGuestList}
        >
          <FaUsers className="mr-2" size={24} />
          Delete Guest List
        </button>

        <DeleteGuestList
          show={deleteGuestList}
          onHide={handleDeleteGuestList}
        />
      </div>
    </>
  );
};

export default EventUserGuestList;
