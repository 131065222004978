import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Row,
  Col,
} from "reactstrap";
import { FaUserAlt, FaSearch, FaPhoneAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import placeholde from "../../../assets/img/placeholder.jpg";
import { TiUser } from "react-icons/ti";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { BsThreeDots, BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { BiDownArrowCircle } from "react-icons/bi";
import { CombinedGuestPool } from "./style";
import DeleteEverythingInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import ExportGuestIcon from "./../../../assets/img/exportGuestListIcon.svg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import GuestEditDropdownpoolmenu from "components/Dropdown/GuestEditDropdownpoolmenu";

import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteAdminIcon from "./../../../assets/img/deleteAdminIcon.svg";
import RemoveEventIcon from "./../../../assets/img/removeEventIcon.svg";
import TrackingIcon from "./../../../assets/img//trackingIcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { eventsService } from "../../../_services/masters/events.service";
import { GrCircleInformation } from "react-icons/gr";
import ProgressIcon from "../../../assets/img/progressIcon.svg";

import SelectDropdown from "components/Dropdown/SelectDropdownPool";

const { SearchBar } = Search;
const emptyDataMessage = () => {
  return "No Data to Display";
};
const CombinedGuestPoolList = ({ args }) => {
  const [csvData, setCSVData] = useState([]);
  const [guestPool, setGuestPool] = useState([]);
  const [guestPoolall, setGuestPoolall] = useState([]);
  const contact = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    getDataPool();
  }, []);

  const headers = [
    { label: "Guest Name", key: "guest_first_name" },
    { label: "Date Created", key: "date_created" },
    { label: "Account type", key: "account_type" },
    { label: "Guest mobile number", key: "guest_mobile_number" },
    { label: "Guest email id", key: "guest_email_id" },
    { label: "Storyz count", key: "storyz_count" },
  ];
  function getDataPool() {
    let organization_id =
      contact?.profiledetails?.organization[0].organization_id;
    eventsService.combinedGuestpool(organization_id).then(
      (res) => {
        if (res.status === "success") {
          setGuestPool(res.data);
          setGuestPoolall(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteEverything, setDeleteEverything] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [exportGuestList, setExportGuestList] = useState(false);
  const [deleteEverthing, setDeleteEverthing] = useState();
  const [userData, setUserData] = useState();
  const showEntries = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
  ];
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handleDeleteEverything = (e) => {
    setUserData(e);
    setDeleteEverything(!deleteEverything);
  };
  const handleDeleteAdmin = (e) => {
    setUserData(e);
    setDeleteAdmin(!deleteAdmin);
  };
  const handleDeleteAll = (e) => {
    setUserData(e);
    setDeleteAll(!deleteAll);
  };
  const handleExportGuestList = (e) => {
    setExportGuestList(!exportGuestList);
  };

  const deleteAdminAccount = (data, val) => {
    if (val === "Delete") {
      eventsService.deleteAdminaccount(data.guest_id, val).then(
        (res) => {
          if (res.status === "success") {
            getDataPool();
            setDeleteAdmin(!deleteAdmin);
            toast.success("Delete Admin Account successfully !!", "Account");
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
    if (val === "ALL GUEST DATA") {
      eventsService.deleteAdminaccount(data.guest_id, val).then(
        (res) => {
          if (res.status === "success") {
            getDataPool();
            setDeleteEverything(!deleteEverything);
            toast.success(
              "Completely Erase this Guest’s Information successfully !!",
              "Account"
            );
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
    if (val === "Storyz and Event Information") {
      eventsService.deleteAdminaccount(data.guest_id, val).then(
        (res) => {
          if (res.status === "success") {
            getDataPool();
            setDeleteAll(!deleteAll);
            toast.success(
              "Remove Storyz and Event Information successfully !!",
              "Account"
            );
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Edit Details",
      link: "/admin/admin-account-view-other-owner",
    },
    {
      img: DeleteAdminIcon,
      title: "Delete Admin Account",
      handleClick: handleDeleteAdmin,
    },
    {
      img: RemoveEventIcon,
      title: "Remove from Storyz and Events",
      handleClick: handleDeleteAll,
    },
    {
      img: TrackingIcon,
      title: "Remove Tracking Data",
      disabled: true,
    },
    {
      img: DeleteIcon,
      title: "Delete Everhything",
      handleClick: handleDeleteEverything,
    },
  ];

  const [selectMenu, setSelectMenu] = useState(false);
  const selectMenuRef = useRef();
  // console.log("useRef", selectMenuRef);
  const dropdownHandle = () => {
    setSelectMenu(true);
  };

  // useEffect(() => {

  //   let handler = (e) => {
  //     if (!selectMenuRef.current.contains(e.target)) {
  //       setSelectMenu(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // }, []);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  function trackingFormatter(cell, row) {

    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.packagetrackr.com/track/${row.guest_first_name}`}
      >
        {row.guest_first_name}
      </a>
    );
  }

  function trackingFormatterFilter(cell, row) {
    return row.tracking;
  }
  const guestPoolColumn = [
    {
      dataField: "full_name",
      text: (
        <div className="d-flex align-items-center">
          Actions
          <TiUser size={20} className="mx-3 userIcon" />
          Guest Name
        </div>
      ),

      formatter: (value, row) => (
        <div className="d-flex align-items-center">
          <GuestEditDropdownpoolmenu
            guestListOption={guestListOption}
            data={row}
            guest_id={row.guest_id}
          />
          {/* <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              color=""
              role="button"
              size="sm"
              style={{ boxShadow: 'none' }}
            >
              <img
                alt="..."
                src={require("assets/img/Item-Action-Icons.svg").default}
                width="36px"
                style={{ marginRight: '10px' }}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/editicon.svg").default}
                  width="15px"

                />
                Edit Details
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/security1.svg").default}
                  width="15px"

                />
                Delete Admin Account
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/profileicon.svg").default}
                  width="15px"

                />

                Remove Guest Information
              </DropdownItem>

              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/editfield.svg").default}
                  width="15px"

                />

                Remove from Storyz and Events
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/editfield9.svg").default}
                  width="20px"

                />

                Remove Tracking Data
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  alt="..."
                  src={require("assets/img/editdelete.svg").default}
                  width="20px"

                />

                DELETE EVERYTHING
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <div className="guestUserCard ml-4">
            <div className="guestUserPic">
              <img
                src={row?.guest_imageUrl ? row?.guest_imageUrl : placeholde}
                className="pic"
                alt=""
              />
            </div>
            <div className="guestUserBody">
              <div className="title">{row.full_name}</div>
              <div className="subTitle">{`${row.storyz_count} storyz,  ${row.account_type}`}</div>
            </div>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      dataField: "date_created",
      text: "Date Created",
      formatter: (value, row) => (
        <>{moment(row.date_created).format("DD/MM h:mm:ss a")}</>
      ),
      sort: true,
    },
    {
      dataField: "account_type",
      text: "Admin?",
      sort: true,
      formatter: (value, row) => (
        <>{row?.account_type ? row?.account_type : "No"}</>
      ),
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      formatter: (value, row) => (
        <div>
          <Link className="contactIcon" size={24} to="/">
            <BsFillChatDotsFill />
          </Link>
          <a
            className="contactIcon"
            size={24}
            href={`mailTo:${row?.guest_email_id}`}
          >
            {" "}
            <AiOutlineMail />
          </a>
          <a
            className="contactIcon"
            size={24}
            href={`tel:${row?.guest_mobile_number}`}
          >
            {" "}
            <FaPhoneAlt />
          </a>
        </div>
      ),
    },
  ];
  const [searchValue, setSearchValue] = useState("");

  function handleChange(e) {
    setSearchValue(e.target.value);

  }

  const getOptions = () => {
    if (!searchValue) {
      return guestPool;
    }

    return guestPool.filter(
      (option) =>
        option.full_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const SelectDropdownonClike = (e) => {
    if (e === "Admin") {
      const filtered2 = guestPoolall.filter((employee) => {
        return employee.account_type === e;
      });
      setGuestPool(filtered2);
    } else if (e === "Not Attached to any Event") {
      const filtered2 = guestPoolall.filter((employee) => {
        return employee.storyz_count === 0;
      });
      setGuestPool(filtered2);
    } else {
      getDataPool();
    }
  };

  const filterBy = [
    {
      img: EditPencilIcon,
      title: "All Guests",
    },
    {
      img: ProgressIcon,
      title: "Admin",
    },
    {
      img: ProgressIcon,
      title: "Not Attached to any Event",
    },
  ];


  return (
    <>
      <CombinedGuestPool>
        <div className="CombinedGuestPoolWrapper">
          <div className="headingWrapper borderBottom">
            <h4 className="heading-1">
              All Guests From all Your Storyz and Events
            </h4>
            <GrCircleInformation
              className="ml-auto iconColor infoToolTip"
              data-tip="Tooltip message will come here....."
            />
            <ReactTooltip />
          </div>

          <Modal
            isOpen={deleteEverything}
            toggle={handleDeleteEverything}
            {...args}
            className="deleteEverythingModal"
          >
            <ModalBody className="pb-0">
              <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
                <img
                  src={DeleteEverythingInfoIcon}
                  alt=""
                  className="deleteEverythingInfoIcon"
                />
                <div className="head">
                  Completely Erase this Guest’s Information?
                </div>
              </div>
              <Row>
                <Col>
                  <div className="deleteEverythingThumWrapper">
                    <div className="deleteEverythingThum">
                      <img
                        src={
                          userData?.guest_imageUrl
                            ? userData?.guest_imageUrl
                            : placeholde
                        }
                        alt=""
                        className="deleteEverythingThumImg"
                      />
                    </div>
                    <div className="deleteEverythingThumContent">
                      <div className="deleteEverythingthumbTitle">
                        {userData?.full_name}
                      </div>
                      <div className="deleteEverythingthumbSubTitle">
                        Role in Event
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col>
                  <p>Confirm you want to erase this guests’s information. </p>
                  <p>
                    Selecting “remove” will ERASE all the Guest information,
                    including all information that associates the Guest with
                    individual Projects and Events. The guest will be removed
                    from event lists.
                  </p>
                  <p>This is NOT un-doable.</p>
                  <p>
                    If you want to remove a guest from an event’s guest list, go
                    the the relevant event and use the ellipsis control to
                    remove them from the list.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 pinkBtn"
                onClick={() => {
                  deleteAdminAccount(userData, "ALL GUEST DATA");
                }}
              >
                ERASE ALL GUEST DATA
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handleDeleteEverything}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={deleteAdmin}
            toggle={handleDeleteAdmin}
            {...args}
            className="deleteEverythingModal"
          >
            <ModalBody className="pb-0">
              <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
                <img
                  src={DeleteEverythingInfoIcon}
                  alt=""
                  className="deleteEverythingInfoIcon"
                />
                <div className="head">Delete Admin Account?</div>
              </div>
              <Row>
                <Col>
                  <div className="deleteEverythingThumWrapper">
                    <div className="deleteEverythingThum">
                      <img
                        src={
                          userData?.guest_imageUrl
                            ? userData?.guest_imageUrl
                            : placeholde
                        }
                        alt=""
                        className="deleteEverythingThumImg"
                      />
                    </div>
                    <div className="deleteEverythingThumContent">
                      <div className="deleteEverythingthumbTitle">
                        {userData?.full_name}
                      </div>
                      <div className="deleteEverythingthumbSubTitle">
                        Role in Event
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col>
                  <p>
                    Confirm you want to delete the Admin account for this guest.{" "}
                  </p>
                  <p>
                    Selecting “delete” will remove Admin logins, but won’t
                    affect the guest’s information in events and Storyz.
                  </p>
                  <p>This is NOT un-doable.</p>
                  <p>
                    If you want to remove a guest from an event’s guest list, go
                    the the relevant event and use the ellipsis control to
                    remove them from the list.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 pinkBtn"
                onClick={() => {
                  deleteAdminAccount(userData, "Delete");
                }}
              >
                Delete
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handleDeleteAdmin}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={deleteAll}
            toggle={handleDeleteAll}
            {...args}
            className="deleteEverythingModal"
          >
            <ModalBody className="pb-0">
              <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
                <img
                  src={DeleteEverythingInfoIcon}
                  alt=""
                  className="deleteEverythingInfoIcon"
                />
                <div className="head">Erase Guest from all Guest Lists?</div>
              </div>
              <Row>
                <Col>
                  <div className="deleteEverythingThumWrapper">
                    <div className="deleteEverythingThum">
                      <img
                        src={
                          userData?.guest_imageUrl
                            ? userData?.guest_imageUrl
                            : placeholde
                        }
                        alt=""
                        className="deleteEverythingThumImg"
                      />
                    </div>
                    <div className="deleteEverythingThumContent">
                      <div className="deleteEverythingthumbTitle">
                        {userData?.full_name}
                      </div>
                      <div className="deleteEverythingthumbSubTitle">
                        Role in Event
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2 ">
                <Col>
                  <p>
                    Confirm you want to remove this guests from all the guest
                    lists.
                  </p>
                  <p>
                    This will not affect the guest record itself (name,
                    email...), which will remain in the global guest pool.
                  </p>
                  <p>
                    Selecting “remove” will remove all Storyz and event
                    information, including profile images and Keepsake Album
                    uploads.
                  </p>
                  <p>This is NOT un-doable.</p>
                  <p>
                    If you want to remove a guest from an event’s guest list, go
                    the the relevant event and use the ellipsis control to
                    remove them from the list.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 pinkBtn"
                onClick={() => {
                  deleteAdminAccount(userData, "Storyz and Event Information");
                }}
              >
                Remove Storyz and Event Information
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handleDeleteAll}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          <ToolkitProvider
            bootstrap4
            keyField="name"
            data={getOptions()}
            columns={guestPoolColumn}
            search
          >
            {(props) => (
              <>
                <div className="filterWrapper">
                  <div className="filterInnerWrapper">
                    <div className="filterList">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter"
                      >
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/icons/monogram-circular.svg")
                              .default
                          }
                        />
                        <input
                          className="form-control"
                          placeholder="Search By Guest Name ..."
                          {...props.searchProps}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="filterBy pl-5">
                        <label
                          className="form-control-label mb-0"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Filter by :
                        </label>

                        <SelectDropdown
                          guestListOption={filterBy}
                          SelectDropdownonClike={SelectDropdownonClike}
                          selectbox="All Guests"
                        />

                        {/* <FormGroup className="mb-0">
                          <Input id="exampleFormControlSelect1" type="select">
                            <option value="All">Select</option>
                            <option value="Organization">
                              Organization Todo
                            </option>
                            <option value="Storyz">Storyz Todo</option>
                            <option value="Event">Events Todo</option>
                          </Input>
                        </FormGroup> */}
                      </div>
                    </div>
                    <div className="guestUserWrap">
                      {/* <div className="addGuest">
                            <FaUserAlt className="icon" size={18} />
                            <div>Add New Guest</div>
                          </div> */}
                      <div
                        className="exportWrap"
                        onClick={handleExportGuestList}
                      >
                        <RiArrowDownCircleLine className="icon mb-0" />
                        <div>Export</div>
                      </div>

                      <Modal
                        isOpen={exportGuestList}
                        toggle={handleExportGuestList}
                        {...args}
                        className="exportGuestModal"
                      >
                        <ModalBody className="pb-0">
                          <div className="exportGuestHeader border-bottom flex-row">
                            <img
                              src={ExportGuestIcon}
                              alt=""
                              className="exportGuestInfoIcon"
                            />
                            <div className="head">Export Guest List</div>
                          </div>
                          <Row>
                            <Col>
                              <p className="mb-4">
                                Export the guest list for this event in a
                                variety of formats. To export all guests, use
                                the combined guest list in your Storyz.
                              </p>
                              {/* <div className="eventThumWrapper">
                                <div className="eventThum">
                                  <img
                                    src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                    alt=""
                                    className="eventThumImg"
                                  />
                                </div>
                                <div className="eventThumContent">
                                  <div className="eventthumbTitle">
                                    Event Name
                                  </div>
                                  <div className="eventthumbSubTitle">
                                    Location
                                  </div>
                                </div>
                              </div> */}
                            </Col>
                          </Row>
                          <Row className="mb-2 ">
                            <Col>
                              <div className="exportFileListWrapper">
                                <div className="exportFileList">
                                  <div className="exportFileWrapper">
                                    <img
                                      src={FileIcon}
                                      alt=""
                                      className="exportFile"
                                    />
                                    <div className="exportFileText">.csv</div>
                                  </div>
                                  <CSVLink
                                    data={guestPoolall}
                                    headers={headers}
                                    filename={"combined_guest_pool_record.csv"}
                                    newLineSeparator="\r\n"
                                  >
                                    <div className="exportFileBtn">
                                      <BiDownArrowCircle className="exportDownloadIcon" />
                                      Download CSV
                                    </div>
                                  </CSVLink>
                                </div>
                                <div className="exportFileList">
                                  <div className="exportFileWrapper">
                                    <img
                                      src={FileIcon}
                                      alt=""
                                      className="exportFile"
                                    />
                                    <div className="exportFileText">.csv</div>
                                  </div>
                                  <CSVLink
                                    data={guestPoolall}
                                    headers={headers}
                                    filename={"combined_guest_pool_record.xlsx"}
                                    newLineSeparator="\r\n"
                                  >
                                    <div className="exportFileBtn">
                                      <BiDownArrowCircle
                                        size={20}
                                        className="exportDownloadIcon"
                                      />
                                      Download Excel
                                    </div>
                                  </CSVLink>
                                </div>
                                <div className="exportFileList">
                                  <div className="exportFileWrapper">
                                    <img
                                      src={FileIcon}
                                      alt=""
                                      className="exportFile"
                                    />
                                    <div className="exportFileText">Sheets</div>
                                  </div>
                                  <div className="exportFileBtn">
                                    <BiDownArrowCircle className="exportDownloadIcon" />
                                    Download Excel
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                          <Button
                            className="btn-default cancelBtn w-100"
                            onClick={() => handleExportGuestList(false)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>

                  <CombinedGuestPool>
                    <div className="rsvpTableWrapper">
                      <BootstrapTable
                        keyField="codmutual"
                        noDataIndication={emptyDataMessage}
                        data={getOptions()}
                        wrapperClasses="responsive"
                        columns={guestPoolColumn}
                        bootstrap4={true}
                        bordered={false}
                        pagination={pagination}
                      />
                    </div>
                  </CombinedGuestPool>
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      </CombinedGuestPool>
      <ToastContainer />
    </>
  );
};

export default CombinedGuestPoolList;
