import { EVENT_SAVE } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    event: {
        timezone: "",
        timevalue: "",

        repeating_event: "Does Not Repeat",
        repeat_event: "",
        custom: "",
        dayofrepeat: "",
        until: "",
    },
};
function eventReducers(state = initialState, action) {
    switch (action.type) {
        case EVENT_SAVE:
            return {
                ...state,
                isConfirmatiom: true,
                event: action.payload,
            };
        //return { ...state, event: [...action.payload] };
        default:
            return state;
    }
}
export default eventReducers;
