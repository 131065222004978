import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Form, Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import { informationService } from "../../_services/masters/information.service";

import PaymentPopus from "../../components/Payment/PaymentPopus";
import { paymentService } from "../../_services/masters/payment.service";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import DummyImg from "../../assets/img/dummyImg.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ItemAction from "../../assets/img/ItemAction.svg";
import Itemactionshow from "../../assets/img/Itemactionshow.svg";
import Visa from "../../assets/img/visa.svg";
import { loadStripe } from "@stripe/stripe-js";
import Paymentpaypal from "../../assets/img/paymentpaypal.svg";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "components/Payment/PaymentForm";
import Paypalbuttons from "components/Payment/PayPalButtons";
import OrgBilling from "./../../assets/img/org-billing.svg";

const stripePromise = loadStripe(
  "pk_test_51LwZKEDoeRAM4EghGQTTM9X2Dw7yoAZl0NMvZQSDFPcNHD7otzsXuPPQdy5FOA8RWnIKwCalL0YclUIfNOCwyd4e00FngMEC0D"
);

function Billingpayment() {
  const [invoicesData, setInvoicesData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  // const [billingCSVData, setBillingCSVData] = useState([]);
  const [upcomingInvoiceData, setUpcomingInvoiceData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [isValue, setValue] = useState();
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const headers = [
    { label: "Invoice ID", key: "invoice_id" },
    { label: "Client Name", key: "client_name" },
    { label: "Plan", key: "plan" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Date Paid", key: "paid_date" },
    { label: "Next Due Date", key: "next_due_date" },
  ];

  const userData = JSON.parse(localStorage.getItem("userDetails"));
  let name = userData.name;
  const userName = name.split(" ").join("-");
  const userImg = userData.imageUrl === "" ? DummyImg : userData.imageUrl;


  const handleChange = (e) => {
    let checked = e.target.checked === true ? "yes" : "no";
    setValue(checked);
    const newArray = {
      meta_tag: "notifications_methods",
      meta_key: "Notify when payment is due",
      meta_value: checked,
    };

    informationService.createOrUpdatesetting(newArray).then(
      (res) => {
        if (res.status === "success" && res.message === "Update") {
          toast.success("Setting Updated successfully !!", "Account");
        } else if (res.status === "success" && res.message === "Save") {
          toast.success("Setting Save successfully !!", "Account");
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };

  useEffect(() => {
    paymentService.billingDetails().then(
      (res) => {
        if (res.status === "success") {
          //console.log("res.findSetting", res.findSetting.meta_value);
          setValue(
            res.findSetting !== undefined ? res.findSetting.meta_value : "no"
          );
          let csvArr = [];
          const d1Arr = res.invoiceList.invoices.data;
          const upcomingInvoiceArr = res.upcomingInvoice.upcomingInvoice;
          setInvoicesData(res.invoiceList.invoices.data);
          setBillingData(res.data);
          //setBillingCSVData(res.data)
          setUpcomingInvoiceData(res.upcomingInvoice.upcomingInvoice);
          setCardData(res.cardDetails.cardData);

          for (let i = 0; i < d1Arr.length; i++) {
            //let d1 = invoicesData[i];
            let csvOBJ = {};
            csvOBJ["invoice_id"] = d1Arr[i].id;
            csvOBJ["client_name"] = d1Arr[i].customer_name;
            csvOBJ["plan"] = d1Arr[i].lines.data[0].description;
            csvOBJ["amount"] = d1Arr[i].total / 100;
            csvOBJ["paid_date"] = moment(
              new Date(d1Arr[i].status_transitions.paid_at * 1000)
            ).format("MMMM D,  YYYY");
            csvOBJ["status"] = d1Arr[i].status;
            csvOBJ["invoice_date"] = moment(
              new Date(d1Arr[i].created * 1000)
            ).format("MMMM D,  YYYY");
            csvOBJ["next_due_date"] = moment(
              new Date(upcomingInvoiceArr.next_payment_attempt * 1000)
            ).format("MMMM D,  YYYY");
            csvArr.push(csvOBJ);
          }
          setCSVData(csvArr);

          //setTimeout(() => {
          //exportBillingData();
          //}, 1000);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
    //exportBillingData();
  }, []);
  return (
    <>
      {/* <Allheader title="Billing and Payment" arw={false} /> */}
      <ToastContainer />
      <CombinedGuestPoolHeader
        title="Billing and Payment"
        arw={false}
        imgScr={OrgBilling}
      />
      <div className="organizerWrapperMain">
        <Form>
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">About Your Billing</h4>
              <span
                data-tip="Tooltips display informative text when users hover over, focus on, or tap an element."
                className="ml-auto"
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
              <ReactTooltip />
            </div>
            <div className="organizationPara p-4">
              This page shows your current billing level, payment method, and
              billing history.
            </div>
            <div className="py-4">
              <h4 className="heading-1">Current Bill</h4>
            </div>
            <div className="px-3 mx-n1">
              <div className="custom-control form-check form-check-inline d-inline-flex">
                <label className="container3 form-check-label">
                  Notify when payment is due:
                  <input
                    type="checkbox"
                    name=""
                    onChange={(e) => handleChange(e)}
                    checked={isValue === "yes" ? "checked" : ""}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="billingBoxWrapper">
              <div className="billingBoxLeft">
                <Card className="bgcard currentPlanWrapper">
                  <CardBody>
                    <div>
                      <h2 className="Jakarta">Current plan</h2>

                      <div className="currentPlanBox">
                        <div className="Jakarta price">
                          ${billingData.amount / 100}/
                          {billingData.plan_interval}
                        </div>

                        <div className="professional Jakarta category">
                          {billingData.name} Level
                        </div>

                        <div className="eventCreate Jakarta desc">
                          Create Teams and Event Groups
                        </div>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Link to="/admin/upgrade-service">
                      <Button
                        className="btn-neutral change-Plan billingGreenBtn"
                        color="default"
                        size="sm"
                      >
                        Change Pour Plan
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
                <Card className="nextPaymentPlanWraper">
                  <CardBody>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="h1 Jakarta">Next payment</div>
                      <div>
                        <img
                          alt="..."
                          src={userImg}
                          width="48px"
                          height="48px"
                          style={{ borderRadius: "50px" }}
                        />
                        <span className="daon">OWNER</span>
                      </div>
                    </div>
                    <div>
                      <div className="Jakarta h1">
                        ${upcomingInvoiceData?.amount_due / 100}
                      </div>
                      <span className="date">
                        {moment(
                          new Date(
                            upcomingInvoiceData?.next_payment_attempt * 1000
                          )
                        ).format("MMMM D,  YYYY")}
                      </span>
                    </div>
                  </CardBody>
                  <CardBody>
                    <PaymentPopus />
                  </CardBody>
                </Card>
              </div>
              <Modal
                className="paymentpopus"
                isOpen={defaultModal}
                toggle={() => setdefaultModal(false)}
              >
                <div className="modal-header">
                  <div className="headerInner">
                    <h6 className="modal-title" id="modal-title-default">
                      Current Payment Gateways
                    </h6>
                    <button className="changeBtn ml-auto">
                      Add New Gateway
                    </button>
                  </div>
                  <button
                    aria-label="Close"
                    className="close ml-3"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setdefaultModal(false)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="tabs">
                    <button
                      className={`tab ${checkActive(1, "active")}`}
                      onClick={() => handleClick(1)}
                    >
                      {checkActive(1, "active") === "active" ? (
                        <img src={ItemAction} alt="" />
                      ) : (
                        <img src={Itemactionshow} alt="" />
                      )}

                      <img src={Visa} alt="" />
                    </button>
                    <button
                      className={`tab ${checkActive(2, "active")}`}
                      onClick={() => handleClick(2)}
                    >
                      {checkActive(2, "active") === "active" ? (
                        <img src={ItemAction} alt="" />
                      ) : (
                        <img src={Itemactionshow} alt="" />
                      )}
                      <img src={Paymentpaypal} alt="" />
                    </button>
                  </div>
                  <div className="panels">
                    <div className={`panel ${checkActive(1, "active")}`}>
                      <Elements stripe={stripePromise}>
                        <PaymentForm />
                      </Elements>
                    </div>
                    <div className={`panel ${checkActive(2, "active")}`}>
                      <Paypalbuttons />
                    </div>
                    <div className={`panel ${checkActive(3, "active")}`}>
                      <p>
                        Cras porta consectetur dolor porttitor fringilla. Cras
                        vitae urna ac erat fermentum egestas. Donec egestas
                        cursus scelerisque.
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
              <div className="billingBoxRight">
                <Card
                  className="card-stats paymentMethodWrapper pointer"
                  onClick={() => setdefaultModal(true)}
                >
                  <CardBody>
                    <div className="head">
                      <div className="Jakarta Jakartabold700 CurrentPaymentMethod">
                        Current Payment Method
                      </div>
                      <div>
                        <img
                          alt="..."
                          src={require("assets/img/Item Action Icons.png")}
                          style={{
                            borderRadius: "50px",
                            width: "20px",
                          }}
                          className="icons"
                        />
                      </div>
                    </div>
                    <div className="mb-0 text-sm">
                      {cardData.brand === "Visa" && (
                        <>
                          <img
                            alt="..."
                            src={require("assets/img/Payment Gateway buttons.png")}
                          />
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats orderSummaryWrapper">
                  {/* <div style={{ padding: "40px" }}> */}
                  <div className="order-Summary">
                    <h2 className="Jakarta Jakartabold700">Order Summary</h2>
                    <div className="Jakarta orderSummaryList">
                      Base Professional Rate{" "}
                      <span>${billingData.amount / 100}</span>
                    </div>

                    {/* <div className="Jakarta orderSummaryList">
                      3 team members included
                    </div>

                    <div className="Jakarta orderSummaryList">
                      2 Additional Seats <span> 4 at $25/seat</span>
                    </div>

                    <div className="Jakarta orderSummaryList">
                      <span>Tax: $44.22</span>
                    </div> */}

                    <div className="Jakarta orderSummaryList">
                      Renewal Payment: {billingData.plan_interval}
                    </div>

                    <div className="Jakarta orderSummaryList">
                      Payment reminder: 3 days before expiration
                    </div>

                    <div className="Jakarta orderSummaryList">
                      Total: <span>${billingData.amount / 100}</span>
                    </div>
                  </div>
                  {/* </div> */}
                </Card>
              </div>
            </div>

            {/* <Row>
              <Col lg={8} md={12}>
                <div className="card-deck">
                  <Card className="bgcard currentPlanWrapper">
                    <CardBody>
                      <div>
                        <h2 className="Jakarta">Current plan</h2>

                        <div className="currentPlanBox">
                          <div className="Jakarta price">$800/Mo.</div>

                          <div className="professional Jakarta category">
                            Professional Level
                          </div>

                          <div className="eventCreate Jakarta desc">
                            Create Teams and Event Groups
                          </div>
                        </div>
                      </div>
                    </CardBody>
                    <CardBody>
                      <Link to="/admin/upgrade-service">
                        <Button
                          className="btn-neutral change-Plan billingGreenBtn"
                          color="default"
                          size="sm"
                        >
                          Change Pour Plan
                        </Button>
                      </Link>
                    </CardBody>
                  </Card>
                  <Card className="nextPaymentPlanWraper">
                    <CardBody>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="h1 Jakarta">Next payment</div>
                        <div className="mr-3">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="48px"
                            style={{ borderRadius: "50px" }}
                          />
                          <span className="daon">OWNER</span>
                        </div>
                      </div>
                      <div>
                        <div className="Jakarta h1">$894.22</div>
                        <span className="date">on September 12, 2022</span>
                      </div>
                      <PaymentPopus />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col lg={4} md={12}>
                <Row style={{ justifyContent: "space-around" }}>
                  <Col md="12" xl="10">
                    <Card className="card-stats paymentMethodWrapper">
                      <CardBody>

                        <div className="head">
                          <div className="Jakarta Jakartabold700 CurrentPaymentMethod">
                            Current Payment Method
                          </div>
                          <div>
                            <img
                              alt="..."
                              src={require("assets/img/Item Action Icons.png")}
                              style={{
                                borderRadius: "50px",
                                width: "20px",
                              }}
                              className="icons"
                            />
                          </div>
                        </div>
                        <div className="mb-0 text-sm">
                          <img
                            alt="..."
                            src={require("assets/img/Payment Gateway buttons.png")}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "space-around" }}>
                  <Col md="12" xl="10">
                    <Card className="card-stats orderSummaryWrapper">
                      <div className="order-Summary">
                        <h2 className="Jakarta Jakartabold700">
                          Order Summary
                        </h2>
                        <div className="Jakarta orderSummaryList">
                          Base Professional Rate <span>$800</span>
                        </div>

                        <div className="Jakarta orderSummaryList">
                          3 team members included
                        </div>

                        <div className="Jakarta orderSummaryList">
                          2 Additional Seats <span> 4 at $25/seat</span>
                        </div>

                        <div className="Jakarta orderSummaryList">
                          <span>Tax: $44.22</span>
                        </div>

                        <div className="Jakarta orderSummaryList">
                          Renewal Payment: monthly
                        </div>

                        <div className="Jakarta orderSummaryList">
                          Payment reminder: 3 days before expiration
                        </div>

                        <div className="Jakarta orderSummaryList">
                          Total: <span>$894.22</span>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row> */}

            <Row>
              <Col lg="12" md="12" className="p-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="mb-3"
                >
                  <div>
                    <p className="BillingHistory">Billing History</p>
                  </div>
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    filename={`${userName}-ourstoryz-invoice-${new Date()}.csv`}
                    newLineSeparator="\r\n"
                  >
                    <div className="exportFileBtn">
                      {/* <BiDownArrowCircle className="exportDownloadIcon" /> */}
                      <img
                        alt="Organization Name"
                        src={require("assets/img/Groupexp.svg").default}
                        className="exportbillingdataimag"
                      />
                      <p className="exportbillingdata">Export Billing Data</p>
                    </div>
                  </CSVLink>

                  {/* <div onClick={exportBillingData}>
                  <img
                      alt="Organization Name"
                      src={require("assets/img/Groupexp.svg").default}
                      className="exportbillingdataimag"
                    />
                    <p className="exportbillingdata">Export Billing Data</p>
                  </div> */}
                </div>
                {/* <Table
                    className="align-items-center table-flush billtable mt-4"
                    responsive
                    striped
                  >
                    <thead className="thead-light">
                      <tr style={{ background: "#D7D7D7" }}>
                        <th style={{ width: "20px", textAlign: "center" }}>
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </th>
                        <th>Amount</th>
                        <th style={{ textAlign: "center" }}>Status</th>

                        <th width="75%">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/use.svg").default}
                          />
                          Client Name
                        </th>
                        <th style={{ width: "100px", textAlign: "center" }}>
                          {" "}
                          Due Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="clientdata">
                      <tr>
                        <td className="table-user">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </td>
                        <td>
                          <span className="text-muted">$894.22</span>
                        </td>
                        <td style={{ textAlign: "center" }}>Completed</td>
                        <td className="table-actions">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="32px"
                            style={{
                              borderRadius: "50px",
                              marginRight: "6px",
                            }}
                          />{" "}
                          Thad Bees
                        </td>
                        <td>Mar 10, 2022</td>
                      </tr>
                      <tr>
                        <td className="table-user">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </td>
                        <td>
                          <span className="text-muted">$894.22</span>
                        </td>
                        <td style={{ textAlign: "center" }}>Completed</td>
                        <td className="table-actions">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="32px"
                            style={{
                              borderRadius: "50px",
                              marginRight: "6px",
                            }}
                          />{" "}
                          Thad Bees
                        </td>
                        <td>Mar 10, 2022</td>
                      </tr>
                      <tr>
                        <td className="table-user">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </td>
                        <td>
                          <span className="text-muted">$894.22</span>
                        </td>
                        <td style={{ textAlign: "center" }}>Completed</td>
                        <td className="table-actions">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="32px"
                            style={{
                              borderRadius: "50px",
                              marginRight: "6px",
                            }}
                          />{" "}
                          Thad Bees
                        </td>
                        <td>Mar 10, 2022</td>
                      </tr>
                      <tr>
                        <td className="table-user">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </td>
                        <td>
                          <span className="text-muted">$894.22</span>
                        </td>
                        <td style={{ textAlign: "center" }}>Completed</td>
                        <td className="table-actions">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="32px"
                            style={{
                              borderRadius: "50px",
                              marginRight: "6px",
                            }}
                          />{" "}
                          Thad Bees
                        </td>
                        <td>Mar 10, 2022</td>
                      </tr>
                      <tr>
                        <td className="table-user">
                          <img
                            alt="..."
                            className="rounded-circle mr-3"
                            src={require("assets/img/selectbox.svg").default}
                          />
                        </td>
                        <td>
                          <span className="text-muted">$894.22</span>
                        </td>
                        <td style={{ textAlign: "center" }}>Completed</td>
                        <td className="table-actions">
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg")}
                            width="32px"
                            style={{
                              borderRadius: "50px",
                              marginRight: "6px",
                            }}
                          />{" "}
                          Thad Bees
                        </td>
                        <td>Mar 10, 2022</td>
                      </tr>
                    </tbody>
                  </Table> */}
                <div className="billingHistoryResponsive">
                  <div className="billingHistoryWrapper">
                    <div className="billingHistoryHead">
                      <div className="billingHistoryList">
                        <img
                          alt="..."
                          className="rounded-circle mr-3"
                          src={require("assets/img/selectbox.svg").default}
                        />
                      </div>
                      <div className="billingHistoryList">Amount</div>
                      <div className="billingHistoryList">Status</div>
                      <div className="billingHistoryList">
                        <img
                          alt="..."
                          className="rounded-circle mr-3"
                          src={require("assets/img/use.svg").default}
                        />
                        Client Name
                      </div>
                      <div className="billingHistoryList">Due Date</div>
                    </div>

                    <Accordion className="billingHistoryListAcc">
                      {invoicesData.map((d1, i) => (
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            <div className="billingHistoryBody">
                              <div className="billingHistoryList">
                                <img
                                  alt="..."
                                  className="rounded-circle mr-3 arrowIcon"
                                  src={
                                    require("assets/img/selectbox.svg").default
                                  }
                                />
                              </div>
                              <div className="billingHistoryList">
                                ${d1.total / 100}
                              </div>
                              <div className="billingHistoryList">
                                {d1.status}
                              </div>
                              <div className="billingHistoryList">
                                <img
                                  alt="..."
                                  className="rounded-circle mr-3 objectFitCover"
                                  width={32}
                                  height={32}
                                  src={userImg}
                                />
                                {d1.customer_name}
                              </div>
                              <div className="billingHistoryList">
                                {moment(
                                  new Date(d1.status_transitions.paid_at * 1000)
                                ).format("MMMM D,  YYYY")}
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              className="billingHistoryContentWrapper py-3"
                              style={{
                                paddingLeft: "34px",
                                paddingRight: "16px",
                              }}
                            >
                              <div className="fontSize12 blackColor fontWeight300">
                                <div className="fontWeight500 mb-2">
                                  {d1.lines.data[0].description}
                                </div>
                                {/* <div className="mb-2">
                                  Best Enterprise Rate
                                  <span className="fontWeight500"> $8000 </span>
                                </div>
                                <div className="mb-2">
                                  Additional Seats
                                  <span className="fontWeight500">
                                    4 at $25/seat
                                  </span>
                                </div>
                                <div className="mb-2">Tax $56.23</div> */}
                                <div className="mb-2">
                                  Total:
                                  <span className="fontWeight500">
                                    ${d1.total / 100}
                                  </span>
                                </div>
                              </div>

                              <div className="fontSize12 blackColor fontWeight300 border-right">
                                <div className="fontWeight500 mb-2">
                                  Details
                                </div>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <div className="mb-2">
                                      <div className="fontWeight500 mb-1">
                                        Status
                                      </div>
                                      {d1.status}
                                    </div>
                                    <div className="mb-2">
                                      <div className="fontWeight500 mb-1">
                                        Invoice Date
                                      </div>
                                      {moment(
                                        new Date(d1.created * 1000)
                                      ).format("MMMM D,  YYYY")}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="mb-2">
                                      <div className="fontWeight500 mb-1">
                                        Invoice ID
                                      </div>
                                      {d1.id}
                                    </div>
                                    <div className="mb-2">
                                      <div className="fontWeight500 mb-1">
                                        Date Paid
                                      </div>
                                      {moment(
                                        new Date(
                                          d1.status_transitions.paid_at * 1000
                                        )
                                      ).format("MMMM D,  YYYY")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="blackColor fontSize18 h-100 d-flex align-items-center justify-content-end">
                                  ${d1.subtotal / 100}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}

                      {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="billingHistoryBody">
                            <div className="billingHistoryList">
                              <img
                                alt="..."
                                className="rounded-circle mr-3 arrowIcon"
                                src={
                                  require("assets/img/selectbox.svg").default
                                }
                              />
                            </div>
                            <div className="billingHistoryList">$8000.00</div>
                            <div className="billingHistoryList">Completed</div>
                            <div className="billingHistoryList">
                              <img
                                alt="..."
                                className="rounded-circle mr-3 objectFitCover"
                                width={32}
                                height={32}
                                src="https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60"
                              />
                              Thad Bees
                            </div>
                            <div className="billingHistoryList">
                              Mar 10, 2022
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="billingHistoryContentWrapper py-3"
                            style={{
                              paddingLeft: "34px",
                              paddingRight: "16px",
                            }}
                          >
                            <div className="fontSize12 blackColor fontWeight300">
                              <div className="fontWeight500 mb-2">
                                Billing Plan
                              </div>
                              <div className="mb-2">
                                Best Enterprise Rate
                                <span className="fontWeight500"> $8000 </span>
                              </div>
                              <div className="mb-2">
                                Additional Seats
                                <span className="fontWeight500">
                                  4 at $25/seat
                                </span>
                              </div>
                              <div className="mb-2">Tax $56.23</div>
                              <div className="mb-2">
                                Total:
                                <span className="fontWeight500"> $8100 </span>
                              </div>
                            </div>
                            <div className="fontSize12 blackColor fontWeight300 border-right">
                              <div className="fontWeight500 mb-2">Details</div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Status
                                    </div>
                                    Complete
                                  </div>
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Invoice Date
                                    </div>
                                    Feb 24, 2022
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Invoice ID
                                    </div>
                                    #229389
                                  </div>
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Date Paid
                                    </div>
                                    March 10, 2024
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="blackColor fontSize18 h-100 d-flex align-items-center justify-content-end">
                                $1800.00
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> */}

                      {/*
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div className="billingHistoryBody">
                            <div className="billingHistoryList">
                              <img
                                alt="..."
                                className="rounded-circle mr-3 arrowIcon"
                                src={
                                  require("assets/img/selectbox.svg").default
                                }
                              />
                            </div>
                            <div className="billingHistoryList">$8000.00</div>
                            <div className="billingHistoryList">Completed</div>
                            <div className="billingHistoryList">
                              <img
                                alt="..."
                                className="rounded-circle mr-3 objectFitCover"
                                width={32}
                                height={32}
                                src="https://images.unsplash.com/photo-1554151228-14d9def656e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZmFjZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60"
                              />
                              Thad Bees
                            </div>
                            <div className="billingHistoryList">
                              Mar 10, 2022
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="billingHistoryContentWrapper py-3"
                            style={{
                              paddingLeft: "34px",
                              paddingRight: "16px",
                            }}
                          >
                            <div className="fontSize12 blackColor fontWeight300">
                              <div className="fontWeight500 mb-2">
                                Billing Plan
                              </div>
                              <div className="mb-2">
                                Best Enterprise Rate
                                <span className="fontWeight500"> $8000 </span>
                              </div>
                              <div className="mb-2">
                                Additional Seats
                                <span className="fontWeight500">
                                  4 at $25/seat
                                </span>
                              </div>
                              <div className="mb-2">Tax $56.23</div>
                              <div className="mb-2">
                                Total:
                                <span className="fontWeight500"> $8100 </span>
                              </div>
                            </div>
                            <div className="fontSize12 blackColor fontWeight300 border-right">
                              <div className="fontWeight500 mb-2">Details</div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Status
                                    </div>
                                    Complete
                                  </div>
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Invoice Date
                                    </div>
                                    Feb 24, 2022
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Invoice ID
                                    </div>
                                    #229389
                                  </div>
                                  <div className="mb-2">
                                    <div className="fontWeight500 mb-1">
                                      Date Paid
                                    </div>
                                    March 10, 2024
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="blackColor fontSize18 h-100 d-flex align-items-center justify-content-end">
                                $1800.00
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
}
export default Billingpayment;
