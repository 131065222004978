import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { eventsService } from "_services/masters/events.service";
import { GuestEventWrapper } from "./style";
import { ReactSession } from "react-client-session";
import { combinedService } from "../../../_services/masters/information.combined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GuestGroupDropdownlistEdit from "components/Dropdown/GuestGroupDropdownlistEdit";
import EventGuestDropdownEdit from "components/Dropdown/EventGuestDropdownEdit";
import { FaChevronLeft } from "react-icons/fa";
import HonoredGuestRolesDropdownaddEdit from "components/Dropdown/HonoredGuestRolesDropdownaddEdit";
import { BsFillChatDotsFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SocialMediaList from "../../../components/model-form/social-media-list";
import placeholde from "../../../assets/img/placeholder.jpg";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import Filemanager from "../../../components/CommonMediaModal/Filemanager";
import en from "react-phone-number-input/locale/en.json";
import GlobalButton from "components/Button/GlobalButton";
import { informationService } from "../../../_services/masters/information.service";
import {
  MdAccountCircle,
} from "react-icons/md";
import Allheader from "components/Headers/AllheaderEvent";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";

import makeAnimated from "react-select/animated";
import newUserIcon from "./../../../assets/img/new-user-icon.svg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import { useHistory } from "react-router-dom";
import {
  useFindPathSid,
  useFindPathEid,
  useFindPathid,
} from "../../../CommonData";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { commonData } from "store/actions";
import ChatIcon from "./../../../assets/img/message-icon.svg";
import SendIcon from "./../../../assets/img/sendIcon.svg";
import DeleteGuestSingle from "./GuestDeleteSingle";
import InfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";

const animatedComponents = makeAnimated();
const EditUserGuestList = () => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const params = useParams();
  const [country, setCountry] = React.useState('us');
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [accountType, setAccountType] = React.useState();
  const [eventLists, seteventLists] = React.useState();
  let sid = useFindPathSid(3);
  const [guestGroupList, setGuestGroupList] = useState([]);
  let eid = useFindPathEid(4);
  let id = useFindPathid(5);
  const [eventList, seteventList] = React.useState();
  const [guestgroupid, setGuestgroupid] = useState([]);
  const onClickguestGroup = (e) => {
    setGuestgroupid(e);
  };
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  }
  function datatolist() {
    const id = { event_id: common?.eid };
    combinedService.guestGroupList(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestGroupList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const [alldetails, setAlldetails] = useState({});
  let history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [listPrefix, setListPrefix] = useState([]);
  const [listSuffi, setListSuffi] = useState([]);
  const [suffix, setSuffix] = useState("");

  const [chatModal, setChatModal] = useState(false);
  const [endChatModal, setEndChatModal] = useState(false);
  const [savedChatModal, setSavedChatModal] = useState(false);
  const [chatwithGuestModal, setChatwithGuestModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const handelChatModal = () => {
    setChatModal(!chatModal);
  };
  const handelEndChatModal = () => {
    setEndChatModal(!endChatModal);
  };
  const handelSavedChatModal = () => {
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
      setEndChatModal(false);
      setSavedChatModal(true);
    }, "1000");
  };
  const handelCloseSavedChatModal = () => {
    setSavedChatModal(false);
    setChatModal(false);
    setChatwithGuestModal(false);
  };

  const handelChatwithGuestModal = () => {
    setChatwithGuestModal(!chatwithGuestModal);
  };

  function handleChangeprefix(dateValue) {
    setPrefix(dateValue);
    setValue("prefix", dateValue, { shouldValidate: true });
  }
  function handleChangesuffix(dateValue) {
    setSuffix(dateValue);
    setValue("suffix", dateValue, { shouldValidate: true });
  }
  const [eventItemsid, setEventItemsid] = useState([]);
  const onClickEvent = (e) => {
    setEventItemsid(e);

    if (sessionStorage.getItem("row_id")) {
      let obj = {};
      obj["user_id"] = sessionStorage.getItem("row_id");
      obj["type"] = "guest_event";
      obj["event_id"] = eid;
      obj["drop_down_data"] = e;

      //console.log('roleItems onAdd --> ', obj);

      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status === "success") {
            //setCount(200)
            sessionStorage.removeItem("row_id");
            toast.success("Upload successfully !!", "Account");
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const guestListOption = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
  ];
  const [roleItemsid, setRoleItemsid] = useState([]);
  const onClickRole = (e) => {
    setRoleItemsid(e);

    if (sessionStorage.getItem("row_id")) {
      let obj = {};
      obj["user_id"] = sessionStorage.getItem("row_id");
      obj["type"] = "guest_relation";
      obj["event_id"] = common.eid;

      obj["drop_down_data"] = e;

      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status === "success") {
            toast.success("updated successfully");
            sessionStorage.removeItem("row_id");
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  const showEntries = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
  ];
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handleClick = () => {
    setModalShow(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [socialmedia, setSocialmedia] = React.useState(false);
  const [guestCompletestatus, setGuestCompletestatus] = React.useState("0");

  const percentage = guestCompletestatus;
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  useEffect(() => {
    prefixlistguest();
    suffixlistguest();
    guestAllDetails();
    datatolist();
    const id12 = { event_id: common.eid };
    eventsService.eventDetails(id12).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventLists(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    sessionStorage.removeItem("row_id");
  }, [params?.id]);

  useEffect(() => {
    seteventList(common.eventList);
  }, [common]);
  function suffixlistguest() {
    userGuestService.listSuffix().then(
      (res) => {
        if (res.status === "success") {
          setListSuffi(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function prefixlistguest() {
    userGuestService.listPrefix().then(
      (res) => {
        if (res.status === "success") {
          setListPrefix(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const PhoneNumber = (event) => {
    setPhoneNumber(event);
    setState({
      ...state,
      mobile_number: event,
    });
  };
  const [guestRsvpsStatus, setGuestRsvpsStatus] = useState("");
  function guestAllDetails() {
    userGuestService.guestDetails(params?.id).then(
      (res) => {
        if (res.status === "success") {

          setAlldetails(res.data);
          setValue("prefix", res.data.surname, { shouldValidate: true });
          setPrefix(res.data.surname);
          setValue("first_name", res.data.first_name, { shouldValidate: true });
          setValue("last_name", res.data.last_name, { shouldValidate: true });
          setValue("suffix", res.data.suffix, { shouldValidate: true });
          setState({
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            email: res.data.email,
            mobile_number: res.data.mobile_number,
          });

          const obj = {
            id: res.data.id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            type: "GuestDetails",
          };
          sessionStorage.setItem("detailsOfUser", JSON.stringify(obj));

          setSuffix(res.data.suffix);
          setValue(
            "guest_personal_greeting",
            res.data.guest_personal_greeting,
            { shouldValidate: true }
          );
          setValue("admin_notes_on_guest", res.data.admin_notes_on_guest, {
            shouldValidate: true,
          });
          setCountry(res.data.country_code);
          setAccountType(res.data.account_type);

          setGuestCompletestatus(res.data.guest_complete_status);

          //setGuestRsvpsStatus(status);

          setValue("email", res.data.email, { shouldValidate: true });

          setTimeout(() => {
            setValue("mobile_number", res.data.mobile_number, {
              shouldTouch: true,
            });
            setPhone(res.data.mobile_number);
          }, 500);


          setSocialmedia(res.data.social_media);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => {
        onChange(event.target.value || undefined);
        setCountry(event.target.value);
        setPhoneNumber("");
        setValue("mobile_number", "", { shouldTouch: true });
      }}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {labels[country]} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );
  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };
  const [allimage, setAllimage] = React.useState([]);

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "profile",
      user_id: alldetails.id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    });

    informationService.profileUpdate(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Profile upload successfully !!", "Account");

          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
          guestAllDetails();
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const seletedImageClike = (e) => {
    let kayVal = {};
    kayVal["val"] = e.url;
    kayVal["key"] = "imageUrl";
    kayVal["id"] = id;
    userGuestService.updateData(kayVal).then(
      (res) => {
        if (res.status === "success") {
          setModalShow(false);
          guestAllDetails();
          toast.success("updated successfully");
        } else {
          console.log("Something went wrong !!");
          toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        toast.error("Something went wrong !!");
      }
    );
  };

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [resetting, setResetting] = useState(false);
  const handleStatusOfGuestUser = (d) => {
    setStatusGuestUser(!statusGuestUser);
  };
  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
    }, 3000);
  };
  const [socialMe, setSocialMe] = useState([]);
  const addElement = (e, id) => {

    let obj = {};
    obj["user_id"] = alldetails.id;
    obj["type"] = "social_media";
    obj["social_media"] = e;

    informationService.updateSocialmedia(obj).then(
      (res) => {
        if (res.status === "success") {
          guestAllDetails();

          toast.success("updated successfully");

        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  /***Datat Set */
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (params?.eid) {
      const id = { event_id: params?.eid };
      eventsService.eventDetails(id).then(
        (res) => {
          if (res.status === "success") {
            let sid = res.data.storyz_id;
            let eid = res.data.id;
            let storyName = res.data.storyz_name;
            let singleEvent = res.data;
            dispatch(commonData(common));
            myFunctionList(sid, eid, storyName, singleEvent);
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [params]);
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  const onSubmit = (data) => {

    const data1 = {
      id: params?.id,
      type: "Guest",
      guest_rsvps_status: guestRsvpsStatus,
      first_name: data.first_name,
      prefix: data.prefix,
      last_name: data.last_name,
      suffix: data.suffix,
      mobile_number: `${data.mobile_number}`,
      country_code: country,
      storyz_id: sid,
      event_id: eid,
      admin_notes_on_guest: data.admin_notes_on_guest,
    };

    informationService.Guestupdate(data1).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Profile upload successfully !!", "Account");
          guestAllDetails();
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [accountTypes, setAccountTypes] = useState(false);
  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
  };

  const [changeEmail, setChangeEmail] = useState(false);
  const handleChangeEmail = () => {

    setChangeEmail(!changeEmail);

  }

  const EditGuestdata = () => {
    setAccountTypes(true);
    setChangeEmail(!changeEmail);
  }



  const [phone, setPhone] = useState("");
  let handleOnChangephone = e => {
    setPhone(e.target.value);
  };

  let handleOnChange = value => {
    setCountry(value.country.countryCode);
    setPhone(value.phone);
    setState({
      ...state,
      mobile_number: value.phone,
    });
    setValue("mobile_number", value.phone, { shouldTouch: true });
  };
  return (
    <>
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />

      <Modal
        isOpen={changeEmail}
        toggle={handleChangeEmail}
        className="changeEmailModal"
      >
        <ModalBody>
          <div className="changeEmailHeader border-bottom">
            <img src={InfoIcon} alt="" className="changeEmailInfoIcon" />
            <div className="head">
              Do you really want to change this email address?
            </div>
          </div>
          <Row>
            <Col>
              <div className="deleteEverythingThumWrapper">
                <div className="deleteEverythingThum">
                  <img
                    src={
                      alldetails?.imageUrl ? alldetails?.imageUrl : placeholde
                    }
                    alt=""
                    className="deleteEverythingThumImg"
                  />
                </div>
                <div className="deleteEverythingThumContent">
                  <div className="deleteEverythingthumbTitle">
                    {state?.first_name} {state?.last_name}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                current email: <span className="bold">{alldetails?.email}</span>
              </p>
              <p>
                If you edit the email you will invalidate their current login.
                If they are using the guest app in an event, it will invalidate
                their RSVP.
              </p>
              <p className="bold">Make sure you want to do this!</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="flex-column">
          <div
            color="primary"
            className="btn pinkBtn w-100"
            onClick={EditGuestdata}
          >
            Yes, change the email
          </div>
          <div
            className="btn btn-default cancelBtn w-100"
            onClick={handleChangeEmail}
          >
            Cancel
          </div>
        </ModalFooter>
      </Modal>
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />
            <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
              <div className="column2">
                <div className="column2sate backgroeventdata">
                  <div className="title">
                    <div className="badge">Event</div>
                    {common?.singleEvent?.event_name
                      ? common?.singleEvent?.event_name
                      : ""}
                  </div>
                  <div>
                    <div className="imageeventim234">
                      <span>Guest List</span>
                    </div>
                  </div>
                </div>
                <div className="organizationPara border-bottom">
                  This page lets you view and create a todo list associated with
                  this specific Storyz. <br /> To see the complete list of
                  todos, go to the
                  <Link
                    onClick={() => {
                      history.push(`/admin/storyz/${sid}`);
                    }}
                    className="text-decoration-underline"
                  >
                    Storyz view
                  </Link>
                  or
                  <Link
                    onClick={() => {
                      history.push(`/admin/organization`);
                    }}
                    className="text-decoration-underline"
                  >
                    Organization view
                  </Link>
                  .
                </div>

                <GuestEventWrapper className="p-4">
                  <Modal
                    isOpen={chatModal}
                    toggle={handelChatModal}
                    className="addSupportTicketModalWrapper"
                  >
                    <ModalHeader
                      className="fontSize20 whiteColor align-items-center"
                      toggle={handelChatModal}
                    >
                      Chat With a Guest
                    </ModalHeader>
                    <ModalBody className="bg4">
                      <Row>
                        <Col>
                          <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                            <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                              <img src={ChatIcon} alt="" className="mr-2" />
                              Chat Header
                            </div>
                            <div
                              className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                              onClick={handelEndChatModal}
                            >
                              end chat
                            </div>
                          </div>

                          <div className="mt-4">
                            <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2 text-right">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                              <input
                                type="email"
                                className="form-control text-right sendBtnInput"
                                id="exampleFormControlInput1"
                                placeholder="This is chat text"
                              />
                              <Button type="button" className="sendBtn">
                                <img
                                  src={SendIcon}
                                  alt=""
                                  width={24}
                                  height={24}
                                  className="sendBtnIcon"
                                />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    isOpen={endChatModal}
                    toggle={handelEndChatModal}
                    className="saveChatTemplateModal"
                  >
                    <ModalBody>
                      <div className="d-flex align-items-center pb-2 mb-3 border-bottom fontSize20 blackColor">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/circle-chat-icon.svg").default
                          }
                          className="m12-right"
                          width={88}
                          height={88}
                        />
                        Save Chat Transcript
                      </div>
                      <p className="mb-0 blackColor">
                        Choose where to save the transcript. The transcript is a
                        simple text file. Images and attachments will not be
                        saved.
                      </p>
                    </ModalBody>
                    <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                      <Button
                        color="primary"
                        className="flex-grow-1 blueBtn rounded"
                        onClick={handelSavedChatModal}
                      >
                        Email Transcript
                      </Button>
                      <Button
                        className="btn-default cancelBtn"
                        onClick={handelEndChatModal}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={savedChatModal}
                    toggle={handelSavedChatModal}
                    className="savedChatModal"
                    backdrop={false}
                  >
                    <ModalBody>
                      <div className="align-items-center pb-2 mb-4 border-bottom fontSize20 blackColor d-flex flex-column">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/circle-saved-chat-icon.svg")
                              .default
                          }
                          className="m12-bottom"
                          width={88}
                          height={88}
                        />
                        Chat Transcript Saved
                      </div>
                      <p className="mb-0 blackColor">
                        Transcript was saved to your email.
                      </p>
                    </ModalBody>
                    <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                      <Button
                        color="primary"
                        className="flex-grow-1 blueBtn rounded w-100"
                        onClick={handelCloseSavedChatModal}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal isOpen={loadingModal} className="loadingModal">
                    <ModalBody className="d-flex align-items-center justify-content-center">
                      <Row>
                        <Col>
                          <div className="circleAnimation1"></div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal
                    isOpen={chatwithGuestModal}
                    toggle={handelChatwithGuestModal}
                    className="addSupportTicketModalWrapper"
                  >
                    <ModalHeader
                      className="fontSize20 whiteColor align-items-center"
                      toggle={handelChatwithGuestModal}
                    >
                      Broadcast to all Guests
                    </ModalHeader>
                    <ModalBody className="bg4">
                      <Row>
                        <Col>
                          <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                            <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                              <img src={ChatIcon} alt="" className="mr-2" />
                              Chat Header
                            </div>
                            <div
                              className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                              onClick={handelEndChatModal}
                            >
                              end chat
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="form-check mr-3 pl-0">
                              <input
                                className="form-check-input"
                                type="radio"
                                value=""
                                name="repeating_event"
                                checked
                              />
                              <label
                                className="form-check-label grayColor"
                                for="flexRadioDefault1"
                              >
                                All Members
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                value=""
                                name="repeating_event"
                              />
                              <label
                                className="form-check-label grayColor"
                                for="flexRadioDefault1"
                              >
                                Lead Member
                              </label>
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                              <div className="my-2 text-right">
                                <div className="">
                                  <div className="fontSize14 fontWeight300 blackColor mb-3">
                                    1:23pm
                                  </div>
                                  <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                                    This is chat text
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                              <input
                                type="email"
                                className="form-control text-right sendBtnInput"
                                id="exampleFormControlInput1"
                                placeholder="This is chat text"
                              />
                              <Button type="button" className="sendBtn">
                                <img
                                  src={SendIcon}
                                  alt=""
                                  width={24}
                                  height={24}
                                  className="sendBtnIcon"
                                />
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <div className="d-flex align-items-center pt-4">
                    <button
                      className="messageBtn active"
                      type="button"
                      onClick={handelChatModal}
                    >
                      <BsFillChatDotsFill className="icon" />
                      Message
                    </button>
                    <div className="guestCircleWrapper ml-auto">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                    <GlobalButton icon customClass="ml-2" type="sumbit">
                      Save Changes
                    </GlobalButton>
                    {/* <DeleteBtn icon> Delete Guest </DeleteBtn> */}
                  </div>
                  <div className="organizeByGroupOuter">
                    <Row className="addGuestUerFormWrapper">
                      <Col className="" md={12} lg={6}>
                        <div>
                          <div className="heading">Basic Information</div>
                          <div className="cardBox">
                            <div className="basiInfoWrapper ">
                              <div
                                className="backBtn"
                                onClick={() => {
                                  sessionStorage.removeItem("detailsOfUser");
                                }}
                              >
                                <Link
                                  to={`/admin/event-user-guest-list/${sid}/${eid}/?type=guests`}
                                >
                                  <FaChevronLeft size={24} />
                                </Link>
                              </div>
                              <div className="userPic pointer">
                                {/* <div className="plusIcon">+</div> */}
                                <img
                                  className="userPicImg"
                                  src={
                                    alldetails?.imageUrl
                                      ? alldetails?.imageUrl
                                      : placeholde
                                  }
                                  // src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80"
                                  alt=""
                                  onClick={() => {
                                    setModalShow(true);
                                  }}
                                />
                                <div className="guestRole text-center">
                                  {accountType ? accountType + " Role" : " "}{" "}
                                </div>
                              </div>
                              <div>
                                <img
                                  src={newUserIcon}
                                  alt=""
                                  className="newUserIcon"
                                  onClick={() => {
                                    setModalShow(true);
                                  }}
                                />
                                {modalShow ? (
                                  <Filemanager
                                    show={modalShow}
                                    onHide={handleClick}
                                    onClickimageUpdate={imageUpdate}
                                    list={allimage}
                                    seletedImage={seletedImageClike}
                                  />
                                ) : (
                                  ""
                                )}
                                <h2 className="newGuestTitle">
                                  {state.first_name || state.last_name
                                    ? state.first_name + " " + state.last_name
                                    : "New Guest"}
                                </h2>
                                <button type="button" className="notAdmin">
                                  Not an Admin
                                </button>
                                {/* <div
                                  className="RSVPchip failed"
                                  onClick={handleStatusOfGuestUser}
                                >
                                  NOT GOING
                                </div> */}

                                <Modal
                                  isOpen={statusGuestUser}
                                  toggle={handleStatusOfGuestUser}
                                  className="resetGeospaceModal statusofGuestaListModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                                      <img
                                        src={ResetGeospaceInfoIcon}
                                        alt=""
                                        className="geospaceInfoIcon"
                                      />
                                      <div className="head">
                                        Force the RSVP Status for This Guest?
                                      </div>
                                    </div>
                                    <Row>
                                      <Col className="px-4">
                                        <div className="eventThumWrapper">
                                          <div className="eventThum">
                                            <img
                                              src={
                                                eventLists?.cover_image
                                                  ? eventLists?.cover_image
                                                  : placeholde
                                              }
                                              alt=""
                                              className="eventThumImg"
                                            />
                                          </div>
                                          <div className="eventThumContent">
                                            <div className="eventthumbTitle">
                                              {eventLists?.event_name}
                                            </div>
                                            <div className="eventthumbSubTitle">
                                              {eventLists?.location}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="geospaceThumWrapper">
                                          <div className="geospaceThum">
                                            <img
                                              src={
                                                alldetails?.imageUrl
                                                  ? alldetails?.imageUrl
                                                  : placeholde
                                              }
                                              alt=""
                                              className="geospaceThumImg"
                                            />
                                          </div>
                                          <div className="geospaceThumContent">
                                            <div className="geothumbTitle">
                                              {alldetails?.first_name}{" "}
                                              {alldetails?.last_name}
                                            </div>
                                            <div className="geothumbSubTitle">
                                              Event Role
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-2 ">
                                      <Col className="px-4">
                                        <p>
                                          You are manually setting the status of
                                          this Guest’s RSVP. Select an RSVP
                                          state belolow by clicking one of the
                                          following buttons:
                                        </p>
                                        <hr className="mb-4 mt-3" />
                                        <div className="statusGuestUserWrapper mb-3">
                                          <div className="form-check pending">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1PENDING"
                                              value="PENDING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("PENDING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "PENDING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1PENDING"
                                            >
                                              PENDING
                                            </label>
                                          </div>
                                          <div className="form-check going">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1GOING"
                                              value="GOING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("GOING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "GOING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1GOING"
                                            >
                                              GOING
                                            </label>
                                          </div>
                                          <div className="form-check notGoing">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1NOT"
                                              value="NOT GOING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("NOT GOING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "NOT GOING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1NOT"
                                            >
                                              NOT GOING
                                            </label>
                                          </div>
                                          <div className="form-check attended">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1ATTENDED"
                                              value="ATTENDED"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("ATTENDED")
                                              }
                                              checked={
                                                guestRsvpsStatus === "ATTENDED"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1ATTENDED"
                                            >
                                              ATTENDED
                                            </label>
                                          </div>
                                        </div>
                                        <p>
                                          Are you sure you want to do this? This
                                          will erase the RSVP status set by the
                                          system.
                                        </p>
                                      </Col>
                                    </Row>

                                    <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                                      <Button
                                        color="primary"
                                        className="flex-grow-1 pinkBtn"
                                        onClick={handleReseting}
                                      >
                                        Force RSVP Status
                                      </Button>
                                      <Button
                                        className="btn-default cancelBtn"
                                        onClick={() =>
                                          handleStatusOfGuestUser(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                          </div>

                          <div className="cardBox formBox d-flex">
                            <div className="inlineFormBox flex-grow-1">
                              <div className="form-group flex-grow-1">
                                <label className="form-label">
                                  Prefix (Ms., Dr.):
                                </label>
                                <input
                                  {...register("prefix")}
                                  type="hidden"
                                  defaultValue={prefix}
                                />
                                <div className="my-1">
                                  <GuestPrefixDropdown
                                    placeholder={
                                      prefix ? prefix : "Select Prefix"
                                    }
                                    guestPrefixOption1={listPrefix}
                                    caption="Pope"
                                    addUserBtn
                                    handleAddModal={""}
                                    style={
                                      errors.prefix
                                        ? { border: "1px solid #f00909" }
                                        : {}
                                    }
                                    handlerFunction={handleChangeprefix}
                                  />
                                </div>
                              </div>
                              <div className="form-group flex-grow-1">
                                <label className="form-label">
                                  First Name:
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  {...register("first_name", {
                                    required: true,
                                  })}
                                  className="form-control"
                                  placeholder="enter first name..."
                                  style={
                                    errors.first_name
                                      ? { border: "1px solid #f00909" }
                                      : {}
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cardBox formBox d-flex">
                            <div className="inlineFormBox flex-grow-1">
                              <div className="form-group flex-grow-1">
                                <label className="form-label">Last Name:</label>
                                <input
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  {...register("last_name", { required: true })}
                                  placeholder="enter last name..."
                                  style={
                                    errors.last_name
                                      ? { border: "1px solid #f00909" }
                                      : {}
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group flex-grow-1">
                                <label className="form-label">
                                  Suffix (Esq, Phd.):
                                </label>
                                <input
                                  {...register("suffix")}
                                  type="hidden"
                                  defaultValue={suffix}
                                />
                                {suffix ? (
                                  <div className="my-1">
                                    <GuestPrefixDropdown
                                      placeholder={
                                        suffix ? suffix : "Select Suffix"
                                      }
                                      guestPrefixOption1={listSuffi}
                                      caption="Grand Wizard"
                                      addUserBtn
                                      handleAddModal={""}
                                      style={
                                        errors.suffix
                                          ? { border: "1px solid #f00909" }
                                          : {}
                                      }
                                      handlerFunction={handleChangesuffix}
                                    />
                                  </div>
                                ) : (
                                  <div className="my-1">
                                    <GuestPrefixDropdown
                                      placeholder={"Select Suffix"}
                                      guestPrefixOption1={listSuffi}
                                      caption="Grand Wizard"
                                      addUserBtn
                                      handleAddModal={""}
                                      style={
                                        errors.suffix
                                          ? { border: "1px solid #f00909" }
                                          : {}
                                      }
                                      handlerFunction={handleChangesuffix}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="heading">Guest Personal Greeting</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Short Greeting on Guest Profile:
                              </label>
                              <input
                                type="text"
                                name="guest_personal_greeting"
                                className="form-control"
                                placeholder="Short Greeting on Guest Profile..."
                                {...register("guest_personal_greeting")}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="heading">
                            Admin Notes on this Guest
                          </div>

                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Add any special information about this guest
                                here:
                              </label>
                              <textarea
                                type="text"
                                name="admin_notes_on_guest"
                                className="form-control"
                                placeholder="enter text..."
                                {...register("admin_notes_on_guest")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className="" md={12} lg={6}>
                        <div>
                          <div className="heading">Contact Information</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Mobile Number:
                              </label>
                              <div className="d-flex align-items-center countryMobileWrpper123">
                                <PhoneInput
                                  name="mobile_number"
                                  autoCorrect="off"
                                  placeholder="Enter a Valid Phone Number"
                                  country={country}
                                  value={phone}
                                  onChange={(phone, country) => handleOnChange({ phone, country })}

                                />
                                <input
                                  style={{ display: "none" }}
                                  placeholder="Enter a Valid Phone Number"
                                  autoCorrect="off"
                                  id="mobile_number"
                                  name="mobile_number"
                                  {...register("mobile_number", {
                                    required: "Mobile number is required",
                                    pattern: {
                                      value: /[0-9]+/,
                                      message: "This input is number only."
                                    },
                                    minLength: {
                                      value: 11,
                                      message: "Please input proper number."
                                    }
                                  })}
                                  value={phone}
                                  onChange={handleOnChangephone}
                                />


                                {/* <ChakraPhone value={chakraPhone} onChange={setChakraPhone} /> */}

                              </div>

                              {errors.mobile_number?.message && (
                                <small style={{ color: "#ff0000" }}>{errors.mobile_number?.message}</small>
                              )}
                            </div>

                            <div className="formBox">
                              <div className="form-group">
                                <label className="form-label">Email:</label>
                                <div
                                  className="d-flex appendIconNew"
                                  onClick={() =>
                                    accountTypes === true
                                      ? ""
                                      : handleChangeEmail("email")
                                  }
                                >
                                  <input
                                    type="eamil"
                                    readonly={
                                      accountTypes === true ? false : true
                                    }
                                    name="email"
                                    className="form-control"
                                    placeholder="enter text"
                                    {...register("email", { required: true })}
                                    style={
                                      errors.email
                                        ? { border: "1px solid #f00909" }
                                        : {}
                                    }
                                    onChange={handleChange}
                                  />
                                  <InputGroupAddon
                                    addonType="append"
                                    className="pointer"
                                  >
                                    <InputGroupText>
                                      <i className="fas fa-envelope" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input type="hidden" value={guestgroupid} />
                        <div className="heading">Social Media</div>
                        <div className="d-flex align-items-center my-4">
                          <SocialMediaList
                            data={alldetails}
                            socialmediadata={
                              alldetails?.social_media
                                ? alldetails.social_media
                                : []
                            }
                            add={addElement}
                          />
                          <div>
                            {alldetails.social_media?.length > 0 &&
                              alldetails.social_media?.map((user) => (
                                <>
                                  {user.name === "facebook" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={FacebookIcon} alt="" />
                                    </a>
                                  )}
                                  {user.name === "instagram" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={InstagramIcon} alt="" />
                                    </a>
                                  )}
                                  {user.name === "tiktok" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={TiktokIcon} alt="" />
                                    </a>
                                  )}
                                  {user.name === "twitter" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={TwitterIcon} alt="" />
                                    </a>
                                  )}
                                  {user.name === "youtube" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={YoutubeIcon} alt="" />
                                    </a>
                                  )}
                                  {user.name === "vimeo" && (
                                    <a
                                      target="_blank"
                                      href={user.value}
                                      className="socialIcon"
                                      key={Math.random()}
                                    >
                                      <img src={VimeoIcon} alt="" />
                                    </a>
                                  )}
                                </>
                              ))}
                          </div>
                        </div>
                        {/* <div>
                        <div className="p-3 heading">Social Media</div>
                        <Row className="my-3 p-2">
                          <Col>
                            <div>
                              <button
                                type="button"
                                className="editSocialBtn disabled"
                              >
                                Edit Social
                              </button>
                              <Link to="/" className="socialIcon">
                                <img src={InstagramIcon} alt="" />
                              </Link>
                              <Link to="/" className="socialIcon">
                                <img src={TiktokIcon} alt="" />
                              </Link>
                              <Link to="/" className="socialIcon">
                                <img src={TwitterIcon} alt="" />
                              </Link>
                              <Link to="/" className="socialIcon">
                                <img src={FacebookIcon} alt="" />
                              </Link>
                              <Link to="/" className="socialIcon">
                                <img src={YoutubeIcon} alt="" />
                              </Link>
                              <Link to="/" className="socialIcon">
                                <img src={VimeoIcon} alt="" />
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div> */}
                        <div>
                          <div className="heading">
                            Invited Events in Storyz
                          </div>
                          <div className="cardBox">
                            <div
                              className="form-group"
                              onClick={() => {
                                sessionStorage.setItem("row_id", alldetails.id);
                              }}
                            >
                              <label className="form-label">
                                Events guest is invited to (type to search
                                events):
                              </label>
                              {eventList ? (
                                <div className="EventdropdownOuter">
                                  <EventGuestDropdownEdit
                                    placeholder="find event "
                                    guestListOption={eventList}
                                    onChange={onClickEvent}
                                    rowData={alldetails.res_event}
                                    eid={common.eid}
                                    gid={alldetails.id}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div>
                        <div className="heading">Invited Events in Storyz</div>
                        <div className="cardBox">
                          <div className="form-group">
                            <label className="form-label">
                              Events guest is invited to (type to search
                              events):
                            </label>
                            <div className="inviteGuestWrapper">
                              {eventList ? (
                                <div className="EventdropdownOuter">
                                  <EventGuestDropdownEdit
                                    placeholder="find event "
                                    guestListOption={eventList}
                                    onChange={onClickEvent}
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              <EventGuestListDropdown
                                placeholder="Find more event roles"
                                guestListOption={eventList}
                                onChange={onClickEvent}
                                rowData={alldetails.res_event}
                              />

                            </div>
                          </div>
                        </div>
                      </div> */}
                        <div>
                          <div className="heading">Honored Guest Roles</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <HonoredGuestRolesDropdownaddEdit
                                placeholder="Find more event roles"
                                onClickrole={onClickRole}
                                selectdata={alldetails.guest_relation}
                                gid={alldetails.id}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="heading">Guest Groups</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Member of Guest Groups (type below to add a
                                group):
                              </label>

                              <GuestGroupDropdownlistEdit
                                placeholder="Find guest group"
                                guestListOption={guestGroupList}
                                onChange={onClickguestGroup}
                                data={alldetails.res_group_type}
                                gid={alldetails.id}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </GuestEventWrapper>
              </div>
            </form>
          </Eventstyle>
        </div>

        <button
          type="button"
          className="resetGeoBtn"
          onClick={handleDeleteGuestUser}
        >
          <MdAccountCircle /> Delete Guest
        </button>
        {deleteGuestUser && (
          <DeleteGuestSingle
            show={deleteGuestUser}
            onHide={handleDeleteGuestUser}
            single={alldetails}
            urlRedirect={true}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default EditUserGuestList;
