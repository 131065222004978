import React, { useState } from "react";
import { Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Loader from "components/Modal/Loader";
import DummyImg from "../../assets/img/dummyImg.svg";
const RemoveLoginModal = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const [adminLoginRemoved, setAdminLoginRemoved] = useState(false);
  const [restoreAdminLogin, serRestoreAdminLogin] = useState(false);
  const [restoredAdminLogin, serRestoredAdminLogin] = useState(false);
  // const [restoreContinue, setRestoreContinue] = useState(false);
  const [removeLogin, setRemoveLogin] = useState(true);

  let history = useHistory();
  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };

  function closeModal(e) {
    setIsLoading(false);
    props.closeModal();
    e.stopPropagation();
  }
  const exitOurStoryz = (e) => {
    history.push("/admin/organization");
    // setAdminLoginRemoved(false);
    // setRemoveLogin(false);


  };
  const removeAdminLogin = () => {
    setAdminLoginRemoved(true);
    setRemoveLogin(false);
  };

  const handleRestoreAdminLogin = () => {
    setAdminLoginRemoved(false);
    serRestoreAdminLogin(true);
    setRemoveLogin(false);
  };

  const handleRestoredAdminLogin = () => {
    setAdminLoginRemoved(false);
    serRestoreAdminLogin(false);
    serRestoredAdminLogin(true);
    setRemoveLogin(false);
  };

  const CancelRestoreAdminLogin = () => {
    history.push("/admin/organization");
    // serRestoreAdminLogin(false);
    // setAdminLoginRemoved(false);
    // setRemoveLogin(false);
  };


  const handleRestoreContinue = () => {
    window.location.reload(false);
    // setRestoreContinue(false);
    // setAdminLoginRemoved(false);
    // serRestoreAdminLogin(false);
    // serRestoredAdminLogin(false);
    // setRemoveLogin(false);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {removeLogin && (
        <div
          className="modal storyzdelet eventDeleteModal accountDeleteModal removedLoginModal"
          style={divStyle}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="head border-bottom">
              <img
                alt="Organization Name"
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                className="icon"
              />
              <h2>Remove Admin Login?</h2>
            </div>
            <div className="popdataWrapper mx-0">
              <div className="popdata popdataOrg">
                <div className="podImg">
                  <img
                    className=""
                    alt="Organization Name"
                    src={props?.data?.imageUrl ? props?.data?.imageUrl : DummyImg}
                  />
                </div>
                <div>
                  <div className="title">{props?.data?.first_name} {props?.data?.last_name}</div>
                  <div className="subTitle">{props?.data?.account_type}</div>
                </div>
              </div>
            </div>

            <p>
              This will remove the login for this Admin. Their Guest information
              will be unchanged.
            </p>
            <p>
              To rejoin the team, the Admin will have to receive and invitation
              from the Organization.
            </p>

            <div className="modal-footer">
              <Button
                className="pinkBtn flex-grow-1"
                type="button"
                onClick={removeAdminLogin}
              >
                Delete Account
              </Button>

              <Button className="cancelBtn" type="button" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            {/* {isLoading === true ? (
              <>
                <div className="toloader">
                  {" "}
                  <TailSpin
                    className="loader"
                    color="#00BFFF"
                    height={120}
                    width={120}
                    style={{ margin: "auto" }}
                  />
                </div>
              </>
            ) : (
              ""
            )} */}
          </div>
        </div>
      )}
      {/*  */}
      {adminLoginRemoved && (
        <div className="modal storyzdelet removedLoginModal" style={divStyle}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="textimgs">
              <img
                alt="Organization Name"
                src={require("assets/img/circle-saved-chat-icon.svg").default}
              />
              <h2>Admin Login was Removed</h2>
              <div className="border123"></div>
            </div>
            <div className="py-4">
              <p>
                Login access for this Admin was removed. Guest information is
                unaffected. If this is your account, you will be logged out.
              </p>
              <p>
                To recover login access, use “Restore Login”. Otherwise, the
                Guest must receive an invite to become an Admin, generated by
                the Account Owner.
              </p>
            </div>
            <div className="modal-footer p-0">
              <Button
                className="pinkBtn flex-grow-1"
                type="button"
                onClick={exitOurStoryz}
              >
                Exit OurStoryz
              </Button>

              <Button
                className="cancelBtn"
                type="button"
                onClick={handleRestoreAdminLogin}
              >
                Restore Login
              </Button>
            </div>
          </div>
        </div>
      )}
      {/*  */}
      {restoreAdminLogin && (
        <div className="modal storyzdelet removedLoginModal" style={divStyle}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="textimgs">
              <img
                alt="Organization Name"
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
              />
              <h2>Restore Admin Login</h2>
              <div className="border123"></div>
            </div>
            <div className="mt-3">
              <p>
                This action will restore your login for the organization you are
                current logged into.
              </p>
            </div>
            <div className="modal-footer p-0 pt-4">
              <Button
                className="cancelBtn"
                type="button"
                onClick={CancelRestoreAdminLogin}
              >
                Cancel Restore
              </Button>
              <Button
                className="pinkBtn flex-grow-1"
                type="button"
                onClick={handleRestoredAdminLogin}
              >
                Continue Login Restore
              </Button>
            </div>
          </div>
        </div>
      )}
      {/*  */}
      {restoredAdminLogin && (
        <div className="modal storyzdelet removedLoginModal" style={divStyle}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="textimgs">
              <img
                alt="Organization Name"
                src={require("assets/img/circle-saved-chat-icon.svg").default}
              />
              <h2>Admin Account Restored</h2>
              <div className="border123"></div>
            </div>
            <div className="mt-3">
              <p>
                Welcome back! All your organization, project and event and
                billing information has been restored.
              </p>
            </div>
            <div className="modal-footer p-0 pt-4">
              <Button
                className="blueBtn flex-grow-1"
                type="button"
                onClick={handleRestoreContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RemoveLoginModal;
