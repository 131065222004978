import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import ExportGuestIcon from "./../../../assets/img/exportGuestListIcon.svg";
import placeholde from "../../../assets/img/placeholder.jpg";
import FileIcon from "./../../../assets/img/fileIcon.svg";
import { CSVLink, CSVDownload } from "react-csv";
import * as actionTypes from "../../../store/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import { BiDownArrowCircle } from "react-icons/bi";
function ExportGuestList({ show, onHide }) {
    const [exportGuestList, setExportGuestList] = useState(false);
    const [activeBtn, setActiveBtn] = useState(false);
    const headers = [
        { label: "RSVP", key: "rsvp_status" },
        { label: "Sir name", key: "surname" },
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Suffix", key: "suffix" },
        { label: "Email", key: "email" },
        { label: "Mobile number", key: "mobile_number" },
    ];
    const handleExportGuestList = () => {
        setExportGuestList(!show);
        setActiveBtn(false);
    };
    const common = useSelector((state) => {
        return state.commonData.common;
    });
    const allguestList = useSelector((state) => {
        return state.guestList.guestsList;
    });

    return (
        <>
            <Modal isOpen={show} toggle={onHide} className="exportGuestModal">
                <ModalBody className="pb-0">
                    <div className="exportGuestHeader border-bottom flex-row">
                        <img src={ExportGuestIcon} alt="" className="exportGuestInfoIcon" />
                        <div className="head">Export Guest List</div>
                    </div>
                    <Row>
                        <Col>
                            <p>
                                Export the guest list for this event in a variety of formats. To
                                export all guests, use the combined guest list in your Storyz.
                            </p>
                            <div className="repeateventDetails pl-0 mb-4">
                                {/* <div className="repeateeventDate">Aug 14</div> */}
                                <div>
                                    <img
                                        className="evimages"
                                        src={
                                            common?.singleEvent?.cover_image
                                                ? common?.singleEvent?.cover_image
                                                : placeholde
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="repeateventTitle">
                                    <div>{common?.singleEvent?.event_name}</div>
                                    <div className="repeateventlocation">
                                        {common?.singleEvent?.location}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2 ">
                        <Col>
                            <div className="exportFileListWrapper">
                                <div className="exportFileList">
                                    <div className="exportFileWrapper">
                                        <img src={FileIcon} alt="" className="exportFile" />
                                        <div className="exportFileText">.csv</div>
                                    </div>

                                    <CSVLink
                                        data={allguestList}
                                        headers={headers}
                                        filename={"combined_guest_pool_record.csv"}
                                        newLineSeparator="\r\n"
                                        onClick={() => {
                                            setActiveBtn(true);
                                        }}
                                    >
                                        <div className="exportFileBtn">
                                            <BiDownArrowCircle className="exportDownloadIcon" />
                                            Download CSV
                                        </div>
                                    </CSVLink>

                                    {/* <div className="exportFileDate">
                          Downloaded 7/2/2032 9:30AM
                        </div> */}
                                </div>

                                <div className="exportFileList">
                                    <div className="exportFileWrapper">
                                        <img src={FileIcon} alt="" className="exportFile" />
                                        <div className="exportFileText">.csv</div>
                                    </div>
                                    <CSVLink
                                        data={allguestList}
                                        headers={headers}
                                        filename={"combined_guest_pool_record.xlsx"}
                                        newLineSeparator="\r\n"
                                        onClick={() => {
                                            setActiveBtn(true);
                                        }}
                                    >
                                        <div className="exportFileBtn">
                                            <BiDownArrowCircle
                                                size={20}
                                                className="exportDownloadIcon"
                                            />
                                            Download Excel
                                        </div>
                                    </CSVLink>
                                </div>
                                {/* <div className="exportFileList">
                                    <div className="exportFileWrapper">
                                        <img src={FileIcon} alt="" className="exportFile" />
                                        <div className="exportFileText">Sheets</div>
                                    </div>
                                    <div className="exportFileBtn">
                                        <BiDownArrowCircle className="exportDownloadIcon" />
                                        Create Google Docs
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                    <Button
                        className=" blueBtn flex-grow-1 height46"
                        onClick={onHide}
                        disabled={!activeBtn}
                    >
                        Done
                    </Button>
                    <Button className=" cancelBtn" onClick={onHide}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default ExportGuestList;
