import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { PrivateRoute } from "./_privateRoute/privateRoute";
import "react-circular-progressbar/dist/styles.css";
// plugins styles downloaded

import "assets/scss/custom-scss/selectMenu.scss";
import "assets/scss/custom-scss/selectMenuNew.scss";
import "assets/scss/custom-scss/form.scss";
import "assets/scss/custom-scss/guestList.scss";
import "assets/scss/custom-scss/mediaModal.scss";
import "assets/scss/custom-scss/todoModal.scss";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import "assets/vendor/nucleo/css/nucleo.css";

import AdminLayout from "layouts/Admin.js";

// import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
// import IndexView from "views/Index.js";
import TwofactorAuthentication from "views/pages/examples/TwofactorAuthentication.js";
import PasswordRecovery from "views/pages/examples/PasswordRecovery.js";
import Invitations from "views/pages/examples/Invitations.js";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import OrganizationList from "layouts/OrganizationList";
import GuestCreateLogin from "views/pages/examples/GuestCreateLogin";
import NewGuestCreateLogin from "views/pages/examples/NewGuestCreateLogin";
import NewGuestCreateLoginForm from "views/pages/examples/NewGuestCreateLoginForm";
import TermCondition from "views/pages/examples/TermCondition";
import Newandcsv from "views/pages/examples/Newandcsv";
import WebListing from "views/pages/examples/WebListing";
import SignUpLayout  from "layouts/SignUpLayout.js";
import VerifyLayout  from "layouts/VerifyLayout.js";
import SingupFinlPageLayout  from "layouts/SingupFinlPageLayout.js";
import PaynowLayout  from "./layouts/PaynowLayout";
import SelectServiceLayout from "./layouts/SelectServiceLayout"

ReactDOM.render(
  <>

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                  <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
                  <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                  <Route path="/" render={(props) => <IndexView {...props} />} /> */}
            {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
            {/* { <PrivateRoute path='/admin' render={(props) => <AdminLayout {...props} />} /> } */}

            <PrivateRoute path="/admin" component={AdminLayout} />

            {/* <PrivateRoute path='/diy' component={DiyLayout} /> */}
            {/* <PrivateRoute path='/locator' component={LocatorLayout} />
          <PrivateRoute path='/professional' component={ProfessionalLayout} /> */}

            <Route exact path="/" render={(props) => <AuthLayout {...props} />} />
            <Route exact path="/signup" render={(props) => <SignUpLayout {...props} />} />
            <Route exact path="/verify" render={(props) => <VerifyLayout {...props} />} />
            <Route exact path="/singup-complete" render={(props) => <SingupFinlPageLayout {...props} />} />
            <Route exact path="/pay-now" render={(props) => <PaynowLayout {...props} />} />
            <Route exact path="/select-services" render={(props) => <SelectServiceLayout {...props} />} />

            
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/two-factor-authentication`}
              component={TwofactorAuthentication}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/password-recovery`}
              component={PasswordRecovery}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/organization-list`}
              component={OrganizationList}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/invitation/accept`}
              component={Invitations}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/existing-guest-create-login`}
              component={GuestCreateLogin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/new-guest-create-login`}
              component={NewGuestCreateLogin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/new-guest-create-login-form`}
              component={NewGuestCreateLoginForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/term-and-conditions`}
              component={TermCondition}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/new-and-csv`}
              component={Newandcsv}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/web-listing/:slug/:id`}
              component={WebListing}
            />

            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>

  </>
  ,
  document.getElementById("root")
);
