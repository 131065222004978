import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import YourCompany from "../../components/Organization/YourCompany";
import ReactTooltip from "react-tooltip";
import Allheader from "../../components/Headers/Allheader";
import Childorganizationmenu from "../../components/Organization/Childorganizationmenu";
import { informationService } from "../../_services/masters/information.service";
import { timezoneService } from "../../_services/masters/timezone.service";
import { useSelector } from "react-redux";
import { ReactSession } from "react-client-session";
function OrganizationDiy() {
  let history = useHistory();
  const [dataList, setdataList] = React.useState([]);
  const [eventList, seteventList] = React.useState([]);
  const [eventid, setEventid] = React.useState("");
  const [eventsId, setEventsid] = React.useState("");
  const key = "2e35f242a46d67eeb74aabc37d5e5d05";
  const contact = useSelector((state) => {
    return state.profile;
  });
  React.useEffect(() => {
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          setdataList(res.data);

          ReactSession.setStoreType("localStorage");
          ReactSession.set("project_id", res.data[0].id);

          setEventid(res.data[0].id);
          eventAlllisr(res.data[0].id);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  function eventAlllisr(id) {
    informationService.eventList(id).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const handleClick = (id) => {
    ReactSession.setStoreType("localStorage");
    ReactSession.set("project_id", id);
  };

  const handleChange = (e) => {
    const valueName = e.target.value;

    const data = {
      event: valueName,
    };

    timezoneService.eventSearch(data).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  // React.useEffect(() => {
  //     const id = { "event_id": eventsId };
  //     eventsService.eventDetails(id).then(
  //         (res) => {
  //             if (res.status === "success") {
  //                 console.log(res);
  //             } else {
  //                 console.log("Something went wrong !!");
  //                 //toast.error(res.errors[0], "Fashion Store");
  //             }
  //         },
  //         (error) => {
  //             console.log("Something went wrong !!");
  //         }
  //     );
  // }, [eventsId]);

  return (
    <>
      <Allheader title="Organization" />
      <Container className="organizationdiy ptop locatorWrapper" fluid>
        <Row className="pt-4">
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="cardheader2">
                    <div className="cardheader">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/icons/eyes.svg").default}
                        style={{ marginRight: "10px" }}
                      />
                      <h3 className="mb-0" style={{ fontSize: "18px" }}>
                        Your Company
                      </h3>
                    </div>
                    <div>
                      <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/tip.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                      </span>
                      <ReactTooltip />
                    </div>
                  </div>
                </CardHeader>
                <hr style={{ margin: "0px" }} />
                <CardBody>
                  <YourCompany />
                  <div className="mt-5">
                    <Childorganizationmenu />
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg="6">
            <div className="card-wrapper">
              <Card style={{ minHeight: "810px" }}>
                <CardHeader>
                  <div className="cardheader2">
                    <div className="cardheader">
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/your-storyz.svg").default
                        }
                        width="30px"
                        className="mr-2"
                      />

                      <h3 className="mb-0 headingNew">Your Storyz</h3>
                    </div>
                    <div>
                      <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/tip.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                      </span>
                      <ReactTooltip />
                    </div>
                  </div>
                </CardHeader>
                <hr style={{ margin: "0px" }} />
                <CardBody>
                  {contact.profiledetails.service_level === "DIY" ? (
                    <>
                      {" "}
                      <p>
                        To access events, select a Storyz, or use the “Events”
                        menus.
                      </p>
                    </>
                  ) : (
                    ""
                  )}

                  {dataList.map((popup, i) => (
                    <>
                      <div
                        style={{ display: "flex", marginBottom: "15px" }}
                        className="story1"
                        key={Math.random()}
                      >
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              popup.project_image
                                ? popup.project_image
                                : require("assets/img/theme/story1.svg").default
                            }
                          />
                        </div>
                        <div>
                          <p
                            className="title"
                            onClick={() => handleClick(popup.id)}
                          >
                            <Link to={`/admin/storyz/${popup.id}`}>
                              {popup.storyz_name}
                            </Link>
                          </p>
                          <p className="subtitle">Storyz 1</p>
                          <p className="subtitle1">City/State for All Events</p>
                          <p className="description">
                            {popup.greeting_description}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}

                  <FormGroup className="mt-5">
                    <Input
                      id="example2cols1Input"
                      placeholder="type to search..."
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <section className="storyzsection">
                    {eventList.length > 0 ? (
                      <>
                        {eventList.map((evdata, i) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "15px",
                              }}
                              className="story1"
                              key={i}
                              onClick={() => {
                                history.push(
                                  `/admin/event-dashboard/${evdata.project_id}/${evdata.id}`
                                );
                              }}
                            >
                              <div>
                                <img
                                  alt={evdata.event_name}
                                  src={
                                    evdata.cover_image
                                      ? evdata.cover_image
                                      : require("assets/img/theme/Event-name.svg")
                                        .default
                                  }
                                  className="eventimag"
                                />
                              </div>
                              <div>
                                {/* to={`/admin/event-dashboard/${evdata.id}`} */}
                                <Link
                                  className="title"
                                // onClick={() => setEventsid(evdata.id)}
                                >
                                  {evdata.event_name}
                                </Link>
                                <p className="subtitle">Storyz {i + 1}</p>
                                <p className="subtitle1">
                                  City/State for All Events
                                </p>
                                <p className="description">
                                  {evdata.event_description}
                                </p>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      "No Event Data"
                    )}
                  </section>

                  {contact.profiledetails.service_level === "DIY" ? (
                    <>
                      <Link
                        to={`/admin/event-about/${eventid}`}
                        className="newstoryz mb-4"
                      >
                        {eventid ? "New Event" : ""}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="#" className="newstoryzto"></Link>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrganizationDiy;
