import React from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import classnames from "classnames";
import IconImg from "../../../assets/img/adminLogo.svg";
import Vector1 from "../../../assets/img/icons/two-factor-icon.svg";
// import { useLocation } from "react-router-dom";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
// import { userService } from "../../../_services/login/user.service";
// import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function GuestCreateLogin() {
  return (
    <>
      <Container fluid className="GuestcreateLoginHeaderWrappper">
        <Link to="/">
          <i className="ni ni-bold-left arrowIcon"></i>
        </Link>
        <img src={IconImg} alt="" />
        Create Your Admin Login
      </Container>
      <div className="twofactorauth">
        <form>
          <Container className="GuestcreateLoginWrappper" fluid>
            <div className="borderLine"></div>
            <Card className="GuestcreateLoginInner">
              <div className="headingWrapper borderBottom">
                <h4 className="heading-1">
                  Provide Information for Your New Admin Account
                </h4>
              </div>
              <div className="organizationPara p-4">
                You have been invited to join an OurStoryz Project as an Admin!
              </div>
              <div>
                <div className="existingGuestLoginBox">
                  <div className="title">Storyz Name: Storyz NameXXX</div>
                  <div className="title">Your Role: Admin</div>

                  <FormGroup>
                    <Label htmlFor="exampleEmail">
                      You are already a guest for an event in this project.
                    </Label>
                    <div className="inlineFormBox">
                      <div className="avatarWraper">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          alt="Avatar"
                          className="avatar"
                        />
                      </div>
                      <Input
                        id="exampleEmail"
                        name="text"
                        placeholder="first name"
                        type="text"
                      />
                      <Input
                        id="exampleEmail"
                        name="text"
                        placeholder="last name"
                        type="text"
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>
              <div className="headingWrapper borderBottom">
                <h4 className="heading-2 px-4">
                  Admins need to create a login they will use when accessing the
                  project. Select an option for logging in below.
                </h4>
              </div>
              <div className="existingGuestLoginWrapper">
                <div className="existingGuestLoginLeft">
                  <div className="basicinformation">
                    <img src={Vector1} alt="" />
                    Set Local Password
                  </div>

                  <div className="cardBox">
                    <Row>
                      <Col lg={10} md={12}>
                        <FormGroup className="formBox">
                          <Label htmlFor="exampleEmail">Password:</Label>
                          <InputGroup className="localPassBox">
                            <Input
                              id="exampleEmail"
                              name="text"
                              placeholder="type in password..."
                              type="text"
                            />
                            <InputGroupText>
                              <img
                                src={
                                  require("assets/img/edit-field-icons.svg")
                                    .default
                                }
                                alt=""
                              />
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="formBox">
                          <Label htmlFor="exampleEmail">Retype Password.</Label>
                          <InputGroup>
                            <Input
                              id="exampleEmail"
                              name="text"
                              placeholder="type in password..."
                              type="text"
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control form-check form-check-inline formBox">
                          <label className="container3 form-check-label">
                            Remember this login
                            <input type="checkbox" name="" />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            className="greenActionBtn ml-auto"
                          >
                            Set Password
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="orBox">
                  <div className="orBoxtext">OR</div>
                </div>
                <div className="existingGuestLoginRight">
                  <div className="basicinformation">
                    <img
                      alt="password"
                      src={require("assets/img/socialIcon.svg").default}
                    />
                    Use a Social Login
                  </div>
                  <div className="cardBox">
                    <div className="guestLoginWrapper">
                      <Button className="loginWithApple">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/common/Vector.svg")
                              .default
                          }
                        />
                        Login with Apple
                      </Button>
                      <Button className="loginWithFacebook">
                        <img
                          alt="..."
                          src={require("assets/img/facebook-white.svg").default}
                        />
                        Login with Facebook
                      </Button>
                      <Button className="loginWithGoogle">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/googleplus.svg").default
                          }
                        />
                        Login with Google
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* <div className="GuestcreateLoginInner">
                <h1></h1>
              </div> */}
            {/* <Card>
                <CardHeader>
                  <h3
                    className="mb-0"
                    style={{
                      borderBottom: "1px solid #E7E7E7",
                      paddingBottom: "13px",
                    }}
                  >
                    Password Recovery
                  </h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <p className="basicinformation mt-2 ">
                        <img
                          src={Vector1}
                          style={{ marginRight: "11px" }}
                          alt=""
                        />
                        Password Recovery
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          New Password:
                        </label>
                        <InputGroup className={classnames("input-group-merge")}>
                          <input
                            placeholder="New Password"
                            type="password"
                            name="password"
                            className="form-control"
                            required
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <img
                                alt="password"
                                src={
                                  require("assets/img/icons/common/password.svg")
                                    .default
                                }
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mt-2 mb-2">
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Retype Password.
                        </label>
                        <input
                          id="example3cols1Input"
                          placeholder="Enter Retype Password."
                          type="password"
                          name="cpassword"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                      <Button className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow">
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
          </Container>
        </form>
      </div>
      <ToastContainer />
      <AuthFooter />
    </>
  );
}
export default GuestCreateLogin;
