import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";

export const paymentService = {
    listInvoices,
    billingDetails,
    cardDetails,
}

async function listInvoices(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/payment/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function billingDetails() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/payment/details`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function cardDetails(){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(false)
    }
     return fetch(Global.BASE_API_PATH+ `/payment/get_card_details`, requestOptions)
    .then(handleResponse).then(res => {
        return res;
    });
}

async function getSettings(){
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/payment/getSettings`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
