import { HONORED_GUEST } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    honoredList: false,
};
function honoredGuestListReducers(state = initialState, action) {
    switch (action.type) {
        case HONORED_GUEST:
            return {
                ...state,
                isConfirmatiom: true,
                honoredList: action.payload,
            };
        default:
            return state;
    }
}
export default honoredGuestListReducers;
