import Account from "views/pages/Account.js";
import Setting from "views/pages/Setting.js";
import storyzDashboard from "views/pages/storyzDashboard.js";
import storyzDashboardempty from "views/pages/storyzDashboardempty.js";
import Organization from "views/pages/Organization.js";
import OrganizationDiy from "views/pages/OrganizationDiy.js";
import OrganizationLocator from "views/pages/OrganizationLocator.js";
import OrganizationSelf from "views/pages/OrganizationSelf.js";
import Reports from "views/pages/Reports.js";
import Accountownerview from "views/pages/Accountownerview.js";
import Selfaccountview from "views/pages/Selfaccountview.js";
import Notifications from "views/pages/Notifications.js";
import Invite from "views/pages/Invite.js";
import Eventspopup from "views/pages/Eventspopup.js";
import eventAbout from "views/pages/eventAbout.js";
import eventAboutedit from "views/pages/eventAboutedit.js";

import Billingpayment from "views/pages/Billingpayment.js";
import Globalguest from "views/pages/Globalguest.js";
import Todos from "views/pages/Todos.js";
import Eventdashboard from "views/pages/Eventdashboard.js";
import Upgradeservice from "views/pages/Upgradeservice.js";
import Filestack from "views/pages/Filestack";
import Massageimg from "./assets/img/icons/massages.svg";
import Eventmediagallery from "views/pages/Eventmediagallery.js";
import Notificationsimg from "./assets/img/icons/notification.svg";
import Settingimg from "./assets/img/icons/Setting.svg";
import storyzAboutEdit from "views/pages/storyzAboutEdit.js";
import storyzKeepsakeAlbum from "views/pages/storyzKeepsakeAlbum.js";
import eventKeepsakeAlbum from "views/pages/eventKeepsakeAlbum.js";
import Organizationimg from "./assets/img/icons/organization.svg";
import Storyzimg from "./assets/img/icons/storyz.svg";
import Eventsimg from "./assets/img/icons/events.svg";
import Reportsimg from "./assets/img/icons/reports.svg";
import Upgradeimg from "./assets/img/icons/upgrade.svg";
import Timeline from "views/pages/timeline";
import Calendar from "views/pages/Calendar.js";
import storyzCalendar from "views/pages/storyzCalendar.js";
import Mediagallery from "views/pages/Mediagallery.js";
//import Crop from 'views/pages/Crop'
import storyzAbout from "views/pages/storyzAbout.js";
import EventUserGuestList from "views/pages/EventUserGuestList.js";
import AddUserGuestList from "components/Events/UserGuestList/addGuestList";
import EditUserGuestList from "components/Events/UserGuestList/editGuestList";
import AddGeospaceList from "components/Events/GeoSpace/addGeospaceList";
import UploadGuestList from "components/Events/UploadGuestList/uploadGuestList";

import CombinedGuestPool from "views/pages/CombinedGuestPool";
import StoryzTodos from "views/pages/StoryzTodos";
import EventTodos from "views/pages/EventTodos";
import CombinedGuestList from "views/pages/CombinedGuestList";
import StoryzHonoredGuest from "views/pages/StoryzHonoredGuest";
import EventHonoredGuest from "views/pages/EventHonoredGuest";
import EventGuestGroup from "views/pages/EventGuestGroup";

import addGuestGroup from "components/Events/GuestGroup/addGuestGroup";
import EvenrGuestGroupcomedit from "components/Events/GuestGroup/EvenrGuestGroupcomedit";

import EventInviteRSVPS from "views/pages/EventInviteRSVPS";
import MnanageInvites from "views/pages/ManageInvites";
import AddInviteRSVPsEmpty from "components/Events/InviteRSVPs/addInviteRSVPsEmpty";
import AddInviteRSVPsPast from "components/Events/InviteRSVPs/addInviteRSVPsPast";
import AddInviteRSVPsMultiple from "components/Events/InviteRSVPs/addInviteRSVPsMultiple";
import CustomerSupport from "views/pages/CustomerSupport";
import StoryzFile from "views/pages/StoryzFile";
import StatciMediaModal from "components/Events/StatciMediaModal";
import OrganizationFiles from "views/pages/OrganizationFiles";
import EventFile from "views/pages/EventFile";
import EventUploadGuestList from "views/pages/EventUploadGuestList";
import StoryzUploadGuestList from "views/pages/StoryzUploadGuestList";
import OrganizationMessage from "views/pages/OrganizationMessage";
import StoryzMessage from "views/pages/StoryzMessage";
import EventMessage from "views/pages/EventMessage";
import StoryzWebListing from "views/pages/StoryzWebListing";
import StoryzWebListingNew from "views/pages/StoryzWebListingNew";
import AccountViewOtherOwner from "views/pages/AccountViewOtherOwner";
import AccountViewOtherOwnerAdmin from "views/pages/AccountViewOtherOwnerAdmin";
import EvenGuestAuthentication from "views/pages/EvenGuestAuthentication";
import GuestKeepsakeAlbumUpload from "views/pages/GuestKeepsakeAlbumUpload";

const routes = [
  {
    path: "/massage",
    name: "Messages",
    img: Massageimg,
    component: Account,
    layout: "/admin",
    countnumber: "3",
  },
  {
    path: "/eventcalendar",
    component: Calendar,
    layout: "/admin",
  },

  {
    path: "/storyzCalendar/:sid",
    component: storyzCalendar,
    layout: "/admin",
  },

  {
    path: "/calendar",
    component: storyzCalendar,
    layout: "/admin",
  },

  {
    path: "/time-line/:sid",
    component: Calendar,
    layout: "/admin",
  },

  // {
  //   path: "/time-line/:sid",
  //   component: Timeline,
  //   layout: "/admin",
  // },
  {
    path: "/event-media-gallery/:sid/:eid",
    component: Eventmediagallery,
    layout: "/admin",
  },
  {
    path: "/static-media-modal",
    component: StatciMediaModal,
    layout: "/admin",
  },

  {
    path: "/event-media-gallery",
    component: Eventmediagallery,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    img: Notificationsimg,
    component: Notifications,
    layout: "/admin",
    countnumber: "3",
  },
  {
    path: "/setting",
    name: "Setting",
    img: Settingimg,
    component: Setting,
    layout: "/admin",
    countnumber: "3",
  },
  {
    collapse: true,
    name: "Organization",
    img: Organizationimg,
    state: "dashboardsCollapse",
    views: [
      {
        path: "/organization",
        name: "Organization",
        miniName: "O",
        component: Organization,
        layout: "/admin",
      },

      {
        path: "/organization-diy",
        name: "Organization DIY",
        miniName: "D",
        component: OrganizationDiy,
        layout: "/admin",
      },
      {
        path: "/organization-locator",
        name: "Organization Locator",
        miniName: "L",
        component: OrganizationLocator,
        layout: "/admin",
      },
      {
        path: "/organization-self-account",
        name: "Organization Self Account",
        miniName: "L",
        component: OrganizationSelf,
        layout: "/admin",
      },
      {
        path: "/invite",
        name: "Invite",
        miniName: "I",
        component: Invite,
        layout: "/admin",
      },
      {
        path: "/global-guest-pool",
        name: "Global Guest Pool",
        miniName: "I",
        component: Globalguest,
        layout: "/admin",
      },
      {
        path: "/storyz-todos/:sid",
        name: "Storyz Todos",
        miniName: "I",
        component: StoryzTodos,
        layout: "/admin",
      },
      {
        path: "/events-todos/:sid/:eid",
        name: "Event Todos",
        miniName: "ET",
        component: EventTodos,
        layout: "/admin",
      },
      {
        path: "/todos",
        name: "Todos",
        miniName: "I",
        component: Todos,
        layout: "/admin",
      },
      {
        path: "/event-dashboard/:sid/:eid",
        name: "Event Dashboard",
        miniName: "ED",
        component: Eventdashboard,
        layout: "/admin",
      },
      {
        path: "/event-invite-rsvps/:sid/:eid",
        name: "",
        miniName: "",
        component: EventInviteRSVPS,
        layout: "/admin",
      },
      {
        path: "/manage-invites/:sid/:eid",
        name: "",
        miniName: "",
        component: MnanageInvites,
        layout: "/admin",
      },
      {
        path: "/manage-invites-edit/:sid/:eid/:id",
        name: "",
        miniName: "",
        component: AddInviteRSVPsEmpty,
        layout: "/admin",
      },
      {
        path: "/event-dashboard",
        name: "Event Dashboard",
        miniName: "ED",
        component: Eventdashboard,
        layout: "/admin",
      },
      {
        path: "/event-user-guest-list/:sid/:eid",
        name: "",
        miniName: "",
        component: EventUserGuestList,
        layout: "/admin",
      },

      {
        path: "/add-user-guest-list/:sid/:eid",
        name: "",
        miniName: "",
        component: AddUserGuestList,
        layout: "/admin",
      },
      {
        path: "/edit-user-guest-list/:sid/:eid/:id",
        name: "",
        miniName: "",
        component: EditUserGuestList,
        layout: "/admin",
      },
      {
        path: "/event-guest-authentication/:sid/:eid/:id",
        name: "",
        miniName: "",
        component: EvenGuestAuthentication,
        layout: "/admin",
      },
      {
        path: "/add-geospace-list/:sid/:eid",
        name: "",
        miniName: "",
        component: AddGeospaceList,
        layout: "/admin",
      },
      {
        path: "/upload-guest-list/:sid/:eid",
        name: "",
        miniName: "",
        component: UploadGuestList,
        layout: "/admin",
      },
      {
        path: "/combined-guest-pool-list",
        name: "",
        miniName: "",
        component: CombinedGuestPool,
        layout: "/admin",
      },
      {
        path: "/combined-guest-list/:sid",
        name: "",
        miniName: "",
        component: CombinedGuestList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/storyz-honored-guest-list/:sid",
    name: "",
    miniName: "",
    component: StoryzHonoredGuest,
    layout: "/admin",
  },
  {
    path: "/events-honored-guest-list",
    name: "",
    miniName: "",
    component: EventHonoredGuest,
    layout: "/admin",
  },
  {
    path: "/event-guest-group-list",
    name: "",
    miniName: "",
    component: EventGuestGroup,
    layout: "/admin",
  },

  {
    path: "/add-guest-group/:sid/:eid",
    name: "",
    miniName: "",
    component: addGuestGroup,
    layout: "/admin",
  },
  {
    path: "/edir-guest-group/:sid/:eid/:id",
    name: "",
    miniName: "",
    component: EvenrGuestGroupcomedit,
    layout: "/admin",
  },

  {
    path: "/customer-support",
    name: "",
    miniName: "",
    component: CustomerSupport,
    layout: "/admin",
  },
  {
    path: "/storyz-file/:sid",
    name: "",
    miniName: "",
    component: StoryzFile,
    layout: "/admin",
  },
  {
    path: "/event-file/:sid/:eid",
    name: "",
    miniName: "",
    component: EventFile,
    layout: "/admin",
  },
  {
    path: "/organization-file",
    name: "",
    miniName: "",
    component: OrganizationFiles,
    layout: "/admin",
  },
  {
    path: "/organization-message",
    name: "",
    miniName: "",
    component: OrganizationMessage,
    layout: "/admin",
  },
  {
    path: "/storyz-message/:sid",
    name: "",
    miniName: "",
    component: StoryzMessage,
    layout: "/admin",
  },

  {
    path: "/storyz-web-listing/:sid",
    name: "",
    miniName: "",
    component: StoryzWebListing,
    layout: "/admin",
  },
  {
    path: "/storyz-web-listings/:sid",
    name: "",
    miniName: "",
    component: StoryzWebListingNew,
    layout: "/admin",
  },
  {
    path: "/event-message",
    name: "",
    miniName: "",
    component: EventMessage,
    layout: "/admin",
  },
  {
    path: "/event-upload-guest-list/:sid/:eid",
    name: "",
    miniName: "",
    component: EventUploadGuestList,
    layout: "/admin",
  },

  {
    path: "/storyz-upload-guest-list/:sid",
    name: "",
    miniName: "",
    component: StoryzUploadGuestList,
    layout: "/admin",
  },

  {
    path: "/add-invite-rsvp/:sid/:eid",
    name: "",
    miniName: "",
    component: AddInviteRSVPsEmpty,
    layout: "/admin",
  },
  {
    path: "/add-invite-rsvp-past/:sid/:eid",
    name: "",
    miniName: "",
    component: AddInviteRSVPsPast,
    layout: "/admin",
  },
  {
    path: "/add-invite-rsvp-multiple/:sid/:eid",
    name: "",
    miniName: "",
    component: AddInviteRSVPsMultiple,
    layout: "/admin",
  },
  {
    path: "/storyz/:sid",
    component: storyzDashboard,
    layout: "/admin",
  },
  {
    path: "/media-gallery/:sid",
    component: Mediagallery,
    layout: "/admin",
  },
  // {
  //   path: "/crop/:id",
  //   component: Crop,
  //   layout: "/admin",
  // },

  {
    path: "/storyz",
    name: "Storyz",
    img: Storyzimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: storyzDashboard,
    layout: "/admin",
  },
  {
    path: "/storyz-about/:sid",
    name: "",
    img: "",
    icon: "",
    component: storyzAboutEdit,
    layout: "/admin",
  },
  {
    path: "/storyz-keepsake-album/:sid",
    name: "",
    img: "",
    icon: "",
    component: storyzKeepsakeAlbum,
    layout: "/admin",
  },

  {
    path: "/storyz-about",
    name: "",
    img: "",
    icon: "",
    component: storyzAbout,
    layout: "/admin",
  },
  {
    path: "/storyz-empty/:sid",
    name: "",
    img: "",
    icon: "",
    component: storyzDashboardempty,
    layout: "/admin",
  },

  {
    path: "/storyz-empty",
    name: "",
    img: "",
    icon: "",
    component: storyzDashboardempty,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    img: Eventsimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Eventspopup,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    img: Reportsimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/event-about/:sid/:eid",
    name: "",
    img: "",
    icon: "",
    component: eventAboutedit,
    layout: "/admin",
  },
  {
    path: "/event-keepsake-album/:sid/:eid",
    name: "",
    img: "",
    icon: "",
    component: eventKeepsakeAlbum,
    layout: "/admin",
  },
  {
    path: "/guest-keepsake-album/:sid/:eid/:id",
    name: "",
    img: "",
    icon: "",
    component: GuestKeepsakeAlbumUpload,
    layout: "/admin",
  },
  {
    path: "/event-about/:id",
    name: "",
    img: "",
    icon: "",
    component: eventAbout,
    layout: "/admin",
  },

  {
    path: "/event-about",
    name: "",
    img: "",
    icon: "",
    component: eventAbout,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Account",
    img: Massageimg,
    state: "AccountsCollapse",
    views: [
      {
        path: "/account-information/:id",
        name: "Account Information",
        miniName: "AI",
        component: Accountownerview,
        layout: "/admin",
      },
      {
        path: "/account-owner-view",
        name: "Self or Account Owner View",
        miniName: "S",
        component: Account,
        layout: "/admin",
      },
      {
        path: "/self-account-view",
        name: "Self Account View",
        miniName: "sa",
        component: Selfaccountview,
        layout: "/admin",
      },

      {
        path: "/account-view-other-owner",
        name: "Account view of other admin",
        miniName: "S",
        component: AccountViewOtherOwner,
        layout: "/admin",
      },
      {
        path: "/admin-account-view-other-owner/:id",
        name: "Account view of other admin",
        miniName: "S",
        component: AccountViewOtherOwnerAdmin,
        layout: "/admin",
      },

      {
        path: "/billing-account-view",
        name: "Billing and Payment",
        miniName: "B",
        component: Billingpayment,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/upgrade-service",
    name: "Upgrade",
    img: Upgradeimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Upgradeservice,
    layout: "/admin",
  },

  {
    path: "/filestack",
    name: "Filestack",
    img: Upgradeimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Filestack,
    layout: "/admin",
  },
];

export default routes;
