import React, { useState, useEffect, useRef } from "react";
import Allheader from "../../components/Headers/Allheader";
import {
  Row,
  Col,
  Modal,
  Button,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import Imggroup from "../../assets/img/dummyImg.svg";
import ReactTooltip from "react-tooltip";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { ReactSession } from "react-client-session";
import { ToastContainer, toast } from "react-toastify";
import { informationService } from "../../_services/masters/information.service";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import DeleteEverythingInfoIcon from "./../../../src/assets/img/resetgeospaceInfoIcon.svg";
import "../../components/FileStackApp/index.css";
import FileStackApp from "../../components/FileStackApp/FileStackApp";
import { TailSpin } from "react-loader-spinner";

function Mediagallery({ ...args }) {
  const inputRef = useRef();
  const [imgData, setImgData] = React.useState(null);
  const [imgEditData, setEditImgData] = React.useState(null);
  const [imgDataUrl, setImgUrl] = React.useState("");
  const [msg, setMsg] = React.useState();
  const [imgModalBox, setImgModalBox] = React.useState(false);
  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [importFromLocal, setImportFromLocal] = useState(false);
  const [imgEditDataUrl, setEditImgUrl] = React.useState("");
  const [imgEditDataUrlPOP, setEditImgUrlPOP] = React.useState(false);
  const params = useParams();
  const [projectid, setProjectId] = React.useState(params.id);
  const [img_id, imgId] = React.useState(0);
  const [delImgUrl, setDelImgUrl] = React.useState("");
  const [delImgName, setDelImgName] = React.useState("");
  const [delImgMimeType, setDelImgMimeType] = React.useState("");

  const [img_eid, imgEditId] = React.useState(0);
  const [mediaGallertList, setMediagallert] = React.useState([]);
  const [defaultModale, setdefaultModale] = React.useState(false);
  const [photo_label, setPhotolabel] = React.useState("");
  const [photo_caption, setPhotocaption] = React.useState("");
  const [photo_Editlabel, setEditPhotolabel] = React.useState("");
  const [photo_Editcaption, setEditPhotocaption] = React.useState("");
  const [gallerylocation, setGallerylocation] = useState("");
  const [imgEditModalBox, setImgEditModalBox] = useState(false);
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [editImageName, setEditImageName] = useState("");
  const [imageName, setImageName] = useState("");
  const [video, setVideo] = useState("");
  const [editVideo, setEditVideo] = useState("");
  const [fullscreenImg, setFullscreenImg] = useState(false);
  const [mediaGallery, setMediaGallery] = useState([]);
  const [filestack, setFilestack] = useState([]);
  const [editHiddenId, setEditHiddenId] = useState("");
  const [photoId, setPhotoId] = useState(0);
  const [eventCount, setEventCount] = useState(0);
  const [storyCount, setStoryCount] = useState(0);
  const [importType, setType] = useState("Add");
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );

  const [storyData, setstoryData] = React.useState({});
  const [paramsId, setParamsId] = React.useState(params.sid ? params.sid : "");

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {

      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // setFormValues({ ...formValues, ['img_address']: place.formatted_address });
    }
  };

  function storyzDetail() {
    const data = { story_id: params.sid };
    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setstoryData(res.data[0]);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    storyzDetail();
  }, [params.sid]);

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const todayDate = new Date();
  const current_date = moment(todayDate).format("MMMM D, YYYY: h:mm:sa"); // December 13th 2018, 5:25:14 pm
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setMsg("");
      setImgUrl(urldata);
      setImgData(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setVideo(videourl1);
    }
  };

  const onEditChangePicture = (e) => {
    setEditImgUrlPOP(null);
    setEditVideo(null);
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const videoUrl = e.target.files[0].type.split("/");
      const videourl1 = videoUrl[0];
      setEditImgData(e.target.files[0]);
      setEditImageName(e.target.files[0].name);
      setTimeout(() => {
        setEditImgUrlPOP(urldata);
        setEditVideo(videourl1);
      }, "10");
    }
  };

  useEffect(() => {
    getmgList(params.sid);
  }, [params.sid]);

  //   list of data showing oin page
  const getmgList = (projectid) => {
    setMediagallert([]);
    setPhotolabel("");
    setPhotocaption("");
    setImgData(null);
    setImgUrl("");
    informationService.getMediaGallertList(projectid).then(
      (res) => {
        if (res.status === "success") {
          setMediagallert(res.data);
          //console.log("res data", res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  //   create media gallery here

  const saveGalleryOld = () => {
    var form = new FormData();
    form.append("photo_name", imgData); // image url
    form.append("mime_type", imgData.type); // image type image/video
    form.append("photo_label", photo_label); // title
    form.append("photo_caption", photo_caption); // image caption
    form.append("project_id", projectid); // story Id
    form.append("event_id", 1); // event category
    form.append("is_default", 1); // Is Deleted
    form.append("status", 1);
    form.append("description", "hhhhh"); // Description
    form.append("gallery_location", gallerylocation); // Location Map

    informationService.saveGallery(form).then(
      (res) => {
        if (res.status === "success") {
          setImgUrl("");
          getmgList(params.sid);
          toast.success("Saved successfully");
        } else {
          //console.log("Something went wrong !!");
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        //console.log("Something went wrong !!");
        toast.error("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
  };

  const saveGallery = () => {
    let saveData = {
      photo_name: imgDataUrl,
      mime_type: video,
      photo_label: photo_label,
      photo_caption: photo_caption,
      project_id: params.sid,
      event_id: 0,
      description: "",
      location: gallerylocation,
      photo_id: photoId,
    };
    // console.log('saveData storyz', saveData);
    informationService.saveGallery(saveData).then(
      (res) => {
        //alert('success : res.status' + res.status)
        if (res.status == "success") {
          setImgUrl("");
          getmgList(params.sid);
          toast.success("Added successfully");
        } else if (res.message == "This photo already exists in this storyz") {
          //console.log("Something went wrong !!");
          toast.error("This photo already exists in this storyz");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        //console.log("Something went wrong !!");
        toast.error("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    setImgModalBox(false);
    setBrowseImgModal(false);
    setImportFromLocal(false);
  };

  const updateGalleryOne = () => {
    var form = new FormData();
    if (imgEditData !== null) {
      form.append("photo_name", imgEditData); // image url
      form.append("mime_type", imgEditData.type); // image type image/video
    }
    form.append("photo_label", photo_Editlabel); // title
    form.append("photo_caption", photo_Editcaption); // image caption
    form.append("project_id", projectid); // story Id
    form.append("event_id", 0); // event category
    form.append("is_default", 1); // Is Deleted
    form.append("status", 1);
    form.append("description", "hhhhh"); // Description
    form.append("id", img_eid);
    form.append("gallery_location", gallerylocation); // Location Map

    informationService.updateGallery(form).then(
      (res) => {
        if (res.status == "success") {
          setImgUrl("");
          setImgEditModalBox(false);
          //imgEditId(0);
          setTimeout(() => {
            getmgList(params.sid);
          });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const updateGallery = () => {
    let updateData = {
      photo_name: imgEditDataUrl,
      mime_type: video,
      photo_label: photo_Editlabel,
      photo_caption: photo_Editcaption,
      project_id: parseInt(params.sid),
      //event_id:parseInt(eventid),
      description: "",
      location: galleryEditlocation,
      id: parseInt(editHiddenId),
    };
    informationService.updateGallery(updateData).then(
      (res) => {
        if (res.status == "success") {
          setImgUrl("");
          setImgEditModalBox(false);
          //imgEditId(0);
          setTimeout(() => {
            getmgList(params.sid);
          });
          toast.success("Updated successfully");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const openDeleteBox = (id, imgUrl, imgName, mime_type) => {
    // alert("openDeleteBox");
    let data = { photo_id: id };
    informationService.countEventStory(data).then(
      (res) => {
        if (res.status === "success") {
          //console.log('res.data.event_id', res.data.event_id)
          setEventCount(res.data.event_id);
          setStoryCount(res.data.story_id);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );

    imgId(id);
    setDelImgUrl(imgUrl);
    setDelImgName(imgName);
    setDelImgMimeType(mime_type);
    setdefaultModale(true);
    setImgEditModalBox(false);
  };

  const deleteImg = (img_id) => {

    informationService.deleteStoryzImg(img_id).then(
      (res) => {
        if (res.status === "success") {
          setdefaultModale(false);
          getmgList(params.sid);

          toast.success("Deleted  successfully");
        } else {
          //sconsole.log("Something went wrong !!");
          toast.error("Something went wrong !!");
        }
      },
      (error) => {
        //console.log("Something went wrong !!");
        toast.error("Something went wrong !!");
      }
    );
  };

  const openEditMode = (eid) => {
    setType("Edit");
    setEditImgUrlPOP(false);
    const result = mediaGallertList.filter(
      (mediaGallertList) => mediaGallertList.id == eid
    );

    setEditVideo(result[0].mime_type);
    //setVideo(result[0].mime_type);
    const urlName = result[0].photo_url.split("/");
    const urlName1 = urlName[urlName.length - 1];
    setEditImgUrl(result[0].photo_url);
    setEditPhotolabel(result[0].alt_text);
    setEditPhotocaption(result[0].caption);
    setGalleryEditlocation(result[0].location);
    setEditImgData(result[0].mime_type);
    setEditImageName(urlName1);
    imgEditId(eid);
    setImgEditModalBox(eid);
    setEditHiddenId(result[0].id);
  };

  const AddBrowImgModal = () => {
    setType("Add");
    setPhotoId(0);
    setBrowseImgModal(true);
    setImgEditModalBox(false);
    getMediaGallery();
  };

  const EditBrowImgModal = () => {
    setType("Edit");
    //setPhotoId(0)
    setBrowseImgModal(true);
    setImgEditModalBox(false);
    getMediaGallery();
  };

  const BrowseImgModal = () => {
    setBrowseImgModal(false);
  };

  const ImportFromLocal = () => {
    setImportFromLocal(true);
    setBrowseImgModal(false);
  };
  const ImportFromLocalClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(true);
  };

  const MediaUserDetails = JSON.parse(localStorage.getItem("userdata"));
  const Userfname = MediaUserDetails.first_name;
  const Userlname = MediaUserDetails.last_name;
  const UserPic = MediaUserDetails.imageUrl;

  const imgFullScreen = () => {

    setFullscreenImg(true);
  };
  const imgFullScreenClose = () => {

    setFullscreenImg(false);
  };

  //   list of data showing oin page
  const getMediaGallery = () => {
    informationService.getMediaGallery().then(
      (res) => {
        if (res.status === "success") {
          setMediaGallery(res.data);
          //console.log('mediaGallery', res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const gotoEdit = (eid) => {
    setImgModalBox(true);
    const result = mediaGallery.filter(
      (mediaGallery) => mediaGallery.id == eid
    );
    //console.log('mediaGallery', mediaGallery);
    setImgUrl(result[0].photo_url);
    setPhotolabel(result[0].alt_text);
    setPhotocaption(result[0].caption);
    setVideo(result[0].mime_type);
    setGallerylocation(result[0].location);
    setBrowseImgModal(false);
    setPhotoId(eid);
  };

  const setdata = (data) => {
    setPhotolabel("");
    setPhotocaption("");
    if (data.length > 0) {
      let mime_typeArr = data[0].mimetype.split("/");
      let mime_type = mime_typeArr[0];
      //alert(mime_type);
      setVideo(mime_type);
      setEditVideo(mime_type);
      setImgUrl(data[0].url);
      setEditImgUrl(data[0].url);
      setImageName(data[0].filename);
      // console.log('filestack imgDataUrl', imgDataUrl);
    }
  };

  return (
    <>
      {storyData ? (
        <>
          <Modal
            isOpen={defaultModale}
            toggle={() => setdefaultModale(false)}
            {...args}
            className="deleteEverythingModal"
          >
            <ModalBody className="pb-0">
              <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
                <img
                  src={DeleteEverythingInfoIcon}
                  alt=""
                  className="deleteEverythingInfoIcon"
                />
                <div className="head">Trash Media File?</div>
              </div>

              <Row className="mb-2 ">
                <Col>
                  {delImgMimeType == "video" ? (
                    <>
                      <video
                        className="deleteModalImg mb-3"
                        alt={delImgUrl}
                        controls
                      >
                        <source src={delImgUrl} type="" />
                      </video>
                    </>
                  ) : (
                    <>
                      <img
                        src={delImgUrl}
                        className="deleteModalImg mb-3"
                        alt="img"
                      />
                    </>
                  )}
                  <p className="fontWeight700">File Name: {delImgName}</p>
                  <p className="fontWeight700">
                    Used by: {storyCount} Storyz, {eventCount} Events
                  </p>
                  <p></p>
                  <p></p>
                  <p>This will delete the file from your archive entirely.</p>
                  <p>
                    If you used this file in a Storyz, event, or Keepsake Album,
                    you will need to replace it with another file. You will
                    receive a notification of the affected files in your
                    Notifications area.
                  </p>
                  <p>
                    If you want to replace this file with different media,
                    select “replace”.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="pt-0">
              <Button
                color="primary"
                className="flex-grow-1 pinkBtn w-100 boxShadowNone"
                onClick={() => deleteImg(img_id)}
              >
                Trash
              </Button>

              <Button
                color="primary"
                className="flex-grow-1 blueBtn w-100 border-radius10 boxShadowNone "
              >
                Import Replacement
              </Button>

              <Button
                className="btn-default cancelBtn w-100 boxShadowNone"
                onClick={() => setdefaultModale(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {/* <Allheader title="Storyz Name..." arw={false} /> */}
          <Allheader
          id={storyData?.proId}
            title={
              storyData?.storyz_name
                ? storyData?.storyz_name
                : "New Storyz edit"
            }
            imgScr={
              storyData?.profile_photo_Url
                ? storyData?.profile_photo_Url
                : Imggroup
            }
            arw
          />
          <div className="organizerWrapperMain bgPannel">
            {/* <div className="col px-0"> */}
            <div className="card organizerCardWrapper">
              <div>
                <div className="headingWrapper borderBottom">
                  <h4 className="heading-1">Image Gallery for this Storyz</h4>
                  <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                      style={{ marginRight: "10px" }}
                    />
                  </span>
                  <ReactTooltip />
                </div>
                <small
                  className="page-title"
                  style={{
                    borderTop: "1px solid #e7e7e7",
                    borderBottom: "none",
                  }}
                >
                  This page shows images your team has specifically associated
                  with Storyz NameXXXXXXX These images are selected by Admins.
                  Any image in your organization’s image pool (including
                  uploaded guest images) can be added to a specific Storyz.
                </small>
              </div>
              <Col lg="12" md="12" className="p-4">
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div className="pointer text-center">
                    <FileStackApp
                      setImgModalBox={setImgModalBox}
                      setFilestack={setFilestack}
                      setdata={setdata}
                      type="Add"
                    >
                      <div className="pt-1">Import Media</div>
                    </FileStackApp>
                  </div>
                </div>
              </Col>
              <Row>
                <Col lg={4} md={3} className="mt-5">
                  <div className="lablepoint disabledNew">
                    <div className="title"> Image Label: </div>
                    <div className="buticon">
                      {/* <button
                        className="btn iconBtn fa fa-edit"
                        title="Edit"
                      ></button> */}
                      <img
                        alt="Organization Name"
                        src={require("assets/img/pencil-icon.svg").default}
                        className="iconBtn pointer"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/trash-icon.svg").default}
                        className="iconBtn pointer"
                      // onClick={() => setImgUrl("")}
                      />
                      {/* <button
                        onClick={() => setImgUrl("")}
                        className="btn iconBtn fa fa-trash"
                        title="Delete"
                      ></button> */}
                    </div>
                  </div>

                  <div className="imageuplode mt-3">
                    <div className="imgMask" onClick={AddBrowImgModal}>
                      <div className="imgMask-content">+</div>
                    </div>
                  </div>

                  {browseImgModal && (
                    <div
                      className={`modal fade mediaFileListModal ${browseImgModal ? "show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title">Storyz Files</h1>
                            <button
                              type="button"
                              className="btn-close p-0"
                              onClick={BrowseImgModal}
                            >
                              <img
                                alt="Import New"
                                src={
                                  require("assets/img/deleteIcon.svg").default
                                }
                                className="closeBtn"
                              />
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="importImageListWrapper">
                              {importType == "Add" ? (
                                <FileStackApp
                                  setImgModalBox={setImgModalBox}
                                  setFilestack={setFilestack}
                                  setdata={setdata}
                                  type="Add"
                                >
                                  Import New
                                </FileStackApp>
                              ) : (
                                <FileStackApp
                                  setImgEditModalBox={setImgEditModalBox}
                                  setBrowseImgModal={setBrowseImgModal}
                                  setFilestack={setFilestack}
                                  setdata={setdata}
                                  type="Edit"
                                >
                                  Import Edit
                                </FileStackApp>
                              )}
                              <div className="importedImageListWrapper">
                                <div className="importedImageListInner">
                                  {mediaGallery.map((x, i) => (
                                    <>
                                      {mediaGallery[i].mime_type == "video" ? (
                                        <>
                                          <div
                                            className="importedImageList"
                                            key={i}
                                            onClick={() =>
                                              gotoEdit(mediaGallery[i].id)
                                            }
                                          >
                                            <div className="uploadedItem">
                                              <div className="uploadedImg">
                                                <video
                                                  className="uploadImageFromLocalImg"
                                                  alt={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  controls
                                                // autoPlay
                                                >
                                                  <source
                                                    src={
                                                      mediaGallery[i].photo_url
                                                    }
                                                    type=""
                                                  />
                                                </video>
                                                <img
                                                  src={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  alt=""
                                                  className="thum"
                                                />
                                              </div>
                                            </div>
                                            <div className="filename">
                                              {mediaGallery[i].alt_text}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="importedImageList"
                                            key={i}
                                            onClick={() =>
                                              gotoEdit(mediaGallery[i].id)
                                            }
                                          >
                                            <div className="uploadedItem">
                                              <div className="uploadedImg">
                                                <img
                                                  src={
                                                    mediaGallery[i].photo_url
                                                  }
                                                  alt=""
                                                  className="thum"
                                                />
                                              </div>
                                            </div>
                                            <div className="filename">
                                              {mediaGallery[i].alt_text}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn selectFilebtn"
                              onClick={() =>
                                imgDataUrl !== ""
                                  ? setImgModalBox(true)
                                  : setMsg("Please choose video or photo")
                              }
                            >
                              Select File
                            </button>
                            <button
                              type="button"
                              className="btn selectFilebtn whiteBg"
                              onClick={() => BrowseImgModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {importFromLocal && (
                    <div
                      className={`modal fade mediaFileListModal importFromLocalModal ${importFromLocal ? "show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content importImageFromLocalContainer">
                          <div className="importFromLocalLeftWrapper">
                            <div className="importFromLocalLefList active">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/local-device.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Local Device
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/google-drive.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Google Drive
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/instagram.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Instagram
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/google-photos.svg")
                                      .default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Google Photos
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={require("assets/img/icloud.svg").default}
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              iCloud
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/one-drive.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              OneDrive
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/dropbox.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Dropbox
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={require("assets/img/box.svg").default}
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Box
                            </div>
                            <div className="importFromLocalLefList">
                              <div className="importFromLocalIconBox">
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/facebook.svg").default
                                  }
                                  className="importFromLocalIcon"
                                />
                              </div>{" "}
                              Facebook
                            </div>
                          </div>
                          <div>
                            <div className="modal-header">
                              <h1 className="modal-title">
                                Import From Local Device
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={ImportFromLocalClose}
                              >
                                <img
                                  alt=""
                                  src={
                                    require("assets/img/deleteIcon.svg").default
                                  }
                                  className="closeBtn"
                                />
                                {/* <svg
                                className="closeBtn"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                              </svg> */}
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="importFromLocalContent">
                                <div className="importFromLocalContentLeft">
                                  <i className="fa fa-image faImage"></i>
                                </div>
                                Drag file into grey area below, or tap to upload
                              </div>
                              <p style={{ color: "#FF0000" }}>{msg}</p>
                              <div className="uploadImageFromLocalWrapper">
                                <div className="uploadImageFromLocalContent">
                                  <input
                                    type="file"
                                    name="files"
                                    className="fileInput"
                                    accept="image/png, image/jpeg, video/*"
                                    onChange={onChangePicture}
                                  />
                                  <i className="fa fa-plus faPlus"></i>
                                </div>
                                {video === "video" ? (
                                  <>
                                    <video
                                      className="uploadImageFromLocalImg"
                                      alt={imgDataUrl}
                                      controls
                                    >
                                      <source
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        type=""
                                      />
                                    </video>
                                  </>
                                ) : (
                                  <>
                                    {imgDataUrl ? (
                                      <img
                                        src={imgDataUrl ? imgDataUrl : ""}
                                        alt={imgDataUrl}
                                        className="uploadImageFromLocalImg"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn selectFilebtn"
                                onClick={() =>
                                  imgDataUrl !== ""
                                    ? setImgModalBox(true)
                                    : setMsg("Please choose video or photo")
                                }
                              >
                                Select File
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {imgModalBox && (
                    <>
                      <div
                        className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""
                          }`}
                      >
                        <div
                          className="closeIcon"
                          onClick={() => setImgModalBox(false)}
                        >
                          <svg
                            className="closeBtn"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                          </svg>
                        </div>
                        <div className="mediaModalHeaderWrapper">
                          <span className="mediaLogo">
                            <i className="fa fa-image mediaLogoIcon"></i>
                          </span>{" "}
                          Add Image Information
                        </div>

                        <div className="mediaImageWrapper">
                          <div className="date">{current_date}</div>
                          <div className="imageCropperWrapper">
                            <div className="uploadImageFromLocalContent">
                              <FileStackApp
                                setImgModalBox={setImgModalBox}
                                setFilestack={setFilestack}
                                setdata={setdata}
                                type="plus"
                              ></FileStackApp>
                            </div>

                            {video === "video" ? (
                              <>
                                <video
                                  className="imageCropper"
                                  alt={imgDataUrl}
                                  controls
                                >
                                  <source
                                    src={imgDataUrl ? imgDataUrl : ""}
                                    type=""
                                  />
                                </video>
                              </>
                            ) : (
                              <>
                                {imgDataUrl ? (
                                  <img
                                    src={imgDataUrl ? imgDataUrl : ""}
                                    alt={imgDataUrl}
                                    className="imageCropper"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>

                          {/* <div className="imageEditIcons">
                            <button className="button">
                              <i className="fa fa-expand"></i>
                            </button>
                            <button className="button">
                              <i className="fa fa-crop"></i>
                            </button>
                            <button
                              className="button"
                              onClick={() => setImgUrl(false)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div> */}
                        </div>

                        <div className="mediaUserDetails">
                          <div className="mediaUserImgWrapper">
                            <img
                              src={UserPic}
                              className="mediaUserImg"
                              alt={UserPic}
                            />
                          </div>
                          <div className="mediaUserContentWrapper">
                            <div className="mediaUserTitle">
                              {Userfname} {Userlname}
                            </div>
                            <div className="mediaUserDesignation">
                              Role in Event
                            </div>
                          </div>
                        </div>

                        <div className="filenameWrapper">
                          {/* File name: <span>File NameXXX </span> */}
                          File name: <span>{imgDataUrl ? imageName : ""}</span>
                        </div>
                        <div className="mediaFormWrapper">
                          <div className="formBox">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="photo_label"
                              name="photo_label"
                              placeholder="Image Label"
                              value={photo_label}
                              onChange={(e) => setPhotolabel(e.target.value)}
                            />
                          </div>
                          <div className="formBox">
                            <label className="form-label">Caption:</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="photo_caption"
                              name="photo_caption"
                              placeholder="photo caption"
                              value={photo_caption}
                              onChange={(e) => setPhotocaption(e.target.value)}
                            ></textarea>
                          </div>
                          <div className="formBox">
                            <label className="form-label">Location:</label>
                            <div className="locationMap locationgooglemap12">
                              <LoadScript
                                googleMapsApiKey={mapkey}
                                libraries={["places"]}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gallerylocation"
                                    placeholder="type in a name, or use the map..."
                                  // value={gallerylocation}
                                  // onChange={(e) => setGallerylocation(e.target.value)}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>

                              <div
                                className="map pointer"
                              //  onClick={handelMapModal}
                              >
                                <svg
                                  className="diamondTrunImg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                </svg>
                                Map
                              </div>
                            </div>
                          </div>
                          <div className="actionWrapper">
                            <button
                              type="submit"
                              className="btn confirmBtn"
                              onClick={() => saveGallery()}
                            >
                              Save Image Meta-Data
                            </button>
                            <button
                              type="submit"
                              className="btn CancelBtn"
                              onClick={() => setImgModalBox(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mediaOverlay"></div>
                    </>
                  )}

                  {imgEditModalBox && (
                    <>
                      <div
                        className={`mediaModalWrapper ${imgEditModalBox ? "ShowModal" : ""
                          }`}
                      >
                        <div
                          className="closeIcon"
                          onClick={() => setImgEditModalBox(false)}
                        >
                          <svg
                            className="closeBtn"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                          </svg>
                        </div>
                        <div className="mediaModalHeaderWrapper">
                          <span className="mediaLogo">
                            <i className="fa fa-image mediaLogoIcon"></i>
                          </span>
                          Edit Image Information
                        </div>
                        <div className="mediaImageWrapper">
                          <div className="date">{current_date}</div>
                          <div className="imageCropperWrapper">
                            {/* <div className="uploadImageFromLocalContent">
                              <div
                                className="imgMask"
                                onClick={EditBrowImgModal}
                              >
                                <div className="imgMask-content">+</div>
                              </div>
                            </div> */}

                            {editVideo === "video" ? (
                              <>
                                {imgEditDataUrlPOP == true ? (
                                  ""
                                ) : (
                                  <video
                                    className="imageCropper"
                                    alt={imgEditDataUrlPOP}
                                    controls
                                  >
                                    <source
                                      src={
                                        imgEditDataUrlPOP !== false
                                          ? imgEditDataUrlPOP
                                          : imgEditDataUrl
                                      }
                                      type=""
                                    />
                                  </video>
                                )}
                              </>
                            ) : (
                              <>
                                {imgEditDataUrlPOP == true ? (
                                  ""
                                ) : (
                                  <img
                                    src={
                                      imgEditDataUrlPOP !== false
                                        ? imgEditDataUrlPOP
                                        : imgEditDataUrl
                                    }
                                    alt={imgEditDataUrlPOP}
                                    className="imageCropper"
                                  />
                                )}
                              </>
                            )}
                          </div>
                          {/* <div className="imageEditIcons">
                            <button className="button" onClick={imgFullScreen}>
                              <i className="fa fa-expand"></i>
                            </button>
                            <button className="button">
                              <i className="fa fa-crop"></i>
                            </button>
                            <button
                              className="button"
                              onClick={() => setEditImgUrlPOP(true)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div> */}
                        </div>

                        <div className="mediaUserDetails">
                          <div className="mediaUserImgWrapper">
                            <img
                              src={UserPic}
                              className="mediaUserImg"
                              alt={UserPic}
                            />
                          </div>
                          <div className="mediaUserContentWrapper">
                            <div className="mediaUserTitle">
                              {Userfname} {Userlname}
                            </div>
                            <div className="mediaUserDesignation">
                              Role in Event
                            </div>
                          </div>
                        </div>

                        <div className="filenameWrapper">
                          File name:{" "}
                          <span>{imgEditDataUrl ? editImageName : ""}</span>
                        </div>
                        <div className="mediaFormWrapper">
                          <div className="formBox">
                            <label className="form-label">Title</label>

                            <input
                              type="text"
                              className="form-control"
                              id="photo_Editlabel"
                              name="photo_Editlabel"
                              placeholder="Image Label"
                              value={photo_Editlabel}
                              onChange={(e) =>
                                setEditPhotolabel(e.target.value)
                              }
                            />
                          </div>
                          <div className="formBox">
                            <label className="form-label">Caption:</label>

                            <textarea
                              type="text"
                              className="form-control"
                              id="photo_Editcaption"
                              name="photo_Editcaption"
                              placeholder="photo caption"
                              value={photo_Editcaption}
                              onChange={(e) =>
                                setEditPhotocaption(e.target.value)
                              }
                            ></textarea>
                          </div>
                          <div className="formBox">
                            <label className="form-label">Location:</label>
                            <div className="locationMap locationgooglemap12">
                              <LoadScript
                                googleMapsApiKey={mapkey}
                                libraries={["places"]}
                              >
                                <StandaloneSearchBox
                                  onLoad={(ref) => (inputRef.current = ref)}
                                  onPlacesChanged={handlePlaceChanged}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="gallerylocation"
                                    placeholder="type in a name, or use the map..."
                                    defaultValue={galleryEditlocation}
                                  //onChange={(e) => setGallerylocation(e.target.value)}
                                  />
                                </StandaloneSearchBox>
                              </LoadScript>
                              <div className="map">
                                <svg
                                  className="diamondTrunImg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                </svg>
                                Map
                              </div>
                            </div>
                          </div>
                          <div className="actionWrapper">
                            <button
                              type="submit"
                              className="btn confirmBtn"
                              onClick={() => updateGallery()}
                            >
                              Save Image Meta-Data
                            </button>
                            <button
                              type="submit"
                              className="btn CancelBtn"
                              onClick={() => setImgEditModalBox(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mediaOverlay"></div>
                    </>
                  )}

                  <div className="caption mt-4">
                    <p>Caption:</p>
                    <div className="captionBox"></div>
                  </div>
                </Col>
                {mediaGallertList.map((GalleryData, i) => (
                  <Col lg={4} md={3} className="mt-5" key={Math.random()}>
                    {img_eid == GalleryData?.id ? (
                      <>
                        <div className="lablepoint">
                          <div className="title" data-tip={photo_Editlabel}>
                            {photo_Editlabel}
                          </div>
                          <ReactTooltip />
                          <div className="buticon">
                            {/* <button
                              className="btn iconBtn fa fa-edit"
                              title="Edit"
                              onClick={() => openEditMode(GalleryData?.id)}
                            ></button> */}
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/pencil-icon.svg").default
                              }
                              className="iconBtn pointer"
                              onClick={() => openEditMode(GalleryData?.id)}
                            />
                            <img
                              alt="Organization Name"
                              src={require("assets/img/trash-icon.svg").default}
                              className="iconBtn pointer"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            />
                            {/* <button
                              className="btn iconBtn fa fa-trash"
                              title="Delete"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            ></button> */}
                          </div>
                        </div>
                        <div className="imageuplode mt-3">
                          {GalleryData?.mime_type !== "video" ? (
                            <>
                              {GalleryData ? (
                                <img
                                  id="blah1"
                                  src={GalleryData?.photo_url}
                                  alt="."
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <video
                                id="blah1"
                                width="320"
                                height="240"
                                controls
                              >
                                <source
                                  src={GalleryData?.photo_url}
                                  type=""
                                  style={{ marginTop: 37 }}
                                />
                              </video>
                            </>
                          )}
                        </div>
                        <div className="caption mt-4">
                          <p>Caption:</p>
                          <div className="captionBox">
                            {GalleryData.caption}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="lablepoint">
                          <div
                            className="title"
                            data-tip={GalleryData.alt_text}
                          >
                            {GalleryData.alt_text}
                          </div>
                          <ReactTooltip />
                          <div className="buticon">
                            {/* <button
                              className="btn iconBtn fa fa-edit"
                              title="Edit"
                              onClick={() => openEditMode(GalleryData?.id)}
                            ></button> */}
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/pencil-icon.svg").default
                              }
                              className="iconBtn pointer"
                              onClick={() => openEditMode(GalleryData?.id)}
                            />

                            <img
                              alt="Organization Name"
                              src={require("assets/img/trash-icon.svg").default}
                              className="iconBtn pointer"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            />
                            {/* <button
                              className="btn iconBtn fa fa-trash"
                              title="Delete"
                              onClick={() =>
                                openDeleteBox(
                                  GalleryData?.id,
                                  GalleryData?.photo_url,
                                  GalleryData?.alt_text,
                                  GalleryData?.mime_type
                                )
                              }
                            ></button> */}
                          </div>
                        </div>
                        <div className="imageuplode mt-3">
                          {GalleryData?.mime_type !== "video" ? (
                            <>
                              {GalleryData ? (
                                <img
                                  id="blah1"
                                  src={GalleryData?.photo_url}
                                  alt="."
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <video
                                id="blah1"
                                width="320"
                                height="240"
                                controls
                              >
                                <source
                                  src={GalleryData?.photo_url}
                                  type=""
                                  style={{ marginTop: 37 }}
                                />
                              </video>
                            </>
                          )}
                        </div>
                        <div className="caption mt-4">
                          <p>Caption:</p>
                          <div className="captionBox">
                            {GalleryData.caption}
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            </div>
            {/* </div> */}
            <ToastContainer />
          </div>
        </>
      ) : (
        <>
          <div className="loding">
            <TailSpin
              ariaLabel="loading"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}
export default Mediagallery;
