import React, { useEffect } from "react";
import { informationService } from "../../_services/masters/information.service";
import { Modal, Button, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { InputField } from "../Inputbox";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";

function Storyzconfiguration(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const contact = useSelector((state) => {
    return state.profile;
  });
  const [storyzInfo, setStoryzInfo] = React.useState({});
  const [selectboxS, setSelectboxS] = React.useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({});
  const handleChangeinput = (e) => {
    storyzInfo[e.targe.name] = e.targe.value;
    setStoryzInfo({ ...storyzInfo });
  };
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
  } = useForm();
  const onSubmitList = (data) => {
    informationService.eventAdd(data).then(
      (res) => {
        if (res.status === "success") {
          reset2();
          Eventtype();
          setDefaultModale(false);
          toast.success(res.message, "Account");
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const onSubmit = (data) => {
    var formdata = new FormData();
    formdata.append("storyz_name", data.story_name);
    formdata.append("storyz_type", selectboxS.id);
    formdata.append(
      "organization_id",
      contact?.profiledetails.organization[0]?.organization_id
    );
    informationService.storyzSave(formdata).then(
      (res) => {
        if (res.status === "success") {
          history.push(`/admin/storyz/${res.storyz_id}`);
          toast.success(res.message, "Account");
          props.onHide();
          storyFunctiom();
          reset();
        } else {
          //console.log('Something went wrong !!');
          toast.error(res.message);
        }
      },
      (error) => {
        //console.log('Something went wrong !!');
        toast.error("Something went wrong !!");
      }
    );
  };
  function storyFunctiom() {
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  const [eventType, setEventType] = React.useState([]);
  const [defaultModale, setDefaultModale] = React.useState(false);

  useEffect(() => {
    Eventtype();
  }, []);
  function Eventtype() {
    informationService.eventType().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const options = eventType;

  const handleClick = () => {
    setDefaultModale(true);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger deleteaccountowner"
        isOpen={defaultModale}
      >
        <form onSubmit={handleSubmit2(onSubmitList)} key={1}>
          <div
            className="modal-header"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <h6
              className="modaltitle mx-auto mb-0"
              id="modal-title-notification"
            >
              <img
                alt="..."
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                width="75px"
                style={{ marginRight: "10px" }}
              />
            </h6>
          </div>
          <div className="modal-body">
            <div className="">
              <h4 className="" style={{ textAlign: "center" }}>
                Add a New List Item?
              </h4>

              <p>
                <span
                  style={{
                    fontSize: "14px",
                    display: "block",
                    fontWeight: "400",
                    marginBottom: "15px",
                  }}
                >
                  {" "}
                  Confirm that you want to add a list item to this list.
                </span>

                <span
                  style={{
                    fontSize: "14px",
                    display: "block",
                    fontWeight: "300",
                    marginBottom: "15px",
                  }}
                >
                  Make sure you want to do this!
                </span>
              </p>

              <input
                className="form-control"
                type="text"
                placeholder="Add New Item"
                name="event_type"
                {...register2("event_type", { required: true })}
              />
              {errors2.event_type && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              type="submit"
              style={{ background: "#FC3158", color: "#fff" }}
            >
              Add
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                // this.setState({ defaultModale: false });
                setDefaultModale(false);
              }}
              style={{ border: "1px solid #147EFB" }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>

      <Modal isOpen={props.show} centered className="storyConfigureModal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h2 className="" id="modal-title-notification">
              <img
                alt="..."
                src={require("assets/img/storysicon.svg").default}
              />
              Storyz Configuration
            </h2>
          </div>
          <div className="modal-body borderBottom">
            <div className="cardBox mb-0">
              <InputField
                type="text"
                placeholder="enter Storyz name here..."
                label="Title (used to link to Storyz page in the Guest App):"
                name="story_name"
                onChange={handleChangeinput}
                {...register("story_name", { required: true })}
              />
              {errors.story_name && (
                <span className="error">This field is required</span>
              )}
            </div>

            <Row>
              <Col md={6} sm={12}>
                <div className="cardBox">
                  <div className="form-group">
                    <label>Select the Storyz Type :</label>
                    {/* <div className="selectStoryTypeDropdown"> */}
                    <Dropdown
                      isSearchable
                      handleClick={handleClick}
                      placeHolder="Select..."
                      options={options}
                      onChange={(value) => setSelectboxS(value)}
                      customClass={"selectStoryTypeDropdown"}
                    />
                    {/* </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button className="pinkBtn" color="default" type="submite">
              Create New Storyz
            </Button>
            <Button
              className="btn-white cancelBtn"
              color="default"
              type="button"
              onClick={props.onHide}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default Storyzconfiguration;
