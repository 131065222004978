import { batch, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  changeAddress,
  changeElevation,
  changeLatitude,
  changeLongitude,
  changeMotion,
} from "../store/geofence/slice";
import {
  useGetMessagesQuery,
  useUpdateInfoMutation,
} from "../services/information";
import { settingSelector } from "../store/settings/selector";
import { geofenceSelector } from "../store/geofence/selector";
import { haversineDistance } from "./helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { locationTypeSelector } from "../store/gps/selector";
const google = (window.google = window.google ? window.google : {});

export const useGps = () => {
  const locationType = useSelector(locationTypeSelector);
  const { geoId } = useSelector(settingSelector);
  const { latitude, longitude } = useSelector(geofenceSelector);
  const [updateGeospace] = useUpdateInfoMutation();
  const dispatch = useDispatch();
  const { data: messages } = useGetMessagesQuery();
  const [datatr, setDatatr] = useState(false);

  const changeGpsLocation = (position, keepMotion = false) => {
    if (keepMotion === false) {
      setDatatr(true);
      const geospace = {
        id: geoId,
      };
      if (keepMotion) {
        const motion =
          haversineDistance({ latitude, longitude, elevation: 0 }, position) /
          Number(10);
        dispatch(changeMotion(motion));
        geospace.motion = motion;
      }
      const latlng = new google.maps.LatLng(
        position.latitude,
        position.longitude
      );
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (
          ![
            google.maps.GeocoderStatus.OK,
            google.maps.GeocoderStatus.OVER_QUERY_LIMIT,
          ].includes(status)
        ) {
          //console.log(messages?.locationNotFound ?? "not found");
          // toast.error(messages?.locationNotFound ?? "not found")
        }
        if (status == google.maps.GeocoderStatus.OK) {
          dispatch(changeAddress(results?.[0].formatted_address ?? ""));
        }
      });
      batch(() => {
        dispatch(changeLatitude(String(position.latitude)));
        dispatch(changeLongitude(String(position.longitude)));
        dispatch(changeElevation(position.elevation));
      });
      geospace.lat = position.latitude;
      geospace.lng = position.longitude;
      geospace.elevation = position.elevation;
      //updateGeospace(geospace)
    }
  };

  return { changeGpsLocation };
};
