import EditIcon from "../../../assets/img/pencil-icon.svg";
import TrashIcon from "../../../assets/img/trash-icon.svg";
import PlusIcon from "../../../assets/img/plus-icon.svg";
const KeepsakeGallery = ({ AddBrowImgModal }) => {
    return (
        <>
            <div className="keepsakeAlbumList albumBlankCard">
                <div className="topAction d-flex justify-content-end align-items-center disabledNew">
                    <img src={EditIcon} alt="" className="icon pointer ml-auto" />
                    <img src={TrashIcon} alt="" className="icon pointer ml-3" />
                </div>
                <div
                    className="keepsakeAlbumListImgWrapper mb-3 pointer"
                    onClick={() => AddBrowImgModal()}
                >
                    <div className="plusIcon">
                        <img src={PlusIcon} alt="" className="icon pointer" />
                    </div>
                </div>

                <div className="form-label captionLabel disabledNew">Caption:</div>
                <div className="keepsakeAlbumListContent textareaBox p-3 disabledNew"></div>
            </div>
        </>
    );
};

export default KeepsakeGallery;
