import React from "react";
import {
    Container,
    Row,
    Col,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Card,
    CardHeader,
    CardBody,
    Button,
} from "reactstrap";
import classnames from "classnames";
import IconImg from "../../../assets/img/icons/project logos@2x.png";
import Vector1 from "../../../assets/img/icons/two-factor-icon.svg";
import { useLocation } from "react-router-dom";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
import { userService } from "../../../_services/login/user.service";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Invitations(props) {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const {
        watch,
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        userService.invitationUser(data).then(
            (res) => {
                if (res.status === "success") {
                    toast.success(res.message, "Login");
                } else if (res.status === "error") {
                    console.log(res.message, "Login");
                } else {
                    console.log("Please enter valid username and password !!", "Login");
                }
            },
            (error) => {
                console.log("Invalid Credetials !!", "Login");
            }
        );
    };

    return (
        <>
            <Container
                fluid
                style={{
                    background: "#2053B5",
                    padding: "30px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {" "}
                <p>
                    <Link to="/">
                        <i
                            className="ni ni-bold-left"
                            style={{ color: "#fff", marginTop: "21px", marginRight: "10px" }}
                        ></i>
                    </Link>
                </p>
                <img
                    src={IconImg}
                    style={{ paddingRight: "20px" }}
                    width="70px"
                    height="50px"
                />
                <h1 style={{ color: "#fff" }}>Invitation</h1>
            </Container>
            {token ? (
                <>
                    <div className="twofactorauth">
                        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                            <Container
                                fluid
                                style={{
                                    background: "#fff",
                                    padding: "40px",
                                    minHeight: "82vh",
                                }}
                            >
                                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                                <Card className="mt-5 mb-5">
                                    <CardHeader>
                                        <h3
                                            className="mb-0"
                                            style={{
                                                borderBottom: "1px solid #E7E7E7",
                                                paddingBottom: "13px",
                                            }}
                                        >
                                            Invitation
                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {/* <Col md="6">
                                            <p className="basicinformation mt-2 ">
                                                <img src={Vector1} style={{ marginRight: "11px" }} />
                                                Password Recovery
                                            </p>
                                        </Col> */}
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mt-2 mb-2">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="example3cols1Input"
                                                    >
                                                        Name
                                                    </label>
                                                    <input
                                                        id="example3cols1Input"
                                                        placeholder="Enter Name."
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        {...register("name", {
                                                            required: "Name is required",
                                                        })}
                                                        required
                                                        autocomplete="off"
                                                    />
                                                    {errors.name ? <div>{errors.required}</div> : " "}
                                                    <input
                                                        type="hidden"
                                                        {...register("token")}
                                                        name="token"
                                                        value={token}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <label className="form-control-label">
                                                        New Password:
                                                    </label>
                                                    <InputGroup
                                                        className={classnames("input-group-merge")}
                                                    >
                                                        <input
                                                            placeholder="New Password"
                                                            type="password"
                                                            name="password"
                                                            className="form-control"
                                                            {...register("password", {
                                                                required: "Password is required",
                                                            })}
                                                            required
                                                            autocomplete="off"
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <img
                                                                    alt="password"
                                                                    src={
                                                                        require("assets/img/icons/common/password.svg")
                                                                            .default
                                                                    }
                                                                />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup className="mt-2 mb-2">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="example3cols1Input"
                                                    >
                                                        Retype Password.
                                                    </label>
                                                    <input
                                                        id="example3cols1Input"
                                                        placeholder="Enter Retype Password."
                                                        type="password"
                                                        name="cpassword"
                                                        className="form-control"
                                                        {...register("cpassword", {
                                                            required: "Confirm Password is required",
                                                            validate: (value) =>
                                                                value === getValues("password"),
                                                        })}
                                                        required
                                                        autocomplete="off"
                                                    />
                                                    {errors.cpassword &&
                                                        errors.cpassword.type === "validate" && (
                                                            <div>Passwords do not match</div>
                                                        )}
                                                    <input
                                                        type="hidden"
                                                        {...register("token")}
                                                        name="token"
                                                        value={token}
                                                    />
                                                </FormGroup>
                                                <Button
                                                    className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow"
                                                    style={{
                                                        float: "right",
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Container>
                        </form>
                    </div>
                </>
            ) : (
                <>loding...</>
            )}
            <ToastContainer />
            <AuthFooter />
        </>
    );
}
export default Invitations;
