import React from "react";
import IconImg from "../../assets/img/icons/system-logos.svg";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
    Navbar,
    FormGroup,
    Input,
    Container,
    Col,
    Row,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const dataTable = [
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
    {
        guest_name: "System Architect",
        date_created: "Edinburgh",
        admin: "61",
    },
];

const { SearchBar } = Search;
function Globalguest() {
    const handleDelete = (rowId, name) => {

        //1 YourCellName
    };
    return (
        <>
            <Navbar
                className="topbarnavbar"
                style={{ backgroundColor: "#2053b5", padding: "2.3rem 1rem" }}
            >
                <Container fluid>
                    <img src={IconImg} style={{ paddingRight: "20px" }} />
                    <h1 style={{ color: "#fff" }}>Global Guest Pool</h1>
                </Container>
            </Navbar>
            <Container className="accountinformation ptop org" fluid>
                <Row style={{ display: "grid" }}>
                    <div className="col">
                        <div className="card mt-4" style={{ padding: "16px" }}>
                            <h4
                                style={{
                                    padding: "10px",
                                    borderBottom: "1px solid #E7E7E7",
                                    margin: "0px",
                                    fontSize: "18px",
                                }}
                            >
                                Global List of all Guests for all Your Events
                            </h4>
                            <small style={{ padding: "23px", fontSize: "16px" }}>
                                This list shows every record for all your events and project. It
                                is provided specifically for your to completely delete a from
                                your records, when by the guest for privacy reasons. A common
                                reason would be that the guest requested that
                                personally-identifying (PII) information was completely removed
                                from OurStoryz.
                            </small>

                            <small
                                style={{
                                    padding: "0px 23px 23px 23px",
                                    fontSize: "16px",
                                    borderBottom: "1px solid #E7E7E7",
                                }}
                            >
                                {" "}
                                To edit guest information, or remove a guest from an event,
                                navigate to that event.{" "}
                            </small>

                            <ToolkitProvider
                                data={dataTable}
                                keyField="name"
                                columns={[
                                    {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: true,
                                        formatter: () => {
                                            return (
                                                <>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className="btn-icon-only text-light"
                                                            color=""
                                                            role="button"
                                                            size="sm"
                                                            style={{ boxShadow: "none" }}
                                                        >
                                                            <img
                                                                alt="..."
                                                                src={
                                                                    require("assets/img/Item-Action-Icons.svg")
                                                                        .default
                                                                }
                                                                width="36px"
                                                                style={{ marginRight: "10px" }}
                                                            />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/editicon.svg").default
                                                                    }
                                                                    width="15px"
                                                                />
                                                                Edit Details
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/security1.svg").default
                                                                    }
                                                                    width="15px"
                                                                />
                                                                Delete Admin Account
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/profileicon.svg")
                                                                            .default
                                                                    }
                                                                    width="15px"
                                                                />
                                                                Remove Guest Information
                                                            </DropdownItem>

                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/editfield.svg").default
                                                                    }
                                                                    width="15px"
                                                                />
                                                                Remove from Storyz and Events
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/editfield9.svg").default
                                                                    }
                                                                    width="20px"
                                                                />
                                                                Remove Tracking Data
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                href="#pablo"
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <img
                                                                    alt="..."
                                                                    src={
                                                                        require("assets/img/editdelete.svg").default
                                                                    }
                                                                    width="20px"
                                                                />
                                                                DELETE EVERYTHING
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </>
                                            );
                                        },
                                    },
                                    {
                                        dataField: "guest_name",
                                        text: "Guest Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "date_created",
                                        text: "Date Created",
                                        sort: true,
                                    },
                                    {
                                        dataField: "admin",
                                        text: "Admin?",
                                        sort: true,
                                    },

                                    {
                                        dataField: "message",
                                        text: "Message",
                                        formatter: () => {
                                            return (
                                                <>
                                                    {/* <button
                                                    className="btn btn-danger btn-xs"
                                                    onClick={() => handleDelete(row.id, row.name)}
                                                >
                                                    Delete
                                                </button> */}
                                                    <div>
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/edit12.svg").default}
                                                            width="25px"
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/edit13.svg").default}
                                                            width="25px"
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                        <img
                                                            alt="..."
                                                            src={require("assets/img/edit14.svg").default}
                                                            width="25px"
                                                        />
                                                    </div>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <Row>
                                            <Col lg={4} md={4}>
                                                <div
                                                    id="datatable-basic_filter"
                                                    className="dataTables_filter px-4 pb-1"
                                                >
                                                    <img
                                                        alt="Organization Name"
                                                        src={
                                                            require("assets/img/icons/monogram-circular.svg")
                                                                .default
                                                        }
                                                        width="30px"
                                                        style={{ marginRight: "10px" }}
                                                    />
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4} md={4}>
                                                <Row>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect1"
                                                        style={{ padding: "12px" }}
                                                    >
                                                        Filter by :
                                                    </label>

                                                    <FormGroup style={{ width: "129px" }}>
                                                        <Input id="exampleFormControlSelect1" type="select">
                                                            <option>All Guests</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Row>
                                            </Col>
                                            <Col lg={4} md={12}></Col>
                                        </Row>

                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
}
export default Globalguest;

//https://stackoverflow.com/questions/70545353/edit-and-delete-react-bootstrap-table-rows
