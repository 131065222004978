import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import StoryzViews from "../../components/model-form/storyzViews";

function StoryzMenu(props) {
  const history = useHistory();
  const params = useParams();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="dashboardLinkWrapper">
        <div className="emenu">
          <div className="emenu1">
            <img
              alt="Organization Name"
              src={require("assets/img/stormenu.svg").default}
              style={{ marginRight: "10px" }}
            />
            <h3 className="mb-0 fontSize20 JakartaMedium300">Storyz Menu</h3>
          </div>
          {/* <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element."> */}
          <img
            alt="Organization Name"
            src={require("assets/img/tip.svg").default}
            className="pointer"
            onClick={() => setModalShow(true)}
          />
          {/* </span> */}
          <StoryzViews
            displayModal={modalShow}
            closeModal={() => setModalShow(false)}
          />
          <ReactTooltip />
        </div>
        <hr style={{ marginTop: "1rem" }} />
        <div>
          <div className="menuLinkWrapper">
            <Link
              to={`/admin/storyz-about/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/adout.svg").default}
                className="icomn"
              />
              <p>About</p>
            </Link>

            <Link to={`/admin/time-line/${props.storyId}`} className="menuLink">
              <img
                alt="Organization Name"
                src={require("assets/img/Timeline.svg").default}
                className="icomn"
              />
              <p>Timeline</p>
            </Link>

            {/* <Link
              to={`/admin/storyzCalendar/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/Calendar.svg").default}
                className="icomn"
              />
              <p>Calendar</p>
            </Link> */}

            <Link
              to={`/admin/storyz-todos/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Storyz Todo"
                src={require("assets/img/StoryzTodos.svg").default}
                className="icomn"
              />
              <p>Storyz Todos</p>
            </Link>

            <Link
              to={`/admin/combined-guest-list/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/CombinedGuestList.svg").default}
                className="icomn"
              />
              <p>Combined Guest List</p>
            </Link>

            {/* <Link
              to={`/admin/storyz-upload-guest-list/${props.storyId}?type=guests`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/UploadGuestList.svg").default}
                className="icomn"
              />
              <p>Upload Guest List</p>
            </Link> */}
            {/* <div
              className="menuLink pointer"
              onClick={() => {
                history.push(
                  `/admin/storyz-upload-guest-list/${params.sid}?type=guests`
                );
              }}
            >
              <img
                alt="Organization Name"
                src={require("assets/img/UploadGuestList.svg").default}
                className="icomn"
              />
              <p>Upload Guest List</p>
            </div> */}

            <Link
              to={`/admin/storyz-honored-guest-list/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/honoredguests.svg").default}
                className="icomn"
                width={40}
                height={40}
                style={{ padding: "4px" }}
              />
              <p>Honored Guests</p>
            </Link>

            <Link
              to={`/admin/storyz-message/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/Message.svg").default}
                className="icomn"
              />
              <p>Message</p>
            </Link>

            <Link
              to={`/admin/media-gallery/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/Media-Gallery.svg").default}
                className="icomn"
              />
              <p>Media Gallery</p>
            </Link>

            <Link
              to={`/admin/storyz-keepsake-album/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/KeepsakeAlbum.svg").default}
                className="icomn"
              />
              <p>Keepsake Album</p>
            </Link>

            <Link
              to={`/admin/storyz-file/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/icons/Vector-6.svg").default}
                className="icomn"
                height={32}
                style={{ marginBottom: "8px", padding: "4px" }}
              />
              <p>Files</p>
            </Link>

            {/* <Link to={`/admin/storyz-web-listing/${props.storyId}`}></Link> */}

            <Link
              to={`/admin/storyz-web-listings/${props.storyId}`}
              className="menuLink"
            >
              <img
                alt="Organization Name"
                src={require("assets/img/preview.svg").default}
                className="icomn"
              />
              <p>Web Listing</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default StoryzMenu;
