import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import NumberInput from "../../components/NumberInput"
import Section from "../../components/Section"
import TextField from "../../components/TextField"
import { geofenceSelector } from "../../store/geofence/selector"
import {
  changeRadius,
  changeLatitude,
  changeLongitude,
  changeElevation,
  changeMotion
} from "../../store/geofence/slice"
import classNames from "./GeofenceGoordinates.module.scss"
import { settingSelector } from "../../store/settings/selector"
import { useUpdateInfoMutation } from "../../services/information"
import Fit from "../../icons/Fit"

const GeofenceCoordinates = ({ fitRadius, isRadiusNotFit }) => {
  const { geoId } = useSelector(settingSelector)
  const [updateGeospace] = useUpdateInfoMutation()
  const { latitude, longitude, radius, elevation, motion } = useSelector(
    geofenceSelector
  )
  const [currentLat, setCurrentLat] = useState(String(latitude))
  const [currentLng, setCurrentLng] = useState(String(longitude))
  const [currentElevation, setCurrentElevation] = useState(elevation)
  const [currentMotion, setCurrentMotion] = useState(motion)
  const [currentRadius, setCurrentRadius] = useState(radius)

  useEffect(() => setCurrentLat(String(latitude)), [latitude])
  useEffect(() => setCurrentLng(String(longitude)), [longitude])
  useEffect(() => setCurrentElevation(elevation), [elevation])
  useEffect(() => setCurrentMotion(motion), [motion])
  useEffect(() => setCurrentRadius(radius), [radius])

  const dispatch = useDispatch()

  const onElevationChange = value => {
    setCurrentElevation(value)
  }
  const onElevationSave = () => {
    dispatch(changeElevation(currentElevation))
    //updateGeospace({ id: geoId, elevation: currentElevation })
  }
  const onMotionChange = value => {
    setCurrentMotion(value)
  }
  const onMotionSave = () => {
    dispatch(changeMotion(currentMotion))
    //updateGeospace({ id: geoId, motion: currentMotion })
  }
  const onRadiusChange = value => {
    setCurrentRadius(value)
  }
  const onRadiusSave = () => {
    dispatch(changeRadius(currentRadius))
    //updateGeospace({ id: geoId, radius: currentRadius })
  }
  const onLatChange = value => {
    setCurrentLat(value)
  }
  const onLatSave = () => {
    dispatch(changeLatitude(currentLat))
    //updateGeospace({ id: geoId, lat: parseInt(currentLat) })
  }
  const onLngChange = value => {
    setCurrentLng(value)
  }
  const onLngSave = () => {
    dispatch(changeLongitude(currentLng))
    //updateGeospace({ id: geoId, lng: parseInt(currentLng) })
  }

  return (
    <Section
    //  title="Geofence Coordinates"
    >
      <div className="mt-4 mb-3 text-right">Units: Metric</div>
      <TextField
        label="Latitude:"
        value={String(currentLat)}
        onChange={onLatChange}
        onBlur={onLatSave}
        onKeyPress={(key) => {
          if (key === "Enter") onLatSave();
        }}
      />
      <TextField
        label="Longitude:"
        value={String(currentLng)}
        onChange={onLngChange}
        onBlur={onLngSave}
        onKeyPress={(key) => {
          if (key === "Enter") onLngSave();
        }}
      />
      <div className={classNames.subsection}>
        <NumberInput
          label="Elevation (m):"
          value={currentElevation}
          onChange={onElevationChange}
          onBlur={onElevationSave}
          onKeyPress={(key) => {
            if (key === "Enter") onElevationSave();
          }}
        />
        <TextField
          label="Motion (m/s):"
          value={currentMotion}
          onChange={onMotionChange}
          onBlur={onMotionSave}
          onKeyPress={(key) => {
            if (key === "Enter") onMotionSave();
          }}
          fullWidth={false}
          type="number"
          disabled
        />
      </div>
      <div className={classNames.radiusSection}>
        <TextField
          label="Radius (m):"
          value={currentRadius}
          onChange={onRadiusChange}
          onBlur={onRadiusSave}
          onKeyPress={(key) => {
            if (key === "Enter") onRadiusSave();
          }}
          type="number"
        />
        <div className={classNames.fitButton} onClick={fitRadius}>
          <Fit
            width={40}
            height={40}
            color={isRadiusNotFit ? "red" : "#443C67"}
          />
        </div>
      </div>
    </Section>
  );
}

export default GeofenceCoordinates
