import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import ReactTooltip from "react-tooltip";

import Allheader from "../../components/Headers/Allheader";

import Eventimage from "../../assets/img/theme/event-image.svg";
function OrganizationDiy() {
  return (
    <>
      <Allheader title="Organization Diy" />
      <Container className="organizationdiy ptop" fluid>
        <Row className="pt-4">
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="cardheader2">
                    <div className="cardheader">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/icons/eyes.svg").default}
                        style={{ marginRight: "10px" }}
                      />
                      <h3 className="mb-0" style={{ fontSize: "18px" }}>
                        Company
                      </h3>
                    </div>
                    <div>
                      <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/tip.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                      </span>
                      <ReactTooltip />
                    </div>
                  </div>
                </CardHeader>
                <hr style={{ margin: "0px" }} />
                <CardBody>
                  <div className="divround mb-4">
                    <span className="organizationimg">
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/common/Layer-1.svg").default
                        }
                        className="img-fluid"
                      />
                    </span>
                    <p style={{ fontSize: "28px", lineHeight: "22px" }}>
                      Organization Name Goes Here
                    </p>
                  </div>
                  <img src={Eventimage} className="img-fluid" width="100%" />

                  <Row>
                    <Col md="12">
                      <a className="MemberTeam">Company Details</a>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md="12">
                      <div className="sp mb-5 mt-3">
                        <div>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/icons/Color.svg").default}
                            className="icomn"
                          />
                          <p>Details</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/budget-dollar-gray-1.svg")
                                .default
                            }
                            className="icomn"
                          />
                          <p>Billing and Payments</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Vector-5.svg").default
                            }
                            className="icomn"
                          />
                          <p>Manage Admins</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Vector-6.svg").default
                            }
                            className="icomn"
                          />
                          <p>Files</p>
                        </div>
                      </div>
                      <div className="sp mb-5">
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Vector-5.svg").default
                            }
                            className="icomn"
                          />
                          <p>Combined Guest Pool</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Vector-6.svg").default
                            }
                            className="icomn"
                          />
                          <p>Global Todo List</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Chat-Vector.svg")
                                .default
                            }
                            className="icomn"
                          />
                          <p>Message Other Admins</p>
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/apple-icon.svg").default
                            }
                            className="icomn"
                          />
                          <p>Customer Support</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="cardheader2">
                    <div className="cardheader">
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/your-storyz.svg").default
                        }
                        width="30px"
                        style={{ marginRight: "10px" }}
                      />

                      <h3 className="mb-0">Your Storyz</h3>
                    </div>
                    <div>
                      <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/tip.svg").default}
                          style={{ marginRight: "10px" }}
                        />
                      </span>
                      <ReactTooltip />
                    </div>
                  </div>
                </CardHeader>
                <hr style={{ margin: "0px" }} />
                <CardBody>
                  <p>
                    To access events, select a Storyz, or use the “Events”
                    menus.
                  </p>
                  <div
                    style={{ display: "flex", marginBottom: "15px" }}
                    className="story1"
                  >
                    <div>
                      <img
                        alt="Organization Name"
                        src={require("assets/img/theme/story1.svg").default}
                      />
                    </div>
                    <div>
                      <p className="title">Storyz 1</p>
                      <p className="subtitle">Storyz 1</p>
                      <p className="subtitle1">City/State for All Events</p>
                      <p className="description">
                        Description can go on for 2 lines then an ellipsis....
                      </p>
                    </div>
                  </div>
                  <FormGroup>
                    <Input
                      id="example2cols1Input"
                      placeholder="type to search..."
                      type="text"
                    />
                  </FormGroup>
                  <section className="storyzsection">
                    {Array.from({ length: 15 }, (_, i) => (
                      <div
                        style={{ display: "flex", marginBottom: "15px" }}
                        className="story1"
                        key={i}
                      >
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/theme/Event-name.svg").default
                            }
                          />
                        </div>
                        <div>
                          <p className="title">Event Name {i + 1}</p>
                          <p className="subtitle">Date and Time</p>
                          <p className="subtitle1">Location</p>
                          <p className="description">
                            Description can go on for 2 lines then an
                            ellipsis....
                          </p>
                        </div>
                      </div>
                    ))}
                  </section>
                  <a className="newstoryz mt-4">New Storyz</a>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrganizationDiy;
