import React from "react";
import MeddiaIcon from "./../../../../src/assets/img/media-icon.svg";
import Filemanager from "../../CommonMediaModal/Filemanager";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import { informationService } from "../../../_services/masters/information.service";
import "react-toastify/dist/ReactToastify.css";
import * as actionTypes from "../../../store/actionTypes";
const UserInfo = ({ data, onClick1, storyzKeepsakeAllArr }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const handleClick12 = () => {
    setModalShow21(false);
  };

  let textData =
    data != undefined && data?.description != undefined
      ? data?.description
      : "";

  const contact = useSelector((state) => {
    return state.profile;
  });
  let organization_id =
    contact?.profiledetails?.organization[0].organization_id;

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  const [allimage, setAllimage] = React.useState([]);
  const [text, setText] = React.useState(textData);
  const [text2, setText2] = React.useState(textData);
  const [modalShow21, setModalShow21] = React.useState(false);

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "Eventkeepsakealbum",
      storyz_id: params.sid,
      event_id: params.eid,
      organization_id: parseInt(organization_id),
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    });

    userGuestService.createAlbumStoryz(data).then(
      (res) => {
        if (res.status === "success") {
          handleClick12();
          toast.success("Updated Successfully");
          onClick1();
          Fileset();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const seletedImageClike = (e) => {
    let jsonData = JSON.parse(e.img_json);
    jsonData = JSON.parse(JSON.stringify(jsonData));

    jsonData.img_address = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData.lat = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData.lng = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = JSON.stringify({
      type: "Eventkeepsakealbum",
      storyz_id: params.sid,
      event_id: params.eid,
      organization_id: parseInt(organization_id),
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData,
    });

    userGuestService.createAlbumStoryz(data).then(
      (res) => {
        if (res.status === "success") {
          handleClick12();
          toast.success("Updated Successfully");
          onClick1();
          Fileset();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const onChange = (val) => {
    setText(val);
  };

  const setModalShow21Open = () => {
    //alert("setModalShow21Open");

    if (storyzKeepsakeAllArr.length === 0) {
      toast.error("Plsese Add Storyz Keepsake first", "Error");
    } else {
      setModalShow21(true);
    }

  };

  const saveText = () => {
    if (text != "" && text2 != text) {
      let obj = {
        storyz_id: params.sid,
        event_id: params.eid,
        des: text,
        organization_id: parseInt(organization_id),
      };
      //console.log("obj--->", obj);
      informationService.saveText(obj).then(
        (res) => {
          if (res.status === "success") {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        },
        (error) => {
          console.log("error");
        }
      );
    }
    setText("");
  };

  function Fileset() {
    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  });

  return (
    <div className="keepsakeAlbumFormWrapper">
      <div className="coverImgWrapper">
        <div className="coverImgHead">
          <div className="form-label">Cover Image:</div>
          <img
            src={MeddiaIcon}
            alt=""
            className="icon pointer"
            onClick={() => setModalShow21Open()}
          />
        </div>
        <div className="coverImgBox" onClick={() => setModalShow21Open()}>
          {data?.ev_cover_image ? (
            <img src={data?.ev_cover_image} alt="" className="coverImg" />
          ) : (
            ""
          )}
          <div className="imgMask-content">+</div>
        </div>
      </div>
      {modalShow21 && (
        <Filemanager
          show={modalShow21}
          onHide={handleClick12}
          onClickimageUpdate={imageUpdate}
          list={allimage}
          seletedImage={seletedImageClike}
        />
      )}
      <div className="textareaWrapper">
        <div className="form-label">
          Text introducing this event’s Keepsake Album to visitors:
        </div>
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          defaultValue={data?.ev_description}
          onChange={(e) => onChange(e.target.value)}
          onMouseOut={saveText}
        ></textarea>
      </div>
    </div>
  );
};

export default UserInfo;
