import React from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DummyImg from "../../assets/img/dummyImg.svg";
import statusClose from "./../../assets/img/status-close.svg";
import statusCheck from "./../../assets/img/status-check.svg";
import statusRefresh from "./../../assets/img/status-refresh.svg";
import statusNotification from "./../../assets/img/status-notification.svg";
// const options = [
//   { value: 'Not Started', label: 'Not Started' },
//   { value: 'In Progress', label: 'In Progress' },
//   { value: 'Completed', label: 'Completed' },
//   { value: 'Overdue', label: 'Overdue' },
// ];

function dataFundatadata(props) {

  return (
    <>
      <div className="todoWrapperNew">
        <div className="todoDottedWrapper sideBrdr">
          <div>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                color=""
                role="button"
                size="sm"
                style={{ boxShadow: "none" }}
              >
                <img
                  alt="..."
                  src={require("assets/img/Item-Action-Icons.svg").default}
                  width="36px"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={(e) => props.editTodo2(props)}>
                  <img
                    alt="..."
                    src={require("assets/img/editicon.svg").default}
                    width="15px"
                  />
                  Edit Todo
                </DropdownItem>
                <DropdownItem onClick={(e) => props.handelDeleteModal(props)}>
                  <img
                    alt="..."
                    src={require("assets/img/editdelete.svg").default}
                    width="20px"
                  />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div className="todoRightContentWrapper">
          <h4 className="todotitle">{props.title}</h4>
          <div className="todoContentListWrapper">
            <div className="todoList todoList1">Assigned</div>
            <div className="todoList todoList2">Due</div>
            <div className="todoList todoList3">Status</div>
            <div className="todoList todoList4">Applied to</div>
          </div>
          <div className="todoContentListWrapper">
            <div className="todoList todoList1">
              <div className="date">{props.assigned}</div>
            </div>
            <div className="todoList todoList2">
              <div className="date">{props.due} </div>
            </div>

            <div className="todoList todoList3">
              <div className="statusBox">
                {props.todo_status === "In Progress" && (
                  <>
                    <img src={statusRefresh} alt="" />
                    {props.todo_status}
                  </>
                )}

                {props.todo_status === "Not Started" && (
                  <>
                    <img src={statusClose} alt="" />
                    {props.todo_status}
                  </>
                )}

                {props.todo_status === "Completed" && (
                  <>
                    <img src={statusCheck} alt="" />
                    {props.todo_status}
                  </>
                )}

                {props.todo_status === "Overdue" && (
                  <>
                    <img src={statusNotification} alt="" />
                    {props.todo_status}
                  </>
                )}
              </div>
            </div>
            <div className="todoList todoList4">
              <div>
                {props.todo_type === "Organization" && (
                  <>
                    <div className="userCardNew">
                      <div>
                        <img
                          src={props.cover_image ? props.cover_image : DummyImg}
                          className="organizationImg"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="title">{props.event_name}</div>
                        <div className="subTitle">
                          {props.todo_type} Service Level
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {props.todo_type === "Storyz" && (
                  <>
                    <div className="userCardNew d-flex align-items-center">
                      <div>
                        <img
                          src={props.cover_image ? props.cover_image : DummyImg}
                          className="storyzImg"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="title">{props.event_name}</div>
                        <div className="subTitle">
                          {props.todo_type} Service Level
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {props.todo_type === "Event" && (
                  <>
                    <div className="userCardNew">
                      <div className="date">{props.start_date}</div>
                      <div>
                        <img
                          src={props.cover_image ? props.cover_image : DummyImg}
                          className="eventImg"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="title">{props.event_name}</div>
                        <div className="subTitle">
                          {props.todo_type} Service Level
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Table className="tableData" responsive>
        <tbody>
          <tr>
            <td className="sideBrdr" style={{ width: "100px" }}>
              <div
                style={{
                  marginTop: "calc(50% + 8px) ",
                }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                    style={{ boxShadow: "none" }}
                  >
                    <img
                      alt="..."
                      src={require("assets/img/Item-Action-Icons.svg").default}
                      width="36px"
                      style={{ marginRight: "10px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem onClick={(e) => props.editTodo2(props)}>
                      <img
                        alt="..."
                        src={require("assets/img/editicon.svg").default}
                        width="15px"
                      />
                      Edit Todo
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => props.handelDeleteModal(props)}
                    >
                      <img
                        alt="..."
                        src={require("assets/img/editdelete.svg").default}
                        width="20px"
                      />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </td>
            <td>
              <table className="w-100">
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <h4 className="todotitle" style={{ margin: "0" }}>
                        {props.title}
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <td className="subtitletodo" style={{ width: "25%" }}>
                      Assigned
                    </td>
                    <td className="subtitletodo" style={{ width: "25%" }}>
                      Due
                    </td>
                    <td className="subtitletodo" style={{ width: "25%" }}>
                      Status
                    </td>
                    <td className="subtitletodo" style={{ width: "25%" }}>
                      Applied to
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <div style={{ lineHeight: "40px" }}>{props.assigned}</div>
                    </td>
                    <td>
                      <div style={{ lineHeight: "40px" }}>{props.due} </div>
                    </td>
                    <td style={{ lineHeight: "40px" }}>
                      {props.todo_status === "In Progress" && (
                        <>
                          <img
                            src={statusRefresh}
                            alt=""
                            className="mr-2"
                            width={20}
                            height={20}
                          />
                          {props.todo_status}
                        </>
                      )}

                      {props.todo_status === "Not Started" && (
                        <>
                          <img
                            src={statusClose}
                            alt=""
                            className="mr-2"
                            width={20}
                            height={20}
                          />
                          {props.todo_status}
                        </>
                      )}

                      {props.todo_status === "Completed" && (
                        <>
                          <img
                            src={statusCheck}
                            alt=""
                            className="mr-2"
                            width={20}
                            height={20}
                          />
                          {props.todo_status}
                        </>
                      )}

                      {props.todo_status === "Overdue" && (
                        <>
                          <img
                            src={statusNotification}
                            alt=""
                            className="mr-2"
                            width={20}
                            height={20}
                          />
                          {props.todo_status}
                        </>
                      )}
                    </td>
                    <td>
                      {props.todo_type === "Organization" && (
                        <>
                          <div className="userCardNew d-flex align-items-center">
                            <div>
                              <img
                                src={
                                  props.cover_image
                                    ? props.cover_image
                                    : DummyImg
                                }
                                className="organizationImg"
                                alt=""
                              />
                            </div>
                            <div style={{ paddingLeft: "10px" }}>
                              <span style={{ fontSize: "14px" }}>
                                {props.event_name}
                              </span>
                              <br />
                              <span style={{ fontSize: "10px" }}>
                                {props.todo_type} Service Level
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {props.todo_type === "Storyz" && (
                        <>
                          <div className="userCardNew d-flex align-items-center">
                            <div>
                              <img
                                src={
                                  props.cover_image
                                    ? props.cover_image
                                    : DummyImg
                                }
                                className="storyzImg"
                                alt=""
                              />
                            </div>
                            <div style={{ paddingLeft: "10px" }}>
                              <span style={{ fontSize: "14px" }}>
                                {props.event_name}
                              </span>
                              <br />
                              <span style={{ fontSize: "10px" }}>
                                {props.todo_type} Service Level
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {props.todo_type === "Event" && (
                        <>
                          <div className="userCardNew d-flex align-items-center">
                            <div className="date mr-2">{props.start_date}</div>
                            <div>
                              <img
                                src={
                                  props.cover_image
                                    ? props.cover_image
                                    : DummyImg
                                }
                                className="eventImg"
                                alt=""
                              />
                            </div>
                            <div style={{ paddingLeft: "10px" }}>
                              <span style={{ fontSize: "14px" }}>
                                {props.event_name}
                              </span>
                              <br />
                              <span style={{ fontSize: "10px" }}>
                                {props.todo_type} Service Level
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </Table> */}
    </>
  );
}

export default dataFundatadata;
