import React, { useEffect, useState } from "react";

import { Form, ModalFooter, ModalBody, Modal, ModalHeader } from "reactstrap";
import Pulldown from "../../assets/img/pulldown item text web app.svg";
import Removeicon from "../../assets/img/removeicon.svg";
import moment from "moment";
import Vector from "../../assets/img/Vector 2.svg";

import chatIcon from "../../assets/notification-icon/chat-icon.png";
import eventIcon from "../../assets/notification-icon/event-icon.png";
import pastRsvpIcon from "../../assets/notification-icon/past-rsvp-icon.png";
import resetPassword from "../../assets/notification-icon/reset-password.png";
import rsvpIcon from "../../assets/notification-icon/rsvp-icon.png";
import settingsIcon from "../../assets/notification-icon/settings-icon.png";
import storyzIcon from "../../assets/notification-icon/storyz-icon.png";
import supportIcon from "../../assets/notification-icon/support-icon.png";
import todoIcon from "../../assets/notification-icon/todo-icon.png";
import userIcon from "../../assets/notification-icon/user-icon.png";

import { userGuestService } from "../../_services/masters/userGuest.service";
import ReactTooltip from "react-tooltip";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [confirmMsg, SetConfirmMsg] = useState(false);
  const [deleteNotification, SetDeleteNotification] = useState(false);
  const [deleteId, SetDeleteId] = useState(0);

  const OpenConfirmModal = () => {
    SetConfirmMsg(true);
  };

  const OpenDeleteNotification = (id) => {
    SetDeleteNotification(true);
    SetDeleteId(id);
  };

  function functionDelete() {
    userGuestService.deleteNotifications(deleteId).then(
      (res) => {
        if (res.status === "success") {
          notificationall();
          SetDeleteNotification(false);
          toast.success("Deleted Notification Successfully", "Notification");
        } else {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Error");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
        toast.error("Something went wrong !!", "Error");
      }
    );
  }
  function notificationall() {
    userGuestService.listNotifications().then(
      (res) => {
        if (res.status === "success") {
          let data = [];
          for (let i = 0; i < res.data.length; i++) {
            let row = res.data[i];
            let notificationIcon = settingsIcon;
            let created_at =
              moment(new Date(row.created_at)).format("DD/MM/YYYY - h:mm a") +
              " UTC";

            if (
              row.module == "auth" ||
              row.module == "GuestInvite" ||
              row.module == "invite"
            ) {
              notificationIcon = userIcon;
            } else if (row.module == "profile") {
              notificationIcon = resetPassword;
            } else if (row.module == "Setting") {
              notificationIcon = settingsIcon;
            } else if (row.module == "Event") {
              notificationIcon = eventIcon;
            } else if (row.module == "Storyz") {
              notificationIcon = storyzIcon;
            } else if (row.module == "Todo") {
              notificationIcon = todoIcon;
            } else if (row.module == "RSVP") {
              notificationIcon = rsvpIcon;
            } else if (row.module == "PASTRSVP") {
              notificationIcon = pastRsvpIcon;
            } else if (row.module == "Chat") {
              notificationIcon = chatIcon;
            } else if (row.module == "Support") {
              notificationIcon = supportIcon;
            }

            // console.log("row",row)
            let payload = {
              date: (
                <>
                  <img src={notificationIcon} className="mr-1" alt="" />{" "}
                  {created_at}
                </>
              ),
              copy_date: created_at,
              message: row.description,
              delete: (
                <>
                  <div className="d-flex align-items-center justify-content-end pointer">
                    <img
                      alt="..."
                      src={Removeicon}
                      onClick={() => {
                        OpenDeleteNotification(row.id);
                      }}
                    />
                  </div>
                </>
              ),
            };
            data.push(payload);
          }
          // console.log("notifications", notifications);
          setNotifications(data);
          // toast.success("Deleted All Notification Successfully", "Notification");
          //console.log("res.data", res.data);
        } else {
          //console.log("Something went wrong !!");
          // toast.error("Something went wrong !!", "Error");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Error");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  function functionClear() {
    userGuestService.clearNotifications().then(
      (res) => {
        if (res.status === "success") {
          SetConfirmMsg(false);
          notificationall();
          toast.success(
            "Deleted All Notification Successfully",
            "Notification"
          );
        } else {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Error");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
        toast.error("Something went wrong !!", "Error");
      }
    );
  }
  useEffect(() => {
    notificationall();
  }, []);

  const columns = [
    {
      dataField: "date",
      text: (
        <>
          <img src={Vector} className="mr-1" alt="" /> Date
        </>
      ),
      headerStyle: { display: "none" },
      sort: false,
    },
    {
      dataField: "copy_date",
      text: (
        <>
          <img src={Vector} className="mr-1" alt="" /> Date
        </>
      ),
      style: { display: "none" },
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "delete",
      text: "",
      sort: false,
    },
  ];

  return (
    <>
      <ToastContainer />

      <CombinedGuestPoolHeader title="Notifications" arw />
      <div className="organizerWrapperMain">
        <Form>
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">
                Storyz, Event, Guest and Team Notifications
              </h4>
              <div className="ml-auto">
                <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                  />
                </span>
                <ReactTooltip />
              </div>
            </div>

            <div className="borderBottom organizationPara p-4">
              This page shows notifications you have received from the system,
              other admins, or guests.
            </div>

            <div className="d-flex py-4">
              {notifications.length > 0 ? (
                <div className="ml-auto">
                  <img
                    src={Pulldown}
                    onClick={() => OpenConfirmModal()}
                    alt=""
                    className="pointer"
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="notificationTableWrapper">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notifications}
                columns={columns}
                //pagination={pagination}
                bordered={false}
                defaultSorted={[{ dataField: "copy_date", order: "desc" }]}
              />
            </div>
          </div>
        </Form>
      </div>
      <Modal
        isOpen={confirmMsg}
        toggle={OpenConfirmModal}
        className="confirmNotificationModal"
      >
        <ModalHeader className="border-bottom">
          Clear All Notifications
        </ModalHeader>
        <ModalBody className="pb-0">
          <p className="mb-0">
            Are your sure you want to clear all notifications?
          </p>
        </ModalBody>
        <ModalFooter>
          <div
            className="btn pinkBtn flex-grow-1"
            onClick={() => {
              functionClear();
            }}
          >
            Confirm
          </div>
          <div
            className="btn btn-default cancelBtn flex-grow-1"
            onClick={() => {
              SetConfirmMsg(false);
            }}
          >
            Cancel
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteNotification}
        toggle={OpenDeleteNotification}
        className="confirmNotificationModal"
      >
        <ModalHeader className="border-bottom">Delete Notification</ModalHeader>
        <ModalBody className="pb-0">
          <p className="mb-0">Are your sure you want to delete?</p>
        </ModalBody>
        <ModalFooter>
          <div
            className="btn pinkBtn flex-grow-1"
            // onClick={() => {
            //   functionClear();
            // }}
            onClick={() => functionDelete()}
          >
            Confirm
          </div>
          <div
            className="btn btn-default cancelBtn flex-grow-1"
            onClick={() => {
              SetDeleteNotification(false);
            }}
          >
            Cancel
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Notifications;
