import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { ReactSession } from "react-client-session";
import Filemanager from "../../../components/CommonMediaModal/Filemanager";
import placeholde from "../../../assets/img/placeholder.jpg";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import CustomButton from "../../Button/CustomButton";
import Eventmenu from "../eventMenu";
import DeleteGroup from "./Deletegroup";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { GuestEventWrapper } from "../UserGuestList/style";
import { BsFillChatDotsFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { combinedService } from "../../../_services/masters/information.combined";
import {
  useFindPathSid,
  useFindPathEid,
  useFindPathid,
} from "../../../CommonData";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MdAccountCircle } from "react-icons/md";
import Allheader from "components/Headers/AllheaderEvent";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import GroupMemberDropdown from "components/Dropdown/GroupMemberDropdown";
import GuestGroupDropdown from "components/Dropdown/GuestGroupDropdown";
import GuestGroupImg1 from "./../../../assets/img/guestGroupImg1.jpg";
import GuestGroupImg2 from "./../../../assets/img/guestGroupImg2.jpg";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import { useHistory } from "react-router-dom";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";

const EvenrGuestGroupcomedit = ({ ...args }) => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClickimag = () => {
    setModalShow(false);
  };
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let useid = useFindPathid(5);
  const [addGuestGroupDropdown, setAddGuestGroupDropdown] = useState(false);
  const [eventItemsid, setEventItemsid] = useState([]);
  const [guestGroupModal, setGuestGroupModal] = useState();
  const [typeOfGroup, setTypeOfGroup] = useState();
  const [memberData, setMemberData] = useState();
  let history = useHistory();
  //   const [eventList, seteventList] = React.useState([]);

  const percentage = 66;

  const groupType = [
    // { title: "Honored Guests" },
    { title: "Family" },
    { title: "Business" },
    { title: "Friends" },
    { title: "Partners" },
    { title: "Professional" },
    { title: "Casual" },
    { title: "Social" },
    { title: "Political" },
    { title: "Relegious" },
    { title: "Cultural" },
    { title: "Educational" },
    { title: "Sports" },
  ];

  const handelAddGuestGroupDropdown = () => {
    setAddGuestGroupDropdown(!addGuestGroupDropdown);
  };
  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };

  const handlerFunction2 = (e) => {
    setTypeOfGroup(e);
  };
  const [imgData, setImgData] = React.useState(null);
  const [imgDa, setImgDa] = React.useState(null);
  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    if (e.target.files[0]) {
      //console.log(e.target.files[0]);
      setImgData(URL.createObjectURL(e.target.files[0]));
    }
  };
  const contact = useSelector((state) => {
    return state.profile;
  });

  //
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [userguestList, setUserguestList] = React.useState();
  useEffect(() => {
    userGuestService.userGuestList(eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  }, [useid]);
  const [userguestID, setUserguestID] = React.useState([]);
  const onClickEvent = (e) => {
    setUserguestID(e);
  };

  const [deleteGroupUser, setDeleteGroupUser] = useState(false);
  const handleDeleteGroupUser = (e) => {
    setDeleteGroupUser(!deleteGroupUser);
  };
  const onSubmit = (data) => {
    const organization_id =
      contact?.profiledetails.organization[0]?.organization_id;
    const user_id = contact?.profiledetails.user_id;
    const data1 = JSON.stringify({
      group_image: imageData,
      user_id: user_id,
      event_id: parseInt(eid),
      organization_id: organization_id,
      group_name: data.group_name,
      group_type: typeOfGroup ? typeOfGroup : "",
      group_description: data.group_description,
      group_admin_notes: data.admin_notes_on_guest,
      member_id: userguestID === undefined ? [] : JSON.stringify(userguestID),
      id: data.id,
    });

    combinedService.GuestcreateUpdate(data1).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Guest Groups update successfully !!", "Account");
        } else {
          toast.error(res.message, "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  };

  const [alldetails, setAlldetails] = useState({});
  useEffect(() => {
    combinedService.groupDetails(useid).then(
      (res) => {
        if (res.status === "success") {
          setAlldetails(res.data);
          const obj = {
            id: res.data.id,
            group_name: res.data.group_name,
            type: "GuestGroups",
          };
          sessionStorage.setItem("detailsOfUser", JSON.stringify(obj));
          setTypeOfGroup(res.data.group_type);
          setImgData(res.data.group_image);
          setMemberData(res.data.member_role);
          setValue("id", res.data.id, { shouldTouch: true });
          setValue("group_name", res.data.group_name, { shouldTouch: true });
          setValue("group_description", res.data.group_description, {
            shouldTouch: true,
          });
          setValue(
            "admin_notes_on_guest",
            res.data.group_admin_notes === null ||
              res.data.group_admin_notes === "null"
              ? ""
              : res.data.group_admin_notes,
            { shouldTouch: true }
          );
        } else {
          toast.error(res.message, "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );
  }, [useid]);
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  const [allimage, setAllimage] = useState([]);
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, [allimage]);
  const [imageData, setImageData] = useState({});
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";
    setImageData(jsonData[0]);
  };
  const seletedImageClike = (e) => {
    let jsonData = JSON.parse(e.img_json);
    setImageData(jsonData);
    setModalShow(false);
  };

  return (
    <>
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />
            <div className="column2">
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>{" "}
                  {common?.singleEvent?.event_name
                    ? common?.singleEvent?.event_name
                    : ""}
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="imageeventim234"
                    onClick={handelGuestGroupModal}
                  >
                    <span>Guest Groups</span>
                  </div>

                  <Modal
                    isOpen={guestGroupModal}
                    toggle={handelGuestGroupModal}
                    {...args}
                    className="aboutGuestGroupModal"
                  >
                    <ModalBody className="pb-0">
                      <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                        <img
                          src={GeospaceInfoIcon}
                          alt=""
                          className="guestGroupInfoIcon mt-3"
                        />
                        <div className="head">About Guest Groups</div>
                      </div>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            Guests at your event can be added to groups. A good
                            example would be a family - you could add several
                            guests to a group, and specify “family” as the group
                            type.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={GuestGroupImg1}
                            alt=""
                            className="maxWidth100 mx-auto"
                            width={183}
                          />
                        </Col>
                      </Row>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            A key advantage of groups is that you can send
                            messages to the entire group, rather than having to
                            send messages to each event individually.
                          </p>
                        </Col>
                        <Col sm={5} className="d-flex align-items-center">
                          <img
                            src={GuestGroupImg2}
                            alt=""
                            className="maxWidth100 mx-auto"
                            width={76}
                          />
                        </Col>
                      </Row>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col>
                          <p>
                            If you remove a guest from a group, it doesn’t
                            affect their status in the guest list - just the
                            group. Guests can also be added to multiple groups
                            within the event.
                          </p>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                      <Button
                        color="primary blueBtn"
                        className="w-100"
                        onClick={handelGuestGroupModal}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <span
                    className="ml-2"
                    data-tip="Tooltip message will come here....."
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                    />
                  </span>
                </div>
              </div>

              <div className="borderBottom eventBannerPara">
                This page lets you view and create a todo list associated with
                this specific Storyz.
                <br />
                To see the complete list of todos, go to the
                <Link
                  onClick={() => {
                    history.push(`/admin/storyz/${sid}`);
                  }}
                  className="text-decoration-underline grayColor ml-1"
                >
                  Storyz view
                </Link>
                or
                <Link
                  onClick={() => {
                    history.push(`/admin/organization`);
                  }}
                  className="text-decoration-underline grayColor ml-1"
                >
                  Organization view
                </Link>
                .
              </div>

              <GuestEventWrapper className="p-4">
                <div className="organizeByGroupOuter mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-center pb-4">
                      <button className="messageBtn" type="button">
                        <BsFillChatDotsFill className="icon" />
                        Message
                      </button>
                      <div className="guestCircleWrapper ml-auto">
                        {/* <CircularProgressbar
                          value={percentage}
                          text={false}
                          strokeWidth={20}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#53D769 ",
                            trailColor: "#1345A5",
                          })}
                        /> */}
                      </div>
                      <GlobalButton
                        customClass="ml-2"
                        img={require("assets/img/peopleIcon.svg").default}
                        type="submit"
                      >
                        Save Changes
                      </GlobalButton>
                      {/* <DeleteBtn icon> Delete Group </DeleteBtn> */}
                    </div>
                    <Row className="addGuestUerFormWrapper">
                      <Col className="">
                        <div className="heading">Description</div>
                        <div className="cardBox">
                          <div className="formBox">
                            <div className="imageventsla my-2">
                              <div>Cover Image:</div>
                              <div>
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/mediaGallery.svg")
                                      .default
                                  }
                                  className="icomn pointer"
                                  style={{ width: "24px" }}
                                  onClick={() => {
                                    setModalShow(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="mt-3">
                              <div
                                className="imgMaskevente"
                                onClick={() => {
                                  setModalShow(true);
                                }}
                              >
                                {/* <div className="imgMask-content">+</div> */}
                                <img
                                  className="storytoimageev"
                                  id="blah2"
                                  src={
                                    imageData?.url
                                      ? imageData?.url
                                      : imgData
                                        ? imgData
                                        : placeholde
                                  }
                                  alt="."
                                />
                              </div>
                            </div>
                          </div>

                          {modalShow ? (
                            <Filemanager
                              show={modalShow}
                              onHide={handleClickimag}
                              onClickimageUpdate={imageUpdate}
                              list={allimage}
                              seletedImage={seletedImageClike}
                              mapkey=""
                            />
                          ) : (
                            ""
                          )}
                          <div className="formBox">
                            <div className="form-group">
                              <label className="form-label">Group Name:</label>
                              <input
                                type="hidden"
                                className="form-control"
                                placeholder="enter text..."
                                name="id"
                                {...register("id")}
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="enter text..."
                                name="group_name"
                                {...register("group_name", {
                                  required: true,
                                })}
                              />

                              {errors.group_name && (
                                <span
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="formBox">
                            <div className="form-group">
                              <label className="form-label">
                                Select the type of group:
                              </label>
                              {typeOfGroup ? (
                                <GuestPrefixDropdown
                                  placeholder={
                                    typeOfGroup
                                      ? typeOfGroup
                                      : "---Select Group Type---2222"
                                  }
                                  guestPrefixOption1={groupType}
                                  handlerFunction={handlerFunction2}
                                  handleAddModal={handelAddGuestGroupDropdown}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="formBox">
                            <div className="form-group">
                              <label className="form-label">
                                Group Description?
                              </label>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="enter text..."
                                name="group_description"
                                {...register("group_description")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className="">
                        <div>
                          <div className="heading">
                            Admin Notes on this Group
                          </div>
                          <div className="formBox">
                            <div className="cardBox">
                              <div className="form-group">
                                <label className="form-label">
                                  Add any special information about this group
                                  here:
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="enter text..."
                                  name="admin_notes_on_guest"
                                  {...register("admin_notes_on_guest")}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="heading">Current Group Members</div>
                          <div className="formBox">
                            <div className="cardBox">
                              <div className="form-group">
                                <label className="form-label">
                                  Current Group Members
                                </label>
                                {memberData ? (
                                  <GuestGroupDropdown
                                    placeholder="search groups"
                                    guestListOption={userguestList}
                                    onChange={onClickEvent}
                                    data={memberData}
                                    customClass="guestGroupUpdated"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* <div className="AdDLiSt_BuTtON">
                      <CustomButton>Save Information</CustomButton>
                    </div> */}
                  </form>
                </div>
              </GuestEventWrapper>
            </div>
          </Eventstyle>
        </div>

        <button
          type="button"
          className="resetGeoBtn"
          onClick={handleDeleteGroupUser}
        >
          <MdAccountCircle /> Delete Group
        </button>
        {deleteGroupUser && (
          <DeleteGroup
            show={deleteGroupUser}
            onHide={handleDeleteGroupUser}
            single={alldetails}
            urlRedirect={true}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default EvenrGuestGroupcomedit;
