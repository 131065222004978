// import Accordion from "../Accordion/Accordion";
import React, { useEffect } from "react";
import { eventsService } from "../../_services/masters/events.service";
import { informationService } from "../../_services/masters/information.service";
import { combinedService } from "../../_services/masters/information.combined";
import { useSelector, useDispatch } from "react-redux";
import { commonData } from "store/actions";
import DeleteGuestSingle from "../Events/UserGuestList/GuestDeleteSingle";
import DeleteGroup from "./GuestGroup/Deletegroup";
import Eventdelete from "../../components/model-form/eventDelete";
import moment from "moment";
import {
  FaCircle,
  FaChevronRight,
  FaStar,
  FaImage,
  FaRegArrowAltCircleDown,
  FaUserAlt,
} from "react-icons/fa";
import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
  useFindPathid,
  useDateCount
} from "../../CommonData";
import { userGuestService } from "_services/masters/userGuest.service";
import {
  MdTextSnippet,
  MdOutlinePeopleAlt,
} from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import { AiFillCloseCircle, AiTwotoneCheckCircle } from "react-icons/ai";
import { RxDragHandleDots2 } from "react-icons/rx";
import DeleteGuestList from "components/Events/UserGuestList/DeleteGuestList";
import { BiCloudUpload } from "react-icons/bi";
import UploadSelectType from "../../components/Events/UploadGuestList/UploadSelectType.js";
import KeepshakeIcon from "./../../assets/img/keepshake-album.svg";
import HonorGuestIcon from "./../../assets/img/honored-guest.svg";
import GeospaceIcon from "./../../assets/img/geospaceIcon.svg";
import GroupIcon from "./../../assets/img/guetGroupIcon.svg";
import ExportGuestList from "./UserGuestList/ExportGuestList";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
export default function Eventmenu(props) {
  // const [open, setOpen] = useState();
  //console.log( "props EventMenu", props.handleInviteTemplate() );

  let history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const type = query.get("type");

  const [deleteGuestList, setDeleteGuestList] = useState(false);
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  let url = useFindPath(2);
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let id = useFindPathid(5);

  const storedObj = JSON.parse(sessionStorage.getItem("detailsOfUser"));
  const [deleteGroupUser, setDeleteGroupUser] = useState(false);
  const handleDeleteGroupUser = (e) => {
    setDeleteGroupUser(!deleteGroupUser);
  };
  const handleDeleteGuestList = () => {
    setDeleteGuestList(!deleteGuestList);
  };

  const [exportGuestlist, setexportGuestlist] = useState(false);
  const handleDeleteGuestUserex = (e) => {
    setexportGuestlist(!exportGuestlist);
  };
  const [guestData, setGuestData] = useState({});

  useEffect(() => {
    if (storedObj?.id && storedObj?.type === "GuestDetails") {
      userGuestService.guestDetails(storedObj?.id).then(
        (res) => {
          if (res.status === "success") {
            setGuestData(res.data);
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          // toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }

    if (storedObj?.id && storedObj?.type === "GuestGroups") {
      combinedService.groupDetails(storedObj?.id).then(
        (res) => {
          if (res.status === "success") {
            setGuestData(res.data);
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          // toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [storedObj?.id]);
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
  };

  const params = useParams();

  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let getInviteName = localStorage.getItem("inviteName");

  const [modalShow, setModalShow] = useState(false);

  const handleClickimag = () => {
    setModalShow(false);
  };

  const common = useSelector((state) => {
    return state.commonData.common;
  });


  React.useEffect(() => {
    if (eid) {
      const id = { event_id: eid };
      eventsService.eventDetails(id).then(
        (res) => {
          if (res.status === "success") {
            let sid = res.data.storyz_id;
            let eid = res.data.id;
            let storyName = res.data.storyz_name;
            let singleEvent = res.data;
            dispatch(commonData(common));
            myFunctionList(sid, eid, storyName, singleEvent);
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [sid, eid]);
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  useEffect(() => {
    if (
      url === "edir-guest-group" ||
      url === "edit-user-guest-list" ||
      url === "event-guest-authentication" ||
      url === "guest-keepsake-album"
    ) {
    } else {
      sessionStorage.removeItem("detailsOfUser");
    }
  });

  return (
    <>
      <div className="column1 border-right">
        <div
          className="storycolumn1"
          onClick={() => {
            history.push(`${`/admin/event-dashboard/${sid}/${eid}`}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            alt="Organization Name"
            src={
              event_img
                ? event_img
                : require("assets/img/dummyImg.svg").default
            }
            className="datastorimg"
          />
          <p className="eventdatea">
            {common?.singleEvent?.event_start_date &&
              common?.singleEvent?.event_start_date !== "NA"
              ? moment
                .utc(common?.singleEvent?.event_start_date)
                .format("MMM Do")
              : "No Date"}{" "}
            <br />

            {`${useDateCount(common?.singleEvent?.event_end_date)}`}
            {/* {common?.singleEvent?.event_name} */}
          </p>
        </div>
        <div className="storycolumn2">
          <div className="insideMenuWrapper">
            <Accordion>
              <AccordionItem isActive={type === "event" ? true : false}>
                <AccordionHeader className="accordionMneuHead ">
                  <h3 className={`accordion-title `}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Event
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>
                <AccordionBody className="contentWrapper active">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-dashboard/${sid}/${eid}`}
                          className="link"
                        >
                          <span className="innerIcon">
                            <FaStar />
                          </span>
                          Dashboard
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-about/${sid}/${eid}?type=event`}
                          className={
                            url === "event-about"
                              ? "link action ads"
                              : "link cc"
                          }
                        >
                          <span className="innerIcon">
                            <RxDragHandleDots2 />
                          </span>
                          About
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-media-gallery/${sid}/${eid}?type=event`}
                          className={
                            url === "event-media-gallery"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <FaImage />
                          </span>
                          Media Gallery
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-keepsake-album/${sid}/${eid}?type=event`}
                          className={
                            url === "event-keepsake-album"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={KeepshakeIcon} alt="" />
                          </span>
                          Keepsake Album
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/events-honored-guest-list/${sid}/${eid}?type=event`}
                          className={
                            url === "events-honored-guest-list"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={HonorGuestIcon} alt="" />
                          </span>
                          Honored Guests
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-file/${sid}/${eid}?type=event`}
                          className={
                            url === "event-file" ? "link action" : "link"
                          }
                        >
                          <span className="innerIcon">
                            <MdTextSnippet />
                          </span>
                          Files
                        </Link>
                      </li>
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <BsFillChatDotsFill />
                          </span>
                          Message
                        </Link>
                      </li>
                      <li
                        className="listItem"

                      >
                        <Link to="#" className="link" onClick={() => setModalShowDelete(true)}>
                          <span className="innerIcon">
                            <AiFillCloseCircle />
                          </span>
                          Delete Event
                        </Link>

                        <Eventdelete
                          displayModal={modalShowDelete}
                          closeModal={() => setModalShowDelete(false)}
                          name={common?.singleEvent?.event_name}
                          id={params?.sid}
                          event_date={common?.singleEvent?.event_start_date}
                          event_count=""
                          simag={
                            event_img
                              ? event_img
                              : "/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg"
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem isActive={type === "geospace" ? true : false}>
                <AccordionHeader
                  className="accordionMneuHead "
                  onClick={() => {
                    sessionStorage.removeItem("detailsOfUser");
                  }}
                >
                  <h3 className={`accordion-title`}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Geospace
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>

                <AccordionBody className="contentWrapper">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/add-geospace-list/${sid}/${eid}?type=geospace`}
                          className={
                            url === "add-geospace-list" ? "link action" : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={GeospaceIcon} alt="" />
                          </span>
                          Manage Geospace
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem isActive={type === "guests" ? true : false}>
                <AccordionHeader
                  className="accordionMneuHead"
                  onClick={() => {
                    sessionStorage.removeItem("detailsOfUser");
                  }}
                >
                  <Link
                    to={`/admin/event-user-guest-list/${sid}/${eid}/?type=guests`}
                  >
                    <h3 className={`accordion-title`}>
                      <span className="circle">
                        <FaCircle />
                      </span>
                      Guests
                    </h3>
                  </Link>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>

                <AccordionBody className="contentWrapper">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li
                        className="listItem"
                        style={
                          url === "edit-user-guest-list" ||
                            (url === "event-guest-authentication" &&
                              id !== undefined &&
                              id !== "")
                            ? { display: "none" }
                            : {}
                        }
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-user-guest-list/${sid}/${eid}/?type=guests`}
                          className={
                            url === "event-user-guest-list"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <MdOutlinePeopleAlt size={20} />
                          </span>
                          Guest List
                        </Link>
                      </li>

                      {storedObj?.type === "GuestDetails" && (
                        <li className="listItem">
                          <AccordionItem isActive={true}>
                            <AccordionHeader className="accordionSubMneuHead">
                              <Link
                                to={`/admin/event-user-guest-list/${sid}/${eid}/?type=guests`}
                                className={
                                  url === "edit-user-guest-list" ? "link" : ""
                                }
                                onClick={() => {
                                  sessionStorage.removeItem("detailsOfUser");
                                }}
                              >
                                <h3 className={`accordion-title`}>
                                  <span className="subMenuInner active">
                                    <span className="innerIcon">
                                      <MdOutlinePeopleAlt size={20} />
                                    </span>
                                    Guest List
                                  </span>
                                </h3>
                              </Link>
                            </AccordionHeader>

                            <AccordionBody className="contentWrapper">
                              <div className="accordion-body">
                                <ul className="subListMenuWrapper">
                                  <li className="listItem">
                                    <Accordion
                                      className="ultraSubMenu"
                                      preExpanded={true}
                                    >
                                      <AccordionItem isActive={true}>
                                        <AccordionHeader className="accordionSubMneuHead">
                                          <h3 className={`accordion-title`}>
                                            <span className="subMenuInner active">
                                              <span className="innerIcon">
                                                <FaUserAlt />
                                              </span>
                                              {storedObj?.first_name
                                                ? storedObj?.first_name
                                                : "Guest Name"}
                                            </span>
                                          </h3>
                                        </AccordionHeader>

                                        <AccordionBody className="contentWrapper">
                                          <div className="accordion-body">
                                            <ul className="subListMenuWrapper">
                                              <li className="listItem">
                                                <Link
                                                  to={`/admin/edit-user-guest-list/${sid}/${eid}/${id}?type=guests`}
                                                  className={
                                                    url ===
                                                      "edit-user-guest-list"
                                                      ? "link action"
                                                      : "link"
                                                  }
                                                >
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Details
                                                </Link>
                                              </li>
                                              <li className="listItem">
                                                <Link
                                                  to={`/admin/guest-keepsake-album/${sid}/${eid}/${id}?type=guests`}
                                                  className={
                                                    url ===
                                                      "guest-keepsake-album"
                                                      ? "link action"
                                                      : "link"
                                                  }
                                                >
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Keepsake Album
                                                </Link>
                                              </li>
                                              <li className="listItem">
                                                <Link
                                                  to={`/admin/event-guest-authentication/${sid}/${eid}/${id}?type=guests`}
                                                  className={
                                                    url ===
                                                      "event-guest-authentication"
                                                      ? "link action"
                                                      : "link"
                                                  }
                                                >
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Authentication
                                                </Link>
                                              </li>
                                              <li className="listItem">
                                                <Link
                                                  to="#"
                                                  className="link"
                                                  onClick={
                                                    handleDeleteGuestUser
                                                  }
                                                >
                                                  <span className="innerIcon">
                                                    <img
                                                      alt="edit icon"
                                                      src={
                                                        require("assets/img/delete-list-icon.svg")
                                                          .default
                                                      }
                                                      width={10}
                                                      height={10}
                                                      className="pointer"
                                                    />
                                                  </span>
                                                  Delete Guest
                                                </Link>
                                              </li>
                                            </ul>
                                            {deleteGuestUser && (
                                              <DeleteGuestSingle
                                                show={deleteGuestUser}
                                                onHide={handleDeleteGuestUser}
                                                single={guestData}
                                                urlRedirect={true}
                                              />
                                            )}
                                          </div>
                                        </AccordionBody>
                                      </AccordionItem>
                                    </Accordion>
                                  </li>
                                </ul>
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        </li>
                      )}

                      <li
                        className="listItem"
                        style={
                          url === "edir-guest-group" &&
                            id !== undefined &&
                            id !== ""
                            ? { display: "none" }
                            : {}
                        }
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-guest-group-list/${sid}/${eid}?type=guests`}
                          className={
                            url === "event-guest-group-list"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={GroupIcon} alt="" />
                          </span>
                          Guest Groups
                        </Link>
                      </li>

                      {storedObj?.type === "GuestGroups" && (
                        <li className="listItem">
                          <AccordionItem isActive={true}>
                            <AccordionHeader
                              className="accordionSubMneuHead"
                              onClick={() => {
                                sessionStorage.removeItem("detailsOfUser");
                              }}
                            >
                              <Link
                                to={`/admin/event-guest-group-list/${sid}/${eid}/${id}?type=guests`}
                                className={
                                  url === "event-guest-group-list" ? "link" : ""
                                }
                              >
                                <h3 className={`accordion-title`}>
                                  <span className="subMenuInner active">
                                    <span className="innerIcon">
                                      <img src={GroupIcon} alt="" />
                                    </span>
                                    Guest Groups
                                  </span>
                                </h3>
                              </Link>
                            </AccordionHeader>

                            <AccordionBody className="contentWrapper">
                              <div className="accordion-body">
                                <ul className="subListMenuWrapper">
                                  <li className="listItem">
                                    <Accordion
                                      className="ultraSubMenu"
                                      preExpanded={true}
                                    >
                                      <AccordionItem isActive={true}>
                                        <AccordionHeader className="accordionSubMneuHead">
                                          <h3 className={`accordion-title`}>
                                            <span className="subMenuInner active">
                                              <span className="innerIcon">
                                                <FaUserAlt />
                                              </span>
                                              {storedObj?.group_name
                                                ? storedObj?.group_name
                                                : " Group Name"}
                                            </span>
                                          </h3>
                                        </AccordionHeader>

                                        <AccordionBody className="contentWrapper">
                                          <div className="accordion-body">
                                            <ul className="subListMenuWrapper">
                                              <li className="listItem">
                                                <Link
                                                  to={`/admin/edir-guest-group/${sid}/${eid}/${id}?type=guests`}
                                                  className="link active"
                                                >
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Details
                                                </Link>
                                              </li>
                                              <li
                                                className="listItem"
                                                onClick={handleDeleteGroupUser}
                                              >
                                                <Link to="#" className="link">
                                                  <span className="innerIcon">
                                                    <img
                                                      alt="edit icon"
                                                      src={
                                                        require("assets/img/delete-list-icon.svg")
                                                          .default
                                                      }
                                                      width={10}
                                                      height={10}
                                                      className="pointer"
                                                    />
                                                  </span>
                                                  Delete Group
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </AccordionBody>
                                      </AccordionItem>
                                    </Accordion>
                                  </li>
                                  {deleteGroupUser && (
                                    <DeleteGroup
                                      show={deleteGroupUser}
                                      onHide={handleDeleteGroupUser}
                                      single={guestData}
                                      urlRedirect={true}
                                    />
                                  )}
                                </ul>
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        </li>
                      )}
                      {/* <li
                        className="listItem"
                        onClick={() => {
                          setModalShow(true);
                          sessionStorage.removeItem("detailsOfUser");
                        }}

                      >
                        <button className="link">
                          <span className="innerIcon">
                            <BiCloudUpload />
                          </span>
                          Upload Guest List
                        </button>
                      </li> */}
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/upload-guest-list/${sid}/${eid}?type=guests`}
                          className={
                            url === "upload-guest-list" ? "link action" : "link"
                          }
                        >
                          <span className="innerIcon">
                            <BiCloudUpload />
                          </span>
                          Upload Guest List
                        </Link>
                      </li>
                      {modalShow ? (
                        <UploadSelectType
                          selectType={modalShow}
                          handleSelectType={handleClickimag}
                        />
                      ) : (
                        ""
                      )}

                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to="#"
                          className="link"
                          onClick={handleDeleteGuestUserex}
                        >
                          <span className="innerIcon">
                            <FaRegArrowAltCircleDown />
                          </span>
                          Export Guest List
                        </Link>
                      </li>
                      {exportGuestlist && (
                        <ExportGuestList
                          show={exportGuestlist}
                          onHide={handleDeleteGuestUserex}
                        />
                      )}
                      <li className="listItem" onClick={handleDeleteGuestList}>
                        <div className="link">
                          <span className="innerIcon">
                            <AiFillCloseCircle />
                          </span>
                          Delete Guest List
                        </div>
                        <DeleteGuestList
                          show={deleteGuestList}
                          onHide={handleDeleteGuestList}
                        />
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem isActive={type === "invites-rsvps" ? true : false}>
                <AccordionHeader className="accordionMneuHead">
                  <h3
                    className={`accordion-title`}
                    onClick={() => {
                      sessionStorage.removeItem("detailsOfUser");
                    }}
                  >
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Invites and RSVPs
                  </h3>

                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>

                <AccordionBody className="contentWrapper">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li
                        className="listItem"
                        style={
                          id !== undefined && id !== ""
                            ? { display: "none" }
                            : {}
                        }
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/manage-invites/${sid}/${eid}/?type=invites-rsvps`}
                          className={
                            url === "manage-invites" ? "link action" : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/heartMailIcon.svg").default
                              }
                              width={20}
                              height={20}
                              className="pointer"
                            />
                          </span>
                          Manage Invites
                        </Link>
                      </li>
                      {id !== undefined && id !== "" && (
                        <li
                          className="listItem"
                          onClick={() => {
                            sessionStorage.removeItem("detailsOfUser");
                          }}
                        >
                          <AccordionItem isActive={true}>
                            <AccordionHeader className="accordionSubMneuHead">
                              <Link
                                to={`/admin/manage-invites/${sid}/${eid}/?type=invites-rsvps`}
                                className={
                                  url === "manage-invites-edit" ? "link" : ""
                                }
                              >
                                <h3 className={`accordion-title`}>
                                  <span className="subMenuInner active">
                                    <span className="innerIcon">
                                      <img
                                        alt="edit icon"
                                        src={
                                          require("assets/img/heartMailIcon.svg")
                                            .default
                                        }
                                        width={20}
                                        height={20}
                                        className="pointer"
                                      />
                                    </span>
                                    Manage Invites
                                  </span>
                                </h3>
                              </Link>
                            </AccordionHeader>

                            <AccordionBody className="contentWrapper">
                              <div className="accordion-body">
                                <ul className="subListMenuWrapper">
                                  <li className="listItem">
                                    <Accordion
                                      className="ultraSubMenu"
                                      preExpanded={true}
                                    >
                                      <AccordionItem isActive={true}>
                                        <AccordionHeader className="accordionSubMneuHead">
                                          <h3 className={`accordion-title`}>
                                            <span className="subMenuInner active">
                                              <span className="innerIcon">
                                                <img
                                                  alt="edit icon"
                                                  src={
                                                    require("assets/img/heartMailIcon.svg")
                                                      .default
                                                  }
                                                  width={20}
                                                  height={20}
                                                  className="pointer"
                                                />
                                              </span>
                                              {getInviteName
                                                ? getInviteName
                                                : "Invite Name"}
                                            </span>
                                          </h3>
                                        </AccordionHeader>

                                        <AccordionBody className="contentWrapper">
                                          <div className="accordion-body">
                                            <ul className="subListMenuWrapper">
                                              <li className="listItem">
                                                <Link
                                                  to={`/admin/manage-invites-edit/${sid}/${eid}/${id}?type=invites-rsvps`}
                                                  className={
                                                    url ===
                                                      "manage-invites-edit"
                                                      ? "link action"
                                                      : "link"
                                                  }
                                                >
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Details
                                                </Link>
                                              </li>
                                              <li
                                                className="listItem"
                                                onClick={() =>
                                                  props.handleInviteTemplate()
                                                }
                                              >
                                                <Link to="#" className="link">
                                                  <span className="innerIcon1">
                                                    <AiTwotoneCheckCircle />
                                                  </span>
                                                  Preview
                                                </Link>
                                              </li>
                                              <li
                                                className="listItem"
                                                onClick={() =>
                                                  props.handleDeleteGuestUser()
                                                }
                                              >
                                                <Link to="#" className="link">
                                                  <span className="innerIcon">
                                                    <img
                                                      alt="edit icon"
                                                      src={
                                                        require("assets/img/delete-list-icon.svg")
                                                          .default
                                                      }
                                                      width={10}
                                                      height={10}
                                                      className="pointer"
                                                    />
                                                  </span>
                                                  Delete
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </AccordionBody>
                                      </AccordionItem>
                                    </Accordion>
                                  </li>
                                </ul>
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        </li>
                      )}
                      <li
                        className="listItem"
                        onClick={() => {
                          sessionStorage.removeItem("detailsOfUser");
                        }}
                      >
                        <Link
                          to={`/admin/event-invite-rsvps/${sid}/${eid}?type=invites-rsvps`}
                          className={
                            url === "event-invite-rsvps"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <FaStar />
                          </span>
                          See RSVPs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem>

              {/* <AccordionItem isActive={type === "invites-rsvps" ? true : false}>
                <AccordionHeader className="accordionMneuHead">
                  <h3 className={`accordion-title`}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Invites and RSVPs
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>

                <AccordionBody className="contentWrapper">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li className="listItem">
                        <Link
                          to={`/admin/manage-invites/${sid}/${eid}?type=invites-rsvps`}
                          className={
                            url === "manage-invites" ? "link action" : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/heartMailIcon.svg").default
                              }
                              width={10}
                              height={10}
                              className="pointer"
                            />
                          </span>
                          Manage Invites
                        </Link>
                      </li>

                      <li className="listItem">
                        <AccordionItem isActive={true}>
                          <AccordionHeader className="accordionSubMneuHead">
                            <Link
                              to={`/admin/manage-invites/${sid}/${eid}/${id}?type=invites-rsvps`}
                              className={
                                url === "manage-invites" ? "link" : "link"
                              }
                            >
                              <h3 className={`accordion-title`}>
                                <span className="subMenuInner active">
                                  <span className="innerIcon">
                                    <img
                                      alt="edit icon"
                                      src={
                                        require("assets/img/heartMailIcon.svg")
                                          .default
                                      }
                                      width={10}
                                      height={10}
                                      className="pointer"
                                    />
                                  </span>
                                  Manage Invites
                                </span>
                              </h3>
                            </Link>
                          </AccordionHeader>

                          <AccordionBody className="contentWrapper">
                            <div className="accordion-body">
                              <ul className="subListMenuWrapper">
                                <li className="listItem">
                                  <Accordion
                                    className="ultraSubMenu"
                                    preExpanded={true}
                                  >
                                    <AccordionItem isActive={true}>
                                      <AccordionHeader className="accordionSubMneuHead">
                                        <h3 className={`accordion-title`}>
                                          <span className="subMenuInner active">
                                            <span className="innerIcon">
                                              <img
                                                alt="edit icon"
                                                src={
                                                  require("assets/img/heartMailIcon.svg")
                                                    .default
                                                }
                                                width={10}
                                                height={10}
                                                className="pointer"
                                              />
                                            </span>
                                            Invite Name
                                          </span>
                                        </h3>
                                      </AccordionHeader>

                                      <AccordionBody className="contentWrapper">
                                        <div className="accordion-body">
                                          <ul className="subListMenuWrapper">
                                            <li className="listItem">
                                              <Link
                                                to={`/admin/manage-invites-edit/${sid}/${eid}/${id}?type=invites-rsvps`}
                                                className="link active"
                                              >
                                                <span className="innerIcon1">
                                                  <AiTwotoneCheckCircle />
                                                </span>
                                                Details
                                              </Link>
                                            </li>
                                            <li className="listItem">
                                              <Link to="#" className="link">
                                                <span className="innerIcon1">
                                                  <AiTwotoneCheckCircle />
                                                </span>
                                                Preview
                                              </Link>
                                            </li>

                                            <li className="listItem">
                                              <Link to="#" className="link">
                                                <span className="innerIcon">
                                                  <img
                                                    alt="edit icon"
                                                    src={
                                                      require("assets/img/delete-list-icon.svg")
                                                        .default
                                                    }
                                                    width={10}
                                                    height={10}
                                                    className="pointer"
                                                  />
                                                </span>
                                                Delete
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>
                                </li>
                              </ul>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </li>

                      <li className="listItem">
                        <Link
                          to={`/admin/event-invite-rsvps/${sid}/${eid}?type=invites-rsvps`}
                          className={
                            url === "event-invite-rsvps"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <FaStar />
                          </span>
                          See RSVPs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem> */}

              {/* <AccordionItem isActive={type === "message" ? true : false}>
                <AccordionHeader className="accordionMneuHead ">
                  <h3 className={`accordion-title `}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Message
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>
                <AccordionBody className="contentWrapper active">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/broadcast-icon.svg").default
                              }
                              width={18}
                              height={18}
                              className="pointer"
                            />
                          </span>
                          Broadcast
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <BsFillChatDotsFill />
                          </span>
                          Chat With a Guest
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem> */}

              {/* <AccordionItem isActive={type === "todos" ? true : false}>
                <AccordionHeader className="accordionMneuHead ">
                  <h3 className={`accordion-title `}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Todos
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>
                <AccordionBody className="contentWrapper active">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                           <img
                              alt="edit icon"
                              src={
                                require("assets/img/todo-checklist-icon.svg")
                                  .default
                              }
                              width={18}
                              height={18}
                              className="pointer"
                            />
                            <BiListUl />
                          </span>
                          Todo Checklist
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem> */}

              {/* <AccordionItem isActive={type === "engine" ? true : false}>
                <AccordionHeader className="accordionMneuHead ">
                  <h3 className={`accordion-title `}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    Style Engine
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>
                <AccordionBody className="contentWrapper active">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <FaHeart />
                          </span>
                          Current Styles
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <FaSearch />
                          </span>
                          Explore Styles
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem> */}

              {/* <AccordionItem isActive={type === "su" ? true : false}>
                <AccordionHeader className="accordionMneuHead ">
                  <h3 className={`accordion-title `}>
                    <span className="circle">
                      <FaCircle />
                    </span>
                    SU
                  </h3>
                  <FaChevronRight className="arrowIcon" />
                </AccordionHeader>
                <AccordionBody className="contentWrapper active">
                  <div className="accordion-body">
                    <ul className="subListMenuWrapper">
                      <li className="listItem">
                        <Link
                          to={`/admin/event-dashboard/${sid}/${eid}`}
                          className="link"
                        >
                          <span className="innerIcon">
                            <BiCloudUpload />
                          </span>
                          Imported Guest Lists
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link
                          to={`/admin/event-about/${sid}/${eid}?type=event`}
                          className={
                            url === "event-about"
                              ? "link action ads"
                              : "link cc"
                          }
                        >
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/event-home-icon.svg")
                                  .default
                              }
                              width={18}
                              height={18}
                              className="pointer"
                            />
                          </span>
                          Events
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link
                          to={`/admin/event-media-gallery/${sid}/${eid}?type=event`}
                          className={
                            url === "event-media-gallery"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={GeospaceIcon} alt="" />
                          </span>
                          Geospaces
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link
                          to={`/admin/event-keepsake-album/${sid}/${eid}?type=event`}
                          className={
                            url === "event-keepsake-album"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <MdOutlineGroups size={20} />
                          </span>
                          Guests
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link
                          to={`/admin/events-honored-guest-list/${sid}/${eid}?type=event`}
                          className={
                            url === "events-honored-guest-list"
                              ? "link action"
                              : "link"
                          }
                        >
                          <span className="innerIcon">
                            <img src={GroupIcon} alt="" />
                          </span>
                          Groups
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <BsShareFill />
                          </span>
                          Relationship Engine
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <FaSearch />
                          </span>
                          Vendors
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <BsFillChatDotsFill />
                          </span>
                          Chat
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/guest-authentication-icon.svg")
                                  .default
                              }
                              width={18}
                              height={18}
                              className="pointer"
                            />
                          </span>
                          Guest Authentication
                        </Link>
                      </li>
                      <li className="listItem">
                        <Link to="#" className="link">
                          <span className="innerIcon">
                            <img
                              alt="edit icon"
                              src={
                                require("assets/img/troubleshoot-icon.svg")
                                  .default
                              }
                              width={18}
                              height={18}
                              className="pointer"
                            />
                          </span>
                          Troubleshoot
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionBody>
              </AccordionItem> */}
            </Accordion>
          </div>

          <UploadSelectType />
        </div>
      </div>
    </>
  );
}
