import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const informationService = {
    acInformation,
    acInformationupdate,
    sendInvite,
    listInvite,
    logOut,
    changePassword,
    profileUpdate,
    organizationImage,
    everyDevicelogout,
    getCountries,
    createOrUpdatesetting,
    getsetting,
    storyzSave,
    storyzUpdate,
    organizationUpdate,
    projectStory,
    eventList,
    calendarList,
    latestprojectId,
    storyzDetails,
    projectSearch,
    eventType,
    eventAdd,
    eventGetMediaGallertList,
    deleteEventImg,
    deleteStoryzImg,
    saveGallery,
    updateGallery,
    saveEventGallery,
    saveGalleryWithFileStack,
    updateEventGallery,
    createOrUpdaTimezone,
    createOrUpdaClockformat,
    createOrUpdateUnits,
    getMediaGallery,
    countEventStory,
};

async function storyzDetails(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/project/storyz/details`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function projectSearch(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/project/search`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function latestprojectId() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(
        Global.BASE_API_PATH + `/project/latestProjectId`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function projectStory() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/project/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function eventList(id) {
    const data = {
        project_id: id ? id : "",
    };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/event/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function calendarList() {
    const data = {
        project_id: 0,
        org_id: 0,
        event_id: 0,
    };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/event/calendarlist`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function logOut() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    return fetch(
        Global.BASE_API_PATH + `/auth/logout-from-device`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
function getCountries() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/getCountries`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function eventAdd(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    console.log(requestOptions);

    return fetch(Global.BASE_API_PATH + `/event_type/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
function eventType() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/event_type/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function everyDevicelogout() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };

    return fetch(Global.BASE_API_PATH + `/auth/logout`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listInvite() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/invite/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function profileUpdate(name) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: name,
    };

    return fetch(Global.BASE_API_PATH + `/profile/upload`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function organizationImage(name) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: name,
    };
    return fetch(Global.BASE_API_PATH + `/organization/saveLogo`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function acInformation() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    //console.log(requestOptions);
    return fetch(Global.BASE_API_PATH + `/profile/detail`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getsetting() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
    };
    //console.log(requestOptions);
    return fetch(Global.BASE_API_PATH + `/setting/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function createOrUpdatesetting(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/setting/createOrUpdate`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function createOrUpdaTimezone(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/setting/createOrUpdatetime`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function createOrUpdaClockformat(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/setting/createOrUpdateclock`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function createOrUpdateUnits(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/setting/createOrUpdateUnits`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function changePassword(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/profile/changePassword`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function acInformationupdate(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/profile/update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function sendInvite(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/invite`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function organizationUpdate(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(
        Global.BASE_API_PATH + `/organization/createUpdate`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function storyzUpdate(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: formdata,
    };

    return fetch(Global.BASE_API_PATH + `/project/update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function storyzSave(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: formdata,
    };

    return fetch(Global.BASE_API_PATH + `/project/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
async function eventGetMediaGallertList(eid) {
    const data = { event_id: eid };

    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/gallery/event-list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getMediaGallery() {
    let data = {};
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/gallery/getmedia-gallery`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function countEventStory(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/gallery/count-event-story`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteEventImg(id) {
    const data = { id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/gallery/delete-event`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteStoryzImg(id) {
    const data = { id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/gallery/delete-storyz`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function saveGallery(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(Global.BASE_API_PATH + `/gallery/create-storyz`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateGallery(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };

    return fetch(Global.BASE_API_PATH + `/gallery/update-storyz`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function saveEventGallery(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(Global.BASE_API_PATH + `/gallery/create-event`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function saveGalleryWithFileStack(data) {
    console.log("saveGalleryWithFileStack", JSON.stringify(data));
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/gallery/saveGalleryWithFileStack`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateEventGalleryOld(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(Global.BASE_API_PATH + `/gallery/update-event`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateEventGallery(data) {
    //console.log('updateEventGallery', JSON.stringify(data));
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/gallery/update-event`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
