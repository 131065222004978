import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const informationService = {
  acInformation,
  acInformationadmin,
  acInformationupdate,
  sendInvite,
  listInvite,
  logOut,
  changePassword,
  profileUpdate,
  organizationImage,
  organizationCover,
  everyDevicelogout,
  getCountries,
  createOrUpdatesetting,
  getsetting,
  storyzSave,
  storyzUpdate,
  organizationUpdate,
  projectStory,
  eventList,
  searcheventList,
  storyzCalendarList,
  calendarList,
  latestprojectId,
  storyzDetails,
  projectSearch,
  eventType,
  eventAdd,
  getMediaGallertList,
  deleteImg,
  saveGallery,
  updateGallery,
  createOrUpdaTimezone,
  createOrUpdaClockformat,
  storyDelete,
  createOrUpdateUnits,
  deleteStoryzImg,
  softDelete,
  timelineList,
  getAllmedia,
  deleteEvent,
  getMediaGallery,
  countEventStory,
  eventListId,
  updateOrgLogo,
  updateCoverImage,
  allMedia,
  updateEventCoverImage,
  updateprojectUpdateimage,
  updateTwoFactorAuthentication,
  acInformationadminserach,
  autoLogin,
  acceptList,
  saveStoryzData,
  getShortStoryzDetails,
  getWebList,
  acInformationAdmin,
  paymentList,
  updateSocialmedia,
  storyzAutoSave,
  saveGalleryWithFileStack,
  saveText,
  saveSettingStoryz,
  saveSettingEvent,
  honoredGuestsDashboard,
  Guestupdate,
  removeSettingImgs,
  removeSettingImgev,
  ContinueAccount
};

async function honoredGuestsDashboard(sid) {
  let data = { storyz_id: sid };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/project/honored-guests-dashboard`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function saveSettingStoryz(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/project/saveSetting`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function removeSettingImgs(id) {
  let data = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/project/remove-setting-img`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function removeSettingImgev(id) {
  let data = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/event/remove-setting-img`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveSettingEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/event/saveSetting`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveText(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/keepsakealbum/save-text`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveGalleryWithFileStack(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/gallery/saveGalleryWithFileStack`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function storyzAutoSave(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/project/storyz-update`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function updateSocialmedia(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/guest/update-social-media`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function Guestupdate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/guest/update`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function paymentList() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/payment/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveStoryzData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/project/add-web-listing`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getShortStoryzDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/project/short-storyz-details`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getWebList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/project/get-web-list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateprojectUpdateimage(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/project/update-image`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateEventCoverImage(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event/update-image`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function autoLogin(data) {
  var d2 = { login_id: data };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(d2),
  };

  // return fetch(Global.BASE_API_PATH + `/organization/autoLogin`, requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));
  return fetch(Global.BASE_API_PATH + `/organization/autoLogin`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateTwoFactorAuthentication(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/update-two-factor-authentication`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function eventListId(id) {
  const data = {
    project_id: id,
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}





async function deleteEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event/delete`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function ContinueAccount(id) {
  const data1 = { profile_id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data1),
  };

  return fetch(Global.BASE_API_PATH + `/profile/restoreAccount`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function softDelete(id) {
  const data1 = { profile_id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data1),
  };

  return fetch(Global.BASE_API_PATH + `/profile/softDelete`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function storyDelete(storyzId) {
  const data1 = { id: storyzId };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data1),
  };

  return fetch(Global.BASE_API_PATH + `/project/delete`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateOrgLogo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(
    Global.BASE_API_PATH + `/organization/update-org-logo`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function updateCoverImage(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(
    Global.BASE_API_PATH + `/organization/update-cover-image`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function storyzDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/project/storyz/details`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function projectSearch(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/project/search`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function latestprojectId() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(false),

  };
  return fetch(Global.BASE_API_PATH + `/project/latestProjectId`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

async function allMedia() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/all-media`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function countEventStory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/gallery/count-event-story`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteStoryzImg(id) {
  const data = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/gallery/delete-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function projectStory() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/project/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getAllmedia() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  console.log(requestOptions);
  return fetch(Global.BASE_API_PATH + `/getmedia`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function eventList(id) {
  const data = {
    project_id: id ? id : "",
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function searcheventList(id, val) {
  const data = { project_id: id ? id : "", event_name: val };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event/search-event-list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function calendarList(data) {

  const requestOptions = {
      method: "POST",
      headers: authHeader(false),
      body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/event/calendarlist`, requestOptions)
      .then(handleResponse)
      .then((res) => {
          return res;
      });
}


async function storyzCalendarList() {
  const data = {
    project_id: 0,
    org_id: 0,
    event_id: 0,
  };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/project/calendarlist`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function logOut(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(
    Global.BASE_API_PATH + `/auth/logout`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/getCountries`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function eventAdd(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/event_type/create`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function eventType() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/event_type/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function everyDevicelogout(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/auth/logout-everywhere`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function acceptList() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/invite/accept-list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function listInvite() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
  };
  return fetch(Global.BASE_API_PATH + `/invite/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function profileUpdate(name) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: name,
  };

  return fetch(Global.BASE_API_PATH + `/profile/upload`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function organizationCover(name) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: name,
  };
  return fetch(
    Global.BASE_API_PATH + `/organization/updateCoverImage`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function organizationImage(name) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    body: name,
  };
  return fetch(Global.BASE_API_PATH + `/organization/saveLogo`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function acInformationAdmin(id) {
  const data = { user_id: id };

  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  //console.log(requestOptions);
  return fetch(Global.BASE_API_PATH + `/get-user-info`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function acInformation() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  //console.log(requestOptions);
  return fetch(Global.BASE_API_PATH + `/profile/detail`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function acInformationadmin() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };

  //console.log(requestOptions);
  return fetch(Global.BASE_API_PATH + `/organization/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error", error));
  // return fetch(Global.BASE_API_PATH + `/organization/list`, requestOptions)
  //   .then(handleResponse)
  //   .then((res) => {
  //     return res;
  //   });
}
async function acInformationadminserach(token, keydata) {
  var myHeaders = new Headers();
  myHeaders.append("token", "b2f8b954-60cd-4ead-aa93-003015a809ab");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    org_name: "n",
  });

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(Global.BASE_API_PATH + `/organization/list`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  // return fetch(Global.BASE_API_PATH + `/organization/list`, requestOptions)
  //   .then(handleResponse)
  //   .then((res) => {
  //     return res;
  //   });
}

async function getsetting() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(false),
  };
  //console.log(requestOptions);
  return fetch(Global.BASE_API_PATH + `/setting/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function createOrUpdatesetting(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/setting/createOrUpdate`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function createOrUpdaTimezone(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/setting/createOrUpdatetime`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function createOrUpdaClockformat(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/setting/createOrUpdateclock`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function createOrUpdateUnits(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/setting/createOrUpdateUnits`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/profile/changePassword`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function acInformationupdate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/profile/update`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function sendInvite(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/invite`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function organizationUpdate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: data,
  };
  return fetch(
    Global.BASE_API_PATH + `/organization/createUpdate`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// async function deleteStoryzImg(id) {
//   const data = { id: id };
//   const requestOptions = {
//     method: "POST",
//     headers: authHeader(false),
//     body: JSON.stringify(data),
//   };

//   return fetch(Global.BASE_API_PATH + `/gallery/delete-storyz`, requestOptions)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     });
// }

async function storyzUpdate(formdata) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: formdata,
  };

  return fetch(Global.BASE_API_PATH + `/project/update`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function storyzSave(formdata) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: formdata,
  };

  return fetch(Global.BASE_API_PATH + `/project/create`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getMediaGallertList(id) {
  const data = { story_id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/gallery/storyz-list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteImg(id) {
  const data = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };

  return fetch(Global.BASE_API_PATH + `/gallery/delete-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveGalleryOld(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: data,
  };
  return fetch(Global.BASE_API_PATH + `/gallery/create-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function saveGallery(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/gallery/create-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateGalleryOld(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(true),
    mimeType: "multipart/form-data",
    body: data,
  };
  return fetch(Global.BASE_API_PATH + `/gallery/update-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateGallery(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/gallery/update-storyz`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function timelineList(id) {
  const data = { storyz_id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/timeline/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getMediaGallery() {
  let data = {};
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/gallery/getmedia-gallery`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function handleResponse(response) {
  if (response.ok === false) {
    if (response.statusText === "Unauthorized") {
      console.log(response.statusText);
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userdata");
      window.location = "/";
    }
  } else {
    return response.text().then((text) => {
      const data = JSON.parse(text);

      if (!response.ok) {
        if (response.status === 401) {
          console.log(response);
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }
}
