import { ALL_FILES } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    allFiles: [],
};
function allFilesReducers(state = initialState, action) {
    switch (action.type) {
        case ALL_FILES:
            return {
                ...state,
                isConfirmatiom: true,
                allFiles: action.payload,
            };
        default:
            return state;
    }
}
export default allFilesReducers;
