import { MEDIA_LIST, CLEAR_MEDIA } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    media: [],
};
function mediaListReducers(state = initialState, action) {
    switch (action.type) {
        case MEDIA_LIST:
            return {
                ...state,
                isConfirmatiom: true,
                media: action.payload,
            };
        case CLEAR_MEDIA:
            // console.log(action.product_Id);
            // const newPeople = state.cart.filter(item => item.id !== action.product_Id);
            //console.log('remove');
            return {
                media: [],
            };
        default:
            return state;
    }
}
export default mediaListReducers;
