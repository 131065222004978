export default class Global {
    //static BASE_API_PATH = "http://localhost:8080/api";
    static BASE_API_PATH = "https://ourstoryzapi.avdemosites.com/api";
    // For Image Path
    static BASE_IMAGES_PATH = "https://ourstoryzapi.avdemosites.com/";
    static BASE_USER_IMAGES_PATH =
        "https://ourstoryzapi.avdemosites.com/profile-photo/";
    // static MAP_LIVE_KEY = "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY";
    static MAP_KEY_DEMO = "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY";
    static Files_Stack_Api_Key = "AJ2aCGNguSqOXgGfVQ9TQz";
}
