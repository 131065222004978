import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Allheader from "../../components/Headers/AllheaderEvent";
import { useHistory } from "react-router-dom";
import Imggroup from "../../assets/img/dummyImg.svg";
import Eventaboutedit from "../../components/Events/eventaboutedit";
import { useParams } from "react-router-dom";
import { eventsService } from "../../_services/masters/events.service";
import { informationService } from "../../_services/masters/information.service";
import HornIcon from "./../../assets/img/hornIcon.svg";
import moment from "moment";

function EventAboutedits() {
    useEffect(() => {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-show");
        document.body.classList.add("g-sidenav-hidden");
    }, []);
    const params = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const common = useSelector((state) => {
        return state.commonData.common;
    });
    const [eventList, seteventList] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState(0);
    const [eventId, setEventId] = React.useState(params.eid ? params.eid : "");
    const [eventData, seteventData] = React.useState();
    const onTabClick = (e, index) => {
        setActiveTab(index);
    };
    // useEffect(() => {
    //     document.body.classList.remove("g-sidenav-pinned");
    //     document.body.classList.remove("g-sidenav-show");
    //     document.body.classList.add("g-sidenav-hidden");
    // });
    React.useEffect(() => {
        informationService.eventList().then(
            (res) => {
                if (res.status === "success") {
                    seteventList(res.data);
                } else {
                    console.log("Something went wrong !!");
                    //toast.error(res.errors[0], "Fashion Store");
                }
            },
            (error) => {
                console.log("Something went wrong !!");
                //toast.error("Something went wrong !!", "Fashion Store");
            }
        );
    }, []);

    React.useEffect(() => {
        const id = { event_id: eventId };
        eventsService.eventDetails(id).then(
            (res) => {
                if (res.status === "success") {
                    seteventData(res.data);
                } else {
                    console.log("Something went wrong !!");
                    //toast.error(res.errors[0], "Fashion Store");
                }
            },
            (error) => {
                console.log("Something went wrong !!");
                //toast.error("Something went wrong !!", "Fashion Store");
            }
        );
    }, [eventId]);

    return (
        <>
            {/* <Allheader title={eventData?.storyz_name} imgScr={Imggroup} arw /> */}
            {/* <Allheader
          title={eventData.storyz_name ? eventData.storyz_name : "Storyz Name"}
          imgScr={eventData.project_image ? eventData.project_image : Imggroup}
          arw
        /> */}
            <Allheader
                title={
                    common?.singleEvent?.storyz_name
                        ? common?.singleEvent?.storyz_name
                        : "Storyz Name"
                }
                imgScr={
                    common?.singleEvent?.project_image
                        ? common?.singleEvent?.project_image
                        : Imggroup
                }
                arw
            />

            {/* <div className="card accountinformation ptop" style={{ marginBottom: '0px' }}>
                <div className='tabs' style={{ background: 'rgb(32, 83, 181)' }}>
                    <div className='helootabs' >
                        <Tabs activeTab={activeTab} onTabClick={onTabClick}

                            leftBtnIcon={<i className="ni ni-bold-left" style={{ color: '#fff' }}></i>}
                            rightBtnIcon={<i className="ni ni-bold-right" style={{ color: '#fff' }}></i>}

                        >
                            {eventList.map((item, i) => (

                                <Tab key={i}


                                    onClick={() => {
                                        history.push(`/admin/event-about/${item.project_id}/${item.id}`, undefined, { scroll: false })
                                        setEventId(item.id)
                                    }
                                    }
                                >

                                    <span style={{ fontSize: '12px', marginRight: '5px', color: '#fff' }}>{moment(item.event_start_date).format('MMM Do')}</span><a style={{ color: '#fff' }} key={i} id={item.id} disabled={activeTab === i} className={`tabsclassevent ${activeTab === i}`}>{item.event_name}</a>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div >
            </div> */}

            <div className="containerWraperOuter">
                <div className="card">
                    <Eventaboutedit eventData={eventData} />
                </div>
                <button type="button" className="delteEventBtn">
                    <img src={HornIcon} alt="" className="mr-2" />
                    Delete Event
                </button>
            </div>
        </>
    );
}
export default EventAboutedits;
