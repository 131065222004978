import React, { useState, useEffect, useRef } from "react";
//import FileStackApp from '../FileStackApp/FileStackAll';
import FileStackApp from "../FileStackApp/FileStackKeepsakeAlbum";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { ReactSession } from "react-client-session";
import placeholde from "../../assets/img/placeholder.jpg";
import { useSelector, useDispatch } from "react-redux";
function EditFilemanager({
  show,
  openEditFrom,
  storyz_id,
  event_id,
  onHide,
  list,
  seletedImage,
  setEditStore,
}) {
  const inputRef = useRef();
  const [imgModalBoxfirst, setImgModalBoxfirst] = useState(show);
  const [filestack, setFilestack] = useState([]);
  const [dataFile, setDataFile] = useState({});
  const [imgModalBox, setImgModalBoxF] = useState(false);
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );
  const [mapPopu, setMapPopu] = useState(false);
  const intialValues = {
    photo_label: "",
    photo_caption: "",
    imaDatajson: [],
    img_address: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [cur_section, setCurSection] = React.useState({});
  const [fileData, setFileData] = useState([]);
  const [photolabel, setPhotolabel] = useState("");
  const [Photocaption, setPhotocaption] = useState("");
  const [gallerylocation, setGallerylocation] = useState("");
  const [galleryEditlocation, setGalleryEditlocation] = useState("");

  const contact = useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
    //setMapkey('');
  }, []);
  const [mapModal, setMapModal] = useState(false);
  const handelMapModal = () => {
    setMapModal(!mapModal);
  };
  useEffect(() => {
    let root = document.getElementsByTagName("html")[0];
    root.classList.add("overflowHidden");
    return () => {
      root.classList.remove("overflowHidden");
    };
  }, [imgModalBox]);

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());

    }
  };

  const setdata = (data) => {
    data["type"] = "keepsakeAlbum";
    //setImgModalBoxfirst(false);
    onHide();
    setFileData(data);
    // setImgModalBox(true);
    //setImgModalBoxF(true)
    //console.log("data setdata", data);
  };

  const onEditChangePicture = (e) => {
    setImgModalBoxfirst(true);
    setImgModalBoxF(false);
  };

  const handleChange = (e) => {
    //console.log(e.target);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
  };

  useEffect(() => {
    //console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //console.log(formErrors);
    }
  }, []);

  const handleSubmitInformation = () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    //onClickimageUpdate(formValues);
    //setMapkey('');
    onHide();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.photo_label) {
      errors.photo_label = "Title is required!";
    }

    if (!values.photo_caption) {
      errors.photo_caption = "Caption is required!";
    }
    return errors;
  };

  const selectFilebtn = () => {
    let newcur_section = JSON.parse(JSON.stringify(cur_section));
    let selImg = JSON.parse(newcur_section.img_json);
    delete newcur_section.img_json;
    newcur_section["imaDatajson"] = selImg;

    setEditStore(newcur_section);
    seletedImage(selImg);
    onHide();
    //openEditFilemanager();
    openEditFrom(true);
    //setImgModalBoxF(true);
    //setImgModalBoxfirst(false);
    //setImgModalBoxfirst(true);
    //setImgModalBox(false)
  };

  return (
    <div>
      {imgModalBoxfirst && (
        <div
          className={`modal fade mediaFileListModal ${imgModalBoxfirst ? "show" : ""
            }`}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Files</h1>
                <div className="btn-close" onClick={onHide}>
                  <svg
                    className="closeBtn"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="importImageListWrapper">
                  <FileStackApp setFilestack={setFilestack} setdata={setdata}>
                    Import New
                  </FileStackApp>

                  <div className="importedImageListWrapper">
                    <div className="importedImageListInner">
                      {list.map((x, i) => (
                        <div
                          className={
                            x.id === cur_section.id
                              ? "importedImageList selected"
                              : "importedImageList"
                          }
                          key={i}
                          onClick={() => setCurSection(x)}
                        >
                          <div className="uploadedItem">
                            <div className="uploadedImg">
                              <img
                                src={x.url ? x.url : placeholde}
                                alt=""
                                className="thum"
                              />
                            </div>
                          </div>
                          <div className="filename">{x.filename}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="btn selectFilebtn" onClick={selectFilebtn}>
                  Select File
                </div>
                <div onClick={onHide} className="btn selectFilebtn whiteBg">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    // message prop requires a string
  );
}
export default EditFilemanager;
