import { useState, useEffect } from "react";
import { client } from "filestack-react";
import Global from "../../_helpers/global";

export default function FileStackApp(props) {
  // alert(props.type);
  const [file, setFile] = useState(null);
  const customSource = {
    label: "LABEL_IN_MENU",
    name: "CUSTOM_SOURCE_KEY_NAME",
    icon: "ICON.svg",
    mounted: (element, actions) => { },
    unmounted: (element) => { },
  };

  const filePickerOptions = {
    fromSources: [
      "local_file_system",
      customSource,
      "box",
      "facebook",
      "instagram",
      "dropbox",
      "googledrive",
      "googlephotos",
      //'googlegmail',
      //'googlesearch',
      "github",
      "onedrive",
      "unsplash",
    ],
    accept: ["image/*", "video/*"],
    // maxSize: 1024 * 1024 * 1024 * 1024 * 1024 * 1024 ,
    maxFiles: 1,
    //onFileUploadFinished: this.setcanvasBG,
    onUploadDone: (file) => {
      if (file != null && file != undefined && file != "") {
        localStorage.setItem("setFile", JSON.stringify(file));
        props.setdata(file.filesUploaded);
        // props.setFilestack(file.filesUploaded)
        if (props.type == "Add Event") {
          props.setImgModalBox(true);
        }
      }
      setFile(file);
    },
  };

  useEffect(() => {
    // send file to your custom server
    fetch("https://httpbin.org/post", {
      method: "POST",
      body: JSON.stringify(file),
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }, [file]);

  const handleFilePicker = () => {
    //alert("hi");
    const filestackApikey = "AOonJnnp3T2u4iDVOQDRzz";
    const filestack = client.init(filestackApikey, filePickerOptions);
    const picker = filestack.picker(filePickerOptions);
    picker.open();
  };

  return (
    <>
      <div
        className="importImageWrapper d-flex align-items-center flex-column"
        onClick={() => handleFilePicker()}
      >
        <img
          alt="Import New"
          src={require("assets/img/upload-icon.svg").default}
          className="importIcon mb-1"
        />
        Import File
      </div>
    </>
  );
}
