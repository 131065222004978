import React from "react";
import { Container } from "reactstrap";
function WebListing() {
    return (
        <>
            <Container>
                <h1 style={{ textAlign: "center", color: "#fff", padding: "25px" }}>
                    Web Listing
                </h1>
            </Container>
        </>
    );
}
export default WebListing;
