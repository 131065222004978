import React from "react";

const UserDetails = (props) => {
  return (
    <>
      <div className="keepsakeUser">
        <div className="keepsakeUserThum">
          <img
            src={
              props.guestProfile
                ? props.guestProfile
                : "https://images.unsplash.com/photo-1621621667797-e06afc217fb0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
            }
            alt={props.name}
            className="keepsakeUserThumImg"
          />
        </div>

        <div className="keepsakeUserThumContent">
          <div className="keepsakeUserthumbTitle">{props.name}</div>
          {props.role != "" ? (
            <div className="keepsakeUserthumbSubTitle">
              Role in {props.role}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="mb-4 textareaWrapper">
        <div className="form-label mb-3">
          Text introducing this event’s Keepsake Album to visitors:
        </div>
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          defaultValue={props.admin_guest_note}
        ></textarea>
      </div>
    </>
  );
};

export default UserDetails;
