/* global google */
const google = window.google = window.google ? window.google : {}


export const getElevation = location =>

  new Promise((resolve, reject) => {

    const elevationService = new google.maps.ElevationService()
    var location123 = new google.maps.LatLng(14.5084692510445, 35.8988013191481);
    const config = { locations: [location ? location : location123] }
    elevationService.getElevationForLocations(config, (result, status) => {
      if (status === google.maps.ElevationStatus.OK && result) {
        const [{ elevation }] = result
        resolve(elevation)
      } else {
        reject(status)
      }
    })
  })

export const getCurrentPosition = new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(async position => {
    try {
      const elevation = await getElevation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
      resolve({
        elevation: elevation,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      })
    } catch (error) {
      reject(error)
    }
  }, reject)
})
