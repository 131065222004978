import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const combinedService = {
  getCombinedlist,
  GuestcreateUpdate,
  guestGroupList,
  deleteguestgroup,
  autoUpdateGuest,
  groupDetails,
  deleteAllGuestGroup,
  mergeAllGuest,
  updateGuestEvent,
  guestDelete,
  getGuest,
  getGuestPool,
  addGuestEvent,
  getCSVData,
  getStoryz,
};

async function getStoryz(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/combined-guest/get-storyz`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function autoUpdateGuest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: data,
  };
  return fetch(
    Global.BASE_API_PATH + `/guest/auto-update-guest`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getCSVData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/combined-guest/get-csv-data`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getGuestPool(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/combined-guest/pool`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function addGuestEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/combined-guest/add-guest-event`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function groupDetails(id) {
  const vid = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(vid),
  };
  return fetch(Global.BASE_API_PATH + `/guest/group-details`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteAllGuestGroup(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(id),
  };
  return fetch(
    Global.BASE_API_PATH + `/guest/delete-all-guest-group`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function deleteguestgroup(id) {
  const vid = { group_id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(vid),
  };
  return fetch(
    Global.BASE_API_PATH + `/guest/delete-guest-group`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
async function guestGroupList(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(id),
  };
  return fetch(Global.BASE_API_PATH + `/guest/group-list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getGuest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/combined-guest/get-guest`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function guestDelete(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/combined-guest/delete`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function mergeAllGuest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(
    Global.BASE_API_PATH + `/combined-guest/merge-all`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function updateGuestEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/combined-guest/update`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function getCombinedlist(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: JSON.stringify(data),
  };
  return fetch(Global.BASE_API_PATH + `/combined-guest/list`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function GuestcreateUpdate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(false),
    body: data,
  };
  return fetch(Global.BASE_API_PATH + `/guest/createUpdate`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function handleResponse(response) {
  if (response.ok === false) {
    if (response.statusText === "Unauthorized") {
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userdata");
      window.location = "/";
    }
  } else {
    return response.text().then((text) => {
      const data = JSON.parse(text);

      if (!response.ok) {
        if (response.status === 401) {
          console.log(response);
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }
}
