import React from "react";

const ArrowDown = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 12L0.0717964 0L13.9282 0L7 12Z" fill="#1F1F1F" />
    </svg>
  );
};

export default ArrowDown;
