import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Progress } from "reactstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { ReactSession } from "react-client-session";
import { Link, useHistory } from "react-router-dom";
import StoryzThemeModal from "components/StoryzThemeModal";
import { useSelector, useDispatch } from "react-redux";
import { todoService } from "_services/masters/information.todos";
import { useParams } from "react-router-dom";
import EventTypeDropdown from "components/Dropdown/EventTypeDropdown";
import AboutExitarea from "../../components/Inputbox/aboutExitarea.js";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { informationService } from "../../_services/masters/information.service";
import { getTimeZone } from "../../CommonData";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import placeholde from "../../assets/img/placeholder.jpg";
import { commonData } from "store/actions";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { eventsService } from "../../_services/masters/events.service";
import "react-toastify/dist/ReactToastify.css";
import * as actionTypes from "../../store/actionTypes";

import EventBg from "./../../assets/img/storyBgNew.png";

function Index(props) {
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const [searchValue, setSearchValue] = useState("");

  const [eventType, setEventType] = React.useState([]);
  const [allimage, setAllimage] = useState([]);
  const searchRef = useRef();
  const [imgData, setImgData] = React.useState(null);
  const [imgDa, setImgDa] = React.useState(null);
  const [startDate, setStartDate] = React.useState("");
  const [endtDate, setEventEnds] = React.useState("");

  // console.log(".....", imgData);

  const [modalShow, setModalShow] = React.useState(false);
  const handleClick = () => {
    setModalShow(false);
  };
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";
    let event_id = common?.singleEvent?.id;
    const data = {
      type: "event",
      event_id: event_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateEventCoverImage(data).then(
      (res) => {
        if (res.status === "success") {
          setImgData(data.imaDatajson.url);

          console.log("data.imaDatajson.url", data.imaDatajson.url);
          setDataevent();

          toast.success(res.message, "Account");
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const { setValue } = useForm({});

  const seletedImageClike = (e) => {
    let event_id = common?.singleEvent?.id;
    const val1 = [
      {
        id: event_id,
        key_name: "cover_image",
        key_value: e.url,
      },
    ];

    userGuestService.eventUpdate(val1).then((res) => {
      if (res.status === "success") {
        toast.success("update successfully !!", "Account");
        setImgData(e.url);

        setModalShow(false);
        setDataevent();
        setDataeventall();
      } else {
        toast.error("Something went wrong !!", "error");
      }
    });
  };
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  function dataThemeNew(e) {
    setDataevent();
  }

  function setDataeventall() {
    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {

          dispatch({ type: actionTypes.EVENTS_LIST, payload: res.data });

        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  function Eventtype() {
    informationService.eventType().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  //console.log(getTimeZone(props.eventName?.user_time_zone.offset, props.eventName?.event_end_date));
  const params = useParams();
  localStorage.setItem("todo_event_name", props.eventName.event_name);
  localStorage.setItem("todo_event_img", props.eventName.cover_image);
  localStorage.setItem("todo_storyz_name", props.eventName.storyz_name);
  localStorage.setItem("todo_storyz_img", props.eventName.project_image);
  let urlArr = window.location.href.split("/");
  let eventId = urlArr[urlArr.length - 1];
  let storyzId = urlArr[urlArr.length - 2];

  // console.log(eventId);
  // console.log(storyzId);
  // console.log(props);

  const [textdisabled, setTextDisabled] = useState(true);
  let history = useHistory();
  const dispatch = useDispatch();
  const [openThemeModal, setOpenThemeModal] = React.useState(false);
  const [todolist, setTodolist] = useState([]);
  const [userguestList, setUserguestList] = React.useState([]);

  const [aboutData, setAboutData] = React.useState();

  let resdata = { event_id: eventId };

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  });

  React.useEffect(() => {
    todoService.listEventTodo(resdata).then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;
          let obj = {};
          for (let index = 0; index < respons.length; index++) {
            let assignedArr = respons[index].assigned_on.split("T");
            let assigned_date = assignedArr[0].split("-").reverse().join("/");
            let dueArr = respons[index].due_date.split("T");
            let due_date = dueArr[0].split("-").reverse().join("/");
            respons[index].assigned_on = assigned_date;
            respons[index].due_date = due_date;
          }
          //console.log('respons index', respons);
          setTodolist(respons);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );

    userGuestService.userGuestList(eventId).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });

    Eventtype();

    setAboutData(common?.singleEvent?.event_description);

    setImgData(common?.singleEvent?.cover_image);
  }, [props]);

  const setDataevent = () => {
    const id = { event_id: common?.singleEvent?.id };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            ...common,
            singleEvent: res.data,
          };
          setstoryzSetting(res.data.Storyz_Setting);
          dispatch(commonData(value));
          setDataeventall();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const dateSetvalueenddate = (val) => {
    var dates = moment(val).format("YYYY-MM-DD HH:mm:ss");
    const val1 = [
      {
        id: common?.singleEvent?.id,
        key_name: "event_end_date",
        key_value: dates,
      },
    ];

    userGuestService.eventUpdate(val1).then((res) => {
      if (res.status === "success") {
        toast.success("update successfully !!", "Account");
        setDataevent();
      } else {
        toast.error("Something went wrong !!", "error");
      }
    });
  };
  const dateSetvalue = (val) => {
    var dates = moment(val).format("YYYY-MM-DD HH:mm:ss");
    const val1 = [
      {
        id: common?.singleEvent?.id,
        key_name: "event_start_date",
        key_value: dates,
      },
    ];

    userGuestService.eventUpdate(val1).then((res) => {
      if (res.status === "success") {
        setDataevent();
        toast.success("update successfully !!", "Account");
      } else {
        toast.error("Something went wrong !!", "error");
      }
    });
  };

  React.useEffect(() => {
    setAboutData("");
    setTimeout(() => {
      setAboutData(common?.singleEvent?.event_description);
    }, 500);

    if (common?.singleEvent?.event_start_date) {
      let assigned_date = moment
        .utc(common?.singleEvent?.event_start_date)
        .format("YYYY-MM-DD HH:mm:ss");
      setStartDate(moment(assigned_date).toDate());
    }
    if (common?.singleEvent?.event_end_date) {
      let due_date = moment
        .utc(common?.singleEvent?.event_end_date)
        .format("YYYY-MM-DD HH:mm:ss");
      setEventEnds(moment(due_date).toDate());
    }
    // const [startDate, setStartDate] = React.useState('');
    // const [endtDate, setEventEnds] = React.useState('');
  }, [common]);

  const mockSave = (val) =>
    new Promise((resolve) => setTimeout(() => resolve(val), 2000));

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return todolist;
    }

    return todolist.filter(
      (option) =>
        option.todo_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const [storyzSetting, setstoryzSetting] = useState({});



  React.useEffect(() => {
    if (params?.eid) {
      const id = { event_id: params?.eid };
      eventsService.eventDetails(id).then(
        (res) => {
          if (res.status === "success") {
            setstoryzSetting(res.data.Storyz_Setting);
            let sid = res.data.storyz_id;
            let eid = res.data.id;
            let storyName = res.data.storyz_name;
            let singleEvent = res.data;
            dispatch(commonData(common));
            myFunctionList(sid, eid, storyName, singleEvent);
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [params]);
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  return (
    <>
      <Row className="m-0 eventCardDetailsWrapper">
        <>
          <Col lg={6} md={12}>
            <div
              className="themeOuterWrapper eventCardLeft"
              style={{
                background: storyzSetting?.screen_bg,
              }}
            >
              <div
                className="themeOuterInner"
                style={{
                  // background: `url(${imgData ? imgData : EventBg})`,
                  background: `url(${storyzSetting?.background_image
                    ? storyzSetting?.background_image
                    : ""
                    // : EventBg
                    })`,
                }}
              >
                <div>
                  <h1
                    className="GreatVibes title"
                    style={{
                      fontFamily: storyzSetting?.font,
                      fontSize: storyzSetting?.size,
                      fontStyle: storyzSetting?.style,
                      color: storyzSetting?.screen_txt,
                    }}
                  >
                    <div className="badge">Event</div>
                    {common?.singleEvent?.event_name}
                  </h1>
                  {/* <p className="evname">Event Name</p> */}
                </div>
                <div className="coverimage mt-2">
                  <div className="coverimage1">
                    <p
                      className="fontSize14"
                      // style={{ color: "#666666" }}
                      style={{
                        color: storyzSetting?.screen_txt,
                      }}
                    >
                      Cover Image :
                    </p>
                  </div>
                  <div>
                    <p>
                      <img
                        alt="Organization Name"
                        src={require("assets/img/mediaGallery.svg").default}
                        className="icomn pointer"
                        style={{ width: "24px" }}
                        onClick={() => {
                          setModalShow(true);
                        }}
                      />
                    </p>
                    {modalShow ? (
                      <Filemanager
                        show={modalShow}
                        onHide={handleClick}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        seletedImage={seletedImageClike}
                        mapkey=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div>
                  <div className="mt-3">
                    <div
                      className="imgMaskevente "
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      <div className="imgMask-content">+</div>
                      {imgData ? (
                        <img
                          className="storytoimageev"
                          id="blah2"
                          src={
                            imgData
                              ? imgData
                              : common?.singleEvent?.cover_image
                                ? common?.singleEvent?.cover_image
                                : ""
                          }
                          alt="."
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="dateyourlocal mt-4">
                  <div>
                    <div className="dateTimePickerGlobal fontSize16 daclass mb-1">
                      <DatePicker
                        isClearable
                        name="event_start_date"
                        className={"form-control pointer"}
                        selected={startDate ? startDate : ""}
                        onChange={(val) => {
                          setStartDate(val);
                          dateSetvalue(val);
                          setValue("event_start_date", val);
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Time"
                        placeholderText="Date"
                        dateFormat="MMMM d, yyyy h:mm a"
                        minDate={new Date()}
                        onFocus={(e) => e.target.blur()}
                      />
                    </div>
                    <div className="dateTimePickerGlobal fontSize16 daclass">
                      <DatePicker
                        isClearable
                        name="event_start_date"
                        className={"form-control pointer"}
                        selected={endtDate ? endtDate : ""}
                        onChange={(val) => {
                          setEventEnds(val);
                          setValue("event_end_date", val);
                          dateSetvalueenddate(val);
                        }}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Time"
                        placeholderText="Date"
                        dateFormat="MMMM d, yyyy h:mm a"
                        minDate={
                          startDate
                            ? new Date(
                              startDate?.getTime() + 60 * 60 * 24 * 1000
                            )
                            : new Date(
                              new Date().getTime() + 60 * 60 * 24 * 1000
                            )
                        }
                        onFocus={(e) => e.target.blur()}
                      />
                    </div>

                    <span
                      className="fontSize10"
                      style={{
                        paddingLeft: "8px",
                        color: storyzSetting?.screen_txt,
                      }}
                    >
                      {moment
                        .utc(common?.singleEvent?.event_start_date)
                        .format("MMMM D, h:mm:ss a")}{" "}
                      -{" "}
                      {moment
                        .utc(common?.singleEvent?.event_end_date)
                        .format("MMMM D, h:mm:ss a")}{" "}
                      {common?.singleEvent?.timezone} in your local timezone
                    </span>
                  </div>

                  {props.eventName.event_type_name && (
                    <div className="eventTypeDropdownOuter">
                      <EventTypeDropdown
                        placeholder={common?.singleEvent?.event_type_name}
                        guestPrefixOption1={eventType}
                        eId={eventId}
                      />
                    </div>
                  )}
                </div>
                <hr
                  style={{
                    marginTop: "2px",
                    marginBottom: "0em",
                    borderTop: "1px solid #979798",
                  }}
                />
                <div>
                  <h4
                    className="GreatVibes fontSize24 mt-2"
                    style={{
                      color: storyzSetting?.screen_txt,
                    }}
                  >
                    About
                  </h4>
                </div>
                <div
                  onClick={() => {
                    setTextDisabled(false);
                  }}
                  className="aboutContentWrapper"
                >
                  <AboutExitarea
                    label="Username"
                    placeholder="About"
                    onSave={mockSave}
                    name="event_description"
                    eId={eventId}
                    value={common ? aboutData : ""}
                  />
                </div>
                <div style={{ padding: "12px" }}>
                  <p className="fontSize14" style={{ padding: "12px" }}>
                    {/* {props.eventName.event_des} */}
                  </p>
                </div>

                {userguestList.length > 0 ? (
                  <>
                    <h4
                      className="GreatVibes fontSize24 mt-2"
                      style={{
                        color: storyzSetting?.screen_txt,
                      }}
                    >
                      Honored Guests
                    </h4>

                    <div
                      className="evavter mt-4"
                      onClick={() => {
                        history.push(
                          `/admin/events-honored-guest-list/${common?.singleEvent?.storyz_id}/${common?.singleEvent?.id}?type=event`
                        );
                      }}
                    >
                      {userguestList.slice(0, 4).map((arr, i) => {
                        return (
                          <div>
                            <a
                              className="avatar rounded-circle"
                              href="#pablo"
                              key={i}
                            >
                              <img
                                alt="..."
                                src={arr.imageUrl ? arr.imageUrl : placeholde}
                                width={45}
                                height={45}
                              />
                            </a>
                            <p
                              className="fontSize12 JakartaMedium500 text-center"
                              style={{
                                color: storyzSetting?.screen_txt,
                              }}
                            >
                              {arr.first_name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div>
                  <Row
                    className="loctain"
                    onClick={() => {
                      history.push(
                        `/admin/add-geospace-list/${common?.singleEvent?.storyz_id}/${common?.singleEvent?.id}/?type=geospace`
                      );
                    }}
                    style={{
                      background: storyzSetting?.location_bg,
                    }}
                  >
                    <Col xl="4" sm="12" md="4">
                      {common?.singleEvent?.name_of_geospace ? (
                        <p
                          className="fontSize18"
                          style={{ fontWeight: "600", margin: "0px" }}
                        >
                          {common?.singleEvent?.name_of_geospace}
                          <br />
                          <span style={{ fontSize: "12px", fontWeight: "500" }}>
                            {common?.singleEvent?.location}
                          </span>
                        </p>
                      ) : (
                        <p
                          className="fontSize18"
                          style={{
                            fontWeight: "600",
                            margin: "0px",
                            color: storyzSetting?.screen_txt,
                          }}
                        >
                          No Location
                          <br />
                          <span
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          ></span>
                        </p>
                      )}
                    </Col>
                    <Col md="8" sm="12" xl="8" className="pr-0">
                      <div className="locImgWrap">
                        <img
                          alt="locImg"
                          src={
                            common?.singleEvent?.location_cover_image
                              ? common?.singleEvent?.location_cover_image
                              : require("assets/img/eventdimage.svg").default
                          }
                          className="locImg"
                        />
                        <img
                          alt="..."
                          src={
                            require("assets/img/cardgeospacemap.svg").default
                          }
                          className="locImg"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr
                  style={{
                    marginTop: "10px",
                    marginBottom: "0em",
                    borderTop: "1px solid #979798",
                  }}
                />
                <Row>
                  <Col xl="7" sm="12">
                    <div
                      className="mt-4 px-3 pb-3 pt-2"
                      style={{
                        background: "rgba(255, 255, 255, 0.38)",
                        borderRadius: "12px",
                      }}
                    >
                      <p
                        className="m-0 mb-2"
                        style={{
                          color: storyzSetting?.screen_txt,
                        }}
                      >
                        Status:
                      </p>
                      <div>
                        <Progress
                          color="#979798"
                          className="progress-lg mb-0"
                          max="100"
                          value={
                            common?.singleEvent?.event_status_bar
                              ? common?.singleEvent?.event_status_bar
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* <div className="mt-4 pl-3 pb-3"> */}
                <Row>
                  <Col xl="8" sm="12">
                    <div
                      className="caldiv mt-4 pl-3 pb-3 eventDashboardCounting"
                      style={{
                        background: "rgba(255, 255, 255, 0.38)",
                        borderRadius: "12px",
                      }}
                    >
                      <div
                        className="caldivList"
                        style={{
                          color: storyzSetting?.screen_txt,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(
                            `/admin/event-user-guest-list/${common?.sid}/${common?.eid}/?type=guests`
                          );
                        }}
                      >
                        <span className="fontSize12 Jakartanormal top statusIcon">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/Vector15.svg").default}
                            style={{ marginRight: "10px" }}
                          />
                        </span>
                        <span
                          className="fontSize12 Jakartanormal "
                          style={{ marginRight: "10px" }}
                        >
                          {userguestList.length} Guests
                        </span>
                      </div>
                      <div
                        className="caldivList"
                        style={{
                          color: storyzSetting?.screen_txt,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(
                            `/admin/manage-invites/${common?.sid}/${common?.eid}/?type=invites-rsvps`
                          );
                        }}
                      >
                        <span className="fontSize12 Jakartanormal top statusIcon">
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/rsvp-black-512.svg").default
                            }
                            style={{ marginRight: "10px" }}
                          />
                        </span>
                        <span
                          className="fontSize12 Jakartanormal"
                          style={{ marginRight: "10px" }}
                        >
                          0 RSVPs
                        </span>
                      </div>
                      <div
                        className="caldivList"
                        style={{
                          color: storyzSetting?.screen_txt,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(
                            `/admin/events-todos/${common?.sid}/${common?.eid}/?type=event`
                          );
                        }}
                      >
                        <span className="fontSize12 Jakartanormal top statusIcon">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/Group.svg").default}
                            style={{ marginRight: "10px" }}
                          />
                        </span>
                        <span
                          className="fontSize12 Jakartanormal"
                          style={{ marginRight: "10px" }}
                        >
                          {todolist.length} Todos
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* </div> */}
              </div>
            </div>

            <div className="mt-4">
              <div
                className="pointer text-end"
                onClick={() => setOpenThemeModal(true)}
              >
                Japanese Wave
                <img
                  alt="Organization Name"
                  src={require("assets/img/paint-icon 1.svg").default}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
            <StoryzThemeModal
              openThemeModal={openThemeModal}
              handleThemeClose={() => setOpenThemeModal(false)}
              eid={params?.eid}
              dataTheme={storyzSetting}
              type="event"
              dataThemeNew={dataThemeNew}
              ThemeTypeName="Event"
            />
          </Col>
          <Col lg={6} md={12} className="boxeventshadow">
            <div>
              <div className="emenu">
                <div className="emenu1">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/sound.svg").default}
                    style={{ marginRight: "10px" }}
                  />
                  <h3 className="mb-0 fontSize20 JakartaMedium300">
                    Event Menu
                  </h3>
                </div>
                <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                    style={{ marginRight: "10px" }}
                  />
                </span>
                <ReactTooltip />
              </div>
              <hr style={{ marginTop: "1rem" }} />
              <div>
                <div className="menuLinkWrapper">
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-about/${params.sid}/${params.eid}?type=event`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/adout.svg").default}
                      className="icomn"
                    />
                    <p>About</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/add-geospace-list/${params.sid}/${params.eid}?type=geospace`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/geospace.svg").default}
                      className="icomn"
                    />
                    <p>Geospace</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      const common = {
                        sid: props.eventName.storyz_id,
                        eid: props.eventName.id,
                      };
                      dispatch(commonData(common));
                      history.push(
                        `/admin/event-user-guest-list/${params.sid}/${params.eid}/?type=guests`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/guestlist.svg").default}
                      className="icomn"
                    />
                    <p>Guest List</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-message/${params.sid}/${params.eid}?type=message`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/icons/Chat-Vector.svg").default}
                      className="icomn"
                    />
                    <p>Messages</p>
                  </div>

                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/events-honored-guest-list/${params.sid}/${params.eid}?type=event`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/honoredguests.svg").default}
                      className="icomn"
                    />
                    <p>Honored Guests</p>
                  </div>

                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-guest-group-list/${params.sid}/${params.eid}?type=guests`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/groups.svg").default}
                      className="icomn"
                    />
                    <p>Guest Groups</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    //event-invite-rsvps
                    onClick={() => {
                      history.push(
                        `/admin/manage-invites/${params.sid}/${params.eid}?type=invites-rsvps`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/RSVPS.svg").default}
                      className="icomn"
                    />
                    <p>Invites and RSVPS</p>
                  </div>

                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/upload-guest-list/${params.sid}/${params.eid}?type=guests`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/uploadGuest.svg").default}
                      className="icomn"
                    />
                    <p>Upload Guest List</p>
                  </div>

                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-media-gallery/${props.eventName.storyz_id}/${props.eventName.id}?type=event`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/mediaGallery.svg").default}
                      className="icomn"
                    />
                    <p>Media Gallery</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-keepsake-album/${params.sid}/${params.eid}?type=event`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/mediaGallery.svg").default}
                      className="icomn"
                    />
                    <p>Keepsake Album</p>
                  </div>
                  <div
                    className="pointer menuLink"
                    onClick={() => {
                      history.push(
                        `/admin/event-file/${params.sid}/${params.eid}?type=event`
                      );
                    }}
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/icons/Vector-6.svg").default}
                      className="icomn"
                    />
                    <p>Files</p>
                  </div>
                  <div className="pointer menuLink">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/preview.svg").default}
                      className="icomn"
                    />
                    <p>Preview</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5" style={{ minHeight: "600px" }}>
              <div className="emenu">
                <div className="emenu1">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/Grouptodo.svg").default}
                    style={{ marginRight: "10px" }}
                  />
                  <h3 className="mb-0 fontSize20 JakartaMedium300">
                    Todos for this Event
                  </h3>
                </div>
                <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                    style={{ marginRight: "10px" }}
                  />
                </span>
                <ReactTooltip />
              </div>
              <hr style={{ marginTop: "1rem", marginBottom: "1.3em" }} />
              <div>
                <div className="form-group">
                  <input
                    id="example2cols1Input"
                    placeholder="type to search..."
                    type="text"
                    className="form-control"
                    onChange={onSearch}
                    value={searchValue}
                    ref={searchRef}
                  />
                </div>
                <div className="todo-list">
                  {getOptions().map((x, i) => (
                    <>
                      <div className="todo-item" key={i}>
                        <div>
                          <span className="fontSize12 Jakartanormal">
                            <img
                              alt="Organization Name"
                              src={require("assets/img/todolist.svg").default}
                              style={{ marginRight: "10px" }}
                            />
                          </span>
                          <span
                            className="fontSize12 Jakartanormal"
                            style={{ marginRight: "10px" }}
                          >
                            {x.due_date}
                          </span>
                          <span className="fontSize12 Jakartanormal">
                            {x.todo_name}
                          </span>
                        </div>
                        <Link to={`/admin/events-todos/${storyzId}/${eventId}`}>
                          <span className="fontSize12 Jakartanormal">
                            <img
                              alt="Organization Name"
                              src={require("assets/img/Vector.svg").default}
                              style={{ marginRight: "10px" }}
                            />
                          </span>
                          <span className="fontSize12 Jakartanormal hcolor">
                            {x.todo_status}
                          </span>
                        </Link>
                      </div>
                    </>
                  ))}
                </div>
                <Link
                  to={`/admin/events-todos/${storyzId}/${eventId}?type=event`}
                  className="newTodo"
                >
                  New Todo
                </Link>
              </div>
            </div>
          </Col>
        </>
      </Row>
    </>
  );
}
export default Index;
