import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { TiUser } from "react-icons/ti";
import { BsThreeDots } from "react-icons/bs";
import { CombinedGuestPool } from "./style";
import Select from "react-select";
import placeholde from "../../assets/img/placeholder.jpg";
import SortIcon from "./../../assets/img/sortIcon.svg";
import DeleteEverythingInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
import GuestEditDropdown from "components/Dropdown/GuestEditDropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditPencilIcon from "./../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../assets/img/deleteIcon.svg";
import StoryzHonoredGuestListDropdown from "components/Dropdown/StoryzHonoredGuestListDropdown";
import ImgIcon from "./../../assets/img/Image-Icon.svg";
import LockIcon from "./../../assets/img/lock-invert-icon.svg";
import { Link, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { useFindPathSid, useFindPathEid } from "../../CommonData";
const { SearchBar } = Search;

const HonoredGuestListStoryz = ({ args }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteEverything, setDeleteEverything] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  let history = useHistory();
  const [roleItemsid, setRoleItemsid] = useState([]);
  const [addRolepopus, setAddRolepopus] = useState(false);
  const [roleerror, setRoleerror] = useState(false);

  const [honoredData, setHonoredData] = useState([]);
  const [honoredDataall, setHonoredDataall] = useState([]);

  var sid = useFindPathSid(3);
  var eid = useFindPathEid(4);

  const onClickRoleCheckbox = (event, data) => {

    let sendData = {
      storyz_id: sid,
      guest_id: data.guest_id,
      honor_in_storyz: event.target.checked,
    };
    userGuestService.honorInstoryz(sendData).then((res) => {
      if (res.status === "success") {
        toast.success("Update successfully");
        DatasetAll();
      } else {
      }
    });
  };
  useEffect(() => {
    DatasetAll();
  }, [sid]);

  function DatasetAll() {
    userGuestService.honoredGuests(sid).then((res) => {
      if (res.status === "success") {
        setHonoredDataall(res.data);
        setHonoredData(res.data);
      } else {
        setHonoredDataall([]);
      }
    });
  }

  const handleSelect = (e) => {
    const valueName = e.target.value;

    if (valueName === "all") {
      DatasetAll();
    } else {
      let filteredData = honoredDataall.filter(
        (data) => data.is_honor_storyz === true
      );

      setHonoredData(filteredData);
    }
  };

  const showEntries = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
  ];
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handleDeleteEverything = (e) => {
    e.preventDefault();
    setDeleteEverything(!deleteEverything);
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: "abc",
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
    },
    {
      img: LockIcon,
      title: "Event Authentication",
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteEverything,
    },
  ];
  const [guestHonorsList, setRolelist] = useState([
    { id: 1, title: "Bride" },
    { id: 2, title: "Featured Speaker" },
    { id: 3, title: "Event" },
    { id: 4, title: "Bride" },
    { id: 5, title: "Featured Speaker" },
    { id: 6, title: "Event" },
  ]);

  const [guestHonorArr, setGuestHonorArr] = useState([
    { id: 1, title: "Bride" },
    { id: 2, title: "Featured Speaker" },
  ]);
  const AddRoleModal = () => {
    setAddRolepopus(!addRolepopus);
    setRoleerror(false);
  };
  const onClickRole = (e) => {
    setRoleItemsid(e);
  };

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const honoredColumn = [
    {
      dataField: "first_name",
      text: (
        <div className="d-flex align-items-center">
          Actions
          <TiUser size={20} className="mx-3 userIcon" />
          Guest Name
        </div>
      ),
      formatter: (rowContent, row) => (
        <div className="align-middle">
          <div className="d-flex align-items-center">
            {/* <GuestEditDropdown guestListOption={guestListOption} /> */}

            <div className="guestUserCard ml-3" onClick={() => {
              // history.push(
              //   `/admin/edit-user-guest-list/${sid}/${eid}/${row?.guest_id}?type=guests`
              // );
            }} style={{ cursor: 'pointer' }}>
              <div className="guestUserPic">
                <img
                  src={row.imageUrl ? row.imageUrl : placeholde}
                  className="pic"
                  alt=""
                />
              </div>
              <div className="guestUserBody">
                <div className="title">
                  {row.first_name} {row.last_name}
                </div>
                <div className="subTitle">The Guest </div>
              </div>
            </div>
          </div>
          <div className="custom-control custom-checkbox honoredCheckbox mt-3">
            <label className="container3">
              <input
                type="checkbox"
                name="storyz_methods"
                checked={row.is_honor_storyz}
                onChange={(event) => onClickRoleCheckbox(event, row)}
              />
              <span className="checkmark"></span>
              Honor in Storyz
            </label>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      dataField: "product",
      text: (
        <div className="d-flex align-items-center">
          {/* <img src={SortIcon} alt="" className="mx-3 userIcon" /> */}
          Events With Honors (Select Event to Edit)
        </div>
      ),
      formatter: (rowContent, row) => (
        <div className="my-2 d-flex">
          {row.event_data.map((eventd) => (
            <div className="mr-4" key={Math.random()}>
              <div
                className="eventCard"
                onClick={() => {
                  history.push(
                    `/admin/events-honored-guest-list/${sid}/${eventd?.event_id}/?type=event`
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={eventd.cover_image ? eventd.cover_image : placeholde}
                  alt=""
                  className="img"
                />
                {eventd.event_name}
              </div>
              <div className="mt-3 p-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mb-3"
                >
                  Select search icon to find a role:
                </label>
                <StoryzHonoredGuestListDropdown
                  guestListOption={eventd.guest_event_relation}
                  selected={guestHonorArr}
                  addUserBtn={false}
                  handleAddModal={AddRoleModal}
                  onChange={onClickRole}
                  eid={eventd.event_id}
                  gid={row.guest_id}
                  evdat={row}
                  evsingledat={eventd}
                />
              </div>
            </div>
          ))}

          {/* <div className="mr-4">
            <div className="eventCard">
              <img
                src="https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                alt=""
                className="img"
              />
              Event Name...
            </div>
            <div className="mt-3 p-1">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mb-3"
              >
                Select search icon to find a role:
              </label>
              <UserGuestListDropdown
                guestListOption={guestHonorsList}
                selected={guestHonorArr}
                addUserBtn={false}
                handleAddModal={AddRoleModal}
                onChange={onClickRole}
              />
            </div>
          </div> */}
          {/* <div className="mr-4">
            <div className="eventCard">
              <img
                src="https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                alt=""
                className="img"
              />
              Event Name...
            </div>
            <div className="mt-3 p-1">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label mb-3"
              >
                Select search icon to find a role:
              </label>
              <UserGuestListDropdown
                guestListOption={guestHonorsList}
                selected={guestHonorArr}
                addUserBtn={false}
                handleAddModal={AddRoleModal}
                onChange={onClickRole}
              />
            </div>
          </div> */}
        </div>
      ),
      sort: false,
    },
  ];

  return (
    <>
      <CombinedGuestPool>
        <div className="CombinedGuestPoolWrapper">
          <div className="headingWrapper borderBottom">
            <h4 className="heading-1">Select Honored Guests for Your Storyz</h4>
            <span
              className="ml-auto"
              data-tip="Tooltip message will come here....."
            >
              <img
                alt="Organization Name"
                src={require("assets/img/tip.svg").default}
              />
            </span>
            <ReactTooltip />
          </div>
          <p className="borderBottom organizationPara p-4">
            This view shows all the guests in your Storyz who have special
            honors set. For large events, you can select a subset of these
            honored guests to show in your Storyz. To edit in detail, go to the
            individual event.
          </p>
          <div>
            <Modal
              isOpen={deleteEverything}
              toggle={handleDeleteEverything}
              {...args}
              className="deleteEverythingModal"
            >
              <ModalBody className="pb-0">
                <div className="deleteEverythingHeader border-bottom mb-4">
                  <img
                    src={DeleteEverythingInfoIcon}
                    alt=""
                    className="deleteEverythingInfoIcon"
                  />
                  <div className="head">
                    Remove This Guest From the Guest List?
                  </div>
                </div>
                <Row>
                  <Col>
                    <div className="deleteEverythingThumWrapper">
                      <div className="deleteEverythingThum">
                        <img
                          src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                          alt=""
                          className="deleteEverythingThumImg"
                        />
                      </div>
                      <div className="deleteEverythingThumContent">
                        <div className="deleteEverythingthumbTitle">
                          Guest Name
                        </div>
                        <div className="deleteEverythingthumbSubTitle">
                          Role in Event
                        </div>
                      </div>
                    </div>

                    <div className="repeateventDetails pl-0">
                      <div>
                        <img
                          className="evimages ml-0"
                          src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                          alt=""
                        />
                      </div>
                      <div className="repeateventTitle">
                        <div>Event Name</div>
                        <div className="repeateventlocation">Location</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2 ">
                  <Col>
                    <p>
                      Confirm you want to remove this guest from the Event guest
                      list.
                    </p>
                    <p>This will NOT remove the guest data.</p>
                    <p>
                      Instead it will just remove the guest from the invite list
                      for this event.
                    </p>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                <Button color="primary" className="flex-grow-1 pinkBtn">
                  Remove Guest from List
                </Button>
                <Button
                  className="btn-default cancelBtn"
                  onClick={handleDeleteEverything}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>

          {/* <div className="guestSearchWrapper mb-3 border-bottom mb-4 pb-4 pt-2">
            <div className="searchWrapper">
              <button className="searchBtn">
                <FaSearch />
              </button>

              <input
                className="formInput searchInput"
                type="text"
                name="search"
                placeholder="search by...."
              />
            </div>
            <div className="showEntries">
              <span>Show</span>

              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={showEntries}
                className="formSelect enteriesDropdown showDropdown"
              />

              <span>Entries</span>
            </div>
            <div className="showEntries">
              <span>Filter by</span>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={filterGuest}
                className="formSelect enteriesDropdown"
              />
            </div>
          </div> */}

          {/* <div className="border-bottom mb-4 pb-3">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label fontSize14 grayColor pb-2 mb-1"
            >
              What to Call Your Honored Guests in Your Storyz:
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Guests of Honor"
            />
          </div> */}
          {honoredData && (
            <>
              {/* <Row className="mb-3 border-bottom mb-4 pb-4 pt-2">
              <Col lg={4} md={4}>
                <div
                  id="datatable-basic_filter"
                  className="dataTables_filter px-4 pb-1"
                  style={{ width: "100%" }}
                >
                  <img
                    alt="Organization Name"
                    src={
                      require("assets/img/icons/monogram-circular.svg")
                        .default
                    }
                    width="30px"
                    style={{ marginRight: "10px" }}
                  />
                  <SearchBar
                    className="form-control"
                    placeholder=""


                  />
                </div>
              </Col>
              <Col lg={5} md={5}>
                <Row>
                  <label
                    className="form-control-label mb-0"
                    htmlFor="exampleFormControlSelect1"
                    style={{ padding: "12px" }}
                  >
                    Filter by :
                  </label>

                  <FormGroup style={{ width: "129px" }} className="mb-0">
                    <Input id="exampleFormControlSelect1" type="select">
                      <option value="All">Select</option>
                      <option value="Organization">
                        Organization Todo
                      </option>
                      <option value="Storyz">Storyz Todo</option>
                      <option value="Event">Events Todo</option>
                    </Input>
                  </FormGroup>
                </Row>
              </Col>
              <Col
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row> */}
              {/*
              <div className="border-bottom mb-2 pb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label fontSize14 grayColor pb-2 mb-1"
                >
                  What to Call Your Honored Guests in Your Storyz:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Guests of Honor"
                />
              </div> */}

              <ToolkitProvider
                keyField="id"
                data={honoredData}
                columns={honoredColumn}
                search
              >
                {(props) => (
                  <>
                    <Row className="mb-3 border-bottom mb-4 pb-4 pt-2">
                      <Col lg={4} md={4}>
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                          style={{ width: "100%" }}
                        >
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/monogram-circular.svg")
                                .default
                            }
                            width="30px"
                            style={{ marginRight: "10px" }}
                          />
                          <SearchBar
                            className="form-control"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </div>
                      </Col>
                      <Col lg={5} md={5}>
                        <Row>
                          <div className="d-flex align-items-center">
                            <label
                              className="form-control-label mb-0"
                              htmlFor="exampleFormControlSelect1"
                              style={{ padding: "12px" }}
                            >
                              Filter by :
                            </label>

                            <div className="mb-0">
                              <select
                                id="exampleFormControlSelect1"
                                onChange={handleSelect}
                                className="honoredFilterDropdown"
                              >
                                <option value="all">All</option>
                                <option value="true">Honor in Storyz</option>
                              </select>
                            </div>
                          </div>
                        </Row>
                      </Col>
                      <Col
                        lg={3}
                        md={12}
                        className="d-flex justify-content-end align-items-center"
                      ></Col>
                    </Row>

                    <div className="border-bottom mb-2 pb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label fontSize14 grayColor pb-2 mb-1"
                      >
                        What to Call Your Honored Guests in Your Storyz:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Guests of Honor"
                      />
                    </div>

                    <div className="honoredTableWrapper">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}

                        pagination={pagination}
                      />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            </>
          )}

          {/* <div className="honoredTableWrapper">
            <table className="table overflowHidden">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex align-items-center">
                      Actions
                      <TiUser size={20} className="mx-3 userIcon" />
                      Guest Name
                    </div>
                  </th>
                  <th>
                    <div className="d-flex align-items-center">
                      <img src={SortIcon} alt="" className="mx-3 userIcon" />
                      Events With Honors (Select Event to Edit)
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[...Array(10)].map((x, i) => (
                  <>
                    <tr key={i} className="honoredGuestTableBg">
                      <td>
                        <div className="d-flex align-items-center">
                          <GuestEditDropdown
                            guestListOption={guestListOption}
                          />

                          <div className="guestUserCard ml-4">
                            <div className="guestUserPic">
                              <img
                                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                                className="pic"
                                alt=""
                              />
                            </div>
                            <div className="guestUserBody">
                              <div className="title">Guest Name </div>
                              <div className="subTitle">The Bride </div>
                            </div>
                          </div>
                        </div>
                        <div className="custom-control custom-checkbox honoredCheckbox mt-3">
                          <label className="container3">
                            <input
                              type="checkbox"
                              name="storyz_methods"
                              defaultChecked
                            />
                            <span className="checkmark"></span>
                            Honor in Storyz
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="eventCard">
                          <img
                            src="https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                            alt=""
                            className="img"
                          />
                          Event Name...
                        </div>
                        <div className="mt-3 p-1">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label mb-3"
                          >
                            Select search icon to find a role:
                          </label>
                          <UserGuestListDropdown
                            guestListOption={guestHonorsList}
                            selected={guestHonorArr}
                            addUserBtn={false}
                            handleAddModal={AddRoleModal}
                            onChange={onClickRole}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="eventCard">
                          <img
                            src="https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                            alt=""
                            className="img"
                          />
                          Event Name...
                        </div>
                        <div className="mt-3 p-1">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label mb-3"
                          >
                            Select search icon to find a role:
                          </label>
                          <UserGuestListDropdown
                            guestListOption={guestHonorsList}
                            selected={guestHonorArr}
                            addUserBtn={false}
                            handleAddModal={AddRoleModal}
                            onChange={onClickRole}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="eventCard">
                          <img
                            src="https://images.unsplash.com/photo-1583939003579-730e3918a45a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                            alt=""
                            className="img"
                          />
                          Event Name...
                        </div>
                        <div className="mt-3 p-1">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label mb-3"
                          >
                            Select search icon to find a role:
                          </label>
                          <UserGuestListDropdown
                            guestListOption={guestHonorsList}
                            selected={guestHonorArr}
                            addUserBtn={false}
                            handleAddModal={AddRoleModal}
                            onChange={onClickRole}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "6px" }}></td>
                      <td style={{ padding: "6px" }}></td>
                      <td style={{ padding: "6px" }}></td>
                      <td style={{ padding: "6px" }}></td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div> */}

          {/* <div className="paginationWrapper">
            <div className="showingPaginationText">
              Showing 1 to 20 of 2220 Entries{" "}
            </div>
            <nav className="customPagination">
              <ul className="pagination">
                <li className="page-item disabled prev">
                  <Link className="page-link next">Previous</Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    2
                  </Link>
                </li>
                <li className="page-item active">
                  <Link className="page-link" to="#">
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    5
                  </Link>
                </li>
                <li className="page-item dotted">
                  <Link className="page-link" to="#">
                    <BsThreeDots />
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to="#">
                    12
                  </Link>
                </li>
                <li className="page-item next">
                  <Link className="page-link" to="#">
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
        <ToastContainer />
      </CombinedGuestPool>
    </>
  );
};

export default HonoredGuestListStoryz;
