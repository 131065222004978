import React from "react";
import { Link } from "react-router-dom";
function Childorganizationmenu() {
  return (
    <>
      <div className="menuLinkWrapper">
        <Link to="/admin/organization-self-account" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/Color.svg").default}
            className="icomn"
          />
          <p>Details</p>
        </Link>

        <Link to="/admin/billing-account-view" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/budget-dollar-gray-1.svg").default}
            className="icomn"
          />
          <p>Billing and Payments</p>
        </Link>

        <Link to="/admin/invite" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/Vector-5.svg").default}
            className="icomn"
          />
          <p>Manage Admins</p>
        </Link>
        <Link to="/admin/organization-file" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/Vector-6.svg").default}
            className="icomn"
          />
          <p>Files</p>
        </Link>

        <Link to="/admin/combined-guest-pool-list" className="menuLink">
          <div className="height40 d-flex align-items-center justify-content-center mx-auto">
            <img
              alt="Organization Name"
              src={require("assets/img/icons/Vector-5.svg").default}
              className="icomn "
            />
          </div>
          <p>Combined Guest Pool</p>
        </Link>

        <Link to="/admin/todos" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/Grouptodo.svg").default}
            className="icomn"
          />
          <p>Combined Todo List</p>
        </Link>

        <Link to="/admin/organization-message" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/Chat-Vector.svg").default}
            className="icomn"
          />
          <p>Message Other Admins</p>
        </Link>

        <Link to="/admin/customer-support" className="menuLink">
          <img
            alt="Organization Name"
            src={require("assets/img/icons/apple-icon.svg").default}
            className="icomn"
            height={40}
          />
          <p>Internal Tester Support</p>
        </Link>
      </div>
    </>
  );
}
export default Childorganizationmenu;
