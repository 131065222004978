import React from "react";

const Cross = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2996 7.48396L19.7411 5.92554L13.5627 12.104L7.38429 5.92554L5.82587 7.48396L12.0043 13.6624L5.82587 19.8408L7.38429 21.3992L13.5627 15.2208L19.7411 21.3992L21.2996 19.8408L15.1211 13.6624L21.2996 7.48396Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};

export default Cross;
