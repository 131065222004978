import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import searchIcon from "../../assets/img/search-icon.svg";
import moment from "moment";
import placeholde from "../../assets/img/placeholder.jpg";
import { IoMdClose } from "react-icons/io";
import { userGuestService } from "_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPortal } from "react-dom";
const EventGuestListDropdown = ({
  guestListOption,
  placeholder,
  onChange,
  rowData,
  gid,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [eventItems, setEventItems] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });
  const [dataArray, setDataArray] = useState([]);
  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();

    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };
  useEffect(() => {
    setEventItems(rowData);

    if (rowData.length > 0) {
      var arr = [];
      let ids = rowData?.map((track) => arr.push(track.id));
      setDataArray(arr);
    }


  }, [rowData]);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    setEventList(guestListOption);
  }, [guestListOption]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !selectMenuRef.current.contains(e.target) &&
        !selectMenuList?.current?.contains(e.target)
      ) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const onAdd = (product) => {
    const index = eventItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      let da2 = [...eventItems, { ...product }];
      setEventItems(da2);
      var arr = [];
      let ids = da2?.map((track) => arr.push(track.id));
      onChange(arr, gid);
      setDataArray(arr);
    }
  };

  const iclose = (e) => {
    var data = {
      guest_id: gid,
      event_id: e.id,
    };

    userGuestService.deletesingleEvent(data).then((res) => {
      if (res.status === "success") {
        // roleall();
        const newPeople = eventItems.filter((person) => person.id !== e.id);
        setEventItems(newPeople);

        toast.success("Delete successfully");



        var toRemove = e.id;
        var index = dataArray.indexOf(toRemove);
        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          dataArray.splice(index, 1);
        }
        setDataArray(dataArray);
      } else {
        console.log("error");
      }
    });

    // console.log(newPeople);
    //setEventItems(newPeople);
  };
  const [more, setMore] = useState(false);

  const guestPrefixOption = () => {
    if (!searchValue) {
      return eventList;
    }

    return eventList.filter(
      (option) =>
        option.event_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };


  //console.log(eventItems);
  //console.log(eventList);
  return (
    <div className="dropdownBoxWrapperPort" ref={selectMenuRef}>
      <div className="dropdownTriggered heightAuto" ref={buttonHeight}>
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer d-flex flex-wrap align-items-center">
            {!more &&
              eventItems?.slice(0, 1)?.map((event, i) => {
                return (
                  <span
                    className="inviteGuestList d-flex align-items-center"
                    key={i}
                  >
                    {event.event_name}

                    <IoMdClose
                      className="ml-2 pointer"
                      size={16}
                      onClick={() => iclose(event)}
                    />
                  </span>
                );
              })}
            {more &&
              eventItems?.map((event, i) => {
                return (
                  <span
                    className="inviteGuestList d-flex align-items-center"
                    key={i}
                    onClick={() => iclose(event)}
                  >
                    {event.event_name}

                    <IoMdClose
                      className="ml-2 pointer"
                      size={16}
                      onClick={() => iclose(event)}
                    />
                  </span>
                );
              })}
            {eventItems.length > 1 ? (
              <span className="more mx-1" onClick={() => setMore(!more)}>
                {more ? "less..." : "more..."}
              </span>
            ) : (
              ""
            )}
            <span onClick={dropdownHandle}>
              <img
                src={searchIcon}
                alt=""
                className="mr-2"
                onClick={dropdownHandle}
              />
              {placeholder}
            </span>
          </div>
        </div>
      </div>
      {selectMenu &&
        createPortal(
          <div
            ref={selectMenuList}
            style={{ top: property.top, left: property.left }}
            className={`dropdownBoxMenuWrapperPort guestListWidth430 left ${selectMenu ? "active" : ""
              }`}
          >
            <div className="dropdownBoxMenuInner">
              <div className="dropdownSearchWrapper">
                <input
                  type="text"
                  placeholder="type to search..."
                  className="form-control"
                  onChange={onSearch}
                  value={searchValue}
                // onChange={(e) =>
                //   setInputValue(e.target.value.toLowerCase().replace(/^\s+/g, ""))
                // }
                />
              </div>
              <ul className="dropdownMenuListWrapper">
                {guestPrefixOption()?.length > 0 &&
                  guestPrefixOption()?.map((option, i) => (
                    <li
                      className={dataArray.indexOf(option.id) > -1 === true ? `menuItem pointer adddatelo` : `menuItem pointer`}
                      key={i}
                      onClick={() => onAdd(option)}

                    >
                      <div className="eventThumDropdown">
                        <div className="date">
                          {" "}
                          {option.event_start_date
                            ? moment(option.event_start_date).format(
                              "MMM Do YY"
                            )
                            : "No Date"}
                        </div>
                        <div className="eventThum">
                          <img
                            alt={option?.event_name}
                            src={
                              option?.cover_image
                                ? option?.cover_image
                                : placeholde
                            }
                            className="eventThumImg"
                          />
                        </div>
                        <div className="eventThumContent">
                          <div className="eventthumbTitle">
                            {option.event_name}
                          </div>
                          <div className="eventthumbSubTitle"></div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default EventGuestListDropdown;
