import React, { useId } from "react";
import classNames from "./Checkbox.module.scss";

const Checkbox = ({ label, checked, onChange }) => {
  const id = useId();

  const innerOnChange = (event) => {
    onChange(event.target.checked, event);
  };

  return (
    <div className={classNames.checkbox}>
      <input
        className={classNames.checkbox__input}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={innerOnChange}
      />
      {label && (
        <label className={classNames.checkbox__label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
