// import React, { useEffect, useState } from "react";
// import Papa from "papaparse";

// import {
//     Container,
// } from "reactstrap";

// import IconImg from "../../../assets/img/logo-terms.svg";
// import AuthFooter from "../../../components/Footers/AuthFooter.js";
// import { Link } from "react-router-dom";
// import { userService } from "../../../_services/login/user.service";
// import { ToastContainer, toast } from "react-toastify";
// import { useHistory } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// function Newandcsv() {
//     const allowedExtensions = ["csv"];
//     // This state will store the parsed data
//     const [data, setData] = useState([]);
//     const [csvData, setCsvData] = useState([]);
//     const [dbdata, setDbdata] = useState([]);
//     // It state will contain the error when
//     // correct file extension is not used
//     const [error, setError] = useState("");

//     // It will store the file uploaded by the user
//     const [file, setFile] = useState("");

//     // This function will be called when
//     // the file input changes
//     const handleFileChange = (e) => {
//         setError("");

//         // Check if user has entered the file
//         if (e.target.files.length) {
//             const inputFile = e.target.files[0];

//             // Check the file extensions, if it not
//             // included in the allowed extensions
//             // we show the error
//             const fileExtension = inputFile?.type.split("/")[1];
//             if (!allowedExtensions.includes(fileExtension)) {
//                 setError("Please input a csv file");
//                 return;
//             }

//             // If input type is correct set the state
//             setFile(inputFile);
//         }
//     };
//     const handleParse = () => {

//         // If user clicks the parse button without
//         // a file we show a error
//         if (!file) return setError("Enter a valid file");

//         // Initialize a reader which allows user
//         // to read any file or blob.
//         const reader = new FileReader();

//         // Event listener on reader when the file
//         // loads, we parse it and set the data.
//         reader.onload = async ({ target }) => {
//             const csv = Papa.parse(target.result, { header: true });
//             const parsedData = csv?.data;
//             setCsvData(parsedData);
//             const columns = Object.keys(parsedData[0]);
//             setData(columns);
//         };
//         reader.readAsText(file);
//     };
//     const reorderArray = (event, originalArray) => {
//         const movedItem = originalArray.find((item, index) => index === event.oldIndex);
//         const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

//         const reorderedItems = [
//             ...remainingItems.slice(0, event.newIndex),
//             movedItem,
//             ...remainingItems.slice(event.newIndex)
//         ];

//         return reorderedItems;
//     }

//     function changeOrder(index, direction) {
//         setData(reorderArray({ oldIndex: index, newIndex: index + (direction === "UP" ? (-1) : 1) }, data));
//     }

//     useEffect(() => {

//         const newArr = csvData.map(e => data.reduce((obj, key) => ({ ...obj, [key]: e[key] }), {}));
//         console.log(newArr);

//         userService.getGuestColumn(data)
//             .then(
//                 res => {
//                     if (res.status === 'success') {
//                         setDbdata(res.column);
//                         console.log(res.column);
//                         //history.push(`/admin/storyz-todos`);
//                     } else {
//                         console.log("Invalid Credetials !!", "Login");

//                     }
//                 }, error => {
//                     console.log("Invalid Credetials !!", "Login");

//                 }
//             )
//     }, [data])

//     return (
//         <>

//             <Container fluid className="GuestcreateLoginHeaderWrappper">
//                 <Link to="/">
//                     <i className="ni ni-bold-left arrowIcon"></i>
//                 </Link>
//                 <img src={IconImg} alt="" />
//                 OurStoryz
//             </Container>
//             <div className="twofactorauth">
//                 <Container className="termConditionWrapper" fluid>

//                     <div className="termConditionContentWrapper">
//                         <div>
//                             <label htmlFor="csvInput" style={{ display: "block" }}>
//                                 Enter CSV File
//                             </label>
//                             <input
//                                 onChange={handleFileChange}
//                                 id="csvInput"
//                                 name="file"
//                                 type="File"
//                             />
//                             <div>
//                                 <button onClick={handleParse}>Parse</button>
//                             </div>
//                             <div className="row">
//                                 <div className="col-md-3" style={{ marginTop: "3rem" }}>
//                                     <table>
//                                         <tr>
//                                             <th>Title</th>
//                                             <th>Order</th>
//                                             <th>---</th>
//                                         </tr>
//                                         {error ? error : data.map((col,
//                                             idx) => {
//                                             return (
//                                                 <tr key={idx}>
//                                                     <td>{col}</td>
//                                                     <td>
//                                                         <button onClick={() => changeOrder(idx, "UP")}>Move Up</button>
//                                                         <button onClick={() => changeOrder(idx, "DOWN")}>Move Down</button>
//                                                     </td>
//                                                     <td>=</td>
//                                                 </tr>
//                                             )
//                                         }
//                                         )}

//                                     </table>
//                                 </div>
//                                 <div className="col-md-6" style={{ marginTop: "3rem" }}>
//                                     <table>
//                                         <tr>
//                                             <th>Title</th>

//                                         </tr>
//                                         {error ? error : dbdata?.map((col,
//                                             idx) => {
//                                             return (
//                                                 <tr key={idx}>
//                                                     <td style={{ padding: '4px' }}>{col}</td>
//                                                 </tr>
//                                             )
//                                         }
//                                         )}

//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Container>
//             </div>
//             <ToastContainer />
//             <AuthFooter />
//         </>
//     );
// }
// export default Newandcsv;

import React, { useEffect, useState } from "react";

import { Importer, ImporterField } from "react-csv-importer";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

import Papa from "papaparse";

import { Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import IconImg from "../../../assets/img/logo-terms.svg";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
import { userService } from "../../../_services/login/user.service";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FilemanagerCsv from "../../../components/CommonMediaModal/FilemanagerCsv";
function Newandcsv() {
    const allowedExtensions = ["csv"];
    // This state will store the parsed data
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [dbdata, setDbdata] = useState([]);
    const [file, setFile] = useState("");
    // It state will contain the error when
    // correct file extension is not used
    const [error, setError] = useState("");

    // It will store the file uploaded by the user

    // This function will be called when
    // the file input changes
    const handleFileChange = (e) => {
        setError("");

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }

            // If input type is correct set the state
            setFile(inputFile);
        }
    };
    const handleParse = () => {
        // If user clicks the parse button without
        // a file we show a error
        if (!file) return setError("Enter a valid file");

        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();

        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            setCsvData(parsedData);
            const columns = Object.keys(parsedData[0]);
            setData(columns);
        };
        reader.readAsText(file);
    };
    const reorderArray = (event, originalArray) => {
        const movedItem = originalArray.find(
            (item, index) => index === event.oldIndex
        );
        const remainingItems = originalArray.filter(
            (item, index) => index !== event.oldIndex
        );

        const reorderedItems = [
            ...remainingItems.slice(0, event.newIndex),
            movedItem,
            ...remainingItems.slice(event.newIndex),
        ];

        return reorderedItems;
    };

    function changeOrder(index, direction) {
        setData(
            reorderArray(
                { oldIndex: index, newIndex: index + (direction === "UP" ? -1 : 1) },
                data
            )
        );
    }

    useEffect(() => {
        const newArr = csvData.map((e) =>
            data.reduce((obj, key) => ({ ...obj, [key]: e[key] }), {})
        );


        userService.getGuestColumn(data).then(
            (res) => {
                if (res.status === "success") {
                    setDbdata(res.column);

                    //history.push(`/admin/storyz-todos`);
                } else {
                    console.log("Invalid Credetials !!", "Login");
                }
            },
            (error) => {
                console.log("Invalid Credetials !!", "Login");
            }
        );
    }, [data]);

    const [modalShow, setModalShow] = React.useState(false);
    const handleClickimag = () => {
        setModalShow(false);
    };

    const allIMages = useSelector((state) => {
        return state.allFiles;
    });
    React.useEffect(() => {
        if (allIMages.isConfirmatiom === true) {
            setAllimage(allIMages.allFiles);
        } else {
            setAllimage([]);
        }
    });
    const [allimage, setAllimage] = useState([]);

    //weqwewqeqweqwee

    return (
        <>
            <Container fluid className="GuestcreateLoginHeaderWrappper">
                <Link to="/">
                    <i className="ni ni-bold-left arrowIcon"></i>
                </Link>
                <img src={IconImg} alt="" />
                OurStoryz
            </Container>
            <div className="twofactorauth">
                <Container className="termConditionWrapper" fluid>
                    {/* <img
                        alt="Organization Name"
                        src={
                            require("assets/img/mediaGallery.svg").default
                        }
                        className="icomn iconSize24 pointer"
                        onClick={() => {
                            setModalShow(true);
                        }}
                    />
                    {modalShow ? (
                        <FilemanagerCsv
                            show={modalShow}
                            onHide={handleClickimag}

                            list={allimage}

                            mapkey=""
                        />
                    ) : (
                        ""
                    )}

                    <div className="termConditionContentWrapper">
                        <div>


                            <div className="row">
                                <div className="col-md-3" style={{ marginTop: "3rem" }}>
                                    <table>
                                        <tr>
                                            <th>Title</th>
                                            <th>Order</th>
                                            <th>---</th>
                                        </tr>
                                        {error ? error : data.map((col,
                                            idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{col}</td>
                                                    <td>
                                                        <button onClick={() => changeOrder(idx, "UP")}>Move Up</button>
                                                        <button onClick={() => changeOrder(idx, "DOWN")}>Move Down</button>
                                                    </td>
                                                    <td>=</td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </table>
                                </div>
                                <div className="col-md-6" style={{ marginTop: "3rem" }}>
                                    <table>
                                        <tr>
                                            <th>Title</th>

                                        </tr>
                                        {error ? error : dbdata?.map((col,
                                            idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td style={{ padding: '4px' }}>{col}</td>
                                                </tr>
                                            )
                                        }
                                        )}

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="termConditionContentWrapper">
                        <div>
                            <h1>React CSV Importer sandbox</h1>

                            <Importer
                                dataHandler={async (rows) => {

                                    await new Promise((resolve) => setTimeout(resolve, 500));
                                }}
                                chunkSize={10000} // optional, internal parsing chunk size in bytes
                                defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                                restartable={false} // optional, lets user choose to upload another file when import is complete
                                onStart={({ file, fields }) => {
                                    // optional, invoked when user has mapped columns and started import
                                    console.log(
                                        "starting import of file",
                                        file,
                                        "with fields",
                                        fields
                                    );
                                }}
                                onComplete={({ file, fields }) => {
                                    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                                    // console.log(
                                    //     "finished import of file",
                                    //     file,
                                    //     "with fields",
                                    //     fields
                                    // );
                                }}
                                onClose={() => {
                                    // optional, invoked when import is done and user clicked "Finish"
                                    // (if this is not specified, the widget lets the user upload another file)
                                    //console.log("importer dismissed");
                                }}
                            >
                                <ImporterField name="prefix" label="Prefix" optional />
                                <ImporterField name="first_name" label="First Name" />
                                <ImporterField name="last_name" label="Last Name" />
                                <ImporterField name="suffix" label="Suffix" optional />
                                <ImporterField
                                    name="country_code"
                                    label="Country Code"
                                    optional
                                />
                                <ImporterField name="mobile_number" label="Mobile Number" />
                                <ImporterField name="email" label="Email" />
                            </Importer>
                        </div>
                        ,
                    </div>
                </Container>
            </div>
            <ToastContainer />
            <AuthFooter />
        </>
    );
}
export default Newandcsv;
