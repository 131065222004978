import React, { useState } from "react";
import {
  Modal,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { Tooltip } from "react-tooltip";
// import { Tooltip as ReactTooltip } from "react-tooltip";
// import * as Tooltip from "react-tooltip";
import Visa from "../../assets/img/visa.svg";
import Paymentpaypal from "../../assets/img/paymentpaypal.svg";
import ItemAction from "../../assets/img/ItemAction.svg";
import Itemactionshow from "../../assets/img/Itemactionshow.svg";
import { useForm } from "react-hook-form";
import { Payment } from "./styles.js";
import Paypalbuttons from "./PayPalButtons";
import { GrCircleInformation } from "react-icons/gr";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(
  "pk_test_51LwZKEDoeRAM4EghGQTTM9X2Dw7yoAZl0NMvZQSDFPcNHD7otzsXuPPQdy5FOA8RWnIKwCalL0YclUIfNOCwyd4e00FngMEC0D"
);

function PaymentPopus(props) {
  const [nameOnCard, setnameOnCard] = React.useState(false);
  const [cardNumber, setcardNumber] = React.useState(false);
  const [date, setdate] = React.useState(false);
  const [ccv, setccv] = React.useState(false);

  const [defaultModal, setdefaultModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <Payment>
        <a
          className="makeaPayment billingGreenBtn pointer"
          onClick={() => setdefaultModal(true)}
        >
          Make a Payment
        </a>
        {/* <Modal
            className="paymentpopus"
            isOpen={defaultModal}
            toggle={() => setdefaultModal(false)}
          >
            <div className="modal-header">
              <div className="headerInner">
                <h6 className="modal-title" id="modal-title-default">
                  Current Payment Gateways
                </h6>
                <button className="changeBtn ml-auto">Add New Gateway</button>
              </div>
              <button
                aria-label="Close"
                className="close ml-3"
                data-dismiss="modal"
                type="button"
                onClick={() => setdefaultModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="tabs">
                <button
                  className={`tab ${checkActive(1, "active")}`}
                  onClick={() => handleClick(1)}
                >
                  {checkActive(1, "active") === "active" ? (
                    <img src={ItemAction} />
                  ) : (
                    <img src={Itemactionshow} />
                  )}

                  <img src={Visa} />
                </button>
                <button
                  className={`tab ${checkActive(2, "active")}`}
                  onClick={() => handleClick(2)}
                >
                  {checkActive(2, "active") === "active" ? (
                    <img src={ItemAction} />
                  ) : (
                    <img src={Itemactionshow} />
                  )}
                  <img src={Paymentpaypal} />
                </button>

              </div>
              <div className="panels">

                <div className={`panel ${checkActive(1, "active")}`}>
                <Elements stripe={stripePromise}>
                  <PaymentForm />
                </Elements>


                </div>
                <div className={`panel ${checkActive(2, "active")}`}>
                  <Paypalbuttons />
                </div>
                <div className={`panel ${checkActive(3, "active")}`}>
                  <p>
                    Cras porta consectetur dolor porttitor fringilla. Cras vitae
                    urna ac erat fermentum egestas. Donec egestas cursus
                    scelerisque.
                  </p>
                </div>
              </div>

            </div>
          </Modal> */}
      </Payment>
    </>
  );
}
export default PaymentPopus;
