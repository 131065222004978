import menuOnOff from "./menuonoff";
import storyzPopup from "./storyzPopup";
import eventsPopup from "./eventsPopup";
import profileReducers from "./profileReducers";
import eventReducers from "./eventReducers";
import eventsList from "./eventListReducers";
import guestList from "./guestListReducers";
import commonData from "./commonDataReducers";
import mediaData from "./mediaListReducers";
import storysList from "./storyListReducers";
import userAdminData from "./userAdminDataReducers";
import allFiles from "./allFilesReducers";
import Eventkeepsakealbum from "./eventkeepsakealbumReducers";
import honoredGuestList from "./honoredGuestListReducers";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    combine: menuOnOff,
    profile: profileReducers,
    eventSave: eventReducers,
    storyz: storyzPopup,
    events: eventsPopup,
    eventsList: eventsList,
    storysList: storysList,
    commonData: commonData,
    guestList: guestList,
    mediaData: mediaData,
    eventkeepsakealbum: Eventkeepsakealbum,
    allFiles: allFiles,
    userAdmin: userAdminData,
    honoredList: honoredGuestList,
});

export default persistReducer(persistConfig, rootReducer);
