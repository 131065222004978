import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { GuestEventWrapper } from "../UserGuestList/style";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Allheader from "components/Headers/Allheader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import Preview from "./../../../assets/img/computer.svg";
import SendInvites from "./../../../assets/img/sendInvites.jpg";
import SendInvites1 from "./../../../assets/img/sendInvites1.jpg";
import SendInvites2 from "./../../../assets/img/sendInvites2.jpg";
import HornIcon from "./../../../assets/img/hornIcon.svg";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";

import ThemeBg from "../../../assets/img/modalThemeBg.png";
import Thememap from "../../../assets/img/thememap.png";
import AppStore from "../../../assets/img/app-store-download.png";
import GooglePlay from "../../../assets/img/google-play-download.png";
import { IoMdClose } from "react-icons/io";
import GlobalButton from "components/Button/GlobalButton";
import DeleteBtn from "components/Button/DeleteBtn";

const AddInviteRSVPsPast = ({ ...args }) => {
  const [guestGroupModal, setGuestGroupModal] = useState();
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [inviteTemplate, setInviteTemplate] = useState(false);

  const handleDeleteGuestUser = () => {
    setDeleteGuestUser(!deleteGuestUser);
  };

  const guestListOption = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
  ];
  const percentage = 66;
  const GuestListOptionList = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Sally Struthers",
      type: "Friend of Groom",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
  ];
  const groupType = [
    { title: "Honored Guests" },
    { title: "Family" },
    { title: "Business" },
    { title: "Friends" },
    { title: "Partners" },
    { title: "Professional" },
    { title: "Casual" },
    { title: "Social" },
    { title: "Political" },
    { title: "Relegious" },
    { title: "Cultural" },
    { title: "Educational" },
    { title: "Sports" },
  ];

  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };
  const handleInviteTemplate = () => {
    setInviteTemplate(!inviteTemplate);
  };
  const common = useSelector((state) => {
    return state.commonData.common;
  });

  return (
    <>
      <Modal
        isOpen={inviteTemplate}
        toggle={handleInviteTemplate}
        // {...args}
        className="inviteTemplateModalWrapper"
      >
        <ModalHeader
          toggle={handleInviteTemplate}
          className="zIndex99 position-relative"
        ></ModalHeader>
        <ModalBody className="pb-0 zIndex99 position-relative">
          <div>
            <div className="themeTitle mb-4">Invite Title</div>
            <div className="subTitle mb-4 pb-4">Invite subtitle</div>
            <div className="themeImg mb-4">
              <img
                src="https://images.unsplash.com/photo-1579724449219-8da6b76bef68?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3266&q=80"
                alt=""
              />
            </div>
            <div className="themeBox">
              <div className="themeName mb-4">Event Name</div>
              <div className="themeDate mb-4">August 14, 2022 • 04:30 PM</div>
              <div className="themeGuestWrapper mb-4">
                <div className="head mb-3">Guests • 10</div>
                <div className="themeGuestListWrapper d-flex">
                  <div className="themeGuestList px-1">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3280&q=80"
                      alt=""
                      className="themeGuestImg"
                    />
                    Allison Adams
                  </div>
                  <div className="themeGuestList px-1">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3280&q=80"
                      alt=""
                      className="themeGuestImg"
                    />
                    Rhian Andrews
                  </div>
                  <div className="themeGuestList px-1">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3280&q=80"
                      alt=""
                      className="themeGuestImg"
                    />
                    Gracie-Mai Leech
                  </div>
                  <div className="themeGuestList px-1">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3280&q=80"
                      alt=""
                      className="themeGuestImg"
                    />
                    Sam Leech
                  </div>
                </div>
              </div>
              <div className="themeBoxWrapper d-flex mb-4">
                <div className="themeMapWrapper pt-3 mr-2">
                  <div className="title mb-1 px-3">
                    The Palace of Fine Arts{" "}
                  </div>
                  <div className="subTitle text-left px-3 border-0">
                    1234 Flyover Lane, Shreveport, LA 70118
                  </div>
                  <div className="mapWrapper position-relative mt-3">
                    <Button className="directionBtn">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/themeDirection.svg").default}
                        className="mr-2"
                        width={18}
                      />
                      Directions
                    </Button>
                    <img
                      alt="Organization Name"
                      src={Thememap}
                      className="themeMapImg"
                    />
                  </div>
                </div>
                <div className="themePorterWrapper position-relative">
                  <div className="title pb-1 mt-7">Jessica Porter</div>
                  <div className="subTitle b-4 border-0">Organizer</div>
                  <Button className="contactBtn">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/themeMail.svg").default}
                      className="mr-2"
                      width={18}
                    />
                    Contact
                  </Button>
                </div>
              </div>
              <div className="themeAppWrapper p-3 mb-4">
                <div className="head mb-2">RSVP Options</div>
                <div className="themeStoreApp d-flex justify-content-between">
                  <div className="themeImg1">
                    <img
                      alt="Organization Name"
                      src={AppStore}
                      className="d-flex"
                    />
                  </div>
                  <div className="themeImg2">
                    <img
                      alt="Organization Name"
                      src={GooglePlay}
                      className="d-flex"
                    />
                  </div>
                  <div className="themeImg3 d-flex flex-column justify-content-center align-items-center">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/computer.svg").default}
                      className="d-flex"
                    />
                    Website
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between px-5 pb-4">
                <img
                  alt="Organization Name"
                  src={require("assets/img/instagram-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
                <img
                  alt="Organization Name"
                  src={require("assets/img/twitter-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
                <img
                  alt="Organization Name"
                  src={require("assets/img/tiktok-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
                <img
                  alt="Organization Name"
                  src={require("assets/img/facebook-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
                <img
                  alt="Organization Name"
                  src={require("assets/img/youtube-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
                <img
                  alt="Organization Name"
                  src={require("assets/img/vimeo-icon.svg").default}
                  className="mr-2"
                  width={24}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <img src={ThemeBg} alt="" className="themeBg" />
      </Modal>
      <Allheader title="Storyz Name" imgScr={Imggroup} arw />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />
            <div className="column2">
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>
                  {common?.singleEvent?.event_name
                    ? common?.singleEvent?.event_name
                    : ""}
                </div>
                <div className="d-flex align-items-center">
                  <div className="imageeventim234">
                    <span>Invite Details</span>
                  </div>

                  <Modal
                    isOpen={guestGroupModal}
                    toggle={handelGuestGroupModal}
                    {...args}
                    className="aboutGuestGroupModal"
                  >
                    <ModalBody className="pb-0">
                      <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                        <img
                          src={GeospaceInfoIcon}
                          alt=""
                          className="guestGroupInfoIcon mt-3"
                        />
                        <div className="head">
                          Send Invites to Your Guest List
                        </div>
                      </div>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            You can create and send multiple invitations to your
                            guests. Invitations CANNOT be recalled - so make
                            sure you are ready before sending!
                          </p>
                          <p>
                            Invites are SEPARATE from your guest list and RSVPs.
                            Guests can RSVP by downloading the guest app, even
                            if they haven’t received an invite.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites}
                            alt=""
                            className="maxWidth100 mx-auto"
                            width={70}
                          />
                        </Col>
                      </Row>
                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            Each invitation can be sent and resent to your guest
                            list. You can control which guests receive the
                            invites.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites1}
                            alt=""
                            className="maxWidth100 mx-auto"
                          />
                        </Col>
                      </Row>

                      <Row className=" border-bottom mb-4 pb-2">
                        <Col sm={7}>
                          <p>
                            If the RSVP date has past, you cannot send the
                            Invite. To send it, you will have to change the RSVP
                            date.
                          </p>
                        </Col>
                        <Col className="d-flex align-items-center justify-center">
                          <img
                            src={SendInvites2}
                            alt=""
                            className="maxWidth100 mx-auto"
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                      <Button color="primary blueBtn" className="w-100">
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <span
                    onClick={handelGuestGroupModal}
                    className="ml-2 pointer"
                    data-tip="Tooltip message will come here....."
                  >
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                    />
                  </span>
                </div>
              </div>
              <div className="organizationPara border-bottom">
                This view lets you edit details, select a template, and send
                your invites.
              </div>

              <GuestEventWrapper className="p-4">
                <div className="organizeByGroupOuter mt-0">
                  <form className="forwith">
                    <div className="d-flex mb-4">
                      <GlobalButton
                        customClass="ml-auto"
                        img={require("assets/img/heartMailIcon.svg").default}
                        type="button"
                      >
                        Save Changes
                      </GlobalButton>
                      <DeleteBtn icon> Delete Invite </DeleteBtn>
                    </div>
                    <Row className="addGuestUerFormWrapper">
                      <Col className="" lg={6} md={12}>
                        <div>
                          <div className="heading">
                            Basic Information for Event
                          </div>
                          <Row>
                            <Col>
                              <div className="imageventsla mb-2 mt-4">
                                <div>Image Label:</div>
                                <div>
                                  <img
                                    alt="Organization Name"
                                    src={
                                      require("assets/img/mediaGallery.svg")
                                        .default
                                    }
                                    className="icomn"
                                    style={{ width: "24px" }}
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="imgMaskevente">
                                  <div className="imgMask-content">+</div>
                                  <input type="file" className="imgg1" />
                                  <img
                                    src="https://images.unsplash.com/photo-1579724449219-8da6b76bef68?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3266&q=80"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="my-3 p-2">
                            <Col>
                              <div className="form-group">
                                <label className="form-label">
                                  Invite Title (this will appear in Invite):
                                </label>
                                <input
                                  type="text"
                                  name="guest_personal_greeting"
                                  className="form-control"
                                  placeholder="enter text..."
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="my-3 p-2">
                            <Col>
                              <div className="form-group">
                                <label className="form-label">
                                  Description (optional):
                                </label>
                                <textarea
                                  type="text"
                                  name="admin_notes_on_guest"
                                  className="form-control"
                                  placeholder="enter text..."
                                ></textarea>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div>
                          <div className="heading">Additional Information</div>

                          <Row className="mt-3">
                            <Col>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Guest App Download
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Location
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include QR Code
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Include Web LInk
                                  <input type="checkbox" name="" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className="" lg={6} md={12}>
                        <div>
                          <div className="heading">Sending and Resending</div>

                          <Row className="my-3 p-2">
                            <Col>
                              <div className="py-1 px-2 d-flex align-items-center justify-content-between grayColor mb-3">
                                <span>RSVP Deadline was 3 days ago</span>
                                <span
                                  className="ml-2 pointer"
                                  data-tip="Tooltip message will come here....."
                                >
                                  <img
                                    alt="Organization Name"
                                    src={require("assets/img/tip.svg").default}
                                  />
                                </span>
                              </div>

                              <div className="border-bottom mb-3 pb-3">
                                <div className="inviteStatus d-flex align-items-center justify-content-end canNotSent w-100">
                                  Cannot be Sent
                                  <div className="guestCircleWrapper ml-3">
                                    <CircularProgressbar
                                      value={percentage}
                                      text={false}
                                      strokeWidth={20}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "#53D769 ",
                                        trailColor: "#1345A5",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="py-1 px-2 d-flex align-items-center justify-content-between grayColor mb-3">
                                <span>8 days until event RSVP deadline</span>
                                <span
                                  className="ml-2"
                                  data-tip="Tooltip message will come here....."
                                >
                                  <img
                                    alt="Organization Name"
                                    src={require("assets/img/tip.svg").default}
                                  />
                                </span>
                              </div>

                              <div className="border-bottom mb-3 pb-3">
                                <div className="inviteStatus d-flex align-items-center justify-content-end w-100">
                                  Ready to Send
                                  <div className="guestCircleWrapper ml-3">
                                    <CircularProgressbar
                                      value={percentage}
                                      text={false}
                                      strokeWidth={20}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "#53D769 ",
                                        trailColor: "#1345A5",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3 fontSize14 grayColor">
                                Send Option:
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Don’t send to guests who have RSVPed
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Send to all events in this Storyz, not just
                                  this event
                                  <input type="checkbox" name="" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="my-5">
                                <button
                                  className="messageBtn pinkBtn ml-auto"
                                  type="button"
                                >
                                  <img src={HornIcon} alt="" className="mr-2" />
                                  Change RSVP Deadline in Event
                                </button>
                              </div>

                              <div className="my-5">
                                <button
                                  className="messageBtn blueBtn ml-auto flex-row text-white"
                                  type="button"
                                >
                                  <img
                                    alt="Organization Name"
                                    src={
                                      require("assets/img/invite-icon-white.svg")
                                        .default
                                    }
                                    className="mr-2"
                                    width={18}
                                  />
                                  Review and Send
                                </button>
                              </div>

                              <div className="custom-control custom-checkbox mb-2 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Don’t send to guests who have RSVPed
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <div className="heading">Where to Send</div>

                          <Row className="pt-3">
                            <Col>
                              <p>
                                Choose a date to send out the invitation. Send
                                out your invitations well in advance of the
                                actual Event
                              </p>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Send in Email
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Send in SMS
                                  <input type="checkbox" name="" />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center disabled rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Generate Printable PDF
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox mb-3 checkList d-flex align-items-center disabled rsvpInviteCheckList">
                                <label className="container3 grayColor mb-0">
                                  Export information for Invitation Service
                                  <input
                                    type="checkbox"
                                    name=""
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div>
                          <div className="heading">Invite Template</div>
                          <Row className="my-3 p-2">
                            <Col>
                              <div className="p-3 d-flex">
                                <div className="mr-3">
                                  <div className="themeImgWrapper bg-white d-flex align-items-center justify-content-center">
                                    <img
                                      src={SendInvites}
                                      alt=""
                                      className="mx-auto"
                                      width={70}
                                    />
                                  </div>
                                </div>
                                <div className="pt-4">
                                  <button
                                    className="messageBtn previewActive"
                                    type="button"
                                    onClick={handleInviteTemplate}
                                  >
                                    <img
                                      alt="Organization Name"
                                      src={Preview}
                                      className="icon"
                                    />
                                    Preview
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </GuestEventWrapper>
            </div>
          </Eventstyle>
        </div>
        <button
          type="button"
          className="delteEventBtn"
          onClick={handleDeleteGuestUser}
        >
          <img
            alt="Organization Name"
            src={require("assets/img/invite-icon-white.svg").default}
            className="mr-2"
            width={18}
          />
          Delete Invitation
        </button>
        <Modal
          isOpen={deleteGuestUser}
          toggle={handleDeleteGuestUser}
          // {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
              <img
                src={ResetGeospaceInfoIcon}
                alt=""
                className="geospaceInfoIcon"
              />
              <div className="head">Delete this Invite?</div>
            </div>
            <Row>
              <Col className="px-4">
                <div className="d-flex align-items-center mb-4">
                  <div className="mr-3">
                    <img alt="Organization Name" src={SendInvites} width={70} />
                  </div>
                  <div className="w-100">
                    <div className="fontSize16 borderBottom pb-2 mb-2">
                      Invite 1 Name
                    </div>
                    <div className="inviteStatus d-flex align-items-center ml-auto w-100 justify-content-end">
                      Ready to Send
                      <div className="guestCircleWrapper ml-3">
                        <CircularProgressbar
                          value={percentage}
                          text={false}
                          strokeWidth={20}
                          styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#53D769 ",
                            trailColor: "#1345A5",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eventThumWrapper">
                  <div className="eventThum">
                    <img
                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                      alt=""
                      className="eventThumImg"
                    />
                  </div>
                  <div className="eventThumContent">
                    <div className="eventthumbTitle">Event Name</div>
                    <div className="eventthumbSubTitle">Location</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-4">
                <p>
                  Confirm you want to remove this invite. This will clear your
                  current invitation and template from the list.
                </p>
                <p>
                  Deleting will NOT affect your guest list or RSVPs, nor will it
                  affect any Invites that have already been sent to guests.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button color="primary" className="flex-grow-1 pinkBtn">
              Delete Invite
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleDeleteGuestUser(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddInviteRSVPsPast;
