//Common data
export const COMMON_DATA = "COMMON_DATA";

export const MENU_ONOFF = "MENU_ONOFF";

//profile page
export const USER_ADMIN = "USER_ADMIN";
export const CLEAR_USER_ADMIN_INFO = "CLEAR_USER_ADMIN_INFO";
export const USER_INFO = "USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

//Event Save Data

export const EVENT_SAVE = "EVENT_SAVE";

//Storyz popup
export const STORYZ_POPUP = "STORYZ_POPUP";

//Guest List
export const GUEST_LIST = "GUEST_LIST";
//Guest List Clear
export const GUEST_LIST_CLEAR = "GUEST_LIST_CLEAR";

//Event popup
export const EVENTS_POPUP = "EVENTS_POPUP";

//All Files
export const ALL_FILES = "ALL_FILES";

//Event list
export const EVENTS_LIST = "EVENTS_LIST";

//Story list
export const STORY_LIST = "STORY_LIST";
export const CLEAR_STORY_LIST = "CLEAR_STORY_LIST";

//media list
export const MEDIA_LIST = "MEDIA_LIST";

export const CLEAR_MEDIA = "CLEAR_MEDIA";

export const HONORED_GUEST = "HONORED_GUEST";

export const KEEPSAKE_MEDIA_event = "KEEPSAKE_MEDIA";

export const EVENT_KEEPSAKE_ALBUM = "EVENT_KEEPSAKE_ALBUM";

export const REMOVE_EVENT_KEEPSAKE_ALBUM = "REMOVE_EVENT_KEEPSAKE_ALBUM";
