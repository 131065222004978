import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { combinedService } from "_services/masters/information.combined";
const GuestEditInviteDropdown = ({
  guestListOption,
  data,
  guest_id,
  setUserData,
  storyz_id,
  setguestDelete,
}) => {
  //console.log("data Option", data.id);
  const params = useParams();
  const [selectMenu, setSelectMenu] = useState(false);
  //const [del_invite_id, setDelinviteID] = useState(data.id);

  const selectMenuRef = useRef();
  // console.log("useRef", selectMenuRef);
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div
      className="dropdownBoxWrapper simpletDropdownWrapper"
      ref={selectMenuRef}
    >
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <button className="btn dottedBtn" type="button">
          <BsThreeDots />
        </button>
      </div>
      <div className={`dropdownBoxMenuWrapper ${selectMenu ? "active" : ""}`}>
        <div className="dropdownBoxMenuInner">
          <ul className="dropdownMenuListWrapper">
            {guestListOption.map((option, i) => (
              <li
                className={`menuItem ${option.disabled ? "disabled" : ""}`}
                key={i}
              >
                {option.link ? (
                  <>
                    {/* <Link to={`/admin/manage-invites-edit/${params.sid}/${params.eid}/?type=invites-rsvps`}> */}
                    <Link
                      to={`${option.link}/${data?.id}?type=${option.settype}`}
                    >
                      <span className="icon">
                        <img src={option.img} alt={option.title} />
                      </span>
                      {option.title}
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        option.handleClick(data.id);
                      }}
                    >
                      <span className="icon">
                        <img src={option.img} alt={option.title} />
                      </span>
                      {option.title}
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GuestEditInviteDropdown;
