import React, { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
const placesLibrary = ["places"];

function AutocompleteD(props) {
  const [searchResult, setSearchResult] = useState("Result: none");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY",
    libraries: placesLibrary,
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const obj = parseGoogleAddressComponents(place);
      obj.name = place.name;
      obj.status = place.business_status;
      obj.formattedAddress = place.formatted_address;
      obj.lat = place.geometry.location.lat();
      obj.lng = place.geometry.location.lng();
      props.buttonClicked(obj);
    } else {
      alert("Please enter text");
    }
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  function parseGoogleAddressComponents(place) {
    const address = {};

    place.address_components.forEach((component) => {
      let { long_name, short_name, types } = component;

      if (types.includes("street_number")) {
        address.streetNumber = long_name;
      } else if (types.includes("route")) {
        address.street = long_name;
      } else if (types.includes("neighborhood")) {
        address.neighborhood = long_name;
      } else if (types.includes("locality")) {
        address.city = long_name;
      } else if (types.includes("administrative_area_level_2")) {
        address.county = long_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.state = long_name;
      } else if (types.includes("country")) {
        address.country = short_name;
      } else if (types.includes("postal_code")) {
        address.zip = long_name;
      }
    });

    return address;
  }
  return (
    <div className="App">
      <div id="searchColumn">
        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
          <input
            id="example2cols1Input"
            placeholder="Address"
            type="text"
            name="address"
            className="form-control"
            defaultValue={props?.add}
          />
        </Autocomplete>
      </div>
    </div>
  );
}

export default AutocompleteD;
