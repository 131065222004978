import React from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import Imggroup from "../../assets/img/dummyImg.svg";
import StoryzWebListingDetails from "components/Events/StoryzWebListing/StoryzWebListingDetails";

const StoryzWebListing = () => {
  const param = useParams();

  return (
    <>
      <CombinedGuestPoolHeader title="Storyz Name..." imgScr={Imggroup} arw />
      <Container
        className="accountinformation ptop pl-0 eventLeftMenu bgPannel py-4"
        fluid
      >
        <div className="card" style={{ marginBottom: "0px" }}>
          <StoryzWebListingDetails />
        </div>
      </Container>
    </>
  );
};

export default StoryzWebListing;
