import React, { useEffect } from "react";
import Allheader from "components/Headers/Allheader";
import Imggroup from "../../assets/img/maskGroup.svg";
import EventUploadGuestListDetails from "components/Events/EventUploadGuestListDetails/EventUploadGuestListDetails";

const EventUploadGuestList = () => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  });

  return (
    <>
      <Allheader title="Storyz Name" imgScr={Imggroup} arw />
      <div className="containerWraperOuter">
        <div className="card">
          <EventUploadGuestListDetails />
        </div>
      </div>
    </>
  );
};

export default EventUploadGuestList;
