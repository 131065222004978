import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import searchIcon from "../../assets/img/search-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { userGuestService } from "_services/masters/userGuest.service";
import { toast } from "react-toastify";

import { createPortal } from "react-dom";

const UserGuestListDropdown = ({
  guestListOption,
  selected,
  user_id,
  type,
  searchAPI,
  placeholder,
  handleAddModal,
  addUserBtn,
  onChange,
}) => {
  let arrData = guestListOption ? guestListOption : [];
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState();
  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();
  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });

  const [roleItems, setRoleItems] = useState(selected ? selected : []);
  const [roleItemsid, setRoleItemsid] = useState([]);
  const [status, setStatus] = useState(0);
  const [more, setMore] = useState(false);

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();


    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };

  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuList?.current?.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [clickedArray, setClickedArray] = useState([]);
  const onAdd = (product) => {
    const index = roleItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      setRoleItems([...roleItems, { ...product }]);
      setStatus(1);
    }
  };
  const [fav, setFav] = useState([]);
  useEffect(() => {
    var arr = [];
    let ids = roleItems.map((track) => arr.push(track.id));
    onChange(arr);

    let obj = {};
    obj["user_id"] = user_id;
    obj["type"] = type;
    obj["drop_down_data"] = roleItems;
    //console.log('roleItems onAdd --> ', obj);
    if (status !== 0) {
      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status == "success" && status == 1) {
            //setCount(200)
            toast.success("updated successfully");
            setStatus(0);
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [status]);

  const iclose = (e) => {
    const newPeople = roleItems.filter((person) => person.id !== e.id);
    setRoleItems(newPeople);
  };

  const searchData = (Sval) => {
    searchAPI(Sval, type, user_id);
  };

  return (
    <div
      className="dropdownBoxWrapperSearch selectSearchDropdown"
      ref={selectMenuRef}
    >
      <div
        className="dropdownTriggered"
        onClick={dropdownHandle}
        ref={buttonHeight}
      >
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer d-flex align-items-center flex-wrap">
            {!more &&
              roleItems.slice(0, 2).map((e, i) => {
                return (
                  <>
                    <span className="inviteGuestList" key={i}>
                      {`${e.title}`}
                      <IoMdClose
                        className="ml-2 pointer"
                        size={16}
                        onClick={() => iclose(e)}
                      />
                    </span>
                  </>
                );
              })}
            {more &&
              roleItems.map((e, i) => {
                return (
                  <>
                    <span className="inviteGuestList" key={i}>
                      {`${e.title}`}
                      <IoMdClose
                        className="ml-2 pointer"
                        size={16}
                        onClick={() => iclose(e)}
                      />
                    </span>
                  </>
                );
              })}

            {roleItems.length > 2 ? (
              <span className="more mx-1" onClick={() => setMore(!more)}>
                {more ? "less..." : "more..."}
              </span>
            ) : (
              ""
            )}

            <span className="align-items-center formBoxInput d-inline-flex pr-2">
              <img src={searchIcon} alt="" className="mr-2" />
              {placeholder}
            </span>
          </div>
        </div>
      </div>
      {selectMenu &&
        createPortal(
          <div
            ref={selectMenuList}
            style={{ top: property.top, left: property.left }}
            className={`dropdownBoxMenuWrapperSearch guestListWidth400 left ${selectMenu ? "active" : ""
              }`}
          >
            <div className="dropdownBoxMenuInner">
              <div>
                {addUserBtn ? (
                  <p className="AddGestUser" onClick={handleAddModal}>
                    Add <FaPlus className="icon" />
                  </p>
                ) : null}
              </div>

              <div className="dropdownSearchWrapper">
                <input
                  type="text"
                  placeholder="type to search..."
                  className="form-control"
                  // defaultValue=""
                  onChange={(e) => searchData(e.target.value.toLowerCase())}
                />
              </div>
              <ul className="dropdownMenuListWrapper">
                {arrData.map((option, i) => (
                  <li className="menuItem" key={i}>
                    <div className="guestList" onClick={() => onAdd(option)}>
                      {option.title}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default UserGuestListDropdown;
