import React from 'react'
import Paynow from "./../views/pages/examples/Paynow"
import AuthFooter from 'components/Footers/AuthFooter'

const PaynowLayout = () => {
  return (
    <>
    <div className="main-content login-backimage">
    <Paynow />
    </div>
    <AuthFooter />
    </>
  )
}

export default PaynowLayout