import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import IconImg2 from "../../assets/img/icons/invite-icons.svg";
import { FormGroup, Input, Row, Col, Button, Container } from "reactstrap";
import { eventsService } from "../../_services/masters/events.service";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { informationService } from "../../_services/masters/information.service";
import placeholde from "../../assets/img/placeholder.jpg";
function InviteForm(props) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  // const [successm, setSuccessm] = useState();
  // const [disabledData, setDisabledData] = useState(true);
  const contact = useSelector((state) => {
    return state.profile;
  });

  const [massages, setMassages] = useState("");
  const [guestPool, setGuestPool] = useState([]);
  const [filteData, setFilteData] = useState([]);
  useEffect(() => {
    let organization_id =
      contact?.profiledetails?.organization[0].organization_id;
    eventsService.combinedGuestpool(organization_id).then(
      (res) => {
        if (res.status === "success") {
          setGuestPool(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);
  const handleChange = (event) => {
    let name = event.target.value;

    if (name.length > 0) {
      if (name.length === 1 || name.length === 2) {
        const filteredCountries = guestPool.filter(
          (option) =>
            option.full_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
        );
        setMassages(
          `${filteredCountries.length} matches in combined guest pool`
        );
        setFilteData([]);
      } else {
        const filteredCountries = guestPool.filter(
          (option) =>
            option.full_name.toLowerCase().indexOf(name.toLowerCase()) >= 0
        );
        setFilteData(filteredCountries);
        if (filteredCountries.length === 0) {
          setMassages("No Data");
        } else {
          setMassages();
        }
      }
    } else {
      setMassages("No Data");
    }
  };

  const onSubmit = (data) => {
    informationService.sendInvite(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Send");
          reset();
          if (props.onTrur) {
            props.onClick3();
          } else {
            props.onClick1();
          }
        } else {
          if (props.onTrur === true) {
            props.onClick3();
          } else {
            props.onClick1();
          }
          toast.error(res.message, "Invite");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const onAdd = (option) => {
    setValue("email", option.guest_email_id);
    setFilteData();
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12} md={8} lg={10} className="px-0">
            <div className="inviteFormWrapper">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="containerBox mb-0">
                  <FormGroup>
                    <input
                      id="exampleFormControlInput1"
                      placeholder="type to search..."
                      type="text"
                      name="email"
                      className="form-control"
                      {...register("email", {
                        required: "Email is required",
                        validate: {
                          matchPattern: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) || "Email address must be a valid address",
                        },
                      })}
                      defaultValue={props.email}
                      onChange={handleChange}
                    />
                    {errors.email?.message && (
                      <small style={{ color: "#ff0000" }}>
                        {errors.email.message}
                      </small>
                    )}
                  </FormGroup>
                  {massages && <p className="m-0">{massages}</p>}
                  {filteData && filteData.length > 0 ? (
                    <>
                      <div className="optionindataclass">
                        {filteData.map((option, i) => (
                          <>
                            <div
                              className="indataclass mb-2"
                              key={i}
                              onClick={() => onAdd(option)}
                            >
                              <div className="mediaUserImgWrapper">
                                <img
                                  src={
                                    option.guest_imageUrl
                                      ? option.guest_imageUrl
                                      : placeholde
                                  }
                                  className="mediaUserImg rounded-circle avatar"
                                  alt="https://ourstoryzapi.avdemosites.com/profile-photo/1687164718698-profile-Rectangle.png"
                                />
                              </div>
                              <div className="mediaUserContentWrapper pl-3">
                                <div className="mediaUserTitle">
                                  {option.guest_first_name}{" "}
                                  {option.guest_last_name}{" "}
                                  {option.guest_mobile_number
                                    ? `(${option.guest_mobile_number})`
                                    : ""}
                                </div>
                                <small>{option.guest_email_id}</small>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <p className="m-0">No Matches</p> */}
                  <FormGroup className="roleTeamOuter mb-0">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                    >
                      Team Role:
                    </label>
                    <FormGroup className="teamRoleWrapper mb-0">
                      <Input
                        id="exampleFormControlSelect"
                        type="select"
                        className="flex-grow-1"
                      >
                        <option>Admin</option>
                      </Input>
                      <Button type="submit" className="grayBtnDisabled">
                        <img src={IconImg2} alt="" />
                        Invite Admin
                      </Button>
                    </FormGroup>
                  </FormGroup>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default InviteForm;
