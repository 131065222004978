import { StoryzThemeModalCss } from "./storyzThemeCss";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { informationService } from "../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import storyzThemeLogo from "./../../assets/img/storyzThemeLogo.svg";
import setBg from "./../../assets/img/setBg.svg";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Filemanager from "../CommonMediaModal/Filemanager";

const StoryzThemeModal = ({
  openThemeModal,
  handleThemeClose,
  id,
  eid,
  dataTheme,
  type,
  dataThemeNew,
  ThemeTypeName,
}) => {
  const [changeBg, SetChangeBg] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [chnageColor, setColorChange] = useState({
    screenTxt: "#333333",
    displayTxt: "#ffffff",
    adminTxt: "#333333",
    emphasisTxt: "#ffffff",
    screenBg: "#97a391",
    locationBg: "#778a6e",
    AdminBg: "#cfd8c0",
    EmphasisBg: "#778a6e",
  });

  const colorChnage = (e) => {

    const name = e.target.name;
    const value = e.target.value;
    setColorChange({ ...chnageColor, [name]: value });
  };

  const handleChangeImg = (e) => {
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      SetChangeBg(urldata);

    }
  };

  const onSubmit = (data) => {
    if (type === "story") {
      var datasend = {
        storyz_id: id,
        font: data.font,
        style: data.style,
        size: data.size,
        screen_txt: chnageColor.screenTxt,
        display_txt: chnageColor.displayTxt,
        admin_txt: chnageColor.adminTxt,
        emphasis_txt: chnageColor.emphasisTxt,
        screen_bg: chnageColor.screenBg,
        location_bg: chnageColor.locationBg,
        admin_bg: chnageColor.AdminBg,
        emphasis_bg: chnageColor.EmphasisBg,
        background_image: dataImage ? dataImage : "",
      };

      informationService.saveSettingStoryz(datasend).then(
        (res) => {
          if (res.status === "success") {
            // handleThemeClose();
            toast.success(res.message);
            SetChangeBg(dataImage.url);
            dataThemeNew(datasend);
            dataThemeNew(datasend);
            toast.success(res.message);
            SetChangeBg(dataImage.url);
          } else {
            //toast.error(res.message);
          }
        },
        (error) => {
          //toast.error("Something went wrong !!");
        }
      );
    } else {
      var datasend = {
        event_id: eid,
        font: data.font,
        style: data.style,
        size: data.size,
        screen_txt: chnageColor.screenTxt,
        display_txt: chnageColor.displayTxt,
        admin_txt: chnageColor.adminTxt,
        emphasis_txt: chnageColor.emphasisTxt,
        screen_bg: chnageColor.screenBg,
        location_bg: chnageColor.locationBg,
        admin_bg: chnageColor.AdminBg,
        emphasis_bg: chnageColor.EmphasisBg,
        background_image: dataImage ? dataImage : "",
      };
      informationService.saveSettingEvent(datasend).then(
        (res) => {
          if (res.status === "success") {
            // handleThemeClose();
            toast.success(res.message);
            dataThemeNew(datasend);
          } else {
            //toast.error(res.message);
          }
        },
        (error) => {
          //toast.error("Something went wrong !!");
        }
      );
    }
  };

  useEffect(() => {
    setValue("font", dataTheme?.font ? dataTheme?.font : "Georgia");
    setValue("style", dataTheme?.style ? dataTheme?.style : "normal");
    setValue("size", dataTheme?.size ? dataTheme?.size : "12px");

    setColorChange({
      screenTxt: dataTheme?.screen_txt ? dataTheme?.screen_txt : "#333333",
      displayTxt: dataTheme?.display_txt ? dataTheme?.display_txt : "#ffffff",
      adminTxt: dataTheme?.admin_txt ? dataTheme?.admin_txt : "#333333",
      emphasisTxt: dataTheme?.emphasis_txt
        ? dataTheme?.emphasis_txt
        : "#ffffff",
      screenBg: dataTheme?.screen_bg ? dataTheme?.screen_bg : "#97a391",
      locationBg: dataTheme?.location_bg ? dataTheme?.location_bg : "#778a6e",
      AdminBg: dataTheme?.admin_bg ? dataTheme?.admin_bg : "#cfd8c0",
      EmphasisBg: dataTheme?.emphasis_bg ? dataTheme?.emphasis_bg : "#778a6e",
    });

    SetChangeBg(dataTheme?.background_image);
  }, [dataTheme]);
  const [dataImage, setDataImage] = React.useState();
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;

    setDataImage(jsonData[0]);
    SetChangeBg(jsonData[0].url);
  };
  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);
    setDataImage(obj);
    SetChangeBg(obj.url);
    setModalShow(false);
  };
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  const [modalShow, setModalShow] = React.useState(false);
  const [allimage, setAllimage] = React.useState([]);
  const handleClick = () => {
    setModalShow(false);
  };

  const removeImage = () => {
    SetChangeBg("")
    if (type === 'story') {
      if (dataTheme && dataTheme?.id) {
        let id = dataTheme?.id;
        //removeSettingImgs
        informationService.removeSettingImgs(id).then(
          (res) => {
            if (res.status === "success") {
              // handleThemeClose();
              setDataImage();
              dataThemeNew();
              toast.success(res.message);
            } else {
              //toast.error(res.message);
            }
          },
          (error) => {
            //toast.error("Something went wrong !!");
          }
        );
      }
    } else {
      if (dataTheme && dataTheme?.id) {

        let id = dataTheme?.id;

        informationService.removeSettingImgev(id).then(
          (res) => {
            if (res.status === "success") {
              // handleThemeClose();
              setDataImage();
              dataThemeNew();
              toast.success(res.message);
            } else {
              //toast.error(res.message);
            }
          },
          (error) => {
            //toast.error("Something went wrong !!");
          }
        );
      }
    }

  }

  return (
    <>
      <ToastContainer />
      <StoryzThemeModalCss
        className={`modal fade storyzThemeModal ${openThemeModal ? "show" : ""
          }`}
        id="storyzThemeModal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  <img
                    src={storyzThemeLogo}
                    className="storyzThemeLogo"
                    alt=""
                  />
                  {ThemeTypeName} Theme
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleThemeClose}
                >
                  <GrClose />
                </button>
              </div>
              <div className="modal-body">
                <div className="textBox">
                  <div className="h4">Theme: Japanese Wave</div>
                  <div className="h4">Component: Event</div>
                  <p>
                    Set an overall theme for your Storyz. Your theme provides a
                    default for your events, invites, and website listings. You
                    can apply a separate theme to individual events or invites.
                  </p>
                </div>
                <div className="textBox">
                  <div className="h5 fontWeight400">Display Type:</div>
                  <div className="displayTypeWrapper">
                    <div className="mb-3 mx-0 row p-0">
                      <label className="col col-form-label p-0 label">
                        Font:
                      </label>
                      <div className="col px-0">
                        {/* <Select
                        defaultValue={selectFont}
                        onChange={setSelectFont}
                        options={fontList}
                        className="displayDropdown"
                      /> */}
                        <select
                          className="displayDropdown"
                          {...register("font")}
                        >
                          <option>Select</option>
                          <option value="Great Vibes">Default</option>
                          <option>Georgia</option>
                          <option>Palatino Linotype</option>
                          <option>Book Antiqua</option>
                          <option>Times New Roman</option>
                          <option>Arial</option>
                          <option>Helvetica</option>
                          <option>Arial Black</option>
                          <option>Impact</option>
                          <option>Lucida Sans Unicode</option>
                          <option>Tahoma</option>
                          <option>Verdana</option>
                          <option>Courier New</option>
                          <option>Lucida Console</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-3 mx-0 row p-0">
                      <div className="col mx-0 row px-0">
                        <label className="col col-form-label p-0 label">
                          Style:
                        </label>
                        <div className="col px-0">
                          <select
                            className="displayDropdown"
                            {...register("style")}
                          >
                            <option>Select</option>
                            <option value="normal">Default</option>
                            <option>normal</option>
                            <option>italic</option>
                            <option>oblique</option>
                          </select>

                          {/* <Select
                          defaultValue={selectStyle}
                          onChange={setSelectStyle}
                          options={fontStyle}
                          className="displayDropdown"
                        /> */}
                        </div>
                      </div>
                      <div className="col mx-0 row px-0">
                        <label className="col col-form-label p-0 label pl-2">
                          Size:
                        </label>
                        <div className="col px-0">
                          {/* <Select
                          defaultValue={selectFontSize}
                          onChange={setSelectFontSize}
                          options={fontSize}
                          className="displayDropdown"
                        /> */}
                          <select
                            className="displayDropdown"
                            {...register("size")}
                          >
                            <option>Select</option>
                            <option value={type === "story" ? "36px" : "48px"}>
                              Default
                            </option>
                            <option>12px</option>
                            <option>14px</option>
                            <option>16px</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="h5">
                    Screen Palette (used in CMS and native app):
                  </h5>
                  <div className="palatteWrapper">
                    <div className="pallateList">
                      <div className="palatteTxt">Screen txt</div>
                      <div
                        className="colorPallate screenTxt brdrTxt pointer"
                        style={{ background: `${chnageColor["screenTxt"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="screenTxt"
                          value={chnageColor["screenTxt"]}
                          onChange={colorChnage}
                        // {...register("screenTxt")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["screenTxt"]}
                      </div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Display txt</div>
                      <div
                        className="colorPallate displayTxt brdrTxt pointer"
                        style={{ background: `${chnageColor["displayTxt"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="displayTxt"
                          value={chnageColor["displayTxt"]}
                          onChange={colorChnage}
                        // {...register("displayTxt")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["displayTxt"]}
                      </div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Admin txt</div>
                      <div
                        className="colorPallate adminTxt brdrTxt pointer"
                        style={{ background: `${chnageColor["adminTxt"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="adminTxt"
                          value={chnageColor["adminTxt"]}
                          onChange={colorChnage}
                        // {...register("adminTxt")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["adminTxt"]}
                      </div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Emphasis txt</div>
                      <div
                        className="colorPallate emphasisTxt brdrTxt pointer"
                        style={{ background: `${chnageColor["emphasisTxt"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="emphasisTxt"
                          value={chnageColor["emphasisTxt"]}
                          onChange={colorChnage}
                        // {...register("emphasisTxt")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["emphasisTxt"]}
                      </div>
                    </div>
                  </div>
                  <div className="palatteWrapper">
                    <div className="pallateList">
                      <div className="palatteTxt">Screen bg</div>
                      <div
                        className="colorPallate screenBg brdrBg pointer"
                        style={{ background: `${chnageColor["screenBg"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="screenBg"
                          value={chnageColor["screenBg"]}
                          onChange={colorChnage}
                        // {...register("screenBg")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["screenBg"]}
                      </div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Location bg</div>
                      <div
                        className="colorPallate locationBg brdrBg pointer"
                        style={{ background: `${chnageColor["locationBg"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="locationBg"
                          value={chnageColor["locationBg"]}
                          onChange={colorChnage}
                        // {...register("locationBg")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["locationBg"]}
                      </div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Admin bg</div>
                      <div
                        className="colorPallate adminBg brdrBg pointer"
                        style={{ background: `${chnageColor["AdminBg"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="AdminBg"
                          value={chnageColor["AdminBg"]}
                          onChange={colorChnage}
                        // {...register("AdminBg")}
                        ></input>
                      </div>
                      <div className="palatteTxt">{chnageColor["AdminBg"]}</div>
                    </div>
                    <div className="pallateList">
                      <div className="palatteTxt">Emphasis bg</div>
                      <div
                        className="colorPallate emphasisBg brdrBg pointer"
                        style={{ background: `${chnageColor["EmphasisBg"]}` }}
                      >
                        <input
                          type="color"
                          className="colorInput pointer"
                          name="EmphasisBg"
                          value={chnageColor["EmphasisBg"]}
                          onChange={colorChnage}
                        // {...register("EmphasisBg")}
                        ></input>
                      </div>
                      <div className="palatteTxt">
                        {chnageColor["EmphasisBg"]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="textBox pb-3">
                  <h6 className="h6">Background Image (overlays screen bg)</h6>
                  <div className="backgroundImgWrapper">
                    <div className="setImgWrapper">
                      <div className="bgPalatte">
                        {changeBg ? (
                          <img
                            src={changeBg ? changeBg : setBg}
                            // src={changeBg ? changeBg : ""}
                            alt=""
                            className="bgImg"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="setImgChip"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        Set Image
                      </div>
                      {/* {changeBg && (
                      <span className="closeChip" onClick={removeImage}>
                        <GrClose />
                      </span>
                      )} */}
                      {changeBg ? (
                        <span className="closeChip" onClick={removeImage}>
                          <GrClose />
                        </span>
                      ) : (
                        <span className="ml-3 redClr">No Image</span>
                      )}

                      {modalShow ? (
                        <Filemanager
                          show={modalShow}
                          onHide={handleClick}
                          onClickimageUpdate={imageUpdate}
                          list={allimage}
                          seletedImage={seletedImageClike}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-2 pt-4 px-0">
                <button type="submit" className="btn saveChangesBtn">
                  Save Changes
                </button>
                <button
                  type="button"
                  className="btn cancelBtn"
                  onClick={handleThemeClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </StoryzThemeModalCss>
    </>
  );
};
export default StoryzThemeModal;
