import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  units: "english",
  latitude: 0,
  longitude: 0,
  motion: 0,
  elevation: 0,
  radius: 10,
  address: ""
}

const geofenceSlice = createSlice({
  name: "geofence",
  initialState,
  reducers: {
    changeUnits(state, action) {
      state.units = action.payload
    },
    changeLatitude: {
      reducer(state, action) {
        state.latitude = action.payload
      },
      prepare(latitude) {
        if (typeof latitude === "number") return { payload: latitude }
        const value = parseFloat(latitude)
        return {
          payload: Number.isNaN(value) ? 0 : value
        }
      }
    },
    changeLongitude: {
      reducer(state, action) {
        state.longitude = action.payload
      },
      prepare(longitude) {
        if (typeof longitude === "number") return { payload: longitude }
        const value = parseFloat(longitude)
        return {
          payload: Number.isNaN(value) ? 0 : value
        }
      }
    },
    changeMotion: {
      reducer(state, action) {
        state.motion = action.payload
      },
      prepare(motion) {
        if (typeof motion === "number") return { payload: motion }
        const value = parseFloat(motion)
        return {
          payload: Number.isNaN(value) ? 0 : value
        }
      }
    },
    changeElevation(state, action) {
      state.elevation = action.payload
    },
    changeRadius: {
      reducer(state, action) {
        state.radius = action.payload
      },
      prepare(radius) {
        if (typeof radius === "number") return { payload: radius }
        const value = parseFloat(radius)
        return {
          payload: Number.isNaN(value) ? 10 : value
        }
      }
    },
    changeAddress(state, action) {
      state.address = action.payload
    }
  }
})

export const {
  changeUnits,
  changeElevation,
  changeLatitude,
  changeLongitude,
  changeMotion,
  changeRadius,
  changeAddress
} = geofenceSlice.actions

export default geofenceSlice.reducer
