import { MENU_ONOFF } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    meunon: true,
};
function addressSaveReducer(state = initialState, action) {
    switch (action.type) {
        case MENU_ONOFF:
            return {
                ...state,
                isConfirmatiom: true,
                meunon: action.payload,
            };
        default:
            return state;
    }
}
export default addressSaveReducer;
