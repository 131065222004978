import * as React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import SocialGoogleLogin from "../../../components/Sociallogin/SocialGoogleLogin";
import SocialFacebookLogin from "../../../components/Sociallogin/SocialFacebookLogin";

import { useForm } from "react-hook-form";
import { userService } from "../../../_services/login/user.service";
import { informationService } from "../../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { userAdminData } from "store/actions";
import { FaChevronLeft } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthHeader from "components/Headers/AuthHeader.js";
import * as actionTypes from "../../../store/actionTypes";
import { clearstoryList, clearuserData } from "../../../store/actions/index";

import { useHistory } from "react-router-dom";
function SingupFinlPage() {
  const currentUrlArr = window.location.href.split("?");
  let email = "";
  if (currentUrlArr.length > 1) {
    let queryDataArr = currentUrlArr[1].split("=");
    email = queryDataArr[1];
  }
  //console.log('email:', email);
  //email
  const history = useHistory();
  const dispatch = useDispatch();

  const contact = useSelector((state) => {
    return state.profile;
  });

  const admindata = useSelector((state) => {
    return state.userAdmin.listData;
  });

  React.useEffect(() => {
    dispatch(clearstoryList());

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === "" || userDetails === null) {
      dispatch(clearuserData());
    }

    // if (contact.isConfirmatiom === true) {
    //   history.push('/admin/account-information');
    // }

  }, []);

  const [emailId, setEmail] = React.useState(email);
  const [emailIdErr, setEmailIdErr] = React.useState(
    email == "" ? "Signup email id not found" : emailId
  );
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [formemail, setFormemail] = React.useState("");
  const [formpassword, setFormpassword] = React.useState("");
  const [formoption, setFormoption] = React.useState();

  const [local, setLocal] = React.useState(false);
  const [codeErr, setCodeErr] = React.useState("");
  const [Scode, setCode] = React.useState("");
  const [passval, setPassval] = React.useState("");
  const [repassErr, setRePassErr] = React.useState("");
  const [repassval, setRePassval] = React.useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  // React.useEffect(() => {
  //   if (localStorage.getItem('userDetails')) {
  //     history.push('/admin/account-information');
  //   }
  // }, [])
  const localMethod = () => {
    setLocal(false);
  };
  const localMethodBack = () => {
    setLocal(true);
  };

  const checkCode = (singup) => {
    //console.log( "code", code.length);
    setCode(singup);
    if (singup.length < 6) {
      setCodeErr("Code invalid");
    } else {
      setCodeErr("");
    }
  };

  const Login = () => {
    let data = { password: Scode, email: "guest008@yopmail.com" };
    console.log("data====", data);
    userService.verifyCode(data).then((res) => {
      if (res.status == "success") {
        console.log("Done");
      } else {
        console.log("Not Done");
      }
    });
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            {/* you are all set  */}
            <Card className="allSetModalWrapper">
                <CardBody className="bg-white ">
                  <div className="heading">
                    <img
                      alt="OurStoryz"
                      src={
                        require("assets/img/modal-icons/allsetIcon.svg")
                          .default
                      }
                    /> You're all set!
                    </div>
                        <div className="succesIcon">
                          <img
                          alt="OurStoryz"
                          src={
                            require("assets/img/modal-icons/succes-icons.svg")
                              .default
                          }
                        />
                        </div>
                   <p className="logniMethodText">Select "Complete Setup" and we"ll take you brand-new-event. You can add or adjust it at any time. By completing signup, I agree that OurStoryz may share information with selected partners or affiliates, and provide me with special offers and other promotions</p>
                   <p className="logniMethodText">By clicking "Complete Setup" I agree to the OurStoryz Terms of Use and our Privacy Policy. you can adjust your privacy settings after your account is set up</p>
                    <div className="text-center card-footer">
                       <Button className="my-4 m-0 eventBtn">
                        Go to your events
                      </Button>
                    </div>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            <Card className="bgsecondary border-0 mb-0 pb-3">
              <CardHeader className="bg-white pb-0">
                <Row>
                  <Col
                    xs="12"
                    style={{ textAlign: "right", margin: "0px" }}
                    className="mb-5"
                  >
                    <span style={{ width: "54px" }}>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "bold",
                          fontSize: "24px",
                          lineHeight: "32px",
                        }}
                      >
                        <img
                          alt="OurStoryz"
                          src={
                            require("assets/img/icons/common/Logomark.svg")
                              .default
                          }
                          style={{ padding: "5px" }}
                        />
                        OurStoryz
                      </h1>
                    </span>

                    <span
                      style={{
                        color: "#FC3158",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      <Link
                        className="font-weight-bold text-warning mt-5"
                        to="/"
                      >
                        Login
                      </Link>
                    </span>
                  </Col>
                </Row>
                </CardHeader>

              {!local && (
                <CardBody className="bg-white pt-0">
                  <div className="loginMethod">You're all set!</div>
                   <p className="logniMethodText">Select "Complete Setup" and we"ll take you brand-new-event. You can add or adjust it at any time. By completing signup, I agree that OurStoryz may share information with selected partners or affiliates, and provide me with special offers and other promotions</p>

                   <p className="logniMethodText">By clicking "Complete Setup" I agree to the OurStoryz Terms of Use and our Privacy Policy. you can adjust your privacy settings after your account is set up</p>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                     
                       <small className="error">{codeErr}</small>
                      <InputGroup
                        className={`input-group-merge input-group-alternative passInput`}
                      >
                        <input
                          className={`form-control`}
                          placeholder="type code here"
                          type="hidden"
                          name="code"
                          value={Scode}
                          onChange={(e) => checkCode(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup
                        className={`input-group-merge input-group-alternative passInput`}
                      >
                        <input
                          className={`form-control`}
                          placeholder="type code here"
                          type="hidden"
                          name="code"
                          value={Scode}
                          onChange={(e) => checkCode(e.target.value)}
                        />
                    </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                       <Button className="my-4" onClick={Login}>
                        Go to your events
                      </Button>
                    
                    </div>
                   </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container> */}
      <ToastContainer />
    </>
  );
}

export default SingupFinlPage;
