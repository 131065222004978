import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import searchIcon from "../../assets/img/search-icon.svg";
import { IoMdClose } from "react-icons/io";
import placeholde from "../../assets/img/placeholder.jpg";
import { useFindPathEid } from "../../CommonData";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Container, Row, Col, Button } from "reactstrap";
import QuestionImg from "../../assets/img/circled-icon.svg";
import DropdownRole from "./DropdownRole";
import DropdownGuest from "./DropdownGuest";
import { eventsService } from "../../_services/masters/events.service";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { event } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
const HonoredGuestRolesDropdownadd = ({
  placeholder,
  onComponentClick,
  onClickrole,
  roledata,
  pop,
  selectdata,
  gid,
}) => {
  useEffect(() => {
    setRoleList(roledata);
    if (pop === true) {
      setAddRolepopus(true);
    }
  }, [roledata]);

  const [addRolepopus, setAddRolepopus] = useState(false);
  const [eventdata, setEventdata] = useState();
  const [roletype, setroleType] = useState("");

  const [rolsList, setRoleList] = useState({});

  const [isthe, setIsthe] = useState("");
  const [forthe, setForthe] = useState("");

  const [eventType, setEventType] = React.useState([]);
  const [defaultModale, setDefaultModale] = React.useState(false);
  let [showInfo1, setShowInfo1] = useState(false);

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  useEffect(() => {
    setFruits(selectdata);

    setArrayOfObjects(selectdata);
  }, [selectdata]);

  let eid = common.eid;
  let sid = common.sid;
  const [userguestList, setUserguestList] = React.useState([]);

  const [addListModal, setAddListModal] = useState(false);
  const [deleteHonorList, setDeleteHonorList] = useState(false);
  const [roleDelete, setRoleDelete] = React.useState([]);

  const handelDeleteHonorList = (event) => {
    event.stopPropagation();
    setDeleteHonorList(!deleteHonorList);
  };

  const options = eventType;
  const AddRoleModal = (event) => {
    // event.stopPropagation();
    setAddRolepopus(!addRolepopus);

    userGuestService.userGuestListStory(eid, sid).then((res) => {
      if (res.status === "success") {

        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  };
  const handleClick = () => {
    setDefaultModale(true);
    setAddListModal(!addListModal);
  };
  const honorRelationship = (e) => {
    e.preventDefault();
    if (forthe) {
      var data = {
        guest_id: rolsList.id,
        relation_id: isthe,
        guest_relation_id: forthe,
        event_id: eventdata.id,
      };
    } else {
      var data = {
        guest_id: rolsList.id,
        relation_id: isthe,
        event_id: eventdata.id,
      };
    }

    userGuestService.addguesteventRole(data).then(
      (res) => {
        if (res.status === "success") {
          setIsthe("");
          setForthe("");
          setAddRolepopus(!addRolepopus);

          onComponentClick();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };
  const validateInput = (e) => {
    e.preventDefault();

    const data = { role_name: e.target.role_type.value };
    userGuestService.addRole(data).then(
      (res) => {
        if (res.status === "success") {
          setroleType("");
          setAddListModal(!addListModal);
          roleall();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  useEffect(() => {
    roleall();

    const id = { event_id: eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          setEventdata(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [eid]);

  function roleall() {
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  const [fruits, setFruits] = useState([]);

  const [fruitsid, setFruitsid] = useState([]);

  const [arrayOfObjects, setArrayOfObjects] = useState([]);

  const [error, setError] = useState(false);
  const AddRoleModalid = () => {
    if (!isthe.id) {
      setError(true);
      return null;
    } else {
      const newObject = {
        relation_id: isthe.id ? isthe.id : "", // this values will come from your form
        guest_relation_id: forthe.id ? forthe.id : "", // depending on your implementation you may have to maintain separate state for each property
        title: isthe.title ? isthe.title : "",
      };

      const index1 = arrayOfObjects.findIndex(
        (item) => item.relation_id === isthe.id
      );

      if (index1 > -1) {
      } else {
        const newState = [...arrayOfObjects, newObject];
        setArrayOfObjects(newState);
        let obj = {};
        obj["user_id"] = gid;
        obj["type"] = "guest_relation";
        obj["event_id"] = eventdata.id;
        obj["drop_down_data"] = newState;
        //const index1 = arrayOfObjects.findIndex((item) => item.relation_id === isthe.id);
        userGuestService.updateDropDown(obj).then(
          (res) => {
            if (res.status == "success") {
              toast.success("inset successfully");
            } else {
              console.log("Something went wrong !!");
              //toast.error(res.message);
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            //toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }
      const index = fruits.findIndex((item) => item.id === isthe.id);
      if (index > -1) {
      } else {
        setFruits([...fruits, isthe]);
        setFruitsid([...fruitsid, isthe.id]);
      }

      setAddRolepopus(!addRolepopus);
    }
    setIsthe({});
    setForthe({});
    setError(false);
  };

  const deleteGuestfromHonoredList = () => {
    const newPeople = fruits.filter((person) => person.id !== roleDelete.id);
    setFruits(newPeople);

    const newPeople1 = fruitsid.filter((person) => person !== roleDelete.id);
    setFruitsid(newPeople1);

    var data = {
      guest_id: gid,
      relation_id: roleDelete.relation_id,
      event_id: eventdata.id,
    };




    userGuestService.removeguestrole(data).then((res) => {
      if (res.status === "success") {
        // roleall();
        const newPeo = arrayOfObjects.filter(
          (person) => person.relation_id !== roleDelete.relation_id
        );

        setArrayOfObjects(newPeo);
        setDeleteHonorList(!deleteHonorList);

        toast.success("Delete successfully");
      } else {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    onClickrole(arrayOfObjects);
  }, [arrayOfObjects]);

  return (
    <div className="dropdownBoxWrapper selectSearchDropdown">
      <div className="dropdownTriggered" onClick={AddRoleModal}>
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer groupMemberWrapper d-flex align-items-center widthAuto">
            {arrayOfObjects?.map((item, i) => {
              return (
                <div
                  className="groupMemberList"
                  onClick={(event) => {
                    handelDeleteHonorList(event);
                    setRoleDelete(item);
                  }}
                  key={i}
                >
                  {/* <img src={placeholde} alt="" className="avatar" /> */}
                  {item.title}

                  <IoMdClose
                    className="ml-2 pointer"
                    size={16}
                    onClick={(event) => {
                      handelDeleteHonorList(event);
                      setRoleDelete(item);
                    }}
                  />
                </div>
              );
            })}

            <div
              className="align-items-center formBoxInput pr-2 ml-1"
              onClick={AddRoleModal}
            >
              <img src={searchIcon} alt="" className="mr-2" />
              {placeholder}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={addRolepopus}
        toggle={AddRoleModal}
        className="honoredRelationshipModal"
      >
        <form onSubmit={honorRelationship}>
          <ModalBody>
            <div className="HonoredGuestGroupHeader border-bottom">
              <img src={QuestionImg} alt="" className="icon" />
              <div className="head">
                Add an Honor and Relationship for this Guest
              </div>
            </div>
            <Row>
              <Col>
                {rolsList ? (
                  <div className="deleteGuestGroupThumWrapper">
                    <div className="deleteGuestGroupThum">
                      <img
                        src={placeholde}
                        alt=""
                        className="deleteGuestGroupThumImg"
                      />
                    </div>
                    <div className="deleteGuestGroupThumContent">
                      <div className="deleteGuestGroupthumbTitle">
                        {rolsList?.first_name + " " + rolsList?.last_name}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="honorRelationshipFormWrapper">
                  <div className="form-group">
                    <label htmlFor="isthe" className="control-label">
                      is the
                    </label>

                    <DropdownRole
                      isSearchable
                      handleClick={handleClick}
                      placeHolder="Select..."
                      options={options}
                      onChange={(value) => {
                        setIsthe(value);
                      }}
                      onClickOutside={showInfo1}
                      addButton={true}
                      customClass={"honorDropdown"}
                    />
                    {error ? (
                      <span style={{ color: "red" }}>this is required</span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="thefor" className="control-label">
                      for the
                    </label>

                    <DropdownGuest
                      isSearchable
                      handleClick={handleClick}
                      placeHolder="Select..."
                      options={userguestList}
                      onChange={(value) => {
                        setForthe(value);
                      }}
                      onClickOutside={showInfo1}
                      addButton={false}
                      customClass={"honorDropdown"}
                      gid={gid}
                    />
                  </div>
                  {eventdata ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="regular1" className="control-label">
                          at the
                        </label>
                        <div className="repeateventDetails">
                          <div>
                            <img
                              className="evimages"
                              src={
                                eventdata?.cover_image
                                  ? eventdata?.cover_image
                                  : placeholde
                              }
                              alt=""
                            />
                          </div>
                          <div className="repeateventTitle">
                            <div>{eventdata?.event_name}</div>
                            <div className="repeateventlocation">
                              {eventdata?.location}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div
              color="flex-grow-1 "
              className="btn btn-primary pinkBtn primary pinkBtn flex-grow-1"
              onClick={AddRoleModalid}
            >
              Add Honor and Relationship
            </div>
            <div className="btn btn-default cancelBtn" onClick={AddRoleModal}>
              Cancel
            </div>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        className="modal-dialog-centered addListModalWrapper"
        isOpen={addListModal}
      >
        <ModalBody>
          <div className="modal-header border-bottom">
            <img
              alt="..."
              src={require("assets/img/Icon-Text-in-Circle.svg").default}
              width="75px"
              height="75px"
              className="icon"
            />
            <div className="head">Add a New List Item?</div>
          </div>

          <form onSubmit={validateInput}>
            <p>Confirm that you want to add a list item to this list.</p>
            <p>Make sure you want to do this!</p>

            <div className="addNewListWrapper">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add New Item"
                  name="role_type"
                  value={roletype}
                  onChange={(e) => setroleType(e.target.value)}
                  required
                />
                <div className="btn pinkBtn height44" type="submit">
                  Add
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="btn cancelBtn w-100" onClick={handleClick}>
            Cancel
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteHonorList}
        toggle={handelDeleteHonorList}
        className="removeGuestFromHonoredModal"
      >
        <ModalBody>
          <div className="modal-header border-bottom">
            <img
              alt="..."
              src={require("assets/img/Icon-Text-in-Circle.svg").default}
              width="75px"
              height="75px"
              className="icon"
            />
            <div className="head">Remove Guest From Honored List?</div>
          </div>
          <Row>
            <Col>
              {eventdata ? (
                <>
                  <div className="repeateventDetails">
                    {/* <div className="repeateeventDate date pr-2">14 Aug</div> */}
                    <div>
                      <img
                        className="evimages"
                        src={
                          eventdata?.cover_image
                            ? eventdata?.cover_image
                            : placeholde
                        }
                        alt=""
                      />
                    </div>
                    <div className="repeateventTitle">
                      <div>{eventdata?.event_name}</div>
                      <div className="repeateventlocation">
                        {eventdata?.location}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {rolsList ? (
                <div className="deleteGuestGroupThumWrapper">
                  <div className="deleteGuestGroupThum">
                    <img
                      src={placeholde}
                      alt=""
                      className="deleteGuestGroupThumImg "
                    />
                  </div>
                  <div className="deleteGuestGroupThumContent">
                    <div className="deleteGuestGroupthumbTitle">
                      {rolsList?.first_name + " " + rolsList?.last_name}
                    </div>
                    <span className="adminBadge">ADMIN</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="removeGuestHonoreContent">
                <p>You have removed all honors for this guest.</p>
                <p>
                  They will be deleted from the Honored Guest List, unless you
                  keep at least 1 honor.
                </p>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary pinkBtn"
            className="flex-grow-1"
            onClick={deleteGuestfromHonoredList}
          >
            Delete Guest from Honored List
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={handelDeleteHonorList}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HonoredGuestRolesDropdownadd;
