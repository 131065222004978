import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
let userToken = JSON.parse(localStorage.getItem('userDetails'));

export const objectsApi = createApi({
	reducerPath: "objectsTmp",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_SERVER_URL,
		prepareHeaders: headers => {
			headers.set("token", `${userToken.token}`)
			return headers
		}
	}),
	tagTypes: ["Beacons", "Tables", "Others"],
	endpoints: builder => ({
		getBeacons: builder.query({
			// query: ({ geoId }) => ({
			// 	url: `geospace/${geoId}/beacons`,
			// 	method: "POST"
			// }),
			providesTags: ["Beacons"]
		}),
		getTables: builder.query({
			// query: ({ geoId }) => ({
			// 	url: `geospace/${geoId}/tables`,
			// 	method: "POST"
			// }),
			providesTags: ["Tables"]
		}),
		getOthers: builder.query({
			// query: ({ geoId }) => ({
			// 	url: `geospace/${geoId}/others`,
			// 	method: "POST"
			// }),
			providesTags: ["Others"]
		}),
		updateBeacon: builder.mutation({
			// query: ({ geoId, id, lat, lng }) => ({
			// 	url: `geospace/${geoId}/beacons/${id}/update`,
			// 	method: "PATCH",
			// 	body: { lat, lng }
			// }),
			invalidatesTags: ["Beacons"]
		}),
		updateTable: builder.mutation({
			// query: ({ geoId, id, lat, lng }) => ({
			// 	url: `geospace/${geoId}/tables/${id}/update`,
			// 	method: "PATCH",
			// 	body: { lat, lng }
			// }),
			invalidatesTags: ["Tables"]
		}),
		updateOther: builder.mutation({
			// query: ({ geoId, id, lat, lng }) => ({
			// 	url: `geospace/${geoId}/others/${id}/update`,
			// 	method: "PATCH",
			// 	body: { lat, lng }
			// }),
			invalidatesTags: ["Others"]
		})
	})
})

export const {
	useGetBeaconsQuery,
	useGetTablesQuery,
	useGetOthersQuery,
	useUpdateBeaconMutation,
	useUpdateTableMutation,
	useUpdateOtherMutation
} = objectsApi
