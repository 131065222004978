import React from "react";
import { Card, CardHeader, CardBody, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import { informationService } from "../../_services/masters/information.service";
import DummyImg from "../../assets/img/dummyImg.svg";
function Yourstoryz() {
  const [dataList, setdataList] = React.useState([]);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const valueName = e.target.value;

    const data = {
      storyz_name: valueName,
    };
    informationService.projectSearch(data).then(
      (res) => {
        if (res.status === "success") {
          setdataList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  React.useEffect(() => {
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          setdataList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);
  return (
    <>
      <Card key={Math.random()}>
        <CardHeader>
          <div className="cardheader2">
            <div className="cardheader">
              <img
                alt="Organization Name"
                src={require("assets/img/icons/your-storyz.svg").default}
                className="icon"
              />

              <h3 className="mb-0 headingNew">Your Storyz</h3>
            </div>
            <div>
              <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                  className="icon"
                />
              </span>
              <ReactTooltip />
            </div>
          </div>
        </CardHeader>
        <hr style={{ margin: "0px" }} />
        <CardBody>
          <p>
            To access events, select a Storyz, or use the “Events” side menu.
          </p>
          <FormGroup>
            <input
              id="example2cols1Input"
              placeholder="type to search..."
              type="text"
              onChange={handleChange}
              className="form-control"
            />
          </FormGroup>
          <section className="youStoryzDashboardWrapper">
            {dataList.length > 0 ? (
              <>
                {dataList.map((popup, i) => (
                  <div key={Math.random()}>
                    <Link to={`/admin/storyz/${popup.id}`}>
                      <div
                        style={{ display: "flex", marginBottom: "15px" }}
                        className="story1"
                        key={Math.random()}
                      >
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              popup.project_image
                                ? popup.project_image
                                : DummyImg
                            }
                          />
                        </div>
                        <div>
                          <p className="title">{popup.storyz_name}</p>
                          <p className="subtitle">{popup.event_count} Events</p>
                          <p className="subtitle1">City/State for All Events</p>
                          <p className="subtitle1">{popup.greeting_title == null || popup.greeting_title == "null" ? "" : popup.greeting_title}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              "No Storyz Data"
            )}
          </section>
          <Link
            to="#"
            className="MemberTeam yourStoryzBtn"
            onClick={() => {
              dispatch({ type: actionTypes.STORYZ_POPUP, payload: true });
            }}
          >
            {" "}
            New Storyz
          </Link>
        </CardBody>
      </Card>
    </>
  );
}
export default Yourstoryz;
