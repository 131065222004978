import React from "react";
import IconImg from "../../assets/img/icons/system-logos.svg";
import Upgradesevise from "../../assets/img/Logomark123.svg";
import { Navbar } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Dashicons from "../../assets/img/dashicons_arrow-left-alt2.svg";
import { useDispatch } from "react-redux";
import { menuSave } from "../../store/actions/index";
function Allheader(props) {
  const [check, setCheck] = React.useState(false);
  const dispatch = useDispatch();
  let history = useHistory();

  const handleClick = () => {
    setCheck((prevCheck) => !prevCheck);
    dispatch(menuSave(check));
    // history.push(`/admin/storyz/${common?.sid}`);
    // history.goBack();
    history.push(`/admin/storyz/${props?.id}`)
  };
  //console.log("props.params",props)
  //console.log("props.params?.sid",props?.id)
  return (
    <>
      <Navbar className="topbarnavbar navBar">
        <div className="menuheader" >
          <img src={Dashicons} className="dashIcon" alt="" onClick={handleClick}/>
          <img
            src={props.imgScr ? props.imgScr : IconImg}
            className="dashIcon1"
            alt=""
          />
          <h1 className="title">
            <span className="badge">Storyz</span>
            <div
              className="pointer"
            // onClick={() => {
            //   history.push(`/admin/storyz/${params?.sid}`);
            // }}
            onClick={handleClick}
            >
              {" "}
              {props.title}
            </div>
          </h1>
        </div>
        <Link to="/admin/customer-support">
          <img src={Upgradesevise} alt="" className="pr-3" />
        </Link>
      </Navbar>
    </>
  );
}
export default Allheader;
