import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { informationService } from "../../_services/masters/information.service";
export default function Events() {
  const params = useParams();
  const history = useHistory();
  const [eventList, seteventList] = React.useState([]);
  const [eventList2, seteventList2] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    informationService.eventList(params.sid).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
          seteventList2(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [params.sid]);
  const handleChange = (e) => {
    const valueName = e.target.value;

    const filtered = eventList2.filter((employee) => {
      //return employee.event_name !== valueName;
      //return employee?.event_name?.toLowerCase()?.includes(valueName?.toLowerCase())
      return employee.event_name.indexOf(valueName) !== -1;
    });
    seteventList(filtered);
  };
  return (
    <>
      <div className="dashboardLinkWrapper">
        <div className="emenu">
          <div className="emenu1">
            <img
              alt="Organization Name"
              src={require("assets/img/sound.svg").default}
              style={{ marginRight: "10px" }}
            />
            <h3 className="mb-0 fontSize20 JakartaMedium300">
              Events in this Storyz
            </h3>
          </div>
          <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
            <img
              alt="Organization Name"
              src={require("assets/img/tip.svg").default}
              style={{ marginRight: "10px" }}
            />
          </span>
          <ReactTooltip />
        </div>
        <hr style={{ marginTop: "1rem", marginBottom: "1.3em" }} />
        <div className="cardBox">
          <div className="dashboardLinkInner">
            <div className="form-group">
              <input
                id="example2cols1Input"
                placeholder="type to search..."
                type="text"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="todolistst">
              <section className="storyzsection">
                {eventList.length > 0 ? (
                  <>
                    {eventList.map((evdata, i) => (
                      <div
                        style={{ display: "flex", marginBottom: "15px" }}
                        className="story1"
                        key={i}
                        onClick={() => {
                          history.push(
                            `/admin/event-dashboard/${evdata.project_id}/${evdata.id}`
                          );
                        }}
                      >
                        <div>
                          <img
                            alt={evdata?.event_name}
                            src={
                              evdata?.cover_image
                                ? evdata?.cover_image
                                : require("assets/img/theme/Event-name.svg")
                                  .default
                            }
                            className="eventimag"
                          />
                        </div>
                        <div>
                          <div className="title">
                            <Link to={``}>{evdata?.event_name}</Link>
                          </div>
                          <div className="subtitle">
                            {evdata.event_start_date
                              ? moment(evdata.event_start_date).format(
                                "MMM Do YY"
                              )
                              : "No Date"}
                          </div>
                          <div className="subtitle1">{evdata?.location}</div>
                          <div className="description">
                            {evdata?.event_description
                              ? evdata?.event_description.substring(0, 25) +
                              "..."
                              : ""}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  "No Events Data"
                )}
              </section>
            </div>
            <a
              href="#"
              className="newTodo"
              onClick={() => {
                dispatch({ type: actionTypes.EVENTS_POPUP, payload: true });
              }}
            >
              New Event
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
