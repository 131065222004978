import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const customersupportService = {
    getCSData,
    getDetails,
    update,
    addData,
    updateStatus,
    sendData,
    getChat,
};

async function getCSData(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/customer/support/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getChat(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/customer/support/get-chat`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function sendData(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/customer/support/send-data`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function addData(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/customer/support/add`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateStatus(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/customer/support/update-status`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function update(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/customer/support/update`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getDetails(data) {
    //let data = {}
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/customer/support/details`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
