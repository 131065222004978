import React, { useEffect } from "react"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Checkbox from "../../../components/Checkbox"
import { useMap } from "../../../hooks/useMap"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { Cross } from "../../../icons"
import { changeOpen } from "../../../store/modal/slice"
import classNames from "./InteractiveMapContent.module.scss"
import Map from "../../../components/Map"
import { interactiveObjectSelector } from "../../../store/interactive-map/selector"
import {
  changeShowBeacons,
  changeShowOther,
  changeShowTables
} from "../../../store/interactive-map/slice"
import { isOpenInteractiveMapModalSelector } from "../../../store/modal/selector"
import cn from "classnames"

const InteractiveMapContent = () => {
  const dispatch = useDispatch()
  const { mapContainerRef } = useMap()
  const ref = useRef(null)
  const { showBeacons, showOther, showTables } = useSelector(
    interactiveObjectSelector
  )

  const isOpen = useSelector(isOpenInteractiveMapModalSelector)

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.position = isOpen
      ? "fixed"
      : "unset"
  }, [isOpen])

  const onClickExit = () => dispatch(changeOpen(false))

  useOutsideClick(ref, () => {
    dispatch(changeOpen(false))
  })

  const onChangeShowBeacons = value => {
    dispatch(changeShowBeacons(value))
  }

  const onChangeShowOther = value => {
    dispatch(changeShowOther(value))
  }

  const onChangeShowTables = value => {
    dispatch(changeShowTables(value))
  }

  return (
    <div
      ref={ref}
      className={cn(classNames.modal, !isOpen && classNames.hidden)}
    >
      <div className={classNames.header}>
        <h2 className={classNames.header__title}>Interactive Map</h2>
        <button onClick={onClickExit} className={classNames.icon__container}>
          <Cross />
        </button>
      </div>
      <span className={classNames.description}>
        Use this enlarged view objects in the Geospace.
      </span>
      <div className={classNames.map__container}>
        <Checkbox
          label="Show Beacons"
          checked={showBeacons}
          onChange={onChangeShowBeacons}
        />
        <Checkbox
          label="Show Tables"
          checked={showTables}
          onChange={onChangeShowTables}
        />
        <Checkbox
          label="Show other Event Objects"
          checked={showOther}
          onChange={onChangeShowOther}
        />
        <Map ref={mapContainerRef} existBorder={false} />
      </div>
    </div>
  )
}

export default InteractiveMapContent
