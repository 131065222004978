/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useId } from "react";
import classNames from "./Input.module.scss";

const TextField = ({
  label,
  value,
  onChange,
  type = "text",
  onFocus,
  onBlur,
  onKeyPress,
  disabled,
  placeholder,
  fullWidth = true,
}) => {
  const id = useId();
  const innerOnChange = (event) => {
    onChange(event.target.value, event);
  };
  const innerOnKeyPress = (event) => {
    // @ts-ignore
    onKeyPress?.(event.key, event);
  };

  return (
    <div
      //@ts-ignore
      className={classNames.textfield}
      style={{ "--width": fullWidth ? "100%" : "110px" }}
    >
      {label && (
        <label htmlFor={id} className={classNames.textfield__label}>
          {label}
        </label>
      )}
      <input
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        className={classNames.textfield__input}
        type={type}
        value={value}
        onChange={innerOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        // @ts-ignore
        onKeyPress={innerOnKeyPress}
      />
    </div>
  );
};

export default TextField;
