import styled from "styled-components";
export const GuestEventWrapper = styled.div`
  .formInput {
    width: 250px;
    height: 40px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
    &::placeholder {
      color: #979798;
      font-style: italic;
    }
  }

  .formSelect {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1s2u09g-control {
      background: rgba(83, 215, 105, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      flex-wrap: nowrap;
    }
    .css-6j8wv5-Input {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: #666666;
    }
  }

  .formMultiSelect {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1s2u09g-control {
      border-radius: 8px;
      flex-wrap: nowrap;
      border: 1px solid #d7d7d7;
      min-width: 220px;
    }
    .css-tj5bde-Svg {
      color: #979798;
    }
    .css-g1d714-ValueContainer {
      flex-wrap: nowrap;
    }
    .css-g1d714-ValueContainer > div {
      overflow: initial !important;
    }
    .css-1pahdxg-control {
      flex-wrap: nowrap !important;
    }
    .css-1rhbuit-multiValue {
      background: #ededed !important;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #666666 !important;
        transition: all 0.3s ease-in-out;
        .css-12jo7m5 {
          color: #fff !important;
          transition: all 0.3s ease-in-out;
        }
        .css-xb97g8 {
          .css-tj5bde-Svg {
            color: #fff;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    .css-xb97g8 {
      background: none !important;
    }

    .css-12jo7m5 {
      font-size: 12px;
    }
  }
  .formCheckInput {
    width: 18px;
    height: 18px;
    border-color: solid 1px #000;
  }

  .guestSearchWrapper {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }
  .searchWrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
    .searchBtn {
      background: #6750a4;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      color: #fdfdfd;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-right: 8px;
    }
    .searchInput {
      width: 250px;
    }
  }
  .showEntries {
    letter-spacing: 0.4px;
    color: #666666;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    span {
      margin-right: 8px;
      white-space: nowrap;
    }

    .enteriesDropdown {
      margin-right: 8px;
      width: 120px;
      /* &.showDropdown {
        width: 60px;
      } */
      /* .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-1s2u09g-control {
        background: rgba(83, 215, 105, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
      .css-6j8wv5-Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        color: #666666;
      }
      .css-tj5bde-Svg {
        color: #979798;
      } */
    }
  }
  .guestUserWrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    .exportWrap,
    .addGuest {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #49454f;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 4px 12px;
      margin-left: 16px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: auto;
        color: #666666;
        margin-bottom: 4px;
      }
    }
  }
  .organizeByGroupOuter {
    margin-top: 32px;
  }
  .organizeByGroupWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.4px;
      margin-left: 8px;
      color: #666666;
    }
  }
  .tableWrapperOuter {
    display: grid;
    grid-template-columns: 400px 1fr;
    margin-bottom: 40px;
  }
  .table {
    th,
    td {
      text-transform: none;
      border-top: none;
    }
    th {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
    }
  }
  .RSVPchip {
    border-radius: 6px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1px;
    color: #49454f;
    text-transform: uppercase;
    min-width: 72px;
    height: 24px;
    padding: 0 4px;
    cursor: pointer;
    &.pending {
      background: #fff2c8;
    }
    &.success {
      background: rgba(83, 215, 105, 0.2);
    }
    &.failed {
      background: rgba(252, 49, 88, 0.2);
    }
  }
  .userLeftTableWrapper {
    /* max-height: 600px;
    overflow: auto; */
    .table {
      border-right: 1px solid #d7d7d7;
    }
    .userIcon {
      width: 16px;
      height: 16px;
      color: #666;
      margin-right: 8px;
    }
    .guestUserCard {
      display: flex;
      align-items: center;
      .guestUserPic {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        .pic {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
        }
      }
      .guestRole {
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.6px;
        color: #000000;
        text-align: center;
        padding-top: 8px;
      }
      .guestUserBody {
        .title {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #1f1f1f;
        }
        .subTitle {
          font-weight: 300;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.4px;
          color: #1f1f1f;
        }
      }
    }

    .dottedBtn {
      width: 36px;
      height: 36px;
      min-width: 36px;
      border-radius: 100%;
      background: rgba(19, 69, 165, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      svg {
        fill: rgba(19, 69, 165, 0.5);
        width: 24px;
        height: 24px;
      }
    }
  }
  .userRightTableWrapper {
    /* max-height: 600px; */
    overflow-x: auto;
  }
  .contactIcon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 2px 8px;
    svg {
      font-size: 16px;
    }
  }
  .editSocialBtn {
    background: rgba(198, 193, 203, 0.2);
    border-radius: 100px;
    width: 94px;
    height: 36px;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #1d192b;
    border: none;
    margin-right: 8px;
    &.disabled {
    }
  }
  .socialIcon {
    margin: 0 8px;
    display: inline-block;
  }
  .guestCircleWrapper {
    width: 24px;
    margin-left: auto;
    svg {
      width: 100%;
    }
  }
  .guestActionDropdown {
    position: relative;
    display: inline-block;
    .css-tj5bde-Svg {
      opacity: 0;
    }
    .css-26l3qy-menu {
      min-width: 180px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
    }
    .css-1hb7zxy-IndicatorsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(19, 69, 165, 0.5);
      font-size: 24px;
    }
    .css-319lph-ValueContainer {
      display: none;
    }
    .css-tlfecz-indicatorContainer {
      cursor: pointer;
    }
    .css-1s2u09g-control {
      min-height: 36px;
      border: none;
      border-radius: 100%;
      background: rgba(19, 69, 165, 0.05);
      height: 36px;
      width: 36px;
    }
    .dottedIcon {
      position: absolute;
      top: 5px;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      color: rgba(19, 69, 165, 0.5);
      font-size: 24px;
    }
  }
  .addGuestUerFormWrapper {
    .heading {
      background: rgba(198, 193, 203, 0.3);
      border-radius: 6px;
      padding: 12px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      text-transform: none;
    }
    .notAdmin {
      background: #e7e7e7;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
    }

    .form-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #666666;
      margin-bottom: 0;
    }
  }

  .basiInfoWrapper {
    padding: 12px 0;
    display: flex;
    .userPic {
      width: 128px;
      height: 128px;
      margin-right: 12px;
      .userPicImg {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }

  .newUserIcon {
    margin-bottom: 12px;
  }
  .newGuestTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 12px;
  }
  .notAdmin {
    background: #e7e7e7;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: fit-content;
    height: 32px;
    padding: 4px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.4px;
    color: #979798;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
    &.active {
      background: rgba(83, 215, 105, 0.1);
      color: #000000;
      svg {
        fill: #666;
        margin-right: 8px;
      }
    }
  }
  .messageBtn {
    background: #e7e7e7;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    letter-spacing: 0.4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #979798;
    padding: 4px 12px;
    height: 32px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 8px;
      color: #666;
      height: 16px;
      width: 16px;
    }
    &.active {
      background: rgba(83, 215, 105, 0.1);
      color: #000000;
    }
  }
  .backBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 8px;
    svg {
      fill: #666666;
    }
  }
  .disabled {
    background: rgba(207, 207, 207, 0.15);
  }
  .inviteGuestWrapper {
    background: rgba(207, 207, 207, 0.15);
    border-radius: 6px;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    .inviteGuestList {
      padding: 4px 8px 4px 4px;
      margin: 5px;
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 4px;
      }
    }
  }
  .honoredChipWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }
  .honoredChip {
    background: #f4f4f4;
    border-radius: 4px;
    height: 32px;
    padding: 4px 8px 4px 4px;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.6px;
    color: #000000;
    margin: 5px;
  }
`;