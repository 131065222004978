import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { useHistory } from "react-router-dom";

function Paynow() {

  //user_singup_data
  const singupUserData  =  JSON.parse(sessionStorage.getItem('user_singup_data'));
  console.log("singupUserData", singupUserData);
  let userID = singupUserData[0].id;
  let account_type = singupUserData[0].account_type;
  let service_level = singupUserData[0].service_level;
  //console.log("singupUserData Email", emailID);
  const history = useHistory();

  
  const GoTo = () => { history.push('/singup-complete'); }

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="7" className="my-7">
            
            {/* pay now or continue trial  */}
            <Card className="payNowWrapper">
                <CardBody className="bg-white ">
                  <div className="heading">
                    <img
                      alt="OurStoryz"
                      src={
                        require("assets/img/modal-icons/pay-icon.svg")
                          .default
                      }
                    /> 
                    Pay now or continue trial
                    </div>
                        
                   <p className="logniMethodText">Your trial account for 14 days, after which you’ll need to pay monthly or yearly for your service.</p>
                  <div className="payNowLine"></div>

                  <div className="diyHeading">
                    <img
                      alt="OurStoryz"
                      src={
                        require("assets/img/modal-icons/flag-diy.svg")
                          .default
                      }
                    /> 
                    {service_level === 'Professional' ? service_level +' (multiple clients)' : service_level +' (multiple clients)' }
                   
                  </div>

                  <div>
                    <div className="formGroupDiy">
                      <FormGroup check>
                        <Input type="checkbox" />
                        <Label check style={{paddingLeft:"0"}}>
                          Pay monthly
                        </Label>
                      </FormGroup>
                      <div className="textRight">$12/month</div>
                    </div>
                    <div className="formGroupDiy">
                      <FormGroup check>
                        <Input type="checkbox" />
                        <Label check style={{paddingLeft:"0"}}>
                          Pay year
                        </Label>
                      </FormGroup>
                      <div className="textRight">$99/year</div>
                    </div>
                  </div>

                  <div className="payNowLine"></div>

                  <div className="savingOuterWrapper">
                    <div>
                      <div>Secure payments through:</div>
                      <div className="paymerntIconWrapper">
                      <img
                        alt="OurStoryz"
                        src={
                          require("assets/img/modal-icons/paypal.svg")
                            .default
                        }
                      /> 
                      <img
                        alt="OurStoryz"
                        src={
                          require("assets/img/modal-icons/pay.svg")
                            .default
                        }
                      /> 
                      <img
                        alt="OurStoryz"
                        src={
                          require("assets/img/modal-icons/venmo.svg")
                            .default
                        }
                      /> 
                      <img
                        alt="OurStoryz"
                        src={
                          require("assets/img/modal-icons/visa.svg")
                            .default
                        }
                      /> 
                      </div>
                    </div>
                    <div className="savingWrapper">
                      <div className="savingList">
                       <span className="text">Savings: </span> <span className="savingBox">30%</span>
                      </div>
                      <div className="savingList">
                       <span className="text">Total: </span><span className="savingTotalBox">$166</span>
                      </div>
                    </div>
                  </div>

                    <div className="text-center card-footer d-flex">
                       <Button className="my-4 m-0 eventBtn" onClick={GoTo}>
                       Confirm payment
                      </Button>
                      <Button className="btn-default cancelBtn w-100">
                        See other plans
                      </Button>
                    </div>
                    <span className="cancelBtnText float-right mt-n2">cancel</span>
                </CardBody>
            </Card>

          
          </Col>
        </Row>
      </Container>
      
      <ToastContainer />
    </>
  );
}

export default Paynow;
