import React, { useEffect, useState, useRef } from "react";
import { BsThreeDots } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const SelectDropdownPool = ({
  guestListOption,
  fullwidth,
  SelectDropdownonClike,
  selectbox,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [selected, setSelected] = useState(selectbox);

  const selectMenuRef = useRef();

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };

  useEffect(() => {
    let handler = (e) => {
      setSelectMenu(false);
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  return (
    <div
      ref={selectMenuRef}
      className={`dropdownBoxWrapper ${fullwidth ? "fullwidthDrodown" : ""}`}
    >
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <div className="dropdownPlaceholder">
          <span className="selectInput">{selected ? selected : "Select"}</span>
          <button className="arrowIcon">
            <FaChevronDown />
          </button>
        </div>
      </div>
      <div className={`dropdownBoxMenuWrapper ${selectMenu ? "active" : ""}`}>
        <div className="dropdownBoxMenuInner">
          <ul className="dropdownMenuListWrapper">
            {guestListOption.map((option, i) => (
              <li
                className={`menuItem ${option.disabled ? "disabled" : ""}`}
                key={i}
                onClick={() => {
                  setSelected(option.title);
                  SelectDropdownonClike(option.title);
                }}
              >
                <span className="icon">
                  <img src={option.img} alt={option.img} />
                </span>
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectDropdownPool;
