import React, { useEffect, useRef, useState } from "react";
import Eventmenu from "./eventMenu";
import { Eventstyle } from "./styles";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import moment from "moment";
import * as actionTypes from "../../store/actionTypes";
import { ToastContainer, toast } from "react-toastify";
import { informationService } from "../../_services/masters/information.service";
import { GuestEventWrapper } from "./UserGuestList/style";
import makeAnimated from "react-select/animated";
import EditPencilIcon from "./../../assets/img/editIPencilcon.svg";
import LockInvertIcon from "./../../assets/img/lock-invert-icon.svg";
import DeleteIcon from "./../../assets/img/deleteIcon.svg";
import ImgIcon from "./../../assets/img/Image-Icon.svg";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
import { useHistory } from "react-router-dom";
import DeleteEverythingInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
import { TiUser } from "react-icons/ti";

import GuestHounerDropdown from "components/Dropdown/GuestHounerDropdown";
import LockIcon from "./../../assets/img/lock-invert-icon.svg";

import HonoredGuestDropdown from "components/Dropdown/HonoredGuestDropdown";
import { CombinedGuestPool } from "./CombinedGuestPool/style";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { eventsService } from "../../_services/masters/events.service";
import placeholde from "../../assets/img/placeholder.jpg";
import { useFindPathSid, useFindPathEid } from "../../CommonData";
import HonoredGuestRolesDropdown from "components/Dropdown/HonoredGuestRolesDropdown";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { useDispatch, useSelector } from "react-redux";
const animatedComponents = makeAnimated();
const EventHonoredGuestList = ({ args, data }) => {
  let history = useHistory();
  var sid = useFindPathSid(3);
  var eid = useFindPathEid(4);
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [exportGuestList, setExportGuestList] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [deleteEverything, setDeleteEverything] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [addRolepopus, setAddRolepopus] = useState(false);
  const [roleerror, setRoleerror] = useState(false);
  const [roleItemsid, setRoleItemsid] = useState([]);
  const [eventdata, setEventdata] = useState();
  const [datauser, setDatauser] = useState({});

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const showEntries = [{ value: "20", label: "20" }];

  const honoredList = useSelector((state) => {
    return state.honoredList.honoredList;
  });

  const [honoredguestlist, setHonoredguestlist] = useState(honoredList);
  useEffect(() => {
    setHonoredguestlist(honoredList);
  });

  const colourOptions = [
    {
      value: "group",
      label: "Group",
      isFixed: true,
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
      isDisabled: true,
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
      isFixed: true,
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
    },
    {
      value: "featured_speaker",
      label: "Featured Speaker",
    },
  ];

  // const percentage = 66;

  const handleDeleteGuestUser = (e) => {
    setDeleteGuestUser(!deleteGuestUser);
  };
  const [guestHonorArr, setGuestHonorArr] = useState([
    { id: 1, title: "Bride" },
    { id: 2, title: "Featured Speaker" },
  ]);

  const GuestListOptionList = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Sally Struthers",
      type: "Friend of Groom",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
  ];

  const HonoredGuestOptionList = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Sally Struthers",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
    },
  ];

  const handleExportGuestList = () => {
    setExportGuestList(!exportGuestList);
  };

  const handleStatusOfGuestUser = () => {
    setStatusGuestUser(!statusGuestUser);
  };

  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      //  console.log("Hello from setTimeout");
      setResetting(false);
    }, 3000);
  };
  var obj = sessionStorage?.guest_single
    ? JSON.parse(sessionStorage.guest_single)
    : {};

  const handleDeleteEverything = (e) => {
    setDatauser(e);
    setDeleteEverything(!deleteEverything);
  };
  const RemoveGuest = () => {
    let id = { guest_id: datauser.id, event_id: eid };

    userGuestService.allRemoveGuestRole(id).then((res) => {
      if (res.status === "success") {
        SecondComponent();
        setDeleteEverything(!deleteEverything);
      } else {
      }
    });
  };
  const AddRoleModal = () => {
    setAddRolepopus(!addRolepopus);
    setRoleerror(false);
  };

  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: `/admin/edit-user-guest-list/${common?.sid}/${common?.eid}`,
      settype: "guests",
    },
    {
      img: ImgIcon,
      title: "Keepsake Album",
    },
    {
      img: LockInvertIcon,
      title: "Event Authentication",
      link: `/admin/event-guest-authentication/${common?.sid}/${common?.eid}`,
      settype: "guests",
    },
    {
      img: DeleteIcon,
      title: "Delete",
      handleClick: handleDeleteEverything,
    },
  ];
  const handleDeleteEverything1 = (e) => {
    setDeleteEverything(!deleteEverything);
    setDatauser(e);
  };
  const [guestHonorsList, setRolelist] = useState([
    { id: 1, title: "Bride" },
    { id: 2, title: "Featured Speaker" },
    { id: 3, title: "Event" },
    { id: 4, title: "Bride" },
    { id: 5, title: "Featured Speaker" },
    { id: 6, title: "Event" },
  ]);

  const onClickRole = (e) => {
    setRoleItemsid(e);
  };

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });

  const honoredColumn = [
    {
      dataField: "name",
      text: (
        <div className="d-flex align-items-center">
          Actions
          <TiUser size={20} className="mx-3 userIcon" />
          Guest Name
        </div>
      ),
      sort: false,
    },
    {
      dataField: "product",
      text: (
        <div className="d-flex align-items-center">
          Events With Honors (Select Event to Edit)
        </div>
      ),
      sort: false,
    },
  ];

  function SecondComponent() {
    userGuestService.guesthonoredList(eid).then((res) => {
      if (res.status === "success") {
        dispatch({ type: actionTypes.HONORED_GUEST, payload: res.data });
      } else {
      }
    });
  }
  const manageClick = () => {
    setHonoredguestlist();
    SecondComponent();
  };
  useEffect(() => {
    SecondComponent();
  }, [eid]);

  const [addData, setAddData] = useState({});
  const [mbRole, setMbRole] = useState(false);
  const onAddData = (e) => {
    setAddData(e);
    setMbRole(true);
  };
  const [userguestList, setUserguestList] = React.useState([]);

  const allData = () => {
    userGuestService.userGuestList(eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList([]);
      }
    });
  };



  useEffect(() => {
    userGuestService.userGuestList(eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList([]);
      }
    });
  }, []);

  const columns1 = [
    {
      dataField: "first_name",
      text: (
        <div className="d-flex align-items-center">
          Actions
          <TiUser size={20} className="mx-3 userIcon" />
          Guest Name
        </div>
      ),
      formatter: (value, row) => (
        <div className="d-flex align-items-center">
          <GuestHounerDropdown
            guestListOption={guestListOption}
            data={row}
            eid={eid}
          />

          <div className="guestUserCard ml-3">
            <div className="guestUserPic">
              <img
                src={row.imageUrl ? row.imageUrl : placeholde}
                className="pic"
                alt=""
              />
            </div>
            <div className="guestUserBody">
              <div className="title">
                {row.first_name + " " + row.last_name}
              </div>
              <div className="subTitle">The {row.account_type} </div>
            </div>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      dataField: "product",
      text: (
        <div className="d-flex align-items-center">
          {/* <img src={SortIcon} alt="" className="mx-3 userIcon" /> */}
          Events With Honors (Select Event to Edit)
        </div>
      ),
      formatter: (value, row) => (
        <div className="fitContent">
          <HonoredGuestRolesDropdown
            roledata={row}
            onComponentClick={manageClick}
            pop={false}
            gid={row.id}
          />
        </div>
      ),
      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "first_name",
      order: "desc",
    },
  ];

  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              {" "}
              <div className="badge">Event</div>
              {common?.singleEvent?.event_name
                ? common?.singleEvent?.event_name
                : ""}
            </div>
            <div>
              <div className="imageeventim234">
                <span>Honored Guests</span>
              </div>
              {/* <GuestListDropdown
                guestListOption={GuestListOptionList}
                placeholder="Honored Guests"
              /> */}
            </div>
          </div>
          <div className="organizationPara border-bottom">
            This view lists all the guests at this event for whom you have
            assigned special honors.
            <br />
            By selecting Storyz Honors, you can add a guest to the Storyz view
            within the native app.
          </div>

          <CombinedGuestPool className="px-4 pb-4">
            <>
              <GuestEventWrapper>
                <div className="guestSearchWrapper border-bottom">
                  <div className="guestUserWrap">
                    <div
                      className="addGuest"
                      onClick={() => {
                        history.push(`/admin/storyz-honored-guest-list/${sid}`);
                      }}
                    >
                      <img
                        alt="Organization Name"
                        src={require("assets/img/aour-storyz.svg").default}
                        className="icon"
                      />
                      <div className="whiteSpace">Storyz Honors</div>
                    </div>

                    <HonoredGuestDropdown
                      guestListOption={userguestList}
                      placeholder="Add Honored Guest"
                      onList={allData}
                      icon={require("assets/img/honoredguests.svg").default}
                    />
                  </div>
                </div>
              </GuestEventWrapper>
              <div className="honoredTableWrapper">
                {honoredguestlist && (
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    ref={inputRef}
                    data={honoredguestlist ? honoredguestlist : ""}
                    columns={columns1}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                  />
                )}
              </div>
              {/* </div> */}
            </>
          </CombinedGuestPool>
          <Modal
            isOpen={deleteEverything}
            toggle={handleDeleteEverything}
            {...args}
            className="deleteEverythingModal"
          >
            <ModalBody>
              <div className="deleteEverythingHeader border-bottom">
                <img
                  src={DeleteEverythingInfoIcon}
                  alt=""
                  className="deleteEverythingInfoIcon"
                />
                <div className="head">
                  Remove This Guest From the Guest List?
                </div>
              </div>
              <Row>
                <Col>
                  <div className="deleteEverythingThumWrapper">
                    <div className="deleteEverythingThum">
                      <img
                        src={
                          datauser?.imageUrl ? datauser?.imageUrl : placeholde
                        }
                        alt=""
                        className="deleteEverythingThumImg"
                      />
                    </div>
                    <div className="deleteEverythingThumContent">
                      <div className="deleteEverythingthumbTitle">
                        {datauser?.first_name} {datauser?.last_name}
                      </div>
                      <div className="deleteEverythingthumbSubTitle">
                        Role in Event :
                        {datauser?.honored_guest_roles?.map((item, i) => {
                          return (
                            <span key={i}>
                              {/* <img src={placeholde} alt="" className="avatar" /> */}{" "}
                              {item?.title},
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="repeateventDetails">
                    <div>
                      {common?.singleEvent?.event_start_date
                        ? moment(common?.singleEvent?.event_start_date).format(
                          "MMM Do"
                        )
                        : "No Date"}
                      {"   "}
                    </div>
                    <div>
                      <img
                        className="evimages"
                        src={
                          common?.singleEvent?.cover_image
                            ? common?.singleEvent?.cover_image
                            : placeholde
                        }
                        alt=""
                      />
                    </div>
                    <div className="repeateventTitle">
                      <div>{common?.singleEvent?.event_name}</div>
                      <div className="repeateventlocation">
                        {common?.singleEvent?.location}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Confirm you want to remove this guest from the Event guest
                    list.
                  </p>
                  <p>This will NOT remove the guest data.</p>
                  <p>
                    Instead it will just remove the guest from the invite list
                    for this event.
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <a
                color="primary"
                className="btn flex-grow-1 pinkBtn"
                onClick={RemoveGuest}
              >
                Remove Guest from List
              </a>
              <a
                className="btn btn-default cancelBtn"
                onClick={handleDeleteEverything}
              >
                Cancel
              </a>
            </ModalFooter>
          </Modal>

          {mbRole === true && (
            <HonoredGuestRolesDropdown
              pop={true}
              roledata={addData}
              onComponentClick={manageClick}
            />
          )}
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
};

export default EventHonoredGuestList;
