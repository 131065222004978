import React from "react";
import IconImg from "../../assets/img/combinedGuestPoolIcon.svg";
import IconImg1 from "../../assets/img/customerSupportIcon.svg";

import { Navbar, Container } from "reactstrap";
import Dashicons from "../../assets/img/dashicons_arrow-left-alt2.svg";
import { useSelector, useDispatch } from "react-redux";
import { menuSave } from "../../store/actions/index";
import { Link, useHistory } from "react-router-dom";
function CombinedGuestPoolHeader(props) {
  let history = useHistory();
  const [check, setCheck] = React.useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    setCheck((prevCheck) => !prevCheck);
    dispatch(menuSave(check));
    // history.goBack();
    history.push(`/admin/organization`);
  };
  return (
    <>
      <Navbar className="topbarnavbar navBar combinedGuestHeaderWrapper">
        <div className="menuheader">
          <img
            src={Dashicons}
            onClick={handleClick}
            className="dashIcon pointer"
            alt=""
          />
          <img
            src={props.imgScr ? props.imgScr : IconImg}
            className="dashIcon1 mr-3"
            alt=""
          />
          <h1 style={{ color: "#fff", fontSize: "28px" }} className="mb-0" onClick={handleClick}>
            {props.title}
          </h1>
        </div>
        <Link to="/admin/customer-support">
          <img src={IconImg1} className="dashIcon2" alt="" />
        </Link>
      </Navbar>
    </>
  );
}
export default CombinedGuestPoolHeader;
