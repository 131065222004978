import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import placeholde from "../../assets/img/placeholder.jpg";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const EventGuestDropdown = ({
  guestListOption,
  onChange,
  eid,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [eventItems, setEventItems] = useState([]);
  const selectMenuRef = useRef();
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };
  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const onAdd = (product) => {
    const index = eventItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      setEventItems([...eventItems, { ...product }]);
    }
  };
  useEffect(() => {
    var arr = [];
    eventItems.map((track) => arr.push(track.id));

    onChange(arr);

    // let ids = eventItems.map((track) => track.id).join(",");
    // console.log(ids);
    // onChange(ids);
  }, [eventItems]);
  const iclose = (e) => {
    const newPeople = eventItems.filter((person) => person.id !== e.id);
    setEventItems(newPeople);
  };


  const common = useSelector((state) => {
    return state.commonData.common;
  });



  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer d-flex flex-wrap">


            <>
              {eid &&
                <>
                  <div
                    className="inviteGuestList d-flex align-items-center"

                  >
                    <Link to={`/admin/event-dashboard/${common?.sid}/${common.eid}`} >
                      <img
                        src={common?.singleEvent?.cover_image ? common?.singleEvent?.cover_image : placeholde}
                        alt=""
                        className="avatar"
                      />
                      {common?.singleEvent?.event_name}
                    </Link>
                  </div>
                </>}

              {eventItems.map((event, i) => {
                return (
                  <div
                    className="inviteGuestList d-flex align-items-center"
                    key={i}
                  >
                    <Link to={`/admin/event-dashboard/${common?.sid}/${event.id}`} >
                      <img
                        src={event?.cover_image ? event?.cover_image : placeholde}
                        alt=""
                        className="avatar"
                      />
                      {event.event_name}
                    </Link>

                    <IoMdClose
                      className="ml-2 pointer"
                      size={16}
                      onClick={() => iclose(event)}
                    />
                  </div>
                );
              })}

            </>
          </div>
        </div>
      </div>
      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 left ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              value={inputValue}
              onChange={(e) =>
                setInputValue(e.target.value.toLowerCase().replace(/^\s+/g, ""))
              }
            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {guestListOption.map((option, i) => (
              <>
                {option.id !== eid ? (
                  <li
                    className="menuItem pointer"
                    key={i}
                    onClick={() => onAdd(option)}
                  >
                    <div className="eventThumDropdown">
                      <div className="date">
                        {" "}
                        {option.event_start_date
                          ? moment(option.event_start_date).format("MMM Do YY")
                          : "No Date"}
                      </div>
                      <div className="eventThum">
                        <img
                          alt={option?.event_name}
                          src={
                            option?.cover_image
                              ? option?.cover_image
                              : placeholde
                          }
                          className="eventThumImg"
                        />
                      </div>
                      <div className="eventThumContent">
                        <div className="eventthumbTitle">
                          {option.event_name}
                        </div>
                        <div className="eventthumbSubTitle"></div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventGuestDropdown;
