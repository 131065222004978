import { configureStore } from "@reduxjs/toolkit"
import settingReducer from "./settings/slice"
import gpsReducer from "./gps/slice"
import geofenceReducer from "./geofence/slice"
import modalReducer from "./modal/slice"
import interactiveMapReducer from "./interactive-map/slice"
import { objectsApi } from "../services/objects"
import { informationApi } from "../services/information"

export const store = configureStore({
    reducer: {
        [objectsApi.reducerPath]: objectsApi.reducer,
        [informationApi.reducerPath]: informationApi.reducer,
        settings: settingReducer,
        gps: gpsReducer,
        geofence: geofenceReducer,
        modal: modalReducer,
        interactiveMap: interactiveMapReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            objectsApi.middleware,
            informationApi.middleware
        ])
})
