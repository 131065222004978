import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  locationType: "type"
}

const gpsLocationSlice = createSlice({
  name: "gpsLocationSlice",
  initialState,
  reducers: {
    changeLocationType(state, action) {
      state.locationType = action.payload
    }
  }
})

export const { changeLocationType } = gpsLocationSlice.actions
export default gpsLocationSlice.reducer
