import React, { useState, useRef, useEffect } from "react";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonData } from "store/actions";
import { eventsService } from "../../_services/masters/events.service";
import { useSelector, useDispatch } from "react-redux";
export const AboutExitareaStory = ({
  onSave,
  icon,
  value,
  loading,
  error,
  name,
  eId,
  onoff,
  ...props
}) => {
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState();
  const [lastValue, setLastValue] = useState(value);
  const [height, setHeight] = useState("auto");
  const [newNote, setNewNote] = useState(value);
  useEffect(() => {
    setNewNote(value);
  }, [value]);

  return (
    <React.Fragment>
      <div className="aboutContentWrapper">
        <textarea
          className="form-control"
          id="textareavalue"
          value={newNote ? newNote : value}
          loading={loading || saving}
          error={error || saveError}
          // style={{ height: height }}
          //   ref={autoHieghtTextarea}
          //   onInput={handelAutoHieghtTextarea}
          onChange={(e) => setNewNote(e.target.value)}
          // onChange={(event) => {
          //   setSaved(true);
          //   setLastValue(event.target.value)
          //   setHeight(event.target.scrollHeight + "px");
          // }}
          onBlur={async (e) => {
            const val = e.target.value;
            if (val !== newNote || val !== value) {
              onSave && (await onSave(val));
            } else {
            }

            // if (val !== newNote || val !== value) {
            //   setSaving(true);
            //   try {
            //     onSave && (await onSave(val));
            //     setSaved(true);
            //     setSaving(false);
            //     setLastValue(val);
            //     userGuestService.eventUpdate(val1).then((res) => {
            //       if (res.status === "success") {
            //         toast.success("update successfully !!", "Account");
            //         setSaving(true);
            //       } else {
            //         toast.error("Something went wrong !!", "error");
            //       }
            //     });
            //     const id = { "event_id": eId };
            //     eventsService.eventDetails(id).then(
            //       (res) => {
            //         if (res.status === "success") {
            //           const value = {
            //             ...common,
            //             singleEvent: res.data
            //           }
            //           dispatch(commonData(value));
            //         } else {
            //           console.log("Something went wrong !!");
            //         }
            //       },
            //       (error) => {
            //         console.log("Something went wrong !!");
            //         //toast.error("Something went wrong !!", "Fashion Store");
            //       }
            //     );

            //   } catch (err) {
            //     setSaveError("Error Message");
            //   }
            // }
          }}
        ></textarea>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default AboutExitareaStory;
