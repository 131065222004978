import React from "react";

const Fit = ({ width = 24, height = 24, color = "#443C67" }) => {
  return (
    <svg fill="none" height={height} viewBox="0 0 24 24" width={width}>
      <path
        clipRule="evenodd"
        d="M13 2.84924C13 2.29696 12.5523 1.84924 12 1.84924C11.4477 1.84924 11 2.29696 11 2.84924V3.91114C7.38128 4.36238 4.51314 7.23053 4.06189 10.8492H3C2.44772 10.8492 2 11.297 2 11.8492C2 12.4015 2.44772 12.8492 3 12.8492H4.06189C4.51314 16.468 7.38128 19.3361 11 19.7873V20.8492C11 21.4015 11.4477 21.8492 12 21.8492C12.5523 21.8492 13 21.4015 13 20.8492V19.7873C16.6187 19.3361 19.4869 16.468 19.9381 12.8492H21C21.5523 12.8492 22 12.4015 22 11.8492C22 11.297 21.5523 10.8492 21 10.8492H19.9381C19.4869 7.23053 16.6187 4.36238 13 3.91114V2.84924ZM18 11.8492C18 8.53553 15.3137 5.84924 12 5.84924C8.68629 5.84924 6 8.53553 6 11.8492C6 15.163 8.68629 17.8492 12 17.8492C15.3137 17.8492 18 15.163 18 11.8492ZM14 11.8492C14 12.9538 13.1046 13.8492 12 13.8492C10.8954 13.8492 10 12.9538 10 11.8492C10 10.7447 10.8954 9.84924 12 9.84924C13.1046 9.84924 14 10.7447 14 11.8492ZM16 11.8492C16 14.0584 14.2091 15.8492 12 15.8492C9.79086 15.8492 8 14.0584 8 11.8492C8 9.6401 9.79086 7.84924 12 7.84924C14.2091 7.84924 16 9.6401 16 11.8492Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Fit;
