import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const todoService = {
    saveEventTodo,
    saveStoryzTodo,
    saveOrganizationTodo,
    listEventTodo,
    listStoryzTodo,
    listOrganizationTodo,
    updateEventTodo,
    updateStoryzTodo,
    updateOrganizationTodo,
    deleteOrganizationTodo,
    deleteStoryzTodo,
    deleteEventTodo,
};
async function saveEventTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/event/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateEventTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/event/update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function saveStoryzTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/storyz/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateStoryzTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/storyz/update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function saveOrganizationTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/todo/organization/create`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateOrganizationTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/todo/organization/update`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listEventTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/event/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listStoryzTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/storyz/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listOrganizationTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/organization/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteOrganizationTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/todo/organization/delete`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteStoryzTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/todo/storyz/delete`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteEventTodo(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/todo/event/delete`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
