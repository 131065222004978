import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Upgradesevise from "../../assets/img/Upgradesevise.svg";
import {
	Container,
	Row,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	Button,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import Accordion from "react-bootstrap/Accordion";
import { FaChevronDown } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import ServiceChangeIcon from "./../../assets/img/service-change-icon.svg";
import { Subscription } from "_services/masters/information.subscription";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import { informationService } from "_services/masters/information.service";

function Upgradeservice({ ...args }) {
	let history = useHistory();
	const [serviceChangeModal, setServiceChangeModal] = useState(false);
	const [downgradeModal, setDowngradeModal] = useState(false);
	const [downgradeCompleteModal, setDowngradeCompleteModal] = useState(false);
	const [restoreModal, setRestoreModal] = useState(false);
	const [restoreAccModal, setRestoreAccModal] = useState(false);
	const [upgradeServiceModal, setUpgradeServiceModal] = useState(false);
	const [downgradeLocalModal, setDowngradeLocalModal] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [userservice, setUserService] = useState([]);
	const [servicelist, setServiceList] = useState([]);
	const [userfeature, setUserFeature] = useState([]);
	const [servicefeature1, setServiceFeature1] = useState([]);
	const [servicefeature2, setServiceFeature2] = useState([]);
	const [processing, setProcessing] = useState(false);

	const handelServiceChangeModal = () => {
		setServiceChangeModal(!serviceChangeModal);
	};

	const handelUpgradeServiceChangeModal = () => {
		setServiceChangeModal(false);
		setLoadingModal(true);
		setTimeout(() => {
			setProcessing(false);
			setLoadingModal(false);
			setUpgradeServiceModal(true);
		}, "2000");
	};

	const handelDowngradeModal = () => {
		setProcessing(false);
		setDowngradeModal(true);
	};

	const handelDowngradeLocalModal = () => {
		setProcessing(false);
		setDowngradeLocalModal(true);
	};

	const handelDowngradeServiceModal = () => {
		setProcessing(false);
		setDowngradeModal(false);
		setDowngradeLocalModal(false);
		setLoadingModal(true);
		setTimeout(() => {
			setLoadingModal(false);
			setDowngradeCompleteModal(true);
		}, "2000");
	};

	const closeDowngradeServiceModal = () => {
		setDowngradeCompleteModal(false);
		setDowngradeLocalModal(false);
		setDowngradeModal(false);
		setProcessing(false);
	};

	const handelRestoreModal = () => {
		setRestoreModal(true);
		setDowngradeCompleteModal(false);
	};

	const closeRestoreModal = () => {
		setRestoreModal(false);
		setDowngradeCompleteModal(true);
	};

	const handelRestorAcceModal = () => {
		setRestoreModal(false);
		setLoadingModal(true);
		setTimeout(() => {
			setLoadingModal(false);
			setRestoreAccModal(true);
		}, "2000");
	};

	const getDate = () => {
		let resdata = {};
		Subscription.listService().then(
			(res) => {
				if (res.status === "success") {
					setUserService(res.data.user_service);

					setServiceList(res.data.service_list);
					let arrUsf = JSON.parse(res.data.user_service[0].service_feature);
					setUserFeature(arrUsf);
					let arrSf1 = JSON.parse(res.data.service_list[0].service_feature);
					setServiceFeature1(arrSf1);
					let arrSf2 = JSON.parse(res.data.service_list[1].service_feature);
					setServiceFeature2(arrSf2);
					//console.log('userfeature', userfeature.length);
				} else {
					console.log("Something went wrong !!");
				}
			},
			(error) => {
				console.log("Something went wrong !!");
			}
		);
	};

	const changeservice = (service_id) => {
		setProcessing(true);
		setTimeout(() => {
			let resdata = { service_level_id: service_id };
			//alert(service_id)
			Subscription.changeservice(resdata).then(
				(res) => {
					if (res.status === "success") {
						getDate();
						closeDowngradeServiceModal();
						toast.success(
							"You service changed successfully, you will be logout in few second."
						);
						setTimeout(() => {
							informationService.logOut().then(
								(res) => {
									if (res.status === "success") {
										//console.log(res.message);
										localStorage.removeItem("userDetails");
										history.push("/"); // whichever component you want it to route to
									} else if (res.status === "error") {
										console.log("2");
									} else {
										console.log("3");
									}
								},
								(error) => {
									console.log("4");
								}
							);
							history.push("/");
						}, 5500);
					} else {
						toast.error("Something went wrong !!");
					}
				},
				(error) => {
					toast.error("Something went wrong !!");
				}
			);
		}, 3500);
	};

	const upgradeSubscription = () => {
		Subscription.upgradeSubscription().then(
			(res) => {
				if (res.status === "success") {
					alert("upgradeSubscription --> Go");

				} else {
					//toast.error("Something went wrong !!");
				}
			},
			(error) => {
				// toast.error("Something went wrong !!");
			}
		);
	};
	// changeservice
	useEffect(() => {
		getDate();
	}, []);

	return (
		<>
			<ToastContainer></ToastContainer>
			{/* <Allheader title="Upgrade Your Service" imgScr={Upgradesevise} arw /> */}
			<CombinedGuestPoolHeader
				title="Upgrade Your Service"
				imgScr={Upgradesevise}
				arw
			/>
			<div className="organizerWrapperMain">
				<div className="card organizerCardWrapper">
					<div className="headingWrapper borderBottom">
						<h4 className="heading-1">Service Levels</h4>
						<div className="ml-auto">
							<span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
								<img
									alt="Organization Name"
									src={require("assets/img/tip.svg").default}
								/>
							</span>
							<ReactTooltip />
						</div>
					</div>
					<div className="borderBottom organizationPara p-4">
						This page shows your current billing level, payment method, and
						billing history.
					</div>
					{/* <div className="mb-3">
					<div className="pagetool">
						<h4 className="pagesubtitle">Service Levels</h4>
						<span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
						<img
							alt="Organization Name"
							src={require("assets/img/tip.svg").default}
							style={{ marginRight: "10px" }}
						/>
						</span>
						<ReactTooltip />
					</div>
					<small className="page-title">
						This page shows your current billing level, payment method, and
						billing history.
					</small>
					</div> */}
					<Row className="py-4">
						<Col lg={6} md={12} className="mb-4">
							<div className="professionaldiv1">
								<div className="professionaldiv">
									<img
										alt="Organization Name"
										src={require("assets/img/theme/Frame239.svg").default}
										width="35px"
									/>
									<h2 className="professional">
										{userservice.length > 0
											? userservice[0]?.service_title
											: ""}
									</h2>
								</div>
								<h2 className="professionalmo mb-0">
									$
									{userservice.length > 0 ? userservice[0].service_pricing : ""}
									<span>
										/
										{userservice.length > 0
											? userservice[0].service_billing_interval
											: ""}
									</span>
								</h2>
							</div>
							<div className="d-flex align-items-center justify-space-between">
								<div>
									<div className="servicediv">
										<img
											alt="Organization Name"
											src={
												require("assets/img/servicelevelstatusicon.svg").default
											}
											width="20px"
										/>
										<h3 className="professionalService">
											Your Current Service
										</h3>
									</div>
									<div>
										<h2 className="manageevents">
											{userservice.length > 0
												? userservice[0].service_sub_title
												: ""}
										</h2>
									</div>
								</div>
								<div className="ml-auto">
									<div className="upgrade ml-3 pointer">Currently Selected</div>
								</div>
							</div>

							<div className="haveservice">
								<h4 className="sinceservice">
									You have had this service since{" "}
									{userservice.length > 0
										? moment(userservice[0].updated_at).format("MMMM D,  YYYY")
										: "No Date"}
								</h4>
							</div>
							<p className="fontSize12 grayColor2 my-3">
								{userservice.length > 0
									? userservice[0].service_description
									: ""}
							</p>
							<div style={{ borderTop: "1px solid #E5E5E5" }}>
								<Accordion
									className="featuredAccordion alwaysFeatured"
									defaultActiveKey="0"
									alwaysOpen
								>
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span className="FeaturedText">Feature List </span>
											<FaChevronDown className="arrowIcon" />
										</Accordion.Header>
										<Accordion.Body>
											<div className="featuredAccordionListWrapper">
												<ul className="featuredAccordionList">
													{userfeature.map((x, i) => (

														<li key={Math.random()}>
															<BsCheckLg className="checkIcon" />
															{userfeature[i]}
														</li>

													))}
												</ul>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} md={12}>
							<Container className="upgrateRightSection">
								<div className="locator">
									<div className="professionaldiv1">
										<div className="professionaldiv">
											<img
												alt="Organization Name"
												src={require("assets/img/service-icons1.svg").default}
												width="35px"
											/>
											<h2 className="professional">
												{servicelist.length > 0
													? servicelist[0].service_title
													: ""}
											</h2>
										</div>
										<h2 className="professionalmo mb-0">
											$
											{servicelist.length > 0
												? servicelist[0].service_pricing
												: ""}
											<span>
												/
												{servicelist.length > 0
													? servicelist[0].service_billing_interval
													: ""}
											</span>
										</h2>
									</div>
									<div className="my-3">
										<div
											className="downgrade ml-auto pointer"
											onClick={handelDowngradeLocalModal}
										>
											{servicelist.length > 0
												? servicelist[0].service_levels
												: ""}
										</div>

										<Modal
											isOpen={downgradeLocalModal}
											toggle={handelDowngradeLocalModal}
											{...args}
											className="serviceChangeModal"
											backdrop="static"
										>
											<ModalBody className="pb-0">
												<div className="serviceChangeHead d-flex align-items-center border-bottom mb-4 pb-3 flex-row">
													<img
														src={ServiceChangeIcon}
														alt=""
														className="serviceChangeIcon mr-2"
													/>
													<div className="head">Service Change </div>
												</div>

												<Row className=" mb-4 pb-2">
													<Col>
														<div
															className={`serviceChangeContentWrapper  mb-4
										${servicelist.length > 0 &&
																	servicelist[0].service_levels == "Downgrade"
																	? " "
																	: "upgradeBg"
																}`}
														>
															<div className="professionaldiv1">
																<div className="professionaldiv">
																	<img
																		alt="Organization Name"
																		src={
																			require("assets/img/theme/Frame239.svg")
																				.default
																		}
																		width="35px"
																	/>
																	<h2 className="professional">
																		{userservice.length > 0
																			? userservice[0]?.service_title
																			: ""}
																	</h2>
																</div>
																<h2 className="professionalmo mb-0">
																	${" "}
																	{userservice.length > 0
																		? userservice[0]?.service_pricing
																		: ""}
																	<span>
																		/
																		{userservice.length > 0
																			? userservice[0]?.service_billing_interval
																			: ""}
																		.
																	</span>
																</h2>
															</div>
															<div className="d-flex align-items-center justify-content-center py-4 fontSize14 blackColor">
																{servicelist.length > 0 &&
																	servicelist[0].service_levels == "Downgrade" ? (
																	<ImArrowDown
																		className="mr-2 fontSize24"
																		style={{ color: "#FC3158" }}
																	/>
																) : (
																	<ImArrowUp
																		className="mr-2 fontSize24"
																		style={{ color: "#0FFF50" }}
																	/>
																)}
																{servicelist.length > 0
																	? servicelist[0].service_levels
																	: ""}
															</div>
															<div className="professionaldiv1">
																<div className="professionaldiv">
																	<img
																		alt="Organization Name"
																		src={
																			require("assets/img/service-icons1.svg")
																				.default
																		}
																		width="35px"
																	/>
																	<h2 className="professional">
																		{servicelist.length > 0
																			? servicelist[0].service_title
																			: ""}
																	</h2>
																</div>
																<h2 className="professionalmo mb-0">
																	$
																	{servicelist.length > 0
																		? servicelist[0].service_pricing
																		: ""}
																	<span>
																		/
																		{servicelist.length > 0
																			? servicelist[0].service_billing_interval
																			: ""}
																		.
																	</span>
																</h2>
															</div>
														</div>
														<p className="fontWeight300 fontSize16 grayColor1">
															Your changes will convert this to a free account.
															You will lose all your events (the oldest will be
															kept active with reduced features). Projects View
															will be unavailable. Your deactivated event data
															will be available as downloadable spreadsheet
															(.csv) files.
														</p>
														<p className="bold fontSize16 grayColor1">
															Confirm Your{" "}
															{servicelist.length > 0
																? servicelist[0].service_levels
																: ""}
														</p>
													</Col>
												</Row>
											</ModalBody>
											<ModalFooter className="pt-0">
												<button
													className="changeBtn height40 fontWeight300"
													onClick={() => {
														setDowngradeLocalModal(false);
													}}
												>
													Cancel{" "}
													{servicelist.length > 0
														? servicelist[0].service_levels
														: ""}
												</button>
												{processing == true ? (
													<button class="btn pinkBtn" type="button" disabled>
														<span
															class="spinner-border spinner-border-sm"
															role="status"
															aria-hidden="true"
														></span>
														&nbsp; Processing...
													</button>
												) : (
													<Button
														color="primary"
														className="pinkBtn boxShadowNone height40 py-2 px-4 border-radius8"
														onClick={() => {
															changeservice(servicelist[0].id);
														}}
													//onClick={() => { upgradeSubscription()}}
													>
														{servicelist.length > 0
															? servicelist[0].service_levels
															: ""}
													</Button>
												)}
											</ModalFooter>
										</Modal>
									</div>

									<h4 className="singlelist mb-3">
										{servicelist.length > 0
											? servicelist[0].service_sub_title
											: ""}
									</h4>

									<div className="eventsdiv mt-3">
										<p className="eventsdivp">
											{servicelist.length > 0
												? servicelist[0].service_description
												: ""}
										</p>
									</div>
								</div>

								<Accordion className="featuredAccordion">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span className="FeaturedText">Feature List </span>
											<span className="closeText">Close </span>
											<FaChevronDown className="arrowIcon" />
										</Accordion.Header>
										<Accordion.Body>
											<div className="featuredAccordionListWrapper">
												<ul className="featuredAccordionList">
													{servicefeature1.map((x, i) => (

														<li key={Math.random()}>
															<BsCheckLg className="checkIcon" />
															{servicefeature1[i]}
														</li>

													))}
												</ul>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>

								<div className="diy">
									<div className="professionaldiv1">
										<div className="professionaldiv">
											<img
												alt="Organization Name"
												src={require("assets/img/serviceicons2.svg").default}
												width="35px"
											/>
											<h2 className="professional">
												{servicelist.length > 0
													? servicelist[1].service_title
													: ""}
											</h2>
										</div>
										<h2 className="professionalmo mb-0">
											$
											{servicelist.length > 0
												? servicelist[1].service_pricing
												: ""}
											<span>
												/
												{servicelist.length > 0
													? servicelist[1].service_billing_interval
													: ""}
												.
											</span>
										</h2>
									</div>
									<div className="my-3">
										<div
											className="downgrade ml-auto pointer"
											onClick={handelDowngradeModal}
										>
											{servicelist.length > 0
												? servicelist[1].service_levels
												: ""}
										</div>
									</div>
									<Modal
										isOpen={downgradeModal}
										toggle={handelDowngradeModal}
										{...args}
										className="serviceChangeModal"
										backdrop="static"
									>
										<ModalBody className="pb-0">
											<div className="serviceChangeHead d-flex align-items-center border-bottom mb-4 pb-3 flex-row">
												<img
													src={ServiceChangeIcon}
													alt=""
													className="serviceChangeIcon mr-2"
												/>
												<div className="head">Service Change</div>
											</div>

											<Row className=" mb-4 pb-2">
												<Col>
													<div className="serviceChangeContentWrapper mb-4">
														<div className="professionaldiv1">
															<div className="professionaldiv">
																<img
																	alt="Organization Name"
																	src={
																		require("assets/img/theme/Frame239.svg")
																			.default
																	}
																	width="35px"
																/>
																<h2 className="professional">
																	{" "}
																	{userservice.length > 0
																		? userservice[0]?.service_title
																		: ""}
																</h2>
															</div>
															<h2 className="professionalmo mb-0">
																${" "}
																{userservice.length > 0
																	? userservice[0]?.service_pricing
																	: ""}
																<span>
																	/
																	{userservice.length > 0
																		? userservice[0]?.service_billing_interval
																		: ""}
																	.
																</span>
															</h2>
														</div>
														<div className="d-flex align-items-center justify-content-center py-4 fontSize14 blackColor">
															{servicelist.length > 0 &&
																servicelist[1].service_levels == "Downgrade" ? (
																<ImArrowDown
																	className="mr-2 fontSize24"
																	style={{ color: "#FC3158" }}
																/>
															) : (
																<ImArrowUp
																	className="mr-2 fontSize24"
																	style={{ color: "#0FFF50" }}
																/>
															)}

															{servicelist.length > 0
																? servicelist[1].service_levels
																: ""}
														</div>
														<div className="professionaldiv1">
															<div className="professionaldiv">
																<img
																	alt="Organization Name"
																	src={
																		require("assets/img/serviceicons2.svg")
																			.default
																	}
																	width="35px"
																/>
																<h2 className="professional">
																	{servicelist.length > 0
																		? servicelist[1].service_title
																		: ""}
																</h2>
															</div>
															<h2 className="professionalmo mb-0">
																${" "}
																{servicelist.length > 0
																	? servicelist[1].service_pricing
																	: ""}
																<span>
																	/
																	{servicelist.length > 0
																		? servicelist[1].service_billing_interval
																		: ""}
																	.
																</span>
															</h2>
														</div>
													</div>
													<p className="fontWeight300 fontSize16 grayColor1">
														Your charges will fall by 95% from their current
														level. You will lose multiple events (the oldest
														will be kept active), along with Projects. Your
														deactivated event data will be available as
														downloadable spreadsheet (.csv) files.
													</p>
													<p className="bold fontSize16 grayColor1">
														Confirm Your{" "}
														{servicelist.length > 0
															? servicelist[1].service_levels
															: ""}
													</p>
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter className="pt-0">
											<button
												className="changeBtn height40 fontWeight300"
												onClick={() => {
													setDowngradeModal(false);
												}}
											>
												Cancel{" "}
												{servicelist.length > 0
													? servicelist[1].service_levels
													: ""}
											</button>
											{processing == true ? (
												<button class="btn pinkBtn" type="button" disabled>
													<span
														class="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"
													></span>
													&nbsp; Processing...
												</button>
											) : (
												<Button
													color="primary"
													className="pinkBtn boxShadowNone height40 py-2 px-4 border-radius8"
													onClick={() => {
														changeservice(servicelist[1].id);
													}}
												//onClick={() => { upgradeSubscription()}}
												>
													{servicelist.length > 0
														? servicelist[1].service_levels
														: ""}
												</Button>
											)}
										</ModalFooter>
									</Modal>

									<Modal
										isOpen={downgradeCompleteModal}
										toggle={handelDowngradeServiceModal}
										{...args}
										className="serviceChangeModal"
										backdrop="static"
									>
										<ModalBody className="pb-0">
											<div className="serviceChangeHead d-flex align-items-center border-bottom mb-4 pb-3 flex-row">
												<img
													src={ServiceChangeIcon}
													alt=""
													className="serviceChangeIcon mr-2"
												/>
												<div className="head">
													Service Downgrade is complete
												</div>
											</div>

											<Row className=" mb-4 pb-2">
												<Col>
													<p className="fontWeight300 fontSize16 grayColor1">
														Your account has been downgraded.
													</p>
													<p className="fontWeight300 fontSize16 grayColor1">
														Your Event and Guest information that is not
														supported in your downgraded service will be stored
														for 30 days, and can be retrieved in spreadsheet
														format by contacting support@ourstoryz.com
													</p>
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter className="pt-0">
											<Button
												color="primary"
												className="pinkBtn boxShadowNone height40 py-2 px-4 flex-grow-1 border-radius8"
												onClick={closeDowngradeServiceModal}
											>
												Exit and Log In
											</Button>
											<Button
												className="btn-default cancelBtn height40 py-2"
												onClick={handelRestoreModal}
											>
												Restore Account
											</Button>
										</ModalFooter>
									</Modal>

									<Modal
										isOpen={restoreModal}
										toggle={handelRestoreModal}
										{...args}
										className="serviceChangeModal"
										backdrop="static"
									>
										<ModalBody className="pb-0">
											<div className="serviceChangeHead d-flex align-items-center border-bottom mb-4 pb-3 flex-column">
												<img
													src={
														require("assets/img/Icon-Text-in-Circle.svg")
															.default
													}
													alt=""
													className="serviceChangeIcon mb-2"
												/>
												<div className="head">Restore Your Account</div>
											</div>

											<Row className=" mb-4 pb-2">
												<Col>
													<p className="fontWeight300 fontSize16 grayColor1">
														This action will restore your organization’s
														account, along with your last payment and billing
														information.
													</p>
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter className="pt-0">
											<Button
												className="btn-default cancelBtn height40 py-2"
												onClick={closeRestoreModal}
											>
												Cancel Restore
											</Button>
											<Button
												className="pinkBtn boxShadowNone height40 py-2 px-4 flex-grow-1 m-0 border-radius8"
												onClick={handelRestorAcceModal}
											>
												Continue Account Restore
											</Button>
										</ModalFooter>
									</Modal>

									<Modal
										isOpen={restoreAccModal}
										toggle={handelRestorAcceModal}
										{...args}
										className="serviceChangeModal"
										backdrop="static"
									>
										<ModalBody className="pb-0">
											<div className="serviceChangeHead d-flex align-items-center border-bottom mb-4 pb-3 flex-column">
												<img
													src={
														require("assets/img/circle-saved-chat-icon.svg")
															.default
													}
													alt=""
													className="serviceChangeIcon mb-2"
												/>
												<div className="head">
													Your Account has been Restored
												</div>
											</div>

											<Row className=" mb-4 pb-2">
												<Col>
													<p className="fontWeight300 fontSize16 grayColor1">
														Welcome back! All your organization, project and
														event and billing information has been restored.
													</p>
												</Col>
											</Row>
										</ModalBody>
										<ModalFooter className="pt-0">
											<Button
												color="primary"
												className=" boxShadowNone height40 py-2 px-4 flex-grow-1 m-0"
												onClick={() => {
													setRestoreAccModal(false);
												}}
											>
												Continue
											</Button>
										</ModalFooter>
									</Modal>

									<h4 className="singlelist mb-3">
										{servicelist.length > 0
											? servicelist[0].service_sub_title
											: ""}
									</h4>

									<div className="eventsdiv mt-3">
										<p className="eventsdivp">
											{servicelist.length > 0
												? servicelist[0].service_description
												: ""}
										</p>
									</div>
								</div>
								<Accordion className="featuredAccordion">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span className="FeaturedText">Feature List </span>
											<span className="closeText">Close </span>
											<FaChevronDown className="arrowIcon" />
										</Accordion.Header>
										<Accordion.Body>
											<div className="featuredAccordionListWrapper">
												<ul className="featuredAccordionList">
													{servicefeature2.map((x, i) => (

														<li key={Math.random()}>
															<BsCheckLg className="checkIcon" />
															{servicefeature2[i]}
														</li>

													))}
												</ul>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Container>
						</Col>
					</Row>
					<p className="closeOrganization">
						Close and Delete Your Organization Account
					</p>
				</div>
			</div>
		</>
	);
}

export default Upgradeservice;
