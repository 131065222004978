import React from "react";
import { useParams } from "react-router-dom";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import Imggroup from "../../assets/img/customerSupportIconNew.svg";
import CustomerSupportList from "components/Events/CustomerSupport/CustomerSupportList";

const CustomerSupport = () => {
  const param = useParams();
  console.log("Param", param);
  return (
    <>
      <CombinedGuestPoolHeader title="Customer Support" imgScr={Imggroup} arw />
      <div className="organizerWrapperMain">
        <div className="card organizerCardWrapper">
          <CustomerSupportList />
        </div>
      </div>
    </>
  );
};

export default CustomerSupport;
