import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Eventmenu from "../eventMenu";
import { TiUser } from "react-icons/ti";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { GuestEventWrapper } from "./style";
import { FaChevronLeft } from "react-icons/fa";
import { BsFillChatDotsFill } from "react-icons/bs";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mediaList } from "../../../store/actions/index";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import PropTypes from "prop-types";
import { ReactSession } from "react-client-session";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import placeholde from "../../../assets/img/placeholder.jpg";

import InstagramIcon from "./../../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../../assets/img/vimeo-icon.svg";
import {
  MdAccountCircle,
} from "react-icons/md";
import Allheader from "components/Headers/AllheaderEvent";
import newUserIcon from "./../../../assets/img/new-user-icon.svg";
import SocialMedia from "../../../components/model-form/social-media";
import avatarImg from "./../../../assets/img/avatarImg.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GuestListDropdown from "components/Dropdown/GuestListDropdown";
import GuestPrefixDropdown from "components/Dropdown/GuestPrefixDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Filemanager from "../../../components/CommonMediaModal/Filemanager";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import QuestionImg from "../../../assets/img/resetgeospaceInfoIcon.svg";
import GuestGroupDropdownlist from "components/Dropdown/GuestGroupDropdownlist";
import HonoredGuestRolesDropdownadd from "components/Dropdown/HonoredGuestRolesDropdownadd";
import EventGuestDropdown from "components/Dropdown/EventGuestDropdown";
import { informationService } from "../../../_services/masters/information.service";
import { combinedService } from "../../../_services/masters/information.combined";
import { useHistory } from "react-router-dom";
import GlobalButton from "components/Button/GlobalButton";
import { eventsService } from "../../../_services/masters/events.service";
const AddUserGuestList = ({ args }) => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const [guestGroupList, setGuestGroupList] = useState([]);
  // const dispatch = useDispatch();

  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  React.useEffect(() => {
    const id = { event_id: eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventLists(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [eid]);

  const [socialMediaLink, setSocialMediaLink] = useState(false);
  const [createSocialMediaLink, setCreateSocialMediaLink] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const [guestRsvpsStatus, setGuestRsvpsStatus] = useState("PENDING");
  const [resetting, setResetting] = useState(false);
  const [addPrefix, setAddPrefix] = useState(false);
  const [addSuffix, setAddSuffix] = useState(false);
  const [eventList, seteventList] = React.useState([]);
  const [eventLists, seteventLists] = React.useState([]);

  const [addRolepopus, setAddRolepopus] = useState(false);
  let history = useHistory();
  const [listPrefix, setListPrefix] = useState([]);
  const [listSuffi, setListSuffi] = useState([]);
  const [roledata, setRoledata] = useState([]);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  const common = useSelector((state) => {
    return state.commonData.common;
  });

  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [country, setCountry] = React.useState("us");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  useEffect(() => {
    prefixlistguest();
    suffixlistguest();
    rolelistguest();
  }, [country]);

  useEffect(() => {
    prefixlistguest();
    suffixlistguest();
    let sid = common.sid;
    informationService.eventList(sid).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  useEffect(() => {
    datatolist();
  }, [eid]);

  function handleChangeprefix(dateValue) {
    setPrefix(dateValue);
    setValue("prefix", dateValue, { shouldValidate: true });
  }
  function handleChangesuffix(dateValue) {
    setSuffix(dateValue);
    setValue("suffix", dateValue, { shouldValidate: true });
  }

  function suffixlistguest() {
    userGuestService.listSuffix().then(
      (res) => {
        if (res.status === "success") {
          setListSuffi(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  function prefixlistguest() {
    userGuestService.listPrefix().then(
      (res) => {
        if (res.status === "success") {
          setListPrefix(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  function rolelistguest() {
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          setRoledata(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  const handleStatusOfGuestUser = () => {
    setStatusGuestUser(!statusGuestUser);
  };
  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
      handleStatusOfGuestUser(false);
    }, 3000);
  };

  const guestListOption = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
  ];
  const [count, setCount] = React.useState(0);

  const percentage = count;



  // const handleCreateSocialMediaLink = () => {
  //   setCreateSocialMediaLink(!createSocialMediaLink);
  //   setSocialMediaLink(false);
  // };
  // const handleCreateSocialMediaLinkClose = () => {
  //   setCreateSocialMediaLink(false);
  //   setSocialMediaLink(true);
  // };

  const [roleItemsid, setRoleItemsid] = useState([]);
  const [eventItemsid, setEventItemsid] = useState([]);
  const [guestgroupid, setGuestgroupid] = useState([]);

  const onClickEvent = (e) => {
    setEventItemsid(e);
  };
  const onClickguestGroup = (e) => {
    setGuestgroupid(e);
  };

  const onClickRole = (e) => {
    setRoleItemsid(e);
  };

  const [socialMe, setSocialMe] = useState([]);
  const addElement = (e) => {
    setSocialMe(e);
  };
  const onSubmit = (data) => {



    const data1 = {
      type: "Guest",
      guest_rsvps_status: guestRsvpsStatus,
      email: data.email,
      first_name: data.first_name,
      prefix: data.prefix,
      last_name: data.last_name,
      suffix: data.suffix,
      mobile_number: `${data.mobile_number}`,
      country_code: country,
      storyz_id: sid,
      event_id: eid,
      guest_event_id: JSON.stringify(eventItemsid),
      guest_relation: roleItemsid,
      guest_group_id: JSON.stringify(guestgroupid),
      imaDatajson: imageData ? imageData : "",
      social_media: socialMe,
      admin_notes_on_guest: data.admin_notes_on_guest,
      guest_personal_greeting: data.guest_personal_greeting,
      guest_complete_status: count,
    };

    userGuestService.adduserGuest(data1).then(
      (res) => {
        if (res.status === "success") {
          reset();
          toast.success(res.message, "Account");
          setCountry("US");
          setImageData({});
          setSocialMe([]);
          setEventItemsid([]);
          setRoleItemsid([]);
          setTimeout(() => {
            history.push(
              `/admin/edit-user-guest-list/${sid}/${eid}/${res.data.id}?type=guests`
            );
          }, "1000");
        } else {
          console.log("Something went wrong !!");
          toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const CountrySelect = ({ value, onChange, name, labels, ...rest }) => (
    <select
      {...rest}
      value={value}
      name={name}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">--Select--</option>

      {getCountries()
        .sort()
        .map((country) => (
          <option key={country} value={country}>
            {labels[country]} (+{getCountryCallingCode(country)})
          </option>
        ))}
    </select>
  );
  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };
  function datatolist() {
    const id = { event_id: eid };
    combinedService.guestGroupList(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestGroupList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  const [prefixname, setPrefixname] = useState("");
  const [prefixerror, setPrefixerror] = useState(false);

  const [suffixname, setSuffixname] = useState("");
  const [suffixerror, setSuffixerror] = useState(false);

  const [rolename, setRolename] = useState("");
  const [roleerror, setRoleerror] = useState(false);

  const [imageData, setImageData] = useState({});

  const handleChangeadd = (event) => {
    setPrefixerror(false);
    setPrefixname(event.target.value);
  };

  const handleChangeadd2 = (event) => {
    setSuffixerror(false);
    setSuffixname(event.target.value);
  };

  // const handleChangeaddrole = (event) => {
  //   setRoleerror(false);
  //   setRolename(event.target.value);
  // };

  // function roleaddData() {
  //   if (!rolename) {
  //     setRoleerror(true);
  //   } else {
  //     setRoleerror(false);
  //     const sdata = {
  //       role_name: rolename,
  //     };
  //     userGuestService.roleData(sdata).then(
  //       (res) => {
  //         if (res.status === "success") {
  //           setRolename();
  //           setAddRolepopus(!addRolepopus);
  //           toast.success(res.message, "Account");
  //           // suffixlistguest()
  //           rolelistguest();
  //         } else {
  //           console.log("Something went wrong !!");
  //         }
  //       },
  //       (error) => {
  //         console.log("Something went wrong !!");
  //       }
  //     );
  //   }
  // }

  function suffixaddData() {
    if (!suffixname) {
      setSuffixerror(true);
    } else {
      setSuffixerror(false);
      const sdata = {
        suffix_name: suffixname,
      };

      userGuestService.suffixData(sdata).then(
        (res) => {
          if (res.status === "success") {
            setSuffixname();
            setAddSuffix(!addSuffix);
            toast.success(res.message, "Account");
            suffixlistguest();
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
        }
      );
    }
  }

  function prefixaddData() {
    if (!prefixname) {
      setPrefixerror(true);
    } else {
      setPrefixerror(false);
      const pdata = {
        prefix_name: prefixname,
      };

      userGuestService.prefixData(pdata).then(
        (res) => {
          if (res.status === "success") {
            setPrefixname();
            setAddPrefix(!addPrefix);
            toast.success(res.message, "Account");
            prefixlistguest();
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
        }
      );
    }
  }

  const AddPrefixModal = () => {
    setPrefixerror(false);
    setAddPrefix(!addPrefix);
    prefixlistguest();
  };

  const AddSuffixModal = () => {
    setAddSuffix(!addSuffix);
    suffixerror(false);
    suffixlistguest();
  };

  const AddRoleModal = () => {
    setAddRolepopus(!addRolepopus);
    setRoleerror(false);
    rolelistguest();
  };
  // const [coverImage, setCoverImage] = useState({});
  // const [commonModal, setCommonModal] = useState(false);
  // function userMedia(data) {
  //   userGuestService.getAllmedia(data).then(
  //     (res) => {
  //       if (res.status === "success") {
  //         dispatch(mediaList(res.data));
  //       } else {
  //         console.log("Something went wrong !!");
  //       }
  //     },
  //     (error) => {
  //       console.log("Something went wrong !!");
  //     }
  //   );
  // }

  const [modalShow, setModalShow] = React.useState(false);
  const [allimage, setAllimage] = React.useState([]);
  const handleClick = () => {
    setModalShow(false);
  };

  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    setImageData(jsonData[0]);
    // const data = JSON.stringify({
    //   type: "profile",

    //   photo_label: e.photo_label,
    //   photo_caption: e.photo_caption,
    //   imaDatajson: jsonData[0],
    // });
  };
  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);

    setImageData(obj);
    setModalShow(false);
  };
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);

  const PhoneNumber = (event) => {
    setPhoneNumber(event);
    setState({
      ...state,
      mobile_number: event,
    });
  };

  useEffect(() => {
    var first_name = "";
    var last_name = "";
    var email = "";
    var mobile_number = "";
    if (state.first_name) {
      first_name = 25;
    } else {
      first_name = 0;
    }
    if (state.last_name) {
      last_name = 25;
    } else {
      last_name = 0;
    }
    if (state.email) {
      email = 25;
    } else {
      email = 0;
    }
    if (state.mobile_number) {
      mobile_number = 25;
    } else {
      mobile_number = 0;
    }

    setCount(first_name + last_name + email + mobile_number);
  }, [state]);

  const [phone, setPhone] = useState("");
  let handleOnChangephone = e => {
    setPhone(e.target.value);
  };

  let handleOnChange = value => {
    setCountry(value.country.countryCode);
    setPhone(value.phone);
    setState({
      ...state,
      mobile_number: value.phone,
    });
    setValue("mobile_number", value.phone, { shouldTouch: true });
  };



  return (
    <>
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />
            <form className="forwith" onSubmit={handleSubmit(onSubmit)}>
              <div className="column2">
                <div className="column2sate backgroeventdata">
                  <div className="title">
                    <div className="badge">Event</div>
                    {eventLists?.event_name}
                  </div>
                  <div>
                    <GuestListDropdown
                      placeholder="Guest List"
                      guestListOption={guestListOption}
                    />
                  </div>
                </div>
                {/* <div className="AdDLiSt_BuTtON">
                  <CustomButton>Save Information</CustomButton>
                </div> */}
                <div className="borderBottom eventBannerPara">
                  This page lets you view and create a todo list associated with
                  this specific Storyz. <br /> To see the complete list of
                  todos, go to the
                  <Link
                    onClick={() => {
                      history.push(`/admin/storyz/${sid}`);
                    }}
                    className="text-decoration-underline mx-1 grayColor1"
                  >
                    Storyz view
                  </Link>
                  or
                  <Link
                    onClick={() => {
                      history.push(`/admin/organization`);
                    }}
                    className="text-decoration-underline mx-1 grayColor1"
                  >
                    Organization view
                  </Link>
                  .
                </div>

                <GuestEventWrapper className="px-4 pb-4">
                  {/* <div className="d-flex justify-content-end align-items-center">
                    <div className="guestCircleWrapper mr-3">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                    <button className="messageBtn" type="button">
                      <BsFillChatDotsFill className="icon" />
                      Message
                    </button>
                  </div> */}
                  <div className="d-flex align-items-center py-4">
                    <button className="messageBtn" type="button">
                      <BsFillChatDotsFill className="icon" />
                      Message
                    </button>
                    <div className="guestCircleWrapper ml-auto">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>

                    {state?.first_name ||
                      state?.last_name ||
                      state?.email ||
                      state?.mobile_number ? (
                      <GlobalButton icon customClass="ml-2" type="sumbit">
                        Save Changes
                      </GlobalButton>
                    ) : (
                      <button
                        className={`btn saveChangesBtn ml-2`}
                        type="sumbit"
                        disabled
                      >
                        {" "}
                        <TiUser className="icon" />
                        Save Changes
                      </button>
                    )}

                    {/* <DeleteBtn icon> Delete Guest </DeleteBtn> */}
                  </div>
                  <div className="organizeByGroupOuter mt-2">
                    <Row className="addGuestUerFormWrapper">
                      <Col className="" md={12} lg={6}>
                        <div>
                          <div className="heading">Basic Information</div>
                          <div className="cardBox">
                            <div className="basiInfoWrapper ">
                              <div className="backBtn">
                                <Link
                                  to={`/admin/event-user-guest-list/${sid}/${eid}/?type=guests`}
                                >
                                  <FaChevronLeft size={24} />
                                </Link>
                              </div>
                              <div className="userPic">
                                {/* <CommonMediaModal openMediaModal={commonModal}> */}
                                <img
                                  className="userPicImg pointer"
                                  src={
                                    imageData?.url ? imageData?.url : avatarImg
                                  }
                                  alt=""
                                  onClick={() => {
                                    setModalShow(true);
                                  }}
                                />
                                {/* </CommonMediaModal> */}
                                {modalShow ? (
                                  <Filemanager
                                    show={modalShow}
                                    onHide={handleClick}
                                    onClickimageUpdate={imageUpdate}
                                    list={allimage}
                                    seletedImage={seletedImageClike}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <Filemanager
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                fileData={trigger === 0 ? userImage : coveImage}
                                onChange={changeHandler}
                                type={trigger}
                                allimage={allmedia}
                              /> */}
                              </div>
                              <div>
                                <img
                                  src={newUserIcon}
                                  alt=""
                                  className="newUserIcon pointer"
                                  onClick={() => {
                                    setModalShow(true);
                                  }}
                                />

                                <h2 className="newGuestTitle">
                                  {state.first_name || state.last_name
                                    ? state.first_name + " " + state.last_name
                                    : "New Guest"}
                                </h2>
                                <button type="button" className="notAdmin">
                                  Not an Admin
                                </button>
                                {/* <div
                                  className={`RSVPchip ${guestRsvpsStatus.toLowerCase().replaceAll(' ', '')}`}
                                  onClick={handleStatusOfGuestUser}
                                >
                                  {guestRsvpsStatus}
                                </div> */}
                                <Modal
                                  isOpen={statusGuestUser}
                                  toggle={handleStatusOfGuestUser}
                                  {...args}
                                  className="resetGeospaceModal statusofGuestaListModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                                      <img
                                        src={ResetGeospaceInfoIcon}
                                        alt=""
                                        className="geospaceInfoIcon"
                                      />
                                      <div className="head">
                                        Force the RSVP Status for This Guest?
                                      </div>
                                    </div>
                                    <Row>
                                      <Col className="px-4">
                                        <div className="eventThumWrapper">
                                          <div className="eventThum">
                                            <img
                                              src={
                                                eventLists?.cover_image
                                                  ? eventLists?.cover_image
                                                  : placeholde
                                              }
                                              alt=""
                                              className="eventThumImg"
                                            />
                                          </div>
                                          <div className="eventThumContent">
                                            <div className="eventthumbTitle">
                                              {eventLists?.event_name}
                                            </div>
                                            <div className="eventthumbSubTitle">
                                              {eventLists?.location}
                                            </div>
                                          </div>
                                        </div>
                                        {state?.first_name && (
                                          <div className="geospaceThumWrapper">
                                            <div className="geospaceThum">
                                              <img
                                                src={
                                                  imageData?.url
                                                    ? imageData?.url
                                                    : placeholde
                                                }
                                                alt=""
                                                className="geospaceThumImg"
                                              />
                                            </div>
                                            <div className="geospaceThumContent">
                                              <div className="geothumbTitle">
                                                {state?.first_name}
                                              </div>
                                              <div className="geothumbSubTitle">
                                                Event Role
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row className="mb-2 ">
                                      <Col className="px-4">
                                        <p>
                                          You are manually setting the status of
                                          this Guest’s RSVP. Select an RSVP
                                          state belolow by clicking one of the
                                          following buttons:
                                        </p>
                                        <hr className="mb-4 mt-3" />
                                        <div className="statusGuestUserWrapper mb-3">
                                          <div className="form-check pending">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1PENDING"
                                              value="PENDING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("PENDING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "PENDING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1PENDING"
                                            >
                                              PENDING
                                            </label>
                                          </div>
                                          <div className="form-check going">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1GOING"
                                              value="GOING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("GOING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "GOING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1GOING"
                                            >
                                              GOING
                                            </label>
                                          </div>
                                          <div className="form-check notGoing">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1NOT"
                                              value="NOT GOING"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("NOT GOING")
                                              }
                                              checked={
                                                guestRsvpsStatus === "NOT GOING"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1NOT"
                                            >
                                              NOT GOING
                                            </label>
                                          </div>
                                          <div className="form-check attended">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              id="flexRadioDefault1ATTENDED"
                                              value="ATTENDED"
                                              onChange={(e) =>
                                                setGuestRsvpsStatus("ATTENDED")
                                              }
                                              checked={
                                                guestRsvpsStatus === "ATTENDED"
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for="flexRadioDefault1ATTENDED"
                                            >
                                              ATTENDED
                                            </label>
                                          </div>
                                        </div>
                                        <p>
                                          Are you sure you want to do this? This
                                          will erase the RSVP status set by the
                                          system.
                                        </p>
                                      </Col>
                                    </Row>

                                    <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                                      <Button
                                        color="primary"
                                        className="flex-grow-1 pinkBtn"
                                        onClick={handleReseting}
                                      >
                                        Force RSVP Status
                                      </Button>
                                      <Button
                                        className="btn-default cancelBtn"
                                        onClick={() =>
                                          handleStatusOfGuestUser(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </ModalBody>
                                </Modal>

                                <Modal
                                  isOpen={resetting}
                                  toggle={handleReseting}
                                  {...args}
                                  className="resetRSVPModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="border-bottom mb-3 pb-3">
                                      <div className="head">
                                        Resetting RSVPs
                                      </div>
                                    </div>
                                    <Row>
                                      <Col className="px-4">
                                        <p className=" px-4 text-center">
                                          Changing the RSVP Status for this
                                          guest...
                                        </p>
                                        <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                                          <div className="circleAnimation"></div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                          </div>
                          <div className="formBox">
                            <div className="inlineFormBox grid-2">
                              <div className="form-group flex-grow-1">
                                <label className="form-label">
                                  Prefix (Ms., Dr.):
                                </label>
                                <input
                                  {...register("prefix")}
                                  type="hidden"
                                  defaultValue={prefix}
                                />

                                {listPrefix ? (
                                  <div className="my-1">
                                    <GuestPrefixDropdown
                                      placeholder="Select Prefix"
                                      guestPrefixOption1={listPrefix}
                                      caption="Pope"
                                      addUserBtn
                                      handleAddModal={AddPrefixModal}
                                      style={
                                        errors.prefix
                                          ? { border: "1px solid #f00909" }
                                          : {}
                                      }
                                      handlerFunction={handleChangeprefix}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Modal
                                  isOpen={addPrefix}
                                  toggle={AddPrefixModal}
                                  {...args}
                                  className="prefixAddModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="modalHeader border-bottom mb-4 pb-3">
                                      <img
                                        src={QuestionImg}
                                        alt=""
                                        className="icon"
                                      />
                                      <div className="head">
                                        Add a New List Item?
                                      </div>
                                    </div>
                                    <Row>
                                      <Col>
                                        <p>
                                          Confirm that you want to add a list
                                          item to this list.
                                        </p>
                                        <p> Make sure you want to do this!</p>
                                        <div>
                                          <form className="mb-4">
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="text"
                                                className="form-control flex-grow-1"
                                                placeholder="Add New Item"
                                                height="48"
                                                onChange={handleChangeadd}
                                              />

                                              <a
                                                color="primary"
                                                className="pinkBtn ml-3 btn btn-primary"
                                                onClick={prefixaddData}
                                              >
                                                Add
                                              </a>
                                            </div>
                                            <p>
                                              {prefixerror ? "Required" : ""}
                                            </p>
                                          </form>
                                        </div>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                  <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                                    <Button
                                      className="btn-default cancelBtn w-100"
                                      onClick={AddPrefixModal}
                                    >
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </div>
                              <div className="form-group flex-grow-1">
                                <label className="form-label">
                                  First Name:
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  {...register("first_name", {
                                    required: true,
                                  })}
                                  className="form-control"
                                  placeholder="enter first name..."
                                  style={
                                    errors.first_name
                                      ? { border: "1px solid #f00909" }
                                      : {}
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="formBox">
                            <div className="inlineFormBox grid-2">
                              <div className="form-group flex-grow-1">
                                <label className="form-label">Last Name:</label>
                                <input
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  {...register("last_name", { required: true })}
                                  placeholder="enter last name..."
                                  style={
                                    errors.last_name
                                      ? { border: "1px solid #f00909" }
                                      : {}
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group flex-grow-1">
                                <input
                                  {...register("suffix")}
                                  type="hidden"
                                  defaultValue={prefix}
                                />
                                <label className="form-label">
                                  Suffix (Esq, Phd.):
                                </label>

                                {listSuffi ? (
                                  <div className="my-1">
                                    <GuestPrefixDropdown
                                      placeholder="Select Suffix"
                                      guestPrefixOption1={listSuffi}
                                      caption="Grand Wizard"
                                      addUserBtn
                                      handleAddModal={AddSuffixModal}
                                      style={
                                        errors.suffix
                                          ? { border: "1px solid #f00909" }
                                          : {}
                                      }
                                      handlerFunction={handleChangesuffix}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                <Modal
                                  isOpen={addSuffix}
                                  toggle={AddSuffixModal}
                                  {...args}
                                  className="prefixAddModal"
                                >
                                  <ModalBody className="pb-0">
                                    <div className="modalHeader border-bottom mb-4 pb-3">
                                      <img
                                        src={QuestionImg}
                                        alt=""
                                        className="icon"
                                      />
                                      <div className="head">
                                        Add a New List Item?
                                      </div>
                                    </div>
                                    <Row>
                                      <Col>
                                        <p>
                                          Confirm that you want to add a list
                                          item to this list.
                                        </p>
                                        <p> Make sure you want to do this!</p>
                                        <div>
                                          <form className="mb-4">
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="text"
                                                className="form-control flex-grow-1"
                                                placeholder="Add New Item"
                                                height="48"
                                                onChange={handleChangeadd2}
                                              />
                                              <a
                                                color="primary"
                                                className="pinkBtn ml-3 btn btn-primary"
                                                onClick={suffixaddData}
                                              >
                                                Add
                                              </a>
                                            </div>
                                            <p>
                                              {suffixerror ? "Required" : ""}
                                            </p>
                                          </form>
                                        </div>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                  <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                                    <Button
                                      className="btn-default cancelBtn w-100"
                                      onClick={AddSuffixModal}
                                    >
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="heading">Guest Personal Greeting</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Short Greeting on Guest Profile:
                              </label>
                              <input
                                type="text"
                                name="guest_personal_greeting"
                                className="form-control"
                                placeholder="Short Greeting on Guest Profile..."
                                {...register("guest_personal_greeting")}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <div className="heading">Profile Visibility</div>

                          <Row className="my-3 p-2">
                            <Col>
                              <label className="form-label">
                                Show/hide their profile data in the native app.
                              </label>
                              <div>
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    className="custom-control-input"
                                    id="customRadioInline1"
                                    name="customRadioInline1"
                                    type="radio"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadioInline1"
                                  >
                                    Radio control
                                  </label>
                                </div>

                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    className="custom-control-input"
                                    id="customRadioInline2"
                                    name="customRadioInline1"
                                    type="radio"
                                    checked
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customRadioInline2"
                                  >
                                    Radio control
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div> */}
                        <div>
                          <div className="heading">
                            Admin Notes on this Guest
                          </div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Add any special information about this guest
                                here:
                              </label>
                              <textarea
                                type="text"
                                name="admin_notes_on_guest"
                                className="form-control"
                                placeholder="enter text..."
                                {...register("admin_notes_on_guest")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className="" md={12} lg={6}>
                        <div>
                          <div className="heading">Contact Information</div>
                          <div className="cardBox">




                            <div className="form-group">
                              <label className="form-label">
                                Mobile Number:
                              </label>
                              <div className="d-flex align-items-center countryMobileWrpper123">
                                <PhoneInput
                                  name="mobile_number"
                                  autoCorrect="off"
                                  placeholder="Enter a Valid Phone Number"
                                  country={country}
                                  value={phone}
                                  onChange={(phone, country) => handleOnChange({ phone, country })}

                                />
                                <input
                                  style={{ display: "none" }}
                                  placeholder="Enter a Valid Phone Number"
                                  autoCorrect="off"
                                  id="mobile_number"
                                  name="mobile_number"
                                  {...register("mobile_number", {
                                    required: "Mobile number is required",
                                    pattern: {
                                      value: /[0-9]+/,
                                      message: "This input is number only."
                                    },
                                    minLength: {
                                      value: 11,
                                      message: "Please input proper number."
                                    }
                                  })}
                                  value={phone}
                                  onChange={handleOnChangephone}
                                />


                                {/* <ChakraPhone value={chakraPhone} onChange={setChakraPhone} /> */}

                              </div>

                              {errors.mobile_number?.message && (
                                <small style={{ color: "#ff0000" }}>{errors.mobile_number?.message}</small>
                              )}
                            </div>




                            <div className="form-group">
                              <label className="form-label">Email:</label>
                              <div className="d-flex">
                                <input
                                  type="eamil"
                                  name="email"
                                  className="form-control"
                                  placeholder="enter text"
                                  {...register("email", { required: true })}
                                  style={
                                    errors.email
                                      ? { border: "1px solid #f00909" }
                                      : {}
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="heading">Social Media</div>
                        <div className="d-flex align-items-center my-4">
                          <SocialMedia
                            add={addElement}
                            value={state}
                            data={state}
                          />
                          {socialMe?.length > 0 &&
                            socialMe?.map((user) => (
                              <>
                                {user.name === "facebook" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={FacebookIcon} alt="" />
                                  </a>
                                )}
                                {user.name === "instagram" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={InstagramIcon} alt="" />
                                  </a>
                                )}
                                {user.name === "tiktok" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={TiktokIcon} alt="" />
                                  </a>
                                )}
                                {user.name === "twitter" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={TwitterIcon} alt="" />
                                  </a>
                                )}
                                {user.name === "youtube" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={YoutubeIcon} alt="" />
                                  </a>
                                )}
                                {user.name === "vimeo" && (
                                  <a
                                    target="_blank"
                                    href={user.value}
                                    className="socialIcon"
                                    key={Math.random()}
                                  >
                                    <img src={VimeoIcon} alt="" />
                                  </a>
                                )}
                              </>
                            ))}
                        </div>

                        <div>
                          <div className="heading">
                            Invited Events in Storyz
                          </div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Events guest is invited to (type to search
                                events):
                              </label>
                              {eventList ? (
                                <div className="EventdropdownOuter">
                                  <EventGuestDropdown
                                    placeholder="find event "
                                    guestListOption={eventList}
                                    onChange={onClickEvent}
                                    eid={common.eid}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="heading">Honored Guest Roles</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <HonoredGuestRolesDropdownadd
                                placeholder="Find more event roles"
                                onClickrole={onClickRole}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="heading">Guest Groups</div>
                          <div className="cardBox">
                            <div className="form-group">
                              <label className="form-label">
                                Member of Guest Groups (type below to add a
                                group):
                              </label>

                              <GuestGroupDropdownlist
                                placeholder="Find guest group"
                                guestListOption={guestGroupList}
                                onChange={onClickguestGroup}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="AdDLiSt_BuTtON"> */}
                    {/* <Button
                        className="btn-white"
                        color="default"
                        style={{
                          background: "#FC3158",
                          color: "#fff",
                          width: "120px",
                          borderRadius: "10px",
                        }}
                      >
                        Submit
                      </Button> */}
                    {/* <CustomButton>Save Information</CustomButton> */}
                    {/* </div> */}
                  </div>
                </GuestEventWrapper>
              </div>
            </form>
          </Eventstyle>
        </div>
        <button type="button" className="deleteGuestBtn">
          <MdAccountCircle /> Delete Guest
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddUserGuestList;