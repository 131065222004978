import { GUEST_LIST, GUEST_LIST_CLEAR } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    guestsList: [],
};
function guestListReducers(state = initialState, action) {
    switch (action.type) {
        case GUEST_LIST:
            return {
                ...state,
                isConfirmatiom: true,
                guestsList: action.payload,
            };
        //return { ...state, event: [...action.payload] };
        case GUEST_LIST_CLEAR:
            return {
                isConfirmatiom: false,
                listData: {},
            };
        default:
            return state;
    }
}
export default guestListReducers;
