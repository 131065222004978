import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { combinedService } from "_services/masters/information.combined";
import { createPortal } from "react-dom";
const GuestEditDropdown = ({
  guestListOption,
  data,
  guest_id,
  setGuestId,
  setUserData,
  storyz_id,
  setguestDelete,
}) => {

  const params = useParams();
  const [selectMenu, setSelectMenu] = useState(false);
  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });

  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();
  // console.log("useRef", selectMenuRef);
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);

    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();

    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        !selectMenuRef.current.contains(e.target) &&
        !selectMenuList?.current?.contains(e.target)
      ) {
        setSelectMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div
      className="dropdownBoxWrapperPort simpletDropdownWrapper"
      ref={selectMenuRef}
    >
      <div
        className="dropdownTriggered"
        onClick={dropdownHandle}
        ref={buttonHeight}
      >
        <button className="btn dottedBtn" type="button">
          <BsThreeDots />
        </button>
      </div>
      {selectMenu &&
        createPortal(
          <div
            ref={selectMenuList}
            style={{ top: property.top, left: property.left }}
            className={`dropdownBoxMenuWrapperPort ${selectMenu ? "active" : ""
              }`}
          >
            <div className="dropdownBoxMenuInner">
              <ul className="dropdownMenuListWrapper">
                {guestListOption.map((option, i) => (
                  <li
                    className={`menuItem ${option.disabled ? "disabled" : ""}`}
                    key={i}
                  >
                    {option.link ? (
                      <>
                        {/* <Link to={`/admin/manage-invites-edit/${params.sid}/${params.eid}/?type=invites-rsvps`}> */}
                        <Link
                          to={`${option.link}/${data?.id}?type=${option.settype}`}
                        >
                          <span className="icon menuItem disabled">
                            <img src={option.img} alt={option.title} />
                          </span>
                          {option.title}
                        </Link>
                      </>
                    ) : (
                      <>
                        <div
                          
                          onClick={() => {
                            option.handleClick(guest_id);
                            //gotoKeepsakeAlbumPage
                            setGuestId(guest_id);
                            //setUserData(data);
                            let deleteGData = {
                              guest_id: Number(guest_id),
                              storyz_id: storyz_id,
                            };
                            //console.log('deleteGData', deleteGData);
                            combinedService
                              .getGuest(deleteGData)
                              .then((res) => {
                                if (res.status == "success") {
                                  //alert(res.status);

                                  setguestDelete(res.data);
                                } else {
                                  setguestDelete(null);
                                }
                              });
                            sessionStorage.setItem("guest_id", guest_id);
                          }}
                        >
                          <span className="icon">
                              <img src={option.img} alt={option.title} />
                          </span>
                          {option.title}
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default GuestEditDropdown;
