import React, { useState } from "react";
import { Col, Row, Modal, Button, ModalFooter, ModalBody } from "reactstrap";
import DeleteEverythingInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
import { toast } from "react-toastify";
import { todoService } from "_services/masters/information.todos";

const DeleteModal = ({
  deleteModal,
  handelDeleteModal,
  delEventData,
  storyz,
  events,
  refresh,
  organization,
  ...args
}) => {
  // const [deleteModal1,setDeleteModal1] = useState(deleteModal);

  let todo_name = delEventData == undefined ? "" : delEventData.title;
  let cover_image = delEventData == undefined ? "" : delEventData.cover_image;
  let id = delEventData == undefined ? "" : delEventData.id;
  let todo_type = delEventData == undefined ? "" : delEventData.todo_type;

  const deleteTodo = (id, todo_type) => {
    let deltodo_data = { id: id };
    if (todo_type == "Event") {
      todoService.deleteEventTodo(deltodo_data).then(
        (res) => {
          if (res.status === "success") {
            toast.success("deleted successfully");
          } else {
            toast.error("not deleted successfully");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    } else if (todo_type == "Storyz") {
      todoService.deleteStoryzTodo(deltodo_data).then(
        (res) => {
          if (res.status === "success") {
            toast.success("deleted successfully");
          } else {
            toast.error("not deleted successfully");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    } else if (todo_type == "Organization") {
      todoService.deleteOrganizationTodo(deltodo_data).then(
        (res) => {
          if (res.status === "success") {
            toast.success("deleted successfully");
          } else {
            toast.error("not deleted successfully");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }

    handelDeleteModal();
    refresh();
  };

  return (
    <Modal
      {...args}
      isOpen={deleteModal}
      toggle={handelDeleteModal}
      className="tododeleteionModal"
    >
      <ModalBody className="pb-0">
        <div className="deleteEverythingHeader border-bottom mb-3">
          <img
            src={DeleteEverythingInfoIcon}
            alt=""
            className="deleteEverythingInfoIcon"
          />
          <div className="head">Confirm Todo Deletion</div>
        </div>
        <Row>
          <Col>
            {/* <div className="deleteEverythingThumWrapper">
              <div className="deleteEverythingThum">
                <img
                  src={cover_image}
                  alt=""
                  className="deleteEverythingThumImg"
                />
              </div>
              <div className="deleteEverythingThumContent">
                <div className="deleteEverythingthumbTitle">{todo_name}</div>
                <div className="deleteEverythingthumbSubTitle">{todo_type}</div>
              </div>
            </div> */}
            <p className="fontWeight700 fontSize16 blackColor">{todo_name}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="fontSize14 blackColor">
              Confirm that you want to delete this todo permanently. All
              information will be lost.
            </p>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
        <Button
          color="primary"
          className="flex-grow-1 pinkBtn"
          onClick={() => deleteTodo(id, todo_type)}
        >
          Delete Todo
        </Button>
        <Button className="btn-default cancelBtn" onClick={handelDeleteModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
