import { EVENTS_POPUP } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    events: false,
};
function eventsPopupReducer(state = initialState, action) {
    switch (action.type) {
        case EVENTS_POPUP:
            return {
                ...state,
                isConfirmatiom: true,
                events: action.payload,
            };
        default:
            return state;
    }
}
export default eventsPopupReducer;
