import React from "react";
import {
  Form,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import Allheader from "components/Headers/Allheader";
function Selfaccountview() {
  return (
    <>

      <Allheader title="Your Account" />
      <div className="organizerWrapperMain">
        {/* <Row> */}
        <Form>
          {/* <div className="col"> */}
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Account Information</h4>
            </div>
            <div className="organizationPara p-4">
              This page shows notifications you have received from the system,
              other admins, or guests.
            </div>

            <Row>
              <Col lg={6} md={12}>
                <div className="basicinformation">
                  <img
                    src={require("assets/img/icons/profile-icon.svg").default}
                    alt=""
                  />
                  Guest Record
                </div>
                <div className="cardBox">
                  <div className="inlineFormBox guestRecordCradWrapper">
                    <div className="guestRecordAvatar">
                      <div className="img">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          alt="Avatar"
                        />
                      </div>
                      <div className="ownerclass">
                        <div className="title">Lauren Ipsas</div>
                        <div className="subTitle">Admin</div>
                      </div>
                    </div>
                    <div>
                      <Link
                        to="/admin/account-information"
                        className="greenActionBtn"
                      >
                        <i className="fas fa-user"></i>
                        View Admin Account
                      </Link>

                      <div className="grayBtnDisabled">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/icons/Chat-Vector2.svg").default
                          }
                        />
                        Message
                      </div>
                    </div>
                  </div>
                </div>

                <div className="basicinformation">
                  <img
                    src={require("assets/img/icons/Vector-5.svg").default}
                    alt=""
                  />
                  Team Role
                </div>
                <div className="cardBox">
                  <div className="teamRoleClient">Client</div>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
        </Form>
        {/* </Row> */}
      </div>
    </>
  );
}

export default Selfaccountview;
