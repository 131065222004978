import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
// import { userGuestService } from "../../_services/masters/userGuest.service";
import { informationService } from "../../_services/masters/information.service";
import { useFindPathEid } from "../../CommonData";
import { useSelector, useDispatch } from "react-redux";
import DummyImg from "../../assets/img/dummyImg.svg";
const AdminList = ({
  placeholder,
  icon,
  addUser,
  admin,
  onParentClick,
}) => {
  let eid = useFindPathEid(4);
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState();
  const contact = useSelector((state) => {
    return state.profile;
  });

  const [userData, setuserData] = useState({});
  const selectMenuRef = useRef();
  const [userguestList, setUserguestList] = React.useState([]);
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
    // userGuestService.userGuestList(eid).then(
    //   (res) => {
    //     if (res.status === "success") {
    //       setUserguestList(res.data);
    //     } else {
    //       setUserguestList();
    //     }
    //   }
    // )
  };
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  useEffect(() => {
    if (common?.singleEvent?.adminDetails) {
      setuserData(common?.singleEvent?.adminDetails);
    } else {
      setuserData({
        account_type: contact?.profiledetails?.account_type,
        email: contact?.profiledetails?.email,
        username: "",
        first_name: contact?.profiledetails?.first_name,
        last_name: contact?.profiledetails?.last_name,
        imageUrl: contact?.profiledetails?.imageUrl,
      });
    }
  }, []);

  useEffect(() => {
    informationService.acceptList().then(
      (res) => {
        if (res.status === "success") {
          setUserguestList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div className="dropdownTriggered">
        <div className="dropdownPlaceholder">
          {admin ? (
            <>
              <div className="imageeventim2 avatarCardWrapper">
                <img
                  alt="Organization Name"
                  src={userData.imageUrl ? userData.imageUrl : DummyImg}
                  className="imagevent"
                />
                <div className="imageventContent">
                  <div className="title">
                    {userData.first_name
                      ? userData.first_name + " " + userData.last_name
                      : " "}
                  </div>

                  <div className="subTitle">
                    {userData.account_type ? userData.account_type : " "}
                  </div>
                </div>
              </div>
              <div
                className="imageeventim22 pointer"
                style={{ marginLeft: "25px" }}
                onClick={dropdownHandle}
              >
                Change
              </div>
            </>
          ) : (
            <>
              <div className="addGuest">
                <img src={icon ? icon : null} alt="" width={30} />
                <div className="nowrap"> {placeholder}</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div>
            {addUser ? (
              <p className="AddGestUser">
                Add <FaPlus className="icon" />
              </p>
            ) : null}
          </div>
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              value={inputValue}
              onChange={(e) =>
                setInputValue(e.target.value.toLowerCase().replace(/^\s+/g, ""))
              }
            />
          </div>
          {userguestList.length > 0 ? (
            <ul className="dropdownMenuListWrapper">
              {userguestList.map((option, i) => (
                <>
                  {option.first_name && (
                    <li
                      className="menuItem"
                      key={i}
                      onClick={() => {
                        onParentClick(option);
                        setSelectMenu(!selectMenu);
                        setuserData(option);
                      }}
                    >
                      <div className="guestList">
                        <div className="guestUserDetails">
                          <img
                            src={option?.imageUrl ? option?.imageUrl : DummyImg}
                            alt={option?.first_name}
                            className="Avatar"
                          />
                          {option?.first_name}
                        </div>
                        <div className="guestType">{option?.account_type}</div>
                      </div>
                    </li>
                  )}
                </>
              ))}
            </ul>
          ) : (
            <p className="dataNotFound">Data not found </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminList;
