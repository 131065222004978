import { EVENTS_LIST } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    eventsList: [],
};
function eventListReducers(state = initialState, action) {
    switch (action.type) {
        case EVENTS_LIST:
            return {
                ...state,
                isConfirmatiom: true,
                eventsList: action.payload,
            };
        //return { ...state, event: [...action.payload] };
        default:
            return state;
    }
}
export default eventListReducers;
