import { useRef, useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { geofenceSelector } from "../store/geofence/selector";
import { changeRadius } from "../store/geofence/slice";
import { interactiveObjectSelector } from "../store/interactive-map/selector";
import {
  getMapOptions,
  getCircleOptions,
  getMarkerOptions,
  removeMarkers,
  haversineDistance,
} from "./helpers";
import { useGps } from "./useGps";
import {
  useGetBeaconsQuery,
  useGetOthersQuery,
  useGetTablesQuery,
  useUpdateBeaconMutation,
  useUpdateOtherMutation,
  useUpdateTableMutation,
} from "../services/objects";
import { settingSelector } from "../store/settings/selector";
import { useUpdateInfoMutation } from "../services/information";

const google = (window.google = window.google ? window.google : {});

export const useMap = () => {
  const { geoId } = useSelector(settingSelector);
  const [updateGeospace] = useUpdateInfoMutation();
  const { data: beaconData } = useGetBeaconsQuery({ geoId }, { skip: !geoId });
  const { data: tableData } = useGetTablesQuery({ geoId }, { skip: !geoId });
  const { data: otherData } = useGetOthersQuery({ geoId }, { skip: !geoId });
  const [updateBeacon] = useUpdateBeaconMutation();
  const [updateTable] = useUpdateTableMutation();
  const [updateOther] = useUpdateOtherMutation();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  const { latitude, longitude, radius } = useSelector(geofenceSelector);
  const [beaconMarkers, setBeaconMarkers] = useState([]);
  const [tableMarkers, setTableMarkers] = useState([]);
  const [otherMarkers, setOtherMarkers] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isRadiusNotFit, setIsRadiusNotFit] = useState(false);
  const { showBeacons, showTables, showOther } = useSelector(
    interactiveObjectSelector
  );
  const { changeGpsLocation } = useGps();

  const dispatch = useDispatch();

  const onChangeRadius = useCallback(() => {
    const radius = circleRef.current?.getRadius();
    if (radius) {
      dispatch(changeRadius(radius));
      // updateGeospace({ id: geoId, radius })
    }
  }, []);

  useEffect(() => {
    let boundsListener;
    let listener;
    if (mapContainerRef.current) {
      mapRef.current = new google.maps.Map(
        mapContainerRef.current,
        getMapOptions(latitude, longitude, radius)
      );
      markerRef.current = new google.maps.Marker(
        getMarkerOptions("center", mapRef.current)
      );
      circleRef.current = new google.maps.Circle(
        getCircleOptions(mapRef.current, radius)
      );
      circleRef.current.bindTo("center", markerRef.current, "position");
      boundsListener = mapRef.current?.addListener(
        "bounds_changed",
        onBoundsChanged
      );
      listener = circleRef?.current?.addListener(
        "radius_changed",
        onChangeRadius
      );
    }

    return () => {
      boundsListener?.remove();
      listener?.remove();
    };
  }, [mapContainerRef.current]);

  useEffect(() => {
    onZoomChanged();
    let zoomListener;
    if (mapRef.current) {
      zoomListener = mapRef.current?.addListener("zoom_changed", onZoomChanged);
    }
    return () => {
      zoomListener?.remove();
    };
  }, [radius]);

  const onBoundsChanged = () => {
    const isFull =
      mapContainerRef.current?.children[0].clientHeight == window.innerHeight &&
      mapContainerRef.current?.children[0].clientWidth == window.innerWidth;
    setIsFullscreen(isFull);
  };

  const onZoomChanged = () => {
    if (mapRef.current) {
      mapRef.current.setCenter({ lat: latitude, lng: longitude });
      const bounds = mapRef.current.getBounds();
      if (!bounds) return;
      const lat = bounds.getNorthEast().lat();
      const lng = bounds.getNorthEast().lng();
      const distanceNorth = haversineDistance(
        { latitude, longitude, elevation: 0 },
        { latitude: lat, longitude, elevation: 0 }
      );
      const distanceEast = haversineDistance(
        { latitude, longitude, elevation: 0 },
        { latitude, longitude: lng, elevation: 0 }
      );
      const newRadius = Math.min(distanceEast, distanceNorth);
      setIsRadiusNotFit(newRadius < radius);
    }
  };

  const [lastCoords, setLastCoords] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    const startDragListener = circleRef?.current?.addListener(
      "dragstart",
      (e) => {
        setLastCoords({
          lat: latitude - e.latLng.lat(),
          lng: longitude - e.latLng.lng(),
        });
      }
    );
    const endDragListener = circleRef?.current?.addListener("dragend", (e) => {
      changeGpsLocation({
        latitude: e.latLng.lat() + lastCoords.lat,
        longitude: e.latLng.lng() + lastCoords.lng,
        elevation: e.elevation,
      });
    });
    return () => {
      startDragListener?.remove();
      endDragListener?.remove();
    };
  }, [lastCoords, latitude, longitude]);

  useEffect(() => {
    rerenderBeacons();
    rerenderTables();
    rerenderOthers();
    mapRef.current?.setCenter({
      lat: Number(latitude),
      lng: Number(longitude),
    });
    markerRef.current?.setPosition({
      lat: Number(latitude),
      lng: Number(longitude),
    });
  }, [latitude, longitude]);

  useEffect(() => {
    circleRef.current?.setRadius(Number(radius));
  }, [radius]);

  const rerenderOthers = () => {
    removeMarkers(otherMarkers);
    if (showOther && otherData) {
      const markers = otherData.map((o) => {
        const m = new google.maps.Marker(
          getMarkerOptions(o.type, mapRef.current, {
            lat: latitude - o.lat,
            lng: longitude - o.lng,
          })
        );
        m.addListener("dragend", (e) => {
          updateOther({
            geoId,
            id: o.id,
            lat: latitude - e.latLng.lat(),
            lng: longitude - e.latLng.lng(),
          });
        });
        return m;
      });
      setOtherMarkers(markers);
    } else {
      setOtherMarkers([]);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      rerenderOthers();
    }
  }, [showOther]);

  const rerenderBeacons = () => {
    removeMarkers(beaconMarkers);
    if (showBeacons && beaconData) {
      const markers = beaconData.map((beacon) => {
        const m = new google.maps.Marker(
          getMarkerOptions(
            "beacon",
            mapRef.current,
            {
              lat: latitude - beacon.lat,
              lng: longitude - beacon.lng,
            },
            beacon.icon
          )
        );
        m.addListener("dragend", (e) => {
          updateBeacon({
            geoId,
            id: beacon.id,
            lat: latitude - e.latLng.lat(),
            lng: longitude - e.latLng.lng(),
          });
        });
        return m;
      });
      setBeaconMarkers(markers);
    } else {
      setBeaconMarkers([]);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      rerenderBeacons();
    }
  }, [showBeacons]);

  const rerenderTables = () => {
    removeMarkers(tableMarkers);
    if (showTables && tableData) {
      const markers = tableData.map((table) => {
        const m = new google.maps.Marker(
          getMarkerOptions("table", mapRef.current, {
            lat: latitude - table.lat,
            lng: longitude - table.lng,
          })
        );

        m.addListener("dragend", (e) => {
          updateTable({
            geoId,
            id: table.id,
            lat: latitude - e.latLng.lat(),
            lng: longitude - e.latLng.lng(),
          });
        });
        return m;
      });
      setTableMarkers(markers);
    } else {
      setTableMarkers([]);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      rerenderTables();
    }
  }, [showTables]);

  const fitRadius = (isRadiusReturned = false) => {
    if (mapRef.current) {
      mapRef.current.setCenter({ lat: latitude, lng: longitude });
      const bounds = mapRef.current.getBounds();
      if (!bounds) return;
      const lat = bounds.getNorthEast().lat();
      const lng = bounds.getNorthEast().lng();
      const distanceNorth = haversineDistance(
        { latitude, longitude, elevation: 0 },
        { latitude: lat, longitude, elevation: 0 }
      );
      const distanceEast = haversineDistance(
        { latitude, longitude, elevation: 0 },
        { latitude, longitude: lng, elevation: 0 }
      );
      const newRadius = Math.min(distanceEast, distanceNorth);
      dispatch(changeRadius(newRadius));
      if (isRadiusReturned) {
        return newRadius;
      } else {
        // updateGeospace({ id: geoId, radius: newRadius })
      }
    }
  };

  return { mapContainerRef, fitRadius, isFullscreen, isRadiusNotFit };
};
