import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
let userToken = JSON.parse(localStorage.getItem('userDetails'));

export const informationApi = createApi({
	reducerPath: "information",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_SERVER_URL,
		prepareHeaders: headers => {
			headers.set("token", `${userToken.token}`)
			return headers
		}
	}),
	tagTypes: ["Info"],
	endpoints: builder => ({
		getInfo: builder.query({
			// query: ({ geoId }) => ({
			// 	url: `geospace/${geoId}/info`,
			// 	method: "POST"
			// }),
			providesTags: ["Info"]
		}),
		getMessages: builder.query({
			// query: () => ({
			// 	url: `geospace/messages`,
			// 	method: "POST"
			// })
		}),
		updateInfo: builder.mutation({
			// query: ({ id, ...body }) => ({
			// 	url: `geospace/${id}/info/update`,
			// 	method: "PATCH",
			// 	body
			// }),
			invalidatesTags: ["Info"]
		})
	})
})

export const {
	useGetInfoQuery,
	useGetMessagesQuery,
	useUpdateInfoMutation
} = informationApi
