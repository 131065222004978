import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import searchIcon from "../../assets/img/search-icon.svg";
import { IoMdClose } from "react-icons/io";
import placeholde from "../../assets/img/placeholder.jpg";
const GuestGroupDropdown = ({
  guestListOption,
  placeholder,
  onChange,
  customClass,
  data,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef();
  // console.log("useRef ..", selectMenuRef);

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };
  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [eventItems, setEventItems] = useState(data ? data : []);
  const onAdd = (product) => {
    const index = eventItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      setEventItems([...eventItems, { ...product }]);
    }
  };
  useEffect(() => {
    var arr = [];
    let ids = eventItems.map((track) => arr.push(track.id));

    onChange(arr);
    // let ids = eventItems.map((track) => track.id).join(",");
    // console.log(ids);
    // onChange(ids);
  }, [eventItems]);
  const iclose = (e) => {
    const newPeople = eventItems.filter((person) => person.id !== e.id);
    setEventItems(newPeople);
  };

  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div
        className={`dropdownTriggered ${customClass}`}
        onClick={dropdownHandle}
      >
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer groupMemberWrapper d-flex align-items-center">
            {eventItems.map((event, i) => {
              return (
                <span className="groupMemberList" key={i}>
                  <img
                    src={event?.imageUrl ? event?.imageUrl : placeholde}
                    alt=""
                    className="avatar"
                  />
                  {event?.first_name + " " + event?.last_name}
                  <IoMdClose
                    className="ml-2 pointer"
                    size={16}
                    onClick={() => iclose(event)}
                  />
                </span>
              );
            })}
            <span className="align-items-center formBoxInput d-inline-flex pr-2">
              <img src={searchIcon} alt="" className="mr-2" />
              {placeholder}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 left ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div>
            {/* <p className="AddGestUser">
              Add <FaPlus className="icon" />{" "}
            </p> */}
          </div>
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              value={inputValue}
            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {guestListOption?.map((option, i) => (
              <li className="menuItem" key={i} onClick={() => onAdd(option)}>
                <div className="guestList">
                  <div className="guestUserDetails">
                    <img
                      src={option?.imageUrl ? option?.imageUrl : placeholde}
                      alt={option.first_name}
                      className="Avatar"
                    />
                    {option?.first_name + " " + option?.last_name}
                  </div>
                  <div className="guestType">{option?.account_type}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GuestGroupDropdown;
