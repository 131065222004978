import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconImg from "../../assets/img/icons/system-logos.svg";
import { informationService } from "../../_services/masters/information.service";
import Upgradesevise from "../../assets/img/Logomark123.svg";
import {
  useFindPathEid,
  useFindPathSid,
  useFindPathid,
} from "../../CommonData";
import { commonData } from "store/actions";
import { Navbar } from "reactstrap";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Dashicons from "../../assets/img/dashicons_arrow-left-alt2.svg";
import { useSelector, useDispatch } from "react-redux";
import { menuSave } from "../../store/actions/index";
import moment from "moment";
import { event } from "jquery";
import { useFindPath } from "../../CommonData";
function Allheader(props) {
  const params = useParams();
  let url = useFindPath(2);

  const common = useSelector((state) => {
    return state.commonData.common;
  });
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  let useid = useFindPathid(5);

  const [storydata, setStorydata] = React.useState();
  const [eventList, seteventList] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  var settings = {
    dots: false,
    infinite: true,
    speed: false,
    loop: false,
    slidesToShow: common?.eventList?.length > 3 ? 3 : common?.eventList?.length,
    slidesToScroll: 1,
  };

  const handleClick = () => {
    setCheck((prevCheck) => !prevCheck);
    dispatch(menuSave(check));
    history.push(`/admin/storyz/${common?.sid}`);
  };

  // useEffect(() => {
  //   const data = {
  //     story_id: common.sid,
  //   };

  //   informationService.storyzDetails(data).then(
  //     (res) => {
  //       if (res.status === "success") {
  //         setStorydata(res.data[0]);
  //       } else {
  //         console.log("Something went wrong !!");
  //         //toast.error(res.errors[0], "Fashion Store");
  //       }
  //     },
  //     (error) => {
  //       console.log("Something went wrong !!");
  //       //toast.error("Something went wrong !!", "Fashion Store");
  //     }
  //   );

  //   informationService.eventListId(common.sid).then(
  //     (res) => {
  //       if (res.status === "success") {
  //         seteventList(res.data);

  //       } else {
  //         console.log("Something went wrong !!");

  //       }
  //     },
  //     (error) => {
  //       console.log("Something went wrong !!");

  //     }
  //   );

  // }, [common.sid, common.eid]);

  const onTabClick = (id, eventData) => {
    const value = {
      ...common,
      eid: id,
      singleEvent: eventData,
    };
    dispatch(commonData(value));
  };

  return (
    <>
      <Navbar className="topbarnavbar navBar">
        <div className="menuheader" onClick={handleClick}>
          <img
            src={Dashicons}

            className="dashIcon"
            alt=""
          />

          <img
            src={props.imgScr ? props.imgScr : IconImg}
            className="dashIcon1"
            alt=""
          />
          <h1 className="title">
            <span className="badge">Storyz </span>

            {props?.title ? props?.title : common?.storyName}
          </h1>
        </div>
        <Link to="/admin/customer-support">
          <img src={Upgradesevise} alt="" className="pr-3" />
        </Link>
      </Navbar>
      <div className="eventHeaderSlider">
        <span className="badge">Event List</span>
        <div className="tps">
          <Slider {...settings}>
            {common?.eventList?.map((item, i) => (
              <div
                onClick={() => {
                  onTabClick(item.id, item);
                  sessionStorage.removeItem("detailsOfUser");
                  if (
                    url === "edit-user-guest-list" ||
                    url === "edir-guest-group"
                  ) {
                    history.push(
                      `/admin/event-dashboard/${item.project_id}/${item.id}`
                    );
                  } else {
                    history.push(`/admin/${url}/${item.project_id}/${item.id}`);
                  }
                }}
                className="sliderList"
                key={i}
              >
                <span
                  style={{
                    fontSize: "12px",
                    marginRight: "5px",
                    color: "#fff",
                  }}
                >
                  {item.event_start_date
                    ? moment(item.event_start_date).format("MMM Do")
                    : "No Date"}
                </span>
                <a
                  style={{ color: "#fff" }}
                  key={i}
                  id={item.id}
                  data-id={eid}
                  className={`tabsclassevent ${eid === item.id ? true : false}`}
                >
                  {`${item.event_name.substring(0, 10)}...`}
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
export default Allheader;
