import React from "react";
import "../../components/FileStackApp/index.css";
import FileStackApp from "../../components/FileStackApp/FileStackApp";

function Filestack() {
    return (
        <>
            <FileStackApp></FileStackApp>
        </>
    );
}

export default Filestack;
