import React, { useEffect, useState } from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import aero from "../../assets/img/brand/aero.svg";
import mlogo from "../../assets/img/smenu logo.svg";
import User from "../../assets/img/PngItem_4212341.png";
import Massageimg from "../../assets/img/icons/massages.svg";
import Calendar2 from "../../assets/img/calendar2.svg";
import Notificationsimg from "../../assets/img/icons/notification.svg";
import Settingimg from "../../assets/img/icons/Setting.svg";
import Organizationimg from "../../assets/img/icons/organization.svg";
import Storyzimg from "../../assets/img/icons/storyz.svg";
import Eventsimg from "../../assets/img/icons/events.svg";

import Todoimg from "../../assets/img/todo1.svg";
import Upgradeimg from "../../assets/img/icons/upgrade.svg";
import EventConfiguration from "../model-form/event-configuration";
import Storyzconfiguration from "../model-form/storyz-configuration";
import Logoutimg from "../../assets/img/icons/logout.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { clearuserData } from "../../store/actions/index";
import { informationService } from "../../_services/masters/information.service";
import DummyImg from "../../assets/img/dummyImg.svg";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { toast } from "react-toastify";

import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Input,
} from "reactstrap";
import { ImSpinner } from "react-icons/im";

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const setCommandata = (sid, eid, storyz_name, evdata) => {

    // informationService.eventListId(sid).then(
    //   (res) => {
    //     if (res.status === "success") {

    //       const common = {
    //         sid: sid,
    //         eid: eid,
    //         storyName: storyz_name,
    //         eventList: res.data,
    //         singleEvent: evdata

    //       };
    //       dispatch(commonData(common));
    //     } else {
    //       console.log("Something went wrong !!");

    //     }
    //   },
    //   (error) => {
    //     console.log("Something went wrong !!");

    //   }
    // );
  };

  const contact2 = useSelector((state) => {
    return state.storyz.story;
  });
  const contact3 = useSelector((state) => {
    return state.events.events;
  });
  const eventsList = useSelector((state) => {
    return state.eventsList.eventsList;
  });
  const storysList = useSelector((state) => {
    return state?.storysList?.storysList;
  });
  const userAdmin = useSelector((state) => {
    return state.userAdmin;
  });

  const [isStory, setIsstory] = useState();
  const [isOpen, setIsopen] = useState(false);
  const [isStoryz, setisStoryz] = useState(false);
  const [storyzList, setStoryzlist] = useState(storysList ? storysList : []);
  const [eventList, seteventList] = useState(eventsList ? eventsList : []);
  const [totalNotification, setCountNotification] = useState(0);

  const ToggleSidebar = () => {
    seteventList(eventsList);
    dispatch({ type: actionTypes.EVENTS_POPUP, payload: false });
    dispatch({ type: actionTypes.STORYZ_POPUP, payload: false });
    isOpen === true ? setIsopen(false) : setIsopen(true);

    setSearch('');
  };
  const ToggleSidebarStoryz = () => {
    setStoryzlist(storysList);
    dispatch({ type: actionTypes.EVENTS_POPUP, payload: false });
    dispatch({ type: actionTypes.STORYZ_POPUP, payload: false });
    isStoryz === true ? setisStoryz(false) : setisStoryz(true);
    setSearch('');
  };

  const getCount = () => {
    userGuestService.getCountNotificat().then(
      (res) => {
        if (res.status === "success") {
          //res.data
          setCountNotification(res.data);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
        toast.error("Something went wrong !!", "Error");
      }
    );
  };

  const getCountUpdate = () => {
    userGuestService.getCountUpdate().then(
      (res) => {
        if (res.status === "success") {
          //res.data
          setCountNotification(res.data);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
        toast.error("Something went wrong !!", "Error");
      }
    );
  };

  const [defaultModale, setdefaultModale] = React.useState(false);

  // setInterval(() => {
  //   informationService.acInformation()
  //     .then(
  //       res => {
  //         if (res.status === 'success') {
  //           localStorage.setItem("userdata", JSON.stringify(res.data));

  //           dispatch({ type: actionTypes.USER_INFO, payload: res.data });

  //         } else {
  //           localStorage.removeItem("userDetails");
  //           localStorage.removeItem("userdata");
  //           history.push("/");

  //         }
  //       },
  //       error => {
  //         localStorage.removeItem("userdata");
  //         localStorage.removeItem("userDetails");
  //         history.push("/");
  //       }
  //     )
  // }, 3000);

  // const contact2 = useSelector((state) => {
  //   return state.storyz.story;
  // })
  // const contact3 = useSelector((state) => {
  //   return state.events.events;
  // })
  //const [defaultStoryx, setdefaultStoryx] = React.useState(contact2);
  const [state, setState] = React.useState({});
  const [items, setItems] = React.useState([]);
  const contact = useSelector((state) => {
    return state.profile;
  });
  const location = useLocation();

  const acountRoute = () => {
    history.push(`/admin/account-information/${contact.profiledetails.user_id}`);
  };
  const expandedDiv = (data) => {
    return data.substr(0, 15);
  };
  const [search, setSearch] = useState("");
  const handleChange = (e) => {

    const valueName = e.target.value;
    setSearch(e.target.value);
    const evfiltered = eventsList.filter((even) => {
      return even.event_name.toLowerCase().indexOf(valueName.toLowerCase()) !== -1;
      //return even.event_name.indexOf(valueName) !== -1;
    });
    seteventList(evfiltered);
  };
  const handleStoryz = (e) => {
    const valueName = e.target.value;
    setSearch(e.target.value);
    const stfiltered = storysList.filter((story) => {

      return story.storyz_name.toLowerCase().indexOf(valueName.toLowerCase()) !== -1;
    });
    setStoryzlist(stfiltered);
  };
  const logOut = () => {
    document.querySelector("#change-class").classList.add("loader-container");

    if (userAdmin.isConfirmatiom === true) {
      document
        .querySelector("#change-class")
        .classList.remove("loader-container");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(userAdmin?.listData?.profileAddmindetails)
      );
      //sessionStorage.setItem("service_level", 'SuperAdmin');
      history.push("/organization-list");
    } else {
      let data = { "userId": contact.profiledetails.user_id }

      informationService.logOut(data).then(
        (res) => {
          if (res.status === "success") {
            history.push("/");
            dispatch(clearuserData());
            setTimeout(() => {
              localStorage.removeItem("userDetails");
              localStorage.removeItem("userdata");
              document
                .querySelector("#change-class")
                .classList.remove("loader-container");
            }, 500);
          } else if (res.status === "error") {
            console.log("2");
          } else {
            console.log("3");
          }
        },
        (error) => {
          console.log("4");
        }
      );
    }
  };

  React.useEffect(() => {
    setState(getCollapseStates(routes));
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setItems(items);
    }
    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.EVENTS_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    getCount();
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
      document.body.classList.add("g-sidenav-hidden");
    }
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views, key),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)

  const createLinks = (routes) => {
    var valuesArr = routes;

    return valuesArr.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={Math.random()}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.img ? (
                <>
                  <img alt="" src={prop.img} style={{ marginRight: "20px" }} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      return (
        <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.img !== undefined ? (
              <>
                {prop.countnumber ? (
                  <>
                    <span className="onlineicon"></span>
                  </>
                ) : (
                  ""
                )}

                <div>
                  <img alt="" src={prop.img} style={{ marginRight: "20px" }} />
                  <span className="nav-link-text">{prop.name}</span>
                </div>
                <span className="countnumber">
                  {prop.countnumber ? prop.countnumber : ""}
                </span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header  align-items-center">
        {logo ? (
          <>
            <div className="navbar-brand">
              <Link to="/admin/organization">
                <div className="accountlogos">
                  <div>
                    <img
                      alt=""
                      src={
                        contact?.profiledetails.organization[0]
                          ?.organization_logo
                          ? contact?.profiledetails.organization[0]
                            ?.organization_logo
                          : ""
                      }
                      className="navbar-brand-img"
                    />
                  </div>
                  <div className="logosavename">
                    {contact?.profiledetails.organization[0]
                      ?.organization_company_name
                      ? contact?.profiledetails.organization[0]?.organization_company_name.substring(
                        0,
                        10
                      ) + "..."
                      : "Org Name..."}
                  </div>
                </div>
              </Link>
            </div>

            <Link to="/admin/organization">
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img mobileimg mobilelogos"
                src={
                  contact?.profiledetails.organization[0]?.organization_logo
                    ? contact?.profiledetails.organization[0]?.organization_logo
                    : mlogo
                }
              />
            </Link>
          </>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            {/* <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div> */}
            <img alt={logo.imgAlt} className="navbar-brand-img" src={aero} />
          </div>
          <div className="userpading" onClick={acountRoute}>
            <span className="online_icon"></span>

            <img
              alt=" placeholder"
              src={
                contact.profiledetails.imageUrl
                  ? contact.profiledetails.imageUrl
                  : User
              }
              className="avatar objectFitCover"
            />

            <div className="content mobile">
              <p
                style={{
                  color: "#fff",
                  fontSize: "18px",
                  marginBottom: "0px",
                  padding: "0 6px",
                }}
              >
                {expandedDiv(
                  contact?.profiledetails
                    ? contact.profiledetails.first_name + " "
                    : //  +
                    // contact.profiledetails.last_name

                    "Lorem ipsum"
                )}

                <span style={{ display: "block", fontSize: "10px" }}>
                  {contact?.profiledetails
                    ? contact.profiledetails.account_type
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>
            {/* {createLinks(routes)} */}
            <NavItem>
              <NavLink to="/admin/organization-message" tag={Link}>
                {/* <span className="onlineicon"></span> */}
                <div className="navLinkNew">
                  <img alt="" src={Massageimg} className="menuIcon" />
                  <span className="nav-link-text">Messages</span>
                </div>
                {/* <span className="countnumber">3</span> */}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="/admin/notifications"
                tag={Link}
                onClick={() => getCountUpdate()}
              >
                <span className="onlineicon"></span>
                <div className="navLinkNew">
                  <img alt="" src={Notificationsimg} className="menuIcon" />
                  <span className="nav-link-text">Notifications</span>
                </div>
                {totalNotification > 0 ? (
                  <span className="countnumber">{totalNotification}</span>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/admin/calendar" tag={Link}>
                {/* <span className="onlineicon"></span> */}
                <div className="navLinkNew">
                  <img alt="" src={Calendar2} className="menuIcon" />
                  <span className="nav-link-text">Calendar</span>
                </div>
                {/* <span className="countnumber">3</span> */}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/admin/setting" tag={Link}>
                {/* <span className="onlineicon"></span> */}
                <div className="navLinkNew">
                  <img alt="" src={Settingimg} className="menuIcon" />
                  <span className="nav-link-text">Settings</span>
                </div>
                {/* <span className="countnumber">3</span> */}
              </NavLink>
            </NavItem>

            {contact.profiledetails.service_level === "SuperAdmin" ? (
              <>
                <div className="blankdiv"></div>
                <NavItem>
                  <NavLink to="/admin/organization" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Organizationimg} className="menuIcon" />
                      <span className="nav-link-text">Organization</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/storyz" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Storyzimg} className="menuIcon" />
                      <span className="nav-link-text">Storyz</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/event-dashboard" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Eventsimg} className="menuIcon" />
                      <span className="nav-link-text ">Events</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/todos" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Todoimg} className="menuIcon" />
                      <span className="nav-link-text">Todos</span>
                    </div>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink to="/admin/reports" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Reportsimg} className="menuIcon" />
                      <span className="nav-link-text">Reports</span>
                    </div>
                  </NavLink>
                </NavItem> */}
                {/* <div className="blankdiv"></div>
                <NavItem>
                  <NavLink to="/admin/clients" tag={Link}>
                    <div>
                      <img alt="" src={Clientsimg} style={{ marginRight: '20px' }} />
                      <span className="nav-link-text">Clients</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/team" tag={Link}>
                    <div>
                      <img alt="" src={Temeimg} style={{ marginRight: '20px' }} />
                      <span className="nav-link-text">Team</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/vendors" tag={Link}>
                    <div>
                      <img alt="" src={Vendorimg} style={{ marginRight: '20px' }} />
                      <span className="nav-link-text">Vendors</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/store" tag={Link}>
                    <div>
                      <img alt="" src={Shoppingimg} style={{ marginRight: '20px' }} />
                      <span className="nav-link-text">Store</span>
                    </div>
                  </NavLink>
                </NavItem>
                */}
                <div className="blankdiv"></div>
              </>
            ) : (
              ""
            )}
            {contact.profiledetails.service_level === "Professional" ? (
              <>
                <div className="blankdiv"></div>
                <NavItem>
                  <NavLink to="/admin/organization" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Organizationimg} className="menuIcon" />
                      <span className="nav-link-text">Organization</span>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink to="#" tag={Link} onClick={ToggleSidebarStoryz}>
                    <div className="navLinkNew">
                      <img alt="" src={Storyzimg} className="menuIcon" />
                      <span className="nav-link-text">Storyz</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* /admin/event-dashboard */}

                  <NavLink
                    to="#"
                    tag={Link}
                    // onClick={() => setdefaultModale(true)}
                    onClick={ToggleSidebar}
                  >
                    <div className="navLinkNew">
                      <img alt="" src={Eventsimg} className="menuIcon" />
                      <span className="nav-link-text">Events</span>
                    </div>
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink to="/admin/reports" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Reportsimg} className="menuIcon" />
                      <span className="nav-link-text">Reports</span>
                    </div>
                  </NavLink>
                </NavItem> */}
                <div className="blankdiv"></div>
              </>
            ) : (
              ""
            )}

            {contact.profiledetails.service_level === "Locator" ? (
              <>
                <div className="blankdiv"></div>
                <NavItem>
                  <NavLink to="/admin/organization" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Organizationimg} className="menuIcon" />
                      <span className="nav-link-text">Organization</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="#" tag={Link} onClick={ToggleSidebar}>
                    <div className="navLinkNew">
                      <img alt="" src={Eventsimg} className="menuIcon" />
                      <span className="nav-link-text">Events</span>
                    </div>
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink to="#" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Reportsimg} className="menuIcon" />
                      <span className="nav-link-text">Reports</span>
                    </div>
                  </NavLink>
                </NavItem> */}
                <div className="blankdiv"></div>
              </>
            ) : (
              ""
            )}

            {contact.profiledetails.service_level === "DIY" ? (
              <>
                <div className="blankdiv"></div>
                <NavItem>
                  <NavLink to="/admin/organization" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Organizationimg} className="menuIcon" />
                      <span className="nav-link-text">Organization</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="#" tag={Link} onClick={ToggleSidebar}>
                    <div className="navLinkNew">
                      <img alt="" src={Eventsimg} className="menuIcon" />
                      <span className="nav-link-text">Events</span>
                    </div>
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink to="#" tag={Link}>
                    <div className="navLinkNew">
                      <img alt="" src={Reportsimg} className="menuIcon" />
                      <span className="nav-link-text">Reports</span>
                    </div>
                  </NavLink>
                </NavItem> */}
                <div className="blankdiv"></div>
              </>
            ) : (
              ""
            )}

            {/* <NavItem >
              <NavLink href="#pablo"
                data-toggle="collapse"
                aria-expanded="true" >
                <img alt="" src={Organizationimg} style={{ marginRight: '20px' }} />
                <span className="nav-link-text">Organization</span>

              </NavLink>
              <Collapse isOpen="true">
                <Nav className="nav-sm flex-column">
                  wqeqwe
                </Nav>
              </Collapse>
            </NavItem> */}
            {/* <NavItem>
              <NavLink to="/admin/account-owner-view" tag={Link}>
                <div>
                  <img alt="" src={Groupsimg} style={{ marginRight: "20px" }} />
                  <span className="nav-link-text">Account</span>
                </div>
              </NavLink>
            </NavItem> */}

            {contact.profiledetails.account_type === "Admin" ? (
              ""
            ) : (
              <NavItem>
                <NavLink to="/admin/upgrade-service" tag={Link}>
                  <div className="navLinkNew">
                    <img alt="" src={Upgradeimg} className="menuIcon" />
                    <span className="nav-link-text">Upgrade</span>
                  </div>
                </NavLink>
              </NavItem>
            )}

            <NavItem>
              <NavLink href="#" onClick={logOut}>
                <div className="navLinkNew">
                  <img alt="" src={Logoutimg} className="menuIcon" />
                  <span className="nav-link-text">Log Out</span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>

      <div
        className={`sidebar sidebar-outer  ${isOpen == true ? "active" : ""}`}
      >
        <div className="sidebarmenuiner">
          <div className="sd-header">
            <h6 className="modal-title" id="modal-title-default">
              <img
                alt="..."
                src={require("assets/img/monogramcircular.svg").default}
                width="24px"
              />
              <span className="title pl-2">Events</span>
            </h6>
            <div onClick={ToggleSidebar}>
              <i className="fa fa-times"></i>
            </div>
          </div>
          <div className="sd-body p-2">
            <FormGroup style={{ marginBottom: "12px" }}>
              <Input
                id="example2cols1Input"
                placeholder="type to search..."
                type="text"
                value={search}
                onChange={handleChange}
              />
            </FormGroup>
            <section className="storyzsection mb-2 eventSidebarPopup">
              {eventList.length > 0 ? (
                <>
                  {eventList.map((evdata, i) => (
                    <div
                      className="story1"
                      key={i}
                      onClick={() => {
                        setCommandata(
                          evdata.project_id,
                          evdata.id,
                          evdata.project_.storyz_name,
                          evdata
                        );

                        history.push(
                          `/admin/event-dashboard/${evdata.project_id}/${evdata.id}`
                        );
                        setIsopen(false);
                      }}
                    >
                      <div className="date">
                        {evdata.event_start_date
                          ? moment(evdata.event_start_date).format("MMM Do YY")
                          : "No Date"}
                      </div>
                      <div>
                        <img
                          alt="Organization Name"
                          src={
                            evdata.cover_image ? evdata.cover_image : DummyImg
                          }
                          className="radius12"
                        />
                      </div>
                      <div>
                        <p className="titlee" style={{ margin: "0px" }}>
                          {evdata.event_name}
                        </p>
                        <p className="subtitle">Location</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                "No Event Data"
              )}
            </section>
            {contact.profiledetails.service_level === "DIY" ||
              contact.profiledetails.service_level === "Professional" ? (
              <>
                <Link
                  to="#"
                  className="newstoryz"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    setIsstory(
                      parseInt(history?.location?.pathname.split("/")[3])
                    );
                    //setdefaultModale(true);
                    setIsopen(false);
                    dispatch({ type: actionTypes.EVENTS_POPUP, payload: true });
                  }}
                >
                  New Event
                </Link>

                <EventConfiguration
                  show={contact3}
                  onHide={() => {
                    dispatch({
                      type: actionTypes.EVENTS_POPUP,
                      payload: false,
                    });
                  }}
                  sId={parseInt(history?.location?.pathname.split("/")[3])}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="floatingSearchDialogfooter"></div>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
        onClick={ToggleSidebar}
      ></div>

      <div
        className={`sidebar sidebar-outer  ${isStoryz == true ? "active" : ""}`}
      >
        <div className="sidebarmenuiner">
          <div className="sd-header">
            <h6 className="modal-title" id="modal-title-default">
              <img
                alt="..."
                src={require("assets/img/monogram_circularstroy.svg").default}
                width="25px"
              />
              <span className="title pl-2">Storyz</span>
            </h6>
            <div onClick={ToggleSidebarStoryz}>
              <i className="fa fa-times"></i>
            </div>
          </div>
          <div className="sd-body p-2">
            <FormGroup style={{ marginBottom: "12px" }}>
              <Input
                id="example2cols1Input"
                placeholder="type to search..."
                type="text"
                onChange={handleStoryz}
                value={search}
              />
            </FormGroup>
            <section className="storyzsection storyzSlide mb-2">
            {storyzList?.length > 0 ? (
                <>
                  {storyzList?.map((popup, i) => (
                    <div key={i}>
                      <div
                        className="story1"

                        onClick={() => {
                          history.push(`/admin/storyz/${popup.id}`);
                          setisStoryz(false);
                        }}
                      >
                        <div className="date">
                          {popup.project_state_date
                            ? moment(popup.project_state_date).format(
                              "MMM Do YY"
                            )
                            : "No Date"}
                        </div>
                        <div>
                          <img
                            alt="Organization Name"
                            src={
                              popup.project_image
                                ? popup.project_image
                                : DummyImg
                            }
                            className="stimage"
                          />
                        </div>

                        <div style={{ paddingLeft: "10px" }}>
                          <p className="titlee" style={{ margin: "0px" }}>
                            {popup.storyz_name}
                          </p>
                          <p className="subtitle" style={{ margin: "0px" }}>
                            Event {popup.event_count}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                "No Storyz Data"
              )}
            </section>
            {contact.profiledetails.service_level === "Professional" ? (
              <>
                <Link
                  className="newstoryz"
                  style={{ marginTop: "0px" }}
                  to="#"
                  // onClick={ToggleSidebarStoryz}
                  onClick={() => {
                    setisStoryz(false);
                    dispatch({ type: actionTypes.STORYZ_POPUP, payload: true });
                  }}
                >
                  New Storyz
                </Link>
                <Storyzconfiguration
                  show={contact2}
                  onHide={() => {
                    // setdefaultStoryx(false)
                    dispatch({
                      type: actionTypes.STORYZ_POPUP,
                      payload: false,
                    });
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="floatingSearchDialogfooter"></div>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isStoryz == true ? "active" : ""}`}
        onClick={ToggleSidebarStoryz}
      ></div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => { },
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
