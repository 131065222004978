import React from "react";
// react library for routing
import { useLocation } from "react-router-dom";

// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Signup from "views/pages/examples/Signup.js";
import AuthFooter from "components/Footers/AuthFooter.js";

// import routes from "routes.js";

function SignUpLayout() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-default");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.collapse) {
  //       return getRoutes(prop.views);
  //     }
  //     if (prop.layout === "/auth") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  return (
    <>
      <div className="main-content login-backimage" ref={mainContentRef}>
        <Signup />
      </div>
      <AuthFooter />
    </>
  );
}

export default SignUpLayout;
