import Global from "../../_helpers/global";
export const userService = {
    login,
    scoiallogin,
    resetPasswordLink,
    scoialloginf,
    resetPasswordupdate,
    invitationUser,
    sendOtp,
    getGuestdata,
    otpVerification,
    getGuestColumn,
    setPassword,
    verifyCode,
};

async function getGuestColumn(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/get-guest-column`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function invitationUser(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/invite/register`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function getGuestdata(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/invite/get-guest-data`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function resetPasswordLink(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/auth/resetPasswordLink`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function scoialloginf(data) {
    const senddata = {
        name: data.name,
        email: data.email,
        provider: "FACEBOOK",
        provider_id: data.id,
        imageUrl: data.picture.data.url,
        // "name": "kundan",
        // "email": "test@gmail.com",
        // "provider": "GOOGLE",
        // "provider_id": "1"
    };
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(senddata),
    };

    return fetch(Global.BASE_API_PATH + `/auth/social-login`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function scoiallogin(data) {
    const senddata = {
        name: data.name,
        email: data.email,
        provider: "GOOGLE",
        provider_id: data.googleId,
        imageUrl: data.imageUrl,
        // "name": "kundan",
        // "email": "test@gmail.com",
        // "provider": "GOOGLE",
        // "provider_id": "1"
    };
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(senddata),
    };

    return fetch(Global.BASE_API_PATH + `/auth/social-login`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function resetPasswordupdate(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/auth/resetpassword`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function login(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/auth/login`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function setPassword(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/auth/set-password`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function verifyCode(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/auth/verify-code`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}


async function sendOtp(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/auth/send-otp`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function otpVerification(data) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/auth/two_step_verification`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        console.log(response);
        return response;
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
