import Global from "../../_helpers/global";
import { authHeader } from "../../_helpers/auth-header";
export const userGuestService = {
    adduserGuest,
    userGuestList,
    prefixData,
    listPrefix,
    suffixData,
    listSuffix,
    userGuestListStory,
    roleData,
    rolelist,
    guestMedia,
    getAllmedia,
    getKeepsake,
    getStoryzKeepsake,
    listPrifix,
    getCountries,
    grouplistAll,
    updateData,
    updateDropDown,
    searchAPI,
    listNotifications,
    addKeepsake,
    updateKeepsake,
    getallmedia,
    listNotifications,
    deleteNotifications,
    clearNotifications,
    createAlbumStoryz,
    fundeletePhoto,
    storyzAlbumdetails,
    addInviteRsvp,
    guestInviteList,
    guestInvitedelete,
    guestInvitedetails,
    eventUpdate,
    assignAdmin,
    changeCoverImage,
    addRole,
    guesthonoredList,
    addguesteventRole,
    removeguestrole,
    allRemoveGuestRole,
    storyzKeepsakeAlbumupdateImage,
    uploadGuest,
    uploadStoryzGuest,
    guestDetails,
    sendNow,
    updateRsvpStatus,
    getEvent,
    deleteAll,
    getRsvp,
    getEventRsvp,
    updateRSVP,
    deleteRsvpGuest,
    uploadGuestCSV,
    listGuestauth,
    InsetGuestauth,
    honoredGuests,
    honorInstoryz,
    deletesingleEvent,
    getGuestKeepsake,
    getDeleteData,
    deleteData,
    getCountNotificat,
    getCountUpdate,
};

async function deletesingleEvent(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest/delete-single-event`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function honorInstoryz(sid) {
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(sid),
    };
    return fetch(
        Global.BASE_API_PATH + `/project/honor-in-storyz`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function honoredGuests(sid) {
    const evid = { storyz_id: sid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(Global.BASE_API_PATH + `/project/honored-guests`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function uploadGuestCSV(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/event/upload-guest`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteRsvpGuest(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest-invite/delete-rsvp-guest`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateRSVP(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest-invite/update-rsvp`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listGuestauth(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/event/guest_auth/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function InsetGuestauth(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/event/guest_auth/createUpdate`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getEventRsvp(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest-invite/get-event-rsvp`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getRsvp(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/get-rsvp`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function storyzKeepsakeAlbumupdateImage(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/update-image`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function sendNow(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/sendmail`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateRsvpStatus(vd) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(vd),
    };
    return fetch(
        Global.BASE_API_PATH + `/event/update-rsvp-status`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getEvent(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/get-event`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteAll(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest-invite/delete-all`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function guestInvitedetails(id) {
    const vid = { id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(vid),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/details`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function guestDetails(id) {
    const vid = { id: id };

    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(vid),
    };
    return fetch(Global.BASE_API_PATH + `/guest/details`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function eventUpdate(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/event/event-update`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function uploadGuest(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(Global.BASE_API_PATH + `/event/upload-guest`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function uploadStoryzGuest(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        mimeType: "multipart/form-data",
        body: data,
    };
    return fetch(Global.BASE_API_PATH + `/project/upload-guest`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function removeguestrole(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest/remove-guest-role`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function addguesteventRole(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest/add-guest-event-role`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function addRole(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/create/role`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function guesthonoredList(eid) {
    const evid = { event_id: eid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest/honored-guest-list`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function guestInvitedelete(id) {
    const vid = { id: id };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(vid),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/delete`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function guestInviteList(eid) {
    const evid = { event_id: eid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(Global.BASE_API_PATH + `/guest-invite/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function storyzAlbumdetails(sid) {
    const evid = { storyz_id: sid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/albumdetails`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function fundeletePhoto(id) {
    const deid = { id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(deid),
    };
    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/delete-photo`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function allRemoveGuestRole(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(id),
    };
    return fetch(
        Global.BASE_API_PATH + `/guest/all-remove-guest-role`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteNotifications(id) {
    const deid = { id: id };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(deid),
    };
    return fetch(Global.BASE_API_PATH + `/notification/delete`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getCountNotificat() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        //body: JSON.stringify(deid)
    };
    return fetch(
        Global.BASE_API_PATH + `/notification/count-unread`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getCountUpdate() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        //body: JSON.stringify(deid)
    };
    return fetch(
        Global.BASE_API_PATH + `/notification/count-update`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function clearNotifications() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/notification/clearAll`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listNotifications() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/notification/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getallmedia() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };

    return fetch(Global.BASE_API_PATH + `/keepsakealbum/getmedia`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
            console.log(res);
        });
}
async function createAlbumStoryz(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: data,
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/create-album`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function addInviteRsvp(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: data,
    };
    return fetch(
        Global.BASE_API_PATH + `/guest-invite/createUpdate`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function updateData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/updateData`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function searchAPI(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/searchAPI`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateDropDown(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/updateDropDown`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function changeCoverImage(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: data,
    };

    return fetch(
        Global.BASE_API_PATH + `/event/change-cover-image`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function updateKeepsake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/update-photo`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function addKeepsake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/upload-photo`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function guestMedia(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(true),
        body: data,
    };

    return fetch(Global.BASE_API_PATH + `/create-media`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function getCountries(data) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/getCountries`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listPrifix(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/guest/prefix/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function grouplistAll(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/guest/grouplist`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getKeepsake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(Global.BASE_API_PATH + `/keepsakealbum/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getStoryzKeepsake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/storylist`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getGuestKeepsake(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/guestlist`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getDeleteData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };

    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/get-delete-data`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function deleteData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(
        Global.BASE_API_PATH + `/keepsakealbum/delete-data`,
        requestOptions
    )
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function getAllmedia(data) {
    const all = {
        image_type: data,
    };
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(all),
    };

    return fetch(Global.BASE_API_PATH + `/getmedia`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            console.log(res);
            return res;
        });
}

async function userGuestList(eid) {
    const evid = { event_id: eid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(Global.BASE_API_PATH + `/guest/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function userGuestListStory(eid, sid) {
    const evid = { event_id: eid, storyz_id: sid };
    //console.log(evid);
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(evid),
    };
    return fetch(Global.BASE_API_PATH + `/guest/list-by-storyz`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}
async function listSuffix() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/guest/suffix/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function listPrefix() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/guest/prefix/list`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function assignAdmin(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/event/assign-admin`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function prefixData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/add/prefix`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function rolelist() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
    };
    return fetch(Global.BASE_API_PATH + `/guest/rolelist`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function roleData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/create/role`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function suffixData(data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(data),
    };
    return fetch(Global.BASE_API_PATH + `/guest/add/suffix`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

async function adduserGuest(formdata) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(false),
        body: JSON.stringify(formdata),
    };
    return fetch(Global.BASE_API_PATH + `/guest/create`, requestOptions)
        .then(handleResponse)
        .then((res) => {
            return res;
        });
}

function handleResponse(response) {
    if (response.ok === false) {
        if (response.statusText === "Unauthorized") {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userdata");
            window.location = "/";
        }
    } else {
        return response.text().then((text) => {
            const data = JSON.parse(text);

            if (!response.ok) {
                if (response.status === 401) {
                    console.log(response);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    }
}
