import * as types from "../actionTypes";

export const clearstoryList = () => ({
    type: types.CLEAR_STORY_LIST,
    payload: "",
});

export function menuSave(payload) {
    return {
        type: types.MENU_ONOFF,
        payload: payload,
    };
}

export function honoredGuestList(payload) {
    return {
        type: types.HONORED_GUEST,
        payload: payload,
    };
}

export function userData(payload) {
    return {
        type: types.USER_INFO,
        payload: payload,
    };
}

export function userAdminData(payload) {
    return {
        type: types.USER_ADMIN,
        payload: payload,
    };
}

export const clearuserData = () => ({
    type: types.CLEAR_USER_INFO,
    payload: "",
});

export function mediaList(payload) {
    return {
        type: types.MEDIA_LIST,
        payload: payload,
    };
}
export function mediaClear(payload) {
    return {
        type: types.CLEAR_MEDIA,
        payload: [],
    };
}

export function eventsSave(payload) {
    return {
        type: types.EVENT_SAVE,
        payload: payload,
    };
}

export function storyzPopup(payload) {
    return {
        type: types.STORYZ_POPUP,
        payload: payload,
    };
}

export function eventsPopup(payload) {
    return {
        type: types.EVENTS_POPUP,
        payload: payload,
    };
}

export function allFiles(payload) {
    return {
        type: types.ALL_FILES,
        payload: payload,
    };
}

export function guestsLists(payload) {
    return {
        type: types.GUEST_LIST,
        payload: payload,
    };
}

export function eventsLists(payload) {
    return {
        type: types.EVENTS_LIST,
        payload: payload,
    };
}

export function storyLists(payload) {
    return {
        type: types.STORY_LIST,
        payload: payload,
    };
}

export function commonData(payload) {
    return {
        type: types.COMMON_DATA,
        payload: payload,
    };
}

export const removeeventKeepsake = (payload) => ({
    type: types.REMOVE_EVENT_KEEPSAKE_ALBUM,
    payload: payload,
});

export function eventKeepsake(payload) {
    return {
        type: types.EVENT_KEEPSAKE_ALBUM,
        payload: payload,
    };
}
