import styled from "styled-components";

export const StoryzThemeModalCss = styled.div`
  &.storyzThemeModal {
    .modal-dialog {
      max-width: 428px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .modal-header {
      padding: 6px 0;
      margin-bottom: 16px;
      .modal-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        .storyzThemeLogo {
          margin-right: 12px;
        }
      }
    }
    .btn-close {
      background: none;
      border: none;
      color: #666;
    }
    .modal-body {
      padding: 0;
    }
    .h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #1c1b1f;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 16px;
      }
    }
    .h5 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #1c1b1f;
      margin-bottom: 12px;
    }
    .h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #666666;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
    }
    .modal-content {
      background: #fdfdfd;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 24px;
    }
    .textBox {
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 16px;
      padding: 0 12px;
    }
    .fontWeight400 {
      font-weight: 400 !important;
    }
    .displayTypeWrapper {
      .label {
        max-width: 60px;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.6px;
        color: #1d192b;
        display: flex;
        align-items: center;
      }
    }
    .palatteWrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 4px;
      margin-bottom: 24px;
      .pallateList {
        display: flex;
        flex-direction: column;
        align-items: center;

        .palatteTxt {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #666666;
        }
        .colorPallate {
          width: 56px;
          height: 56px;
          background: #333333;
          border: 1px solid #939393;
          border-radius: 100px;
          margin: 4px 0;
          position: relative;
          .colorInput {
            position: absolute;
            width: 56px;
            height: 56px;
            opacity: 0;
          }
          &.screenTxt {
            background: #333333;
          }
          &.displayTxt {
            background: #ffffff;
          }
          &.adminTxt {
            background: #333333;
          }
          &.emphasisTxt {
            background: #ffffff;
          }
          &.screenBg {
            background: #97a391;
          }
          &.locationBg {
            background: #778a6e;
          }
          &.adminBg {
            background: #cfd8c0;
          }
          &.emphasisBg {
            background: #778a6e;
          }
          &.brdrTxt {
            border: 1px solid #939393;
          }
          &.brdrBg {
            border: 1px solid #939393;
          }
        }
      }
    }
    .bgPalatte {
      width: 56px;
      height: 56px;
      background: #333333;
      border-radius: 100px;
      margin: 4px 0;
      .bgImg {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .setImgWrapper {
      width: fit-content;
      display: flex;
      align-items: center;
      .setImgChip {
        padding: 6px 16px;
        height: 40px;
        background: rgba(198, 193, 203, 0.2);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;
        color: #1d192b;
        border-radius: 100px;
        margin: 0 12px;
        overflow: hidden;
        position: relative;
      }
      .fileUplaod {
        position: absolute;
        appearance: none;
        opacity: 0;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
    .closeChip {
      background: none;
      border: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;
    }
    .btn {
      margin: 0;
    }
    .saveChangesBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      height: 40px;
      background: #fc3158;
      border-radius: 8px;
      width: fit-content;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #ffffff;
    }
    .cancelBtn {
      padding: 10px 24px;
      width: fit-content;
      height: 40px;
      border: 2px solid #147efb;
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.4px;
      color: #666666;
      margin-left: 12px;
    }
    .displayDropdown {
      height: 40px;
      background: rgba(207, 207, 207, 0.15);
      border-radius: 6px;
      color: #666666;
      box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
      border: 1px solid #dee2e6;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.625rem 0.75rem;
      font-size: 0.875rem;
      transition: all 0.15s ease-in-out;
      display: block;
      width: 100%;
      .css-1s2u09g-control {
        background: rgba(207, 207, 207, 0.15);
        border: none;
      }
      .css-1okebmr-indicatorSeparator {
        background: none;
      }
      .css-qc6sy-singleValue {
        color: #666666;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
`;