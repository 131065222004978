import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { todoService } from "_services/masters/information.todos";
import paginationFactory from "react-bootstrap-table2-paginator";
import Imggroup from "../../assets/img/dummyImg.svg";
import moment from "moment";
import Allheader from "../../components/Headers/Allheader";
import DataFundata from "../../components/Todo/DataFundata";
import CreateTodo from "components/Todo/CreateTodo";
import CreateEditTodo from "components/Todo/CreateEditTodo";
import { Link } from "react-router-dom";
import TodoIcon from "./../../assets/img/todo-icon.svg";
import DeleteModal from "components/Modal/DeleteModal";
import mL from "_helpers/month";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  useFindPathSid,
  useFindPathEid,
  useFindPathid,
} from "../../CommonData";
import { GrCircleInformation } from "react-icons/gr";

import { TailSpin } from "react-loader-spinner";
import { informationService } from "../../_services/masters/information.service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { SearchBar } = Search;

function StoryzTodos() {
  let history = useHistory();
  let toID = useFindPathSid(3);
  const emptyDataMessage = () => {
    return "No Data to Display";
  };
  const [todolist, setTodolist] = useState([]);
  const [modal_flag, setmodalFlag] = useState(false);
  const [EventData, setEventData] = useState({});
  const [delEventData, setDelEventData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  let eventTodoArr = [];
  let urlArr = window.location.href.split("/");
  let event_id = useFindPathEid(4);
  let storyz_id = useFindPathSid(3);

  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let storyz_name = localStorage.getItem("todo_storyz_name");
  let storyz_img = localStorage.getItem("todo_storyz_img");

  const [storyData, setstoryData] = React.useState({});
  const params = useParams();
  const [paramsId, setParamsId] = React.useState(params.sid ? params.sid : "");
  function storyzDetail() {
    const data = {
      story_id: paramsId,
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setstoryData(res.data[0]);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    storyzDetail();
  }, [paramsId]);

  localStorage.setItem("count_s", Math.floor(Math.random() * 11));

  const TodoModal = () => {
    setmodalFlag(!modal_flag);
    listStoryzTodo();
  };

  const editTodo2 = (props) => {
    //console.log('---props', props);
    //console.log('propsRohit', props);
    setEventData(props);
    setTimeout(() => {
      setmodalFlag(true);
    }, 1);
  };

  const handelDeleteModal = (props) => {
    setDelEventData(props);
    setDeleteModal(!deleteModal);
    listStoryzTodo();
  };

  const refresh = () => {
    listStoryzTodo();
    localStorage.setItem("count_s", Math.floor(Math.random() * 4));
  };

  //  const onSize = (size_val) => {
  //       setSize(size_val)
  //       localStorage.setItem('size_s', size_val);
  //       //localStorage.setItem('count_s', Math.floor(Math.random()*2));
  // };

  const listStoryzTodo = () => {
    let resdata = { storyz_id: storyz_id };
    todoService.listStoryzTodo(resdata).then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;

          let obj = {};
          for (let index = 0; index < respons.length; index++) {
            let assignedArr = respons[index].assigned_on.split("T");
            let assigned_date_arr = assignedArr[0].split("-");
            let day = assigned_date_arr[assigned_date_arr.length - 1];
            assigned_date_arr[assigned_date_arr.length - 1] = day;
            let assigned_date = moment
              .utc(respons[index].assigned_on)
              .format("DD/MM/YYYY h:mm a");

            let dueArr = respons[index].due_date.split("T");
            let due_date_arr = dueArr[0].split("-");
            let end_day = due_date_arr[due_date_arr.length - 1];
            due_date_arr[due_date_arr.length - 1] = end_day;
            let due_date = moment
              .utc(respons[index].due_date)
              .format("DD/MM/YYYY h:mm a");
            let start_date = "";

            if (respons[index].start_date != null) {
              let start_dateArr = respons[index].start_date.split("T");
              let start_date_Arr = start_dateArr[0].split("-");
              let strat_day = start_date_Arr[start_date_Arr.length - 1];
              let strat_month = start_date_Arr[start_date_Arr.length - 2];
              let monthArr = strat_month.split("");
              let m = 0;
              if (monthArr[0] == 0) {
                m = monthArr[1];
              } else {
                m = monthArr[0] + monthArr[1];
              }
              m = parseInt(m - 1);
              //console.log('monthArr', monthArr);
              start_date = ` ${mL[m]} ${strat_day}`;
            }
            obj = {
              todo_name: respons[index].todo_name,
              actions: respons[index].id,
              guest_name: (
                <DataFundata
                  start_date={start_date}
                  id={respons[index].id}
                  title={respons[index].todo_name}
                  assigned={assigned_date}
                  due={due_date}
                  cover_image={respons[index].project_image}
                  event_name={respons[index].storyz_name}
                  todo_status={respons[index].todo_status}
                  url={respons[index].knowledge_base_url}
                  shortdes={respons[index].short_description}
                  todo_type={respons[index].todo_type}
                  editTodo2={editTodo2}
                  handelDeleteModal={handelDeleteModal}
                  oldDate={respons[index].assigned_on}
                  endoldDate={respons[index].due_date}
                  storyz
                />
              ),
            };
            eventTodoArr.push(obj);
          }
          setTodolist(eventTodoArr);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  useEffect(() => {
    listStoryzTodo();
  }, [localStorage.getItem("count_s")]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              // {size}
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              {/* <option value={size}>{size}</option> */}
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const dataTable = todolist;

  return (
    <>
      {storyData ? (
        <>
          <ToastContainer />
          <DeleteModal
            deleteModal={deleteModal}
            handelDeleteModal={handelDeleteModal}
            delEventData={delEventData}
            refresh={refresh}
            storyz
          />
          <CreateEditTodo
            modal_flag={modal_flag}
            TodoModal={TodoModal}
            refresh={refresh}
            title=""
            EventData={EventData}
            storyz
          />
          <Allheader
            id={storyData?.proId}
            title={
              storyData?.storyz_name
                ? storyData?.storyz_name
                : "New Storyz edit"
            }
            imgScr={
              storyData?.profile_photo_Url
                ? storyData?.profile_photo_Url
                : Imggroup
            }
            arw
          />
          {/* <Allheader title="Todos" /> */}
          <div className="storyzWrapperMain todo">
            {/* <Row style={{ display: "grid" }} className="todoslist">
          <div className="col"> */}
            <div className="card storyzCardWrapper">
              <div className="headingWrapper borderBottom">
                <h4 className="heading-1">Storyz-Level Todos</h4>
                <GrCircleInformation className="ml-auto iconColor infoToolTip" />
              </div>

              <div className="borderBottom organizationPara">
                This page lets you view and create a todo list associated with
                this specific Storyz.
                <br />
                To see tools related to your organization, go to the{" "}
                <Link
                  onClick={() => {
                    history.push(`/admin/organization`);
                  }}
                  className="ml-1"
                >
                  {" "}
                  Organization view.
                </Link>
              </div>
              <ToolkitProvider
                data={dataTable}
                keyField="name"
                columns={[
                  {
                    dataField: "guest_name",
                    text: "",
                    sort: false,
                  },
                  {
                    dataField: "todo_name",
                    sort: false,
                    style: { display: "none" },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="todoTableWrapper storyTodoTableWrapper">
                    <div className="todoTableWrapperInner storyzTodoWrapper">
                      <div className="storyzTodoList">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter"
                        >
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/monogram-circular.svg")
                                .default
                            }
                          />
                          <SearchBar
                            className="form-control"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </div>
                      </div>

                      <div className="storyzTodoList">
                        <div
                          className="todoIconWrapper"
                          onClick={() => {
                            history.push(`/admin/todos`);
                          }}
                        >
                          <img src={TodoIcon} alt="" className="toIcon" />
                          Organization Todos
                        </div>
                        <div className="todoIconWrapper">
                          <CreateTodo
                            title="New Storyz Todo"
                            refresh={refresh}
                            storyz
                          />
                        </div>
                      </div>
                    </div>
                    <div className="eventTodoTableNew">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        noDataIndication={emptyDataMessage}
                      ></BootstrapTable>
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
            {/* </div>
        </Row> */}
          </div>
        </>
      ) : (
        <>
          <div className="loding">
            <TailSpin
              ariaLabel="loading"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}

export default StoryzTodos;
