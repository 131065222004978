import React from "react";
import { Input, Form, Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import HeaderSection from "../../components/Headers/HeaderSection";
import { informationService } from "../../_services/masters/information.service";
import Allheader from "../../components/Headers/Allheader";
import {
  InputField,
  CheckboxField,
  TextareaField,
} from "../../components/Inputbox";
import Selectbox from "../../components/Inputbox/selectbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Events from "../../components/Events";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import GlobalButton from "components/Button/GlobalButton";
function StoryzAbout() {
  const [imgData, setImgData] = React.useState(null);
  const [imgDa, setImgDa] = React.useState(null);
  const [ourStoryzchke, setOurstoryzchke] = React.useState(0);
  const [attirechke, setAttirechke] = React.useState(0);
  const [additionalchke, setAdditionalchke] = React.useState(0);
  const [howtochke, setHowtochke] = React.useState(0);
  const [questions, setQuestions] = React.useState(0);
  const [selectboxS, setSelectboxS] = React.useState("");
  const [val, setcheckVal] = React.useState(0);

  const [userInfo, setUserInfo] = React.useState({});

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const contact = useSelector((state) => {
    return state.profile;
  });
  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    if (e.target.files[0]) {
      setImgData(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleChangeinput = (e) => {
    // set userInfo state

    userInfo[e.targe.name] = e.targe.value;
    setUserInfo({ ...userInfo });
  };

  const onSubmit = (data) => {

    var formdata = new FormData();
    formdata.append("storyz_name", data.project_name);
    formdata.append("hosted_by", data.hosted_by);
    formdata.append("project_image", imgDa);
    formdata.append("storyz_type", selectboxS.event_type);

    formdata.append("greeting_title", data.title_greeting);
    formdata.append("greeting_description", data.greeting);
    formdata.append("send_greeting", data.checkboxcontrol);

    formdata.append("is_our_storyz", data.usethisfieldstoryz);
    formdata.append("our_storyz_title", data.information);
    formdata.append("our_storyz_description", data.our_storyz_description);

    formdata.append("is_attire", data.usethisfieldattire);
    formdata.append("attire_title", data.attire);
    formdata.append("attire_description", data.attire_description);

    formdata.append("is_additional_things", data.usethisfieldadditional);
    formdata.append("additional_things_title", data.additional);
    formdata.append(
      "additional_things_description",
      data.additional_things_description
    );

    formdata.append("is_how_to_get", data.usethisfieldget);
    formdata.append("how_to_get_title", data.titlearrangements);
    formdata.append("how_to_get_description", data.how_to_get_description);

    formdata.append("is_que_and_ans", data.usethisfieldquestions);
    formdata.append("que_and_ans_title", data.titlequ);
    formdata.append("que_and_ans_description", data.que_and_ans_description);

    formdata.append(
      "organization_id",
      contact?.profiledetails.organization[0]?.organization_id
    );

    // for (var key of formdata.entries()) {
    //     console.log(key[0] + ', ' + key[1]);
    // }
    informationService.storyzSave(formdata).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          reset();
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  return (
    <>
      <Allheader
        title={userInfo.project_name ? userInfo.project_name : "New Storyz"}
      />
      <div className="storyabout ptop backgroevent">
        <Form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <div className="col">
            <div className="card" style={{ padding: "16px" }}>
              <HeaderSection
                title="About"
                tooltip="This page shows your current billing level, payment method, and billing history."
                setdata="This page lets youprovidie additional information to your audience about your overall Storyz. You can provide additional information for each event by navigating to the event page."
              />
              <Col md="12">
                <div style={{ float: "right" }}>
                  <img
                    alt="Organization Name"
                    src={require("assets/img/Groupexp.svg").default}
                    className="exportbillingdataimag"
                  />
                  <p className="exportbillingdata">Preview</p>
                </div>
              </Col>
              <div className="p-4">

                <GlobalButton
                  customClass="ml-auto"
                  img={require("assets/img/hornIcon.svg").default}
                  type="submit"
                >
                  Save Changes
                </GlobalButton>
              </div>
              <Row>
                <Col md="12">
                  <p className="basicinformation mt-2">
                    <img
                      src={require("assets/img/aour-storyz.svg").default}
                      style={{ marginRight: "11px" }}
                      alt=""
                    />
                    Our Storyz
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="coverimagest">
                    <div className="coverimage1">
                      <p className="fontSize14" style={{ color: "#666666" }}>
                        Cover Image :
                      </p>
                    </div>
                    <div>
                      <div
                        className="upload-btn-wrapper"
                        style={{ marginLeft: "10px" }}
                      >
                        <img
                          src={require("assets/img/update.svg").default}
                          className="mb-3"
                          alt=""
                        />

                        <Input id="profilePic" type="file" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="imgMask1">
                      <div className="imgMask-content">+</div>

                      {imgData ? (
                        <img
                          className="storytoimage"
                          id="blah2"
                          src={imgData ? imgData : ""}
                          alt="."
                        />
                      ) : (
                        ""
                      )}
                      <input
                        type="file"
                        className="imgg1"
                        id="imgInp"
                        onChange={fileHandler}
                      />
                    </div>

                    {/* <img className='storytoimage' alt="Organization Name" src="/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg" /> */}
                  </div>
                </Col>
                <Col md="6">
                  <InputField
                    type="text"
                    placeholder="Storyz Name Goes Here"
                    label="Storyz Name:"
                    name="project_name"
                    value={userInfo.project_name}
                    onChange={handleChangeinput}
                    {...register("project_name", { required: true })}
                  />
                  {errors.project_name && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}

                  <p className="information">
                    Information Below is Defined by Editing Your Events:
                  </p>
                  <p className="information">
                    <b>Dates:</b> Aug 12 - Aug 14, 2022
                  </p>
                  <p className="information">
                    <b>Location:</b> Miami, FL
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="6">
                  <Selectbox onChangeSelect={setSelectboxS} />
                  <div className="mb-3"></div>
                  <InputField
                    type="text"
                    placeholder="Hosted by"
                    label="Hosted by:"
                    name="hosted_by"
                    value={userInfo.hosted_by}
                    onChange={handleChangeinput}
                    {...register("hosted_by", { required: true })}
                  />
                  {errors.hosted_by && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <InputField
                    type="text"
                    placeholder="Welcome!"
                    label="Title for Greeting:"
                    name="title_greeting"
                    value={userInfo.title_greeting}
                    onChange={handleChangeinput}
                    {...register("title_greeting", { required: true })}
                  />
                  {errors.greeting && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <InputField
                    type="text"
                    placeholder="Enter a short greeting to your guests....."
                    label="Greeting:"
                    name="greeting"
                    value={userInfo.greeting}
                    onChange={handleChangeinput}
                    {...register("greeting", { required: true })}
                  />
                  {errors.greeting && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <p className="basicinformation mt-2">
                    <img
                      src={require("assets/img/greetingicon.svg").default}
                      style={{ marginRight: "11px" }}
                    />
                    Let Guests Send You a Greeting?
                  </p>
                  <p>
                    <small>
                      Leave this checked to show a “send greeting” option to
                      guests. Their greeting will go to all the Honored Guests
                      specified in the event.
                    </small>
                  </p>
                  <div className="ml-n3">
                    <CheckboxField
                      label="Checkbox control"
                      {...register("checkboxcontrol")}
                      name="checkboxcontrol"
                      onChange={(e) => {
                        setValue(
                          "checkboxcontrol",
                          e.target.checked ? "1" : "0"
                        );
                        setcheckVal(!val);
                      }}
                      check={val}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <Events />
                </Col>
                <Col md="12" className="mt-4">
                  <div className="emenu">
                    <div className="emenu1">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/sound.svg").default}
                        style={{ marginRight: "10px" }}
                      />
                      <h3 className="mb-0 fontSize20 JakartaMedium300">
                        Optional Fields
                      </h3>
                    </div>
                    <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/tip.svg").default}
                        style={{ marginRight: "10px" }}
                      />
                    </span>
                    <ReactTooltip />
                  </div>
                  <hr style={{ marginTop: "1rem", marginBottom: "1em" }} />
                </Col>
                <Col md="12">
                  <div className="storydaall" id="ourStoryz">
                    <p>Our Storyz</p>
                    <div style={{ textAlign: "right" }}>
                      <CheckboxField
                        label="Use this Field"
                        name="usethisfieldstoryz"
                        {...register("usethisfieldstoryz")}
                        onChange={(e) => {
                          setValue(
                            "usethisfieldstoryz",
                            e.target.checked ? "1" : "0"
                          );
                          setOurstoryzchke(!ourStoryzchke);
                        }}
                        check={ourStoryzchke}
                      />
                    </div>
                  </div>
                </Col>
                {ourStoryzchke ? (
                  <>
                    <Col md="12">
                      <InputField
                        type="text"
                        placeholder="Information About This Event....."
                        label="Title (used to link to Storyz page in the Guest App):"
                        name="information"
                        value={userInfo.information}
                        onChange={handleChangeinput}
                        {...register("information")}
                      />

                      <TextareaField
                        name="our_storyz_description"
                        value={userInfo.our_storyz_description}
                        onChange={handleChangeinput}
                        {...register("our_storyz_description")}
                        row="3"
                        placeholder="This storyz rox. It will be the greatest storyz ever told."
                        label="Description:"
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12">
                  <hr style={{ marginTop: "0rem", marginBottom: "1em" }} />
                </Col>
                <Col md="12">
                  <div className="storydaall" id="Attire">
                    <p>Attire</p>
                    <div style={{ textAlign: "right" }}>
                      <CheckboxField
                        label="Use this Field"
                        name="usethisfieldattire"
                        {...register("usethisfieldattire")}
                        onChange={(e) => {
                          setValue(
                            "usethisfieldattire",
                            e.target.checked ? "1" : "0"
                          );
                          setAttirechke(!attirechke);
                        }}
                        check={attirechke}
                      />
                    </div>
                  </div>
                </Col>

                {attirechke ? (
                  <>
                    <Col md="12">
                      <InputField
                        type="text"
                        placeholder="Attire....."
                        label="Title:"
                        name="attire"
                        value={userInfo.attire}
                        onChange={handleChangeinput}
                        {...register("attire")}
                      />

                      <TextareaField
                        name="attire_description"
                        value={userInfo.attire_description}
                        onChange={handleChangeinput}
                        {...register("attire_description")}
                        row="3"
                        placeholder="A description of dress codes and clothing, if needed):"
                        label="A description of dress codes and clothing, if needed):"
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12">
                  <hr style={{ marginTop: "0rem", marginBottom: "1em" }} />
                </Col>
                <Col md="12" id="thingsToKnow">
                  <div className="storydaall">
                    <p>Additional Things to Know</p>
                    <div style={{ textAlign: "right" }}>
                      <CheckboxField
                        label="Use this Field"
                        name="usethisfieldadditional"
                        {...register("usethisfieldadditional")}
                        onChange={(e) => {
                          setValue(
                            "usethisfieldadditional",
                            e.target.checked ? "1" : "0"
                          );
                          setAdditionalchke(!additionalchke);
                        }}
                        check={additionalchke}
                      />
                    </div>
                  </div>
                </Col>
                {additionalchke ? (
                  <>
                    <Col md="12">
                      <InputField
                        type="text"
                        placeholder="Additional Things to Know....."
                        label="Title:"
                        name="additional"
                        value={userInfo.additional}
                        onChange={handleChangeinput}
                        {...register("additional")}
                      />

                      <TextareaField
                        name="additional_things_description"
                        value={userInfo.additional_things_description}
                        onChange={handleChangeinput}
                        {...register("additional_things_description")}
                        row="3"
                        placeholder="Special information related to the events:"
                        label="Special information related to the events:"
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12">
                  <hr style={{ marginTop: "0rem", marginBottom: "1em" }} />
                </Col>
                <Col md="12">
                  <div className="storydaall" id="aeroplane">
                    <p>How to Get Here</p>
                    <div style={{ textAlign: "right" }}>
                      <CheckboxField
                        label="Use this Field"
                        name="usethisfieldget"
                        {...register("usethisfieldget")}
                        onChange={(e) => {
                          setValue(
                            "usethisfieldget",
                            e.target.checked ? "1" : "0"
                          );
                          setHowtochke(!howtochke);
                        }}
                        check={howtochke}
                      />
                    </div>
                  </div>
                </Col>
                {howtochke ? (
                  <>
                    <Col md="12">
                      <InputField
                        type="text"
                        placeholder="How to get here..."
                        label="Title:"
                        name="titlearrangements"
                        value={userInfo.titlearrangements}
                        onChange={handleChangeinput}
                        {...register("titlearrangements")}
                      />

                      <TextareaField
                        name="how_to_get_description"
                        value={userInfo.how_to_get_description}
                        onChange={handleChangeinput}
                        {...register("how_to_get_description")}
                        row="3"
                        placeholder="Travel Arrangements for the events, if present:"
                        label="Travel Arrangements for the events, if present:"
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12">
                  <hr style={{ marginTop: "0rem", marginBottom: "1em" }} />
                </Col>
                <Col md="12">
                  <div className="storydaall">
                    <p>Questions and Answers</p>
                    <div style={{ textAlign: "right" }}>
                      <CheckboxField
                        label="Use this Field"
                        name="usethisfieldquestions"
                        onChange={(e) => {
                          setValue(
                            "usethisfieldquestions",
                            e.target.checked ? "1" : "0"
                          );
                          setQuestions(!questions);
                        }}
                        check={questions}
                      />
                    </div>
                  </div>
                </Col>
                {questions ? (
                  <>
                    <Col md="12">
                      <InputField
                        type="text"
                        placeholder="Questions and Answers..."
                        label="Title:"
                        name="titlequ"
                        value={userInfo.titlequ}
                        onChange={handleChangeinput}
                        {...register("titlequ")}
                      />
                      <TextareaField
                        row="3"
                        placeholder="List questions and answer them here:"
                        label="List questions and answer them here:"
                        name="que_and_ans_description"
                        value={userInfo.que_and_ans_description}
                        onChange={handleChangeinput}
                        {...register("que_and_ans_description")}
                      />
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12">
                  <hr style={{ marginTop: "0rem", marginBottom: "0em" }} />
                </Col>
              </Row>
              {/* <Button
                  className="btn btn-secondary mt-3 buttonfute"
                  type="submit"
                >
                  Save
                </Button> */}
            </div>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
}
export default StoryzAbout;
