import React, { forwardRef, useEffect, useId, useRef } from "react";
import classNames from "./AddressField.module.scss";
import cn from "classnames";
import { TailSpin } from "react-loader-spinner";
const AddressField = ({ label, disabled, value, datatr }, ref) => {
  const inputRef = useRef(null);
  const id = useId();
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => {
    setFocused(true);
    if (inputRef.current !== null) {
      inputRef.current.value = value ?? "";
      setTimeout(() => inputRef.current.focus(), 0);
    }
  };
  const onBlur = () => setFocused(false);

  useEffect(onBlur, [disabled]);

  return (
    <div className={classNames.address_field}>
      {label && (
        <>
          <label className={classNames.address_field__label} htmlFor={id}>
            {label}
          </label>{" "}
          {datatr ? (
            <TailSpin
              className="loader"
              color="#00BFFF"
              height={20}
              width={20}
            />
          ) : (
            ""
          )}
        </>
      )}
      <input
        onBlur={onBlur}
        ref={(node) => {
          inputRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        disabled={disabled}
        className={classNames.address_field__textarea}
        id={id}
        style={{ display: !disabled && focused ? "block" : "none" }}
      />
      {(disabled || !focused) && (
        <div
          onClick={onFocus}
          className={cn(
            classNames.address_field__dummy,
            disabled && classNames.address_field__dummy_disabled
          )}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default forwardRef(AddressField);
