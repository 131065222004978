import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  interactiveMapIsOpen: false
}

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    changeOpen(state, action) {
      state.interactiveMapIsOpen = action.payload
    }
  }
})

export const { changeOpen } = modalSlice.actions
export default modalSlice.reducer
