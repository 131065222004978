import React, { useEffect } from "react";

import Allheader from "../../components/Headers/AllheaderEvent";
import Imggroup from "../../assets/img/dummyImg.svg";
import Eventaboutmedia from "../../components/Events/eventaboutmedia";
import { useSelector } from "react-redux";
function EventAbout() {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />

      <div className="containerWraperOuter">
        <div className="card">
          <Eventaboutmedia />
        </div>
      </div>
    </>
  );
}
export default EventAbout;
