import React, { useEffect } from "react";
import Allheader from "components/Headers/AllheaderEvent";
import Imggroup from "../../assets/img/dummyImg.svg";
import MnanageInviteList from "components/Events/InviteRSVPs/MnanageInviteList";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const MnanageInvites = ({ ...args }) => {


  const params = useParams();

  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>
      <ToastContainer />
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter rsvpInviteOuter">
        <div className="card">
          <MnanageInviteList />
        </div>
        {/* <button
          type="button"
          className="resetGeoBtn d-flex align-items-center"
          onClick={handleDeleteGuestGroup}
        >
          <img
            alt="Organization Name"
            src={require("assets/img/invite-icon-white.svg").default}
            width={18}
            className="mr-2"
          />
          Delete All Invitations
        </button> */}
        {/* <Modal
          isOpen={deleteGuestGroup}
          toggle={handleDeleteGuestGroup}
          {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-4 pb-2">
              <img
                src={ResetGeospaceInfoIcon}
                alt=""
                className="geospaceInfoIcon"
              />
              <div className="head">Delete All Invites</div>
            </div>
            <Row>
              <Col className="px-3">
                <div className="eventThumWrapper">
                  <div className="eventThum">
                   <img
                      src={event_img}
                      alt=""
                      className="eventThumImg"
                    />
                  </div>
                  <div className="eventThumContent">
                    <div className="eventthumbTitle">{eventname}</div>
                    <div className="eventthumbSubTitle">{event_location}</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-3">
                <p>
                  Confirm that you want to ALL the invitations you created for
                  this event.
                </p>
                <p>
                  This does NOT recall or undo any invitations you have sent out
                  to your guests.
                </p>
                <p className="bold">
                  Your RSVPs will NOT be affected by deleting Invites.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button color="primary" className="flex-grow-1 pinkBtn"  onClick={() => deleteAll()}>
              Delete All Invites

            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => setDeleteGuestGroup(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    </>
  );
};

export default MnanageInvites;
