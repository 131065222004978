import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SocialMediaLinkIcon from "./../../assets/img/socialMediaLinkIcon.svg";
import InstagramIcon from "./../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../assets/img/vimeo-icon.svg";
import CloseCircle from "./../../assets/img/close-circle.svg";
import DummyImg from "../../assets/img/dummyImg.svg";
import { FaPlus } from "react-icons/fa";
import moment from "moment";
function WebSocialMedia({ args, add, name, img, role, date }) {
  let web_social_media = [];

  if (
    localStorage.getItem("web_social_media") !== undefined &&
    localStorage.getItem("web_social_media") !== null &&
    localStorage.getItem("web_social_media") !== "null" &&
    localStorage.getItem("web_social_media") !== ""
  ) {
    web_social_media = JSON.parse(localStorage.getItem("web_social_media"));
  } else {
    web_social_media = [];
  }
  const [socialMediaLink, setSocialMediaLink] = useState(false);
  const [createSocialMediaLink, setCreateSocialMediaLink] = useState(false);
  // const [setClosCircle] = useState(false);

  // const [iconactive, setIconactive] = useState("");
  const [socialMe, setSocialMe] = useState(web_social_media);
  let smValobj = {};
  let smImgobj = {};
  let smInput = {};

  //console.log("socialMe", socialMe);

  let arr = web_social_media;
  for (let index = 0; index < socialMe.length; index++) {
    let key = socialMe[index].key;
    let val = socialMe[index].value;
    if (key === "Facebook") {
      smValobj["Facebook"] = key;
      smInput["Facebook"] = val;
      smImgobj["Facebookurl"] = FacebookIcon;
    }

    if (key === "Instagram") {
      smValobj["Instagram"] = key;
      smInput["Instagram"] = val;
      smImgobj["Instagramurl"] = InstagramIcon;
    }

    if (key === "Twitter") {
      smValobj["Twitter"] = key;
      smInput["Twitter"] = val;
      smImgobj["Twitterurl"] = TwitterIcon;
    }

    if (key === "Tiktok") {
      smValobj["Tiktok"] = key;
      smInput["Tiktok"] = val;
      smImgobj["Tiktokurl"] = TiktokIcon;
    }

    if (key === "Youtube") {
      smValobj["Youtube"] = key;
      smInput["Youtube"] = val;
      smImgobj["Youtubeurl"] = YoutubeIcon;
    }

    if (key === "Vimeo") {
      smValobj["Vimeo"] = key;
      smInput["Vimeo"] = val;
      smImgobj["Vimeourl"] = VimeoIcon;
    }
  }
  if (arr.length === 0) {
    smValobj = {
      Facebook: "",
      Instagram: "",
      Twitter: "",
      Tiktok: "",
      Youtube: "",
      Vimeo: "",
    };

    smImgobj = {
      Facebookurl: "",
      Instagramurl: "",
      Twitterurl: "",
      Tiktokurl: "",
      Youtubeurl: "",
      Vimeourl: "",
    };
  }

  const [inputVal, setInputValue] = useState(smInput);

  //console.log('inputVal', inputVal);

  const [allValues, setAllValues] = useState(smValobj);
  const [imgactive, setImgactive] = useState(smImgobj);
  //console.log("allValues", allValues);

  const iconhandleClick = (event) => {
    //alert(event.target.alt);
    // alert(event.target.src);
    //setImgactive(event.target.src);
    setImgactive({ ...imgactive, [event.target.alt]: event.target.src });

    //setIconactive(event.target.id);
    //setClosCircle(true);
    setAllValues({ ...allValues, [event.target.id]: event.target.id });

    setInputValue({ ...inputVal, [event.target.id]: "" });
  };

  const handleSocialMediaLink = () => {
    for (let index = 0; index < socialMe.length; index++) {
      let key = socialMe[index].key;
      let val = socialMe[index].value;
      if (key === "Facebook") {
        smValobj["Facebook"] = key;
        smInput["Facebook"] = val;
        smImgobj["Facebookurl"] = FacebookIcon;
      }

      if (key === "Instagram") {
        smValobj["Instagram"] = key;
        smInput["Instagram"] = val;
        smImgobj["Instagramurl"] = InstagramIcon;
      }

      if (key === "Twitter") {
        smValobj["Twitter"] = key;
        smInput["Twitter"] = val;
        smImgobj["Twitterurl"] = TwitterIcon;
      }

      if (key === "Tiktok") {
        smValobj["Tiktok"] = key;
        smInput["Tiktok"] = val;
        smImgobj["Tiktokurl"] = TiktokIcon;
      }

      if (key === "Youtube") {
        smValobj["Youtube"] = key;
        smInput["Youtube"] = val;
        smImgobj["Youtubeurl"] = YoutubeIcon;
      }

      if (key === "Vimeo") {
        smValobj["Vimeo"] = key;
        smInput["Vimeo"] = val;
        smImgobj["Vimeourl"] = VimeoIcon;
      }
      setAllValues(smValobj);
      setImgactive(smImgobj);
      setInputValue(smInput);
    }
    setSocialMediaLink(!socialMediaLink);
  };

  const handleCreateSocialMediaLink = () => {
    setCreateSocialMediaLink(!createSocialMediaLink);
    //setSocialMediaLink(false)
  };
  const handleCreateSocialMediaLinkClose = () => {
    setCreateSocialMediaLink(false);
    setSocialMediaLink(true);
  };

  // const divIcon = () => {
  //   setClosCircle(false);
  // };

  function removeIcon(val) {
    delete inputVal[val];

    if (val === "Facebook") {
      delete inputVal.Facebook;
    }
    if (val === "Instagram") {
      delete inputVal.Instagram;
    }
    if (val === "Twitter") {
      delete inputVal.Twitter;
    }
    if (val === "Tiktok") {
      delete inputVal.Tiktok;
    }
    if (val === "Youtube") {
      delete inputVal.Youtube;
    }
    if (val === "Vimeo") {
      delete inputVal.Vimeo;
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setInputValue({ ...inputVal, [name]: value });
  }

  const handleSubmit = (event) => {
    // console.log(inputVal);
    //setSocialMe(dat2);
    //localStorage.setItem('socialMedia', JSON.stringify(inputVal));
    // alert(`Email: ${inputVal.Name} & Message: ${inputVal.message}`);

    let arr = Object.keys(inputVal).map((key) => {
      return { key: key, value: inputVal[key] };
    });

    setSocialMe(arr);
    event.preventDefault();
    setCreateSocialMediaLink(false);
    setSocialMediaLink(true);
  };

  function handleRemove(id) {
    const newList = socialMe.filter((item) => item.key !== id);

    setSocialMe(newList);
  }

  const handleSocialMediaadd = () => {
    //   if(localStorage.getItem("web_social_media") != undefined && localStorage.getItem("web_social_media") != null && localStorage.getItem("web_social_media") != "null" && localStorage.getItem("web_social_media") != ""){
    //     //web_social_media = JSON.parse(localStorage.getItem("web_social_media"));
    //     setSocialMe(JSON.parse(localStorage.getItem("web_social_media")))
    // }else{
    //   setSocialMe([])
    // }

    handleSocialMediaLink(false);
    add(socialMe);
  };
  useEffect(() => {
    if (
      localStorage.getItem("web_social_media") !== undefined &&
      localStorage.getItem("web_social_media") !== null &&
      localStorage.getItem("web_social_media") !== "null" &&
      localStorage.getItem("web_social_media") !== ""
    ) {
      //web_social_media = JSON.parse(localStorage.getItem("web_social_media"));
      setSocialMe(JSON.parse(localStorage.getItem("web_social_media")));
    } else {
      setSocialMe([]);
    }
  }, [localStorage.getItem("web_social_media")]);

  return (
    <>
      <div>
        <div className="basicinformation">
          <img src={require("assets/img/socialIcon.svg").default} alt="" />
          Social Media
        </div>
        <div className="cardBox">
          <p>
            Specify any Social Media links here. These links can be different
            from social medial links in individual events.
          </p>
          <div></div>
        </div>
        <Row className="my-3 p-2">
          <Col>
            <button
              type="button"
              className="editSocialBtn"
              onClick={handleSocialMediaLink}
            >
              Edit Social
            </button>

            {socialMe?.map((d2) => (
              <div key={Math.random()}>
                {d2.key === "Facebook" && d2.value !== "" ? (
                  <img src={FacebookIcon} alt="" className="socialIcon mr-3" />
                ) : (
                  ""
                )}
                {d2.key === "Instagram" && d2.value !== "" ? (
                  <img
                    src={InstagramIcon}
                    size={48}
                    alt=""
                    className="socialIcon mr-3"
                  />
                ) : (
                  ""
                )}
                {d2.key === "Twitter" && d2.value !== "" ? (
                  <img
                    src={TwitterIcon}
                    size={48}
                    alt=""
                    className="socialIcon mr-3"
                  />
                ) : (
                  ""
                )}
                {d2.key === "Tiktok" && d2.value !== "" ? (
                  <img
                    src={TiktokIcon}
                    size={48}
                    alt=""
                    className="socialIcon mr-3"
                  />
                ) : (
                  ""
                )}
                {d2.key === "Youtube" && d2.value !== "" ? (
                  <img
                    src={YoutubeIcon}
                    size={48}
                    alt=""
                    className="socialIcon mr-3"
                  />
                ) : (
                  ""
                )}
                {d2.key === "Vimeo" && d2.value !== "" ? (
                  <img
                    src={VimeoIcon}
                    size={48}
                    alt=""
                    className="socialIcon mr-3"
                  />
                ) : (
                  ""
                )}
              </div>
            ))}

            <Modal
              isOpen={socialMediaLink}
              toggle={handleSocialMediaLink}
              {...args}
              className="socialMediaLinksModal"
            >
              <ModalBody className="pb-0">
                <div className="modalHeader border-bottom mb-4 pb-3 flex-row">
                  <img
                    src={SocialMediaLinkIcon}
                    alt=""
                    className="icon mb-0 mr-3"
                  />
                  <div className="head">Social Media Links</div>
                </div>
                <Row>
                  <Col>
                    <div className="exportGuestThumWrapper mb-2">
                      <div className="date mr-2">
                        {date !== null
                          ? moment(new Date(date)).format("MMM DD")
                          : ""}
                      </div>
                      <div className="exportGuestThum">
                        <img
                          src={img !== "" && img !== null ? img : DummyImg}
                          alt=""
                          className="exportGuestThumImg"
                        />
                      </div>
                      <div className="exportGuestThumContent">
                        <div className="exportGuestthumbTitle">
                          {name !== null ? name : ""}
                        </div>
                        <div className="exportGuestthumbSubTitle">
                          {role !== null ? role : ""}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className="AddGestUser mb-3"
                        onClick={handleCreateSocialMediaLink}
                      >
                        Add <FaPlus className="icon" />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {socialMe?.map((d2) => (
                      <div key={Math.random()}>
                        {d2.key === "Facebook" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">Facebook</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={FacebookIcon}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="facebook"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Facebook: "" });
                                  setImgactive({
                                    ...imgactive,
                                    Facebookurl: "",
                                  });
                                  removeIcon(allValues.Facebook);
                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {d2.key === "Instagram" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">Instagram</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={InstagramIcon}
                                size={48}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="Instagram"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Instagram: "" });
                                  setImgactive({
                                    ...imgactive,
                                    Instagramurl: "",
                                  });
                                  removeIcon(allValues.Instagram);
                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {d2.key === "Twitter" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">Twitter</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={TwitterIcon}
                                size={48}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="Twitter"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Twitter: "" });
                                  setImgactive({
                                    ...imgactive,
                                    Twitterurl: "",
                                  });
                                  removeIcon(allValues.Twitter);

                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {d2.key === "Tiktok" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">TikTok</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={TiktokIcon}
                                size={48}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="TikTok"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Tiktok: "" });
                                  setImgactive({ ...imgactive, Tiktokurl: "" });
                                  removeIcon(allValues.Tiktok);
                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {d2.key === "Youtube" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">YouTube</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={YoutubeIcon}
                                size={48}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="youtube"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Youtube: "" });
                                  setImgactive({
                                    ...imgactive,
                                    Youtubeurl: "",
                                  });
                                  removeIcon(allValues.Youtube);
                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {d2.key === "Vimeo" && d2.value !== "" ? (
                          <div className="socialMediaLinkList">
                            <label className="form-label mb-2">Vimeo</label>
                            <div className="socialMediaLinkInput d-flex align-items-center">
                              <img
                                src={VimeoIcon}
                                size={48}
                                alt=""
                                className="socialIcon mr-3"
                              />
                              <input
                                type="text"
                                name="Vimeo"
                                value={d2.value}
                                className="form-control"
                                disabled
                              />
                              <img
                                src={CloseCircle}
                                alt=""
                                className="CloseIcon ml-3"
                                onClick={() => {
                                  setAllValues({ ...allValues, Vimeo: "" });
                                  setImgactive({ ...imgactive, Vimeourl: "" });
                                  removeIcon(allValues.Vimeo);
                                  handleRemove(d2.key);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="pt-3 d-flex align-center justify-space-between">
                <a
                  type="button"
                  className="flex-grow-1 pinkBtn btn btn-primary mx-w"
                  onClick={handleSocialMediaadd}
                >
                  Save Social Media Links
                </a>
                <a
                  className="btn btn-default cancelBtn  flex-grow-1 mx-w"
                  onClick={() => {
                    handleSocialMediaLink(false);
                    // setInputValue({});
                    // setAllValues({});
                    // setSocialMe();
                  }}
                >
                  Cancel
                </a>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={createSocialMediaLink}
              toggle={handleCreateSocialMediaLink}
              {...args}
              className="socialMediaLinksModal"
            >
              <form className="form-center">
                <ModalBody className="pb-0">
                  <div className="modalHeader border-bottom mb-4 pb-3 flex-row">
                    <img
                      src={SocialMediaLinkIcon}
                      alt=""
                      className="icon mb-0 mr-3"
                    />
                    <div className="head">Create New Social Media LInk</div>
                  </div>
                  <Row>
                    <Col>
                      <div>
                        <div className="mb-4 chooseSocailHead">
                          Choose a social network:
                        </div>
                        <div className="d-flex align-items-center justify-content-center mb-5">
                          <img
                            src={FacebookIcon}
                            alt={"Facebookurl"}
                            id={"Facebook"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                          <img
                            src={InstagramIcon}
                            alt={"Instagramurl"}
                            id={"Instagram"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                          <img
                            src={TwitterIcon}
                            alt={"Twitterurl"}
                            id={"Twitter"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                          <img
                            src={TiktokIcon}
                            alt={"Tiktokurl"}
                            id={"Tiktok"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                          <img
                            src={YoutubeIcon}
                            alt={"Youtubeurl"}
                            id={"Youtube"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                          <img
                            src={VimeoIcon}
                            alt={"Vimeourl"}
                            id={"Vimeo"}
                            className=" mx-3 pointer"
                            onClick={iconhandleClick}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Facebook === "Facebook"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Facebook
                            ? allValues.Facebook
                            : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Facebookurl ? (
                              <img
                                src={imgactive.Facebookurl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Facebook}
                              name={allValues.Facebook}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Facebook: "" });
                              setImgactive({ ...imgactive, Facebookurl: "" });
                              removeIcon(allValues.Facebook);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Instagram === "Instagram"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Instagram
                            ? allValues.Instagram
                            : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Instagramurl ? (
                              <img
                                src={imgactive.Instagramurl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Instagram}
                              name={allValues.Instagram}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Instagram: "" });
                              setImgactive({ ...imgactive, Instagramurl: "" });
                              removeIcon(allValues.Instagram);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Twitter === "Twitter"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Twitter ? allValues.Twitter : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Twitterurl ? (
                              <img
                                src={imgactive.Twitterurl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Twitter}
                              name={allValues.Twitter}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Twitter: "" });
                              setImgactive({ ...imgactive, Twitterurl: "" });
                              removeIcon(allValues.Twitter);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Tiktok === "Tiktok"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Tiktok ? allValues.Tiktok : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Tiktokurl ? (
                              <img
                                src={imgactive.Tiktokurl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Tiktok}
                              name={allValues.Tiktok}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Tiktok: "" });
                              setImgactive({ ...imgactive, Tiktokurl: "" });
                              removeIcon(allValues.Tiktok);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Youtube === "Youtube"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Youtube ? allValues.Youtube : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Youtubeurl ? (
                              <img
                                src={imgactive.Youtubeurl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Youtube}
                              name={allValues.Youtube}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Youtube: "" });
                              setImgactive({ ...imgactive, Youtubeurl: "" });
                              removeIcon(allValues.Youtube);
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="socialMediaLinkList"
                        style={
                          allValues.Vimeo === "Vimeo"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label className="form-label mb-2">
                          {allValues.Vimeo ? allValues.Vimeo : "No Network"}
                        </label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <span className="socialIcon mr-3">
                            {imgactive.Vimeourl ? (
                              <img
                                src={imgactive.Vimeourl}
                                style={{ width: "51px" }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="empty-form-control">
                            <input
                              type="url"
                              className="form-control"
                              value={inputVal?.Vimeo}
                              name={allValues.Vimeo}
                              onChange={handleChange}
                            />
                          </span>
                          <img
                            src={CloseCircle}
                            alt=""
                            className="CloseIcon ml-3"
                            onClick={() => {
                              setAllValues({ ...allValues, Vimeo: "" });
                              setImgactive({ ...imgactive, Vimeourl: "" });
                              removeIcon(allValues.Vimeo);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="pt-3 d-flex align-center justify-space-between">
                  <a
                    className="flex-grow-1  btn btn-primary mx-w"
                    onClick={handleSubmit}
                  >
                    Save Social Network
                  </a>
                  <a
                    className="btn btn-default cancelBtn  flex-grow-1 mx-w"
                    onClick={handleCreateSocialMediaLinkClose}
                  >
                    Cancel
                  </a>
                </ModalFooter>
              </form>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default WebSocialMedia;
