import React from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { informationService } from "../../_services/masters/information.service";
import { toast } from "react-toastify";
import { clearuserData } from "../../store/actions/index";
import { TailSpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
const DeleteaccountOwner = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [resetpopus, setResetpopus] = React.useState(false);
  const [restoreaccount, setRestoreaccount] = React.useState(false);
  const [restorecontinue, setRestorecontinue] = React.useState(false);

  let history = useHistory();
  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };

  function closeModal(e) {
    setIsLoading(false);
    props.closeModal();
    e.stopPropagation();
  }
  const exitOurStoryzContinue = () => {
    window.location.reload(false);
  }
  const exitOurStoryz = () => {

    dispatch(clearuserData());
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userdata");
    history.push("/");
  };
  const ContinueAccountRestore = () => {

    const id = props.id;

    informationService.ContinueAccount(id).then(
      (res) => {
        if (res.status === "success") {
          setRestorecontinue(true)
          // toast.success(res.message);
        } else {
          //console.log('Something went wrong !!');

        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  const deleteStory = () => {
    const id = props.id;
    // setIsLoading(false);

    setIsLoading(false);
    setResetpopus(true);

    informationService.softDelete(id).then(
      (res) => {
        if (res.status === "success") {
          setIsLoading(false);
          setResetpopus(true);
          toast.success(res.message);
        } else {
          //console.log('Something went wrong !!');

        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };
  return (
    <>
      {resetpopus === false ? (
        <>
          <div
            className="modal storyzdelet eventDeleteModal accountDeleteModal"
            style={divStyle}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="head border-bottom">
                <img
                  alt="Organization Name"
                  src={require("assets/img/Icon-Text-in-Circle.svg").default}
                  className="icon"
                />
                <h2>Dialog question</h2>
              </div>
              <h4>Confirm Organization Account Deletion</h4>
              <div className="popdataWrapper mx-0">
                <div className="popdata popdataOrg">
                  <div className="podImg">
                    <img className="" alt="Organization Name" src={props.Img} />
                  </div>
                  <div>
                    <div className="title">{props.name}</div>
                    <div className="subTitle">{props.accountType}</div>
                  </div>
                </div>
              </div>

              <p>
                This operation will delete everything your organization has
                saved to OurStoryz. This can only be done by the Account Owner.
                This will delete all events, teams, clients, and vendors from
                your system, and remove your login information.
              </p>
              <p>Make absolutely sure you want to do this!</p>
              <p>
                Your Event and Guest information will be stored for 30 days, and
                can be retrieved in spreadsheet format by contacting
                support@ourstoryz.com.
              </p>

              <div
                className="modal-footer"
                style={
                  isLoading === true
                    ? { pointerEvents: "none", opacity: "0.4" }
                    : {}
                }
              >
                <Button
                  className="pinkBtn flex-grow-1"
                  type="button"
                  onClick={deleteStory}
                >
                  Delete Entire Organization
                </Button>

                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </div>
              {isLoading === true ? (
                <>
                  <div className="toloader">
                    {" "}
                    <TailSpin
                      className="loader"
                      color="#00BFFF"
                      height={120}
                      width={120}
                      style={{ margin: "auto" }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {restorecontinue === true ? (
            <>
              <div
                className="modal storyzdelet deleteLoginModal"
                style={divStyle}
              >
                <div
                  className="modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="textimgs">
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/circle-saved-chat-icon.svg").default
                      }
                    />
                    <h2>Your Account has been Restored</h2>
                    <div className="border123"></div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Welcome back! All your organization, project and event and
                      billing information has been restored.
                    </p>
                  </div>
                  <div className="modal-footer px-0 pb-0">
                    <Button
                      className="btn blueBtn mx-auto"
                      type="button"
                      onClick={exitOurStoryzContinue}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="modal storyzdelet deleteLoginModal"
                style={divStyle}
              >
                <div
                  className="modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="textimgs">
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/circle-saved-chat-icon.svg").default
                      }
                    />
                    <h2>Organization Account Deleted</h2>
                    <div className="border123"></div>
                  </div>
                  <div className="mt-3">
                    <p>Your account has been deleted.</p>

                    <p>
                      {" "}
                      Your Event and Guest information will be stored for 30
                      days, you will receive instructions to download your guest
                      and event data in spreadsheet format. For help, contact
                      support@ourstoryz.com .
                    </p>
                  </div>
                  <div className="modal-footer px-0 pb-0">
                    <Button
                      className="btn cancelBtn"
                      type="button"
                      onClick={() => setRestoreaccount(true)}
                    >
                      Restore Account
                    </Button>
                    <Button
                      className="btn pinkBtn flex-grow-1"
                      type="button"
                      onClick={exitOurStoryz}
                    >
                      Exit OurStoryz
                    </Button>
                  </div>
                </div>
              </div>

              {restoreaccount === true ? (
                <>
                  <div
                    className="modal storyzdelet deleteLoginModal"
                    style={divStyle}
                  >
                    <div
                      className="modal-content"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="textimgs">
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/Icon-Text-in-Circle.svg")
                              .default
                          }
                        />
                        <h2>Restore Your Account</h2>
                        <div className="border123"></div>
                      </div>
                      <div className="mt-3">
                        <p>
                          This action will restore your organization’s account,
                          along with your last payment and billing information.
                        </p>
                      </div>
                      <div className="modal-footer px-0 pb-0">
                        <Button
                          className="btn cancelBtn"
                          type="button"
                          onClick={() => setRestoreaccount(false)}
                        >
                          Cancel Restore
                        </Button>
                        <Button
                          className="btn pinkBtn flex-grow-1"
                          type="button"
                          onClick={ContinueAccountRestore}
                        >

                          Continue Account Restore
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DeleteaccountOwner;
