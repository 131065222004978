import { USER_INFO, CLEAR_USER_INFO } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    profiledetails: null,
};
function profileReducers(state = initialState, action) {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                isConfirmatiom: true,
                profiledetails: action?.payload,
            };
        case CLEAR_USER_INFO:
            // console.log(action.product_Id);
            // const newPeople = state.cart.filter(item => item.id !== action.product_Id);
            //console.log('remove');
            return {
                isConfirmatiom: false,
                profiledetails: {},
            };

        default:
            return state;
    }
}
export default profileReducers;
