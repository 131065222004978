import React from "react";
import { Button } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { informationService } from "../../_services/masters/information.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
const Storyzdelete = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };

  function closeModal(e) {
    e.stopPropagation();
    setIsLoading(false);
    props.closeModal();
  }

  const deleteStory = () => {
    const storyzId = props.id;
    informationService.storyDelete(storyzId).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message);

          history.push("/admin/organization");
        } else {
          //console.log('Something went wrong !!');
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    informationService.projectStory().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.STORY_LIST, payload: res.data });
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  return (
    <>
      {isLoading === false ? (
        <div
          className="modal storyzdelet eventDeleteModal storyzDeleteModal"
          onClick={closeModal}
          style={divStyle}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="head border-bottom">
              <img
                alt="Organization Name"
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                className="icon"
              />
              <h2>Permanently Delete this Storyz?</h2>
            </div>

            <div className="popdata">
              <div className="popdate">
                {props.event_date === "NA"
                  ? "No Date"
                  : moment(props.event_date).format("MMM Do")}
              </div>
              <div className="popdata popdataStoryz">
                <div className="podImg">
                  <img className="" alt="Organization Name" src={props.simag} />
                </div>
              </div>
              <div>
                <div className="title">{props.name}</div>
                <div className="subTitle">{props.event_count} Events</div>
              </div>
            </div>

            <div className="mt-3">
              <p>Confirm that you want to delete this Storyz permanently.</p>

              <p>
                All information for events and guest lists will be lost, and not
                recoverable.
              </p>

              <p>Make sure you want to do this.</p>
            </div>
            <div className="modal-footer">
              <Button
                className="pinkBtn flex-grow-1"
                type="submit"
                onClick={deleteStory}
              >
                Delete Storyz
              </Button>
              <Button className="cancelBtn" type="submit" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Storyzdelete;
