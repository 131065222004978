import React, { Component } from "react";
import Generator from "./Generator";
import TimeLine from "react-gantt-timeline";
import Allheader from "../../components/Headers/Allheader";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { informationService } from "../../_services/masters/information.service";
import DummyImg from "../../assets/img/dummyImg.svg";

const mL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let urlArr = window.location.href.split("/");
//let storyz_id = urlArr[urlArr.length - 1];
//let storyz_id = this.props.match.params.sid

class Timelines extends Component {
  constructor(props) {
    super(props);
    var result = [];
    this.state = {
      itemheight: 80,
      data: result,
      selectedItem: null,
      timelineMode: "month",
      links: [],
      storyz_id: this.props.match.params.sid,
      nonEditableName: true,
      setstorydata: {},
    };
  }


  componentDidMount() {

    const data = {
      story_id: this.props.match.params.sid
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          this.setState({ setstorydata: res.data[0] });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    informationService.timelineList(this.props.match.params.sid).then(
      (res) => {
        const timelineData = JSON.parse(JSON.stringify(res.data));
        const newData = timelineData.filter(
          (timelineData) =>
            timelineData.event_start_date !== null &&
            timelineData.event_start_date !== null
        );

        let timeLineArr = [];
        for (let index = 0; index < newData.length; index++) {
          let finalDate = "";
          let id = newData[index].id;
          let img_url = newData[index].cover_image;
          let event_tittle = newData[index].event_name;
          let start = newData[index].event_start_date;
          let end = newData[index].event_end_date;

          let startDate = new Date(String(start));
          let endDate = new Date(String(end));

          let S_day = startDate.getDate();
          let S_month = startDate.getMonth(); // (January gives 0)
          let S_Year = startDate.getFullYear();
          let startDate_month = mL[S_month];
          //let finalSt = `${S_day}, ${st_month} ${S_Year}`;

          let E_day = endDate.getDate();
          let E_month = endDate.getMonth(); // (January gives 0)
          let E_Year = endDate.getFullYear();
          let endDate_month = mL[E_month];
          //let finalEt = `${S_day}, ${st_month} ${S_Year}`;
          if (S_Year === 1970 && E_Year !== 1970) {
            finalDate =
              endDate_month + " " + E_day + " - " + E_day + "," + " " + E_Year;
          } else if (E_Year === 1970 && S_Year !== 1970) {
            finalDate =
              startDate_month +
              " " +
              S_day +
              " - " +
              S_day +
              "," +
              " " +
              S_Year;
          } else {
            if (startDate_month === endDate_month && S_Year === E_Year) {
              finalDate =
                startDate_month +
                " " +
                S_day +
                " - " +
                E_day +
                "," +
                " " +
                E_Year;
            } else if (startDate_month !== endDate_month && S_Year === E_Year) {
              finalDate =
                startDate_month +
                " " +
                S_day +
                " - " +
                endDate_month +
                " " +
                E_day +
                "," +
                " " +
                E_Year;
            } else {
              finalDate =
                startDate_month +
                " " +
                S_day +
                "," +
                " " +
                S_Year +
                " - " +
                endDate_month +
                " " +
                E_day +
                "," +
                " " +
                E_Year;
            }
          }
          let timeLineObje = {
            id: id,
            name: (
              <Link to={`/admin/event-dashboard/${this.state.storyz_id}/${id}`}>
                <div className="eventCard">
                  <img
                    src={img_url ? img_url : DummyImg}
                    alt="img_url"
                    width="96"
                    height="86"
                    className="img objectFitCover"
                  />
                  <div className="eventCardText">
                    <div>{event_tittle}</div>
                    <div className="subtitle">
                      <b>{finalDate}</b>
                    </div>
                  </div>
                </div>
              </Link>
            ),
            start: String(start),
            end: String(end),
            color: "#43eb34",
          };
          timeLineArr.push(timeLineObje);
          //delete res.data[index].event_tittle;
        }

        this.setState({ data: timeLineArr });
        //return res.data;
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    //location.reload();
  }

  handleDayWidth = (e) => {
    //this.setState({ daysWidth: parseInt(e.target.value) });
  };

  handleItemHeight = (e) => {
    //this.setState({ itemheight: parseInt(e.target.value) });
  };

  onHorizonChange = (start, end) => {
    // let result2 = this.data.filter((item) => {
    //   return (item.start < start && item.end > end) || (item.start > start && item.start < end) || (item.end > start && item.end < end);
    // });

    // this.setState({ data: result2 });
  };

  // onSelectItem = (item) => {
  //     console.log(`Select Item ${item}`);
  //     this.setState({ selectedItem: item });
  // };

  // onUpdateTask = (item, props) => {
  //     item.start = props.start;
  //     item.end = props.end;
  //     this.setState({ data: [...this.state.data] });
  //     console.log(`Update Item ${item}`);
  // };

  onCreateLink = (item) => {
    let newLink = Generator.createLink(item.start, item.end);
    this.setState({ links: [...this.state.links, newLink] });

  };

  getbuttonStyle(value) {
    //return this.state.timelineMode === value ? { backgroundColor: 'grey', boder: 'solid 1px #223344' } : {};
  }

  modeChange = (value) => {
    this.setState({ timelineMode: value });
  };

  // genID() {
  //     function S4() {
  //         return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  //     }
  //     return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
  // }

  // getRandomDate() {
  //     let result2 = new Date();
  //     result2.setDate(result2.getDate() + Math.random() * 10);
  //     return result2;
  // }

  // getRandomColor() {
  //     var letters = '0123456789ABCDEF';
  //     var color = '#';
  //     for (var i = 0; i < 6; i++) {
  //         color += letters[Math.floor(Math.random() * 16)];
  //     }
  //     return color;
  // }

  // addTask = () => {
  //     let newTask = {
  //         id: this.state.data.length + 1,
  //         start: new Date(),
  //         end: this.getRandomDate(),
  //         name: 'New Task',
  //         color: this.getRandomColor()
  //     };
  //     this.setState({ data: [newTask, ...this.state.data] });
  // };

  // delete = () => {

  //     if (this.state.selectedItem) {
  //         let index = this.state.links.indexOf(this.state.selectedItem);
  //         if (index > -1) {
  //             this.state.links.splice(index, 1);
  //             this.setState({ links: [...this.state.links] });
  //         }
  //         index = this.state.data.indexOf(this.state.selectedItem);
  //         if (index > -1) {
  //             this.state.data.splice(index, 1);
  //             this.setState({ data: [...this.state.data] });
  //         }
  //     }
  // };

  render() {
    console.log();
    return (
      <>
        <Allheader
          title={
            this.state.setstorydata?.storyz_name
              ? this.state.setstorydata?.storyz_name
              : "Event Timeline"
          }
          imgScr={
            this.state.setstorydata?.profile_photo_Url
              ? this.state.setstorydata?.profile_photo_Url
              : DummyImg
          }
          arw
        />
        <div className="organizerWrapperMain">
          <div className="card organizerCardWrapper">
            <div className="header header-dark pb-6 content__title content__title--calendar">
              {/* <Container fluid className="ptop"> */}
              <div className="headingWrapper borderBottom">
                <h4 className="heading-1">Event Timeline</h4>
                <div className="ml-auto">
                  <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                    />
                  </span>
                  <ReactTooltip />
                </div>
              </div>
              {/* <div
                className="cardheader2"
                style={{
                  display: "flex",
                  justifContent: "space-between",
                  borderBottom: "1px solid #E7E7E7",
                  justifyContent: "space-between",
                }}
              >
                <div className="cardheader" style={{ marginBottom: "13px" }}>
                  <h3 className="mb-0" style={{ fontSize: "18px" }}>
                    Event Timeline
                  </h3>
                </div>
                <div>
                  <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/tip.svg").default}
                      style={{ marginRight: "10px" }}
                    />
                  </span>
                  <ReactTooltip />
                </div>
              </div> */}

              <div className="organizationPara p-4 mb-4">
                The Timeline lets you see the individual events in your larger
                Storyz. Drag on the ovals to change the start and end date of an
                event. Select the event to edit in detail. Select a todo at the
                bottom of the timeline. Use the slider to change the dates you
                are looking at.
              </div>
              <div className="app-container">
                <div className="nav-container">
                  <div className="mode-container">
                    <div
                      className="mode-container-item mode-container-item-left"
                      onClick={(e) => this.modeChange("day")}
                      style={this.getbuttonStyle("day")}
                    >
                      Day
                    </div>
                    <div
                      className="mode-container-item"
                      onClick={(e) => this.modeChange("week")}
                      style={this.getbuttonStyle("week")}
                    >
                      Week
                    </div>
                    <div
                      className="mode-container-item"
                      onClick={(e) => this.modeChange("month")}
                      style={this.getbuttonStyle("month")}
                    >
                      Month
                    </div>
                    <div
                      className="mode-container-item mode-container-item-right"
                      onClick={(e) => this.modeChange("year")}
                      style={this.getbuttonStyle("year")}
                    >
                      Year
                    </div>
                    <div
                      className="mode-container-item mode-container-item-editable-toggle"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        this.setState({
                          nonEditableName: !this.state.nonEditableName,
                        });
                      }}
                    >
                      {this.state.nonEditableName ? "Enable" : "Disable"} name
                      edition
                    </div>
                  </div>
                </div>
                <div className="time-line-container">
                  <TimeLine
                    // config={config}
                    data={this.state.data}
                    links={this.state.links}
                    onHorizonChange={this.onHorizonChange}
                    onSelectItem={this.onSelectItem}
                    onUpdateTask={this.onUpdateTask}
                    onCreateLink={this.onCreateLink}
                    mode={this.state.timelineMode}
                    itemheight={this.state.itemheight}
                    selectedItem={this.state.selectedItem}
                    nonEditableName={this.state.nonEditableName}
                  />
                </div>
              </div>
              {/* </Container> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Timelines;
