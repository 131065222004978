import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import classnames from "classnames";
import IconImg from "../../../assets/img/adminLogo.svg";
import Vector1 from "../../../assets/img/icons/two-factor-icon.svg";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { userService } from "../../../_services/login/user.service";
function NewGuestCreateLogin() {
  const search = useLocation().search;

  const [datain, setDatain] = useState([]);
  const token = new URLSearchParams(search).get("token");
  let history = useHistory();
  const {
    watch,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {

    let data = {
      token: token,
    };

    getData(data);
  }, [token]);
  const onSubmit = (data) => {
    sessionStorage.setItem("userAdmin", JSON.stringify(data));
    if (datain.isAlreadyExists === 1) {
      history.push(`/term-and-conditions`);
    } else {
      history.push(`/new-guest-create-login-form`);
    }
  };
  function getData(data) {
    userService.getGuestdata(data).then(
      (res) => {
        if (res.status === "success") {
          setDatain(res.data);
          setValue("email", res.data.invite_email);
          if (datain.isAlreadyExists === 1) {
            setValue("first_name", res.data.first_name);
            setValue("last_name", res.data.last_name);
          }
          //history.push(`/admin/storyz-todos`);
        } else {
          console.log("Invalid Credetials !!", "Login");
        }
      },
      (error) => {
        console.log("Invalid Credetials !!", "Login");
      }
    );
  }

  const [passwordType, setPasswordType] = React.useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <Container fluid className="GuestcreateLoginHeaderWrappper">
        <Link to="/">
          <i className="ni ni-bold-left arrowIcon"></i>
        </Link>
        <img src={IconImg} alt="" />
        {datain.isAlreadyExists === 1
          ? "Create Your Admin Login"
          : "Set Up Your Account"}
      </Container>
      <div className="twofactorauth">
        <Container className="GuestcreateLoginWrappper" fluid>
          <div className="borderLine"></div>
          <Card className="GuestcreateLoginInner">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="headingWrapper borderBottom">
                <h4 className="heading-1">
                  Provide Information for Your New Admin Account
                </h4>
              </div>
              {datain.isAlreadyExists === 1 ? (
                <div className="organizationPara p-4">
                  You have been invited to join an OurStoryz Project as an
                  Admin!
                </div>
              ) : (
                <div className="organizationPara p-4">
                  You have been invited to join an OurStoryz Project as an
                  Admin!
                </div>
              )}

              <div>
                {datain.isAlreadyExists === 1 ? (
                  ""
                ) : (
                  <div className="organizationPara">
                    You have been invited become an OurStoryz Admin!
                  </div>
                )}

                <div className="existingGuestLoginBox">
                  <div className="title">Storyz Name: Storyz NameXXX</div>
                  <div className="title">Your Role: Admin</div>

                  {datain.isAlreadyExists === 1 ? (
                    <div className="form-group">
                      <label htmlFor="exampleEmail" className="">
                        You are already a guest for an event in this project.
                      </label>
                      <div className="inlineFormBox">
                        <div className="avatarWraper">
                          <img
                            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                            alt="Avatar"
                            className="avatar"
                          />
                        </div>
                        <input
                          name="first_name"
                          placeholder="first name"
                          type="text"
                          className="form-control"
                          {...register("first_name", {
                            required: "first namee is required",
                          })}
                          defaultValue={datain.first_name}
                          autocomplete="off"
                        />

                        <input
                          name="last_name"
                          placeholder="last name"
                          type="text"
                          className="form-control"
                          {...register("last_name", {
                            required: "last name is required",
                          })}
                          autocomplete="off"
                          defaultValue={datain.last_name}
                        />
                      </div>
                      {errors.first_name && (
                        <small style={{ color: "#ff0000" }}>
                          first name is required
                        </small>
                      )}
                      {errors.last_name && (
                        <small style={{ color: "#ff0000" }}>
                          last name is required
                        </small>
                      )}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              <div className="headingWrapper borderBottom">
                <h4 className="heading-2 px-4">
                  To become an Admin, you will need to provide information to
                  log into the OurStoryz Cloud. Complete this information and
                  choose a login option.
                </h4>
              </div>
              <div className="existingGuestLoginWrapper">
                <div className="existingGuestLoginLeft">
                  <div className="basicinformation">
                    <img src={Vector1} alt="" />
                    Set Local Password
                  </div>

                  <div className="cardBox">
                    <Row>
                      <Col lg={10} md={12}>
                        <input
                          type="hidden"
                          name="email"
                          {...register("email")}
                        />
                        <FormGroup className="">
                          <Label htmlFor="exampleEmail">Password:</Label>
                          <InputGroup className="localPassBox">
                            <input
                              className="form-control"
                              name="password"
                              placeholder="type in password..."
                              type={passwordType}
                              {...register("password", {
                                required: "Password is required",
                              })}
                              autocomplete="off"
                            />
                            <InputGroupText onClick={togglePassword}>
                              <img
                                src={
                                  require("assets/img/edit-field-icons.svg")
                                    .default
                                }
                                alt=""
                              />
                            </InputGroupText>
                          </InputGroup>
                        </FormGroup>
                        {errors.password && (
                          <small style={{ color: "#ff0000" }}>
                            Password is required
                          </small>
                        )}
                        <FormGroup className="mt-3">
                          <Label htmlFor="exampleEmail">Retype Password.</Label>
                          <InputGroup>
                            <input
                              className="form-control"
                              name="cpassword"
                              placeholder="type in password..."
                              type="password"
                              // {...register("cpassword", {
                              //   required: "Confirm Password is required",
                              //   validate: (value) =>
                              //     value === getValues("password")
                              // })}
                              {...register("cpassword", {
                                required: "Please Retype Your Password",
                                validate: (match) => {
                                  const password = getValues("password");
                                  return (
                                    match === password || "Retype should match!"
                                  );
                                },
                              })}
                              autocomplete="off"
                            />
                          </InputGroup>
                        </FormGroup>

                        {errors.cpassword?.message && (
                          <small style={{ color: "#ff0000" }}>
                            {errors.cpassword?.message}
                          </small>
                        )}
                        <input
                          type="hidden"
                          {...register("token")}
                          name="token"
                          value={token}
                        />
                        <div className="custom-control form-check form-check-inline formBox mt-3">
                          <label className="container3 form-check-label">
                            Remember this login
                            <input type="checkbox" name="" />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="d-flex">
                          <button className="greenActionBtn ml-auto">
                            Set Password
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="orBox">
                  <div className="orBoxtext">OR</div>
                </div>
                <div className="existingGuestLoginRight">
                  <div className="basicinformation">
                    <img
                      alt="password"
                      src={require("assets/img/socialIcon.svg").default}
                    />
                    Use a Social Login
                  </div>
                  <div className="cardBox">
                    <div className="guestLoginWrapper">
                      <a className="loginWithApple">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/common/Vector.svg")
                              .default
                          }
                        />
                        Login with Apple
                      </a>
                      <a className="loginWithFacebook">
                        <img
                          alt="..."
                          src={require("assets/img/facebook-white.svg").default}
                        />
                        Login with Facebook
                      </a>
                      <a className="loginWithGoogle">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/googleplus.svg").default
                          }
                        />
                        Login with Google
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Card>
          {/* <div className="GuestcreateLoginInner">
                <h1></h1>
              </div> */}
          {/* <Card>
                <CardHeader>
                  <h3
                    className="mb-0"
                    style={{
                      borderBottom: "1px solid #E7E7E7",
                      paddingBottom: "13px",
                    }}
                  >
                    Password Recovery
                  </h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <p className="basicinformation mt-2 ">
                        <img
                          src={Vector1}
                          style={{ marginRight: "11px" }}
                          alt=""
                        />
                        Password Recovery
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">
                          New Password:
                        </label>
                        <InputGroup className={classnames("input-group-merge")}>
                          <input
                            placeholder="New Password"
                            type="password"
                            name="password"
                            className="form-control"
                            required
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <img
                                alt="password"
                                src={
                                  require("assets/img/icons/common/password.svg")
                                    .default
                                }
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="mt-2 mb-2">
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          Retype Password.
                        </label>
                        <input
                          id="example3cols1Input"
                          placeholder="Enter Retype Password."
                          type="password"
                          name="cpassword"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                      <Button className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow">
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
        </Container>
      </div>
      <ToastContainer />
      <AuthFooter />
    </>
  );
}
export default NewGuestCreateLogin;
