import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import { eventsService } from "_services/masters/events.service";
import { useSelector, useDispatch } from "react-redux";
import DummyImg from "../../../assets/img/dummyImg.svg";
import { userGuestService } from "_services/masters/userGuest.service";
import { Link, useHistory } from "react-router-dom";
import * as actionTypes from "../../../store/actionTypes";
import { commonData, guestsLists } from "store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function DeleteGuestSingle({ show, onHide, eventLists, single, urlRedirect }) {
  const dispatch = useDispatch();
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  let history = useHistory();

  const showAlert = () => {
    var data = {
      event_id: common?.eid,
      guest_id: single?.id,
    };
    eventsService.deleteGuestsingle(data).then(
      (res) => {
        if (res.status === "success") {
          onHide();
          allDataget();
          toast.success("Delete Guest successfully");
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  function allDataget() {
    userGuestService.userGuestList(common?.eid).then((res) => {
      if (res.status === "success") {
        dispatch(guestsLists(res.data));
        if (urlRedirect === true) {
          sessionStorage.removeItem("detailsOfUser");
          history.push(
            `/admin/event-user-guest-list/${common?.sid}/${common?.eid}?type=guests`
          );
        }
      } else {
      }
    });
  }

  return (
    <>
      <Modal
        isOpen={show}
        toggle={onHide}
        // {...args}
        className="resetGeospaceModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Remove This Guest From the Guest List?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="geospaceThumWrapper">
                <div className="geospaceThum">
                  <img
                    src={single?.imageUrl ? single?.imageUrl : DummyImg}
                    alt=""
                    className="geospaceThumImg"
                  />
                </div>
                <div className="geospaceThumContent">
                  <div className="geothumbTitle">{single?.first_name}</div>
                  <div className="geothumbSubTitle">Role in Event</div>
                </div>
              </div>
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img
                    src={
                      common.singleEvent?.cover_image
                        ? common.singleEvent?.cover_image
                        : DummyImg
                    }
                    alt=""
                    className="eventThumImg"
                  />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">
                    {common.singleEvent?.event_name}
                  </div>
                  <div className="eventthumbSubTitle">
                    {common.singleEvent?.location}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                Confirm you want to remove this guest from the Event guest list.{" "}
              </p>
              <p>This will NOT remove the guest datga2.</p>
              <p>
                Instead it will just remove the guest from the invite list for
                this event.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn"
            onClick={showAlert}
          >
            Remove Guest from List
          </Button>
          <Button className="btn-default cancelBtn" onClick={() => onHide()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default DeleteGuestSingle;
