import * as React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import SocialGoogleLogin from "../../../components/Sociallogin/SocialGoogleLogin";
import SocialFacebookLogin from "../../../components/Sociallogin/SocialFacebookLogin";

import { useForm } from "react-hook-form";
import { userService } from "../../../_services/login/user.service";
import { informationService } from "../../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { userAdminData } from "store/actions";
import { FaChevronLeft } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthHeader from "components/Headers/AuthHeader.js";
import * as actionTypes from "../../../store/actionTypes";
import { clearstoryList, clearuserData } from "../../../store/actions/index";

import { useHistory } from "react-router-dom";
//console.log( "navigator.userAgentData.platform", navigator.userAgentData.platform);
function Verification() {
  const currentUrlArr = window.location.href.split('?');
  let email = '';
  if(currentUrlArr.length > 1){
       let emailData = atob( currentUrlArr[1]);
       //console.log("emailData", emailData);
       let queryDataArr = emailData.split("=");
       email = queryDataArr[1];
    }
   //console.log('email:', email);
   //email
  const history = useHistory();
  const dispatch = useDispatch();

  const contact = useSelector((state) => {
    return state.profile;
  });

  const [cancelModal, setCancelModal] = React.useState(false);
  const CancelModalHandler = () => {
    setCancelModal(!cancelModal);
  };

  const admindata = useSelector((state) => {
    return state.userAdmin.listData;
  });

  React.useEffect(() => {
    dispatch(clearstoryList());

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === "" || userDetails === null) {
      dispatch(clearuserData());
    }

    // if (contact.isConfirmatiom === true) {
    //   history.push('/admin/account-information');
    // }
  }, []);

  const [emailId, setEmail] = React.useState(email);
  const [emailIdErr, setEmailIdErr] = React.useState(
    email == "" ? "Signup email id not found" : emailId
  );
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [formemail, setFormemail] = React.useState("");
  const [formpassword, setFormpassword] = React.useState("");
  const [formoption, setFormoption] = React.useState();

  const [local, setLocal] = React.useState(false);
  const [codeErr, setCodeErr] = React.useState("");
  const [Scode, setCode] = React.useState("");
  const [passval, setPassval] = React.useState("");
  const [repassErr, setRePassErr] = React.useState("");
  const [repassval, setRePassval] = React.useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  // React.useEffect(() => {
  //   if (localStorage.getItem('userDetails')) {
  //     history.push('/admin/account-information');
  //   }
  // }, [])
  const localMethod = () => {
    setLocal(false);
  };
  const localMethodBack = () => {
    setLocal(true);
  };

  const checkCode = (singup) => {
    //console.log( "code", code.length);
    setCode(singup);
    if (singup.length < 6) {
      setCodeErr("Code invalid");
    } else {
      setCodeErr("");
    }
  };

const verifyCode = () => {
    let data = {singup:Scode, email:emailId};
     userService.verifyCode(data).then(
        (res) => {
          if (res.status == "success") {
                  let userData = JSON.stringify(res.data);
                  sessionStorage.setItem("user_singup_data", userData);
                  setTimeout(() => {
                      history.push('/pay-now');
                  }, 1000);
                 
                  //console.log("res====", res.data);
                  //console.log("Done");
          }else{
                console.log("Not Done");
          }
        })
    
   
}

 return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            <Card className="bgsecondary border-0 mb-0 pb-3">
              <CardHeader className="bg-white pb-0">
                <Row>
                  <Col
                    xs="12"
                    style={{ textAlign: "right", margin: "0px" }}
                    className="mb-5"
                  >
                    <span style={{ width: "54px" }}>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "bold",
                          fontSize: "24px",
                          lineHeight: "32px",
                        }}
                      >
                        <img
                          alt="OurStoryz"
                          src={
                            require("assets/img/icons/common/Logomark.svg")
                              .default
                          }
                          style={{ padding: "5px" }}
                        />
                        OurStoryz
                      </h1>
                    </span>

                    <span
                      style={{
                        color: "#FC3158",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      {/* <Link
                        className="font-weight-bold text-warning mt-5"
                        to="/"
                      >
                        Login
                      </Link> */}
                    </span>
                  </Col>
                </Row>
              </CardHeader>

              {!local && (
                <CardBody className="bg-white pt-0">
                  <div className="loginMethod">Verify your account</div>
                  <p className="logniMethodText">
                    We Sent a verification code to 'emailid'. Enter the code to
                    continue signing up
                  </p>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <label style={{ fontSize: "14px", fontWeight: "400" }}>
                      Verification code:
                    </label>
                    <small className="error">{codeErr}</small>
                    <InputGroup
                      className={`input-group-merge input-group-alternative passInput`}
                    >
                      <input
                        className={`form-control`}
                        placeholder="type code here"
                        type="text"
                        name="code"
                        value={Scode}
                        onChange={(e) => checkCode(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <div>
                      {emailId != "" ? (
                        <Button className="my-4" onClick={verifyCode}>
                          Continue
                        </Button>
                      ) : (
                        <small className="error">{""}</small>
                      )}
                      <Button
                        className="btn-default cancelBtn ml-auto"
                        onClick={CancelModalHandler}
                      >
                        Cancel
                      </Button>
                    </div>

                    <Modal
                        className="modal-dialog-centered addListModalWrapper"
                        isOpen={cancelModal}
                        toggle={CancelModalHandler}
                      >
                        <ModalBody>
                          <div className="modal-header border-bottom">
                            <img
                              alt="..."
                              src={
                                require("assets/img/Icon-Text-in-Circle.svg")
                                  .default
                              }
                              width="75px"
                              height="75px"
                              className="icon"
                            />
                            <div className="head">
                              Do you really want to exit?
                            </div>
                          </div>

                          <p>
                            Selecting “Cancel” will close and remove any data
                            you have entered.
                          </p>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            color="primary pinkBtn"
                            className="flex-grow-1 w-100"
                          >
                            Continue with setup
                          </Button>
                          <Button
                            className="btn-default cancelBtn w-100"
                            onClick={CancelModalHandler}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
}

export default Verification;
