import React, { useState, useEffect } from "react";

import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { GuestEventWrapper } from "../UserGuestList/style";
import Select from "react-select";

import EditPencilIcon from "./../../../assets/img/editIPencilcon.svg";
import DeleteIcon from "./../../../assets/img/deleteIcon.svg";

import SortIcon from "./../../../assets/img/sortIcon.svg";
import GuestEditInviteDropdown from "components/Dropdown/GuestEditInviteDropdown";
import { CombinedGuestPool } from "../CombinedGuestPool/style";
import GuestGroupIcon from "./../../../assets/img/guest-group-icon.svg";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";

import SendInvites from "./../../../assets/img/sendInvites.jpg";
import SendInvites1 from "./../../../assets/img/sendInvites1.jpg";
import SendInvites2 from "./../../../assets/img/sendInvites2.jpg";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import Preview from "./../../../assets/img/computer.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import ThemeBg from "../../../assets/img/modalThemeBg.png";
import Thememap from "../../../assets/img/thememap.png";
import AppStore from "../../../assets/img/app-store-download.png";
import GooglePlay from "../../../assets/img/google-play-download.png";

import { useParams } from "react-router-dom";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { eventsService } from "../../../_services/masters/events.service";
import InviteThemeModal from "components/InviteThemeModal";

import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
} from "../../../CommonData";
import RSVPDeadline from "../../../assets/img/infoRedIcon.svg";
import DummyImg from "../../../assets/img/dummyImg.svg";

const MnanageInviteList = ({ args }) => {
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteGuestGroup, setDeleteGuestGroup] = useState(false);

  const [guestGroupModal, setGuestGroupModal] = useState();
  const [deleteGuestUser, setDeleteGuestUser] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [deleteData, setDeleteData] = useState();
  //const [event_img, setEventImg] = useState("");
  const params = useParams();
  const [inviteTemplate, setInviteTemplate] = useState(false);
  const [addPrefix, setAddPrefix] = useState(false);
  const [eventItems, setEventItems] = useState();
  const [userguestList, setUserguestList] = React.useState([]);
  const [guestInviteList, setGuestInviteList] = React.useState([]);
  const [inviteTitle, setInviteTitle] = useState("");
  const [inviteCoverImage, setInviteCoverImage] = useState("");
  const [inviteDescription, setInviteDescription] = useState("");
  const [invite_status, setInviteStatus] = useState(0);
  const [invite_updated_at, setInviteUpdatedAT] = useState(new Date());
  const [is_send, setisSend] = useState(0);
  const [eventname, setEventName] = useState("");
  const [eventenddate, setEventStartDate] = useState(new Date());
  const [eventstartdate, setEventStartEnd] = useState(new Date());
  const [event_location, setEventLocation] = useState("");
  const [rsvp_deadline, setRSVP_Deadline] = useState(0);
  const [isSendEmail, setisSendEmail] = useState(1);
  const [event_img, setEventImg] = useState("");
  const [rsvpDeadline, setRsvpDeadline] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleDeleteGuestGroupNew = () => {
    //console.log("params.eid===", params.eid)
    userGuestService.getEvent({ event_id: params.eid }).then(
      (res) => {
        if (res.status === "success") {
          if (params.eid != undefined) {
            setEventName(res.data?.event_name);
            setEventStartDate(res.data?.event_start);
            setEventStartEnd(res.data?.event_end);
            setEventImg(res.data?.cover_image);
            setEventLocation(res.data?.event_location);
            let ev_last_date = new Date(res.data?.rsvp_deadline);
            let today_date = new Date();

            if (today_date >= ev_last_date) {
              setRSVP_Deadline(1);
              //console.log("Event Date  1 -->  ", 1);
            } else {
              //console.log("Event Date 0  -->  ", 0);
              setRSVP_Deadline(0);
            }
            setDeleteGuestGroup(true);
          }
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const deleteAll = () => {
    userGuestService.deleteAll({ event_id: params.eid }).then((res) => {
      if (res.status === "success") {
        setDeleteGuestGroup(false);
        localStorage.setItem("update_lnvite_list", Math.random() * 100);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        datatolist();

        toast.success("Delete All Successfully");
      } else {
      }
    });
  };

  function datatolist() {
    userGuestService.guestInviteList(params.eid).then((res) => {
      if (res.status === "success") {
        setGuestInviteList(res.data);
        // console.log("set Guest Invite List ", res.data);
        setEventDetails(res.eventDetails);
      } else {
        setGuestInviteList([]);
      }
    });
  }
  useEffect(() => {
    datatolist();
    const eid = { event_id: params.eid };
    eventsService.eventDetails(eid).then(
      (res) => {
        if (res.status === "success") {
          setEventItems(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    userGuestService.userGuestList(params.eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  }, [params, localStorage.getItem("update_lnvite_list")]);
  const [openThemeModal, setOpenThemeModal] = useState(false);
  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      formatter: (value, row) => (
        <div>
          <GuestEditInviteDropdown
            guestListOption={guestListOption}
            data={row}
          />
        </div>
      ),
      sort: false,
    },
    {
      dataField: "invite",
      text: "Invite Name and Theme",
      formatter: (value, row) => (
        <div className="inviteThemeWrapper d-flex">
          <div className="leftInvite mr-4">
            <img src={SendInvites} alt="" className="" width={70} />
          </div>

          <div className="rightInvite">
            <div className="title">{row.invite_title}</div>
            <div className="subTitle">Send to {row.total_guest} Guests</div>
            <div className="subTitle">RSVPs: {row.total_rsvp}</div>
            <div className="subTitle">{row.invite_description}</div>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      dataField: "invite_status",
      text: "Invite Status",
      formatter: (value, row) => (
        // <div className="inviteStatus d-flex align-items-center ml-auto">
        <div
          className={`inviteStatus d-flex align-items-center ml-auto
          ${Number(row.invite_status) === 100
              ? Number(row.is_rsvp) === 0
                ? ""
                : "canNotSent"
              : "incomplete"
            }
          `}
        >
          {Number(row.invite_status) === 100 ? (
            Number(row.invite_status) === 100 && Number(row.is_send) === 1 ? (
              Number(row.is_rsvp) === 0 ? (
                <div className="text-right">
                  <div>
                    Send {moment(row.updated_at).format("MMMM D, YYYY")}
                  </div>
                  {moment(row.updated_at).format("h:mm:sa")} PST
                </div>
              ) : (
                "Cannot be send"
              )
            ) : // "Send" +
              //   " " +
              //   moment(row.updated_at).format("MMMM D, YYYY: h:mm:sa") +
              //   " " +
              //   "PST"
              Number(row.is_rsvp) === 0 ? (
                "Ready To Send"
              ) : (
                "Cannot be send"
              )
          ) : Number(row.is_rsvp) === 0 ? (
            "Incomplete"
          ) : (
            "Cannot be send"
          )}

          <div className="guestCircleWrapper ml-3">
            <CircularProgressbar
              value={row.invite_status}
              text={false}
              strokeWidth={20}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: "#53D769 ",
                trailColor: "#1345A5",
              })}
            />
          </div>
        </div>
      ),

      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "invite",
      order: "desc",
    },
  ];

  const resultSet = guestInviteList;

  const handleDeleteGuestGroup = (e) => {
    e.preventDefault();
    setDeleteGuestGroup(!deleteGuestGroup);
  };

  const handleDeleteGuestUser = (del_invite_id) => {
    //console.log("del_invite_id=====", del_invite_id)
    setDeleteData(del_invite_id);
    //setDeleteGuestUser(true);
    userGuestService.guestInvitedetails(del_invite_id).then(
      (res) => {
        if (res.status === "success") {
          if (del_invite_id != undefined) {
            //console.log("res.data list one", res.data);
            setInviteTitle(res.data?.invite_title);
            setInviteCoverImage(res.data?.cover_image);
            setInviteDescription(res.data?.invite_description);
            setInviteStatus(res.data?.invite_status);
            setInviteUpdatedAT(res.data?.updated_at);
            setisSend(res.data?.is_send);
            setEventName(res.data?.event_name);
            setEventStartDate(res.data?.event_start);
            setEventStartEnd(res.data?.event_end);
            setEventImg(res.data?.event_img);
            setEventLocation(res.data?.event_location);
            let ev_last_date = new Date(res.data?.rsvp_deadline);
            let today_date = new Date();
            //console.log("Event Date ev_last_date -->  ", ev_last_date);
            //console.log("Event Date today_date -->  ", today_date);
            if (today_date >= ev_last_date) {
              setRSVP_Deadline(1);
              //console.log("Event Date  1 -->  ", 1);
            } else {
              //console.log("Event Date 0  -->  ", 0);
              setRSVP_Deadline(0);
            }

            //setInviteID(params.id);
            setisSendEmail(Number(res.data?.is_send));
            setDeleteGuestUser(true);
          }
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };
  const handleInviteTemplate = (del_invite_id) => {
    //console.log("del_invite_id=====", "del_invite_id");
    userGuestService.guestInvitedetails(del_invite_id).then(
      (res) => {
        if (res.status === "success") {
          if (del_invite_id != undefined) {
            //console.log("res.data list one", res.data);
            setInviteTitle(res.data?.invite_title);
            setInviteCoverImage(res.data?.cover_image);
            setInviteDescription(res.data?.invite_description);
            setInviteTemplate(true);
          }
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const handleInviteTemplateClose = (e) => {
    setInviteTemplate(false);
  };
  const guestListOption = [
    {
      img: EditPencilIcon,
      title: "Details",
      link: `/admin/manage-invites-edit/${sid}/${eid}`,
      settype: "invites-rsvps",
    },
    {
      img: Preview,
      title: "Preview",
      handleClick: handleInviteTemplate,
    },
    {
      img: DeleteIcon,
      title: "Delete Invite",
      handleClick: handleDeleteGuestUser,
    },
  ];

  const AddPrefixModal = () => {
    setAddPrefix(!addPrefix);
  };
  const filterGuest = [{ value: "allGuest", label: "All Guest" }];

  const handelGuestGroupModal = () => {
    setGuestGroupModal(!guestGroupModal);
  };
  const [resetting, setResetting] = useState(false);
  const [statusGuestUser, setStatusGuestUser] = useState(false);
  const handleStatusOfGuestUser = () => {
    setStatusGuestUser(!statusGuestUser);
  };
  const handleReseting = () => {
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
    }, 3000);
  };
  const [resetRsvps, setResetRsvps] = useState(false);

  const handleResetRsvps = () => {
    setResetRsvps(!resetRsvps);
  };
  const percentage = 100;

  const handleDelGuestUser = () => {
    userGuestService.guestInvitedelete(deleteData).then((res) => {
      if (res.status === "success") {
        datatolist();
        //setDeleteGuestGroup(!deleteGuestGroup);
        setDeleteGuestUser(false);
        toast.success("Delete successfully");
      } else {
      }
    });
  };

  const eventUrl = `/admin/event-about/${params.sid}/${params.eid}?type=event`;
  const gotoEvent = () => {
    //eventUrl
    window.open(eventUrl, "_blank");
  };

  const handleRsvpDeadline = () => {
    setRsvpDeadline(!rsvpDeadline);
  };
  const handleRsvpDeadlineClose = () => {
    setRsvpDeadline(false);
  };

  return (
    <>
      <Modal
        isOpen={deleteGuestUser}
        toggle={handleDeleteGuestUser}
        // {...args}
        className="resetGeospaceModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Delete this Invite?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="d-flex align-items-center mb-4">
                <div className="mr-3">
                  <img alt="Organization Name" src={SendInvites} width={70} />
                </div>
                <div className="w-100">
                  <div className="fontSize16 borderBottom pb-2 mb-2">
                    {inviteTitle}
                  </div>
                  <div
                    className={`inviteStatus d-flex align-items-center justify-content-end  w-100
                                  ${Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                        ? "incomplete"
                        : ""
                      }
                                  ${Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                        ? "canNotSent"
                        : ""
                      }
                                  `}
                  >
                    {Number(invite_status) === 100 && Number(rsvp_deadline) === 0
                      ? Number(isSendEmail) === 0
                        ? "Ready To Send"
                        : "Send" +
                        " " +
                        moment(invite_updated_at).format(
                          "MMMM D, YYYY: h:mm:sa"
                        ) +
                        " " +
                        "PST"
                      : Number(invite_status) === 100 && Number(rsvp_deadline) === 1
                        ? "Cannot be send"
                        : Number(invite_status) !== 100 && Number(rsvp_deadline) === 0
                          ? "incomplete"
                          : "incomplete"}
                    <div className="guestCircleWrapper ml-3">
                      <CircularProgressbar
                        value={invite_status}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img src={event_img} alt="" className="eventThumImg" />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">{eventname}</div>
                  <div className="eventthumbSubTitle">{event_location}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                Confirm you want to remove this invite. This will clear your
                current invitation and template from the list.
              </p>
              <p>
                Deleting will NOT affect your guest list or RSVPs, nor will it
                affect any Invites that have already been sent to guests.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn"
            onClick={() => handleDelGuestUser()}
          >
            Delete Invite
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={() => setDeleteGuestUser(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={resetRsvps}
        toggle={handleResetRsvps}
        // {...args}
        className="resetGeospaceModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Remove This Guest From the Guest List?</div>
          </div>
          <Row>
            <Col className="px-4">
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img src={event_img} alt="" className="eventThumImg" />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">{eventname}</div>
                  <div className="eventthumbSubTitle">{event_location}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-4">
              <p>
                If you remove The RSVPs, the guest list won’t be changed, just
                their RSVP status.
              </p>
              <p>All your guest will need to onboard.</p>
              <p>
                Use this option ONLY when you need to reset your RSVP list for
                some reason.
              </p>
              <p className="bold redClr">This operation is NOT un-doable!</p>
              <p>In most cases, you will NOT want to do this.</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
          <Button color="primary" className="flex-grow-1 pinkBtn">
            Erase RSVPs for this Event
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={() => handleResetRsvps(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={inviteTemplate}
        toggle={handleInviteTemplateClose}
        // {...args}
        className="inviteTemplateModalWrapper"
      >
        <ModalHeader
          toggle={handleInviteTemplateClose}
          className="zIndex99 position-relative"
        ></ModalHeader>
        <ModalBody className="pb-0 zIndex99 position-relative">
          <div>
            <div className="themeTitle mb-4">{inviteTitle}</div>
            <div className="subTitle mb-4 pb-4">{inviteDescription}</div>

            {inviteCoverImage != "" ? (
              <>
                <div className="themeImg mb-4">
                  <img src={inviteCoverImage} alt="" />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="themeBox">
              <div className="themeName mb-4">{eventItems?.event_name}</div>
              <div className="themeDate mb-4">
                {moment(eventItems?.event_start_date).format(
                  "MMMM D, YYYY: h:mm:sa"
                )}
              </div>

              {deleteData?.include_guest !== 0 ? (
                <div className="themeGuestWrapper mb-4">
                  <div className="head mb-3">
                    Guests • {userguestList.length >= 4 ? 4 : userguestList.length}
                  </div>
                  {userguestList.length > 0 ? (

                    <>
                      <div className="themeGuestListWrapper d-flex">
                        {userguestList.map((arr, i) => {
                          if (i < 4) {
                            return (
                              <>
                                <div className="themeGuestList px-1" key={i}>
                                  <img
                                    src={
                                      arr.imageUrl === "" ? DummyImg : arr.imageUrl
                                    }
                                    alt=""
                                    className="themeGuestImg"
                                  />
                                  {arr.first_name}
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {deleteData?.include_organizer_contact !== 0 ||
                deleteData?.include_location !== 0 ? (
                <div className="themeBoxWrapper d-flex mb-4">
                  <div className="themeMapWrapper pt-3 mr-2">
                    <div className="title mb-1 px-3">
                      {eventItems?.name_of_geospace}
                    </div>
                    <div className="subTitle text-left px-3 border-0">
                      {eventItems?.location}
                    </div>

                    <div className="mapWrapper position-relative mt-3">
                      <Button className="directionBtn">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/themeDirection.svg").default}
                          className="mr-2"
                          width={18}
                        />
                        Directions
                      </Button>
                      <img
                        alt="Organization Name"
                        src={Thememap}
                        className="themeMapImg"
                      />
                    </div>
                  </div>

                  {userDetails != undefined ? (
                    <div className="themePorterWrapper align-items-center position-relative pt-3">
                      <img
                        alt="Organization Name"
                        src={userDetails.imageUrl}
                        className="img"
                        width={50}
                      />
                      <div className="title pb-1">{userDetails.name}</div>
                      <div className="subTitle b-4 border-0">
                        {userDetails.account_type}
                      </div>
                      <Button className="contactBtn">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/themeMail.svg").default}
                          className="mr-2"
                          width={18}
                        />
                        Contact
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {userDetails != undefined ? (
                  <div className="themePorterWrapper position-relative">
                    <div className="title pb-1 mt-7">{userDetails.name}</div>
                    <div className="subTitle b-4 border-0">Organizer</div>
                    <Button className="contactBtn">
                        <img
                          alt="Organization Name"
                          src={require("assets/img/themeMail.svg").default}
                          className="mr-2"
                          width={18}
                        />
                        Contact
                      </Button>
                  </div>
                 ) : (
                  ""
                )} */}
                </div>
              ) : (
                ""
              )}

              {deleteData?.include_web_link !== 0 ? (
                <div className="themeAppWrapper p-3 mb-4">
                  <div className="head mb-2">RSVP Options</div>
                  <div className="themeStoreApp d-flex justify-content-between">
                    <div className="themeImg1">
                      <img
                        alt="Organization Name"
                        src={AppStore}
                        className="d-flex"
                      />
                    </div>
                    <div className="themeImg2">
                      <img
                        alt="Organization Name"
                        src={GooglePlay}
                        className="d-flex"
                      />
                    </div>
                    <div className="themeImg3 d-flex flex-column justify-content-center align-items-center">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/computer.svg").default}
                        className="d-flex"
                      />
                      Website
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {deleteData?.include_social_networks !== 0 ? (
                <div className="d-flex align-items-center justify-content-between px-5 pb-4">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/instagram-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/twitter-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/tiktok-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/facebook-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/youtube-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                  <img
                    alt="Organization Name"
                    src={require("assets/img/vimeo-icon.svg").default}
                    className="mr-2"
                    width={24}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
        <img src={ThemeBg} alt="" className="themeBg" />
      </Modal>

      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              Event Name
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234">
                <span>Invite List</span>
              </div>

              <Modal
                isOpen={guestGroupModal}
                toggle={handelGuestGroupModal}
                {...args}
                className="aboutGuestGroupModal"
              >
                <ModalBody className="pb-0">
                  <div className="aboutGuestGroupModalHeader border-bottom mb-4 pb-3">
                    <img
                      src={GeospaceInfoIcon}
                      alt=""
                      className="guestGroupInfoIcon mt-3"
                    />
                    <div className="head">Send Invites to Your Guest List</div>
                  </div>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        You can create and send multiple invitations to your
                        guests. Invitations CANNOT be recalled - so make sure
                        you are ready before sending!
                      </p>
                      <p>
                        Invites are SEPARATE from your guest list and RSVPs.
                        Guests can RSVP by downloading the guest app, even if
                        they haven’t received an invite.
                      </p>
                    </Col>
                    <Col className="d-flex align-items-center justify-center">
                      <img
                        src={SendInvites}
                        alt=""
                        className="maxWidth100 mx-auto"
                        width={70}
                      />
                    </Col>
                  </Row>
                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        Each invitation can be sent and resent to your guest
                        list. You can control which guests receive the invites.
                      </p>
                    </Col>
                    <Col className="d-flex align-items-center justify-center">
                      <img
                        src={SendInvites1}
                        alt=""
                        className="maxWidth100 mx-auto"
                      />
                    </Col>
                  </Row>

                  <Row className=" border-bottom mb-4 pb-2">
                    <Col sm={7}>
                      <p>
                        If the RSVP date has past, you cannot send the Invite.
                        To send it, you will have to change the RSVP date.
                      </p>
                    </Col>
                    <Col className="d-flex align-items-center justify-center">
                      <img
                        src={SendInvites2}
                        alt=""
                        className="maxWidth100 mx-auto"
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter className="pt-0">
                  <Button color="primary blueBtn" className="w-100">
                    Continue
                  </Button>
                </ModalFooter>
              </Modal>

              <span
                onClick={handelGuestGroupModal}
                className="ml-2 pointer"
                data-tip="Tooltip message will come here....."
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
            </div>
          </div>
          <div className="border-bottom organizationPara">
            This page lists invites you have sent to your guests, and lets you
            create new ones.
          </div>

          <GuestEventWrapper className="p-4">
            <div className="guestSearchWrapper">
              <div className="d-flex align-items-center fontSize14 grayColor">
                12 days until RSVP deadline for this event
                <button className="changeBtn ml-2" onClick={handleRsvpDeadline}>
                  Change
                </button>
                <Modal
                  isOpen={rsvpDeadline}
                  toggle={handleRsvpDeadline}
                  {...args}
                  className="resetGeospaceModal rsvpDeadlineModal"
                >
                  <ModalBody className="pb-0">
                    <div className="resetGeoModalHeader border-bottom mb-3 pb-2">
                      <img
                        src={RSVPDeadline}
                        alt=""
                        className="geospaceInfoIcon"
                      />
                      <div className="head">It is After Your RSVP Deadline</div>
                    </div>
                    <div className="border-bottom">
                      <Row>
                        <Col className="px-3">
                          <p className="fontWeight700">
                            Invites can’t be sent after the RSVP deadline for
                            the event.
                          </p>
                          <p>
                            This is a safety precaution so you don’t send out
                            the Invite after your deadline for receiving
                            Invites.
                          </p>
                          <p>
                            To change this, go to your event, and change your
                            RSVP deadline.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                  <ModalFooter className="pt-0 d-flex align-center justify-space-between mt-n1">
                    <Button
                      color="primary"
                      className="flex-grow-1 pinkBtn"
                      onClick={gotoEvent}
                    >
                      Go to Event
                    </Button>
                    <Button
                      className="btn-default cancelBtn flex-grow-1"
                      onClick={handleRsvpDeadlineClose}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                {/* <button
                  className="changeBtn ml-2"
                  onClick={() => setOpenThemeModal(true)}
                >
                  Invite Theme
                </button> */}
              </div>

              <InviteThemeModal
                openThemeModal={openThemeModal}
                handleThemeClose={() => setOpenThemeModal(false)}
              />

              <div className="guestUserWrap">
                <Link
                  to={`/admin/add-invite-rsvp/${params.sid}/${params.eid}?type=invites-rsvps`}
                  className="addGuest"
                >
                  <img
                    alt="Organization Name"
                    src={require("assets/img/newInvite.svg").default}
                    className="icon"
                  />
                  <div className="whiteSpace">New Invite</div>
                </Link>
              </div>
            </div>
          </GuestEventWrapper>
          <CombinedGuestPool className="px-4 pb-4">
            <div className="inviteRSVPTableWrapper">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={resultSet}
                columns={columns}
                defaultSorted={defaultSorted}
                pagination={paginationFactory()}
              />
            </div>
          </CombinedGuestPool>
        </div>
      </Eventstyle>
      <ToastContainer />
    { guestInviteList.length !== 0 ? ( <button
        type="button"
        className="resetGeoBtn d-flex align-items-center allInviteDeleteBtn"
        onClick={handleDeleteGuestGroupNew}
      ><img
          alt="Organization Name"
          src={require("assets/img/invite-icon-white.svg").default}
          width={18}
          className="mr-2"
        />Delete All Invitation 
      </button>):( <button
        type="button"
        className="deleteGuestBtn d-flex align-items-center allInviteDeleteBtn"
       ><img
          alt="Organization Name"
          src={require("assets/img/invite-icon-white.svg").default}
          width={18}
          className="mr-2"
        />Delete All Invitation  
      </button>) } 
     
      <Modal
        isOpen={deleteGuestGroup}
        toggle={handleDeleteGuestGroupNew}
        {...args}
        className="resetGeospaceModal"
      >
        <ModalBody className="pb-0">
          <div className="resetGeoModalHeader border-bottom mb-4 pb-2">
            <img
              src={ResetGeospaceInfoIcon}
              alt=""
              className="geospaceInfoIcon"
            />
            <div className="head">Delete All Invites</div>
          </div>
          <Row>
            <Col className="px-3">
              <div className="eventThumWrapper">
                <div className="eventThum">
                  <img src={event_img} alt="" className="eventThumImg" />
                </div>
                <div className="eventThumContent">
                  <div className="eventthumbTitle">{eventname}</div>
                  <div className="eventthumbSubTitle">{event_location}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-2 ">
            <Col className="px-3">
              <p>
                Confirm that you want to ALL the invitations you created for
                this event.
              </p>
              <p>
                This does NOT recall or undo any invitations you have sent out
                to your guests.
              </p>
              <p className="bold">
                Your RSVPs will NOT be affected by deleting Invites.
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-0 d-flex align-center justify-space-between">
          <Button
            color="primary"
            className="flex-grow-1 pinkBtn"
            onClick={() => deleteAll()}
          >
            Delete All Invites
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={() => setDeleteGuestGroup(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MnanageInviteList;
