import React from "react";

import AllheaderEvent from "../../components/Headers/AllheaderEvent";
import Tabs from "../../components/Tabs/Tabs";
import Imggroup from "../../assets/img/dummyImg.svg";
import { useParams } from "react-router-dom";
import { eventsService } from "../../_services/masters/events.service";
import HornIcon from "./../../assets/img/hornIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Eventdelete from "../../components/model-form/eventDelete";
function Eventdashboard() {
  const [eventList, seteventList] = React.useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  React.useEffect(() => {
    const id = { event_id: params.eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [params]);

  return (
    <>
      <>
        <AllheaderEvent
          title={eventList.storyz_name ? eventList.storyz_name : ""}
          imgScr={eventList.project_image ? eventList.project_image : Imggroup}
          arw
        />
        <div className="accountinformation ptop eventLeftMenu eventBoxWrapper">
          <div className="card mb-0">
            <Tabs eventdetails={eventList} />
          </div>
          <button
            type="button"
            className="delteEventBtn"
            onClick={() => setModalShowDelete(true)}
          >
            <img src={HornIcon} alt="" className="mr-2" />
            Delete Event
          </button>
        </div>

        <Eventdelete
          displayModal={modalShowDelete}
          closeModal={() => setModalShowDelete(false)}
          name={eventList?.event_name}
          id={params?.sid}
          event_date={eventList?.event_start_date}
          event_count=""
          simag={
            eventList?.cover_image
              ? eventList?.cover_image
              : "/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg"
          }
        />
      </>
    </>
  );
}

export default Eventdashboard;
