import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import searchIcon from "../../assets/img/search-icon.svg";
import { IoMdClose } from "react-icons/io";
import placeholde from "../../assets/img/placeholder.jpg";
import { useHistory } from "react-router-dom";
import { useFindPathSid, useFindPathEid } from "../../CommonData";
import { createPortal } from "react-dom";
const GuestGrouplist = ({
  guestListOption,
  placeholder,
  onChange,
  data,
  gid,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
   const [property, setProperty] = useState({
     top: 0,
     left: 0,
   });
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();
  const history = useHistory();
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  const [searchValue, setSearchValue] = useState("");

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();

    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };
  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    let handler = (e) => {
      if (
        !selectMenuRef.current.contains(e.target) &&
        !selectMenuList?.current?.contains(e.target)
      ) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [eventItems, setEventItems] = useState([]);

  useEffect(() => {
    setEventItems(data);
  }, [data]);

  const guestPrefixOption12 = () => {
    if (!searchValue) {
      return guestListOption;
    }

    return guestListOption.filter(
      (option) =>
        option.group_name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const onAdd = (product) => {
    const index = eventItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      let da2 = [...eventItems, { ...product }];
      setEventItems(da2);

      var arr = [];
      let ids = da2.map((track) => arr.push(track.id));

      onChange(arr, gid);
    }
  };
  const [more, setMore] = useState(false);
  useEffect(() => {
    // let ids = eventItems.map((track) => track.id).join(",");
    // console.log(ids);
    // onChange(ids);
  }, [eventItems]);
  const iclose = (e) => {
    const newPeople = eventItems.filter((person) => person.id !== e.id);
    setEventItems(newPeople);
  };

  return (
    <div className="dropdownBoxWrapperPort" ref={selectMenuRef}>
      <div className="dropdownTriggered heightAuto" ref={buttonHeight}>
        <div className="dropdownPlaceholder w-100">
          <div className="form-control pointer groupMemberWrapper d-flex">
            {!more &&
              eventItems?.slice(0, 1)?.map((event, i) => {
                return (
                  <span className="groupMemberList" key={i}>
                    {event?.group_name}
                    <IoMdClose
                      className="ml-2 pointer"
                      size={16}
                      onClick={() => iclose(event)}
                    />
                  </span>
                );
              })}
            {more &&
              eventItems?.map((event, i) => {
                return (
                  <span
                    className="inviteGuestList d-flex align-items-center"
                    key={i}
                  >
                    {event.group_name}

                    <IoMdClose
                      className="ml-2 pointer"
                      size={16}
                      onClick={() => iclose(event)}
                    />
                  </span>
                );
              })}
            {eventItems.length > 1 ? (
              <span className="more mx-1" onClick={() => setMore(!more)}>
                {more ? "less..." : "more..."}
              </span>
            ) : (
              ""
            )}
            <span onClick={dropdownHandle}>
              <img
                src={searchIcon}
                alt=""
                className="mr-2"
                onClick={dropdownHandle}
              />
              {placeholder}
            </span>
          </div>
        </div>
      </div>
      {selectMenu &&
        createPortal(
          <div
            ref={selectMenuList}
            style={{ top: property.top, left: property.left }}
            className={`dropdownBoxMenuWrapperPort guestListWidth430 left ${
              selectMenu ? "active" : ""
            }`}
          >
            <div className="dropdownBoxMenuInner">
              <div>
                <p
                  className="AddGestUser"
                  onClick={() => {
                    history.push(
                      `/admin/add-guest-group/${sid}/${eid}/?type=guests`
                    );
                  }}
                >
                  Add <FaPlus className="icon" />{" "}
                </p>
              </div>
              <div className="dropdownSearchWrapper">
                <input
                  type="text"
                  placeholder="type to search..."
                  className="form-control"
                  onChange={onSearch}
                  value={searchValue}
                />
              </div>
              <ul className="dropdownMenuListWrapper">
                {guestPrefixOption12()?.length > 0 &&
                  guestPrefixOption12()?.map((option, i) => (
                    <li
                      className="menuItem"
                      key={i}
                      onClick={() => onAdd(option)}
                    >
                      <div className="guestList">
                        <div className="guestUserDetails">
                          <img
                            src={
                              option?.group_image
                                ? option?.group_image
                                : placeholde
                            }
                            alt={option.group_name}
                            className="Avatar"
                          />
                          {option?.group_name}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default GuestGrouplist;
