import React, { useEffect, useState, useRef } from "react";
import { Modal, Row, Col, ModalBody, ModalFooter } from "reactstrap";
import QuestionImg from "../../assets/img/circled-icon.svg";
import placeholde from "../../assets/img/placeholder.jpg";
import DropdownRole from "../Dropdown/DropdownRole";
import DropdownGuest from "../Dropdown/DropdownGuest";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { eventsService } from "../../_services/masters/events.service";
import { useFindPathEid } from "../../CommonData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../store/actionTypes";
const HonoredGuestDropdown = ({
  icon,
  placeholder,
  guestListOption,
  onList,
}) => {
  var eid = useFindPathEid(4);
  const [eventdata, setEventdata] = useState();
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef();
  const [addRolepopus, setAddRolepopus] = useState(false);
  const [userDatagu, setUserDatagu] = useState({});
  const [defaultModale, setDefaultModale] = React.useState(false);
  const [addListModal, setAddListModal] = useState(false);
  const [isthe, setIsthe] = useState("");
  const [forthe, setForthe] = useState("");
  const [eventType, setEventType] = React.useState([]);
  let [showInfo1, setShowInfo1] = useState(false);
  const [arrayOfObjects, setArrayOfObjects] = useState([]);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const dropdownHandle = () => {
    onList();
    setSelectMenu(!selectMenu);
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };
  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    const id = { event_id: eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          setEventdata(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [guestListOption]);

  const AddRoleModal = (event) => {
    setAddRolepopus(false);
  };
  const dataSend = (option) => {
    setUserDatagu(option);
    setSelectMenu(!selectMenu);
    setAddRolepopus(true);
  };
  const handleClick = () => {
    setDefaultModale(true);
    setAddListModal(!addListModal);
  };

  const AddRoleModalid = () => {
    if (!isthe.id) {
      setError(true);
      return null;
    } else {
      const newObject = [
        {
          relation_id: isthe.id ? isthe.id : "", // this values will come from your form
          guest_relation_id: forthe.id ? forthe.id : "",
          title: isthe.title ? isthe.title : "", // depending on your implementation you may have to maintain separate state for each property
        },
      ];

      let obj = {};
      obj["user_id"] = userDatagu.id;
      obj["type"] = "guest_relation";
      obj["event_id"] = eventdata.id;
      obj["drop_down_data"] = newObject;
      //const index1 = arrayOfObjects.findIndex((item) => item.relation_id === isthe.id);
      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status == "success") {
            setAddRolepopus(false);
            toast.success("inset  successfully");
            SecondComponent();
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  function SecondComponent() {
    userGuestService.guesthonoredList(eid).then((res) => {
      if (res.status === "success") {
        dispatch({ type: actionTypes.HONORED_GUEST, payload: res.data });
      } else {
      }
    });
  }
  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div className="dropdownTriggered" onClick={dropdownHandle}>
        <div className="dropdownPlaceholder">
          <div className="addGuest">
            <img src={icon ? icon : null} alt="" width={30} />
            <div className="nowrap"> {placeholder}</div>
          </div>
          {/* <div className="imageeventim234">
            <img src={icon ? icon : null} alt="" width={30} />
            <span>{placeholder}</span>
          </div> */}
        </div>
      </div>
      <div
        className={`dropdownBoxMenuWrapper guestListWidth430 ${selectMenu ? "active" : ""
          }`}
      >
        <div className="dropdownBoxMenuInner">
          <div>
            {/* <p className="AddGestUser">
              Add <FaPlus className="icon" />{" "}
            </p> */}
          </div>
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              value={inputValue}
              onChange={(e) =>
                setInputValue(e.target.value.toLowerCase().replace(/^\s+/g, ""))
              }
            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {guestListOption
              .filter((option) =>
                option.first_name.toLowerCase().includes(inputValue)
              )
              .map((option, i) => (
                <li
                  className="menuItem"
                  key={i}
                  onClick={() => dataSend(option)}
                >
                  <div className="guestList">
                    <div className="guestUserDetails">
                      <img
                        src={option?.imageUrl ? option?.imageUrl : placeholde}
                        alt={option?.first_name}
                        className="Avatar"
                      />
                      {option?.first_name} {option?.last_name}
                    </div>
                    {/* <div className="guestType">{option?.account_type}</div> */}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Modal
        isOpen={addRolepopus}
        toggle={AddRoleModal}
        className="honoredRelationshipModal"
      >
        <ModalBody>
          <div className="HonoredGuestGroupHeader border-bottom">
            <img src={QuestionImg} alt="" className="icon" />
            <div className="head">
              Add an Honor and Relationship for this Guest
            </div>
          </div>
          <Row>
            <Col>
              {userDatagu ? (
                <div className="deleteGuestGroupThumWrapper">
                  <div className="deleteGuestGroupThum">
                    <img
                      src={
                        userDatagu?.imageUrl ? userDatagu?.imageUrl : placeholde
                      }
                      alt=""
                      className="deleteGuestGroupThumImg"
                    />
                  </div>
                  <div className="deleteGuestGroupThumContent">
                    <div className="deleteGuestGroupthumbTitle">
                      {userDatagu?.first_name + " " + userDatagu?.last_name}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="honorRelationshipFormWrapper">
                <div className="form-group">
                  <label htmlFor="isthe" className="control-label">
                    is the
                  </label>

                  <DropdownRole
                    isSearchable
                    handleClick={handleClick}
                    placeHolder="Select..."
                    options={eventType}
                    onChange={(value) => {
                      setIsthe(value);
                    }}
                    onClickOutside={showInfo1}
                    addButton={true}
                    customClass={"honorDropdown"}
                  />
                  {error ? (
                    <span style={{ color: "red" }}>this is required</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="thefor" className="control-label">
                    for the
                  </label>

                  <DropdownGuest
                    isSearchable
                    handleClick={handleClick}
                    placeHolder="Select..."
                    options={guestListOption}
                    onChange={(value) => {
                      setForthe(value);
                    }}
                    onClickOutside={showInfo1}
                    addButton={false}
                    customClass={"honorDropdown"}
                    gid={userDatagu?.id}
                  />
                </div>
                {eventdata ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="regular1" className="control-label">
                        at the
                      </label>
                      <div className="repeateventDetails">
                        <div>
                          <img
                            className="evimages"
                            src={
                              eventdata?.cover_image
                                ? eventdata?.cover_image
                                : placeholde
                            }
                            alt=""
                          />
                        </div>
                        <div className="repeateventTitle">
                          <div>{eventdata?.event_name}</div>
                          <div className="repeateventlocation">
                            {eventdata?.location}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div
            color="flex-grow-1 "
            className="btn btn-primary pinkBtn primary pinkBtn flex-grow-1"
            onClick={AddRoleModalid}
          >
            Add Honor and Relationship
          </div>
          <div className="btn btn-default cancelBtn" onClick={AddRoleModal}>
            Cancel
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HonoredGuestDropdown;
