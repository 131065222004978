import React, { useState } from "react";
import IconImg from "../../assets/img/icons/system-logos.svg";
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import User from "../../assets/img/PngItem_4212341.png";
import { useHistory } from "react-router-dom";

import Resetpasswod from "../../components/model-form/resetPasswod";
import Vector1 from "../../assets/img/icons/two-factor-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { informationService } from "../../_services/masters/information.service";

import Deleteaccount from "../../components/model-form/Deleteaccount.js";

import DeleteaccountOwner from "../../components/model-form/DeleteaccountOwner.js";


import ResetGeospaceInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
import SocialLoginIcon from "../../assets/img/socialLoginIcon.svg";
import { RiCloseFill } from "react-icons/ri";
import Loader from "components/Modal/Loader";
import SelectDropdown from "components/Dropdown/SelectDropdown";
import AdminIcon from "../../assets/img/CombinedGuestList.svg";
import CustomerSupportIcon from "../../assets/img/Logomark123.svg";
import ClientIcon from "../../assets/img/userIcon.svg";
import VenderIcon from "../../assets/img/venderIcon.svg";
import RemoveLoginModal from "components/model-form/RemoveLoginModal";
import { IoMdClose } from "react-icons/io";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import { timezoneService } from "../../_services/masters/timezone.service";
import { useParams } from "react-router-dom";
function Accountownerview({ ...args }) {

  const params = useParams();

  let history = useHistory();
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [timezoneData, setTimezoneData] = React.useState();
  const [time_zone, TimezoneSet] = React.useState(37);
  const [Clock_style, ClockStyleSet] = React.useState(12);
  const [time_zoneData, TimezoneData] = React.useState([]);
  const [removeLoginModal, setRemoveLoginModal] = useState(false);
  const [userdata, setUserdata] = React.useState([]);
  const [timezone, setTimezone] = useState(false);
  const [socialLogin, setSocialLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [verify, setVerify] = useState(0);
  const [id, setId] = React.useState(params?.id);
  const [userInfo, setuserInfo] = React.useState({});



  function userdetail() {
    informationService.acInformationAdmin(params.id).then(
      (res) => {
        if (res.status === "success") {
          setId(params.id);
          setuserInfo(res.userInfo);


        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

const contact = useSelector((state) => {
    return state.profile.profiledetails;
  });


function handleLogOut() {
    setIsLoading(true);

    let data = { "userId": userInfo.users_id }
    informationService.logOut(data).then(
      (res) => {

        if (res.status === "success") {
          setTimeout(() => {

            setIsLoading(false);
            localStorage.removeItem("userDetails");
            history.push("/"); // whichever component you want it to route to
          }, 3000);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  }

  const handleChange = (e) => {

    let checked = e.target.checked === true ? 1 : 0;
    let data = {
      "auth_value": checked,
      "userId": userInfo.users_id

    };

    informationService.updateTwoFactorAuthentication(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success("Updated successfully");
        } else {
          toast.error("Something went wrong");
        }
      },
      (error) => {
        toast.error("Something went wrong");
      }
    );
  };

  const everyLogOut = () => {

    setIsLoading(true);
    let data = { "userId": userInfo.users_id }
    informationService.everyDevicelogout(data).then(
      (res) => {
        if (res.status === "success") {
          setTimeout(() => {

            setIsLoading(false);
            localStorage.removeItem("userDetails");
            history.push("/"); // whichever component you want it to route to
          }, 3000);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
    // history.push("/");
  };
 
  const getuserTimeZone = (timezone_Data) => {
    //console.log("timezoneData", timezoneData);
    timezoneService.getuserTimeZone().then(
      (res) => {
        if (res.status === "success") {
               //setTimezoneData(res.data);
               TimezoneSet(res.data.time_zone);
               ClockStyleSet(res.data.clock_format);
             
               let resultTime = timezone_Data.filter((timezone_Data) => timezone_Data.id == res.data.time_zone);
               TimezoneData(resultTime);
            } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
   
  }

  const updateUserTimeZone = () => {
      const TimeZoneData = { clock_format: Clock_style, time_zone:time_zone };
      timezoneService.updateUserTimeZone(TimeZoneData).then(
      (res) => {
        if (res.status === "success") {
               //setTimezoneData(res.data);
               setTimezone(false);
               getuserTimeZone(timezoneData);
               toast.success("Updated successfully");
               //userdetail();
          } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

  }

  React.useEffect(() => {
    timezoneService.timezoneList().then(
      (res) => {
        if (res.status === "success") {
          setTimezoneData(res.data);
          getuserTimeZone(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  
   }, []);

  React.useEffect(() => {
    userdetail();
  }, [id, params?.id]);
  React.useEffect(() => {
    setVerify(userInfo.two_step_verification);
    //contact.t
    setUserdata(userInfo);
  }, [userInfo]);

  const handleTimezone = (e) => {
    e.preventDefault();
    setTimezone(!timezone);
  };

  const handelSocialLogin = () => {
    setSocialLogin(!socialLogin);
  };

  const TeamRoleList = [
    {
      img: AdminIcon,
      title: "Admin",
    },
    {
      img: AdminIcon,
      title: "Account Owner",
    },
    {
      img: ClientIcon,
      title: "Client",
      disabled: "disabled",
    },
    {
      img: VenderIcon,
      title: "Vendor",
      disabled: "disabled",
    },
    {
      img: CustomerSupportIcon,
      title: "Customer Support",
      disabled: "disabled",
    },
  ];


  return (
    <>
      <ToastContainer />
      {/* <Allheader title="Your Account" /> */}
      <CombinedGuestPoolHeader title="Your Account" imgScr={IconImg} />
      <div className="organizerWrapperMain">
        {/* <Row> */}
        <Form>
          {/* <div className="col"> */}
          <div className="card organizerCardWrapper aboutInfoWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">Account Information</h4>
            </div>

            <div className="organizationPara p-4">
              This page shows your current loging information and team role.
            </div>
            <Row className="aboutInfoBox">
              <Col lg={6} md={12}>
                <div>
                  <div className="basicinformation">
                    <img
                      src={require("assets/img/icons/profile-icon.svg").default}
                      alt=""
                    />
                    Guest Record
                  </div>
                  <div className="cardBox">
                    <div className="inlineFormBox guestRecordCradWrapper">
                      <div className="guestRecordAvatar">
                        <div className="img">
                          <img
                            src={userInfo?.imageUrl ? userInfo.imageUrl : User}
                            alt="Avatar"
                          />
                        </div>
                        <div className="ownerclass">
                          <div className="title">
                            {userInfo?.first_name} {userInfo?.last_name}
                          </div>
                          <div className="subTitle">
                            {userInfo?.account_type}
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link
                          to={`/admin/admin-account-view-other-owner/${userInfo?.users_id}`
                          }
                          className="greenActionBtn"
                        >
                          <i className="fas fa-user"></i>
                          View Guest Record
                        </Link>

                        <div className="grayBtnDisabled disabled">
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/icons/Chat-Vector2.svg")
                                .default
                            }
                          />
                          Message
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="basicinformation">
                    <img
                      src={require("assets/img/icons/Vector-5.svg").default}
                      alt=""
                    />
                    Team Role
                  </div>

                  <div className="cardBox aboutTeamRoleWrapper">
                    <div className="form-group">
                      <label>Team Role</label>
                      <div className="teamRoleDropdown">
                        <div className="teamRoleClient" style={{ background: 'rgba(83, 215, 105, 0.1)' }}>{userInfo?.account_type}</div>
                        {/* <SelectDropdown guestListOption={TeamRoleList} type={userInfo?.account_type} /> */}
                      </div>
                    </div>

                  </div>
                </div>
                <div style={contact.account_type === 'Admin' && userInfo.users_id != contact.user_id ? { display: 'none' } : {}}>
                  <div className="basicinformation">
                    <img
                      src={require("assets/img/timeZone.svg").default}
                      alt=""
                    />
                    Timezone
                  </div>
                  <div className="cardBox">
                    <div className="form-group timeZoneWrapper">
                      <div className="inlineFormBox">
                        <div>
                          <div className="accountname2Client1">
                            <strong>Timezone:</strong> { time_zoneData.length > 0 ? time_zoneData[0].text : "" }
                          </div>
                          <div className="accountname2Client1">
                            <strong>Clock:</strong> {Clock_style} Hour
                          </div>
                        </div>
                        <div>
                          <button
                            className="changeBtn"
                            onClick={handleTimezone}
                          >
                            Change
                          </button>
                          <Modal
                            isOpen={timezone}
                            toggle={handleTimezone}
                            {...args}
                            className="changeTimezoneModal"
                          >
                            <ModalBody>
                              <div className="changeTimezoneModalHeader border-bottom ">
                                <img
                                  src={ResetGeospaceInfoIcon}
                                  alt=""
                                  className="changeTimezoneInfoIcon"
                                />
                                <div className="head">
                                  Change Timezone and Clock Style?
                                </div>
                              </div>
                              <Row>
                                <Col>
                                  <div className="containerBox px-4">
                                    <div className="form-group">
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        Timezone for the Organization
                                      </label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) => TimezoneSet(e.target.value)}
                                        value={time_zone}
                                      >
                                        {timezoneData?.map((post, index) => (
                                          <option key={index} data-key={post.abbr} value={post.id}>
                                            {post.text}
                                            </option>
                                           ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                    
                                      <label
                                        htmlFor="exampleFormControlInput1"
                                        className="form-label"
                                       >
                                        Clock Style
                                      </label>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) => ClockStyleSet(e.target.value)}
                                        value={Clock_style}
                                      >
                                        <option value="12" >12-Hour</option>
                                        <option value="24">24-Hour</option>
                                      </select>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <ModalFooter>
                                <Button
                                  color="primary"
                                  className="flex-grow-1 pinkBtn"
                                  onClick={() => updateUserTimeZone() }
                                >Change
                                </Button>
                                <Button
                                  className="btn-default cancelBtn"
                                  onClick={handleTimezone}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </ModalBody>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} style={contact.account_type === 'Admin' && userInfo.users_id != contact.user_id ? { display: 'none' } : {}}>
                <div>
                  <div className="basicinformation">
                    <img src={Vector1} alt="" />
                    Authentication
                  </div>
                  <div className="cardBox AboutAuthenticationWrapper">
                    <div className="AboutAuthenticationList border-bottom">
                      <div className="custom-control form-check form-check-inline ">
                        <label className="container3 form-check-label">
                          Enable 2-factor authentication

                          <input
                            type="checkbox"
                            name="auth_value"
                            value={userInfo.two_step_verification}
                            checked={
                              Number(userInfo.two_step_verification) === 1
                                ? true
                                : false
                            }
                            onClick={(e) => handleChange(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="align-items-center AboutAuthenticationList border-bottom">
                      <div>Change your local password:</div>
                      <div>
                        <Resetpasswod userId={userInfo?.users_id} />
                      </div>
                    </div>

                    {/* <div className="AboutAuthenticationList">
                      <div>
                        <div className="">Create or remove social logins:</div>
                        <Modal
                          isOpen={socialLogin}
                          toggle={handelSocialLogin}
                          {...args}
                          className="socialLoginModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="socialLoginModalHeader border-bottom">
                              <img
                                src={SocialLoginIcon}
                                alt=""
                                className="socialLoginModalInfoIcon"
                              />
                              <div className="head">
                                Add or Remove Social Login
                              </div>
                            </div>

                            <p>
                              Selecting below lets you add a social login
                              provided by a third-party authorization service
                              like Google, Facebook, or Apple. It does not
                              affect your local username and password.
                            </p>

                            <h3>Available Logins</h3>
                            <div>
                              <div className="socialList active">
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/facebook.svg").default
                                  }
                                  className="socialIcon pointer"
                                />
                                Facebook added 8.22.2022
                                <RiCloseFill size={18} className="closeIcon" />
                              </div>
                              <div className="socialList">
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/icons/appleicon.svg")
                                      .default
                                  }
                                  className="socialIcon"
                                />
                                Add Apple login
                                <RiCloseFill
                                  size={18}
                                  className="closeIcon pointer"
                                />
                              </div>
                              <div className="socialList">
                                <img
                                  alt="Organization Name"
                                  src={
                                    require("assets/img/icons/googleplus.svg")
                                      .default
                                  }
                                  className="socialIcon"
                                />
                                Add Google login
                                <RiCloseFill
                                  size={18}
                                  className="closeIcon pointer"
                                />
                              </div>
                            </div>

                            <ModalFooter className="">
                              <Button
                                color="primary"
                                className="flex-grow-1 blueBtn"
                              // onClick={handleReseting}
                              >
                                Save Settings
                              </Button>
                              <Button
                                className="btn-default cancelBtn"
                                onClick={handelSocialLogin}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </ModalBody>
                        </Modal>
                      </div>
                      <div
                        className="d-flex justify-flex-end pointer"
                        onClick={handelSocialLogin}
                      >
                        <img
                          alt="Organization Name"
                          src={require("assets/img/icons/facebook.svg").default}
                          className="ml-auto mr-2"
                          width="24px"
                        />
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/icons/googleplus.svg").default
                          }
                          width="24px"
                          className="mr-2"
                        />
                        <img
                          alt="Organization Name"
                          src={
                            require("assets/img/icons/appleicon.svg").default
                          }
                          width="24px"
                        />
                      </div>
                    </div> */}



                  </div>
                </div>
                <div className="aboutLogoutWrapper">
                  <div className="basicinformation">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/logoutIcon1.svg").default}
                    />
                    Logout
                  </div>
                  <div className="cardBox aboutLogoutList">
                    <div>
                      <p>
                        You can log out on your current device, or log out
                        everywhere. This is useful if you are using OurStoryz on
                        a public computer.
                      </p>
                    </div>
                    <div className="logoutFromDeviceWraper">
                      <div
                        className="accountnameclose pointer"
                        onClick={() => handleLogOut()}
                      >
                        <img
                          alt="Organization Name"
                          src={require("assets/img/icons/close.svg").default}
                        />
                        LOGOUT FROM THIS DEVICE
                      </div>
                      <div
                        className="accountnameclose pointer"
                        onClick={() => everyLogOut()}
                      >
                        <img
                          alt="Organization Name"
                          src={require("assets/img/icons/close.svg").default}
                        />
                        LOGOUT EVERYWHERE
                      </div>
                    </div>
                  </div>
                  {isLoading ? <Loader /> : null}

                  <div className="basicinformation">
                    <img
                      alt="Organization Name"
                      src={require("assets/img/trash-icon.svg").default}
                    />
                    Delete Login or Account
                  </div>
                  <div className="cardBox aboutDeleteAccount">
                    <div>
                      <p>
                        Important! this will remove the login for this Admin.
                        Guest information won’t be altered.
                      </p>
                    </div>
                    <div className="aboutDeleteAccountList">
                      {/* <div
                        className="accountdelete pointer removeLoginBtn"
                        onClick={() => setRemoveLoginModal(true)}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/icons/delete.svg").default}
                            width="12px"
                          />
                        </span>
                        Remove Login
                      </div> */}
                      {userInfo?.account_type === 'Account Owner' ? <>
                        <Button
                          className="saveChangesBtn ml-auto"
                          disabled
                        >
                          <IoMdClose className="icon" /> Remove Login
                        </Button>
                      </> : <>
                        <Button
                          className="saveChangesBtn ml-auto"
                          onClick={() => setRemoveLoginModal(true)}
                        >
                          <IoMdClose className="icon" /> Remove Login
                        </Button>
                      </>}


                      <RemoveLoginModal
                        displayModal={removeLoginModal}
                        closeModal={() => setRemoveLoginModal(false)}
                        data={userInfo}
                      />
                    </div>
                  </div>
                  <div className="cardBox aboutDeleteAccount">
                    <div>
                      <p>
                        Important! this will completely delete your information.
                        If you are the Account Owner, it will delete the entire
                        organization account.
                        <strong>Your information is non-recoverable.</strong>
                      </p>
                    </div>
                    <div className="aboutDeleteAccountList">
                      {/* <div
                        className="accountdelete pointer"
                        onClick={() => setModalShowDelete(true)}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <img
                            alt="Organization Name"
                            src={require("assets/img/icons/delete.svg").default}
                            width="12px"
                          />
                        </span>
                        Delete Account
                      </div> */}
                      <Button
                        className="saveChangesBtn ml-auto"
                        onClick={() => setModalShowDelete(true)}
                      >
                        <IoMdClose className="icon" /> Delete Account
                      </Button>

                      {userInfo?.account_type === 'Admin' ? <>

                        <Deleteaccount
                          displayModal={modalShowDelete}
                          closeModal={() => setModalShowDelete(false)}
                          Img={userdata?.imageUrl ? userdata.imageUrl : User}
                          name={`${userdata?.first_name} ${userdata?.first_name}`}
                          accountType={userdata?.account_type}

                          id={params?.id}
                        />

                      </> : <>
                        <DeleteaccountOwner
                          displayModal={modalShowDelete}
                          closeModal={() => setModalShowDelete(false)}
                          Img={contact?.imageUrl ? contact.imageUrl : User}
                          name={`${contact?.first_name} ${contact?.first_name}`}
                          accountType={contact?.account_type}
                          id={params?.id}
                        />

                      </>
                      }

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
        </Form>
        {/* </Row> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default Accountownerview;
