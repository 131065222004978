import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
import placeholde from "../../assets/img/placeholder.jpg";
import { eventsService } from "../../_services/masters/events.service";
import { useFindPathSid, useFindPathEid } from "../../CommonData";
import Allheader from "components/Headers/AllheaderEvent";
import Imggroup from "../../assets/img/dummyImg.svg";
import ResetGeospaceInfoIcon from "../../assets/img/resetgeospaceInfoIcon.svg";
import { MdOutlineGroup } from "react-icons/md";
import EventGuestGroupList from "components/Events/GuestGroup/EventGuestGroupList";
import { combinedService } from "../../_services/masters/information.combined";
import { useHistory } from "react-router-dom";
const EventGuestGroup = ({ ...args }) => {
  let history = useHistory();
  const [deleteGuestGroup, setDeleteGuestGroup] = useState(false);
  const handleDeleteGuestGroup = () => {
    setDeleteGuestGroup(!deleteGuestGroup);
  };
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const param = useParams();
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);
  const [guestGroupList, setGuestGroupList] = useState();
  const [eventList, seteventList] = React.useState([]);
  useEffect(() => {
    const id = { event_id: eid };

    combinedService.guestGroupList(id).then(
      (res) => {
        if (res.status === "success") {
          setGuestGroupList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          //console.log(res.data);
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [eid]);
  //console.log(eventList);
  return (
    <>
      <Allheader
        title={eventList.storyz_name ? eventList.storyz_name : "Storyz Name"}
        imgScr={eventList.project_image ? eventList.project_image : Imggroup}
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <EventGuestGroupList />
        </div>

        {/* guestGroupList  pointer-events: none; opacity: 0.4 */}

        <button
          type="button"
          className="resetGeoBtn"
          onClick={handleDeleteGuestGroup}
          style={
            guestGroupList?.length === 0
              ? { pointerEvents: "none", opacity: "0.4" }
              : {}
          }
        >
          <MdOutlineGroup className="mr-2" size={24} />
          Delete Guest List
        </button>
        <Modal
          isOpen={deleteGuestGroup}
          toggle={handleDeleteGuestGroup}
          {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-4 pb-2">
              <img
                src={ResetGeospaceInfoIcon}
                alt=""
                className="geospaceInfoIcon"
              />
              <div className="head">
                Remove All Groups from the Guest List for this Event?
              </div>
            </div>
            <Row>
              <Col className="px-3">
                <div className="eventThumWrapper">
                  <div className="eventThum">
                    <img
                      src={
                        eventList?.cover_image
                          ? eventList?.cover_image
                          : placeholde
                      }
                      alt=""
                      className="eventThumImg"
                    />
                  </div>
                  <div className="eventThumContent">
                    <div className="eventthumbTitle">
                      {eventList?.event_name}
                    </div>
                    <div className="eventthumbSubTitle">Location</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-3">
                <p>
                  Confirm that you want to remove ALL groups from the Guest
                  List. This will Not affect the Guest List, just how guests are
                  grouped into larger collections.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button
              color="primary"
              className="flex-grow-1 pinkBtn"
              onClick={() => {
                try {
                  // const val1 =
                  // {
                  //   "group_id": row.id,
                  //   "key_name": 'group_name',
                  //   "val": e.target.value
                  // };
                  const val1 = {
                    event_id: eventList?.id,
                  };
                  //console.log(val1);
                  combinedService.deleteAllGuestGroup(val1).then((res) => {
                    if (res.status === "success") {
                      handleDeleteGuestGroup(false);
                      // datatolist();
                      // toast.success('update successfully !!', "Account");
                      history.push(`/admin/event-dashboard/${sid}/${eid}`);
                    } else {
                      //toast.error('Something went wrong !!', "error");
                    }
                  });
                } catch (err) {
                  //toast.error('Something went wrong !!', "error");
                }
              }}
            >
              Delete All Groups
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleDeleteGuestGroup(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default EventGuestGroup;
