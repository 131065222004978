import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FilemanagerCsv from "../../../components/CommonMediaModal/FilemanagerCsv";
const UploadSelectType = ({ handleSelectType, selectType }) => {
  // const [selectType, setSelectType] = useState(false);
  // const handleSelectType = () => setSelectType(!selectType);
  const [modalShow, setModalShow] = useState(false);
  const handleClickimag = () => {
    setModalShow(false);
  };
  const [allimage, setAllimage] = useState([]);
  return (
    <>
      {selectType && (
        <>
          <div className={`modal fade  ${selectType ? "show" : ""}`}>
            <div className="modal-dialog modal-dialog-centered uploadSelectTypeModal">
              <div className="modal-content">
                <div className="modal-header border-bottom">
                  <h5 className="modal-title"> Choose an import source</h5>
                </div>
                <div className="modal-body">
                  <h2>You can upgrade later if desired.</h2>
                  <div className="chooseImportSourceWrapper">
                    <div className="chooseImportBox chooseImportSourceLeft ">
                      <div className="chooseImportDocWrapper">
                        <div className="chooseImportDoc">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/excel-icon.svg").default}
                            className="icon"
                          />
                          <div className="docName">Excel</div>
                        </div>
                        <div className="chooseImportDoc">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/CSVIcon.svg").default}
                            className="icon"
                          />
                          <div className="docName">CSV</div>
                        </div>
                      </div>
                      <div className="choosImportContentWraper">
                        <h3>Upload from your files</h3>
                        <p>
                          Choose a spreadsheet with a guest list already made.
                          <br />
                          OurStoryz will try to match fields in your file.
                        </p>
                        <div
                          className="btn uploadBtn"
                          onClick={() => {
                            setModalShow(true);
                          }}
                        >
                          Upload
                        </div>
                      </div>
                    </div>
                    <div className="chooseImportBox chooseImportSourceRight">
                      <div className="listAnotherServicesWraper">
                        <div className="listAnotherServices">
                          <img
                            alt="Organization Name"
                            src={require("assets/img/zola-icon.svg").default}
                            className="icon"
                          />
                          <img
                            alt="Organization Name"
                            src={require("assets/img/theKnotIcon.svg").default}
                            className="icon"
                          />
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/paperlessIcon.svg").default
                            }
                            className="icon"
                          />
                          <img
                            alt="Organization Name"
                            src={
                              require("assets/img/greenEnvelopIcon.svg").default
                            }
                            className="icon"
                          />
                          <img
                            alt="Organization Name"
                            src={require("assets/img/rsvipfyIcon.svg").default}
                            className="icon check"
                          />
                        </div>
                      </div>
                      <div className="choosImportContentWraper">
                        <h3>Get list from another service</h3>
                        <p>
                          If you have a guest list on another service, you may
                          be able to import it to OurStoryz. Access depends on
                          your account setup.
                        </p>
                        <div className="btn uploadBtn">Upload</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    className="canelTextBtn ml-auto"
                    onClick={handleSelectType}
                  >
                    cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal
            isOpen={selectType}
            toggle={handleSelectType}
            className="modal-dialog uploadSelectTypeModal"
          >
            <ModalHeader className="modal-header border-bottom">
              Choose an import source
            </ModalHeader>
            <ModalBody className="modal-body">
              <h2>You can upgrade later if desired.</h2>
              <div className="chooseImportSourceWrapper">
                <div className="chooseImportBox chooseImportSourceLeft ">
                  <div className="chooseImportDocWrapper">
                    <div className="chooseImportDoc">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/excel-icon.svg").default}
                        className="icon"
                      />
                      <div className="docName">Excel</div>
                    </div>
                    <div className="chooseImportDoc">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/CSVIcon.svg").default}
                        className="icon"
                      />
                      <div className="docName">CSV</div>
                    </div>
                  </div>
                  <div className="choosImportContentWraper">
                    <h3>Upload from your files</h3>
                    <p>
                      Choose a spreadsheet with a guest list already made.
                      <br />
                      OurStoryz will try to match fields in your file.
                    </p>
                    <div
                      className="btn uploadBtn"
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </div>
                <div className="chooseImportBox chooseImportSourceRight">
                  <div className="listAnotherServicesWraper">
                    <div className="listAnotherServices">
                      <img
                        alt="Organization Name"
                        src={require("assets/img/zola-icon.svg").default}
                        className="icon"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/theKnotIcon.svg").default}
                        className="icon"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/paperlessIcon.svg").default}
                        className="icon"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/greenEnvelopIcon.svg").default}
                        className="icon"
                      />
                      <img
                        alt="Organization Name"
                        src={require("assets/img/rsvipfyIcon.svg").default}
                        className="icon check"
                      />
                    </div>
                  </div>
                  <div className="choosImportContentWraper">
                    <h3>Get list from another service</h3>
                    <p>
                      If you have a guest list on another service, you may be
                      able to import it to OurStoryz. Access depends on your
                      account setup.
                    </p>
                    <div className="btn uploadBtn">Upload</div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="modal-footer">
              <Button className="canelTextBtn" onClick={handleSelectType}>
                cancel
              </Button>
            </ModalFooter>
          </Modal> */}
        </>
      )}

      {modalShow ? (
        <FilemanagerCsv
          show={modalShow}
          onHide={handleClickimag}
          list={allimage}
          mapkey=""
        />
      ) : (
        ""
      )}
    </>
  );
};

export default UploadSelectType;
