import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { todoService } from "_services/masters/information.todos";
import mL from "_helpers/month";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import {
  FormGroup,
  Input,
} from "reactstrap";
import DataFundata from "../../components/Todo/DataFundata";
import CreateTodo from "components/Todo/CreateTodo";
import CreateEditTodo from "components/Todo/CreateEditTodo";
import DeleteModal from "components/Modal/DeleteModal";
import { GrCircleInformation } from "react-icons/gr";
import { ToastContainer } from "react-toastify";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import IconImg from "../../assets/img/icons/system-logos.svg";

const { SearchBar } = Search;

function Accountownerview() {
  const [todolist, setTodolist] = useState([]);
  const [modal_flag, setmodalFlag] = useState(false);
  const [EventData, setEventData] = useState({});
  const [delEventData, setDelEventData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [filter, setFilter] = useState("All");

  let eventTodoArr = [];
  let urlArr = window.location.href.split("/");
  let event_id = urlArr[urlArr.length - 1];
  let storyz_id = urlArr[urlArr.length - 2];
  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let storyz_name = localStorage.getItem("todo_storyz_name");
  let storyz_img = localStorage.getItem("todo_storyz_img");
  let UserData = JSON.parse(localStorage.getItem("userDetails"));
  // let org_id = UserData.orgData[0].id;
  // let org_name = UserData.orgData[0].company_name;
  // let org_img = UserData.orgData[0].logo;
  //let c = 0;
  //Math.floor(Math.random()*21)
  localStorage.setItem("count_o", Math.floor(Math.random() * 4));

  const TodoModal = () => {
    setmodalFlag(!modal_flag);
    listOrganizationTodo();
  };

  const editTodo2 = (props) => {
    //console.log('---props', props);
    //console.log('propsRohit', props);
    setEventData(props);
    setTimeout(() => {
      setmodalFlag(true);
    }, 1);
  };

  const onFilter = (filter_val) => {
    //alert(filter_val);
    setFilter(filter_val);
    setTimeout(() => {
      listOrganizationTodo();
      localStorage.setItem("count_o", Math.floor(Math.random() * 2));
    }, 1);
  };

  const handelDeleteModal = (props) => {
    setDelEventData(props);
    setDeleteModal(!deleteModal);
    listOrganizationTodo();
  };

  const refresh = () => {
    listOrganizationTodo();
    localStorage.setItem("count_o", Math.floor(Math.random() * 5));
  };

  const listOrganizationTodo = () => {
    let resdata = { filter: filter };
    todoService.listOrganizationTodo(resdata).then(
      (res) => {
        if (res.status === "success") {
          let respons = res.data;

          let obj = {};
          for (let index = 0; index < respons.length; index++) {
            let assignedArr = respons[index].assigned_on.split("T");
            let assigned_date_arr = assignedArr[0].split("-");
            let day = assigned_date_arr[assigned_date_arr.length - 1];
            assigned_date_arr[assigned_date_arr.length - 1] = day;
            let assigned_date = moment
              .utc(respons[index].assigned_on)
              .format("DD/MM/YYYY h:mm a");

            let dueArr = respons[index].due_date.split("T");
            let due_date_arr = dueArr[0].split("-");
            let end_day = due_date_arr[due_date_arr.length - 1];
            due_date_arr[due_date_arr.length - 1] = end_day;
            let due_date = moment
              .utc(respons[index].due_date)
              .format("DD/MM/YYYY h:mm a");
            let start_date = "";

            if (
              respons[index].start_date !== null &&
              respons[index].start_date !== ""
            ) {
              let start_dateArr = respons[index].start_date.split("T");
              let start_date_Arr = start_dateArr[0].split("-");
              let strat_day = start_date_Arr[start_date_Arr.length - 1];
              let strat_month = start_date_Arr[start_date_Arr.length - 2];
              let monthArr = strat_month.split("");
              let m = 0;
              if (monthArr[0] == 0) {
                m = monthArr[1];
              } else {
                m = monthArr[0] + monthArr[1];
              }
              m = parseInt(m - 1);
              //console.log('monthArr', monthArr);
              start_date = ` ${mL[m]} ${strat_day}`;
            }
            obj = {
              todo_name: respons[index].todo_name,
              actions: respons[index].id,
              guest_name: (
                <DataFundata
                  start_date={start_date}
                  id={respons[index].id}
                  title={respons[index].todo_name}
                  assigned={assigned_date}
                  due={due_date}
                  cover_image={respons[index].cover_image}
                  event_name={respons[index].event_name}
                  todo_status={respons[index].todo_status}
                  url={respons[index].knowledge_base_url}
                  shortdes={respons[index].short_description}
                  editTodo2={editTodo2}
                  todo_type={respons[index].todo_type}
                  handelDeleteModal={handelDeleteModal}
                  organization
                  oldDate={respons[index].assigned_on}
                  endoldDate={respons[index].due_date}
                />
              ),
            };
            eventTodoArr.push(obj);
          }
          setTodolist(eventTodoArr);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  useEffect(() => {
    listOrganizationTodo();
  }, [localStorage.getItem("count_o")]);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    prePageText: "Previous",
    nextPageText: "Next",
    sizePerPage: 10,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  const dataTable = todolist;

  return (
    <>
      <ToastContainer />
      <DeleteModal
        deleteModal={deleteModal}
        handelDeleteModal={handelDeleteModal}
        delEventData={delEventData}
        refresh={refresh}
        organization
      />
      <CreateEditTodo
        modal_flag={modal_flag}
        TodoModal={TodoModal}
        title=""
        EventData={EventData}
        organization
      />
      {/* <Allheader title="Todos" /> */}
      <CombinedGuestPoolHeader title="Todos" imgScr={IconImg} />
      <div className="todo organizerWrapperMain">
        {/* <Row className="todoslist">
          <div className="col px-0"> */}
        <div className="card organizerCardWrapper">
          <div className="headingWrapper borderBottom">
            <h4 className="heading-1">Organization-Level Todo List</h4>
            <GrCircleInformation className="ml-auto iconColor infoToolTip" />
          </div>
          <div className="borderBottom organizationPara">
            This screen lets you create and assign Todos at the Organization
            level. It shows todos created here, in Storyz, or in individual
            events. To assign a todo to a Storyz or Event, navigate there and
            select “todo” from the menu.
          </div>
          <ToolkitProvider
            data={dataTable}
            keyField="name"
            columns={[
              {
                dataField: "guest_name",
                text: "",
                sort: false,
              },
              {
                dataField: "todo_name",
                sort: false,
                style: { display: "none" },
              },
            ]}
            search
          >
            {(props) => (
              <div className="dataFundaTable todoTableWrapper orgTodoTableWrapper">
                <div className="todoTableWrapperInner orgTodoWrapper">
                  <div className="orgTodoList">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter"
                    >
                      <img
                        alt="Organization Name"
                        src={
                          require("assets/img/icons/monogram-circular.svg")
                            .default
                        }
                      />
                      <SearchBar
                        className="form-control"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </div>
                    <div className="filterBy">
                      <label
                        className="form-control-label mb-0"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Filter by :
                      </label>
                      <FormGroup>
                        <Input
                          id="exampleFormControlSelect1"
                          type="select"
                          onChange={(e) => onFilter(e.target.value)}
                        >
                          <option value="All">All Todo</option>
                          <option value="Organization">
                            Organization Todo
                          </option>
                          <option value="Storyz">Storyz Todo</option>
                          <option value="Event">Events Todo</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="orgTodoList">
                    <CreateTodo
                      title="Add Organization Todo"
                      refresh={listOrganizationTodo}
                      organization
                    />
                  </div>

                  {/* <div xl={4} md={4} className="d-flex align-items-center">
                        <Row className="d-flex align-items-center">
                          <div className="showEntries">
                              <span className="form-control-label">Show</span>
                              <FormGroup
                                style={{ width: "129px" }}
                                className="mb-0 mr-2"
                              >
                                <Input
                                  id="exampleFormControlSelect1"
                                  type="select"
                                >
                                  <option>Select</option>
                                </Input>
                              </FormGroup>

                              <span className="form-control-label">
                                Entries
                              </span>
                            </div>
                          <label
                            className="form-control-label mb-0"
                            htmlFor="exampleFormControlSelect1"
                            style={{ padding: "12px" }}
                          >
                            Filter by :
                          </label>

                          <FormGroup
                            style={{ width: "129px" }}
                            className="mb-0"
                          >
                            <Input
                              id="exampleFormControlSelect1"
                              type="select"
                              onChange={(e) => onFilter(e.target.value)}
                            >
                              <option value="All">All Todo</option>
                              <option value="Organization">
                                Organization Todo
                              </option>
                              <option value="Storyz">Storyz Todo</option>
                              <option value="Event">Events Todo</option>
                            </Input>
                          </FormGroup>
                        </Row>
                      </div> */}
                  {/* <div
                        xl={4}
                        md={4}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <CreateTodo
                          title="Add Organization Todo"
                          refresh={listOrganizationTodo}
                          organization
                        />
                      </div> */}
                </div>

                <div className="eventTodoTableNew">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                  ></BootstrapTable>
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
        {/* </div>
        </Row> */}
      </div>
    </>
  );
}

export default Accountownerview;
