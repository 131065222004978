import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Allheader from "../../components/Headers/Allheader";
import StoryzMenu from "../../components/Storyz/StoryzMenu";
import { Row, Col, Progress, Input, Button } from "reactstrap";
import Imggroup from "../../assets/img/dummyImg.svg";
import { useForm } from "react-hook-form";
import CoverImag from "../../assets/img/e7d345fae0c7184e121fa3b3520ce064.png";
import ReactTooltip from "react-tooltip";
import Events from "../../components/Events";
import { informationService } from "../../_services/masters/information.service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function StoryzDashboardempty() {
    // const [dateRange, setDateRange] = React.useState([null, null]);
    // const [startDate, endDate] = dateRange;
    const [imgData, setImgData] = React.useState(null);

    const [imgCover, setImgCover] = React.useState(null);

    const onCoverimage = (e) => {
        if (e.target.files[0]) {
            setImgCover(URL.createObjectURL(e.target.files[0]));
        }
    };
    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            setImgData(URL.createObjectURL(e.target.files[0]));
        }
    };

    const contact = useSelector((state) => {
        return state.profile;
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const onSubmit = (data) => {
        var formdata = new FormData();
        formdata.append("project_name", data.project_name);
        formdata.append("description", data.description);
        formdata.append("project_greeting_text", data.project_greeting_text);
        formdata.append("project_image", data.file[0]);
        formdata.append("organization_id", data.organization_id);
        informationService.storyzSave(formdata).then(
            (res) => {
                if (res.status === "success") {
                    toast.success(res.message, "Account");
                    reset();
                } else {
                    console.log("Something went wrong !!");
                    //toast.error(res.errors[0], "Fashion Store");
                }
            },
            (error) => {
                console.log("Something went wrong !!");
                //toast.error("Something went wrong !!", "Fashion Store");
            }
        );
    };

    return (
        <>
            <Allheader title="Storyz Name" imgScr={Imggroup} arw />

            <div className="accountinformation ptop storyzDashboard">
                <div className="card">
                    <Row>
                        <Col lg={6} md={12}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* <div className="backgroevent"> */}
                                <div
                                    className="backgroevent"
                                    style={{
                                        background: `url(${imgCover ? imgCover : CoverImag})`,
                                    }}
                                >
                                    <div className="layer">
                                        <div style={{ display: "flex" }}>
                                            <img
                                                alt="Organization Name"
                                                src={require("assets/img/edit-fieldicons.svg").default}
                                                style={{ paddingRight: "5px" }}
                                            />

                                            <h3
                                                className="fontSize14 hcolor"
                                                style={{
                                                    margin: "5px",
                                                    color: "#1F1F1F",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                Organization Name
                                            </h3>
                                        </div>

                                        <Row>
                                            <Col lg={5} md={12}>
                                                <div className="coverimagest">
                                                    <div className="coverimage1">
                                                        <p
                                                            className="fontSize14"
                                                            style={{ color: "#666666" }}
                                                        >
                                                            Cover Image :
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="upload-btn-wrapper"
                                                            style={{ marginLeft: "10px" }}
                                                        >
                                                            <img
                                                                src={require("assets/img/update.svg").default}
                                                                className="mb-3"
                                                            />
                                                            <Input
                                                                id="profilePic"
                                                                type="file"
                                                                name="myfile"
                                                                onChange={onCoverimage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="imgMask1">
                                                        <div className="imgMask-content">+</div>
                                                        {imgData ? (
                                                            <img
                                                                className="storytoimage"
                                                                id="blah2"
                                                                src={imgData ? imgData : ""}
                                                                alt="."
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <input
                                                        type="file"
                                                        className="imgg1"
                                                        id="imgInp"
                                                        name="file"
                                                        onChange={onChangePicture}
                                                        {...register("file")}
                                                    />
                                                    {/* <img className='storytoimage' alt="Organization Name" src="/static/media/story1.34378bd92fc0b551845d1e84fc07af79.svg" /> */}
                                                </div>
                                            </Col>
                                            <Col lg={7} md={12}>
                                                <h1 className="storyzname">
                                                    <input
                                                        type="text"
                                                        {...register("project_name", {
                                                            required: "Name is required",
                                                        })}
                                                        name="project_name"
                                                        className="form-control"
                                                        placeholder="Storyz  Name goes here and continues"
                                                        style={{ fontSize: "20px" }}
                                                    />
                                                </h1>
                                                <small className="error">
                                                    {errors?.project_name && errors.project_name.message}
                                                </small>
                                                <div>
                                                    <span className="fontSize16 daclass">No Dates</span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="stclass">0 Events</span>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="wedding">Type Set</p>
                                                    <p className="wedding">Location</p>
                                                    <p className="wedding">Hosted by: </p>
                                                    <p
                                                        className="GreatVibes fontSize24"
                                                        style={{ margin: "0px" }}
                                                    >
                                                        Greeting
                                                    </p>
                                                    <p className="wedding">
                                                        <input
                                                            {...register("project_greeting_text", {
                                                                required: "Greeting is required",
                                                            })}
                                                            type="text"
                                                            name="project_greeting_text"
                                                            className="form-control"
                                                        />
                                                    </p>
                                                    <small className="error">
                                                        {errors?.project_greeting_text &&
                                                            errors.project_greeting_text.message}
                                                    </small>
                                                </div>
                                            </Col>

                                            {contact?.profiledetails.organization[0]
                                                ?.organization_id ? (
                                                <>
                                                    <input
                                                        id="example2cols1Input"
                                                        placeholder="Postal Code"
                                                        type="hidden"
                                                        className="form-control"
                                                        name="organization_id"
                                                        {...register("organization_id")}
                                                        defaultValue={
                                                            contact?.profiledetails.organization[0]
                                                                ?.organization_id
                                                                ? contact?.profiledetails.organization[0]
                                                                    ?.organization_id
                                                                : ""
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Row>

                                        <div>
                                            <h4 className="GreatVibes fontSize24 mt-2">About</h4>
                                        </div>
                                        <div>
                                            <p className="fontSize14">
                                                <textarea
                                                    {...register("description", {
                                                        required: "About is required",
                                                    })}
                                                    className="form-control"
                                                    name="description"
                                                    rows="3"
                                                    placeholder=""
                                                ></textarea>
                                            </p>
                                            <small className="error">
                                                {errors?.description && errors.description.message}
                                            </small>
                                        </div>
                                        <div className="moredetails mt-3">
                                            More Details:
                                            <img
                                                alt="..."
                                                className="imgclast"
                                                src={require("assets/img/moredetails1.svg").default}
                                            />
                                            <img
                                                alt="..."
                                                className="imgclast"
                                                src={require("assets/img/moredetails2.svg").default}
                                            />
                                            <img
                                                alt="..."
                                                className="imgclast"
                                                src={require("assets/img/Vectorma.svg").default}
                                            />
                                            <img
                                                alt="..."
                                                className="imgclast"
                                                src={require("assets/img/moredetails4.svg").default}
                                            />
                                        </div>
                                        <hr
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "0em",
                                                borderTop: "1px solid #979798",
                                            }}
                                        />

                                        <div className="mt-4 pl-3 pb-3">
                                            <div>
                                                <Row>
                                                    <Col
                                                        md="8"
                                                        style={{
                                                            background: "rgba(255, 255, 255, 0.38)",
                                                            borderRadius: "6px",
                                                            padding: "13px",
                                                        }}
                                                    >
                                                        <p>Status:</p>
                                                        <Progress
                                                            color="#979798"
                                                            className="progress-lg mb-0"
                                                            max="100"
                                                            value="30"
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="mt-4 pl-3 pb-3">
                                            <Row>
                                                <Col
                                                    md="8"
                                                    style={{
                                                        background: "rgba(255, 255, 255, 0.38)",
                                                        borderRadius: "6px",
                                                        padding: "8px",
                                                    }}
                                                >
                                                    <div className="stcaldiv">
                                                        <div>
                                                            <span className="fontSize12 Jakartanormal">
                                                                <img
                                                                    alt="Organization Name"
                                                                    src={
                                                                        require("assets/img/Vector15.svg").default
                                                                    }
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                            </span>
                                                            <span
                                                                className="fontSize12 Jakartanormal"
                                                                style={{ marginRight: "10px" }}
                                                            >
                                                                0 Guests
                                                            </span>
                                                        </div>

                                                        <div>
                                                            <span className="fontSize12 Jakartanormal">
                                                                <img
                                                                    alt="Organization Name"
                                                                    src={require("assets/img/Group.svg").default}
                                                                    style={{ marginRight: "10px" }}
                                                                />
                                                            </span>
                                                            <span
                                                                className="fontSize12 Jakartanormal"
                                                                style={{ marginRight: "10px" }}
                                                            >
                                                                0 Todos
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="emstore">
                                    <Button className="btn btn-secondary" type="submit">
                                        Save
                                    </Button>
                                    <p style={{ textAlign: "right", margin: "0 " }}>
                                        Japanese Wave
                                        <img
                                            alt="Organization Name"
                                            src={require("assets/img/paint-icon 1.svg").default}
                                            style={{ marginLeft: "10px" }}
                                        />
                                    </p>
                                </div>
                            </form>
                        </Col>
                        <Col lg={6} md={12}>
                            <StoryzMenu />
                            <div className="mt-5">
                                <div className="emenu">
                                    <div className="emenu1">
                                        <img
                                            alt="Organization Name"
                                            src={require("assets/img/sound.svg").default}
                                            style={{ marginRight: "10px" }}
                                        />
                                        <h3 className="mb-0 fontSize20 JakartaMedium300">
                                            Events in this Storyz
                                        </h3>
                                    </div>
                                    <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                                        <img
                                            alt="Organization Name"
                                            src={require("assets/img/tip.svg").default}
                                            style={{ marginRight: "10px" }}
                                        />
                                    </span>
                                    <ReactTooltip />
                                </div>
                                <hr style={{ marginTop: "1rem", marginBottom: "1.3em" }} />
                                <div>
                                    <Events />
                                    <div className="form-group">
                                        <input
                                            id="example2cols1Input"
                                            placeholder="type to search..."
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="todolistst"></div>
                                    <a href="#" className="newTodo">
                                        New Event
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default StoryzDashboardempty;
