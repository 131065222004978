import React, { useState } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";

const Loader = ({ ...args }) => {
  return (
    <Modal isOpen={true} {...args} className="loaderModal">
      <ModalBody className="d-flex align-items-center justify-content-center">
        <Row>
          <Col className="px-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="loaderAnim"></div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Loader;
