import styled from "styled-components";
export const Eventstyle = styled.article`
  .column1 {
    float: left;
    width: 230px;
    padding-bottom: 20px;
  }
  .column2 {
    float: left;
    width: calc(100% - 230px);
    border-left: 1px solid #e7e7e7;
  }
  .storycolumn1 {
    padding: 16px 16px 36px 16px;
    text-align: center;
  }
  .storycolumn1 .datastorimg {
    margin: 0 0 6px 0;
  }
  .storycolumn1 .eventdatea {
    margin: 0;
  }
  .text-decoration-underline {
    text-decoration: underline;
  }

  .eventdatea {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #49454f;
  }
  .column2sate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 117px; */
    padding: 24px 24px 12px 12px;
  }
  .column2sate .title {
    font-size: 24px;
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .column2sate .title .badge {
    padding: 2px 4px;
    color: #fdfdfd;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.6px;
    border-radius: 4px;
    background: #666;
    font-family: "Inter", sans-serif !important;
    text-transform: inherit;
    margin-bottom: 4px;
  }
  .imageventsla {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    /* margin-bottom: 12px; */
  }

  .imgMaskevente {
    position: relative;
    height: 200px;
    border: 1px solid #d7d7d7;
    cursor: pointer;
    background: #d7d7d7;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
  }
  .imagevent {
    border: 1px solid #979798;
    border-radius: 37px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .imageeventim {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .imageeventim2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  imageeventim234 span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #1d192b;
  }

  .imageeventim22 span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #1d192b;
  }
  .imageeventim22 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    width: 77px;
    height: 39px;
    background: rgba(207, 207, 207, 0.6);
    border-radius: 100px;

    color: #1d192b;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .imageeventim234 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;
    /* width: 77px; */
    width: fit-content;
    height: 39px;
    background: #fff;
    border-radius: 100px;
  }
  .RSVPDeadline .form-group {
    margin-bottom: 0px;
  }
  .RSVPDeadline .text-muted {
    color: #8898aa !important;
    text-align: end;
  }
  h4.text-center.ChangeTimezone {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .repeatingevent {
    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  #column_left {
    background-color: #515151;
  }

  .nav-list li a {
    text-decoration: none;
    display: block;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #515151 !important;
    color: #9d9d9d;
  }
  .forwith {
    width: 100%;
  }
  .nav-list > li > a {
    color: #c4c4c4;
    font-size: 14px;
    padding-left: 13px !important;
    border-bottom: 1px solid #585858;
  }

  .nav-list > li > a:hover {
    background-color: #444444;
  }
  .keepsakeBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;
    margin-bottom: 24px;
    .keepsakeIcon,
    .preview {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #49454f;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 8px;
      margin-left: 16px;
      gap: 4px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: auto;
        color: #666666;
        margin-bottom: 4px;
      }
    }
  }

  .displayOption {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 24px;
    padding: 24px 0 36px;
  }
  .keepsakeAlbumFormWrapper {
    .coverImgWrapper {
      width: 384px;
      padding: 12px;
      margin-bottom: 20px;
    }
    .coverImgHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
      .icon {
        max-width: 32px;
        max-height: 32px;
      }
      .form-label {
        margin-bottom: 0;
      }
    }
    .coverImgBox {
      width: 360px;
      height: 144px;
      border-radius: 12px;
      position: relative;
      cursor: pointer;
      background: #d7d7d7;
    }
    .coverImgBox .imgMask-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      opacity: 0.5;
    }
    .coverImg {
      width: 360px;
      height: 144px;
      border-radius: 12px;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
  }
  .textareaWrapper {
    max-width: 720px;
    width: 100%;
    margin-bottom: 12px;
  }
  textarea.form-control,
  .form-control {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
  }
  .form-label {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #666666;
    margin-bottom: 12px;
  }
  .form-check-inline {
  }
  .form-check {
  }
  .form-check-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #666666;
    margin-left: 4px;
    padding-top: 4px;
  }
  .label-inline {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
  }
  .scrollWrapper {
    max-height: 680px;
    overflow-y: auto;
    margin-bottom: 40px;
    max-width: 980px;
    padding-right: 28px;
  }
  .inputFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    cursor: pointer;
  }
  .keepsakeAlbumListWrapperOuter {
    .form-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #666666;
    }
    textarea.form-control,
    .form-control {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
    }
    .date {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.4px;
      margin-bottom: 24px;
      color: #000000;
    }
    .keepsakeAlbumListWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, 200px);
      gap: 24px;
      margin-bottom: 60px;
    }
    .keepsakeAlbumList {
      max-width: 220px;
    }
    .albumBlankCard {
      margin-bottom: 40px;
    }
    .topAction {
      padding-bottom: 4px;
      .icon {
        width: 22px;
        height: 22px;
      }
    }
    .disabledNew {
      opacity: 0.6;
    }
    .keepsakeAlbumListImgWrapper {
      background: #d7d7d7;
      border-radius: 6px;
      /* width: 220px; */
      width: 100%;
      height: 165px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      video {
        width: 100%;
      }
    }
    .plusIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    .listImg {
      width: 220px;
      height: 165px;
      object-fit: cover;
      border-radius: 6px;
    }
    .keepsakeAlbumListContent {
      background: rgba(207, 207, 207, 0.15);
      border-radius: 6px;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
      height: 96px;
    }
    .captionLabel {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #666666;
      margin-bottom: 12px;
    }
    .textareaBox {
      height: 56px;
      background: #fff;
      border: 1px solid #d7d7d7;
    }
  }
  .keepsakeUser {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
  }
  .keepsakeUser .keepsakeUserThum {
    margin-right: 8px;
    width: 48px;
    height: 48px;
  }
  .keepsakeUser .keepsakeUserThum .keepsakeUserThumImg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
  .keepsakeUser .keepsakeUserThumContent .keepsakeUserthumbTitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
  }
  .keepsakeUser .keepsakeUserThumContent .keepsakeUserthumbSubTitle {
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1f1f1f;
  }
`;
