import React, { useState, useEffect } from "react";
import { Eventstyle } from "../styles";
import Eventmenu from "../eventMenu";
import { GrCircleInformation } from "react-icons/gr";
import { MdDevices } from "react-icons/md";
import keepsakeAlbumIcon from "./../../../../src/assets/img/KeepsakeAlbum.svg";
import { Row, Col, Container, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import Filemanager from "../../CommonMediaModal/FilemanagerKeepsakeAlbum";
import KeepsakeGallery from "./KeepsakeGallery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserInfo from "./UserInfo";
import UserDetails from "./UserDetails";
import BrowseFileModal from "components/MediaModal/BrowseFileModal";
import ImportFromLocalModal from "components/MediaModal/ImportFromLocalModal";
import ImageUploadModal from "components/MediaModal/ImageUploadModal";
import { useSelector, useDispatch } from "react-redux";
import KeepsakeAlbumCoverImg from "./../../../../src/assets/img/cover_img.jpg";
import { FaChevronRight } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import {
  mediaList,
  mediaClear,
  eventKeepsake,
  removeeventKeepsake,
} from "../../../store/actions/index";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import BlankImages from "./blankImages";
import { useHistory } from "react-router-dom";
const KeepsakeAlbumList = ({ args, name }) => {
  const dispatch = useDispatch();
  const params = useParams();
  let history = useHistory();
  const common = useSelector((state) => {
    return state.eventkeepsakealbum.Eventkeepsakealbum;
  });

  const [allimage, setAllimage] = React.useState([]);
  const [allimage2, setAllimage2] = React.useState([]);
  const [modalShow21, setModalShow21] = React.useState(false);
  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [importFromLocal, setImportFromLocal] = useState(false);
  const [imgModalBox, setImgModalBox] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [item, setItem] = useState();

  const [selectedOption, setSelectedOption] = useState("byDate");
  const [keepsakeAlbumAll, setKeepsakeAlbumAll] = useState([]);
  const changeHandler = (e) => {
    setSelectedOption(e.target.value);
  };
  const [eventId, setEventId] = React.useState(params.eid ? params.eid : "");
  const [storyzId, setStoryzId] = React.useState(params.sid ? params.sid : "");
  const [storyzKeepsake, setstoryzKeepsake] = React.useState();
  const [storyzKeepsakeAll, setstoryzKeepsakeAll] = React.useState([]);


  const handleClick12 = () => {
    setModalShow21(false);
  };
  useEffect(() => {
    datakeepskeAlbum();
  }, [eventId, selectedOption]);

  function datakeepskeAlbum() {
    const dataa = {
      display_by: selectedOption,
      event_id: eventId,
      storyz_id: storyzId,
    };
    userGuestService.getKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            setKeepsakeAlbumAll(res.data[0].keepsake_album_photo);
            setstoryzKeepsake(res.data[0]);
            setstoryzKeepsakeAll(res.data);
            sessionStorage.setItem("keep_id", res.data[0].id);
            dispatch(eventKeepsake(res.data));
            setImgModalBox(false);
            setModalShow21(false);
          } else {
            dispatch(removeeventKeepsake());
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    CloseUploadImgModal();
    setModalShow21(false);
  }

  const imageUpdate = (e) => {

  };
  const seletedImageClike = (e) => {
    setAllimage2(e);
    //console.log( "KAE seletedImageClike", e);

    let imgJSON = JSON.parse(e.img_json);
    let mime_typeArr = e.mimetype.split("/");
    let mimeType = mime_typeArr;
    e["imaDatajson"] = imgJSON;
    e["mimetype"] = mimeType;
    delete e.img_json;
    // delete e.id;
    // delete e.user_id;
    // delete e.filename;
    // delete e.handle;

    // delete e.source;
    // delete e.url;
    // delete e.created_at;
    // delete e.updated_at;
    // delete e.img_address;
    // delete e.lat;
    // delete e.lng;
    let kaData = JSON.parse(JSON.stringify(e));
    kaData["storyz_id"] = params.sid;
    kaData["guest_user_id"] = 2;
    kaData["event_id"] = params.eid;
    kaData["location"] = "";
    kaData["let"] = "";
    kaData["long"] = "";
    kaData["description"] = "";
    kaData["type"] = "keepsakeAlbum";
    setAllimage2(kaData);

    //console.log( "KAE seletedImageClike", kaData);
    UploadImgModal();
    //AddBrowImgModal()
  };
  function getAllmedia() {
    userGuestService.getallmedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch(mediaList(res.data));
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const BrowseImgModal = () => {
    setBrowseImgModal(false);
  };
  const closepopus = () => {
    setModalShow(false);
    datakeepskeAlbum();
  };

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  const ImportFromLocal = () => {
    setImportFromLocal(true);
    setBrowseImgModal(false);
  };
  const UploadImgModal = () => {
    setImgModalBox(true);
  };
  const CloseUploadImgModal = () => {
    setImgModalBox(false);
  };
  const ImportFromLocalClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(true);
  };
  const PreviweModal = () => {
    datakeepskeAlbum();
    setPreviewModal(true);
  };

  const PreviweModalClose = () => {
    setPreviewModal(false);
  };

  const handleClick = (val) => {
    //console.log("ehandleClick", e);
    setSelectedOption(val);
    const dataa = {
      display_by: val,
      event_id: eventId,
      storyz_id: storyzId,
    };
    userGuestService.getKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            setKeepsakeAlbumAll(res.data[0].keepsake_album_photo);
            setstoryzKeepsake(res.data[0]);
            setstoryzKeepsakeAll(res.data);
            sessionStorage.setItem("keep_id", res.data[0].id);
            dispatch(eventKeepsake(res.data));
            setImgModalBox(false);
            setModalShow21(false);
          } else {
            dispatch(removeeventKeepsake());
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    CloseUploadImgModal();
    setModalShow21(false);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [trigger, setTrigger] = useState(0);

  const [allmedia, setAllmedia] = useState({
    imgUrl: "",
    imgdata: {},
    title: "",
    caption: "",
    location: "",
    mime_type: "",
    type: 0,
    page: "keepsakeAlbum",
    keep_id: keepsakeAlbumAll[0]?.id ? keepsakeAlbumAll[0]?.id : "",
  });

  const changeHandlermodel = (e) => {
    setAllmedia([...allmedia, { ...e }]);
  };

  const openBrowImgModal = () => {
    // setModalShow(true);
    // setTrigger(1);
    // dispatch(mediaClear());
    // getAllmedia();
    // setModalShow21(true);
    setModalShow21(true);
  };

  const AddBrowImgModal = () => {

    if (storyzKeepsakeAll.length === 0) {
      // array does not exist or is empty
      toast.error("Plsese Add Storyz Keepsake first", "Error");
    } else {
      setModalShow(true);
      setTrigger(1);
      dispatch(mediaClear());
      getAllmedia();
      setModalShow21(true);
    }
  };

  const handleClickUserInfo = () => {
    datakeepskeAlbum();
  };

  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {name ? name : ""}
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234">
                <span>Keepsake Album Uploads</span>
              </div>
              <GrCircleInformation className="ml-3 pointer infoIcon" />
            </div>
          </div>
          <div className="border-bottom eventBannerPara">
            This view shows you all the files that guests have uploaded from the
            event. You can add additional information to their uploads.
            <br />
            To organize uploads into an album, go to the Storyz view
          </div>
          <Container>
            <Row>
              <Col>
                <div className="keepsakeBox">
                  <div
                    className="keepsakeIcon"
                    onClick={() => {
                      history.push(
                        `/admin/storyz-keepsake-album/${params.sid}`
                      );
                    }}
                  >
                    <img src={keepsakeAlbumIcon} alt="" className="icon" />
                    <div>Storyz Keepsake </div>
                  </div>
                  <div className="preview" onClick={PreviweModal}>
                    <MdDevices className="icon" />
                    <div>Preview</div>
                  </div>
                  <Modal
                    isOpen={previewModal}
                    toggle={PreviweModalClose}
                    {...args}
                    className="keepsakeAlbumModal"
                  >
                    <ModalHeader
                      toggle={() => setPreviewModal(false)}
                    ></ModalHeader>
                    <ModalBody>
                      {selectedOption == "byDate" ? (
                        <>
                          <div className="coverImgWrapper">
                            <span className="title">
                              {storyzKeepsake?.tittle}
                            </span>
                            <img
                              src={storyzKeepsake?.cover_image}
                              alt=""
                              className="coverImg"
                            />
                          </div>
                          <div className="keepsakeAlbumContent">
                            {storyzKeepsake?.description}
                          </div>
                          <div className="keepsakeAlbumAction">
                            <div className="actionWrapper">
                              <button
                                className="keepsakeAlbumBtn active"
                                onClick={() => handleClick("byDate")}
                              >
                                Date
                              </button>
                              <button
                                className="keepsakeAlbumBtn"
                                onClick={() => handleClick("Guest")}
                                data-id="1"
                              >
                                Guest
                              </button>
                            </div>
                          </div>
                          <div className="keepsakeAlbumMediaWrapper">
                            {common[0]?.keepsake_album_photo.map((x, i) => (
                              <div key={i}>
                                <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                                  <div className="date">{x.date}</div>
                                  <FaChevronRight size={24} className="icon" />
                                </div>
                                <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                                  {x.images.map((img, g) => (
                                    <div className="sliderList" key={g}>
                                      {img.mime_type === "video" ? (
                                        <>
                                          <video
                                            width="400"
                                            controls
                                            className="videoHeight"
                                          >
                                            <source
                                              src={img.photo_url}
                                              type="video/mp4"
                                            />
                                            Your browser does not support HTML
                                            video.
                                          </video>
                                        </>
                                      ) : (
                                        <img
                                          src={img.photo_url}
                                          alt=""
                                          className="sliderImg"
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="coverImgWrapper">
                            <span className="title">
                              {storyzKeepsake?.tittle}
                            </span>
                            <img
                              src={storyzKeepsake?.cover_image}
                              alt=""
                              className="coverImg"
                            />
                          </div>
                          <div className="keepsakeAlbumContent">
                            {storyzKeepsake?.description}
                          </div>
                          <div className="keepsakeAlbumAction">
                            <div className="actionWrapper">
                              <button
                                className="keepsakeAlbumBtn"
                                onClick={() => handleClick("byDate")}
                              >
                                Date
                              </button>
                              <button
                                className="keepsakeAlbumBtn active"
                                onClick={() => handleClick("Guest")}
                                data-id="1"
                              >
                                Guest
                              </button>
                            </div>
                          </div>
                          <div className="keepsakeAlbumMediaWrapper">
                            {common[0]?.keepsake_album_photo.map((x, i) => (
                              <div key={i}>
                                <div className="head d-flex align-items-center justify-content-between mx-3 pb-3">
                                  <div className="date">
                                    {/* Rohit-Gust-BY */}
                                    <img
                                      src={x.guestProfile}
                                      alt="Girl in a jacket"
                                      width="21"
                                      height="21"
                                    />
                                    {/* &nbsp; */}
                                    {x.name}
                                  </div>
                                  <FaChevronRight size={24} className="icon" />
                                </div>
                                <div className="keepsakeAlbumSliderWrapper d-flex align-items-center">
                                  {x.images.map((img, g) => (
                                    <div className="sliderList" key={g}>
                                      {img.mime_type === "video" ? (
                                        <>
                                          <video
                                            width="400"
                                            controls
                                            className="videoHeight"
                                          >
                                            <source
                                              src={img.photo_url}
                                              type="video/mp4"
                                            />
                                            Your browser does not support HTML
                                            video.
                                          </video>
                                        </>
                                      ) : (
                                        <img
                                          src={img.photo_url}
                                          alt=""
                                          className="sliderImg"
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {selectedOption === "byDate" && (
                  <UserInfo
                    data={storyzKeepsake}
                    onClick1={handleClickUserInfo}
                    storyzKeepsakeAllArr={storyzKeepsakeAll}
                  />
                )}

                <Modal
                  isOpen={imgModalBox}
                  toggle={UploadImgModal}
                  {...args}
                  className="mediaModalWrapper1"
                >
                  <ImageUploadModal
                    closeModal={CloseUploadImgModal}
                    getAllstate={allimage2}
                    datakeepskeAlbum={datakeepskeAlbum}
                    setImgModalBox={setImgModalBox}
                    openBrowImgModal={openBrowImgModal}
                    storyz_id={params.sid}
                    event_id={params.eid}
                  //onHide={handleClick12}
                  />
                </Modal>

                <div className="displayOption">
                  <div className="form-check-inline label-inline mr-3">
                    Display Options:
                  </div>
                  <div className="form-check form-check-inline mr-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="displayOption"
                      value="byDate"
                      id="byDate"
                      checked={selectedOption === "byDate"}
                      onChange={changeHandler}
                    />
                    <label
                      className="form-check-label pointer"
                      htmlFor="byDate"
                    >
                      By Date
                    </label>
                  </div>
                  <div className="form-check form-check-inline ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="displayOption"
                      value="Guest"
                      id="Guest"
                      checked={selectedOption === "Guest"}
                      onChange={changeHandler}
                    />
                    <label className="form-check-label pointer" htmlFor="Guest">
                      By Guest
                    </label>
                  </div>
                </div>
                <div className="keepsakeAlbumListWrapperOuter">
                  <div className="scrollWrapper">
                    {selectedOption === "byDate" && (
                      <>
                        <BlankImages AddBrowImgModal={AddBrowImgModal} />

                        {common[0]?.keepsake_album_photo.map((x, i) => (
                          <>
                            <div className="date" key={i}>
                              {x.date}
                            </div>
                            <KeepsakeGallery
                              data={x.images}
                              onData={selectedOption}
                              handleClickUserInfo={handleClickUserInfo}
                            />
                          </>
                        ))}
                      </>
                    )}

                    {selectedOption === "Guest" && (
                      <>
                        <BlankImages AddBrowImgModal={AddBrowImgModal} />
                        {common[0]?.keepsake_album_photo.map((x, i) => (
                          <div key={i}>
                            <UserDetails
                              name={x?.name}
                              role={x.role}
                              guestProfile={x.guestProfile}
                              admin_guest_note={x.admin_guest_note}
                              AddBrowImgModal={AddBrowImgModal}
                            />
                            <KeepsakeGallery
                              AddBrowImgModal={AddBrowImgModal}
                              data={x.images}
                              onData={selectedOption}
                              handleClickUserInfo={handleClickUserInfo}
                            />
                          </div>
                        ))}
                      </>
                    )}
                    {modalShow21 && (
                      <Filemanager
                        show={modalShow21}
                        onHide={handleClick12}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        setAllimage2={setAllimage2}
                        seletedImage={seletedImageClike}
                        setImgModalBox={setImgModalBox}
                        storyz_id={params.sid}
                        event_id={params.eid}
                        datakeepskeAlbum={datakeepskeAlbum}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
};

export default KeepsakeAlbumList;
