import React, { useEffect, useState, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import searchIcon from "../../assets/img/search-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { userGuestService } from "_services/masters/userGuest.service";
import { toast } from "react-toastify";
import { Modal, Row, Col, ModalBody, ModalFooter, Button } from "reactstrap";
import QuestionImg from "../../assets/img/circled-icon.svg";
import placeholde from "../../assets/img/placeholder.jpg";
import { createPortal } from "react-dom";
import DropdownRole from "../Dropdown/DropdownRole";
import DropdownGuest from "../Dropdown/DropdownGuest";
const StoryzHonoredGuestListDropdown = ({
  evdat,
  guestListOption,
  selected,
  user_id,
  type,
  searchAPI,
  placeholder,
  eid,
  addUserBtn,
  onChange,
  evsingledat,
  gid,
}) => {
  let arrData = guestListOption ? guestListOption : [];
  const [addRolepopus, setAddRolepopus] = useState(false);
  const [arrayOfObjects, setArrayOfObjects] = useState([]);
  const [rolsList, setRoleList] = useState({});
  const [eventdata, setEventdata] = useState();
  let [showInfo1, setShowInfo1] = useState(false);
  const [error, setError] = useState(false);
  const [userguestList, setUserguestList] = React.useState([]);
  const [selectMenu, setSelectMenu] = useState(false);
  const [inputValue, setInputValue] = useState();
  const selectMenuRef = useRef();
  const selectMenuList = useRef();
  const buttonHeight = useRef();

  const [property, setProperty] = useState({
    top: 0,
    left: 0,
  });
  const [isthe, setIsthe] = useState("");
  const [forthe, setForthe] = useState("");
  const [roleItems, setRoleItems] = useState(selected ? selected : []);
  const [roleItemsid, setRoleItemsid] = useState([]);
  const [status, setStatus] = useState(0);
  const [more, setMore] = useState(false);
  const [addListModal, setAddListModal] = useState(false);
  const [defaultModale, setDefaultModale] = useState(false);
  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
    const rect = selectMenuRef.current.getBoundingClientRect();
    const buttonHeightBox = buttonHeight.current.getBoundingClientRect();


    setProperty({
      top: rect.top + window.scrollY + buttonHeightBox.height,
      left: rect.left + window.scrollX,
    });
  };

  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuList?.current?.contains(e.target)) {
        setSelectMenu(false);
        setInputValue("");
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const handleClick = () => {
    setDefaultModale(true);
    setAddListModal(!addListModal);
  };
  const [clickedArray, setClickedArray] = useState([]);
  const onAdd = (product) => {
    const index = roleItems.findIndex((item) => item.id === product.id);
    if (index > -1) {
    } else {
      setRoleItems([...roleItems, { ...product }]);
      setStatus(1);
    }
  };
  const [fav, setFav] = useState([]);
  useEffect(() => {
    var arr = [];
    let ids = roleItems.map((track) => arr.push(track.id));
    onChange(arr);

    let obj = {};
    obj["user_id"] = user_id;
    obj["type"] = type;
    obj["drop_down_data"] = roleItems;
    //console.log('roleItems onAdd --> ', obj);
    if (status !== 0) {
      userGuestService.updateDropDown(obj).then(
        (res) => {
          if (res.status == "success" && status == 1) {
            //setCount(200)
            toast.success("updated successfully");
            setStatus(0);
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.message);
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [status]);

  const iclose = (e) => {
    const newPeople = roleItems.filter((person) => person.id !== e.id);
    setRoleItems(newPeople);
  };

  const searchData = (Sval) => {
    searchAPI(Sval, type, user_id);
  };
  useEffect(() => {
    setArrayOfObjects(guestListOption);
    roleall();
    userGuestService.userGuestList(eid).then((res) => {
      if (res.status === "success") {
        setUserguestList(res.data);
      } else {
        setUserguestList();
      }
    });
  }, [guestListOption]);

  useEffect(() => {
    setEventdata(evdat);
  }, [evdat]);
  const [eventType, setEventType] = React.useState([]);
  const AddRoleModal = (event) => {
    setAddRolepopus(!addRolepopus);
  };
  function roleall() {
    userGuestService.rolelist().then(
      (res) => {
        if (res.status === "success") {
          setEventType(res.data);
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }
  const AddRoleModalid = () => {
    if (!isthe.id) {
      setError(true);
      return null;
    } else {
      const newObject = {
        relation_id: isthe.id ? isthe.id : "", // this values will come from your form
        guest_relation_id: forthe.id ? forthe.id : "",
        title: isthe.title ? isthe.title : "", // depending on your implementation you may have to maintain separate state for each property
      };

      const index1 = arrayOfObjects.findIndex(
        (item) => item.relation_id === isthe.id
      );

      if (index1 > -1) {
      } else {
        const newState = [...arrayOfObjects, newObject];
        setArrayOfObjects(newState);

        let obj = {};
        obj["user_id"] = gid;
        obj["type"] = "guest_relation";
        obj["event_id"] = eid;

        obj["drop_down_data"] = newState;

        userGuestService.updateDropDown(obj).then(
          (res) => {
            if (res.status == "success") {
              toast.success("updated successfully");
            } else {
              console.log("Something went wrong !!");
              //toast.error(res.message);
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            //toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }
      // const index = fruits.findIndex((item) => item.id === isthe.id);
      // if (index > -1) {

      // } else {
      //   setFruits([...fruits, isthe])
      //   setFruitsid([...fruitsid, isthe.id])

      // }

      setAddRolepopus(!addRolepopus);
    }
    setIsthe({});
    setForthe({});
    setError(false);
  };
  const [deleteHonorList, setDeleteHonorList] = useState(false);
  const [roleDelete, setRoleDelete] = React.useState([]);
  const handelDeleteHonorList = (event) => {
    event.stopPropagation();
    setDeleteHonorList(!deleteHonorList);
  };

  const deleteGuestfromHonoredList = () => {
    var data = {
      guest_id: gid,
      relation_id: roleDelete.relation_id,
      event_id: eid,
    };

    userGuestService.removeguestrole(data).then((res) => {
      if (res.status === "success") {
        // roleall();
        setDeleteHonorList(!deleteHonorList);
        toast.success("Delete successfully");
        const newPeo = arrayOfObjects.filter(
          (person) => person.relation_id !== roleDelete.relation_id
        );
        setArrayOfObjects(newPeo);
      } else {
        console.log("error");
      }
    });
  };

  return (
    <>
      <div
        className="dropdownBoxWrapperSearch selectSearchDropdown"
        ref={selectMenuRef}
        onClick={AddRoleModal}
      >
        <div
          className="dropdownTriggered"
          onClick={dropdownHandle}
          ref={buttonHeight}
        >
          <div className="dropdownPlaceholder w-100">
            <div className="form-control pointer d-flex align-items-center flex-wrap">
              {!more &&
                arrayOfObjects.slice(0, 6).map((e, i) => {
                  return (
                    <>
                      <span
                        className="inviteGuestList"
                        key={i}
                        onClick={(event) => {
                          handelDeleteHonorList(event);
                          setRoleDelete(e);
                        }}
                      >
                        {`${e.title}`}
                        <IoMdClose className="ml-2 pointer" size={16} />
                      </span>
                    </>
                  );
                })}
              {more &&
                arrayOfObjects.map((e, i) => {
                  return (
                    <>
                      <span
                        className="inviteGuestList"
                        key={i}
                        onClick={(event) => {
                          handelDeleteHonorList(event);
                          setRoleDelete(e);
                        }}
                      >
                        {`${e.title}`}
                        <IoMdClose className="ml-2 pointer" size={16} />
                      </span>
                    </>
                  );
                })}

              {arrayOfObjects.length > 6 ? (
                <span className="more mx-1" onClick={() => setMore(!more)}>
                  {more ? "less..." : "more..."}
                </span>
              ) : (
                ""
              )}

              <span
                className="align-items-center formBoxInput d-inline-flex pr-2"
                onClick={AddRoleModal}
              >
                <img src={searchIcon} alt="" className="mr-2" />
                {placeholder}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={addRolepopus}
        toggle={AddRoleModal}
        className="honoredRelationshipModal"
      >
        <ModalBody>
          <div className="HonoredGuestGroupHeader border-bottom">
            <img src={QuestionImg} alt="" className="icon" />
            <div className="head">
              Add an Honor and Relationship for this Guest
            </div>
          </div>
          <Row>
            <Col>
              {eventdata ? (
                <div className="deleteGuestGroupThumWrapper">
                  <div className="deleteGuestGroupThum">
                    <img
                      src={eventdata.imageUrl ? eventdata.imageUrl : placeholde}
                      alt=""
                      className="deleteGuestGroupThumImg"
                    />
                  </div>
                  <div className="deleteGuestGroupThumContent">
                    <div className="deleteGuestGroupthumbTitle">
                      {eventdata?.first_name + " " + eventdata?.last_name}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="honorRelationshipFormWrapper">
                <div className="form-group">
                  <label htmlFor="isthe" className="control-label">
                    is the
                  </label>

                  <DropdownRole
                    isSearchable
                    handleClick={handleClick}
                    placeHolder="Select..."
                    options={eventType}
                    onChange={(value) => {
                      setIsthe(value);
                    }}
                    onClickOutside={showInfo1}
                    addButton={true}
                    customClass={"honorDropdown"}
                  />
                  {error ? (
                    <span style={{ color: "red" }}>this is required</span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="thefor" className="control-label">
                    for the
                  </label>

                  <DropdownGuest
                    isSearchable
                    handleClick={handleClick}
                    placeHolder="Select..."
                    options={userguestList}
                    onChange={(value) => {
                      setForthe(value);
                    }}
                    onClickOutside={showInfo1}
                    addButton={false}
                    customClass={"honorDropdown"}
                  />
                </div>
                {evsingledat ? (
                  <>
                    <div className="form-group">
                      <label htmlFor="regular1" className="control-label">
                        at the
                      </label>
                      <div className="repeateventDetails">
                        <div>
                          <img
                            className="evimages"
                            src={
                              evsingledat?.cover_image
                                ? evsingledat?.cover_image
                                : placeholde
                            }
                            alt=""
                          />
                        </div>
                        <div className="repeateventTitle">
                          <div>{evsingledat?.event_name}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div
            color="flex-grow-1 "
            className="btn btn-primary pinkBtn primary pinkBtn flex-grow-1"
            onClick={AddRoleModalid}
          >
            Add Honor and Relationship
          </div>
          <div className="btn btn-default cancelBtn" onClick={AddRoleModal}>
            Cancel
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteHonorList}
        toggle={handelDeleteHonorList}
        className="removeGuestFromHonoredModal"
      >
        <ModalBody>
          <div className="modal-header border-bottom">
            <img
              alt="..."
              src={require("assets/img/Icon-Text-in-Circle.svg").default}
              width="75px"
              height="75px"
              className="icon"
            />
            <div className="head">Remove Guest From Honored List?</div>
          </div>
          <Row>
            <Col>
              {evsingledat ? (
                <>
                  <div className="repeateventDetails">
                    {/* <div className="repeateeventDate date pr-2">14 Aug</div> */}
                    <div>
                      <img
                        className="evimages"
                        src={
                          evsingledat?.cover_image
                            ? evsingledat?.cover_image
                            : placeholde
                        }
                        alt=""
                      />
                    </div>
                    <div className="repeateventTitle">
                      <div>{evsingledat?.event_name}</div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {eventdata ? (
                <div className="deleteGuestGroupThumWrapper">
                  <div className="deleteGuestGroupThum">
                    <img
                      src={eventdata.imageUrl ? eventdata.imageUrl : placeholde}
                      alt=""
                      className="deleteGuestGroupThumImg "
                    />
                  </div>
                  <div className="deleteGuestGroupThumContent">
                    <div className="deleteGuestGroupthumbTitle">
                      {eventdata?.first_name + " " + eventdata?.last_name}
                    </div>
                    {/* <span className="adminBadge">ADMIN</span> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="removeGuestHonoreContent">
                <p>You have removed all honors for this guest.</p>
                <p>
                  They will be deleted from the Honored Guest List, unless you
                  keep at least 1 honor.
                </p>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary pinkBtn"
            className="flex-grow-1"
            onClick={deleteGuestfromHonoredList}
          >
            Delete Guest from Honored List
          </Button>
          <Button
            className="btn-default cancelBtn"
            onClick={handelDeleteHonorList}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default StoryzHonoredGuestListDropdown;
