import Account from "views/pages/Account.js";
import Setting from "views/pages/Setting.js";
import Organization from "views/pages/Organization.js";
import storyzDashboard from "views/pages/storyzDashboard.js";
import storyzDashboardempty from "views/pages/storyzDashboardempty.js";
import OrganizationDiy from "views/pages/OrganizationDiy.js";
import OrganizationLocator from "views/pages/OrganizationLocator.js";
import OrganizationSelf from "views/pages/OrganizationSelf.js";
import Reports from "views/pages/Reports.js";
import Accountownerview from "views/pages/Accountownerview.js";
import Selfaccountview from "views/pages/Selfaccountview.js";
import Notifications from "views/pages/Notifications.js";
import Invite from "views/pages/Invite.js";
import Eventspopup from "views/pages/Eventspopup.js";
import Billingpayment from "views/pages/Billingpayment.js";
import Globalguest from "views/pages/Globalguest.js";
import Todos from "views/pages/Todos.js";
import storyzAbout from "views/pages/storyzAbout.js";
import eventAbout from "views/pages/eventAbout.js";
import storyzAboutEdit from "views/pages/storyzAboutEdit.js";
import Eventdashboard from "views/pages/Eventdashboard.js";
import Mediagallery from "views/pages/Mediagallery.js";
import Eventmediagallery from "views/pages/Eventmediagallery";
//import Crop from 'views/pages/Crop.js'
import Calendar from "views/pages/Calendar.js";
import storyzCalendar from "views/pages/storyzCalendar.js";
import Timeline from "views/pages/timeline";
import Upgradeservice from "views/pages/Upgradeservice.js";
import Filestack from "views/pages/Filestack";
import Todoimg from "./assets/img/todo1.svg";
import Massageimg from "./assets/img/icons/massages.svg";
import Notificationsimg from "./assets/img/icons/notification.svg";
import Settingimg from "./assets/img/icons/Setting.svg";
import Organizationimg from "./assets/img/icons/organization.svg";
import Storyzimg from "./assets/img/icons/storyz.svg";
import Eventsimg from "./assets/img/icons/events.svg";
import Reportsimg from "./assets/img/icons/reports.svg";
import Upgradeimg from "./assets/img/icons/upgrade.svg";
import Clientsimg from "./assets/img/peoplecameos.svg";
import Temeimg from "./assets/img/temeimg.svg";
import Vendorimg from "./assets/img/vendor-white-512.svg";
import Shoppingimg from "./assets/img/shopping_cart_black_24dp 1.svg";
const routes = [
  {
    path: "/massage",
    name: "Messages",
    img: Massageimg,
    component: Account,
    layout: "/admin",
    countnumber: "3",
  },

  {
    path: "/notifications",
    name: "Notifications",
    img: Notificationsimg,
    component: Notifications,
    layout: "/admin",
    countnumber: "3",
  },
  {
    path: "/media-gallery/:sid",
    component: Mediagallery,
    layout: "/admin",
  },
  {
    path: "/event-media-gallery/:id",
    component: Eventmediagallery,
    layout: "/admin",
  },
  {
    path: "/event-media-gallery",
    component: Eventmediagallery,
    layout: "/admin",
  },
  // {
  //   path: "/crop/:id",
  //   component: Crop,
  //   layout: "/admin",
  // },
  {
    path: "/calendar",
    component: Calendar,
    layout: "/admin",
  },

  {
    path: "/storyzCalendar/:sid",
    component: storyzCalendar,
    layout: "/admin",
  },


  {
    path: "/time-line",
    component: Timeline,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Setting",
    img: Settingimg,
    component: Setting,
    layout: "/admin",
    countnumber: "3",
  },
  {
    collapse: true,
    name: "Organization",
    img: Organizationimg,
    state: "dashboardsCollapse",
    views: [
      {
        path: "/organization",
        name: "Organization",
        miniName: "O",
        component: Organization,
        layout: "/admin",
      },
      {
        path: "/organization-diy",
        name: "Organization DIY",
        miniName: "D",
        component: OrganizationDiy,
        layout: "/admin",
      },
      {
        path: "/organization-locator",
        name: "Organization Locator",
        miniName: "L",
        component: OrganizationLocator,
        layout: "/admin",
      },
      {
        path: "/organization-self-account",
        name: "Organization Self Account",
        miniName: "L",
        component: OrganizationSelf,
        layout: "/admin",
      },
      {
        path: "/invite",
        name: "Invite",
        miniName: "I",
        component: Invite,
        layout: "/admin",
      },
      {
        path: "/global-guest-pool",
        name: "Global Guest Pool",
        miniName: "I",
        component: Globalguest,
        layout: "/admin",
      },
      {
        path: "/todos",
        name: "Todos",
        miniName: "I",
        component: Todos,
        layout: "/admin",
      },
      {
        path: "/event-dashboard/:sid/:eid",
        name: "Event Dashboard",
        miniName: "ED",
        component: Eventdashboard,
        layout: "/admin",
      },
      {
        path: "/event-dashboard",
        name: "Event Dashboard",
        miniName: "ED",
        component: Eventdashboard,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/storyz/:id",
    component: storyzDashboard,
    layout: "/admin",
  },

  {
    path: "/storyz",
    name: "Storyz",
    img: Storyzimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: storyzDashboard,
    layout: "/admin",
  },
  {
    path: "/storyz-about/:id",
    name: "",
    img: "",
    icon: "",
    component: storyzAboutEdit,
    layout: "/admin",
  },
  {
    path: "/storyz-about",
    name: "",
    img: "",
    icon: "",
    component: storyzAbout,
    layout: "/admin",
  },

  {
    path: "/event-about",
    name: "",
    img: "",
    icon: "",
    component: eventAbout,
    layout: "/admin",
  },
  {
    path: "/event-about/:id",
    name: "",
    img: "",
    icon: "",
    component: eventAbout,
    layout: "/admin",
  },
  {
    path: "/storyz-empty/:id",
    name: "",
    img: "",
    icon: "",
    component: storyzDashboardempty,
    layout: "/admin",
  },
  {
    path: "/storyz-empty",
    name: "",
    img: "",
    icon: "",
    component: storyzDashboardempty,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    img: Eventsimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Eventspopup,
    layout: "/admin",
  },

  {
    path: "/todos",
    name: "Todos",
    img: Todoimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Eventspopup,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    img: Reportsimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clients",
    img: Clientsimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/team",
    name: "Team",
    img: Temeimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/vendors",
    name: "Vendors",
    img: Vendorimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/store",
    name: "Store",
    img: Shoppingimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Reports,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "Account",
    img: Massageimg,
    state: "AccountsCollapse",
    views: [
      {
        path: "/account-information",
        name: "Account Information",
        miniName: "AI",
        component: Accountownerview,
        layout: "/admin",
      },
      {
        path: "/account-owner-view",
        name: "Self or Account Owner View",
        miniName: "S",
        component: Account,
        layout: "/admin",
      },
      {
        path: "/self-account-view",
        name: "Self Account View",
        miniName: "sa",
        component: Selfaccountview,
        layout: "/admin",
      },
      {
        path: "/billing-account-view",
        name: "Billing and Payment",
        miniName: "B",
        component: Billingpayment,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/upgrade-service",
    name: "Upgrade",
    img: Upgradeimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Upgradeservice,
    layout: "/admin",
  },

  {
    path: "/filestack",
    name: "Filestack",
    img: Upgradeimg,
    icon: "ni ni-calendar-grid-58 text-red",
    component: Filestack,
    layout: "/admin",
  },

  // {
  //   path: "/logout",
  //   name: "Log Out",
  //   img: Logoutimg,
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Logout,
  //   layout: "/admin"
  // },
];

export default routes;
