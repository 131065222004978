import { STORY_LIST, CLEAR_STORY_LIST } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    storysList: [],
};
function eventListReducers(state = initialState, action) {
    switch (action.type) {
        case STORY_LIST:
            return {
                ...state,
                isConfirmatiom: true,
                storysList: action.payload,
            };
        case CLEAR_STORY_LIST:
            // console.log(action.product_Id);
            // const newPeople = state.cart.filter(item => item.id !== action.product_Id);
            //console.log('remove');
            return {
                storysList: [],
            };
        //return { ...state, event: [...action.payload] };
        default:
            return state;
    }
}
export default eventListReducers;
