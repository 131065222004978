import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SocialMediaLinkIcon from "./../../assets/img/socialMediaLinkIcon.svg";

import InstagramIcon from "./../../assets/img/instagram-icon.svg";
import TiktokIcon from "./../../assets/img/tiktok-icon.svg";
import TwitterIcon from "./../../assets/img/twitter-icon.svg";
import FacebookIcon from "./../../assets/img/facebook-icon.svg";
import YoutubeIcon from "./../../assets/img/youtube-icon.svg";
import VimeoIcon from "./../../assets/img/vimeo-icon.svg";
import CloseCircle from "./../../assets/img/close-circle.svg";
import DummyImg from "../../assets/img/dummyImg.svg";
import { FaPlus } from "react-icons/fa";
import moment from "moment";
function Socialmedialist({
  args,
  add,
  socialmediadata,
  img,
  role,
  date,
  data,
  gid,
}) {
  const [socialMediaLink, setSocialMediaLink] = useState(false);
  const [createSocialMediaLink, setCreateSocialMediaLink] = useState(false);
  const [arrayOfObjects, setArrayOfObjects] = useState([]);
  const [dataFirstbox, setDataFirstbox] = useState([]);

  useEffect(() => {
    setDataFirstbox(socialmediadata);
  }, [socialmediadata]);
  const iconhandleClick = (event) => {

    const index1 = arrayOfObjects.findIndex((item) => item.name === event);
    if (index1 > -1) {
    } else {
      const newObject = {
        name: event, // this values will come from your form
        value: "", // depending on your implementation you may have to maintain separate state for each property
      };
      const newState = [...arrayOfObjects, newObject];
      setArrayOfObjects(newState);
    }
  };

  const handleSocialMediaLink = () => {
    setSocialMediaLink(!socialMediaLink);
  };

  const handleCreateSocialMediaLink = () => {

    setArrayOfObjects(dataFirstbox);
    setCreateSocialMediaLink(!createSocialMediaLink);
    //setSocialMediaLink(false)
  };
  const handleCreateSocialMediaLinkClose = () => {
    setCreateSocialMediaLink(false);
    setSocialMediaLink(true);
  };

  // const divIcon = () => {
  //   setClosCircle(false);
  // };

  // const [inputVal, setInputValue] = useState();

  // function handleChange(event) {

  //   const { name, value } = event.target;
  //   setInputValue({ ...inputVal, [name]: value });

  // }

  // function handleRemove(id) {
  //   const newList = socialMe.filter((item) => item.key !== id);

  //   setSocialMe(newList);
  // }

  const handleSocialMediaadd = () => {

    handleSocialMediaLink(false);
    add(dataFirstbox, gid);
  };

  const handler = ({ target: { name, value } }) => {

    setArrayOfObjects(
      arrayOfObjects.map((item) => {
        if (item.name === name) {
          return { ...item, value };
        } else {
          return item;
        }
      })
    );
  };

  function removeIcon(val) {
    const newPeo = arrayOfObjects.filter((person) => person.name !== val);
    setArrayOfObjects(newPeo);
  }

  const handlingEvents = (val) => {
    const newPeo = dataFirstbox.filter((person) => person.name !== val);
    setDataFirstbox(newPeo);
  };

  const handleSubmit = () => {
    setDataFirstbox(arrayOfObjects);
    setCreateSocialMediaLink(false);
  };


  return (
    <>
      {/* <div> */}

      {/* <div className="cardBox d-flex align-items-center"> */}
      <button
        type="button"
        className="editSocialBtn"
        onClick={handleSocialMediaLink}
      >
        Edit Social
      </button>
      <Modal
        isOpen={socialMediaLink}
        toggle={handleSocialMediaLink}
        {...args}
        className="socialMediaLinksModal"
      >
        <ModalBody className="pb-0">
          <div className="modalHeader border-bottom mb-4 pb-3 flex-row">
            <img src={SocialMediaLinkIcon} alt="..." className="icon mb-0 mr-3" />
            <div className="head">Social Media Links</div>
          </div>
          <Row>
            <Col>
              <div className="exportGuestThumWrapper mb-2">
                <div className="date mr-2">
                  {date != null ? moment(new Date(date)).format("MMM DD") : ""}
                </div>
                <div className="exportGuestThum">
                  <img
                    src={data.imageUrl ? data.imageUrl : DummyImg}
                    alt="..."
                    className="exportGuestThumImg"
                  />
                </div>
                <div className="exportGuestThumContent">
                  <div className="exportGuestthumbTitle">
                    {data.first_name} {data.last_name}
                  </div>
                  <div className="exportGuestthumbSubTitle">Role in Event</div>
                </div>
              </div>
              <div>
                <button
                  className="AddGestUser mb-3"
                  onClick={handleCreateSocialMediaLink}
                >
                  Add <FaPlus className="icon" />
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {dataFirstbox?.length > 0 &&
                dataFirstbox?.map((d2) => (
                  <div key={Math.random()}>
                    {d2.name === "facebook" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">Facebook</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={FacebookIcon}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="facebook"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("facebook")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {d2.name === "instagram" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">Instagram</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={InstagramIcon}
                            size={48}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="Instagram"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("instagram")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {d2.name === "twitter" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">Twitter</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={TwitterIcon}
                            size={48}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="Twitter"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("twitter")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {d2.name === "tiktok" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">TikTok</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={TiktokIcon}
                            size={48}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="TikTok"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("tiktok")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {d2.name === "youtube" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">YouTube</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={YoutubeIcon}
                            size={48}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="youtube"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("youtube")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {d2.name === "vimeo" ? (
                      <div className="socialMediaLinkList">
                        <label className="form-label mb-2">Vimeo</label>
                        <div className="socialMediaLinkInput d-flex align-items-center">
                          <img
                            src={VimeoIcon}
                            size={48}
                            alt="..."
                            className="socialIcon mr-3"
                          />
                          <input
                            type="text"
                            name="Vimeo"
                            value={d2.value}
                            className="form-control"
                            disabled
                          />
                          <img
                            src={CloseCircle}
                            alt="..."
                            className="CloseIcon ml-3"
                            onClick={() => handlingEvents("vimeo")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="pt-3 d-flex align-center justify-space-between">
          <a
            type="button"
            className="flex-grow-1 pinkBtn btn btn-primary mx-w"
            onClick={handleSocialMediaadd}
          >
            Save Social Media Links
          </a>
          <a
            className="btn btn-default cancelBtn  flex-grow-1 mx-w"
            onClick={() => {
              handleSocialMediaLink(false);
              // setInputValue({});
              // setAllValues({});
              // setSocialMe();
            }}
          >
            Cancel
          </a>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={createSocialMediaLink}
        toggle={handleCreateSocialMediaLink}
        {...args}
        className="socialMediaLinksModal"
      >
        <form className="form-center">
          <ModalBody className="pb-0">
            <div className="modalHeader border-bottom mb-4 pb-3 flex-row">
              <img
                src={SocialMediaLinkIcon}
                alt="..."
                className="icon mb-0 mr-3"
              />
              <div className="head">Create New Social Media LInk</div>
            </div>
            <Row>
              <Col>
                <div>
                  <div className="mb-4 chooseSocailHead">
                    Choose a social network:
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-5">
                    <img
                      src={FacebookIcon}
                      alt={"Facebookurl"}
                      id={"Facebook"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("facebook")}
                    />
                    <img
                      src={InstagramIcon}
                      alt={"Instagramurl"}
                      id={"Instagram"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("instagram")}
                    />
                    <img
                      src={TwitterIcon}
                      alt={"Twitterurl"}
                      id={"Twitter"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("twitter")}
                    />
                    <img
                      src={TiktokIcon}
                      alt={"Tiktokurl"}
                      id={"Tiktok"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("tiktok")}
                    />
                    <img
                      src={YoutubeIcon}
                      alt={"Youtubeurl"}
                      id={"Youtube"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("youtube")}
                    />
                    <img
                      src={VimeoIcon}
                      alt={"Vimeourl"}
                      id={"Vimeo"}
                      className=" mx-3 pointer"
                      onClick={() => iconhandleClick("vimeo")}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {arrayOfObjects?.length > 0 &&
                  arrayOfObjects?.map((user) => (
                    <div key={Math.random()}>
                      {user.name === "facebook" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Facebook</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img
                                src={FacebookIcon}
                                style={{ width: "51px" }}
                                alt="..."
                              />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="facebook"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("facebook")}
                            />
                          </div>
                        </div>
                      )}
                      {user.name === "instagram" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Instagram</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img
                                src={InstagramIcon}
                                style={{ width: "51px" }}
                                alt="..."
                              />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="instagram"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("instagram")}
                            />
                          </div>
                        </div>
                      )}
                      {user.name === "tiktok" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Tiktok</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img src={TiktokIcon} style={{ width: "51px" }} alt="..." />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="tiktok"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("tiktok")}
                            />
                          </div>
                        </div>
                      )}

                      {user.name === "twitter" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Twitter</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img
                                src={TwitterIcon}
                                style={{ width: "51px" }}
                                alt="..."
                              />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="twitter"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("twitter")}
                            />
                          </div>
                        </div>
                      )}

                      {user.name === "youtube" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Youtube</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img
                                src={YoutubeIcon}
                                style={{ width: "51px" }}
                                alt="..."
                              />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="youtube"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("youtube")}
                            />
                          </div>
                        </div>
                      )}

                      {user.name === "vimeo" && (
                        <div className="socialMediaLinkList">
                          <label className="form-label mb-2">Vimeo</label>
                          <div className="socialMediaLinkInput d-flex align-items-center">
                            <span className="socialIcon mr-3">
                              <img src={VimeoIcon} style={{ width: "51px" }} alt="..." />
                            </span>
                            <span className="empty-form-control">
                              <input
                                type="url"
                                className="form-control"
                                name="vimeo"
                                value={user.value}
                                onChange={handler}
                              />
                            </span>
                            <img
                              src={CloseCircle}
                              alt="..."
                              className="CloseIcon ml-3"
                              onClick={() => removeIcon("vimeo")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                {/* <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          Facebook
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={FacebookIcon}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="facebook"
                                            value="https://facebook.com?xofisufaopfopt"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          Instagram
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={InstagramIcon}
                                            size={48}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="Instagram"
                                            value="https://instagram.com?209238029"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          Twitter
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={TwitterIcon}
                                            size={48}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="Twitter"
                                            value="https://twitter.com?20923402389"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          TikTok
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={TiktokIcon}
                                            size={48}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="TikTok"
                                            value="https://tiktok.com?20923402389"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          YouTube
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={YoutubeIcon}
                                            size={48}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="youtube"
                                            value="https://youtube.com?20923402389"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div>
                                      <div className="socialMediaLinkList">
                                        <label className="form-label mb-2">
                                          Vimeo
                                        </label>
                                        <div className="socialMediaLinkInput d-flex align-items-center">
                                          <img
                                            src={VimeoIcon}
                                            size={48}
                                            alt=""
                                            className="socialIcon mr-3"
                                          />
                                          <input
                                            type="text"
                                            name="Vimeo"
                                            value="https://vimeo.com?45823809889"
                                            className="form-control"
                                          />
                                          <img
                                            src={CloseCircle}
                                            alt=""
                                            className="CloseIcon ml-3"
                                          />
                                        </div>
                                      </div> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-3 d-flex align-center justify-space-between">
            <a
              className="flex-grow-1  btn btn-primary mx-w"
              onClick={handleSubmit}
            >
              Save Social Network
            </a>
            <a
              className="btn btn-default cancelBtn  flex-grow-1 mx-w"
              onClick={handleCreateSocialMediaLinkClose}
            >
              Cancel
            </a>
          </ModalFooter>
        </form>
      </Modal>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
export default Socialmedialist;
