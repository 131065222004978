/* global google */
import React, { useState } from "react"
import AddressField from "../../components/AddressField"
import RadioGroup from "../../components/RadioGroup"
import Section from "../../components/Section"
import { useGpsLocation } from "./hooks"
import { useDispatch, useSelector } from "react-redux"
import { geofenceSelector } from "../../store/geofence/selector"
import {
  changeLatitude,
  changeLongitude,
  changeAddress

} from "../../store/geofence/slice";
const options = [
  {
    id: 1,
    value: "current",
    label: "Use Current GPS"
  },
  {
    id: 2,
    value: "type",
    label: "Type in Address"
  }
]

const GpsLocationSection = ({ fitRadius }) => {
  const dispatch = useDispatch();
  const { addressRef, onChangeLocationType, locationType } = useGpsLocation(
    fitRadius
  )
  const { address: addressValue } = useSelector(geofenceSelector)
  const [datatr, setDatatr] = useState(false);
  const onhelloData = (e) => {
    if (e === "current") {
      setDatatr(true);
      dispatch(changeAddress(""));
      navigator.geolocation.getCurrentPosition(function (position) {

        dispatch(changeLatitude(position.coords.latitude));
        dispatch(changeLongitude(position.coords.longitude));
        const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (
            ![
              google.maps.GeocoderStatus.OK,
              google.maps.GeocoderStatus.OVER_QUERY_LIMIT
            ].includes(status)
          ) {
            //console.log(messages?.locationNotFound ?? "not found");
            // toast.error(messages?.locationNotFound ?? "not found")
          }
          if (status == google.maps.GeocoderStatus.OK) {
            setDatatr(false);
            dispatch(changeAddress(results?.[0].formatted_address ?? ""))
          }
        })
      })
    }

  }
  return (
    <Section title="Coordinates">
      <div className="my-2">Specify Location</div>
      <RadioGroup
        options={options}
        value={locationType}
        onChange={onChangeLocationType}
        onClick={onhelloData}
      />

      <AddressField
        disabled={locationType === "current"}
        ref={addressRef}
        label="Type in an Address:"
        value={addressValue}
        datatr={datatr}
      />


    </Section>
  );
}

export default GpsLocationSection
