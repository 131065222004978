import { useRef, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { locationTypeSelector } from "../../store/gps/selector"
import { changeLocationType } from "../../store/gps/slice"
import { getElevation, getCurrentPosition } from "./helpers"
import { useGps } from "../../hooks/useGps"
import { geofenceSelector } from "../../store/geofence/selector"
import { useUpdateInfoMutation } from "../../services/information"
import { settingSelector } from "../../store/settings/selector"

const autoCompleteConfig = {
  fields: ["formatted_address", "geometry", "name"]
}
const google = window.google = window.google ? window.google : {}


export const useGpsLocation = fitRadius => {

  const { geoId } = useSelector(settingSelector)
  const { latitude, longitude } = useSelector(geofenceSelector)
  const [updateGeospace] = useUpdateInfoMutation()
  const locationType = useSelector(locationTypeSelector)
  const { address } = useSelector(geofenceSelector)
  const dispatch = useDispatch()

  const onChangeLocationType = useCallback(
    value => dispatch(changeLocationType(value)),
    []
  )


  const addressRef = useRef(null)
  const { changeGpsLocation } = useGps()
  const autoCompleteRef = useRef(null)

  useEffect(() => {

    if (addressRef.current) {
      addressRef.current.value = address
    }
  }, [address])

  const onChangePlace = async () => {

    const place = autoCompleteRef.current?.getPlace()
    const coordinates = place?.geometry?.location?.toJSON()
    if (coordinates) {
      const elevation = await getElevation(coordinates)
      changeGpsLocation({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        elevation
      })
      const radius = fitRadius(true)
      // updateGeospace({
      //   id: geoId,
      //   lat: coordinates.lat,
      //   lng: coordinates.lng,
      //   elevation,
      //   radius
      // })
    }
  }

  useEffect(() => {

    if (addressRef.current) {
      autoCompleteRef.current = new google.maps.places.Autocomplete(
        addressRef.current,
        autoCompleteConfig
      )
    }
    const listener = autoCompleteRef?.current?.addListener(
      "place_changed",
      onChangePlace
    )
    return () => {
      listener?.remove()
    }
  }, [])

  useEffect(() => {

    let id = null
    const timeoutRequest = () => {
      id = setTimeout(
        getPosition,
        Number(10)
      )
    }
    const getPosition = () =>

      getCurrentPosition
        .then(position => changeGpsLocation(position, true))
        .then(timeoutRequest)
    if (locationType === "current") {

      void getPosition()
    }
    return () => {
      if (id) clearTimeout(id)
    }
  }, [locationType, latitude, longitude])

  return {
    onChangeLocationType,
    addressRef,
    locationType
  }
}
