import React from "react";
import { IoMdClose } from "react-icons/io";

const DeleteBtn = ({ children, customClass, img, icon }) => {
  return (
    <>
      <button className={`btn deleteBtn ${customClass}`}>
        {img ? <img src={img} alt="" className="icon" /> : ""}
        {icon ? <IoMdClose className="icon" /> : ""}
        {children}
      </button>
    </>
  );
};

export default DeleteBtn;
