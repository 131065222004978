import React from "react";
import {
    Navbar,
    Container,
    Row,
    Col,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Card,
    CardHeader,
    CardBody,
    Button,
} from "reactstrap";
import classnames from "classnames";
import IconImg from "../../../assets/img/icons/project logos@2x.png";
import Vector1 from "../../../assets/img/icons/two-factor-icon.svg";
import { useLocation } from "react-router-dom";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { Link, useHistory } from "react-router-dom";
import { userService } from "../../../_services/login/user.service";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
function PasswordRecovery(props) {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const [emailAddress, setemailAddress] = React.useState(false);
    let history = useHistory();
    const {
        watch,
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm();
    const twoFactorauth = (data) => {
        userService.resetPasswordupdate(data).then(
            (res) => {
                if (res.status === "success") {
                    toast.success(res.message, "ResetPassword");
                } else {
                    toast.error(res.message, "error");
                }
            },
            (error) => {
                // localStorage.removeItem("userDetails");
                // toast.error("Invalid Credetials !!", "Login");
                toast.error("Invalid", "ResetPassword");
            }
        );
    };
    const onSubmit = (data) => {
        userService.resetPasswordLink(data).then(
            (res) => {
                if (res.status === "success") {
                    //
                    // localStorage.setItem("userDetails", JSON.stringify(res.data));
                    // history.push('/admin/account-information');
                    // var retrievedObject = JSON.parse(localStorage.getItem('userDetails'));
                    // if (retrievedObject.name !== Cookies.get('name')) {
                    //     Cookies.set('name', retrievedObject.name);
                    // }

                    toast.success(res.message, "ResetPassword");
                    setTimeout(() => {
                        history.push("/admin/account-information");
                    }, 1000);
                } else if (res.status === "error") {
                    //console.log('2');
                    // localStorage.removeItem("userDetails");
                    toast.error(res.message, "ResetPassword");
                } else {
                    //console.log('3');
                    // localStorage.removeItem("userDetails");
                    // toast.error("Please enter valid username and password !!", "Login");
                    toast.error("User Not found", "ResetPassword");
                }
            },
            (error) => {
                // localStorage.removeItem("userDetails");
                // toast.error("Invalid Credetials !!", "Login");
                toast.error("User Not found", "ResetPassword");
            }
        );
    };

    return (
        <>
            <Container
                fluid
                style={{
                    background: "#2053B5",
                    padding: "30px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {" "}
                <p>
                    <Link to="/">
                        <i
                            className="ni ni-bold-left"
                            style={{ color: "#fff", marginTop: "21px", marginRight: "10px" }}
                        ></i>
                    </Link>
                </p>
                <img
                    src={IconImg}
                    style={{ paddingRight: "20px" }}
                    width="70px"
                    height="50px"
                />
                <h1 style={{ color: "#fff" }}>Organization Name</h1>
            </Container>
            {token ? (
                <>
                    <div className="twofactorauth">
                        <form
                            onSubmit={handleSubmit(twoFactorauth)}
                            style={{ width: "100%" }}
                        >
                            <Container
                                fluid
                                style={{
                                    background: "#fff",
                                    padding: "40px",
                                    minHeight: "82vh",
                                }}
                            >
                                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                                <Card className="mt-5 mb-5">
                                    <CardHeader>
                                        <h3
                                            className="mb-0"
                                            style={{
                                                borderBottom: "1px solid #E7E7E7",
                                                paddingBottom: "13px",
                                            }}
                                        >
                                            Password Recovery
                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="6">
                                                <p className="basicinformation mt-2 ">
                                                    <img src={Vector1} style={{ marginRight: "11px" }} />
                                                    Password Recovery
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label className="form-control-label">
                                                        New Password:
                                                    </label>
                                                    <InputGroup
                                                        className={classnames("input-group-merge")}
                                                    >
                                                        <input
                                                            placeholder="New Password"
                                                            type="password"
                                                            name="password"
                                                            className="form-control"
                                                            {...register("password", {
                                                                required: "Password is required",
                                                            })}
                                                            required
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>
                                                                <img
                                                                    alt="password"
                                                                    src={
                                                                        require("assets/img/icons/common/password.svg")
                                                                            .default
                                                                    }
                                                                />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup className="mt-2 mb-2">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="example3cols1Input"
                                                    >
                                                        Retype Password.
                                                    </label>
                                                    <input
                                                        id="example3cols1Input"
                                                        placeholder="Enter Retype Password."
                                                        type="password"
                                                        name="cpassword"
                                                        className="form-control"
                                                        {...register("cpassword", {
                                                            required: "Confirm Password is required",
                                                            validate: (value) =>
                                                                value === getValues("password"),
                                                        })}
                                                        required
                                                    />
                                                    {errors.cpassword &&
                                                        errors.cpassword.type === "validate" && (
                                                            <div>Passwords do not match</div>
                                                        )}
                                                    <input
                                                        type="hidden"
                                                        {...register("token")}
                                                        name="token"
                                                        value={token}
                                                    />
                                                </FormGroup>
                                                <Button
                                                    className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow"
                                                    style={{
                                                        float: "right",
                                                    }}
                                                >
                                                    Change Password
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Container>
                        </form>
                    </div>
                    <ToastContainer />
                </>
            ) : (
                <>
                    <div className="twofactorauth">
                        <Container
                            fluid
                            style={{ background: "#fff", padding: "40px", minHeight: "82vh" }}
                        >
                            <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                            <Card className="mt-5 mb-5">
                                <CardHeader>
                                    <h3
                                        className="mb-0"
                                        style={{
                                            borderBottom: "1px solid #E7E7E7",
                                            paddingBottom: "13px",
                                        }}
                                    >
                                        Password Recovery
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <p className="basicinformation mt-2">
                                                <img src={Vector1} style={{ marginRight: "11px" }} />
                                                Password Recovery
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            style={{ width: "100%" }}
                                        >
                                            <Col md="6">
                                                <FormGroup className="mt-2 mb-2">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="example3cols1Input"
                                                    >
                                                        Enter Email Address for Password Recovery:
                                                    </label>
                                                    <input
                                                        className={`form-control ${errors.email ? "errorborder" : ""
                                                            }`}
                                                        placeholder="Enter your email"
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        {...register("email", {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value:
                                                                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message: "Invalid email address",
                                                            },
                                                        })}
                                                    />
                                                    <small className="error">
                                                        {errors?.email && errors.email.message}
                                                    </small>
                                                </FormGroup>
                                                <Button
                                                    className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow"
                                                    style={{
                                                        float: "right",
                                                    }}
                                                    type="submit"
                                                >
                                                    Send Email
                                                </Button>
                                                {/* <a
                                                    href="#pablo"
                                                    className="btn-neutral btn btn-default mt-2 mb-5 authenticatetow"
                                                    style={{

                                                        float: "right",
                                                    }}
                                                >
                                                    Send Email
                                                </a> */}
                                            </Col>
                                        </form>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                        <ToastContainer />
                    </div>
                </>
            )}
            <AuthFooter />
        </>
    );
}
export default PasswordRecovery;
