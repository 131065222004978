import {
    EVENT_KEEPSAKE_ALBUM,
    REMOVE_EVENT_KEEPSAKE_ALBUM,
} from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    Eventkeepsakealbum: [],
};
function eventkeepsakealbum(state = initialState, action) {
    switch (action.type) {
        case EVENT_KEEPSAKE_ALBUM:
            return {
                ...state,
                isConfirmatiom: true,
                Eventkeepsakealbum: action.payload,
            };
        //return { ...state, event: [...action.payload] };
        case REMOVE_EVENT_KEEPSAKE_ALBUM:
            // const newPeople = state.cart.filter(item => item.id !== action.product_Id);
            //console.log('remove');

            return {
                ...state,
                isConfirmatiom: true,
                Eventkeepsakealbum: [],
            };
        default:
            return state;
    }
}
export default eventkeepsakealbum;
