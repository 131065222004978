import React from "react";
import ReactTooltip from "react-tooltip";
function HeaderSection(props) {
  return (
    <>
      {/* <div className="pagetool">
            <h4 className="pagesubtitle">{props.title}</h4>
            <span data-tip={props.tooltip}>
                <img
                    alt="Organization Name"
                    src={require("assets/img/tip.svg").default}
                    style={{ marginRight: "10px" }}
                />
            </span>
            <ReactTooltip />
        </div>
        <hr className="hrclass" />
        <small style={{
            padding: '16px', fontSize: '16px'
            , fontWeight: '300'
        }}>{props.setdata}</small> */}
      <div className="headingWrapper borderBottom">
        <h4 className="heading-1">{props.title}</h4>
        <span data-tip={props.tooltip} className="ml-auto">
          <img
            alt="Organization Name"
            src={require("assets/img/tip.svg").default}
            style={{ marginRight: "10px" }}
          />
        </span>
        <ReactTooltip />
      </div>

      <div className="borderBottom organizationPara p-4">{props.setdata}</div>
    </>
  );
}
export default HeaderSection;
