import React, { useState } from "react";
// import Eventmenu from "../eventMenu";
// import StoryzMenu from "../../../components/Storyz/StoryzMenu";
// import { Eventstyle } from "../styles";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import base_url from "_helpers/baseUrl";
// import Global from "../../../_helpers/global";
// import FileImg from "./../../../assets/img/file-listings.png";
// import { GuestEventWrapper } from "../UserGuestList/style";
import { useParams } from "react-router-dom";
import { userGuestService } from "../../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrCircleInformation } from "react-icons/gr";

const StoryzUploadGuestListDetails = () => {
  const [imgData, setImgData] = useState(null);
  const [imgModalBox, setImgModalBox] = useState(false);
  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [importFromLocal, setImportFromLocal] = useState(false);
  const [imgDataUrl, setImgUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [msg, setMsg] = useState();
  const [csvType, setVideo] = useState("");
  const params = useParams();

  const AddBrowImgModal = () => {
    setBrowseImgModal(true);
  };

  const BrowseImgModal = () => {
    setBrowseImgModal(true);
  };

  const ImportFromLocal = () => {
    setVideo("");
    setImportFromLocal(true);
    setBrowseImgModal(false);
  };

  const allClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(false);
    setImgModalBox(false);
  };

  const ImportFromLocalClose = () => {
    setImportFromLocal(false);
    setBrowseImgModal(true);

    setImgUrl();
    setImgData();
    setImageName();
    setVideo();
  };

  const onChangePicture = (e) => {
    ///alert('Hiii');
    setImgData("");
    if (e.target.files[0]) {
      let urldata = URL.createObjectURL(e.target.files[0]);
      const type = e.target.files[0].type.split("/");
      setMsg("");
      setImgUrl("loading..");
      setVideo(type[1]);
      setImgData(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setTimeout(() => {
        //setVideo(videourl1);
        setImgData(e.target.files[0]);
        setImgUrl(urldata);
      }, "0");
    }
  };

  const uploadGuest = () => {
    var formData = new FormData();
    formData.append("documents", imgData);
    formData.append("storyz_id", params.sid);
    formData.append("type", csvType);
    //console.log('formData', formData);
    userGuestService.uploadStoryzGuest(formData).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message);
          allClose();
        } else {
          toast.error(res.message);
          allClose();
        }
      },
      (error) => {
        toast.error("Something went wrong !!");
      }
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="headingWrapper borderBottom">
        <h4 className="heading-1">Upload Guest List for All Events</h4>
        <GrCircleInformation size={20} className="ml-auto iconColor" />
      </div>
      <div className="organizationPara p-4">
        Upload a guest list for
        <span className="fontWeight500">
          {" "}
          all your events in this Storyz.
        </span>{" "}
        If you want to upload to just one event,
        <Link className=" ml-1 text-decoration-underline">
          the event upload.
        </Link>
        <br />
        You can reassign guests to individual events by using the
        <Link className=" ml-1 text-decoration-underline">
          combined guest list.
        </Link>
      </div>

      <Row>
        <Col>
          <div className="uploadGuestListWrapper d-flex justify-content-center py-4 ">
            <div className="uploadGuestList mx-4">
              <div className="mb-4 iconBox pointer" onClick={ImportFromLocal}>
                <img
                  alt="Organization Name"
                  src={require("assets/img/file-type-icon.svg").default}
                  className="mb-2"
                />
                <div className="fontSize12 blackColor text-center">.csv</div>
              </div>
              <div className="fontSize16 grayColor mb-4 text-center">
                Spreadsheet(CSV)
              </div>
              <a
                href={base_url + "documents/guest_list_sample.csv"}
                target="_blank"
              >
                <div className="DownloadBtn pointer">
                  <img
                    alt="Organization Name"
                    src={require("assets/img/download-icon.svg").default}
                    className="mr-2"
                  />
                  Download Template
                </div>
              </a>
            </div>
            <div className="uploadGuestList mx-4">
              <div
                className="mb-4 iconBox pointer"
                onClick={setImportFromLocal}
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/file-type-icon.svg").default}
                  className="mb-1"
                />
                <div className="fontSize12 blackColor text-center">.xls</div>
              </div>
              <div className="fontSize16 grayColor mb-4 text-center">
                Excel (Native)
              </div>
              <div className="DownloadBtn pointer">
                <img
                  alt="Organization Name"
                  src={require("assets/img/download-icon.svg").default}
                  className="mr-1"
                />
                Download Template
              </div>
            </div>
            <div className="uploadGuestList mx-4">
              <div
                className="mb-4 iconBox pointer"
                onClick={setImportFromLocal}
              >
                <img
                  alt="Organization Name"
                  src={require("assets/img/file-type-icon.svg").default}
                  className="mb-1"
                />
                <div className="fontSize12 blackColor text-center">
                  Google Sheets
                </div>
              </div>
              <div className="fontSize16 grayColor mb-4 text-center">
                Google Sheets
              </div>
              <div className="DownloadBtn pointer">
                <img
                  alt="Organization Name"
                  src={require("assets/img/download-icon.svg").default}
                  className="mr-1"
                />
                Download Template
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* {browseImgModal && (
      <div
        className={`modal fade mediaFileListModal ${
          browseImgModal ? "show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Event Files</h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => setImportFromLocal(false)}
              >
                <img
                  alt=""
                  src={require("assets/img/deleteIcon.svg").default}
                  className="closeBtn"
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="importImageListWrapper">
                <div className="importImageWrapper" onClick={ImportFromLocal}>
                  <img
                    alt="Import New"
                    src={require("assets/img/upload-icon.svg").default}
                    className="importIcon"
                  />
                  Import New
                </div>
                <div className="importedImageListWrapper">
                  <div className="importedImageListInner">
                    <div className="importedImageList selected">
                      <div className="uploadedItem">
                        <div className="uploadedImg">
                          <div className="playIcon">
                            <i className="fa fa-play"></i>
                          </div>
                          <img
                            src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2373&q=80"
                            alt=""
                            className="thum"
                          />
                        </div>
                      </div>
                      <div className="filename">File the Name Goes Here</div>
                    </div>
                    <div className="importedImageList">
                      <div className="uploadedItem">
                        <div className="uploadedImg">
                          <img
                            src="https://images.unsplash.com/photo-1485968579580-b6d095142e6e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=986&q=80"
                            alt=""
                            className="thum"
                          />
                        </div>
                      </div>
                      <div className="filename">File the Name Goes Here</div>
                    </div>
                    {[...Array(8)].map((x, i) => (
                      <div className="importedImageList" key={i}>
                        <div className="uploadedItem">
                          <div className="uploadedImg">
                            <img
                              src="https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2373&q=80"
                              alt=""
                              className="thum"
                            />
                          </div>
                        </div>
                        <div className="filename">File the Name Goes Here</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn selectFilebtn"
                onClick={() =>
                  imgDataUrl != ""
                    ? setImgModalBox(true)
                    : setMsg("Please choose video or photo")
                }
              >
                Select File
              </button>
              <button
                type="button"
                className="btn selectFilebtn whiteBg"
                onClick={() => setImportFromLocal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )} */}

      {importFromLocal && (
        <div
          className={`modal fade mediaFileListModal importFromLocalModal ${importFromLocal ? "show" : ""
            }`}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content importImageFromLocalContainer">
              <div className="importFromLocalLeftWrapper">
                <div className="importFromLocalLefList active">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/local-device.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Local Device
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/google-drive.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Google Drive
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/instagram.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Instagram
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/google-photos.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Google Photos
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/icloud.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  iCloud
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/one-drive.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  OneDrive
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/dropbox.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Dropbox
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/box.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Box
                </div>
                <div className="importFromLocalLefList">
                  <div className="importFromLocalIconBox">
                    <img
                      alt=""
                      src={require("assets/img/facebook.svg").default}
                      className="importFromLocalIcon"
                    />
                  </div>{" "}
                  Facebook
                </div>
              </div>
              <div>
                <div className="modal-header">
                  <h1 className="modal-title">Import From Local Device</h1>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setImportFromLocal(false)}
                  >
                    <svg
                      className="closeBtn"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importFromLocalContent">
                    <div className="importFromLocalContentLeft">
                      <i className="fa fa-image faImage"></i>
                    </div>
                    <div>Drag file into grey area below, or tap to upload</div>
                  </div>
                  {/* <h1>Image Type</h1> */}
                  <p style={{ color: "#FF0000" }}>{msg}</p>
                  <div className="uploadImageFromLocalWrapper">
                    <div className="uploadImageFromLocalContent">
                      <input
                        type="file"
                        name="files"
                        className="fileInput"
                        accept=""
                        onChange={(event) => {
                          onChangePicture(event);
                        }}
                      // onChange={onChangePicture}
                      />
                      <i className="fa fa-plus faPlus"></i>
                    </div>
                    <>
                      {csvType == "csv" ? (
                        <img
                          alt="Organization Name"
                          src={require("assets/img/file-type-icon.svg").default}
                          className="mb-2"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn selectFilebtn"
                    onClick={() =>
                      imgDataUrl !== ""
                        ? uploadGuest()
                        : setMsg("Please choose video or photo")
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StoryzUploadGuestListDetails;
