import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Section from "../../components/Section"
import TextField from "../../components/TextField"
import { settingSelector } from "../../store/settings/selector"
import Filemanager from '../../../../CommonMediaModal/Filemanager';
import { ReactSession } from "react-client-session";
import placeholde from "../../../../../assets/img/placeholder.jpg";
import { changeSettingName, changeSettingDesc, changeSettingImage } from "../../store/settings/slice"
import {
  useGetInfoQuery,
  useUpdateInfoMutation
} from "../../services/information"




const NameAndSettingSection = ({ allimage, imageurl }) => {
  const { name, geoId, desc, image } = useSelector(settingSelector)
  const { data } = useGetInfoQuery({ geoId }, { skip: !geoId })
  const [updateGeospace] = useUpdateInfoMutation()
  const dispatch = useDispatch()

  const [singleUrl, setsingleUrl] = React.useState('');
  useEffect(() => {
    if (data) dispatch(changeSettingName(data.name))
    if (data) dispatch(changeSettingDesc(data.desc))
    if (data) dispatch(changeSettingImage(data.image))
  }, [data])

  useEffect(() => {
    setsingleUrl(imageurl);
  }, [imageurl])

  const onChangeName = value => dispatch(changeSettingName(value))
  const onChangeName1 = value => dispatch(changeSettingDesc(value))

  const onNameSave = () => {
    // updateGeospace({ id: geoId, name })
  }


  const handleClick = () => {
    setModalShow(false)
  };

  const [modalShow, setModalShow] = React.useState(false);
  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";

    const data = {
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };
    setsingleUrl(jsonData[0].url);
    dispatch(changeSettingImage(data))
  }
  const seletedImageClike = (e) => {
    let jsonData = JSON.parse(e.img_json);
    const data = {
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData,
    };
    setsingleUrl(jsonData.url);
    dispatch(changeSettingImage(data))
    setModalShow(false)
  }

  return (
    <Section title="Name and Settings">
      <TextField
        label="Name of Geospace:"
        value={name}
        onChange={onChangeName}
        onBlur={onNameSave}
        onKeyPress={(key) => {
          if (key === "Enter") onNameSave();
        }}
      />
      <div>
        <div className="formBox">
          <div className="imageventsla">
            <div>Cover Image:</div>
            <div>
              <img
                alt="Organization Name"
                src={require("assets/img/mediaGallery.svg").default}
                className="icomn pointer"
                style={{ width: "24px" }}
                onClick={() => {
                  setModalShow(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="formBox">
          <div className="imgMaskevente" onClick={() => {
            setModalShow(true);
          }}>
            <div className="imgMask-content">+</div>

            <img className="storytoimageev" id="blah2" src={singleUrl ? singleUrl : placeholde} alt="." />

            {/* <input type="file" className="imgg1" /> */}





          </div>
        </div>

        {modalShow ? (
          <Filemanager
            show={modalShow}
            onHide={handleClick}
            onClickimageUpdate={imageUpdate}
            list={allimage}
            seletedImage={seletedImageClike}
          />
        ) : (
          ""
        )}
      </div>
      <TextField
        label="Description"
        value={desc}
        onChange={onChangeName1}
        onBlur={onNameSave}
        onKeyPress={(key) => {
          if (key === "Enter") onNameSave();
        }}
      />
    </Section>
  );
}

export default NameAndSettingSection
