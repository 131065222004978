import React, { useState, useEffect, useRef } from "react";
import { Eventstyle } from "../styles";
import Eventmenu from "../eventMenu";
import { GrCircleInformation } from "react-icons/gr";
import {
  MdDevices,
  MdSignalCellular4Bar,
  MdSignalWifi4Bar,
} from "react-icons/md";
import { ReactSession } from "react-client-session";
import keepsakeAlbumIcon from "./../../../../src/assets/img/KeepsakeAlbum.svg";
import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Filemanager from "../../CommonMediaModal/FilemanagerKeepsakeAlbum";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
// import Filemanager from "../../CommonMediaModal/Filemanager"
// import KeepsakeGallery from "./KeepsakeGallery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import UserInfo from './UserInfo';
// import UserDetails from './UserDetails';
// import BrowseFileModal from 'components/MediaModal/BrowseFileModal';
// import ImportFromLocalModal from 'components/MediaModal/ImportFromLocalModal';
// import ImageUploadModal from 'components/MediaModal/ImageUploadModal';
import { useSelector, useDispatch } from "react-redux";
// import KeepsakeAlbumCoverImg from "./../../../../src/assets/img/cover_img.jpg";
// import { FaChevronRight } from 'react-icons/fa';
import { FiArrowLeft } from "react-icons/fi";
import {
  mediaList,
  mediaClear,
  eventKeepsake,
  removeeventKeepsake,
} from "../../../store/actions/index";
import { userGuestService } from "../../../_services/masters/userGuest.service";
// import BlankImages from './blankImages'
import { useHistory } from "react-router-dom";
import { BsFillChatDotsFill } from "react-icons/bs";
import EditIcon from "../../../assets/img/pencil-icon.svg";
import TrashIcon from "../../../assets/img/trash-icon.svg";
import PlusIcon from "../../../assets/img/plus-icon.svg";
import { IoMdBatteryFull } from "react-icons/io";
import ChatIcon from "./../../../assets/img/message-icon.svg";
import SendIcon from "./../../../assets/img/sendIcon.svg";
import DeleteEverythingInfoIcon from "./../../../../src/assets/img/resetgeospaceInfoIcon.svg";
import DummyImg from "./../../../assets/img/dummyImg.svg";
import moment from "moment";

const KeepsakeAlbumUpload = ({ args, name }) => {
  const params = useParams();
  const inputRef = useRef();

  const [browseImgModal, setBrowseImgModal] = useState(false);
  const [imgModalBox, setImgModalBox] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("byDate");
  const [keepsakeAlbumAll, setKeepsakeAlbumAll] = useState([]);
  const [KPREImages, setImages] = useState([]);
  const [eventId, setEventId] = useState(params.eid ? params.eid : "");
  const [storyzId, setStoryzId] = useState(params.sid ? params.sid : "");
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [deleteStore, setDeleteStore] = useState({});
  const [chatModal, setChatModal] = useState(false);
  const [endChatModal, setEndChatModal] = useState(false);
  const [savedChatModal, setSavedChatModal] = useState(false);
  const [chatwithGuestModal, setChatwithGuestModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [guestrole, setGuestRole] = useState("");
  const [guestprofile, setGuestProfile] = useState(DummyImg);
  const [modalShow21, setModalShow21] = useState(false);
  const [allimage2, setAllimage2] = useState([]);
  const [allimage, setAllimage] = useState([]);
  const [EditID, setEditID] = useState(0);
  const [photolabel, setPhotolabel] = useState("");
  const [Photocaption, setPhotocaption] = useState("");
  const [gallerylocation, setGallerylocation] = useState("");
  const [mapkey, setMapkey] = useState(
    "AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
  );
  const [mapModal, setMapModal] = useState(false);
  const [galleryEditlocation, setGalleryEditlocation] = useState("");
  const [DeleteData, setDeleteData] = useState(null);

  const handelChatModal = () => {
    setChatModal(!chatModal);
  };
  const handelEndChatModal = () => {
    setEndChatModal(!endChatModal);
  };
  const handelSavedChatModal = () => {
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
      setEndChatModal(false);
      setSavedChatModal(true);
    }, "1000");
  };
  const handelCloseSavedChatModal = () => {
    setSavedChatModal(false);
    setChatModal(false);
    setChatwithGuestModal(false);
  };

  const handelChatwithGuestModal = () => {
    setChatwithGuestModal(!chatwithGuestModal);
  };

  const PreviweModal = () => {
    setPreviewModal(!previewModal);
  };

  const UploadImgModal = () => {
    setImgModalBox(true);
  };
  const CloseUploadImgModal = () => {
    setImgModalBox(false);
  };

  const handelMapModal = () => {
    setMapModal(!mapModal);
  };

  const imageUpdate = (e) => {

  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setGallerylocation(place.formatted_address);
      setGalleryEditlocation(place.formatted_address);
      ReactSession.set("formatted_address", place.formatted_address);
      ReactSession.set("lat", place.geometry.location.lat());
      ReactSession.set("lng", place.geometry.location.lng());
      // console.log(place.formatted_address);
      // console.log(place.geometry.location.lat());
      // console.log(place.geometry.location.lng());
      // setFormValues({ ...formValues, ['img_address']: place.formatted_address });
    }
  };

  const seletedImageClike = (e) => {
    //setAllimage2(e)
    setPhotolabel(e.photo_label);
    setPhotolabel(e.photo_caption);
    setGallerylocation(e.location);

    let imgJSON = JSON.parse(e.img_json);
    let mime_typeArr = e.mimetype.split("/");
    let mimeType = mime_typeArr;
    e["imaDatajson"] = imgJSON;
    e["mimetype"] = mimeType[0];
    delete e.img_json;
    // delete e.id;
    // delete e.user_id;
    // delete e.filename;
    // delete e.handle;

    // delete e.source;
    // delete e.url;
    // delete e.created_at;
    // delete e.updated_at;
    // delete e.img_address;
    // delete e.lat;
    // delete e.lng;
    let kaData = JSON.parse(JSON.stringify(e));
    kaData["storyz_id"] = params.sid;
    kaData["guest_user_id"] = params.id;
    kaData["event_id"] = params.eid;
    kaData["location"] = gallerylocation;
    kaData["caption"] = Photocaption;
    kaData["let"] = "";
    kaData["long"] = "";
    kaData["description"] = "";
    kaData["type"] = "keepsakeAlbum";
    setAllimage2(kaData);

    //console.log( "seletedImageClike", kaData);
    UploadImgModal();
    //AddBrowImgModal()
  };

  const saveData = () => {
    let objKaData = {};
    objKaData["type"] = "keepsakealbum";
    objKaData["imaDatajson"] = allimage2.imaDatajson;
    objKaData["storyz_id"] = params.sid;
    objKaData["guest_user_id"] = params.id;
    objKaData["event_id"] = params.eid;
    objKaData["location"] = gallerylocation;
    objKaData["photo_label"] = photolabel;
    objKaData["photo_caption"] = Photocaption;
    objKaData["description"] = "";
    //console.log("objKaData", objKaData);
    userGuestService.addKeepsake(objKaData).then(
      (res) => {
        if (res.status === "success") {
          handleClick();
          toast.success("Save Successfully");
          //setImgModalBoxF(false);
          setImgModalBox(false);

          //onChildClick(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const handleClick12 = () => {
    setModalShow21(false);
  };

  const AddBrowImgModal = () => {
    //setBrowseImgModal(!browseImgModal);
    setModalShow21(true);
    setImgModalBox(false);
  };

  const AddBrowImgModalClose = () => {
    //setBrowseImgModal(!browseImgModal);
    setModalShow21(false);
  };

  const handleImportModal = () => {
    setImgModalBox(!imgModalBox);
    setBrowseImgModal(false);
  };

  const handelDeleteMedia = (photo_url) => {
    let obj = { photo_url: photo_url };
    userGuestService.getDeleteData(obj).then(
      (res) => {
        if (res.status == "success") {
          setDeleteData(res.data);
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
    //console.log( "obj-->", obj );
    setDeleteMedia(!deleteMedia);
  };

  const deleteData = (photo_url) => {
    let obj = { photo_url: photo_url };
    userGuestService.deleteData(obj).then(
      (res) => {
        if (res.status == "success") {
          handleClick();
          setDeleteMedia(false);
          toast.success("Deleted Successfully");
        } else {
          toast.error("Not Deleted Successfully");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };

  const handelEditMedia = (eid, obj) => {
    //console.log("KPREImages obj", obj);
    //setAllimage2(obj)
    let newObj = JSON.parse(JSON.stringify(obj));
    let kaData = {};
    setPhotolabel("");
    setPhotocaption(newObj.caption);
    setGallerylocation(newObj.location);
    setEditID(eid);

    let imgJSON = JSON.parse(newObj.keepsake_image_json);
    //console.log("imgJSON", imgJSON);
    //setAllimage2(obj)
    imgJSON["url"] = newObj.photo_url;
    kaData["type"] = "keepsakeAlbum";
    kaData["id"] = eid;
    kaData["keepsake_album_id"] = newObj.keepsake_album_id;
    kaData["imaDatajson"] = imgJSON;
    kaData["mimetype"] = newObj.mime_type;
    delete newObj.keepsake_image_json;
    //let kaData = JSON.parse(JSON.stringify(e));
    kaData["storyz_id"] = params.sid;
    kaData["guest_user_id"] = params.id;
    kaData["event_id"] = params.eid;
    kaData["caption"] = Photocaption;
    kaData["location"] = gallerylocation;
    kaData["let"] = "";
    kaData["long"] = "";
    kaData["description"] = "";
    let newkaData = JSON.parse(JSON.stringify(kaData));

    //setTimeout(() => {
    setImgModalBox(true);
    setAllimage2(newkaData);
    // }, 1000);

    //console.log("kaData", kaData);
    //console.log("handelEditMedia", eid);
  };
  const updateData = () => {
    let newObj = JSON.parse(JSON.stringify(allimage2));
    newObj["caption"] = Photocaption;
    newObj["location"] = gallerylocation;
    newObj["id"] = EditID;
    //setAllimage2(newObj)


    userGuestService.updateKeepsake(newObj).then(
      (res) => {
        if (res.status === "success") {
          handleClick();
          toast.success("Updated Successfully");
          setImgModalBox(false);
          //setAllimage2([])
          //handleClickUserInfo();
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  };

  const handleClick = (val) => {
    //console.log("ehandleClick", e);
    //setSelectedOption(val);
    const dataa = {
      guest_id: params.id,
      storyz_id: params.sid,
    };
    userGuestService.getGuestKeepsake(dataa).then(
      (res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            //console.log("klp[0].images", res.data[0].keepsake_album_photo[0].images);
            const klp = res.data[0].keepsake_album_photo;
            setKeepsakeAlbumAll(klp);
            //setImages(res.data[0].keepsake_album_photo);
            setGuestName(res.data[0].guest_name);
            const obj = {
              id: params.id,
              first_name: res.data[0].guest_name,
              last_name: "",
              type: "GuestDetails",
            };
            sessionStorage.setItem("detailsOfUser", JSON.stringify(obj));
            setGuestRole("");
            setGuestProfile(res.data[0].guest_profile_url);
          } else {
            console.log("Something went wrong !!");
          }
        } else {
          console.log("Something went wrong !!");
          // toast.error(res.message);
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        // toast.error("Something went wrong !!", "Fashion Store");
      }
    );

    //   CloseUploadImgModal();
    //   setModalShow21(false)
  };

  useEffect(() => {
    handleClick();
  }, [params.id, selectedOption]);

  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  //selectedOption

  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {/* {common?.singleEvent?.event_name
                ? common?.singleEvent?.event_name
                : ""} */}
            </div>
            <div className="d-flex align-items-center">
              <div className="imageeventim234">
                <span>Keepsake Album Uploads</span>
              </div>
              <GrCircleInformation className="ml-3 pointer infoIcon" />
            </div>
          </div>
          <div className="border-bottom eventBannerPara">
            This page shows all guest uploads, using the OurStoryz guest app.
            <br />
            To see the complete list of uploads, go to the{" "}
            <Link> Storyz view </Link> or
            <Link> Organization view</Link>.
          </div>
          <div className="pt-2 pb-4 d-flex justify-content-between pl-5 pr-4 border-bottom">
            <div className="keepsakeAvatarCard">
              <img
                alt="DummyImg"
                src={guestprofile == "" ? DummyImg : guestprofile}
                className="avatarImg"
              />
              <div>
                <div className="title">{guestName}</div>
                <div className="subTitle">{guestrole}</div>
              </div>
            </div>
            {/* <button
              className="messageBtn active"
              type="button"
              onClick={handelChatModal}
            >
              <BsFillChatDotsFill className="icon" />
              Message
            </button> */}
          </div>
          <Modal
            isOpen={chatModal}
            toggle={handelChatModal}
            {...args}
            className="addSupportTicketModalWrapper"
          >
            <ModalHeader
              className="fontSize20 whiteColor align-items-center"
              toggle={handelChatModal}
            >
              Chat With a Guest
            </ModalHeader>
            <ModalBody className="bg4">
              <Row>
                <Col>
                  <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                    <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                      <img src={ChatIcon} alt="" className="mr-2" />
                      Chat Header
                    </div>
                    <div
                      className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                      onClick={handelEndChatModal}
                    >
                      end chat
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2 text-right">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                      <input
                        type="email"
                        className="form-control text-right sendBtnInput"
                        id="exampleFormControlInput1"
                        placeholder="This is chat text"
                      />
                      <Button type="button" className="sendBtn">
                        <img
                          src={SendIcon}
                          alt=""
                          width={24}
                          height={24}
                          className="sendBtnIcon"
                        />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={endChatModal}
            toggle={handelEndChatModal}
            {...args}
            className="saveChatTemplateModal"
          >
            <ModalBody>
              <div className="d-flex align-items-center pb-2 mb-3 border-bottom fontSize20 blackColor">
                <img
                  alt="Organization Name"
                  src={require("assets/img/circle-chat-icon.svg").default}
                  className="m12-right"
                  width={88}
                  height={88}
                />
                Save Chat Transcript
              </div>
              <p className="mb-0 blackColor">
                Choose where to save the transcript. The transcript is a simple
                text file. Images and attachments will not be saved.
              </p>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 blueBtn rounded"
                onClick={handelSavedChatModal}
              >
                Email Transcript
              </Button>
              <Button
                className="btn-default cancelBtn"
                onClick={handelEndChatModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={savedChatModal}
            toggle={handelSavedChatModal}
            {...args}
            className="savedChatModal"
            backdrop={false}
          >
            <ModalBody>
              <div className="align-items-center pb-2 mb-4 border-bottom fontSize20 blackColor d-flex flex-column">
                <img
                  alt="Organization Name"
                  src={require("assets/img/circle-saved-chat-icon.svg").default}
                  className="m12-bottom"
                  width={88}
                  height={88}
                />
                Chat Transcript Saved
              </div>
              <p className="mb-0 blackColor">
                Transcript was saved to your email.
              </p>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
              <Button
                color="primary"
                className="flex-grow-1 blueBtn rounded w-100"
                onClick={handelCloseSavedChatModal}
              >
                Continue
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={loadingModal} {...args} className="loadingModal">
            <ModalBody className="d-flex align-items-center justify-content-center">
              <Row>
                <Col>
                  <div className="circleAnimation1"></div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={chatwithGuestModal}
            toggle={handelChatwithGuestModal}
            {...args}
            className="addSupportTicketModalWrapper"
          >
            <ModalHeader
              className="fontSize20 whiteColor align-items-center"
              toggle={handelChatwithGuestModal}
            >
              Broadcast to all Guests
            </ModalHeader>
            <ModalBody className="bg4">
              <Row>
                <Col>
                  <div className="p-3 d-flex align-items-center justify-content-between mx-n4 mt-n4 bg3 mb-3">
                    <div className="d-flex align-items-center fontSize18 blackColor flex-grow">
                      <img src={ChatIcon} alt="" className="mr-2" />
                      Chat Header
                    </div>
                    <div
                      className="fontSize16 link-opacity-100 ml-2 blackColor pointer  text-decoration-underline"
                      onClick={handelEndChatModal}
                    >
                      end chat
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="form-check mr-3 pl-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                        checked
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        All Members
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value=""
                        name="repeating_event"
                      />
                      <label
                        className="form-check-label grayColor"
                        for="flexRadioDefault1"
                      >
                        Lead Member
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="chatBoxWrapper py-4 mr-n3 pr-3">
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                      <div className="my-2 text-right">
                        <div className="">
                          <div className="fontSize14 fontWeight300 blackColor mb-3">
                            1:23pm
                          </div>
                          <div className="fontSize14 fontWeight300 blackColor p-3 chatBg d-inline-flex">
                            This is chat text
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg2 py-2 px-3 fontSize14 fontWeight300 d-flex align-items-center justify-content-end pointer sendBtnWrapper position-relative">
                      <input
                        type="email"
                        className="form-control text-right sendBtnInput"
                        id="exampleFormControlInput1"
                        placeholder="This is chat text"
                      />
                      <Button type="button" className="sendBtn">
                        <img
                          src={SendIcon}
                          alt=""
                          width={24}
                          height={24}
                          className="sendBtnIcon"
                        />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Container>
            <Row>
              <Col>
                <div className="keepsakeBox pt-4">
                  <Link to={`/admin/storyz-keepsake-album/${params.sid}`}>
                    <div
                      className="keepsakeIcon"
                    // onClick={() => {
                    //   history.push(
                    //     `/admin/storyz-keepsake-album/${params.sid}`
                    //   );
                    // }}
                    >
                      <img src={keepsakeAlbumIcon} alt="" className="icon" />
                      <div>Storyz Keepsake</div>
                    </div>
                  </Link>
                  <Link
                    to={`/admin/event-keepsake-album/${params.sid}/${params.eid}?type=event`}
                  >
                    <div
                      className="keepsakeIcon"
                    // onClick={() => {
                    //   history.push(`/admin/edit-user-guest-list/${params.sid}`);
                    // }}
                    >
                      <img src={keepsakeAlbumIcon} alt="" className="icon" />
                      <div>Event Keepsake</div>
                    </div>
                  </Link>
                  <div className="preview" onClick={PreviweModal}>
                    <MdDevices className="icon" />
                    <div>Preview</div>
                  </div>
                  <Modal
                    isOpen={previewModal}
                    toggle={PreviweModal}
                    {...args}
                    className="keepsakeAlbumModalUpload"
                  >
                    <ModalHeader
                      toggle={() => PreviweModal(false)}
                    ></ModalHeader>
                    <ModalBody>
                      <div className="headerTop">
                        <div className="date">
                          {moment(new Date()).format("h:mm A")}
                        </div>
                        <div className="signalWrapper">
                          <MdSignalCellular4Bar className="icon" />
                          <MdSignalWifi4Bar className="icon" />
                          <IoMdBatteryFull className="icon" />
                        </div>
                      </div>
                      <div className="header">
                        <FiArrowLeft className="arrowIcon" />
                        {guestName + " images"}
                      </div>
                      <div className="galleryGridWrapperOuter">
                        {keepsakeAlbumAll.map((x, i) => (
                          <div key={i}>
                            {/* <div className="date">{moment(x.date).format("MMMM D YYYY")}</div> */}
                            {/* <div className="keepsakeAlbumListWrapper"> */}
                            <div className="galleryGridWrapper">
                              {x.images.map((d, i) => (
                                <>
                                  {d.mime_type == "image" ? (
                                    <img
                                      src={d.photo_url}
                                      alt=""
                                      width="200"
                                      key={Math.random()}
                                    // className="listImg"
                                    />
                                  ) : (
                                    <video width="200" controls className="" key={Math.random()}>
                                      <source
                                        src={d.photo_url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support HTML video.
                                    </video>
                                  )}
                                </>
                              ))}
                            </div>
                            {/* </div> */}
                          </div>
                        ))}
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="keepsakeAlbumListWrapperOuter">
                  <div className="scrollWrapper">
                    <div className="keepsakeAlbumList albumBlankCard">
                      <div className="topAction d-flex justify-content-end align-items-center disabledNew">
                        <img
                          src={EditIcon}
                          alt=""
                          className="icon pointer ml-auto"
                        />
                        <img
                          src={TrashIcon}
                          alt=""
                          className="icon pointer ml-3"
                        />
                      </div>
                      <div
                        className="keepsakeAlbumListImgWrapper mb-3 pointer"
                        onClick={AddBrowImgModal}
                      >
                        <div className="plusIcon">
                          <img src={PlusIcon} alt="" className="icon pointer" />
                        </div>
                      </div>

                      <div className="form-label captionLabel disabledNew">
                        Caption:
                      </div>
                      <div className="keepsakeAlbumListContent textareaBox p-3 disabledNew"></div>
                    </div>

                    {keepsakeAlbumAll.map((x, i) => (
                      <div key={i}>
                        <div className="date">
                          {moment(x.date).format("MMMM D YYYY")}
                        </div>
                        <div className="keepsakeAlbumListWrapper">
                          {x.images.map((d, i) => (
                            <div className="keepsakeAlbumList" key={i}>
                              <div className="topAction d-flex justify-content-end align-items-center">
                                <img
                                  src={EditIcon}
                                  alt=""
                                  className="icon pointer ml-auto"
                                  onClick={() => handelEditMedia(d.id, d)}
                                />

                                <img
                                  src={TrashIcon}
                                  alt=""
                                  className="icon pointer ml-3"
                                  onClick={() => handelDeleteMedia(d.photo_url)}
                                />
                              </div>
                              <div className="keepsakeAlbumListImgWrapper mb-3">
                                {d.mime_type == "image" ? (
                                  <img
                                    src={d.photo_url}
                                    alt=""
                                    className="listImg"
                                  />
                                ) : (
                                  <video
                                    width="400"
                                    controls
                                    className="videoHeight"
                                  >
                                    <source
                                      src={d.photo_url}
                                      type="video/mp4"
                                    />
                                    Your browser does not support HTML video.
                                  </video>
                                )}
                              </div>
                              <div className="keepsakeAlbumListContent p-3">
                                <div
                                  className="two-line-trucate"
                                  title={d.caption}
                                >
                                  {d.caption}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    <Modal
                      isOpen={deleteMedia}
                      toggle={handelDeleteMedia}
                      {...args}
                      className="deleteEverythingModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="deleteEverythingHeader border-bottom mb-4 pb-3">
                          <img
                            src={DeleteEverythingInfoIcon}
                            alt=""
                            className="deleteEverythingInfoIcon"
                          />
                          <div className="head">Trash Media File?</div>
                        </div>

                        <Row className="mb-2 ">
                          <Col>
                            {DeleteData != null &&
                              DeleteData.mime_type == "video" ? (
                              <>
                                <video
                                  width="400"
                                  controls
                                  className="videoHeight"
                                >
                                  <source
                                    src={
                                      DeleteData != null && DeleteData.photo_url
                                    }
                                    type="video/mp4"
                                  />
                                  Your browser does not support HTML video.
                                </video>
                              </>
                            ) : (
                              <>
                                <img
                                  alt="Organization Name"
                                  src={
                                    DeleteData != null
                                      ? DeleteData.photo_url
                                      : DummyImg
                                  }
                                  className="mb-5 deleteMediaImg w-100"
                                />
                              </>
                            )}

                            <p className="bold">
                              File Name:
                              {DeleteData != null ? DeleteData.filename : ""}
                            </p>
                            <p className="bold">
                              Used by:{" "}
                              {DeleteData != null ? DeleteData.total_storyz : 0}{" "}
                              Storyz ,{" "}
                              {DeleteData != null ? DeleteData.total_event : 0}{" "}
                              Events
                            </p>
                            <p></p>
                            <p></p>
                            <p>
                              This will delete the file from your archive
                              entirely.
                            </p>
                            <p>
                              If you used this file in a Storyz, event, or
                              Keepsake Album, you will need to replace it with
                              another file. You will receive a notification of
                              the affected files in your Notifications area.
                            </p>
                            <p>
                              If you want to replace this file with different
                              media, select “replace”.
                            </p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0">
                        <Button
                          color="primary"
                          className="flex-grow-1 pinkBtn w-100 boxShadowNone"
                          onClick={() =>
                            deleteData(
                              DeleteData != null
                                ? DeleteData.photo_url
                                : DummyImg
                            )
                          }
                        >
                          Trash
                        </Button>

                        <Button
                          color="primary"
                          className="flex-grow-1 blueBtn w-100 border-radius10 boxShadowNone"
                        >
                          Import Replacement
                        </Button>
                        <Button
                          className="btn-default cancelBtn w-100 boxShadowNone"
                          onClick={handelDeleteMedia}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {imgModalBox && (
                      <>
                        <div
                          className={`mediaModalWrapper ${imgModalBox ? "ShowModal" : ""
                            }`}
                        >
                          <div
                            className="closeIcon"
                            onClick={() => setImgModalBox(false)}
                          >
                            <svg
                              className="closeBtn"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                            </svg>
                          </div>
                          <div className="mediaModalHeaderWrapper">
                            <span className="mediaLogo">
                              <i className="fa fa-image mediaLogoIcon"></i>
                            </span>{" "}
                            {EditID != 0
                              ? "Edit Image Information"
                              : "Add Image Information"}
                          </div>
                          <div className="mediaImageWrapper">
                            <div className="date">
                              {" "}
                              {moment(new Date()).format("MMMM D YYYY")}
                            </div>
                            <input
                              type="hidden"
                              className="form-control"
                              id="edit_id"
                              name="edit_id"
                              placeholder="edit_id"
                              value={EditID}
                            />
                            <div className="imageCropperWrapper">
                              {/* <div
                                className="uploadImageFromLocalContent"
                                onClick={AddBrowImgModal}
                              >
                                <i className="fa fa-plus faPlus"></i>
                              </div> */}

                              {allimage2.mimetype == "video" ? (
                                <>
                                  <video
                                    // width="400"
                                    controls
                                    className="imageCropper"
                                  >
                                    <source
                                      src={allimage2.imaDatajson.url}
                                      type="video/mp4"
                                    />
                                    Your browser does not support HTML video.
                                  </video>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={allimage2.imaDatajson.url}
                                    alt="w"
                                    className="imageCropper"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="mediaUserDetails">
                            <div className="mediaUserImgWrapper">
                              <img
                                src={
                                  guestprofile == "" ? DummyImg : guestprofile
                                }
                                className="mediaUserImg"
                                alt=""
                              />
                            </div>
                            <div className="mediaUserContentWrapper flex-column">
                              <div className="mediaUserTitle">{guestName}</div>
                              <div className="mediaUserDesignation">
                                {guestrole}
                              </div>
                            </div>
                          </div>

                          <div className="filenameWrapper">
                            File name: <span>{allimage2.filename}</span>
                          </div>
                          <div className="mediaFormWrapper">
                            <div className="formBox">
                              <label className="form-label">Title</label>
                              <input
                                type="text"
                                className="form-control"
                                id="photo_label"
                                name="photo_label"
                                placeholder="Image Label"
                                defaultValue={photolabel}
                                onChange={(e) => setPhotolabel(e.target.value)}
                              />
                            </div>
                            <div className="formBox">
                              <label className="form-label">Caption:</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="photo_caption"
                                name="photo_caption"
                                placeholder="photo caption"
                                defaultValue={Photocaption}
                                onChange={(e) =>
                                  setPhotocaption(e.target.value)
                                }
                              />
                            </div>
                            <div className="formBox">
                              <label className="form-label">Location:</label>
                              <div className="locationMap locationgooglemap12">
                                <LoadScript
                                  googleMapsApiKey={mapkey}
                                  libraries={["places"]}
                                >
                                  <StandaloneSearchBox
                                    onLoad={(ref) => (inputRef.current = ref)}
                                    onPlacesChanged={handlePlaceChanged}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="gallerylocation"
                                      placeholder="type in a name, or use the map..."
                                      defaultValue={gallerylocation}
                                    //   onChange={(e) => setGallerylocation(e.target.value)}
                                    />
                                  </StandaloneSearchBox>
                                </LoadScript>

                                <div
                                  className="map pointer"
                                  onClick={handelMapModal}
                                >
                                  <svg
                                    className="diamondTrunImg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M227.7 11.7c15.6-15.6 40.9-15.6 56.6 0l216 216c15.6 15.6 15.6 40.9 0 56.6l-216 216c-15.6 15.6-40.9 15.6-56.6 0l-216-216c-15.6-15.6-15.6-40.9 0-56.6l216-216zm87.6 137c-4.6-4.6-11.5-5.9-17.4-3.5s-9.9 8.3-9.9 14.8v56H224c-35.3 0-64 28.7-64 64v48c0 13.3 10.7 24 24 24s24-10.7 24-24V280c0-8.8 7.2-16 16-16h64v56c0 6.5 3.9 12.3 9.9 14.8s12.9 1.1 17.4-3.5l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80z" />
                                  </svg>
                                  Map
                                </div>
                              </div>
                            </div>
                            <div className="actionWrapper">
                              {EditID == 0 ? (
                                <button
                                  type="submit"
                                  className="btn confirmBtn"
                                  onClick={() => saveData()}
                                >
                                  Save Image Meta-Data
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn confirmBtn"
                                  onClick={() => updateData()}
                                >
                                  Update Image Meta-Data
                                </button>
                              )}
                              <button
                                type="submit"
                                className="btn CancelBtn"
                                onClick={() => setImgModalBox(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                        <div className="mediaOverlay"></div>
                      </>
                    )}
                    {modalShow21 && (
                      <Filemanager
                        show={modalShow21}
                        onHide={handleClick12}
                        onClickimageUpdate={imageUpdate}
                        list={allimage}
                        setAllimage2={setAllimage2}
                        seletedImage={seletedImageClike}
                        setImgModalBox={setImgModalBox}
                        storyz_id={params.sid}
                        event_id={params.eid}
                        guest_user_id={params.id}
                        datakeepskeAlbum={handleClick}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </Eventstyle>
    </>
  );
};

export default KeepsakeAlbumUpload;
