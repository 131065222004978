import styled from "styled-components";
export const CombinedGuestPool = styled.div`
  .CombinedGuestPoolWrapper {
    width: 100%;
  }
  .formInput {
    width: 250px;
    height: 40px;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
    &::placeholder {
      color: #979798;
      font-style: italic;
    }
  }

  .formSelect {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1s2u09g-control {
      background: rgba(83, 215, 105, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      flex-wrap: nowrap;
    }
    .css-6j8wv5-Input {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: #666666;
    }
  }

  .guestSearchWrapper {
    display: flex;
    align-items: center;
  }
  .searchWrapper {
    display: flex;
    align-items: center;
    margin-right: 8px;
    .searchBtn {
      background: #6750a4;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      color: #fdfdfd;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-right: 8px;
    }
    .searchInput {
      width: 250px;
    }
  }
  .showEntries {
    letter-spacing: 0.4px;
    color: #666666;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    span {
      margin-right: 8px;
    }

    .enteriesDropdown {
      margin-right: 8px;
      width: 120px;
    }
  }
  .guestUserWrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    .exportWrap,
    .addGuest {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #49454f;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 4px 12px;
      margin-left: 16px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: auto;
        color: #666666;
        margin-bottom: 4px;
      }
    }
  }

  .tableWrapperOuter {
    margin-bottom: 40px;
    height: 400px;
    overflow: auto;
    display: block;
  }
  .table {
    width: 100%;
    thead {
      border: 1px solid #d7d7d7;
    }
    th,
    td {
      text-transform: none;
      border-top: none;
    }
    th {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #000000;
      background: rgba(207, 207, 207, 0.15) !important;
      border-bottom: 1px solid #d7d7d7 !important;
    }
  }
  .RSVPchip {
    border-radius: 6px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1px;
    color: #49454f;
    text-transform: uppercase;
    min-width: 72px;
    height: 24px;
    cursor: pointer;
    padding: 0 4px;
    &.pending {
      background: #fff2c8;
    }
    &.success {
      background: rgba(83, 215, 105, 0.2);
    }
    &.failed {
      background: rgba(252, 49, 88, 0.2);
    }
  }
  .table {
  }
  .userIcon {
    color: #666;
  }
  .guestUserCard {
    display: flex;
    align-items: center;
    .guestUserPic {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      .pic {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .guestRole {
      font-weight: 300;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.6px;
      color: #000000;
      text-align: center;
      padding-top: 8px;
    }
    .guestUserBody {
      .title {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #1f1f1f;
      }
    }
  }
  .dottedBtn {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 100%;
    background: rgba(19, 69, 165, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      fill: rgba(19, 69, 165, 0.5);
      width: 24px;
      height: 24px;
    }
  }

  .userRightTableWrapper {
    overflow-x: auto;
  }
  .contactIcon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 2px 8px;
    svg {
      font-size: 16px;
      fill: #666666;
    }
  }
  .socialIcon {
    margin: 0 8px;
    display: inline-block;
  }

  .messageBtn {
    background: #e7e7e7;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    letter-spacing: 0.4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #979798;
    padding: 4px 12px;
    height: 32px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 8px;
      color: #666;
      height: 16px;
      width: 16px;
    }
    &.active {
      background: rgba(83, 215, 105, 0.1);
      color: #000000;
    }
  }

  .eventCard {
    height: 40px;
    background: #ffffff;
    border-radius: 6px;
    padding: 4px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1f1f1f;
    .img {
      width: 48px;
      height: 32px;
      border-radius: 6px;
      margin-right: 8px;
      object-fit: cover;
    }
  }
`;