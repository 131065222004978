import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Allheader from "../../components/Headers/AllheaderEvent";
import Imggroup from "../../assets/img/dummyImg.svg";
import Eventabout from "../../components/Events/eventabout";
import { eventsService } from "../../_services/masters/events.service";
import { informationService } from "../../_services/masters/information.service";
import { useParams } from "react-router-dom";
import { commonData } from "store/actions";
function EventAbout() {
  const params = useParams();
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  // useEffect(() => {
  //     document.body.classList.remove("g-sidenav-pinned");
  //     document.body.classList.remove("g-sidenav-show");
  //     document.body.classList.add("g-sidenav-hidden");
  // });
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (params?.eid) {
      const id = { event_id: params?.eid };
      eventsService.eventDetails(id).then(
        (res) => {
          if (res.status === "success") {
            let sid = res.data.storyz_id;
            let eid = res.data.id;
            let storyName = res.data.storyz_name;
            let singleEvent = res.data;
            dispatch(commonData(common));
            myFunctionList(sid, eid, storyName, singleEvent);
          } else {
            console.log("Something went wrong !!");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }, [params]);
  function myFunctionList(sid, eid, storyName, singleEvent) {
    informationService.eventListId(sid).then(
      (res) => {
        if (res.status === "success") {
          const value = {
            sid: sid,
            eid: eid,
            storyName: storyName,
            singleEvent: singleEvent,
            eventList: res.data,
          };
          dispatch(commonData(value));
        } else {
          console.log("Something went wrong !!");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
      }
    );
  }

  return (
    <>
      {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventabout />
        </div>
      </div>
    </>
  );
}
export default EventAbout;
