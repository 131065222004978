import React, { useState } from "react";
import SimpleTabs from "./Async";
import Index from "./index";

const Tabs = (props) => {
    return (
        <>
            <SimpleTabs eventdata={props.eventdetails} />
        </>
    );
};

export default Tabs;
