import React, { useEffect } from "react";
import { Modal, Button } from "reactstrap";
import { timezoneService } from "../../_services/masters/timezone.service";
import { useForm } from "react-hook-form";
import * as actionTypes from "../../store/actionTypes";
import { userGuestService } from "../../_services/masters/userGuest.service";
import { useDispatch, useSelector } from "react-redux";
import { useFindPathEid } from "../../CommonData";
function Timezoneset() {
  let eid = useFindPathEid(4);
  const [eventInfo, setEventInfo] = React.useState({ timevalue: "12" });
  const [datekey, setDataKey] = React.useState();
  const contact = useSelector((state) => {
    return state.eventSave.event;
  });
  const dispatch = useDispatch();
  const [notificationModalacouno, setnotificationModalacouno] =
    React.useState(false);
  const [timezoneData, setTimezoneData] = React.useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    timezoneService.timezoneList().then(
      (res) => {
        if (res.status === "success") {
          setTimezoneData(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  const handleChangeinput = (e) => {
    setEventInfo({ ...eventInfo, [e.target.name]: e.target.value });
  };

  const onSelect = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    setDataKey(e.target.options[selectedIndex].getAttribute("data-key"));
    setEventInfo({ ...eventInfo, [e.target.name]: e.target.value });
  };
  // {
  //   "abbr": datekey,
  //   "timezone": eventInfo.timezone,
  //   "timevalue": eventInfo.timevalue,
  // }
  const save = () => {
    const data12 = {
      abbr: datekey,
      timezone: eventInfo.timezone,
      timevalue: eventInfo.timevalue,
    };
    const data = [
      {
        id: eid,
        key_name: "timezone",
        key_value: datekey,
      },
      {
        id: eid,
        key_name: "clock_format",
        key_value: eventInfo.timevalue,
      },
      {
        id: eid,
        key_name: "timezone_id",
        key_value: eventInfo.timezone,
      },
    ];

    userGuestService.eventUpdate(data).then((res) => {
      if (res.status === "success") {
        //toast.success('update successfully !!', "Account");
        const value = {
          ...contact,
          time: data12,
        };
        dispatch({ type: actionTypes.EVENT_SAVE, payload: value });
        setnotificationModalacouno(false);

      } else {
        // toast.success('Something went wrong !!', "error");
      }
    });
  };
  return (
    <>
      <div className="imageeventim">
        <div className="imageeventim2">
          <div>
            <span className="timezone">
              Timezone:{" "}
              <span className="smallText"> {contact?.time?.abbr}</span>
            </span>
            <br />
            <span className="timezone">
              Clock:{" "}
              <span className="smallText">
                {contact?.time?.timevalue
                  ? `${contact?.time?.timevalue} hour`
                  : ""}
              </span>
            </span>
          </div>
        </div>
        <div
          className="imageeventim22 pointer"
          style={{ marginLeft: "25px" }}
          onClick={() => setnotificationModalacouno(true)}
        >
          <span>Change</span>
        </div>
        <Modal
          className="ChangeTimezoneModalWrapper"
          isOpen={notificationModalacouno}
          toggle={() => setnotificationModalacouno(false)}
        >
          <div className="modal-body">
            <h4 className="ChangeTimezone border-bottom">
              <img
                alt="Organization Name"
                src={require("assets/img/Icon-Text-in-Circle.svg").default}
                className="imagevent"
              />
              Change Timezone and Clock Style.....?
            </h4>

            <div className="changeTimezoneForm">
              <div className="formBox form-group">
                <label for="sel1" className="form-label">
                  Timezone for the Organization:
                </label>
                <select
                  className="form-control"
                  id="sel1"
                  name="timezone"
                  {...register("timezone")}
                  onChange={onSelect}
                >
                  <option value="">Select</option>
                  {timezoneData?.map((post, index) => (
                    <option key={index} data-key={post.abbr} value={post.id}>
                      {post.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formBox form-group">
                <label for="sel1" className="form-label">
                  Clock Style:
                </label>
                <select
                  className="form-control"
                  id="sel1"
                  name="timevalue"
                  {...register("timevalue")}
                  onChange={handleChangeinput}
                >
                  <option value="12">12-Hour Clock</option>
                  <option value="24">24-Hour Clock</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="pinkBtn flex-grow-1"
              color="default"
              type="button"
              onClick={save}
            >
              Change
            </Button>
            <Button
              className="btn-white cancelBtn"
              color="default"
              type="button"
              onClick={() => setnotificationModalacouno(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default Timezoneset;

// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

// function MyVerticallyCenteredModal(props) {
//     const contact = useSelector((state) => {
//         return state.eventSave.event;
//     })
//     const dispatch = useDispatch();
//     const {
//         register,
//         handleSubmit,
//         formState: { errors },
//     } = useForm();
//     const onSubmit = (data) => {
//         const value = {
//             ...contact,
//             time: data
//         }
//         dispatch({ type: actionTypes.EVENT_SAVE, payload: value });
//         //dispatch({ type: actionTypes.EVENT_SAVE, payload: data });
//         //setnotificationModalacouno(false)
//         // props.onHide
//     };
//     const [timezoneData, setTimezoneData] = React.useState();
//     return (
//         <Modal
//             {...props}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >

//             <Modal.Body>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                     <div className="modal-body mt-4">
//                         <div className="">
//                             <h4 className="text-center ChangeTimezone">
//                                 <img
//                                     alt="Organization Name"
//                                     src={
//                                         require("assets/img/Icon-Text-in-Circle.svg")
//                                             .default
//                                     }
//                                     className="imagevent"
//                                 />
//                                 <br />
//                                 Change Timezone and Clock Style?
//                             </h4>
//                             <hr style={{ marginTop: "0px" }} />
//                             <div className="">
//                                 <label for="sel1" className="form-label">
//                                     Timezone for the Organization:
//                                 </label>
//                                 <select
//                                     className="form-control"
//                                     id="sel1"
//                                     name="timezone"
//                                     {...register('timezone')}
//                                 >

//                                     {timezoneData?.map((post, index) =>

//                                         <option key={index} value={post.abbr}>{post.text}</option>
//                                     )}

//                                 </select>
//                             </div>
//                             <div className="mt-2">
//                                 <label for="sel1" className="form-label">
//                                     Clock Style:
//                                 </label>
//                                 <select
//                                     className="form-control"
//                                     id="sel1"
//                                     name="timevalue"
//                                     {...register('timevalue')}
//                                 >
//                                     <option value="12">12-Hour Clock</option>
//                                     <option value="24">24-Hour Clock</option>
//                                 </select>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <Button
//                             className="btn-white"
//                             color="default"
//                             type="submit"
//                             style={{
//                                 background: "#FC3158",
//                                 color: "#fff",
//                                 width: "76%",
//                                 borderRadius: "10px",
//                             }}

//                         >
//                             Change
//                         </Button>
//                         <Button
//                             className="btn-white"
//                             color="default"
//                             type="button"
//                             onClick={props.onHide}
//                             style={{
//                                 border: "1px solid #147EFB",
//                                 borderRadius: "10px",
//                             }}
//                         >
//                             Cancel
//                         </Button>
//                     </div>
//                 </form>
//             </Modal.Body>

//         </Modal>
//     );
// }

// function Timezoneset() {
//     const [modalShow, setModalShow] = React.useState(false);
//     const contact = useSelector((state) => {
//         return state.eventSave.event;
//     })
//     return (
//         <>
//             <div className="imageeventim">
//                 <div className="imageeventim2">
//                     <div>
//                         <span>Timezone: {contact.timezone}</span>
//                         <br />
//                         <span>Clock: {contact.timevalue ? `${contact.timevalue} hour` : ''}</span>
//                     </div>
//                 </div>
//                 <div
//                     className="imageeventim22"
//                     style={{ marginLeft: "25px" }}
//                     onClick={() => setModalShow(true)}
//                 >
//                     <span>Change</span>
//                 </div>

//                 <MyVerticallyCenteredModal
//                     show={modalShow}
//                     onHide={() => setModalShow(false)}
//                 />
//             </div>
//         </>
//     );
// }

// export default Timezoneset;
