import React, { useState } from "react";
import TodoIcon from "./../../assets/img/todo-icon.svg";
import ArrowBtn from "./../../assets/img/arrow-btn.svg";
import statusClose from "./../../assets/img/status-close.svg";
import statusCheck from "./../../assets/img/status-check.svg";
import statusRefresh from "./../../assets/img/status-refresh.svg";
import statusNotification from "./../../assets/img/status-notification.svg";

import { todoService } from "_services/masters/information.todos";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import placeholde from "../../assets/img/placeholder.jpg";
import "react-toastify/dist/ReactToastify.css";
import format from "date-fns/format";
import { useFindPathEid, useFindPathSid } from "../../CommonData";
import DatePicker from "react-datepicker";
import validUrl from "valid-url";
// import mL from "_helpers/month";

const CreateTodo = ({ args, title, storyz, events, organization, refresh }) => {
  let UserData = JSON.parse(localStorage.getItem("userDetails"));
  let org_id = UserData.orgData.length > 0 ? UserData.orgData[0].id : 0;
  let org_name = UserData.orgData.length > 0 ? UserData.orgData[0].company_name : "";
  let org_img = UserData.orgData.length > 0 ? UserData.orgData[0].logo : "";

  let event_name = localStorage.getItem("todo_event_name");
  let event_img = localStorage.getItem("todo_event_img");
  let storyz_name = localStorage.getItem("todo_storyz_name");
  let storyz_img = localStorage.getItem("todo_storyz_img");

  const [todoModal, setTodoModal] = useState(false);
  const [active, setActive] = useState("Not Started");
  const [name, setName] = useState(null);
  // const [duedate, setDuedate] = useState(null);
  // const [assigned, setAssigned] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [shortdes, setShortdes] = useState(null);
  const [url, setUrl] = useState(null);

  const [name_error, setNameError] = useState("");
  const [url_error, setUrlError] = useState("");

  const [duedate_error, setDuedateError] = useState("");
  const [assigned_error, setAssignedError] = useState("");

  const countryData = JSON.parse(localStorage.getItem("userdata"));
  const countryCode = countryData.country_code;

  // console.log("country Code",countryCode);

  let todayDate = new Date();
  localStorage.setItem("todayDate", todayDate);
  localStorage.getItem("todayDate");
  // console.log("Local storage",localStorage.getItem("todayDate"));

  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  if (day.toString().length === 1) {
    day = "0" + day;
  }

  let Nowday = day + "/" + month + "/" + year;
  //  console.log("Now day", Nowday);

  // let urlArr = window.location.href.split('/');

  let event_id = useFindPathEid(4);
  let storyz_id = useFindPathSid(3);

  const TodoModal = () => {
    setTodoModal(!todoModal);
  };

  const addAnother = () => {
    if (url && !validUrl.isUri(url)) {
      setUrlError("Is Not Valid URL");
      setTimeout(() => {
        setUrlError("");
      }, 3000);
    } else if (name === "" || name === null) {
      setNameError("This field required");
      setTimeout(() => {
        setNameError("");
      }, 3000);
    } else if (startDate === "" || startDate === null) {
      setAssignedError("This field required");
      setTimeout(() => {
        setAssignedError("");
      }, 3000);
    } else if (endDate === "" || endDate === null) {
      setDuedateError("This field required");
      setTimeout(() => {
        setDuedateError("");
      }, 3000);
    } else if (startDate > endDate) {
      setDuedateError("Assigned date should be less than Due date");
      setTimeout(() => {
        setDuedateError("");
      }, 3000);
    } else {
      if (events === true) {
        let todo_Eventdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a "),
          due_date: format(endDate, "yyyy-MM-dd"),
          todo_status: active,
          status: 1,
          event_id: event_id,
          todo_type: "Event",
        };
        todoService.saveEventTodo(todo_Eventdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (storyz === true) {
        let todo_Storyzdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a"),
          due_date: format(endDate, "yyyy-MM-dd h:mm a"),
          todo_status: active,
          status: 1,
          storyz_id: storyz_id,
          todo_type: "Storyz",
        };
        //console.log(todo_Storyzdata);
        todoService.saveStoryzTodo(todo_Storyzdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (organization === true) {
        let todo_Organizationdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a"),
          due_date: format(endDate, "yyyy-MM-dd h:mm a"),
          todo_status: active,
          status: 1,
          org_id: org_id,
          todo_type: "Organization",
        };
        todoService.saveOrganizationTodo(todo_Organizationdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }

      setActive("Not Started");
      setName("");
      setStartDate(new Date());
      setEndDate(null);
      setShortdes("");
      setUrl("");
    }
    refresh();
  };

  const add = () => {
    if (url && !validUrl.isUri(url)) {
      setUrlError("Is Not Valid URL");
      setTimeout(() => {
        setUrlError("");
      }, 3000);
    } else if (name === "" || name === null) {
      setNameError("This field required");
      setTimeout(() => {
        setNameError("");
      }, 3000);
    } else if (startDate === "" || startDate === null) {
      setAssignedError("This field required");
      setTimeout(() => {
        setAssignedError("");
      }, 3000);
    } else if (endDate === "" || endDate === null) {
      setDuedateError("This field required");
      setTimeout(() => {
        setDuedateError("");
      }, 3000);
    } else if (startDate > endDate) {
      setDuedateError("Assigned on date should be greater than  Due date");
      setTimeout(() => {
        setDuedateError("");
      }, 3000);
    } else {
      if (events === true) {
        let todo_Eventdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a"),
          due_date: format(endDate, "yyyy-MM-dd h:mm a"),
          todo_status: active,
          status: 1,
          event_id: event_id,
          todo_type: "Event",
        };
        todoService.saveEventTodo(todo_Eventdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (storyz === true) {
        let todo_Storyzdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a"),
          due_date: format(endDate, "yyyy-MM-dd h:mm a"),
          todo_status: active,
          status: 1,
          storyz_id: storyz_id,
          todo_type: "Storyz",
        };
        todoService.saveStoryzTodo(todo_Storyzdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      } else if (organization === true) {
        let todo_Organizationdata = {
          todo_name: name,
          short_description: shortdes,
          knowledge_base_url: url,
          assignee_id: 0,
          assigned_by: 0,
          assigned_on: format(startDate, "yyyy-MM-dd h:mm a"),
          due_date: format(endDate, "yyyy-MM-dd h:mm a"),
          todo_status: active,
          status: 1,
          org_id: org_id,
          todo_type: "Organization",
        };

        todoService.saveOrganizationTodo(todo_Organizationdata).then(
          (res) => {
            if (res.status === "success") {
              refresh();
              toast.success("saved successfully");
            } else {
              toast.error("not saved successfully");
            }
          },
          (error) => {
            console.log("Something went wrong !!");
            toast.error("Something went wrong !!", "Fashion Store");
          }
        );
      }

      setActive("Not Started");
      setName("");
      setStartDate(new Date());
      setEndDate(null);
      setShortdes("");
      setUrl("");
      TodoModal();
    }
    refresh();
  };

  //   const handleChangeinput = (e) => {
  //     eventsInfo[e.targe.name] = e.targe.value;
  //     setEventsInfo({ ...eventsInfo });
  // };
  const UpdateSetActive = (value) => {
    setActive(value);
  };

  return (
    <>
      <ToastContainer />
      <div className="todoIconWrapper" onClick={TodoModal}>
        <img src={TodoIcon} alt="" className="toIcon" />
        {title}
      </div>
      <Modal
        isOpen={todoModal}
        toggle={TodoModal}
        {...args}
        className="TodoModalWrapper"
      >
        <ModalHeader toggle={TodoModal}>Add Todo</ModalHeader>
        <ModalBody className="p-2 modalContainerScroll">
          <div className="formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Todo Name
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="todo name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="mb-0 error">{name_error}</div>
          </div>

          {organization && (
            <div className="todoThumWrapper">
              <div className="orgTodoLogo">
                <img src={org_img} alt="" className="todoThumImg" />
              </div>
              <div className="todoThumContent">
                <div className="todothumbTitle">{org_name}</div>
                <div className="todothumbSubTitle">Service Level</div>
              </div>
            </div>
          )}

          {storyz && (
            <div className="todoThumWrapper">
              {/* <div className="todoDate">Aug 14</div> */}
              <div className="todoThum">
                <img
                  src={storyz_img ? storyz_img : placeholde}
                  alt="storyz img"
                  className="todoThumImg"
                />
              </div>
              <div className="todoThumContent">
                <div className="todothumbTitle">Storyz: {storyz_name}</div>
                {/* <div className="todothumbSubTitle">Event: {event_name}</div> */}
              </div>
            </div>
          )}

          {events && (
            <div className="todoThumWrapper">
              <div className="todoDate">Aug 14</div>
              <div className="todoEventThumb">
                <img src={event_img ? event_img : placeholde} alt="event img" className="todoThumImg" />
              </div>
              <div className="todoThumContent">
                <div className="todothumbTitle">{event_name}</div>
                <div className="todothumbSubTitle">Location</div>
              </div>
            </div>
          )}

          <div className="statusWrapper mb-3">
            <div className="form-label">Status:</div>
            <div className="statusListWrapper">
              <div
                className={`statusList d-flex align-items-center ${active === "Not Started" ? "active" : ""
                  }`}
                onClick={() => UpdateSetActive("Not Started")}
              >
                <img src={statusClose} alt="" className="icon" />
                Not Started
              </div>
              <div
                className={`statusList d-flex align-items-center ${active === "In Progress" ? "active" : ""
                  }`}
                onClick={() => UpdateSetActive("In Progress")}
              >
                <img src={statusRefresh} alt="" className="icon" /> In Progress
              </div>
              <div
                className={`statusList d-flex align-items-center ${active === "Completed" ? "active" : ""
                  }`}
                onClick={() => UpdateSetActive("Completed")}
              >
                <img src={statusCheck} alt="" className="icon" /> Completed
              </div>
              <div
                className={`statusList d-flex align-items-center ${active === "Overdue" ? "active" : ""
                  }`}
                onClick={() => UpdateSetActive("Overdue")}
              >
                <img src={statusNotification} alt="" className="icon" /> Overdue
              </div>
            </div>
          </div>
          <div className="formBox dateWrapper">
            <div>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Assigned on:
              </label>
              <div className="dateTimePickerGlobal">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className={"form-control"}
                  placeholderText={"dd/MM/yyyy"}
                  // dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy h:mm a"
                  locale="en-GB"
                  minDate={new Date()}
                  onFocus={(e) => e.target.blur()}
                />
              </div>
              <div className="error">{assigned_error}</div>
              <div className="form-text">
                {/* {Nowday}
                in your local timezone
                {countryCode === "US" ? "(PST)" : "(IST)"} */}
              </div>
            </div>
            <div>
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Date Due:
              </label>
              <div className="dateTimePickerGlobal">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className={"form-control"}
                  placeholderText={"dd/MM/yyyy"}
                  // dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy h:mm a"
                  minDate={new Date(startDate)}
                  onFocus={(e) => e.target.blur()}
                />
              </div>
              <div className="error">{duedate_error}</div>
              <div className="form-text">
                {Nowday} in your local timezone
                {countryCode === "US" ? "(PST)" : "(IST)"}
              </div>
            </div>
          </div>
          <div className="formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Short Description:
            </label>
            <textarea
              type=""
              className="form-control"
              placeholder=""
              value={shortdes}
              rows="3"
              onChange={(e) => setShortdes(e.target.value)}
            ></textarea>
          </div>
          <div className="formBox">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Address of Knowledge Base (URL):
            </label>
            <div className="d-flex align-items-center">
              <input
                type="url"
                className="form-control"
                placeholder="Field Content"
                value={url}
                // onChange={(e) => validate(e.target.value)}
                onChange={(e) => setUrl(e.target.value)}
              />

              {url !== "" && validUrl.isUri(url) ? (
                <>
                  <a href={url} target="_blank">
                    <img src={ArrowBtn} alt="" className="arrowBtn ml-1" />
                  </a>
                </>
              ) : (
                <>
                  <img src={ArrowBtn} alt="" className="arrowBtn ml-1" />
                </>
              )}
            </div>
            <div className="mb-0 error">{url_error}</div>
          </div>
        </ModalBody>
        <ModalFooter className="justify-centent-center">
          <Button onClick={add} className="blueBtn">
            Save
          </Button>
          <Button onClick={addAnother} className="blueBtn flex-grow-1">
            Save and Add Another
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateTodo;
