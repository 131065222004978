import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  name: "",
  desc: "",
  image: {},
  units: "english",
  geoId: 1
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeSettingName(state, action) {
      state.name = action.payload
    },
    changeSettingDesc(state, action) {
      state.desc = action.payload
    },
    changeSettingImage(state, action) {
      state.image = action.payload
    },
    changeSettingUnits(state, action) {
      state.units = action.payload
    },
    setGeoId(state, action) {
      state.geoId = action.payload
    }
  }
})

export const {
  changeSettingName,
  changeSettingUnits,
  changeSettingDesc,
  changeSettingImage,
  setGeoId
} = settingsSlice.actions
export default settingsSlice.reducer
