import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import Autocomplete from '../../components/autocomplete'
import { Row, Col, FormGroup, Modal, ModalHeader, ModalBody } from "reactstrap";
import MapPicker from 'react-google-map-picker'
import { informationService } from "../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Global from "../../_helpers/global";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actionTypes";
import { GrCircleInformation } from "react-icons/gr";
import Filemanager from "../../components/CommonMediaModal/Filemanager";
import { ReactSession } from "react-client-session";

import { BsBoxArrowLeft } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import GlobalButton from "components/Button/GlobalButton";
import CombinedGuestPoolHeader from "components/Headers/CombinedGuestPoolHeader";
import OrgEye from "./../../assets/img/org-eye.svg";

const DefaultZoom = 10;

function OrganizationSelf() {
  const [seoFullscreen, setSeoFullscreen] = useState(false);
  const [geoSpaceModal, setGeoSpaceModal] = useState(false);
  const [allimage, setAllimage] = useState([]);
  const dispatch = useDispatch();
  const contact = useSelector((state) => {
    return state.profile;
  });
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });

  const [openMap, setOpenmap] = React.useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpenmap(true); // count is 0 here
    }, 1000);
    // Update count to be 5 after timeout is scheduled
  }, []);

  const DefaultLocation = { lat: 25.750891, lng: -80.199913 };
  // const [picture, setPicture] = React.useState(null);
  const [imgData, setImgData] = React.useState(null);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);

  const [countries, setCountries] = useState([]);
  const [zoom, setZoom] = useState(DefaultZoom);


  const [userRequest, setUserRequest] = useState({
    plus_code: null,
    city: null,
    postal_code: null,
    country: null,
    state: null,
    lat: 25.750891,
    lng: -80.199913,
    addressfull: null,
  });
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });

  }
  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  useEffect(() => {


    informationService.getCountries().then(
      (res) => {
        if (res.status === "success") {
          setCountries(res.data);
        } else {
          toast.error("Something went wrong !! !!", "Account");
        }
      },
      (error) => {
        toast.error("Something went wrong !! !!", "Account");
      }
    );

    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);


  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  // const onChangePicture = (e) => {
  //   console.log(e);
  //   if (e.target.files[0]) {
  //     console.log("picture: ", e.target.files);
  //     setPicture(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       setImgData(reader.result);
  //     });
  //     reader.readAsDataURL(e.target.files[0]);

  //     const imageUrl = URL.createObjectURL(e.target.files[0]);
  //     const formData = new FormData();
  //     //URL.createObjectURL(e.target.files[0])
  //     setOrglogo(e.target.files[0]);

  //     formData.append("org_logo", e.target.files[0]);

  //     // informationService.organizationImage(formData)
  //     //     .then(
  //     //         res => {
  //     //             if (res.status === 'success') {
  //     //                 toast.success("Profile upload successfully !!", "Account");
  //     //                 userdetail();
  //     //             } else {
  //     //                 toast.error("Something went wrong !! !!", "Account");
  //     //             }

  //     //         }, error => {
  //     //             toast.error("Something went wrong !! !!", "Account");

  //     //         }
  //     //     )
  //   }
  // };
  const userdetail = () => {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
      }
    );
  };

  const onClickAddress = e => {

    setUserRequest({
      addressfull: e.formattedAddress ? e.formattedAddress : '',
      city: e.city ? e.city : '',
      state: e.state ? e.state : '',
      postal_code: e.zip ? e.zip : '',
      country: e.country ? e.country : '',
      lat: e.lat ? e.lat : '25.750891',
      lng: e.lng ? e.lng : '-80.199913',
    });


    setValue("address", e.formattedAddress ? e.formattedAddress : '');
    setValue("city", e.city ? e.city : '');
    setValue("state", e.state ? e.state : '');
    setValue("postal_code", e.zip ? e.zip : '');
    setValue("country", e.country ? e.country : '');
    setValue("company_latitude", e.lat ? e.lat : '');
    setValue("company_longitude", e.lng ? e.lng : '');
  }



  const onSubmit = (data) => {

    var form = new FormData();
    form.append("company_name", data.company_name);
    form.append("company_tagline", data.company_tagline);
    form.append("address", data.address);
    form.append("city", data.city);
    form.append("state", data.state);
    form.append("postal_code", data.postal_code);
    form.append("country", data.country);
    form.append("company_latitude", data.company_latitude);
    form.append("company_longitude", data.company_longitude);
    form.append("short_description", data.short_description);

    form.append("org_logo", data.file ? data.file[0] : "");
    form.append("id", data.id);
    informationService.organizationUpdate(form).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          userdetail();
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const [modalShow, setModalShow] = React.useState(false);
  const handleClick = () => {
    setModalShow(false);
  };
  const seletedImageClike = (e) => {
    var obj = JSON.parse(e.img_json);
    let organization_id =
      contact?.profiledetails?.organization[0]?.organization_id;
    let jsonData = e;
    jsonData["org_id"] = organization_id;
    jsonData["imaDatajson"] = obj;
    informationService.updateOrgLogo(jsonData).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          userdetail();
          setModalShow(false);
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };



  const imageUpdate = (e) => {
    let jsonData = e.imaDatajson;
    jsonData[0]["img_address"] = ReactSession.get("formatted_address")
      ? ReactSession.get("formatted_address")
      : "";
    jsonData[0]["lat"] = ReactSession.get("lat") ? ReactSession.get("lat") : "";
    jsonData[0]["lng"] = ReactSession.get("lng") ? ReactSession.get("lng") : "";
    let organization_id =
      contact?.profiledetails?.organization[0]?.organization_id;
    const data = {
      type: "organization",
      org_id: organization_id,
      photo_label: e.photo_label,
      photo_caption: e.photo_caption,
      imaDatajson: jsonData[0],
    };

    informationService.updateOrgLogo(data).then(
      (res) => {
        if (res.status === "success") {
          toast.success(res.message, "Account");
          userdetail();
          ReactSession.set("formatted_address", "");
          ReactSession.set("lat", "");
          ReactSession.set("lng", "");
        } else if (res.status === "error") {
          console.log("2");
        } else {
          console.log("3");
        }
      },
      (error) => {
        console.log("4");
      }
    );
  };

  const handleFullscreen = () => {
    //alert('hello');
    setSeoFullscreen(!seoFullscreen);
  };

  const handelGeoSpaceModal = () => {
    setGeoSpaceModal(!geoSpaceModal);
  };


  useEffect(() => {
    setUserRequest({
      addressfull: contact?.profiledetails.organization[0]?.organization_address ? contact?.profiledetails.organization[0]?.organization_address : '',
      city: contact?.profiledetails.organization[0]
        ?.organization_city
        ? contact?.profiledetails.organization[0]
          ?.organization_city : '',
      state: contact?.profiledetails.organization[0]
        ?.organization_state
        ? contact?.profiledetails.organization[0]
          ?.organization_state : '',
      postal_code: contact?.profiledetails.organization[0]
        ?.organization_postal_code
        ? contact?.profiledetails.organization[0]
          ?.organization_postal_code : '',
      country: contact?.profiledetails.organization[0]
        ?.organization_country
        ? contact?.profiledetails.organization[0]
          ?.organization_country : '',
      lat: contact?.profiledetails.organization[0]
        ?.organization_company_latitude
        ? contact?.profiledetails.organization[0]
          ?.organization_company_latitude : '25.750891',
      lng: contact?.profiledetails.organization[0]
        ?.organization_company_longitude
        ? contact?.profiledetails.organization[0]
          ?.organization_company_longitude : '-80.199913',
    });

    setValue("address", contact?.profiledetails.organization[0]
      ?.organization_address
      ? contact?.profiledetails.organization[0]
        ?.organization_address : '');


    setValue("city", contact?.profiledetails.organization[0]
      ?.organization_city
      ? contact?.profiledetails.organization[0]
        ?.organization_city : '');
    setValue("state", contact?.profiledetails.organization[0]
      ?.organization_state
      ? contact?.profiledetails.organization[0]
        ?.organization_state : '');
    setValue("postal_code", contact?.profiledetails.organization[0]
      ?.organization_postal_code
      ? contact?.profiledetails.organization[0]
        ?.organization_postal_code : '');
    setValue("country", contact?.profiledetails.organization[0]
      ?.organization_country
      ? contact?.profiledetails.organization[0]
        ?.organization_country : '');
    setValue("company_latitude", contact?.profiledetails.organization[0]
      ?.organization_company_latitude
      ? contact?.profiledetails.organization[0]
        ?.organization_company_latitude : '');
    setValue("company_longitude", contact?.profiledetails.organization[0]
      ?.organization_company_longitude
      ? contact?.profiledetails.organization[0]
        ?.organization_company_longitude : '');
  }, [contact])

  return (
    <>
      {/* <Allheader title="Organization Details" /> */}
      <CombinedGuestPoolHeader title="Organization Details" imgScr={OrgEye} />
      <div className="organizerWrapperMain">
        {/* <Row> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="col"> */}
          <div className="card organizerCardWrapper">
            <div className="headingWrapper borderBottom">
              <h4 className="heading-1">About Your Organization</h4>
              <GrCircleInformation className="ml-auto pointer infoIcon" />
              <ReactTooltip />
            </div>
            <div className="organizationPara px-4 pb-4">
              Information about your organization, and workspace setup.
            </div>
            <div className="d-flex px-4 pb-4 borderBottom mb-2">
              {contact?.profiledetails.organization[0]?.organization_id ? (
                <GlobalButton
                  customClass="ml-auto"
                  img={require("assets/img/dashboardIcon.svg").default}
                  type="submit"
                >
                  Update
                </GlobalButton>
              ) : (
                <GlobalButton
                  customClass="ml-auto"
                  img={require("assets/img/dashboardIcon.svg").default}
                  type="submit"
                >
                  Save Changes
                </GlobalButton>
              )}

            </div>
            <Row>
              <Col lg={9} md={7}>
                <Row>
                  <Col md={12} lg={6}>
                    <div className="cardBox">
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            Company Name:
                          </label>
                          <input
                            id="example2cols1Input"
                            placeholder="Company Name"
                            type="text"
                            name="company_name"
                            className="form-control"
                            {...register("company_name", { required: true })}
                            defaultValue={
                              contact?.profiledetails.organization[0]
                                ?.organization_company_name
                                ? contact?.profiledetails.organization[0]
                                  ?.organization_company_name
                                : ""
                            }
                          />
                          {errors.company_name && (
                            <small className="error">
                              Company name is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            Company Tagline:
                          </label>
                          <input
                            id="example2cols1Input"
                            placeholder="Company Tagline"
                            type="text"
                            className="form-control"
                            name="company_tagline"
                            {...register("company_tagline", {
                              required: true,
                            })}
                            defaultValue={
                              contact?.profiledetails.organization[0]
                                ?.organization_company_tagline
                                ? contact?.profiledetails.organization[0]
                                  ?.organization_company_tagline
                                : ""
                            }
                          />
                          {errors.company_tagline && (
                            <small className="error">
                              Company tagline is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea2"
                          >
                            Short Description:
                          </label>
                          <textarea
                            id="exampleFormControlTextarea2"
                            rows="3"
                            className="form-control"
                            placeholder="Short Description"
                            name="short_description"
                            {...register("short_description", {
                              required: true,
                            })}
                            defaultValue={
                              contact?.profiledetails.organization[0]
                                ?.organization_short_description
                                ? contact?.profiledetails.organization[0]
                                  ?.organization_short_description
                                : ""
                            }
                          ></textarea>
                          {errors.short_description && (
                            <small className="error">
                              Short description is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <div className="aboutAccountInfo">
                          <div className="img">
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/theme/Frame239.svg").default
                              }
                            />
                          </div>
                          <div className="professional123">
                            <div className="title">Account Information</div>
                            <div className="subTitle">Professional Plan</div>
                            <div className="subTitle">Next Payment Sep 14</div>
                          </div>
                          <Link
                            to="/admin/billing-account-view"
                            className="gotoBillingBtn"
                          >
                            <img
                              alt="Organization Name"
                              src={
                                require("assets/img/icons/security-1.svg")
                                  .default
                              }
                            />
                            <div className="gotobilling">Goto Billing</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="cardBox">
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            Address:
                          </label>
                          <Autocomplete buttonClicked={onClickAddress} add={userRequest.addressfull} />
                          <input
                            id="example2cols1Input"
                            placeholder="Address"
                            type="hidden"
                            name="address"
                            className="form-control"
                            {...register("address", { required: true })}
                            defaultValue={
                              userRequest.addressfull
                            }
                          />
                          {errors.address && (
                            <small className="error">
                              Address name is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            City:
                          </label>
                          <input
                            id="example2cols1Input"
                            placeholder="City"
                            type="text"
                            className="form-control"
                            name="city"
                            {...register("city", { required: true })}
                            defaultValue={
                              userRequest?.city
                            }
                          />
                          {errors.city && (
                            <small className="error">
                              City Name is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            State or Province:
                          </label>
                          <input
                            id="example2cols1Input"
                            placeholder="State or Province"
                            type="text"
                            className="form-control"
                            name="state"
                            {...register("state", { required: true })}
                            defaultValue={
                              userRequest?.state
                            }
                          />
                          {errors.state && (
                            <small className="error">
                              State Name is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            Country:
                          </label>
                          {/* {userRequest.country} */}
                          <select
                            id=""
                            className="form-control"
                            name="country"
                            {...register("country", { required: true })}
                            value={
                              userRequest.country
                            }
                          >
                            <option>Select Country</option>
                            {countries.map((value, index) => (
                              <option
                                key={index}
                                value={value.country_sortname}
                              >
                                {value.country_name}
                              </option>
                            ))}
                          </select>

                          {errors.country && (
                            <small className="error">
                              Country Name is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                      <div className="formBox">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                          >
                            Postal Code:
                          </label>
                          <input
                            id="example2cols1Input"
                            placeholder="Postal Code"
                            type="text"
                            className="form-control"
                            name="postal_code"
                            {...register("postal_code", { required: true })}
                            defaultValue={
                              userRequest?.postal_code
                            }
                          />
                          {errors.postal_code && (
                            <small className="error">
                              Postal code is required.
                            </small>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                    <FormGroup>
                      <input
                        id="example2cols1Input"
                        placeholder="Postal Code"
                        type="hidden"
                        className="form-control"
                        name="company_latitude"
                        {...register("company_latitude")}
                        defaultValue={
                          userRequest?.lat
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        id="example2cols1Input"
                        placeholder="Postal Code"
                        type="hidden"
                        className="form-control"
                        name="company_longitude"
                        {...register("company_longitude")}
                        defaultValue={
                          userRequest?.lng
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>



              <Col lg={3} md={5}>
                <div className="cardBox">
                  <div className="formBox">
                    <FormGroup>
                      <p
                        className="bold fontSize14 blackColor mb-4"
                        htmlFor="profilePic"
                        style={{ textAlign: "center" }}
                      >
                        Company Brand
                      </p>
                      <div className="cpname">
                        <img
                          alt="Organization Name"
                          src={
                            imgData
                              ? imgData
                              : contact?.profiledetails.organization[0]
                                ?.organization_logo
                                ? contact?.profiledetails.organization[0]
                                  ?.organization_logo
                                : require("assets/img/icons/organizationlogos.svg")
                                  .default
                          }
                          className="companyBrand mr-3"
                          width="56px"
                          height="56px"
                        />

                        <div className="companyBrandBtn">
                          <a
                            style={{ color: "#1D192B", cursor: "pointer" }}
                            onClick={() => {
                              setModalShow(true);
                            }}
                          >
                            Set Logo
                          </a>
                          {modalShow ? (
                            <Filemanager
                              show={modalShow}
                              onHide={handleClick}
                              onClickimageUpdate={imageUpdate}
                              list={allimage}
                              seletedImage={seletedImageClike}
                              mapkey=""
                            />
                          ) : (
                            ""
                          )}


                        </div>
                      </div>
                    </FormGroup>
                    {contact?.profiledetails.organization[0]
                      ?.organization_id ? (
                      <>
                        <input
                          id="example2cols1Input"
                          placeholder="Postal Code"
                          type="hidden"
                          className="form-control"
                          name="id"
                          {...register("id")}
                          defaultValue={
                            contact?.profiledetails.organization[0]
                              ?.organization_id
                              ? contact?.profiledetails.organization[0]
                                ?.organization_id
                              : ""
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>



                  <div className="formBox text-center">
                    <div className="textCenter blackColor  mb-4 bold fontSize14">
                      Company Geospace
                    </div>

                    {/* <p>{`https://www.google.com/maps/embed/v1/place?key=AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY
                        &q=${userRequest.lat},${userRequest.lng}`}</p> */}
                    <div id="map" className="GeospaceCompany">
                      <iframe
                        border="0"
                        width="200"
                        height="200"
                        style={{ borderRadius: '12px',border:"none" }}
                        loading="lazy"
                        allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY
                        &q=${userRequest.lat},${userRequest.lng}`}>
                      </iframe>
                    </div>


                    {/* <div
                      // onClick={handelGeoSpaceModal}
                      className="editGeopspaceBtn"
                    >
                      Edit Geospace
                    </div> */}


                  </div>
                </div>
              </Col>
            </Row>

          </div>

        </form>

        <ToastContainer />
      </div>
    </>
  );
}
export default OrganizationSelf;
