import React from "react";
import { Component } from "react";
import Button from "@atlaskit/button";
import { informationService } from "../../_services/masters/information.service";
import Select from "react-select";
import { defaultTheme } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "reactstrap";
import { InputField } from "../../components/Inputbox";

const { colors } = defaultTheme;

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

class Selectbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: {
        value: this.props.selectdata?.value ? this.props.selectdata?.value : 1,
        label: this.props.selectdata?.label
          ? this.props.selectdata?.label
          : "Wedding",
      },
      defaultModale: false,
      eventList: [],
      event_type: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = (value) => {
    this.toggleOpen();
    this.setState({ value });
    this.props.onChangeSelect(value);
  };

  componentDidMount() {
    this.UserList();
    this.props.onChangeSelect(this.state.value);
  }
  componentWillMount() {
    this.props.onChangeSelect(this.state.value);
  }
  handleChange(event) {
    this.setState({ event_type: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    // only submit the search if there is search criteria
    if (this.state.event_type.length > 0) {
      let data = {
        event_type: this.state.event_type,
      };

      informationService.eventAdd(data).then(
        (res) => {
          if (res.status === "success") {
            this.UserList();
            this.setState({ defaultModale: false });
            toast.success(res.message, "Account");
          } else {
            console.log("Something went wrong !!");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          console.log("Something went wrong !!");
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  }
  UserList() {
    informationService.eventType().then(
      (res) => {
        if (res.status === "success") {
          this.setState({ eventList: res.data });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  render() {
    const options = this.state.eventList;
    const { isOpen, value } = this.state;
    return (
      <>
        <div className="selecttodata">
          <label htmlFor="input-field">
            {this.props.title ? this.props.title : "Storyz Type"}
          </label>
          <Dropdown
            isOpen={isOpen}
            onClose={this.toggleOpen}
            target={
              <Button
                iconAfter={<ChevronDown />}
                onClick={this.toggleOpen}
                isSelected={isOpen}
                className="buttonselect"
              >
                {value
                  ? `${value.label}`
                  : this.props.title
                    ? this.props.title
                    : "Select a Storyz Type:"}
              </Button>
            }
          >
            <Select
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelectChange}
              options={options}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
              required
            />

            <p
              style={{ textAlign: "right" }}
              onClick={(event) => {
                event.preventDefault();
                this.setState({ defaultModale: true });
              }}
            >
              Add +
            </p>

            <Modal
              className="modal-dialog-centered modal-danger deleteaccountowner"
              isOpen={this.state.defaultModale}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header" style={{ margin: "auto" }}>
                  <h6
                    className="modaltitle mx-auto mb-0"
                    id="modal-title-notification"
                  >
                    <img
                      alt="..."
                      src={
                        require("assets/img/Icon-Text-in-Circle.svg").default
                      }
                      width="75px"
                      style={{ marginRight: "10px" }}
                    />
                  </h6>
                </div>
                <div className="modal-body">
                  <div className="">
                    <h4 className="" style={{ textAlign: "center" }}>
                      Add a New List Item?
                    </h4>

                    <p>
                      <span
                        style={{
                          fontSize: "14px",
                          display: "block",
                          fontWeight: "400",
                          marginBottom: "15px",
                        }}
                      >
                        {" "}
                        Confirm that you want to add a list item to this list.
                      </span>

                      <span
                        style={{
                          fontSize: "14px",
                          display: "block",
                          fontWeight: "300",
                          marginBottom: "15px",
                        }}
                      >
                        Make sure you want to do this!
                      </span>
                    </p>

                    <input
                      className="form-control"
                      type="text"
                      placeholder="Add New Item"
                      name="event_type"
                      required
                      defaultValue={this.state.event_type}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    className="btn-white"
                    type="submit"
                    style={{
                      background: "#FC3158",
                      color: "#fff !important",
                    }}
                  >
                    <span className="whiteColor"> Add</span>
                  </Button>
                  <Button
                    className="btn-white"
                    color="default"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      this.setState({ defaultModale: false });
                    }}
                    style={{ border: "1px solid #147EFB" }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Modal>
          </Dropdown>
        </div>
      </>
    );
  }
}

// styled components

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      css={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default Selectbox;
