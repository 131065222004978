import React, { useEffect, useState, useRef } from "react";
import { FaPlus, FaChevronDown } from "react-icons/fa";

import { userGuestService } from "../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EventTypeDropdown = ({
  guestPrefixOption1,
  placeholder,
  caption,
  addUserBtn,
  style,
  handlerFunction,
  handleAddModal,
  eId,
}) => {
  const [selectMenu, setSelectMenu] = useState(false);
  const [changeText, setChangeText] = useState(placeholder);
  const [searchValue, setSearchValue] = useState("");
  const selectMenuRef = useRef();

  const dropdownHandle = () => {
    setSelectMenu(!selectMenu);
  };
  useEffect(() => {
    let handler = (e) => {
      if (!selectMenuRef.current.contains(e.target)) {
        setSelectMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const ChangetextHandle = (title) => {
    setSelectMenu(false);
    setChangeText(title.event_type);
    //handlerFunction(title.event_type);

    const val1 = [
      {
        id: eId,
        key_name: "event_type",
        key_value: title.id,
      },
    ];

    userGuestService.eventUpdate(val1).then((res) => {
      if (res.status === "success") {
        toast.success("update successfully !!", "Account");
      } else {
        toast.success("Something went wrong !!", "error");
      }
    });
  };

  // const filterHandle = (e) => {
  //   const searchWord = e.target.value;
  //   const newFilter = filterData.filter((value) => {
  //     return value.title.toLowerCase().includes(searchWord.toLowerCase());
  //   });
  //   setFilterData(newFilter);
  // };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const guestPrefixOption = () => {
    if (!searchValue) {
      return guestPrefixOption1;
    }

    return guestPrefixOption1.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="dropdownBoxWrapper" ref={selectMenuRef}>
      <div
        className="dropdownTriggered placeholderText"
        onClick={dropdownHandle}
        style={style}
      >
        <div className="dropdownPlaceholder">
          <span>{changeText}</span>
          <button className="arrowIcon">
            <FaChevronDown />
          </button>
          {/* </div> */}
        </div>
      </div>
      <div className={`dropdownBoxMenuWrapper ${selectMenu ? "active" : ""}`}>
        <div className="dropdownBoxMenuInner">
          {addUserBtn ? (
            <p className="AddGestUser" onClick={handleAddModal}>
              Add <FaPlus className="icon" />
            </p>
          ) : null}
          <div className="dropdownSearchWrapper">
            <input
              type="text"
              placeholder="type to search..."
              className="form-control"
              onChange={onSearch}
              value={searchValue}
            />
          </div>
          <ul className="dropdownMenuListWrapper">
            {caption ? (
              <li className="menuItem captionTitle">
                <div className="guestList">{caption}</div>
              </li>
            ) : (
              ""
            )}

            {guestPrefixOption().map((option, i) => (
              <li
                className="menuItem"
                key={i}
                onClick={() => ChangetextHandle(option)}
              >
                <div className="guestList">{option.event_type}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventTypeDropdown;
