import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Row, Col } from "reactstrap";
import { CombinedGuestPool } from "./style";
import FileImg from "./../../../assets/img/file-listings.png";
import DummyImg from "../../../assets/img/dummyImg.svg";
import { Link } from "react-router-dom";
import Global from "../../../_helpers/global";
import { GrCircleInformation } from "react-icons/gr";

const StoryzWebListingDetails = ({ args }) => {
  return (
    <>
      <div className="p-4">
        <div
          className="d-flex align-items-center mb-4"
          style={{
            padding: "10px",
            borderBottom: "1px solid #E7E7E7",
            margin: "0px",
            fontSize: "18px",
          }}
        >
          <h4 style={{ fontSize: "18px", margin: "0px" }}>Web Access</h4>
          <GrCircleInformation size={20} className="ml-auto iconColor" />
        </div>
        <Row className="blackColor px-3 pb-3">
          <Col>
            <div className="fontSize16 fontWeight300 pb-3 border-bottom mb-4">
              This view lets you specify information included in the web listing
              for your Storyz.
            </div>
            <Row className="addGuestUerFormWrapper">
              <Col>
                <div className="pb-4">
                  <div className="p-3 heading">Basic Information for Event</div>
                  <div className="p-3 fontSize14 fontWeight300">
                    <p>
                      You can specify a custom download link. In that case, the
                      OurStoryz app will display your organization logo during
                      startup, and only show events created by your
                      organization.
                    </p>

                    <div className="custom-control custom-checkbox mb-2 d-flex align-items-center pl-3 py-2">
                      <label className="container3 blackColor mb-0 fontSize14 fontWeight300">
                        Make App downloads specific to our Organization
                        <input type="checkbox" name="check" defaultChecked />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="p-3 heading">Social Media</div>
                <div className="p-3">
                  <p>Specify any Social Media links here.</p>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex align-items-center mb-4">
              <div className="form-check mr-3 pl-0">
                <input
                  className="form-check-input"
                  type="radio"
                  value=""
                  name="repeating_event"
                  checked
                />
                <label
                  className="form-check-label grayColor"
                  for="flexRadioDefault1"
                >
                  All Guests (Broadcast)
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value=""
                  name="repeating_event"
                />
                <label
                  className="form-check-label grayColor"
                  for="flexRadioDefault1"
                >
                  Selected Guest(s)
                </label>
              </div>
            </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StoryzWebListingDetails;
