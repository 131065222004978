/*global google*/
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";
import { GrCircleInformation } from "react-icons/gr";

import { useSelector } from "react-redux";
import Allheader from "components/Headers/AllheaderEvent";
import "react-circular-progressbar/dist/styles.css";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import Global from "../../../_helpers/global";
import { useParams } from "react-router-dom";

import { userGuestService } from "../../../_services/masters/userGuest.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import { useHistory } from "react-router-dom";
import { Importer, ImporterField } from "react-csv-importer";

import "react-csv-importer/dist/index.css";
const DefaultLocation = { lat: 39.91714233673976, lng: -103.65621948242185 };
const DefaultZoom = 10;

const UploadGuestList = (args) => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);
  const params = useParams();
  let history = useHistory();
  const [modal, setModal] = useState(false);
  // const [seoFullscreen, setSeoFullscreen] = useState(false);
  // const [resetGeospace, setResetGeospace] = useState(false);
  const [address, setAddress] = useState();
  const toggle = () => setModal(!modal);
  const [val, setcheckVal] = React.useState(1);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  // const [eventId, setEventId] = React.useState(params.eid ? params.eid : "");
  // const [locationid, setLocationid] = React.useState("");
  // const [eventData, setEventData] = React.useState({});
  const [Ltkey, setLtkey] = React.useState("");
  let csvData = [];
  // const [loctiontype, setLoctiontype] = useState("");
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);

  const mapDivRef = useRef(null);
  useEffect(() => {
    if (mapDivRef.current) {
      setLtkey(Global.MAP_KEY_DEMO);
    }
  }, [Ltkey]);

  const [dataerrorValue, setDataerrorValue] = React.useState([]);
  const [errorBox, setErrorBox] = React.useState(false);
  const [resetBox, setResetBox] = React.useState('');
  const [resetB, setResetB] = React.useState(true);
  const [resetBoxs, setResetBoxs] = React.useState('');
  function DataSendBlob(data) {
    var csv = {
      event_id: eid,
      storyz_id: sid,
      type: "csv",
      csv_data: data,
    };

    userGuestService.uploadGuestCSV(csv).then((res) => {
      if (res.status == "success") {

        if (res?.DataNotAdded.length > 0) {
          setDataerrorValue(res?.DataNotAdded);
          setErrorBox(true);

        } else {
          setResetBoxs('Added successfully');
          // toast.success("Added successfully");

        }
      } else {
        // document.querySelector('.CSVImporter_ProgressDisplay__status').value = res.message;
        setResetBox(res.message);
        //toast.error(res.message);
      }
    });
  }

  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>
      {/* <Allheader title={"Storyz Name"} imgScr={Imggroup} arw /> */}
      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />

            <div className="column2">
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>
                  {common?.singleEvent?.event_name
                    ? common?.singleEvent?.event_name
                    : ""}
                </div>
                <div className="d-flex align-items-center">
                  <div className="imageeventim234">
                    <span>Upload Guest List</span>
                  </div>
                  <GrCircleInformation
                    className="ml-3 pointer infoIcon"
                    onClick={toggle}
                  />

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    {...args}
                    className="aboutGeospaceModal"
                  >
                    <ModalBody>
                      <div className="aboutGeoModalHeader border-bottom">
                        <img
                          src={GeospaceInfoIcon}
                          alt=""
                          className="geospaceInfoIcon"
                        />
                        <div className="head">About Geospaces</div>
                      </div>
                      <Container fluid className="aboutGeoContent">
                        <Row className="border-bottom">
                          <Col sm={7}>
                            <p>
                              All OurStoryz Events happen inside a geospace,
                              also called a “geofence.”
                            </p>
                            <p>
                              Many guest app functions, like finding each other
                              at the event, only work inside the event.
                            </p>
                            <p>Each event can have its own geospace.</p>
                          </Col>
                          <Col>
                            <iframe
                              title="map"
                              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d112039.88568714322!2d77.22434559999999!3d28.652339199999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1677597775183!5m2!1sen!2sin"
                              width="100%"
                              height="auto"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </Col>
                        </Row>
                        <Row className=" border-bottom">
                          <Col sm={7}>
                            <p>
                              A geospace consists of a GPS coordinate centered
                              on your event location, and a “bubble” defining
                              its limits. For example, you could set the size of
                              your geospace to just enclose a specific building.
                              Alternately, you could define a very large
                              geospace covering a racetrack, hiking trail, city
                              or even country.
                            </p>
                          </Col>
                          <Col className="d-flex align-items-center">
                            <div className="custom-control form-check form-check-inline">
                              <label className="container3 form-check-label">
                                Invite sent
                                <input type="checkbox" name="" checked />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" border-bottom">
                          <Col>
                            <p>
                              Specify your geospace in by typing in an address,
                              or using your current GPS coordinates. If you have
                              coordinates without an address, these can be typed
                              in as well.
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        className="w-100 blueBtn"
                        onClick={toggle}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
              {/* <div className="AdDLiSt_BuTtON">
                  <CustomButton>Save Information</CustomButton>
                </div> */}
              <div className="organizationPara border-bottom mb-2">
                This page lets you view and create a todo list associated with
                this specific Storyz. <br /> To see the complete list of todos,
                go to the
                <Link
                  onClick={() => {
                    history.push(`/admin/storyz/${sid}`);
                  }}
                  className="text-decoration-underline mx-1 grayColor1"
                >
                  Storyz view
                </Link>
                or
                <Link
                  onClick={() => {
                    history.push(`/admin/organization`);
                  }}
                  className="text-decoration-underline mx-1 grayColor1"
                >
                  Organization view
                </Link>
                .
              </div>

              <div className="p-4 csfFileUploadedWrapper">
                <p style={{ color: 'red' }}>{resetBox}</p>
                <p style={{ color: 'green' }}>{resetBoxs}</p>
                {errorBox === true && (
                  <>
                    <div>
                      <p className="errobox">Please Check All Your Data</p>
                      {dataerrorValue?.map(function (data) {
                        return <>{data}, </>;
                      })}
                      <div style={{ marginBottom: "10px" }}></div>
                    </div>
                  </>
                )}

                <Importer
                  chunkSize={100000} // optional, internal parsing chunk size in bytes
                  defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                  restartable={resetB} // optional, lets user choose to upload another file when import is complete
                  onStart={({ file, preview, fields, columnFields }) => {
                    // optional, invoked when user has mapped columns and started import
                    csvData = [];
                    // console.log(
                    //   "starting import of file",
                    //   file,
                    //   "with fields",
                    //   fields
                    // );
                  }}
                  processChunk={async (rows) => {
                    //console.log("received batch of rows ", rows);
                    csvData.push(...rows);
                    // DataSendBlob(csvData);
                  }}
                  onComplete={({ file, preview, fields, columnFields }) => {
                    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                    // console.log(
                    //   "finished import of file",
                    //   file,
                    //   "with fields",
                    //   fields
                    // );

                    DataSendBlob(csvData);
                  }}
                  onClose={() => {
                    setResetB(true);
                    setResetBox('');
                    setResetBoxs('');
                    window.location.reload();
                    // optional, invoked when import is done and user clicked "Finish"
                    // (if this is not specified, the widget lets the user upload another file)
                    // console.log("importer dismissed");
                  }}
                >
                  <ImporterField name="prefix" label="Prefix" optional />
                  <ImporterField name="first_name" label="First Name" />
                  <ImporterField name="last_name" label="Last Name" />
                  <ImporterField name="suffix" label="Suffix" optional />
                  <ImporterField
                    name="country_code"
                    label="Country Code"
                  />
                  <ImporterField name="email" label="Email" />
                  <ImporterField name="mobile_number" label="Mobile Number" />

                </Importer>
              </div>
            </div>
          </Eventstyle>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UploadGuestList;
