import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Link } from "react-router-dom";
import Imggroup from "../../../assets/img/dummyImg.svg";

import { GrCircleInformation } from "react-icons/gr";

import { useForm } from "react-hook-form";

import Allheader from "components/Headers/AllheaderEvent";
import "react-circular-progressbar/dist/styles.css";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import GeospaceInfoIcon from "../../../assets/img/geospaceInfoIcon.svg";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import GeospaceIcon from "./../../../assets/img/geospaceIcon.svg";
import Global from "../../../_helpers/global";

import { useParams } from "react-router-dom";
import { eventsService } from "../../../_services/masters/events.service";
import { Wrapper as GoogleWrapper } from "@googlemaps/react-wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFindPathSid, useFindPathEid } from "../../../CommonData";
import { useHistory } from "react-router-dom";

import GeoSpaceApp from "./GeoSpaceApp";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";

const DefaultLocation = { lat: 39.91714233673976, lng: -103.65621948242185 };
const DefaultZoom = 10;

const AddGeospaceList = (args) => {
  useEffect(() => {
    document.body.classList.remove("g-sidenav-pinned");
    document.body.classList.remove("g-sidenav-show");
    document.body.classList.add("g-sidenav-hidden");
  }, []);

  const params = useParams();
  let history = useHistory();
  const [modal, setModal] = useState(false);
  const [seoFullscreen, setSeoFullscreen] = useState(false);
  const [resetGeospace, setResetGeospace] = useState(false);
  const toggle = () => setModal(!modal);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [eventId, setEventId] = React.useState(params.eid ? params.eid : "");
  const [locationid, setLocationid] = React.useState("");
  const [eventData, setEventData] = React.useState({});
  const [Ltkey, setLtkey] = React.useState("");
  const [loctiontype, setLoctiontype] = useState("");
  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);

  const mapDivRef = useRef(null);
  useEffect(() => {
    if (mapDivRef.current) {
      setLtkey(Global.MAP_KEY_DEMO);
    }
  }, [Ltkey]);

  const handleFullscreen = () => {
    //alert('hello');
    setSeoFullscreen(!seoFullscreen);
  };

  const resetInformation = () => {
    const id = { id: locationid };
    eventsService.addLocationDelete(id).then(
      (res) => {
        if (res.status === "success") {
          eventDetai();
          handleresetGeospace(false);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  };
  const [imgData2, setImgData2] = React.useState({});
  const [imgDa, setImgDa] = React.useState(null);
  const [addgeospacelist, setAddgeospacelist] = React.useState();
  const [allimage, setAllimage] = React.useState([]);
  const allIMages = useSelector((state) => {
    return state.allFiles;
  });
  React.useEffect(() => {
    if (allIMages.isConfirmatiom === true) {
      setAllimage(allIMages.allFiles);
    } else {
      setAllimage([]);
    }
  }, []);

  React.useEffect(() => {
    const id = { event_id: params.eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          setAddgeospacelist(res.data);
          setValue("address", res.data.location, { shouldTouch: true });
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, [eventId]);

  const handleresetGeospace = () => {
    setResetGeospace(!resetGeospace);
  };



  const mapOptions = {
    fullscreenControl: false,
  };
  React.useEffect(() => {
    eventDetai();
  }, [eventId]);
  function eventDetai() {
    const id = { event_id: params.eid };
    eventsService.eventDetails(id).then(
      (res) => {
        if (res.status === "success") {
          setEventData(res.data);
          setValue("address", res.data.location, { shouldTouch: true });
          setValue("latitude", res.data.latitude, { shouldTouch: true });
          setValue("longitude", res.data.longitude, { shouldTouch: true });
          setValue("elevation", res.data.elevation, { shouldTouch: true });
          setValue("motion", res.data.motion, { shouldTouch: true });
          setValue("radius", res.data.radius, { shouldTouch: true });
          setValue("nameofGeospace", res.data.name_of_geospace, {
            shouldTouch: true,
          });
          setValue("discription", res.data.location_description, {
            shouldTouch: true,
          });
          setValue("location_id", res.data.location_id, { shouldTouch: true });
          setImgData2(res.data.location_cover_image);
          setLocationid(res.data.location_id);
          setLoctiontype(res.data.location_type);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});
  const onSubmit = (data) => {
    const dataval = JSON.stringify({
      // location: address ? address : '',
      // latitude: latitude ? latitude : '',
      // longitude: longitude ? longitude : '',
      // elevation: elevation ? elevation : '',
      // motion: motion ? motion : '',
      // radius: radius ? radius : '',
      // show_beacons: 1,
      // show_other_event_objects: 1,
      // name_of_geospace: name,
      // location_type: locationType,
      // location_description: desc,
      // event_id: eventId,
    });
    // formdata.append("location", data.address);
    // formdata.append("latitude", data.latitude);
    // formdata.append("longitude", data.longitude);
    // formdata.append("elevation", data.elevation);
    // formdata.append("motion", data.motion)
    // formdata.append("radius", data.radius);
    // formdata.append("show_beacons", 1);
    // formdata.append("show_other_event_objects", 1);
    // formdata.append("name_of_geospace", data.nameofGeospace);
    // formdata.append("location_description", data.discription);
    // formdata.append("event_id", eventId);
    // formdata.append("location_type", loctiontype);
    // formdata.append("location_cover_image", imgDa);

    if (data.location_id) {
      eventsService.updateLocation(dataval).then(
        (res) => {
          if (res.status === "success") {
            eventDetai();
            toast.success(res.message, "Account");
          } else {
            //console.log('Something went wrong !!');
            toast.error("Something went wrong !!", "Error");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          toast.error("Something went wrong !!", "Error");
          //console.log('Something went wrong !!');
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    } else {
      eventsService.addLocation(dataval).then(
        (res) => {
          if (res.status === "success") {
            eventDetai();
            toast.success(res.message, "Account");
          } else {
            //console.log('Something went wrong !!');
            toast.error("Something went wrong !!", "Error");
            //toast.error(res.errors[0], "Fashion Store");
          }
        },
        (error) => {
          toast.error("Something went wrong !!", "Error");
          //console.log('Something went wrong !!');
          //toast.error("Something went wrong !!", "Fashion Store");
        }
      );
    }
  };

  const fileHandler = (e) => {
    setImgDa(e.target.files[0]);
    setImgData2(URL.createObjectURL(e.target.files[0]));
  };

  const common = useSelector((state) => {
    return state.commonData.common;
  });
  return (
    <>

      <Allheader
        title={
          common?.singleEvent?.storyz_name
            ? common?.singleEvent?.storyz_name
            : "Storyz Name"
        }
        imgScr={
          common?.singleEvent?.project_image
            ? common?.singleEvent?.project_image
            : Imggroup
        }
        arw
      />
      <div className="containerWraperOuter">
        <div className="card">
          <Eventstyle>
            <Eventmenu />

            <div className="column2">
              <div className="column2sate backgroeventdata">
                <div className="title">
                  <div className="badge">Event</div>
                  {addgeospacelist?.event_name}
                </div>
                <div className="d-flex align-items-center">
                  <div className="imageeventim234">
                    <span>Geospace</span>
                  </div>
                  <GrCircleInformation
                    className="ml-3 pointer infoIcon"
                    onClick={toggle}
                  />

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    {...args}
                    className="aboutGeospaceModal"
                  >
                    <ModalBody>
                      <div className="aboutGeoModalHeader border-bottom">
                        <img
                          src={GeospaceInfoIcon}
                          alt=""
                          className="geospaceInfoIcon"
                        />
                        <div className="head">About Geospaces</div>
                      </div>
                      <Container fluid className="aboutGeoContent">
                        <Row className="border-bottom">
                          <Col sm={7}>
                            <p>
                              All OurStoryz Events happen inside a geospace,
                              also called a “geofence.”
                            </p>
                            <p>
                              Many guest app functions, like finding each other
                              at the event, only work inside the event.
                            </p>
                            <p>Each event can have its own geospace.</p>
                          </Col>
                          <Col>
                            <iframe
                              title="map"
                              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d112039.88568714322!2d77.22434559999999!3d28.652339199999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1677597775183!5m2!1sen!2sin"
                              width="100%"
                              height="auto"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </Col>
                        </Row>
                        <Row className=" border-bottom">
                          <Col sm={7}>
                            <p>
                              A geospace consists of a GPS coordinate centered
                              on your event location, and a “bubble” defining
                              its limits. For example, you could set the size of
                              your geospace to just enclose a specific building.
                              Alternately, you could define a very large
                              geospace covering a racetrack, hiking trail, city
                              or even country.
                            </p>
                          </Col>
                          <Col className="d-flex align-items-center">
                            <div className="custom-control form-check form-check-inline">
                              <label className="container3 form-check-label">
                                Invite sent
                                <input type="checkbox" name="" checked />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" border-bottom">
                          <Col>
                            <p>
                              Specify your geospace in by typing in an address,
                              or using your current GPS coordinates. If you have
                              coordinates without an address, these can be typed
                              in as well.
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        className="w-100 blueBtn"
                        onClick={toggle}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
              {/* <div className="AdDLiSt_BuTtON">
                  <CustomButton>Save Information</CustomButton>
                </div> */}
              <div className="organizationPara border-bottom mb-2">
                This page lets you view and create a todo list associated with
                this specific Storyz. <br /> To see the complete list of todos,
                go to the
                <Link
                  to="#"
                  onClick={() => {
                    history.push(`/admin/storyz/${sid}`);
                  }}
                  className="text-decoration-underline mx-1 grayColor1"
                >
                  Storyz view
                </Link>
                or
                <Link
                  onClick={() => {
                    history.push(`/admin/organization`);
                  }}
                  className="text-decoration-underline mx-1 grayColor1"
                  to="#"
                >
                  Organization view
                </Link>
                .
              </div>
              <div className="d-flex m-4">
                {/* <GlobalButton
                    customClass="ml-auto"
                    img={require("assets/img/hornIcon.svg").default}
                    type="submit"
                  >
                    Save Changes
                  </GlobalButton> */}
                {/* <DeleteBtn icon> Delete Event </DeleteBtn> */}
              </div>

              <Provider store={store}>
                <GoogleWrapper
                  apiKey="AIzaSyCG2YvMYjtoPcq3tP8ROejpgqd-RxenQOY"
                  libraries={["places"]}
                >
                  <GeoSpaceApp
                    evid={common?.eid}
                    location_id={eventData.location_id}
                    allimage={allimage}
                  />
                </GoogleWrapper>
              </Provider>

              {/* <hr />
                <GeospaceWrapper className="p-4">
                  <div className="organizeByGroupOuter mt-0">
                    <Row className="addGuestUerFormWrapper breakpointWrapper">
                      <Col sm={12} xl={5} lg={6} className="minWidth1400">
                        <div className="heading">Coordinates</div>
                        <div className="geospaceBox">
                          <Row>
                            <Col>
                              <div className="formBox">
                                <label className="form-label">
                                  Specify Location:
                                </label>
                                <div className="inlineFormBox specifyLocation">
                                  <div className="form-check form-check-inline mr-0">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      onClick={() => {
                                        UseCurrentLocation("Current");
                                        setLoctiontype("manually_location");
                                      }}
                                      {...register("inlineRadioOptions", {
                                        required: true,
                                      })}
                                      checked={
                                        loctiontype === "manually_location"
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      Use Current Location
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mr-0">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio2"
                                      onClick={() => {
                                        UseCurrentLocation("Address");
                                        setLoctiontype("auto_location");
                                      }}
                                      checked={loctiontype === "auto_location"}
                                      {...register("inlineRadioOptions", {
                                        required: true,
                                      })}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio2"
                                    >
                                      Type in an Address
                                    </label>
                                  </div>
                                </div>
                                <div>
                                  {errors.inlineRadioOptions && (
                                    <span style={{ color: "red" }}>
                                      Please select your specify location.
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="formBox">
                                <label className="form-label">
                                  Type in an Address:
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Type in an Address..."
                                  defaultValue={address}
                                  {...register("address", {
                                    required: true,
                                  })}
                                  style={
                                    errors.address
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                ></textarea>
                              </div>
                            </Col>
                          </Row>
                          <div className="formBox units">
                            <label className="form-label mb-0">
                              Units: Metric
                            </label>
                          </div>
                          <Row>
                            <Col md={8}>
                              <div className="formBox">
                                <label className="form-label">Latitude:</label>
                                <input
                                  type=""
                                  name="latitude"
                                  className="form-control"
                                  placeholder="enter first name..."
                                  defaultValue={location.lat}
                                  {...register("latitude", {
                                    required: true,
                                  })}
                                  style={
                                    errors.latitude
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                />
                              </div>
                              <div className="formBox">
                                <label className="form-label">Longitude:</label>
                                <input
                                  type=""
                                  name="longitude"
                                  className="form-control"
                                  placeholder="122.7617° W"
                                  defaultValue={location.lng}
                                  {...register("longitude", {
                                    required: true,
                                  })}
                                  style={
                                    errors.longitude
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                />
                              </div>
                              <Row>
                                <Col sm={10}>
                                  <div className="formBox">
                                    <div className="inlineFormBox">
                                      <div>
                                        <label className="form-label">
                                          Elevation:
                                        </label>
                                        <input
                                          type="number"
                                          name="elevation"
                                          className="form-control"
                                          placeholder="122.7617° W"
                                          {...register("elevation", {
                                            required: true,
                                          })}
                                          style={
                                            errors.elevation
                                              ? { border: "1px solid red" }
                                              : {}
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label className="form-label">
                                          Motion:
                                        </label>
                                        <input
                                          type=""
                                          name="motion"
                                          className="form-control"
                                          placeholder="0m/sec"
                                          {...register("motion", {
                                            required: true,
                                          })}
                                          style={
                                            errors.motion
                                              ? { border: "1px solid red" }
                                              : {}
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <div className="formBox">
                                <label className="form-label">Radius:</label>
                                <input
                                  type=""
                                  name="radius"
                                  className="form-control"
                                  placeholder="22"
                                  {...register("radius", {
                                    required: true,
                                  })}
                                  style={
                                    errors.radius
                                      ? { border: "1px solid red" }
                                      : {}
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={12} xl={7} lg={6} className="minWidth1400">
                        <div>
                          <div className="heading">Interactive Map</div>
                          <div className="geospaceBox">
                            <Row>
                              <Col>
                                <div className="formBox intractiveMap">
                                  <div className="inlineFormBox">
                                    <div className="inlineFormBox">
                                      <div className="custom-control form-check form-check-inline">
                                        <label className="container3 form-check-label">
                                          Show Beacons
                                          <input
                                            type="checkbox"
                                            name=""
                                            checked
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                      <div className="custom-control form-check form-check-inline ">
                                        <label className="container3 form-check-label">
                                          Show other Event Objects
                                          <input type="checkbox" name="" />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="ml-auto">
                                      <label className="form-label mb-0 pb-0 ml-auto">
                                        Fullscreen
                                      </label>
                                      <BiLinkExternal
                                        size={24}
                                        className="ml-2 pointer"
                                        onClick={handleFullscreen}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <div ref={mapDivRef}>
                                  {Ltkey && (
                                    <MapPicker
                                      defaultLocation={defaultLocation}
                                      mapTypeId="roadmap"
                                      style={{ width: "600", height: "300px" }}
                                      onChangeLocation={handleChangeLocation}
                                      onChangeZoom={handleChangeZoom}
                                      apiKey={Ltkey}
                                      options={mapOptions}
                                      className="eventGeospacemap"
                                    />
                                  )}
                                </div>
                                <Modal
                                  isOpen={seoFullscreen}
                                  toggle={handleFullscreen}
                                  className="geospaceFullscreenWrapper"
                                >
                                  <ModalBody className="p-0">
                                    <Row>
                                      <Col>
                                        <div className="d-flex align-items-center p-3">
                                          <div className="custom-control form-check form-check-inline mr-3">
                                            <label className="container3 form-check-label">
                                              Show Beacons
                                              <input
                                                type="checkbox"
                                                name=""
                                                checked
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                          <div className="custom-control form-check form-check-inline ">
                                            <label className="container3 form-check-label">
                                              Show other Event Objects
                                              <input type="checkbox" name="" />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                          <div className="ml-auto">
                                            <label className="form-label mb-0 pb-0 ml-auto">
                                              Exit Fullscreen
                                            </label>
                                            <BsBoxArrowLeft
                                              size={24}
                                              className="ml-2 pointer"
                                              onClick={handleFullscreen}
                                            />
                                          </div>
                                        </div>
                                        <div ref={mapDivRef}>
                                          {Ltkey && (
                                            <MapPicker
                                              defaultLocation={defaultLocation}
                                              mapTypeId="roadmap"
                                              onChangeLocation={
                                                handleChangeLocation
                                              }
                                              onChangeZoom={handleChangeZoom}
                                              apiKey={Ltkey}
                                              options={mapOptions}
                                              className="eventGeospacemap iframe"
                                            />
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div>
                          <div className="heading">Name and Settings</div>
                          <div className="geospaceBox">
                            <Row>
                              <Col>
                                <div className="formBox">
                                  <label className="form-label">
                                    Name of Geospace:
                                  </label>
                                  <input
                                    type=""
                                    name="nameofGeospace"
                                    className="form-control mr-2"
                                    placeholder="Halloween Haunt"
                                    {...register("nameofGeospace", {
                                      required: true,
                                    })}
                                    style={
                                      errors.nameofGeospace
                                        ? { border: "1px solid red" }
                                        : {}
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="formBox">
                                  <div className="imageventsla">
                                    <div>Cover Image:</div>
                                    <div>
                                      <img
                                        alt="Organization Name"
                                        src={
                                          require("assets/img/mediaGallery.svg")
                                            .default
                                        }
                                        className="icomn pointer"
                                        style={{ width: "24px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="formBox">
                                  <div className="imgMaskevente">
                                    <div className="imgMask-content">+</div>
                                    {imgData2 ? (
                                      <img
                                        className="storytoimageev"
                                        id="blah2"
                                        src={imgData2}
                                        alt="."
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <input
                                      type="file"
                                      className="imgg1"
                                      onChange={fileHandler}
                                    />
                                  </div>
                                </div>
                                <div className="formBox">
                                  <label className="form-label">
                                    Description:
                                  </label>
                                  <textarea
                                    className="form-control mr-2"
                                    placeholder="enter text"
                                    name="discription"
                                    {...register("discription", {
                                      required: true,
                                    })}
                                    style={
                                      errors.discription
                                        ? { border: "1px solid red" }
                                        : {}
                                    }
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col></Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </GeospaceWrapper> */}
            </div>
          </Eventstyle>
        </div>
        <button
          style={locationid ? {} : { pointerEvents: "none", opacity: "0.4" }}
          type="button"
          className="resetGeoBtn"
          onClick={handleresetGeospace}
        >
          <img src={GeospaceIcon} alt="" className="mr-2" /> Reset Geospace
        </button>

        <Modal
          isOpen={resetGeospace}
          toggle={handleresetGeospace}
          {...args}
          className="resetGeospaceModal"
        >
          <ModalBody className="pb-0">
            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
              <img
                src={ResetGeospaceInfoIcon}
                alt=""
                className="geospaceInfoIcon"
              />
              <div className="head">Reset Geospace and its Objects?</div>
            </div>
            <Row>
              <Col className="px-5">
                <div className="geospaceThumWrapper">
                  <div className="geospaceThum">
                    <img
                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                      alt=""
                      className="geospaceThumImg"
                    />
                  </div>
                  <div className="geospaceThumContent">
                    <div className="geothumbTitle">{eventData.location}</div>
                    <div className="geothumbSubTitle">
                      <span className="mr-3">
                        Latitude: {eventData.latitude}{" "}
                      </span>
                      <span>Longitude: {eventData.longitude}</span>
                    </div>
                  </div>
                </div>
                <div className="eventThumWrapper">
                  <div className="eventThum">
                    <img
                      src={
                        eventData.cover_image
                          ? eventData.cover_image
                          : "https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                      }
                      alt=""
                      className="eventThumImg"
                    />
                  </div>
                  <div className="eventThumContent">
                    <div className="eventthumbTitle">
                      {eventData.event_name}
                    </div>
                    <div className="eventthumbSubTitle">Location</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 ">
              <Col className="px-5">
                <p>Confirm that you want to delete the geospace. </p>
                <p>
                  This will also remove any associated object assignments to the
                  geospace, as well as Bluetooth Beacons.
                </p>
                <p>
                  Since all events have a geospace, a new, blank geospace will
                  be created.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
            <Button
              color="primary"
              onClick={resetInformation}
              className="flex-grow-1 pinkBtn"
            >
              Reset Geospace Information
            </Button>
            <Button
              className="btn-default cancelBtn"
              onClick={() => handleresetGeospace(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddGeospaceList;
