import { React, useState } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import IconImg from "../../../assets/img/icons/project logos@2x.png";
import Vector1 from "../../../assets/img/icons/two-factor-icon.svg";
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import { userService } from "../../../_services/login/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { informationService } from "../../../_services/masters/information.service";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actionTypes";
import { useHistory } from "react-router-dom";

function TwofactorAuthentication(props) {
  const [option, setOption] = useState("Email");
  let [Otp, setOTP] = useState(null);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  //const otp: string
  const sendOtp = () => {
    let otpData = JSON.parse(localStorage.getItem("twoStepVerification"));
    if (
      otpData.mobile_number == undefined ||
      otpData.mobile_number == null ||
      otpData.mobile_number == ""
    ) {
      setMessage("Mobile number invalid");
    }

    let data = {
      option: option,
      country_id: otpData.country_id,
      email: otpData.email,
      mobile_no: otpData.mobile_number,
      first_name: otpData.first_name,
      user_id: otpData.user_id,
    };

    //console.log('otpData data', data);
    userService.sendOtp(data).then((res) => {
      if (res.status == "success" && option == "Email") {
        setMessage(res.message);
        //toast.success(res.message);
      } else if (option == "SMS") {
        setMessage(
          "An OTP has been sent to your mobile number, please check your mobile number"
        );
      } else {
        setMessage("something went wrong");
        //toast.error("something went wrong");
      }
    });
  };

  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        //alert(res.status)
        if (res.status == "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  const otpVerification = () => {
    if (Otp !== null) {
      let otpData = JSON.parse(localStorage.getItem("twoStepVerification"));
      let data = { user_id: otpData.user_id, otp: Otp };

      userService.otpVerification(data).then(
        (res) => {
          if (res.status == "success") {
            localStorage.setItem("userDetails", JSON.stringify(res.data));
            var retrievedObject = JSON.parse(
              localStorage.getItem("userDetails")
            );
            if (retrievedObject.name !== Cookies.get("name")) {
              Cookies.set("name", retrievedObject.name);
            }
            userdetail();
            //localStorage.removeItem("userDetails");
            localStorage.removeItem("twoStepVerification");
          } else if (res.status == "error") {
            localStorage.removeItem("userDetails");
            toast.error(res.message, "Login");
          } else {
            localStorage.removeItem("userDetails");
            toast.error("Invalid OTP !!", "Login");
          }
        },
        (error) => {
          localStorage.removeItem("userDetails");
          toast.error("Invalid OTP !!", "Login");
        }
      );

      // if (data.option === true) {
      //   localStorage.setItem("keepLoggedin", JSON.stringify(data));
      // } else {
      //   localStorage.removeItem("keepLoggedin");
      // }
    } else {
      toast.error("Invalid OTP !!", "Login");
    }
  };
  return (
    <>
      <Container
        fluid
        style={{
          background: "#2053B5",
          padding: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <p>
            <i
              className="ni ni-bold-left"
              style={{ color: "#fff", marginTop: "21px", marginRight: "10px" }}
            ></i>
          </p> */}
        <img
          src={IconImg}
          style={{ paddingRight: "20px" }}
          width="70px"
          height="50px"
        />

        <h1 style={{ color: "#fff" }}>Authentication</h1>
      </Container>
      <div className="twofactorauth completeAutoWrapper">
        <Container
          fluid
          style={{ background: "#fff", padding: "40px", minHeight: "82vh" }}
        >
          <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
          <Card className="mt-5 mb-5">
            <CardHeader>
              <h3
                className="mb-0"
                style={{
                  borderBottom: "1px solid #E7E7E7",
                  paddingBottom: "10px",
                }}
              >
                Complete Authentication
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <div className="basicinformation mb-2">
                    <img src={Vector1} style={{ marginRight: "12px" }} alt="" />
                    Two-Factor Authentication
                  </div>
                  <div className="cardBox mb-0">
                    <div className="formBox">
                      <div className="inlineFormBox mb-2">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            onChange={() => setOption("Email")}
                            checked={option == "Email"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Send Email
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            onChange={() => setOption("SMS")}
                            checked={option == "SMS"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Send SMS Message
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cardBox mb-0">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="example3cols1Input"
                      >
                        Click “Send SMS” to get an Authentication Code:
                      </label>

                      <div
                        className={
                          message == "something went wrong"
                            ? "form-error"
                            : "form-success"
                        }
                      >
                        {message}
                      </div>

                      <div className="inlineFormBox align-items-center">
                        <Input
                          id="example3cols1Input"
                          placeholder="please enter otp"
                          type="text"
                          value={Otp}
                          onChange={(e) =>
                            setOTP(e.target.value == "" ? null : e.target.value)
                          }
                        />
                        <button
                          type="button"
                          className="sendSMSBtn"
                          onClick={() => sendOtp()}
                        >
                          Send {option}
                        </button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="cardBox mb-0">
                    {Otp !== null ? (
                      <button
                        className="authenticatorBtn"
                        onClick={() => otpVerification()}
                      >
                        Authenticate
                      </button>
                    ) : (
                      <button disabled className="authenticatorBtn">
                        Authenticate
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}
export default TwofactorAuthentication;
