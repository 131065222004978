import { STORYZ_POPUP } from "../actionTypes";
const initialState = {
    isConfirmatiom: false,
    story: false,
};
function storyzPopupReducer(state = initialState, action) {
    switch (action.type) {
        case STORYZ_POPUP:
            return {
                ...state,
                isConfirmatiom: true,
                story: action.payload,
            };
        default:
            return state;
    }
}
export default storyzPopupReducer;
