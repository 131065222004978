import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Imggroup from "../../assets/img/dummyImg.svg";
import StoryzMessagingList from "components/Events/StoryzMessaging/StoryzMessagingList";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import { informationService } from "../../_services/masters/information.service";
import Allheader from "components/Headers/Allheader";

const StoryzMessage = () => {
  const [storyData, setstoryData] = React.useState({});
  const params = useParams();
  const [paramsId, setParamsId] = React.useState(params.sid ? params.sid : "");
  function storyzDetail() {
    const data = {
      story_id: paramsId,
    };

    informationService.storyzDetails(data).then(
      (res) => {
        if (res.status === "success") {
          setstoryData(res.data[0]);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }
  useEffect(() => {
    storyzDetail();
  }, [paramsId]);

  return (
    <>
      {storyData ? (
        <>
          {/* <Allheader title="Storyz Name" imgScr={Imggroup} arw /> */}
          <Allheader
          id={storyData?.proId}
            title={
              storyData?.storyz_name
                ? storyData?.storyz_name
                : "New Storyz edit"
            }
            imgScr={
              storyData?.profile_photo_Url
                ? storyData?.profile_photo_Url
                : Imggroup
            }
            arw
          />
          {/* <CombinedGuestPoolHeader title="Storyz Name..." imgScr={Imggroup} arw /> */}
          <div className="organizerWrapperMain bgPannel">
            <div className="card organizerCardWrapper">
              <StoryzMessagingList />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="loding">
            <TailSpin
              ariaLabel="loading"
              color="#00BFFF"
              height={50}
              width={50}
            />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default StoryzMessage;
