import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  showBeacons: false,
  showTables: false,
  showOther: false
}

const interactiveMapSlice = createSlice({
  name: "interactiveMap",
  initialState,
  reducers: {
    changeShowBeacons(state, action) {
      state.showBeacons = action.payload
    },
    changeShowTables(state, action) {
      state.showTables = action.payload
    },
    changeShowOther(state, action) {
      state.showOther = action.payload
    }
  }
})

export const {
  changeShowBeacons,
  changeShowOther,
  changeShowTables
} = interactiveMapSlice.actions
export default interactiveMapSlice.reducer
