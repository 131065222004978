import React from "react";
import cn from "classnames";
import classNames from "./Section.module.scss";

const Section = ({ title, children }) => {
  return (
    <section className={classNames.section}>
      <div className={cn(classNames.section__header, classNames.header)}>
        <h1 className={classNames.header__title}>{title}</h1>
      </div>
      <div className={classNames.section__content}>{children}</div>
    </section>
  );
};

export default Section;
