import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import ReactTooltip from "react-tooltip";
import Childorganizationmenu from "./Childorganizationmenu";
function Organizationmenu() {
  return (
    <>
      <Card className="">
        <CardHeader>
          <div className="cardheader2">
            <div className="cardheader">
              <img
                alt="Organization Name"
                src={require("assets/img/icons/organization-2.svg").default}
                className="icon"
              />

              <h3 className="mb-0 headingNew">Organization Menu</h3>
            </div>
            <div>
              <span data-tip="Tooltips display informative text when users hover over, focus on, or tap an element.">
                <img
                  alt="Organization Name"
                  src={require("assets/img/tip.svg").default}
                />
              </span>
              <ReactTooltip />
            </div>
          </div>
        </CardHeader>
        <hr style={{ margin: "0px" }} />

        <CardBody>
          <Childorganizationmenu />
        </CardBody>
      </Card>
    </>
  );
}
export default Organizationmenu;
