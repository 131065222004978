import * as React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
import SocialGoogleLogin from "../../../components/Sociallogin/SocialGoogleLogin";
import SocialFacebookLogin from "../../../components/Sociallogin/SocialFacebookLogin";

import { useForm } from "react-hook-form";
import { userService } from "../../../_services/login/user.service";
import { informationService } from "../../../_services/masters/information.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { userAdminData } from "store/actions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthHeader from "components/Headers/AuthHeader.js";
import * as actionTypes from "../../../store/actionTypes";
import { clearstoryList, clearuserData } from "../../../store/actions/index";

import { useHistory } from "react-router-dom";
function Login() {
  const history = useHistory();

  const dispatch = useDispatch();

  const contact = useSelector((state) => {
    return state.profile;
  });

  const admindata = useSelector((state) => {
    return state.userAdmin.listData;
  });

  React.useEffect(() => {
    dispatch(clearstoryList());

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails === "" || userDetails === null) {
      dispatch(clearuserData());
    }

    // if (contact.isConfirmatiom === true) {
    //   history.push('/admin/account-information');
    // }
  }, []);

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [formemail, setFormemail] = React.useState("");
  const [formpassword, setFormpassword] = React.useState("");
  const [formoption, setFormoption] = React.useState();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      option: "",
    },
  });

  function userdetail() {
    informationService.acInformation().then(
      (res) => {
        if (res.status === "success") {
          localStorage.setItem("userdata", JSON.stringify(res.data));
          dispatch({ type: actionTypes.USER_INFO, payload: res.data });
          history.push("/admin/organization");
        } else {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("userdata");
          history.push("/");
        }
      },
      (error) => {
        localStorage.removeItem("userdata");
        localStorage.removeItem("userDetails");
        history.push("/");
      }
    );

    informationService.allMedia().then(
      (res) => {
        if (res.status === "success") {
          dispatch({ type: actionTypes.ALL_FILES, payload: res.data });
        } else {
          console.log("error");
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  const onSubmit = (data) => {
    userService.login(data).then(
      (res) => {
        if (res.status == "success") {
          localStorage.setItem("userDetails", JSON.stringify(res.data));
          var retrievedObject = JSON.parse(localStorage.getItem("userDetails"));
          if (retrievedObject.name !== Cookies.get("name")) {
            Cookies.set("name", retrievedObject.name);
          }
          if (res.data.account_type === "Super Admin") {
            dispatch({ type: actionTypes.CLEAR_USER_ADMIN_INFO, payload: "" });

            let to = { token: res.data.token };
            localStorage.setItem("userAdmintoken", JSON.stringify(to));
            const value = {
              ...admindata,
              profileAddmindetails: res.data,
            };
            dispatch(userAdminData(value));
            //dispatch({ type: actionTypes.USER_ADMIN, payload: res.data });
            history.push("/organization-list");
          } else {
            userdetail();
          }
        } else if (res.status == "otp_sent_successfully") {
          localStorage.setItem("twoStepVerification", JSON.stringify(res.data));
          window.location.assign("/two-factor-authentication");
        } else if (res.status == "error") {
          localStorage.removeItem("userDetails");
          toast.error(res.message, "Login");
        } else {
          localStorage.removeItem("userDetails");
          toast.error("Please enter valid username and password !!", "Login");
        }
      },
      (error) => {
        localStorage.removeItem("userDetails");
        toast.error("Invalid Credetials !!", "Login");
      }
    );

    if (data.option === true) {
      localStorage.setItem("keepLoggedin", JSON.stringify(data));
    } else {
      localStorage.removeItem("keepLoggedin");
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("keepLoggedin")) {
      var retrievedObject = JSON.parse(localStorage.getItem("keepLoggedin"));
      setValue("email", retrievedObject.email, { shouldTouch: true });
      setValue("password", retrievedObject.password, { shouldTouch: true });
      setValue("option", retrievedObject.option, { shouldTouch: true });
      //console.log(retrievedObject.email);
    }
  }, [onSubmit]);

  // React.useEffect(() => {
  //   if (localStorage.getItem('userDetails')) {
  //     history.push('/admin/account-information');
  //   }
  // }, [])

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col lg="6" md="8" xl="5" className="my-7">
            <Card className="bgsecondary border-0 mb-0">
              <CardHeader className="bg-white">
                <Row>
                  <Col
                    xs="12"
                    style={{ textAlign: "right", margin: "0px" }}
                    className="mb-5"
                  >
                    <span style={{ width: "54px" }}>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "bold",
                          fontSize: "24px",
                          lineHeight: "32px",
                        }}
                      >
                        <img
                          alt="OurStoryz"
                          src={
                            require("assets/img/icons/common/Logomark.svg")
                              .default
                          }
                          style={{ padding: "5px" }}
                        />
                        OurStoryz
                      </h1>
                    </span>

                    <span
                      style={{
                        color: "#FC3158",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                  <Link
                    className="font-weight-bold text-warning mt-5"
                    to="/signup"
                  >
                    Sign Up
                  </Link>
                     
                    </span>
                  </Col>
                </Row>
                <div className=" mb-5">
                  <span style={{ float: "left", width: "54px" }}>
                    <img
                      alt="Organization Name"
                      src={
                        require("assets/img/icons/common/Layer-1.svg").default
                      }
                    />
                  </span>
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "32px",
                    }}
                  >
                    {Cookies.get("name")
                      ? Cookies.get("name")
                      : "Organization Name"}
                  </h2>
                </div>
                <div className="text-muted    mb-4">
                  <small style={{ color: "#000", fontSize: "14px" }}>
                    Sign in to your account to begin working in OurStoryz.
                  </small>
                </div>

                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon appleLoginBtn"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    style={{ background: "#000", color: "#FDFDFD" }}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/Vector.svg").default
                        }
                        style={{ width: "16px" }}
                      />
                    </span>
                    <span className="btn-inner--text">Login with Apple</span>
                  </Button>

                  <SocialFacebookLogin />
                  <SocialGoogleLogin />
                </div>
              </CardHeader>
              <div
                className="text-center text-muted bg-white"
                style={{ borderBottom: "1px solid #979798" }}
              >
                <span
                  style={{
                    display: "inline-block",
                    padding: "0px 20px",
                    background: "#fff",
                    top: "10px",
                    position: "relative",
                  }}
                >
                  OR
                </span>
              </div>
              <CardBody className="bg-white">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="loginFormNew"
                >
                  <FormGroup
                    className={classnames("mb-3 ", {
                      focused: focusedEmail,
                    })}
                  >
                    <label style={{ fontSize: "14px", fontWeight: "400" }}>
                      Email Address:
                    </label>
                    <InputGroup
                      className={`input-group-merge input-group-alternative  ${
                        errors.email ? "errorborder" : ""
                      }`}
                    >
                      <input
                        className={`form-control`}
                        placeholder="enter email..."
                        type="email"
                        name="email"
                        id="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                        defaultValue={formemail ? formemail : ""}
                      />
                    </InputGroup>
                    <small className="error">
                      {errors?.email && errors.email.message}
                    </small>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <label style={{ fontSize: "14px", fontWeight: "400" }}>
                      Password:
                    </label>
                    <InputGroup
                      className={`input-group-merge input-group-alternative passInput  ${
                        errors.password ? "errorborder" : ""
                      }`}
                    >
                      <input
                        className={`form-control`}
                        placeholder="type in password..."
                        type={passwordType}
                        name="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        defaultValue={formpassword ? formpassword : ""}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          onClick={togglePassword}
                          style={{ cursor: "pointer", color: "#000" }}
                        >
                          {passwordType === "text" ? (
                            <IoMdEye />
                          ) : (
                            <IoMdEyeOff />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <small className="error">
                      {errors?.password && errors.password.message}
                    </small>
                  </FormGroup>
                  <Row className="mt-3" style={{ fontSize: "11px" }}>
                    <Col xs="5">
                      <div className="custom-control custom-control-alternative custom-checkbox loggedInCheckbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option"
                          {...register("option")}
                        />
                        <label
                          className="form-check-label"
                          style={{ fontSize: "12px" }}
                        >
                          Keep Logged In
                        </label>
                      </div>
                    </Col>
                    <Col className="text-right" xs="7">
                      <label
                        className="form-check-label"
                        style={{ fontSize: "12px" }}
                      >
                        Forgot{" "}
                        <Link
                          to="/password-recovery"
                          style={{
                            color: "#FC3158",
                            textDecoration: "underline",
                          }}
                        >
                          username or password?
                        </Link>
                      </label>
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button className="my-4" type="submit">
                      Log In
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
}

export default Login;
