import React, { useState } from "react";
import Eventmenu from "../eventMenu";
import { Eventstyle } from "../styles";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { GuestEventWrapper } from "./style";
import {
  useFindPath,
  useFindPathSid,
  useFindPathEid,
} from "../../../CommonData";

import makeAnimated from "react-select/animated";
import { useHistory } from "react-router-dom";
import GuestListData from "./GuestListData";

const UserGuestList = ({ args, columns, data, name }) => {
  let history = useHistory();

  const [exportGuestList, setExportGuestList] = useState(false);

  let sid = useFindPathSid(3);
  let eid = useFindPathEid(4);

  // const percentage = 66;

  // const handleDeleteGuestUser = (e) => {
  //   setDeleteGuestUser(!deleteGuestUser);
  // };

  // const guestListOption = [
  //   {
  //     img: EditPencilIcon,
  //     title: " Details",
  //   },
  //   {
  //     img: ImgIcon,
  //     title: "Profile Images",
  //   },
  //   {
  //     img: ImgIcon,
  //     title: "Keepsake Album",
  //   },
  //   {
  //     img: LockInvertIcon,
  //     title: "Event Authentication",
  //   },
  //   {
  //     img: DeleteIcon,
  //     title: "Delete",
  //     handleClick: handleDeleteGuestUser,
  //   },
  // ];

  const GuestListOptionList = [
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Sally Struthers",
      type: "Friend of Groom",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Jane Doe",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Donna Marton",
      type: "Sibling of Bride",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Bill Killer",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Dr. Maya Angelou",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Thad Bees",
      type: "Event Planner",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Mark Bigler",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "Mark Bigler",
      type: "Guest",
    },
    {
      guest_img:
        "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1361&q=80",
      title: "John Kresl",
      type: "Guest",
    },
  ];

  const handleExportGuestList = () => {
    setExportGuestList(!exportGuestList);
  };

  // const handleStatusOfGuestUser = () => {
  //   setStatusGuestUser(!statusGuestUser);
  // };

  // const handleReseting = () => {
  //   setResetting(true);
  //   setTimeout(() => {
  //     //  console.log("Hello from setTimeout");
  //     setResetting(false);
  //   }, 3000);
  // };

  return (
    <>
      <Eventstyle>
        <Eventmenu />
        <div className="column2">
          <div className="column2sate backgroeventdata">
            <div className="title">
              <div className="badge">Event</div>
              {name ? name : ""}
            </div>
            <div>
              <div className="imageeventim234">
                <span>Guest List</span>
              </div>
              {/* <GuestListDropdown
                guestListOption={GuestListOptionList}
                placeholder="Guest List"
              /> */}
            </div>
          </div>
          <div className="eventBannerPara border-bottom mb-4">
            This page lets you view and create a todo list associated with this
            specific Storyz. <br /> To see the complete list of todos, go to the
            <Link
              onClick={() => {
                history.push(`/admin/storyz/${sid}`);
              }}
              className="text-decoration-underline mx-1"
            >
              Storyz view
            </Link>
            or
            <Link
              onClick={() => {
                history.push(`/admin/organization/${sid}`);
              }}
              className="text-decoration-underline mx-1"
            >
              Organization view
            </Link>
            .
          </div>
          {/* <DataTable
            columns={columns}
            data={data}
            pagination
            // sortIcon={sortIcon}
          /> */}

          {/* <MaterialStory /> */}

          <GuestEventWrapper className="px-4 pb-4">
            <GuestListData />
            {/* <table id="example" className="stripe row-border order-column">
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Position</th>
                  <th>Office</th>
                  <th>Age</th>
                  <th>Start date</th>
                  <th>Salary</th>
                  <th>Extn.</th>
                  <th>E-mail</th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Position</th>
                  <th>Office</th>
                  <th>Age</th>
                  <th>Start date</th>
                  <th>Salary</th>
                  <th>Extn.</th>
                  <th>E-mail</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
                <tr>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                  <td>Tiger</td>
                  <td>Nixon</td>
                  <td>System Architect</td>
                  <td>Edinburgh</td>
                  <td>61</td>
                  <td>2011-04-25</td>
                  <td>$320,800</td>
                  <td>5421</td>
                  <td>t.nixon@datatables.net</td>
                </tr>
              </tbody>
            </table> */}
            {/* <div className="userRightTableWrapper"> */}

            {/* <table
              className="table stripe row-border order-column"
              id="example"
            >
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>
                    <FaUserAlt className="userIcon" />
                    Guest
                  </th>
                  <th>
                    <div className="guestCircleWrapper">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                  </th>
                  <th>RSVP</th>
                  <th>Sirname</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Suffix</th>
                  <th>Email Address </th>
                  <th>Phone Number</th>
                  <th>Country Code</th>
                  <th>Greeting</th>
                  <th>Guest Honors</th>
                  <th>Groups</th>
                  <th>Events Invited to</th>
                  <th>Admin Notes</th>
                  <th>Contact</th>
                  <th>Social Media</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <GuestActionDropdown guestListOption={guestListOption} />

                    <Modal
                      isOpen={deleteGuestUser}
                      toggle={handleDeleteGuestUser}
                      {...args}
                      className="resetGeospaceModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Remove This Guest From the Guest List?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">Guest Name</div>
                                <div className="geothumbSubTitle">
                                  Role in Event
                                </div>
                              </div>
                            </div>
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              Confirm you want to remove this guest from the
                              Event guest list.{" "}
                            </p>
                            <p>This will NOT remove the guest data.</p>
                            <p>
                              Instead it will just remove the guest from the
                              invite list for this event.
                            </p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button color="primary" className="flex-grow-1 pinkBtn">
                          Remove Guest from List
                        </Button>
                        <Button
                          className="btn-default cancelBtn"
                          onClick={() => handleDeleteGuestUser(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </td>
                  <td>
                    <div className="guestUserCard">
                      <div className="guestUserPic">
                        <img
                          src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                          className="pic"
                          alt=""
                        />
                      </div>
                      <div className="guestUserBody">
                        <div className="title">Guest Name </div>
                        <div className="subTitle">Sibling of Bride</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="guestCircleWrapper">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                  </td>
                  <td>
                    <span
                      className="RSVPchip pending"
                      onClick={handleStatusOfGuestUser}
                    >
                      Pending
                    </span>
                    <Modal
                      isOpen={statusGuestUser}
                      toggle={handleStatusOfGuestUser}
                      {...args}
                      className="resetGeospaceModal statusofGuestaListModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Force the RSVP Status for This Guest?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">
                                  Guest NameXXX
                                </div>
                                <div className="geothumbSubTitle">
                                  Event Role
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              You are manually setting the status of this
                              Guest’s RSVP. Select an RSVP state belolow by
                              clicking one of the following buttons:
                            </p>
                            <hr className="mb-4 mt-3" />
                            <div className="statusGuestUserWrapper mb-3">
                              <div className="form-check pending">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  PENDING
                                </label>
                              </div>
                              <div className="form-check going">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  GOING
                                </label>
                              </div>
                              <div className="form-check notGoing">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  NOT GOING
                                </label>
                              </div>
                              <div className="form-check attended">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  ATTENDED
                                </label>
                              </div>
                            </div>
                            <p>
                              Are you sure you want to do this? This will erase
                              the RSVP status set by the system.
                            </p>
                          </Col>
                        </Row>

                        <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                          <Button
                            color="primary"
                            className="flex-grow-1 pinkBtn"
                            onClick={handleReseting}
                          >
                            Remove Guest from List
                          </Button>
                          <Button
                            className="btn-default cancelBtn"
                            onClick={() => handleStatusOfGuestUser(false)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </ModalBody>
                    </Modal>
                    <Modal
                      isOpen={resetting}
                      toggle={handleReseting}
                      {...args}
                      className="resetRSVPModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="border-bottom mb-3 pb-3">
                          <div className="head">Resetting RSVPs</div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <p className=" px-4 text-center">
                              Changing the RSVP Status for this guest...
                            </p>
                            <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                              <div className="circleAnimation"></div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="First Name"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Last Name"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Email"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Phone Number"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Type short greeting here.."
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Admin notes here"
                    />
                  </td>
                  <td>
                    <div>
                      <Link className="contactIcon" to="/">
                        <BsFillChatDotsFill />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <AiOutlineMail />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <FaPhoneAlt />
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button type="button" className="editSocialBtn">
                        Edit Social
                      </button>
                      <Link to="/" className="socialIcon">
                        <img src={InstagramIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TiktokIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TwitterIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={FacebookIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={YoutubeIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={VimeoIcon} alt="" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <GuestActionDropdown guestListOption={guestListOption} />

                    <Modal
                      isOpen={deleteGuestUser}
                      toggle={handleDeleteGuestUser}
                      {...args}
                      className="resetGeospaceModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Remove This Guest From the Guest List?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">Guest Name</div>
                                <div className="geothumbSubTitle">
                                  Role in Event
                                </div>
                              </div>
                            </div>
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              Confirm you want to remove this guest from the
                              Event guest list.{" "}
                            </p>
                            <p>This will NOT remove the guest data.</p>
                            <p>
                              Instead it will just remove the guest from the
                              invite list for this event.
                            </p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button color="primary" className="flex-grow-1 pinkBtn">
                          Remove Guest from List
                        </Button>
                        <Button
                          className="btn-default cancelBtn"
                          onClick={() => handleDeleteGuestUser(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </td>
                  <td>
                    <div className="guestUserCard">
                      <div className="guestUserPic">
                        <img
                          src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                          className="pic"
                          alt=""
                        />
                      </div>
                      <div className="guestUserBody">
                        <div className="title">Guest Name </div>
                        <div className="subTitle">Sibling of Bride</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="guestCircleWrapper">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                  </td>
                  <td>
                    <span
                      className="RSVPchip success"
                      onClick={handleStatusOfGuestUser}
                    >
                      Going
                    </span>

                    <Modal
                      isOpen={statusGuestUser}
                      toggle={handleStatusOfGuestUser}
                      {...args}
                      className="resetGeospaceModal statusofGuestaListModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Force the RSVP Status for This Guest?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">
                                  Guest NameXXX
                                </div>
                                <div className="geothumbSubTitle">
                                  Event Role
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              You are manually setting the status of this
                              Guest’s RSVP. Select an RSVP state belolow by
                              clicking one of the following buttons:
                            </p>
                            <hr className="mb-4 mt-3" />
                            <div className="statusGuestUserWrapper mb-3">
                              <div className="form-check pending">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  PENDING
                                </label>
                              </div>
                              <div className="form-check going">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  GOING
                                </label>
                              </div>
                              <div className="form-check notGoing">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  NOT GOING
                                </label>
                              </div>
                              <div className="form-check attended">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  ATTENDED
                                </label>
                              </div>
                            </div>
                            <p>
                              Are you sure you want to do this? This will erase
                              the RSVP status set by the system.
                            </p>
                          </Col>
                        </Row>

                        <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                          <Button
                            color="primary"
                            className="flex-grow-1 pinkBtn"
                            onClick={handleReseting}
                          >
                            Remove Guest from List
                          </Button>
                          <Button
                            className="btn-default cancelBtn"
                            onClick={() => handleStatusOfGuestUser(false)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </ModalBody>
                    </Modal>
                    <Modal
                      isOpen={resetting}
                      toggle={handleReseting}
                      {...args}
                      className="resetRSVPModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="border-bottom mb-3 pb-3">
                          <div className="head">Resetting RSVPs</div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <p className=" px-4 text-center">
                              Changing the RSVP Status for this guest...
                            </p>
                            <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                              <div className="circleAnimation"></div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="First Name"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Last Name"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Email"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Phone Number"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Type short greeting here.."
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Admin notes here"
                    />
                  </td>
                  <td>
                    <div>
                      <Link className="contactIcon" to="/">
                        <BsFillChatDotsFill />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <AiOutlineMail />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <FaPhoneAlt />
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button type="button" className="editSocialBtn">
                        Edit Social
                      </button>
                      <Link to="/" className="socialIcon">
                        <img src={InstagramIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TiktokIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TwitterIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={FacebookIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={YoutubeIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={VimeoIcon} alt="" />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <GuestActionDropdown guestListOption={guestListOption} />

                    <Modal
                      isOpen={deleteGuestUser}
                      toggle={handleDeleteGuestUser}
                      {...args}
                      className="resetGeospaceModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Remove This Guest From the Guest List?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">Guest Name</div>
                                <div className="geothumbSubTitle">
                                  Role in Event
                                </div>
                              </div>
                            </div>
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              Confirm you want to remove this guest from the
                              Event guest list.{" "}
                            </p>
                            <p>This will NOT remove the guest data.</p>
                            <p>
                              Instead it will just remove the guest from the
                              invite list for this event.
                            </p>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                        <Button color="primary" className="flex-grow-1 pinkBtn">
                          Remove Guest from List
                        </Button>
                        <Button
                          className="btn-default cancelBtn"
                          onClick={() => handleDeleteGuestUser(false)}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </td>
                  <td>
                    <div className="guestUserCard">
                      <div className="guestUserPic">
                        <img
                          src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                          className="pic"
                          alt=""
                        />
                      </div>
                      <div className="guestUserBody">
                        <div className="title">Guest Name </div>
                        <div className="subTitle">Sibling of Bride</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="guestCircleWrapper">
                      <CircularProgressbar
                        value={percentage}
                        text={false}
                        strokeWidth={20}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor: "#53D769 ",
                          trailColor: "#1345A5",
                        })}
                      />
                    </div>
                  </td>
                  <td>
                    <span
                      className="RSVPchip failed"
                      onClick={handleStatusOfGuestUser}
                    >
                      no going
                    </span>
                    <Modal
                      isOpen={statusGuestUser}
                      toggle={handleStatusOfGuestUser}
                      {...args}
                      className="resetGeospaceModal statusofGuestaListModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                          <img
                            src={ResetGeospaceInfoIcon}
                            alt=""
                            className="geospaceInfoIcon"
                          />
                          <div className="head">
                            Force the RSVP Status for This Guest?
                          </div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <div className="eventThumWrapper">
                              <div className="eventThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="eventThumImg"
                                />
                              </div>
                              <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                  Event Name
                                </div>
                                <div className="eventthumbSubTitle">
                                  Location
                                </div>
                              </div>
                            </div>
                            <div className="geospaceThumWrapper">
                              <div className="geospaceThum">
                                <img
                                  src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                  alt=""
                                  className="geospaceThumImg"
                                />
                              </div>
                              <div className="geospaceThumContent">
                                <div className="geothumbTitle">
                                  Guest NameXXX
                                </div>
                                <div className="geothumbSubTitle">
                                  Event Role
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-2 ">
                          <Col className="px-4">
                            <p>
                              You are manually setting the status of this
                              Guest’s RSVP. Select an RSVP state belolow by
                              clicking one of the following buttons:
                            </p>
                            <hr className="mb-4 mt-3" />
                            <div className="statusGuestUserWrapper mb-3">
                              <div className="form-check pending">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  PENDING
                                </label>
                              </div>
                              <div className="form-check going">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  GOING
                                </label>
                              </div>
                              <div className="form-check notGoing">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  NOT GOING
                                </label>
                              </div>
                              <div className="form-check attended">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  ATTENDED
                                </label>
                              </div>
                            </div>
                            <p>
                              Are you sure you want to do this? This will erase
                              the RSVP status set by the system.
                            </p>
                          </Col>
                        </Row>

                        <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                          <Button
                            color="primary"
                            className="flex-grow-1 pinkBtn"
                            onClick={handleReseting}
                          >
                            Remove Guest from List
                          </Button>
                          <Button
                            className="btn-default cancelBtn"
                            onClick={() => handleStatusOfGuestUser(false)}
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </ModalBody>
                    </Modal>
                    <Modal
                      isOpen={resetting}
                      toggle={handleReseting}
                      {...args}
                      className="resetRSVPModal"
                    >
                      <ModalBody className="pb-0">
                        <div className="border-bottom mb-3 pb-3">
                          <div className="head">Resetting RSVPs</div>
                        </div>
                        <Row>
                          <Col className="px-4">
                            <p className=" px-4 text-center">
                              Changing the RSVP Status for this guest...
                            </p>
                            <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                              <div className="circleAnimation"></div>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="First Name"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Last Name"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Email"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Phone Number"
                    />
                  </td>
                  <td>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={showEntries}
                      className="formSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Type short greeting here.."
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      options={colourOptions}
                      className="formMultiSelect"
                    />
                  </td>
                  <td>
                    <input
                      className="formInput"
                      type="text"
                      name="name"
                      value=""
                      placeholder="Admin notes here"
                    />
                  </td>
                  <td>
                    <div>
                      <Link className="contactIcon" to="/">
                        <BsFillChatDotsFill />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <AiOutlineMail />
                      </Link>
                      <Link className="contactIcon" to="/">
                        <FaPhoneAlt />
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button type="button" className="editSocialBtn">
                        Edit Social
                      </button>
                      <Link to="/" className="socialIcon">
                        <img src={InstagramIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TiktokIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={TwitterIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={FacebookIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={YoutubeIcon} alt="" />
                      </Link>
                      <Link to="/" className="socialIcon">
                        <img src={VimeoIcon} alt="" />
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> */}
            {/* </div> */}
            {/* <div className="tableWrapperOuter">
              <div className="userLeftTableWrapper">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Actions</th>
                      <th>
                        <FaUserAlt className="userIcon" />
                        Guest
                      </th>
                      <th>
                        <div className="guestCircleWrapper">
                          <CircularProgressbar
                            value={percentage}
                            text={false}
                            strokeWidth={20}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#53D769 ",
                              trailColor: "#1345A5",
                            })}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <GuestActionDropdown
                          guestListOption={guestListOption}
                        />

                        <Modal
                          isOpen={deleteGuestUser}
                          toggle={handleDeleteGuestUser}
                          {...args}
                          className="resetGeospaceModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                              <img
                                src={ResetGeospaceInfoIcon}
                                alt=""
                                className="geospaceInfoIcon"
                              />
                              <div className="head">
                                Remove This Guest From the Guest List?
                              </div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <div className="geospaceThumWrapper">
                                  <div className="geospaceThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="geospaceThumImg"
                                    />
                                  </div>
                                  <div className="geospaceThumContent">
                                    <div className="geothumbTitle">
                                      Guest Name
                                    </div>
                                    <div className="geothumbSubTitle">
                                      Role in Event
                                    </div>
                                  </div>
                                </div>
                                <div className="eventThumWrapper">
                                  <div className="eventThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="eventThumImg"
                                    />
                                  </div>
                                  <div className="eventThumContent">
                                    <div className="eventthumbTitle">
                                      Event Name
                                    </div>
                                    <div className="eventthumbSubTitle">
                                      Location
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 ">
                              <Col className="px-4">
                                <p>
                                  Confirm you want to remove this guest from the
                                  Event guest list.{" "}
                                </p>
                                <p>This will NOT remove the guest data.</p>
                                <p>
                                  Instead it will just remove the guest from the
                                  invite list for this event.
                                </p>
                              </Col>
                            </Row>
                          </ModalBody>
                          <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                            <Button
                              color="primary"
                              className="flex-grow-1 pinkBtn"
                            >
                              Remove Guest from List
                            </Button>
                            <Button
                              className="btn-default cancelBtn"
                              onClick={() => handleDeleteGuestUser(false)}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </td>
                      <td>
                        <div className="guestUserCard">
                          <div className="guestUserPic">
                            <img
                              src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                              className="pic"
                              alt=""
                            />
                          </div>
                          <div className="guestUserBody">
                            <div className="title">Guest Name </div>
                            <div className="subTitle">Sibling of Bride</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="guestCircleWrapper">
                          <CircularProgressbar
                            value={percentage}
                            text={false}
                            strokeWidth={20}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#53D769 ",
                              trailColor: "#1345A5",
                            })}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <GuestActionDropdown
                          guestListOption={guestListOption}
                        />
                      </td>
                      <td>
                        <div className="guestUserCard">
                          <div className="guestUserPic">
                            <img
                              src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                              className="pic"
                              alt=""
                            />
                          </div>
                          <div className="guestUserBody">
                            <div className="title">Guest Name </div>
                            <div className="subTitle">Sibling of Bride</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="guestCircleWrapper">
                          <CircularProgressbar
                            value={percentage}
                            text={false}
                            strokeWidth={20}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#53D769 ",
                              trailColor: "#1345A5",
                            })}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <GuestActionDropdown
                          guestListOption={guestListOption}
                        />
                      </td>
                      <td>
                        <div className="guestUserCard">
                          <div className="guestUserPic">
                            <img
                              src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                              className="pic"
                              alt=""
                            />
                          </div>
                          <div className="guestUserBody">
                            <div className="title">Guest Name </div>
                            <div className="subTitle">Sibling of Bride</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="guestCircleWrapper">
                          <CircularProgressbar
                            value={percentage}
                            text={false}
                            strokeWidth={20}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#53D769 ",
                              trailColor: "#1345A5",
                            })}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="userRightTableWrapper">
                <table
                  className="table stripe row-border order-column"
                  id="example"
                >
                  <thead>
                    <tr>
                      <th>RSVP</th>
                      <th>Sirname</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Suffix</th>
                      <th>Email Address </th>
                      <th>Phone Number</th>
                      <th>Country Code</th>
                      <th>Greeting</th>
                      <th>Guest Honors</th>
                      <th>Groups</th>
                      <th>Events Invited to</th>
                      <th>Admin Notes</th>
                      <th>Contact</th>
                      <th>Social Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span
                          className="RSVPchip pending"
                          onClick={handleStatusOfGuestUser}
                        >
                          Pending
                        </span>
                        <Modal
                          isOpen={statusGuestUser}
                          toggle={handleStatusOfGuestUser}
                          {...args}
                          className="resetGeospaceModal statusofGuestaListModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                              <img
                                src={ResetGeospaceInfoIcon}
                                alt=""
                                className="geospaceInfoIcon"
                              />
                              <div className="head">
                                Force the RSVP Status for This Guest?
                              </div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <div className="eventThumWrapper">
                                  <div className="eventThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="eventThumImg"
                                    />
                                  </div>
                                  <div className="eventThumContent">
                                    <div className="eventthumbTitle">
                                      Event Name
                                    </div>
                                    <div className="eventthumbSubTitle">
                                      Location
                                    </div>
                                  </div>
                                </div>
                                <div className="geospaceThumWrapper">
                                  <div className="geospaceThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="geospaceThumImg"
                                    />
                                  </div>
                                  <div className="geospaceThumContent">
                                    <div className="geothumbTitle">
                                      Guest NameXXX
                                    </div>
                                    <div className="geothumbSubTitle">
                                      Event Role
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 ">
                              <Col className="px-4">
                                <p>
                                  You are manually setting the status of this
                                  Guest’s RSVP. Select an RSVP state belolow by
                                  clicking one of the following buttons:
                                </p>
                                <hr className="mb-4 mt-3" />
                                <div className="statusGuestUserWrapper mb-3">
                                  <div className="form-check pending">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      PENDING
                                    </label>
                                  </div>
                                  <div className="form-check going">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      GOING
                                    </label>
                                  </div>
                                  <div className="form-check notGoing">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      NOT GOING
                                    </label>
                                  </div>
                                  <div className="form-check attended">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      ATTENDED
                                    </label>
                                  </div>
                                </div>
                                <p>
                                  Are you sure you want to do this? This will
                                  erase the RSVP status set by the system.
                                </p>
                              </Col>
                            </Row>

                            <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                              <Button
                                color="primary"
                                className="flex-grow-1 pinkBtn"
                                onClick={handleReseting}
                              >
                                Remove Guest from List
                              </Button>
                              <Button
                                className="btn-default cancelBtn"
                                onClick={() => handleStatusOfGuestUser(false)}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </ModalBody>
                        </Modal>
                        <Modal
                          isOpen={resetting}
                          toggle={handleReseting}
                          {...args}
                          className="resetRSVPModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="border-bottom mb-3 pb-3">
                              <div className="head">Resetting RSVPs</div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <p className=" px-4 text-center">
                                  Changing the RSVP Status for this guest...
                                </p>
                                <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                                  <div className="circleAnimation"></div>
                                </div>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="First Name"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Last Name"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Email"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Phone Number"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Type short greeting here.."
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Admin notes here"
                        />
                      </td>
                      <td>
                        <div>
                          <Link className="contactIcon" to="/">
                            <BsFillChatDotsFill />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <AiOutlineMail />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <FaPhoneAlt />
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button type="button" className="editSocialBtn">
                            Edit Social
                          </button>
                          <Link to="/" className="socialIcon">
                            <img src={InstagramIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TiktokIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TwitterIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={FacebookIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={YoutubeIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={VimeoIcon} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="RSVPchip success"
                          onClick={handleStatusOfGuestUser}
                        >
                          Going
                        </span>

                        <Modal
                          isOpen={statusGuestUser}
                          toggle={handleStatusOfGuestUser}
                          {...args}
                          className="resetGeospaceModal statusofGuestaListModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                              <img
                                src={ResetGeospaceInfoIcon}
                                alt=""
                                className="geospaceInfoIcon"
                              />
                              <div className="head">
                                Force the RSVP Status for This Guest?
                              </div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <div className="eventThumWrapper">
                                  <div className="eventThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="eventThumImg"
                                    />
                                  </div>
                                  <div className="eventThumContent">
                                    <div className="eventthumbTitle">
                                      Event Name
                                    </div>
                                    <div className="eventthumbSubTitle">
                                      Location
                                    </div>
                                  </div>
                                </div>
                                <div className="geospaceThumWrapper">
                                  <div className="geospaceThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="geospaceThumImg"
                                    />
                                  </div>
                                  <div className="geospaceThumContent">
                                    <div className="geothumbTitle">
                                      Guest NameXXX
                                    </div>
                                    <div className="geothumbSubTitle">
                                      Event Role
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 ">
                              <Col className="px-4">
                                <p>
                                  You are manually setting the status of this
                                  Guest’s RSVP. Select an RSVP state belolow by
                                  clicking one of the following buttons:
                                </p>
                                <hr className="mb-4 mt-3" />
                                <div className="statusGuestUserWrapper mb-3">
                                  <div className="form-check pending">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      PENDING
                                    </label>
                                  </div>
                                  <div className="form-check going">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      GOING
                                    </label>
                                  </div>
                                  <div className="form-check notGoing">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      NOT GOING
                                    </label>
                                  </div>
                                  <div className="form-check attended">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      ATTENDED
                                    </label>
                                  </div>
                                </div>
                                <p>
                                  Are you sure you want to do this? This will
                                  erase the RSVP status set by the system.
                                </p>
                              </Col>
                            </Row>

                            <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                              <Button
                                color="primary"
                                className="flex-grow-1 pinkBtn"
                                onClick={handleReseting}
                              >
                                Remove Guest from List
                              </Button>
                              <Button
                                className="btn-default cancelBtn"
                                onClick={() => handleStatusOfGuestUser(false)}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </ModalBody>
                        </Modal>
                        <Modal
                          isOpen={resetting}
                          toggle={handleReseting}
                          {...args}
                          className="resetRSVPModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="border-bottom mb-3 pb-3">
                              <div className="head">Resetting RSVPs</div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <p className=" px-4 text-center">
                                  Changing the RSVP Status for this guest...
                                </p>
                                <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                                  <div className="circleAnimation"></div>
                                </div>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="First Name"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Last Name"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Email"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Phone Number"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Type short greeting here.."
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Admin notes here"
                        />
                      </td>
                      <td>
                        <div>
                          <Link className="contactIcon" to="/">
                            <BsFillChatDotsFill />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <AiOutlineMail />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <FaPhoneAlt />
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button type="button" className="editSocialBtn">
                            Edit Social
                          </button>
                          <Link to="/" className="socialIcon">
                            <img src={InstagramIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TiktokIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TwitterIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={FacebookIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={YoutubeIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={VimeoIcon} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          className="RSVPchip failed"
                          onClick={handleStatusOfGuestUser}
                        >
                          no going
                        </span>
                        <Modal
                          isOpen={statusGuestUser}
                          toggle={handleStatusOfGuestUser}
                          {...args}
                          className="resetGeospaceModal statusofGuestaListModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                              <img
                                src={ResetGeospaceInfoIcon}
                                alt=""
                                className="geospaceInfoIcon"
                              />
                              <div className="head">
                                Force the RSVP Status for This Guest?
                              </div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <div className="eventThumWrapper">
                                  <div className="eventThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="eventThumImg"
                                    />
                                  </div>
                                  <div className="eventThumContent">
                                    <div className="eventthumbTitle">
                                      Event Name
                                    </div>
                                    <div className="eventthumbSubTitle">
                                      Location
                                    </div>
                                  </div>
                                </div>
                                <div className="geospaceThumWrapper">
                                  <div className="geospaceThum">
                                    <img
                                      src="https://images.unsplash.com/photo-1677069955603-26200937faa7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
                                      alt=""
                                      className="geospaceThumImg"
                                    />
                                  </div>
                                  <div className="geospaceThumContent">
                                    <div className="geothumbTitle">
                                      Guest NameXXX
                                    </div>
                                    <div className="geothumbSubTitle">
                                      Event Role
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2 ">
                              <Col className="px-4">
                                <p>
                                  You are manually setting the status of this
                                  Guest’s RSVP. Select an RSVP state belolow by
                                  clicking one of the following buttons:
                                </p>
                                <hr className="mb-4 mt-3" />
                                <div className="statusGuestUserWrapper mb-3">
                                  <div className="form-check pending">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      PENDING
                                    </label>
                                  </div>
                                  <div className="form-check going">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      GOING
                                    </label>
                                  </div>
                                  <div className="form-check notGoing">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      NOT GOING
                                    </label>
                                  </div>
                                  <div className="form-check attended">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      ATTENDED
                                    </label>
                                  </div>
                                </div>
                                <p>
                                  Are you sure you want to do this? This will
                                  erase the RSVP status set by the system.
                                </p>
                              </Col>
                            </Row>

                            <ModalFooter className="px-0 pb-4 d-flex align-center justify-space-between border-top pt-3">
                              <Button
                                color="primary"
                                className="flex-grow-1 pinkBtn"
                                onClick={handleReseting}
                              >
                                Remove Guest from List
                              </Button>
                              <Button
                                className="btn-default cancelBtn"
                                onClick={() => handleStatusOfGuestUser(false)}
                              >
                                Cancel
                              </Button>
                            </ModalFooter>
                          </ModalBody>
                        </Modal>
                        <Modal
                          isOpen={resetting}
                          toggle={handleReseting}
                          {...args}
                          className="resetRSVPModal"
                        >
                          <ModalBody className="pb-0">
                            <div className="border-bottom mb-3 pb-3">
                              <div className="head">Resetting RSVPs</div>
                            </div>
                            <Row>
                              <Col className="px-4">
                                <p className=" px-4 text-center">
                                  Changing the RSVP Status for this guest...
                                </p>
                                <div className="circleAnimationWrapper px-4 pb-4 pt-2 d-flex align-items-center justify-content-center">
                                  <div className="circleAnimation"></div>
                                </div>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="First Name"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Last Name"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Email"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Phone Number"
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={showEntries}
                          className="formSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Type short greeting here.."
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={[colourOptions[4], colourOptions[5]]}
                          isMulti
                          options={colourOptions}
                          className="formMultiSelect"
                        />
                      </td>
                      <td>
                        <input
                          className="formInput"
                          type="text"
                          name="name"
                          value=""
                          placeholder="Admin notes here"
                        />
                      </td>
                      <td>
                        <div>
                          <Link className="contactIcon" to="/">
                            <BsFillChatDotsFill />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <AiOutlineMail />
                          </Link>
                          <Link className="contactIcon" to="/">
                            <FaPhoneAlt />
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button type="button" className="editSocialBtn">
                            Edit Social
                          </button>
                          <Link to="/" className="socialIcon">
                            <img src={InstagramIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TiktokIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={TwitterIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={FacebookIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={YoutubeIcon} alt="" />
                          </Link>
                          <Link to="/" className="socialIcon">
                            <img src={VimeoIcon} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            {/* <div className="paginationWrapper">
              <div className="showingPaginationText">
                Showing 1 to 20 of 2220 Entries{" "}
              </div>
              <nav className="customPagination">
                <ul className="pagination">
                  <li className="page-item disabled prev">
                    <Link className="page-link next">Previous</Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      1
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      2
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">
                      3
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      4
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      5
                    </Link>
                  </li>
                  <li className="page-item dotted">
                    <Link className="page-link" to="#">
                      <BsThreeDots />
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      12
                    </Link>
                  </li>
                  <li className="page-item next">
                    <Link className="page-link" to="#">
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div> */}
          </GuestEventWrapper>
        </div>
      </Eventstyle>
    </>
  );
};

export default UserGuestList;
