import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Container,
    Row,
    Col,
} from "reactstrap";
import ResetGeospaceInfoIcon from "../../../assets/img/resetgeospaceInfoIcon.svg";
import { commonData } from "store/actions";
import { eventsService } from "_services/masters/events.service";
import { useSelector, useDispatch } from "react-redux";
import DummyImg from "../../../assets/img/dummyImg.svg";
import * as actionTypes from "../../../store/actionTypes";
function DeleteGuestList(props) {
    const dispatch = useDispatch();
    const common = useSelector((state) => {
        return state.commonData.common;
    });

    const showAlert = () => {
        var data = {
            event_id: common.eid,
            type: "All",
        };

        eventsService.deleteGuest(data).then(
            (res) => {
                if (res.status === "success") {
                    dispatch({ type: actionTypes.GUEST_LIST_CLEAR, payload: "" });
                    props.onHide();
                } else {
                    console.log("Something went wrong !!");
                }
            },
            (error) => {
                console.log("Something went wrong !!");
            }
        );
    };

    return (
        <Modal
            isOpen={props.show}
            toggle={props.onHide}
            className="resetGeospaceModal"
        >
            <ModalBody className="pb-0">
                <div className="resetGeoModalHeader border-bottom mb-4 pb-3">
                    <img
                        src={ResetGeospaceInfoIcon}
                        alt=""
                        className="geospaceInfoIcon"
                    />
                    <div className="head">Delete the Guest List</div>
                </div>
                <Row>
                    <Col className="px-5">
                        <div className="eventThumWrapper">
                            <div className="eventThum">
                                <img
                                    src={
                                        common?.singleEvent?.cover_image
                                            ? common?.singleEvent?.cover_image
                                            : DummyImg
                                    }
                                    alt=""
                                    className="eventThumImg"
                                />
                            </div>
                            <div className="eventThumContent">
                                <div className="eventthumbTitle">
                                    {common?.singleEvent?.event_name}
                                </div>
                                <div className="eventthumbSubTitle">
                                    {common?.singleEvent?.location}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2 ">
                    <Col className="px-5">
                        <p>
                            Confirm that you want to delete the Guest list. All guests will be
                            removed from this event.
                        </p>
                        <p>
                            Guest records will remain in your organization’s global guest
                            pool. To completly delete guest information, navigate to the guest
                            pool.
                        </p>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="pt-0 d-flex align-center justify-space-between">
                <Button
                    color="primary"
                    className="flex-grow-1 pinkBtn"
                    onClick={showAlert}
                >
                    Delete Guest LIst
                </Button>
                <Button className="btn-default cancelBtn" onClick={props.onHide}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default DeleteGuestList;
