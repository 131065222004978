import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { informationService } from "../../_services/masters/information.service";
import moment from "moment";
import Index from "./index";
import { useHistory } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
const SimpleTabs = (props) => {
  const common = useSelector((state) => {
    return state.commonData.common;
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [common]);
  let history = useHistory();
  const [eventList, seteventList] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState(0);
  const pageId = props.eventdata.id;

  // React.useEffect(() => {
  //     // if it equals 0 set the active tab to 0
  //     if (pageId === 0) {
  //         setActiveTab(0);
  //         return;
  //     }
  //     // set the active tab state to the page id that we selected
  //     setActiveTab(pageId || 1);
  // }, [pageId]);
  React.useEffect(() => {
    informationService.eventList().then(
      (res) => {
        if (res.status === "success") {
          seteventList(res.data);
        } else {
          console.log("Something went wrong !!");
          //toast.error(res.errors[0], "Fashion Store");
        }
      },
      (error) => {
        console.log("Something went wrong !!");
        //toast.error("Something went wrong !!", "Fashion Store");
      }
    );
  }, []);

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  return (
    <>
      {/* <div className='tabs' style={{ background: 'rgb(32, 83, 181)', }}>
                <div className='helootabs' >
                    <Tabs activeTab={activeTab} onTabClick={onTabClick}

                        leftBtnIcon={<i className="ni ni-bold-left" style={{ color: '#fff' }}></i>}
                        rightBtnIcon={<i className="ni ni-bold-right" style={{ color: '#fff' }}></i>}

                    >
                        {eventList.map((item, i) => (

                            <Tab key={i}


                                onClick={() =>
                                    history.push(`/admin/event-dashboard/${item.project_id}/${item.id}`, undefined, { scroll: false })
                                }
                            >

                                <span style={{ fontSize: '12px', marginRight: '5px', color: '#fff' }}>{moment(item.event_start_date).format('MMM Do')}</span><a style={{ color: '#fff' }} key={i} id={item.id} disabled={activeTab === i} className={`tabsclassevent ${activeTab === i}`}>{item.event_name}</a>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div > */}
      <div className="content mainContentWrapper">
        {loading ? (
          <>
            {/* <div className="loding dashboardLoadingWrapper">
                <TailSpin
                  height="80"
                  width="80"
                  radius="9"
                  color="green"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              </div> */}
            <div className="dashboardLoadingWrapper">
              <div className="circleAnimation1"></div>
            </div>
          </>
        ) : (
          <>
            {eventList.map((tab, i) => (
              <>
                <div key={i}>
                  <div>
                    {activeTab === i && <Index eventName={props.eventdata} />}
                  </div>
                </div>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default SimpleTabs;
